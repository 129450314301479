/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { AssignStatus } from 'models';
import { GetEvaluation } from 'domains/cognite/services/manageEvaluation';
import { GetUser } from 'domains/cognite/services/manageUser';
import { CreateAssignRelationship } from 'domains/cognite/services/manageAssignRelationship';
import { Executor } from 'domains/apigateway/services/get-Executor';
import { CreateExecutorViewHistory } from 'domains/cognite/services/manageExecutorViewHistory';
import {
  sendEmailForAssign,
  SendEmailForAssignReqBody,
} from 'domains/apigateway/services/sendEmail';
import { logger } from 'utils/logger';
import minusBtn from '../images/minusBtn.svg';
import ExecutorFormCard from './executorFormCard';
import ExecutorCard from './executorCard';
import './executorInfoCard.css';

type Props = {
  executor: Executor;
};

const ExecutorInfoCard: FC<Props> = (props) => {
  const { data: userData } = useQuery(['executor_infocard'], GetUser);
  if (!userData) {
    logger.error(userData);
    throw Error;
  }
  const { executor } = props;
  const { id, name, email, zone, passport } = executor;

  const { data: evaluation } = useQuery(
    ['executor_info_card_evaluation'],
    GetEvaluation,
  );

  const evaluationByAssigneeId = evaluation
    ? evaluation.filter((ele) => ele.assigneeId === id)
    : [];

  const evaluationValue = evaluationByAssigneeId.map((ele1) => {
    const evaluationValueSum =
      (ele1.response !== undefined && ele1.response !== null
        ? ele1.response
        : 0) +
      (ele1.explanation !== undefined && ele1.explanation !== null
        ? ele1.explanation
        : 0) +
      (ele1.answer !== undefined && ele1.answer !== null ? ele1.answer : 0) +
      (ele1.claritOfyPrice !== undefined && ele1.claritOfyPrice !== null
        ? ele1.claritOfyPrice
        : 0) +
      (ele1.validityOfPrice !== undefined && ele1.validityOfPrice !== null
        ? ele1.validityOfPrice
        : 0);

    return evaluationValueSum;
  });

  const evaluationValueTotal = evaluationValue.reduce(
    (sum, element) => sum + element,
    0,
  );

  const evaluationValueTotalAve =
    evaluationValueTotal / evaluationByAssigneeId.length / 5;

  // 評価の星に渡す数字（整数）
  const evaluationValueAveStar = Math.round(evaluationValueTotalAve);

  // 表示用の数字（小数第一位）
  const evaluationValueAveDisplay =
    Math.round(evaluationValueTotalAve * 10) / 10;

  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const applyToAssign = async () => {
    const user = await GetUser();

    await CreateAssignRelationship({
      clientId: user.attributes.sub,
      assigneeId: id,
      status: AssignStatus.REGISTERD,
    }).then(() => {
      navigate('../executorawaitingapproval', { state: { id: 1 } });
    });

    const body: SendEmailForAssignReqBody = {
      fromEmail: userData.attributes.email,
      toEmail: email,
      action: 'registerd',
    };
    await sendEmailForAssign(body);
  };

  return (
    <>
      <button
        type="button"
        onClick={async () => {
          setShow(true);
          await CreateExecutorViewHistory({
            userId: userData.attributes.sub,
            executorId: id,
          });
        }}
      >
        <ExecutorCard
          name={name}
          zone={zone.toString()}
          passport={passport.toString()}
          key={`ExecutorCard_${id}`}
        />
      </button>
      {show ? (
        <div
          className="executorInfoCard"
          id="overlay1"
          onClick={() => setShow(false)}
        >
          <div>
            <div className="executorInfoCardText">
              <p>詳細情報</p>
              <ExecutorFormCard
                executor={executor}
                evaluationValueAveStar={evaluationValueAveStar || 0}
                evaluationValueAveDisplay={evaluationValueAveDisplay || 0}
                evaluationValueCount={evaluationByAssigneeId.length || 0}
                // とりあえず
              />
              <div>
                <p>
                  この人を選定する場合は、承認申込ボタンをクリックしてください。
                  <br />
                  承認申請ボタンを押すと、あなたが現在遺言アプリに入力している情報（架空）も、この遺言執行者に送信されます。
                  <br />
                  5営業日以内に、この遺言執行者から返信がない場合は、「承認されなかった（遺言執行者側の都合により対応不可）」とご理解ください。
                  <br />
                  その場合は、他の遺言執行者を選定願います。
                </p>
                <button
                  type="submit"
                  className="searchBtn"
                  onClick={applyToAssign}
                >
                  <p>承認申込する</p>
                </button>
                <button type="button" onClick={() => setShow(false)}>
                  <img src={minusBtn} alt="minus-Button" />
                  <p>閉じる</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ExecutorInfoCard;
