import WillInfoImg from '../images/willInfoImg.svg';
import RealEstateImg from '../images/realEstateImg.svg';
import MovingPropertyImg from '../images/movingPropertyImg.svg';
import DepositsImg from '../images/depositsImg.svg';
import StockImg from '../images/stockImg.svg';
import LifeInsuranceImg from '../images/lifeInsuranceImg.svg';
import BondsImg from '../images/bondsImg.svg';
import LiabilitiesImg from '../images/liabilitiesImg.svg';
import LegalHeirImg from '../images/legalHeirImg.svg';
import RecipientImg from '../images/recipientImg.svg';
import PropertyAllocationImg from '../images/propertyAllocationImg.svg';
import './pageDate.css';

const pageData = [
  {
    // 0:遺言者情報,
    description: (
      <>
        ここで入力する
        <span className="descriptionText">名前や住所は、仮名や架空の住所</span>
        で構いません。
        <br />
        あとで、正式な遺言書にしたいときは、戸籍上の氏名・住民票上の住所が必要になります。
      </>
    ),
    descriptionImg: <img src={WillInfoImg} alt="" />,
  },
  {
    // 1:遺言者情報2 未使用,
    description: (
      <>
        あとで、正式な遺言書にしたい場合や専門家のアドバイスを受ける時は、戸籍上の氏名・住民票上の住所が必要になります。
      </>
    ),
    descriptionImg: <img src={WillInfoImg} alt="" />,
  },
  {
    // 2:不動産1
    description: (
      <>
        不動産を所有している方は{' '}
        <span className="descriptionText">「はい」</span>
        、所有していない方は <span className="descriptionText">「いいえ」</span>
        を選んで次の項目へ進んでください。
      </>
    ),
    descriptionImg: <img src={RealEstateImg} alt="" />,
  },
  {
    // 3:不動産2 未使用
    description: (
      <>
        不動産を所有していない方、又は省略したい方は「いいえ」を選んで次の項目へ進んでください。
      </>
    ),
    descriptionImg: <img src={RealEstateImg} alt="" />,
  },
  {
    // 4:動産
    description: (
      <>
        動産に関する質問です。
        <br />
        ここでは、
        <span className="descriptionText">
          「はい」または「いいえ」だけで回答
        </span>
        してください。
        <span className="descriptionText">
          動産の具体的な特定方法は、専門家への相談
        </span>
        をお勧めします。
      </>
    ),
    descriptionImg: <img src={MovingPropertyImg} alt="" />,
  },
  {
    // 5:預貯金1
    description: (
      <>
        {/* 預貯金に関する質問です。
        <br />
        <span className="descriptionText">金融機関名までの記入</span>
        で足ります。架空の金融機関・支店名でも構いません。 */}
        あなた名義の預貯金口座を記入してください。
        <span className="descriptionText">
          架空の金融機関・支店名で構いません
        </span>
        が、ご自分で、どの口座の事か分かるようにしておいてください。
      </>
    ),
    descriptionImg: <img src={DepositsImg} alt="" />,
  },
  {
    // 6:預貯金2 未使用
    description: (
      <>
        あなた名義の預貯金口座がない場合は、又は省略したい方は「いいえ」を選択して次の項目へ進んでください。
      </>
    ),
    descriptionImg: <img src={DepositsImg} alt="" />,
  },
  {
    // 7:株式・債券1
    description: (
      <>
        証券会社に口座があれば<span className="descriptionText">「はい」</span>
        を、口座がない場合は<span className="descriptionText">「いいえ」</span>
        を選択してください。
      </>
    ),
    descriptionImg: <img src={StockImg} alt="" />,
  },
  {
    // 8:株式・債券2 未使用
    description: (
      <>
        証券会社の口座がない場合は、又は省略したい方は「いいえ」を選択して次の項目へ進んでください。
      </>
    ),
    descriptionImg: <img src={StockImg} alt="" />,
  },
  {
    // 9:生命保険1
    description: (
      <>
        生命保険に加入していれば
        <span className="descriptionText">「はい」</span>
        を、加入していない場合は
        <span className="descriptionText">「いいえ」</span>を選択してください。
      </>
    ),
    descriptionImg: <img src={LifeInsuranceImg} alt="" />,
  },
  {
    // 10:生命保険2 未使用
    description: (
      <>
        加入していない場合は、又は省略したい方は「いいえ」を選択して次の項目へ進んでください。
      </>
    ),
    descriptionImg: <img src={LifeInsuranceImg} alt="" />,
  },
  {
    // 11:債権1
    description: (
      <>
        貸付金や出資金、経営者の帳簿上の貸付金などがある場合は
        <span className="descriptionText">「はい」</span>を、無い場合は
        <span className="descriptionText">「いいえ」</span>を選択してください。
      </>
    ),
    descriptionImg: <img src={BondsImg} alt="" />,
  },
  {
    // 12:債権2 未使用
    description: (
      <>
        債権が無い場合は、又は省略したい方は「いいえ」を選択して次の項目へ進んでください。
      </>
    ),
    descriptionImg: <img src={BondsImg} alt="" />,
  },
  {
    // 13:負債1
    description: (
      <>
        将来的に増減があっても、
        <span className="descriptionText">現在の情報を入力</span>
        してください。
        <br />
        負債が無い場合、又は省略したい方は「いいえ」を選択して次の項目へ進んでください。
      </>
    ),
    descriptionImg: <img src={LiabilitiesImg} alt="" />,
  },
  {
    // 14:負債2 未使用
    description: (
      <>
        負債が無い場合、又は省略したい方は「いいえ」を選択して次の項目へ進んでください。
      </>
    ),
    descriptionImg: <img src={LiabilitiesImg} alt="" />,
  },
  {
    // 15:法定相続人1 未使用
    description: (
      <>
        法定相続人に関する質問にはい／いいえで回答してください。
        すべて回答すると法定相続人の範囲がわかります。
      </>
    ),
    descriptionImg: <img src={LegalHeirImg} alt="" />,
  },
  {
    // 16:法定相続人2,
    description: (
      <>
        法定相続人に関する質問に、はい／いいえで回答してください。
        <br />
        すべて回答すると、あなたの法定相続人は誰かがわかります。
      </>
    ),
    descriptionImg: <img src={LegalHeirImg} alt="" />,
  },
  {
    // 17:法定相続人3 未使用
    description: (
      <>
        法定相続人の名前は
        <span className="descriptionText">架空で構いません</span>。
        妻・長男・次男のような表現でも構いません。
      </>
    ),
    descriptionImg: <img src={LegalHeirImg} alt="" />,
  },
  {
    // 18:受遺者1,
    description: (
      <>
        受遺者に関する項目です。
        <br />
        <span className="descriptionText">
          法定相続人以外の人や団体に遺贈したい
        </span>
        場合はこちらへ記入します。 架空の名前、法人名で構いません。
      </>
    ),
    descriptionImg: <img src={RecipientImg} alt="" />,
  },
  {
    // 19:受遺者2 未使用
    description: <>架空の名前、法人名で構いません。</>,
    descriptionImg: <img src={RecipientImg} alt="" />,
  },
  {
    // 20:相続財産の割り振り,
    description: (
      <>
        相続財産を、各相続人へ割り振る項目です。
        <span className="descriptionText">登録した財産を割り振ります。</span>
      </>
    ),
    descriptionImg: <img src={RecipientImg} alt="" />,
  },
  {
    // 21:相続財産の割り振り2, 未使用
    description: (
      <>
        相続財産の割り振りを決める項目です。
        <br />
        <span className="descriptionText">
          登録した財産の割り振りをします。
        </span>
      </>
    ),
    descriptionImg: <img src={RecipientImg} alt="" />,
  },
  {
    // 22:遺言書1,
    description: (
      <>
        「遺言書に反映させる」ボタンを押すと、今まで入力した内容がPDFやWordで確認できます。
      </>
    ),
    descriptionImg: <img src={PropertyAllocationImg} alt="" />,
  },
  {
    // 23:遺言書2 未使用,
    description: (
      <>
        全体の確認が出来たら、ここからは正確な情報を入力して、法的に有効な遺言書を完成させます。
        有効な遺言書ができるまでチェック事項はたくさんありますから専門家に相談されるようお勧めいたします。
      </>
    ),
    descriptionImg: <img src={PropertyAllocationImg} alt="" />,
  },
  {
    // 24:遺言執行者の選定1,
    description: (
      <>
        {/* <span className="descriptionText">遺言執行者の選定がまだです。</span> */}
        ボタンを押して遺言執行者の選定を行ってください。
        <br />
        名簿から選定し承認申込をクリックすると相手に申込が届きます。
      </>
    ),
    descriptionImg: <img src={RealEstateImg} alt="" />,
  },
  {
    // 25:遺言執行者の選定2, 未使用
    description: (
      <>
        遺言執行者を選定して名前をクリックすると、自動的に相手に通知が届き、相手方が承認（対応可能なら）したら、3営業日以内に折り返し連絡が来ます。
      </>
    ),
    descriptionImg: <img src={RealEstateImg} alt="" />,
  },
  {
    // 26:遺言執行者名簿から探す,
    description: (
      <>
        この名簿には遺言執行士クラブの登録会員が記載されています。
        <br />
        検索条件を選択して入力してください。
      </>
    ),
    descriptionImg: <img src={RealEstateImg} alt="" />,
  },
  {
    // 27:検索結果1,
    description: (
      <>
        <span className="descriptionText">検索結果です</span>
        <br />
        名簿の中から遺言執行者を選定してクリックすると遺言執行者のプロフィールを見られます。
      </>
    ),
    descriptionImg: <img src={RealEstateImg} alt="" />,
  },

  {
    // 28:検索結果2,
    description: (
      <>
        <span className="descriptionText">遺言執行者のプロフィール</span>
        をクリックし、<span className="descriptionText">「承認申込」</span>
        をすると自動的に相手に「承認申込」通知が届きます。
      </>
    ),
    descriptionImg: <img src={RealEstateImg} alt="" />,
  },

  {
    // 29:検索結果3,
    description: (
      <>
        「承認申込」とは、あなたから遺言執行者に対し
        <span className="descriptionText">
          『遺言書のチェックや質問に答えてくれますか？』
        </span>
        という申込です。
      </>
    ),
    descriptionImg: <img src={RealEstateImg} alt="" />,
  },

  {
    // 30:検索結果4,
    description: (
      <>
        相手方が「承認」したら、通知メールが来ます。
        <span className="descriptionText">5営業日以内</span>
        に通知メールが届かない場合は他の遺言執行士を選定してください。
      </>
    ),
    descriptionImg: <img src={RealEstateImg} alt="" />,
  },

  {
    // 31:担当遺言執行者（承認待ち）,
    description: (
      <>
        現在担当遺言執行者の
        <span className="descriptionText">承認まち</span>
        です。
        <br />
        今しばらくお待ちください。
      </>
    ),
    descriptionImg: <img src={RealEstateImg} alt="" />,
  },
  {
    // 32:担当遺言執行者1,
    description: (
      <>
        あなたが選定し、相手が承認した遺言執行者です。チャットで5往復まで無料で相談できます。
        <br />
        それを超える相談は、担当遺言執行者に直接電話でお尋ね下さい（担当者によってはその後有料となる場合もあります）。
      </>
    ),
    descriptionImg: <img src={RealEstateImg} alt="" />,
  },
  {
    // 33:担当遺言執行者2,
    description: (
      <>
        担当遺言執行者は変更することができますが、変更する場合は、現在の遺言執行者に必ずその旨を連絡してください。
      </>
    ),
    descriptionImg: <img src={RealEstateImg} alt="" />,
  },
  {
    // 34:担当遺言執行者3,
    description: (
      <>
        また、新しく選定した遺言執行者に対し、他の遺言執行者からの見解に対するコメントを求めることは出来ません。
      </>
    ),
    descriptionImg: <img src={RealEstateImg} alt="" />,
  },
  {
    // 35:名簿以外の遺言執行者を指定,
    description: (
      <>
        <span className="descriptionText">
          名簿以外の方を遺言執行者とする場合
        </span>
        は、こちらに記入してください。
      </>
    ),
    descriptionImg: <img src={RealEstateImg} alt="" />,
  },
  {
    // 36:担当遺言執行者（名簿以外から選定）,
    description: (
      <>
        <span className="descriptionText">
          あなたの選定した遺言執行者です。
        </span>
        内容を確認してください。
        <br />
        変更したい場合は、下の変更するをクリックしてください。
      </>
    ),
    descriptionImg: <img src={RealEstateImg} alt="" />,
  },
  {
    // 37:担当遺言執行者の変更,
    description: (
      <>
        担当遺言執行者を変更すると、
        <span className="descriptionText">
          過去のチャットを確認することが出来なくなります。
        </span>
        <br className="pc-only" />
        <br />
        <span className="descriptionText">
          もう一度同じ遺言執行者を選定することは出来なくなります。
        </span>
      </>
    ),
    descriptionImg: <img src={RealEstateImg} alt="" />,
  },
];
export default pageData;
