import { FC } from 'react';
import UserLogoHeader from '../organisms/userLogoHeader';
import ExecutorPrivacyPolicyContent from '../templates/executorPrivacyPolicyContent';
import CommonFooter from '../../apply/templates/commonFooter';
import NavigateBtn from '../../../user/components/atoms/navigateBtn';
import './privacyPolicy.css';

const ExecutorPrivacyPolicy: FC = () => (
  <>
    <UserLogoHeader />
    <div className="privacyPolicy">
      <ExecutorPrivacyPolicyContent />
      <NavigateBtn />
    </div>
    <CommonFooter />
  </>
);

export default ExecutorPrivacyPolicy;
