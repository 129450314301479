import { FC, Suspense } from 'react';
import Spinner from '../../../components/molecules/Spinner';
import { OthersDatas } from '../data/homeData';
import OtherItem from '../molecules/otherItem';
import OtherItemExecutor from '../molecules/otherItemExecutor';
import descriptionWomen from '../images/descriptionWomen.svg';
import './others.css';

type Props = {
  linkName: string;
};

const Others: FC<Props> = (props) => {
  const { linkName } = props;

  return (
    <>
      <div className="others">
        <div>
          <div>
            <div className="othersCheck">
              <p>
                遺言書確認ボタンを押すと現時点での遺言書の内容が確認できます。
              </p>
              <img src={descriptionWomen} alt="説明アイコン" />
            </div>
            <Suspense fallback={<div>{Spinner}</div>}>
              <OtherItem
                name={OthersDatas[0].name}
                href={OthersDatas[0].href}
                icon={OthersDatas[0].icon}
              />
            </Suspense>
          </div>
          <div>
            <div className="othersSelection">
              <p>この遺言の執行をする人を決めてください。</p>
              <img src={descriptionWomen} alt="説明アイコン" />
            </div>
            <Suspense fallback={<div>{Spinner}</div>}>
              <OtherItemExecutor
                name={OthersDatas[1].name}
                icon={OthersDatas[1].icon}
                linkName={linkName}
              />
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
};

export default Others;
