/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import { FC, ChangeEvent } from 'react';
import { Receiver } from 'models';
import minusBtn from '../images/minusBtn.svg';
import InputItem from '../atoms/InputItem';
import './lifeInsuranceReceiverFormCard.css';

type Props = {
  title: string;
  conponentIndex: number;
  form: string;
  minusBtnView: boolean;
  displayReceiver: Receiver | undefined;
  updateReceiverContent: (
    sortKey: string,
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement>,
    target: string,
  ) => void;
  sortId: string;
  subjectReceiverContentId: string;
  deleteReceiverCard: (
    sortId: string,
    subjectReceiverContentId: string,
  ) => void;
};

const LifeInsuranceReceiverFormCard: FC<Props> = (props) => {
  const {
    title,
    conponentIndex,
    form,
    minusBtnView,
    displayReceiver,
    updateReceiverContent,
    sortId,
    subjectReceiverContentId,
    deleteReceiverCard,
  } = props;

  const scroll = () => {
    window.scrollBy({
      top: 100,
      behavior: 'smooth',
    });
  };

  const updateReceiverNameData = (e: ChangeEvent<HTMLInputElement>) => {
    updateReceiverContent(sortId, subjectReceiverContentId, e, 'name');
  };

  const updateReceiverRatioData = (e: ChangeEvent<HTMLInputElement>) => {
    updateReceiverContent(sortId, subjectReceiverContentId, e, 'ratio');
  };

  const deleteReceiverCardFunc = () => {
    deleteReceiverCard(sortId, subjectReceiverContentId);
  };

  const receiverName =
    displayReceiver && displayReceiver.name ? displayReceiver.name : '';
  const receiverRatio =
    displayReceiver && displayReceiver.ratio ? displayReceiver.ratio : '';

  return (
    <div className="lifeInsuranceReceiverForm">
      <div>
        <p>
          {title}
          {conponentIndex + 1}
        </p>
        <button
          type="button"
          onClick={deleteReceiverCardFunc}
          className={
            minusBtnView
              ? 'lifeInsuranceReceiveropen'
              : 'lifeInsuranceReceiverclose'
          }
        >
          <div>
            <img src={minusBtn} alt="minus-Button" />
            <p>削除</p>
          </div>
        </button>
      </div>
      <div>
        <div>
          <label htmlFor="receiver">
            <InputItem title="受取人名" name="require" />
            <input
              type="text"
              id={`receiver_${form}`}
              key={`receiver_${form}`}
              name="receiver"
              placeholder="入力してください"
              onBlur={scroll}
              defaultValue={receiverName}
              onChange={updateReceiverNameData}
            />
          </label>
          <p>架空の名前で構いません</p>
        </div>
        <div>
          <label htmlFor="ratio">
            <InputItem title="受取割合" name="optional" />
            <input
              type="number"
              id={`ratio_${form}`}
              key={`ratio_${form}`}
              name="ratio"
              placeholder="50"
              onBlur={scroll}
              defaultValue={receiverRatio}
              autoComplete="off"
              onChange={updateReceiverRatioData}
            />
            %
          </label>
        </div>
      </div>
    </div>
  );
};

export default LifeInsuranceReceiverFormCard;
