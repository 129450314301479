import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ExecutorQuestionData } from 'common/components/data/executorQuestionData';
import UserLogoHeader from 'common/components/organisms/userLogoHeader';
import QuestionCard from 'common/components/organisms/questionCard';
import CommonFooter from 'common/apply/templates/commonFooter';
import NavigateBtn from '../../../user/components/atoms/navigateBtn';
import './executorQuestion.css';

const ExecutorQuestion: FC = () => (
  <>
    <UserLogoHeader />
    <div className="faq executorfaq">
      <p>よくあるご質問</p>
      <div>
        <p>登録資格者用</p>
      </div>
      <Link to="//executor/willquestion">
        <p>お客様用のよくあるご質問＞</p>
      </Link>
      {ExecutorQuestionData.map((data) => (
        <QuestionCard
          questionNumber={data.questionNumber}
          question={data.question}
          answer={data.answer}
          key={data.questionNumber}
        />
      ))}
      <NavigateBtn />
    </div>
    <CommonFooter />
  </>
);

export default ExecutorQuestion;
