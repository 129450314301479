/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-alert */
import { FC, ChangeEvent } from 'react';
import minusBtn from '../images/minusBtn.svg';
import InputItem from '../atoms/InputItem';
import './depositsFormCard.css';

type Props = {
  title: string;
  form: string;
  minusBtnView: boolean;
  name: string;
  subNum: number;
  bankValue: string;
  branchValue: string;
  accountHolderValue: string;
  accountTypeName: string;
  updateDepositsContent: (
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement>,
    target: string,
  ) => void;
  subjectId: string;
  deleteCard: (deleteSubject: string) => void;
};

const DepositsFormCard: FC<Props> = (props) => {
  const {
    title,
    form,
    minusBtnView,
    name,
    subNum,
    bankValue,
    branchValue,
    accountHolderValue,
    accountTypeName,
    updateDepositsContent,
    subjectId,
    deleteCard,
  } = props;

  const scroll = () => {
    window.scrollBy({
      top: 100,
      behavior: 'smooth',
    });
  };

  const updateBankNameData = (e: ChangeEvent<HTMLInputElement>) => {
    updateDepositsContent(subjectId, e, 'bankName');
  };

  const updateBranchNameData = (e: ChangeEvent<HTMLInputElement>) => {
    updateDepositsContent(subjectId, e, 'branchName');
  };

  const updateAccountTypeData = (e: ChangeEvent<HTMLInputElement>) => {
    updateDepositsContent(subjectId, e, 'accountType');
    scroll();
  };

  const deleteCardFunc = () => {
    deleteCard(subjectId);
  };

  return (
    <div className="depositsForm">
      <div>
        <p>
          {title}
          {subNum}
        </p>
        <button
          type="button"
          onClick={deleteCardFunc}
          className={minusBtnView ? 'depositsopen' : 'depositsclose'}
        >
          <div>
            <img src={minusBtn} alt="minus-Button" />
            <p>削除</p>
          </div>
        </button>
      </div>
      <div>
        <div>
          <label htmlFor={`bank_${form}`}>
            <InputItem title="銀行名" name="require" />
            <input
              required
              type="text"
              id={`bank_${form}`}
              key={`bank_${form}`}
              name="bank"
              placeholder="入力してください"
              onBlur={scroll}
              defaultValue={bankValue}
              onChange={updateBankNameData}
            />
          </label>
          <p>架空の金融機関名で構いません</p>
        </div>
        <div>
          <label htmlFor={`branch_${form}`}>
            <InputItem title="支店名" name="optional" />
            <input
              type="text"
              id={`branch_${form}`}
              key={`branch_${form}`}
              name="branch"
              placeholder="入力してください"
              onBlur={scroll}
              defaultValue={branchValue}
              onChange={updateBranchNameData}
            />
          </label>
          <p>架空の支店名（ゆうちょの場合は記号・番号）で構いません</p>
        </div>
        <div className="depositsFormAccount">
          <InputItem title="口座種別" name="optional" />
          <div>
            <div>
              <input
                type="radio"
                id={`normal_${form}`}
                key={`normal_${form}`}
                name={name}
                onChange={updateAccountTypeData}
                onBlur={scroll}
                value="普通"
                defaultChecked={accountTypeName === '普通'}
              />
              <label htmlFor={`normal_${form}`}>普通</label>
            </div>
            <div>
              <input
                type="radio"
                id={`current_${form}`}
                key={`current_${form}`}
                name={name}
                onChange={updateAccountTypeData}
                onBlur={scroll}
                value="定期"
                defaultChecked={accountTypeName === '定期'}
              />
              <label htmlFor={`current_${form}`}>定期</label>
            </div>
          </div>
        </div>
        <div>
          <label htmlFor="accountNumber">
            <InputItem title="口座番号" name="blank" />
            <input
              type="text"
              id="accountNumber"
              name="accountNumber"
              placeholder=""
              readOnly
            />
          </label>
        </div>
        <div>
          <label htmlFor="accountHolder">
            <InputItem
              title={`口座名義人\n(カナ)`}
              title1="口座名義人(カナ)"
              name="blank"
            />
            <input
              type="text"
              id="accountHolder"
              name="accountHolder"
              placeholder=""
              readOnly
              defaultValue={accountHolderValue}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default DepositsFormCard;
