/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import { DataStore } from 'aws-amplify';
import {
  AllocationReceiver,
  AllocationReceiverMetaData,
  AllocationProperties,
  Allocation,
} from 'models';
import { logger } from 'utils/logger';
import { ModelInit } from '@aws-amplify/datastore';

export const MutateAllocationReceiverAndGetId = async (
  id: string,
  legalHeirQuestionId: number,
  num: number,
  name: string,
  birth: string,
  // 法定相続人or受遺者
  type: string,
  allocation?: Allocation,
): Promise<AllocationReceiver> => {
  try {
    logger.info('法定相続人または受遺者の項目の作成または更新を開始します');
    const original = await DataStore.query(AllocationReceiver, id);

    if (original) {
      const data = await DataStore.save(
        AllocationReceiver.copyOf(original, (updated) => {
          updated.name = name;
          updated.birth = birth;
          updated.type = type;
          updated.allocation = allocation;
        }),
      );
      logger.info('法定相続人または受遺者の項目の更新が完了しました');

      return data;
    }
    logger.info('法定相続人または受遺者の項目の作成を開始します');
    const instance = {
      legalHeirQuestionId,
      num,
      name,
      birth,
      type,
      allocation,
    };
    const data = await DataStore.save(new AllocationReceiver(instance));
    logger.info('法定相続人のまたは受遺者の項目の作成が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const MutatePropertiesOfAllocationReceiver = async (
  id: string,
  allocationProperties: (AllocationProperties | null)[],
): Promise<void> => {
  try {
    logger.info('法定相続人または受遺者の受取人の作成または更新を開始します');
    const original = await DataStore.query(AllocationReceiver, id);

    if (original) {
      const data = await DataStore.save(
        AllocationReceiver.copyOf(original, (updated) => {
          updated.allocationProperties = allocationProperties;
        }),
      );
      logger.debug(data);
      logger.info('法定相続人のまたは受遺者の受取人の更新が完了しました');

      // return data;
    }
    // logger.info('法定相続人のまたは受遺者の受取人の作成を開始します');
    // const instance = {
    //   subNum: 10,
    //   allocationProperties
    // };
    // const data = await DataStore.save(new AllocationReceiver(instance));
    // logger.info('法定相続人のまたは受遺者の項目の作成が完了しました');

    // return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const CreateAllocationReceiverAndGetId = async (
  instance: ModelInit<AllocationReceiver, AllocationReceiverMetaData>,
): Promise<string> => {
  try {
    logger.info('法定相続人の項目の作成を開始します');
    const data = await DataStore.save(new AllocationReceiver(instance));
    logger.debug(data);
    logger.info('法定相続人の項目の作成が完了しました');

    return data.id;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const CreateAllocationReceiver = async (
  instance: ModelInit<AllocationReceiver, AllocationReceiverMetaData>,
): Promise<AllocationReceiver> => {
  try {
    logger.info('法定相続人の項目の作成を開始します');
    const data = await DataStore.save(new AllocationReceiver(instance));
    logger.info('法定相続人の項目の作成が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const UpdateAllocationReceiverById = async (
  id: string,
  num: number,
  name: string,
  birth: string,
  // 法定相続人or受遺者
  type: string,
  // allocationProperties: []
): Promise<void> => {
  try {
    logger.info('法定相続人の項目の更新を開始します');

    const original = await DataStore.query(AllocationReceiver, id);

    if (original) {
      logger.info(original);
      const data = await DataStore.save(
        AllocationReceiver.copyOf(original, (updated) => {
          updated.name = name;
          updated.birth = birth;
          updated.type = type;
        }),
      );
      logger.info(data);
      logger.info('法定相続人の項目の更新が完了しました');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteAllocationReceiverById = async (
  id: string,
): Promise<void> => {
  try {
    logger.info('法定相続人の項目の削除を開始します');
    const original = await DataStore.query(AllocationReceiver, id);
    if (original) {
      // logger.info(original);
      await DataStore.delete(original);
      logger.info('法定相続人の項目の削除が完了しました');
    } else {
      logger.info('なしなし');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetAllocationReceiver = async (): Promise<
  AllocationReceiver[]
> => {
  try {
    logger.info('法定相続人の項目のフェッチを開始します');
    const data = await DataStore.query(AllocationReceiver);
    logger.debug(data);
    logger.info('法定相続人の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetAllocationReceiverByName = async (
  name: string,
): Promise<AllocationReceiver[]> => {
  try {
    logger.info('法定相続人の項目のフェッチを開始します');
    const data = await DataStore.query(AllocationReceiver, (c) =>
      c.name('eq', name),
    );
    logger.debug(data);
    logger.info('法定相続人の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteAllocationReceiver = async (): Promise<
  AllocationReceiver[]
> => {
  try {
    logger.info('相続人の項目の全削除を開始します');
    const data = await DataStore.query(AllocationReceiver);
    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);

          return data;
        }),
      );
    }
    logger.debug(data);
    logger.info('相続人の項目の全削除が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteLegalHeir = async (): Promise<AllocationReceiver[]> => {
  try {
    logger.info('法定相続人の項目の全削除を開始します');
    const data = await DataStore.query(AllocationReceiver);
    const typeLegalHeirData = data.filter((ele) => ele.type === '法定相続人');
    if (typeLegalHeirData) {
      await Promise.all(
        typeLegalHeirData.map(async (ele) => {
          await DataStore.delete(ele);

          return typeLegalHeirData;
        }),
      );
    }
    logger.debug(typeLegalHeirData);
    logger.info('法定相続人の項目の全削除が完了しました');

    return typeLegalHeirData;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetAllocationReceiverByParent = async (
  allocation: Allocation | null | undefined,
): Promise<AllocationReceiver[]> => {
  try {
    const data = (await DataStore.query(AllocationReceiver)).filter((c) => {
      if (c.allocation === null || c.allocation === undefined || !allocation) {
        return false;
      }
      const allocationId = c.allocation.id;

      return allocation.id === allocationId;
    });

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const MutateAllocationReceiver = async (
  id: string,
  instance: ModelInit<AllocationReceiver, AllocationReceiverMetaData>,
): Promise<AllocationReceiver> => {
  try {
    logger.info('受遺者の作成または更新を開始します');
    const original = await DataStore.query(AllocationReceiver, id);

    if (original) {
      logger.info('受遺者の更新を開始します');
      const data = await DataStore.save(
        AllocationReceiver.copyOf(original, (updated) => {
          // ワンチャンここがバグ
          updated.allocationProperties = instance.allocationProperties;
        }),
      );

      return data;
    }
    logger.info('受遺者の作成を開始します');
    const data = await DataStore.save(new AllocationReceiver(instance));

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteRecipient = async (): Promise<AllocationReceiver[]> => {
  try {
    logger.info('受遺者の項目の全削除を開始します');
    const data = await DataStore.query(AllocationReceiver);
    const typeRecipientData = data.filter((ele) => ele.type === '受遺者');
    if (typeRecipientData) {
      await Promise.all(
        typeRecipientData.map(async (ele) => {
          await DataStore.delete(ele);

          return typeRecipientData;
        }),
      );
    }
    logger.debug(typeRecipientData);
    logger.info('受遺者の項目の全削除が完了しました');

    return typeRecipientData;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteAllocationReceiverByQuestionId = async (
  legalHeirQuestionId: number,
): Promise<void> => {
  try {
    logger.info('法定相続人の項目の削除を開始します');
    const original = await DataStore.query(AllocationReceiver, (c) =>
      c.legalHeirQuestionId('eq', legalHeirQuestionId),
    );
    if (original) {
      await Promise.all(
        original.map(async (ele) => {
          await DataStore.delete(ele);

          return original;
        }),
      );
      logger.info('法定相続人の項目の削除が完了しました');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
