/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import { ChangeEvent, FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUsersListFromAdmin } from 'hooks';
import { PrefectureArray } from 'user/components/data/prefecture';
import { UserAttributes } from 'domains/cognite/user';
import PaginationFunc from 'utils/paginationFunc';
import AdminNav from '../organisms/adminNav';
import PaginationLayout from '../organisms/paginationLayout';
import './userList.css';

const UserList: FC = () => {
  const userDatas = useUsersListFromAdmin(['userList_admin']);

  // 年代でソート
  const ageArray = userDatas.slice().sort((a, b) => {
    const ageA = a['custom:ages'];
    const ageB = b['custom:ages'];

    return ageA < ageB ? 1 : ageA > ageB ? -1 : 0;
  });

  // 地域でソート

  const addressArray: UserAttributes[] = [];

  const addressSort = () =>
    PrefectureArray.map((pre) => {
      userDatas.findIndex((ele) => {
        if (ele.address === pre.label) {
          addressArray.push(ele);
        }
      });
    });

  addressSort();

  // ソートの条件分岐
  const [sortData, setSortData] = useState(ageArray);

  const changeSort = (data: ChangeEvent<HTMLSelectElement>) => {
    data.target.value === 'ageArray'
      ? setSortData(ageArray)
      : setSortData(addressArray);
  };

  const [sortUpDown, setSortUpDown] = useState(true);

  const reverseSort = () => {
    setSortData(sortData.reverse());
    setSortUpDown(!sortUpDown);
  };

  const resetReverse = () => {
    setSortUpDown(true);
  };

  const [selectedNum, setSelectedNum] = useState(0);

  const paginationData = PaginationFunc(sortData, selectedNum);

  return (
    <>
      <AdminNav />
      <div className="adminUserList">
        <div className="adminUserListWrapper">
          <h1>遺言者管理</h1>
          <div>
            <p>遺言者一覧</p>
            <div className="admin-user-list-sort">
              <select
                onChange={(e) => {
                  changeSort(e);
                  resetReverse();
                }}
              >
                <option value="ageArray">年代</option>
                <option value="addressArray">地域</option>
              </select>
              <button
                className={sortUpDown === true ? 'user-sort-down-button' : ''}
                onClick={reverseSort}
                type="button"
              >
                {sortUpDown === true ? '降順' : '昇順'}
              </button>
            </div>
          </div>
          <div className="adminUserListCards">
            {paginationData.map((UserData) => (
              <Link
                className="adminUserListCard"
                to="/admin/user/detail"
                key={`adminUserList_${UserData.sub}`}
                state={{
                  email: UserData.email,
                  gender: UserData.gender,
                  age: UserData['custom:ages'],
                  address: UserData.address,
                  sub: UserData.sub,
                }}
              >
                <p>{UserData.email}</p>
                <p>性別 : {UserData.gender}</p>
                <p>年代 : {UserData['custom:ages']}</p>
                <p>地域 : {UserData.address}</p>
              </Link>
            ))}
          </div>
        </div>
        <PaginationLayout
          arrayLength={sortData.length}
          setSelectedNum={setSelectedNum}
          selectedNum={selectedNum}
        />
      </div>
      <div className="news-message-btn">
        <Link to="/admin/user/newsMessage">お知らせ配信</Link>
      </div>
    </>
  );
};

export default UserList;
