/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-console */
/* eslint-disable no-alert */
import { FC, useState, useRef, useEffect, ChangeEvent } from 'react';
import { Prefecture, PrefectureArray } from '../data/prefecture';
import minusBtn from '../images/minusBtn.svg';
import InputItem from '../atoms/InputItem';
import './realEstateFormCard.css';
import '../molecules/bottomFormCard.css';
import '../molecules/checkBox.css';
import '../atoms/checkTxt';

export type RealEstateContent = {
  id: string;
  subNum: number;
  type: string;
  address: string;
};

type City = {
  prefCode: string;
  cityCode: string;
  cityName: string;
  bigCityFlag: string;
};

type Props = {
  title: string;
  form: string;
  minusBtnView: boolean;
  subNum: number;
  realEstateCheck: string;
  prefectureSelected: string;
  citySelected: string;
  houseNumberValue: string;
  realEstateTypeCheck: boolean;
  keyValue: string;
  prefecturekeyValue: string;
  updateRealEstateContent: (
    subjectKey: string,
    updateData: string,
    target: string,
  ) => void;
  subjectId: string;
  deleteCard: (deleteSubject: string) => void;
};

const RealEstateFormCard: FC<Props> = (props) => {
  const {
    title,
    form,
    minusBtnView,
    subNum,
    realEstateCheck,
    prefectureSelected,
    citySelected,
    houseNumberValue,
    realEstateTypeCheck,
    prefecturekeyValue,
    keyValue,
    updateRealEstateContent,
    subjectId,
    deleteCard,
  } = props;

  const scroll = () => {
    window.scrollBy({
      top: 100,
      behavior: 'smooth',
    });
  };

  const prefectureRef = useRef<HTMLSelectElement>(null);
  const citiesRef = useRef<HTMLSelectElement>(null);
  const addressRef = useRef<HTMLInputElement>(null);

  const updateAddressData = () => {
    const prefectureRefValue = prefectureRef.current
      ? prefectureRef.current.value
      : '';
    const citiesRefValue = citiesRef.current ? citiesRef.current.value : '';
    const addressRefValue = addressRef.current ? addressRef.current.value : '';
    const total = `${prefectureRefValue} ${citiesRefValue} ${addressRefValue}`;
    updateRealEstateContent(subjectId, total, 'address');
  };

  const deleteCardFunc = () => {
    deleteCard(subjectId);
  };

  const [openCard, setOpenCard] = useState(realEstateTypeCheck);

  const [city, setCity] = useState<City[]>([]);
  const getCity = async () => {
    // const getSelect = document.getElementById('prefecture') as HTMLInputElement;
    const selectLabel = prefectureRef.current
      ? prefectureRef.current.value
      : '';

    const selectArr = PrefectureArray.find((ele) => ele.label === selectLabel);
    const selectId = selectArr ? selectArr.id : 1;

    console.info(selectLabel);
    await fetch(
      `https://opendata.resas-portal.go.jp/api/v1/cities?prefCode=${selectId}`,
      {
        headers: { 'X-API-KEY': 'yvet69MOZSiyzRoinuvNJaWjcXaqhoP118HTqciA' },
      },
    )
      .then((response) => response.json())
      .then((cityData) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        setCity(cityData.result);
      });
  };

  useEffect(() => {
    const selectLabel = prefectureRef.current
      ? prefectureRef.current.value
      : prefectureSelected;

    const selectArr = PrefectureArray.find((ele) => ele.label === selectLabel);
    const selectId = selectArr ? selectArr.id : 1000;

    void fetch(
      `https://opendata.resas-portal.go.jp/api/v1/cities?prefCode=${selectId}`,
      {
        headers: { 'X-API-KEY': 'yvet69MOZSiyzRoinuvNJaWjcXaqhoP118HTqciA' },
      },
    )
      .then((response) => response.json())
      .then((citiesDataEle) => {
        setCity(citiesDataEle.result);
      });
  }, [prefectureSelected]);

  const btnInfos = [
    {
      id: 'checkbox1',
      btnName: '土地',
    },
    {
      id: 'checkbox2',
      btnName: '建物戸建て',
    },
    {
      id: 'checkbox3',
      btnName: '建物マンション（１棟）',
    },
    {
      id: 'checkbox4',
      btnName: '建物マンション（部屋）',
    },
  ];

  const [check1, setCheck1] = useState(realEstateCheck === '土地');
  const [check2, setCheck2] = useState(realEstateCheck === '建物戸建て');
  const [check3, setCheck3] = useState(
    realEstateCheck === '建物マンション（１棟）',
  );
  const [check4, setCheck4] = useState(
    realEstateCheck === '建物マンション（部屋）',
  );

  const checkStateArr = [check1, check2, check3, check4];
  console.log(checkStateArr);
  const checkSetStateArr = [setCheck1, setCheck2, setCheck3, setCheck4];

  const handleCheck = (index: number) => {
    checkSetStateArr[index](true);
  };

  const [realEstateType, setRealEstateType] = useState<string>('');
  const updateTypeData = (e: ChangeEvent<HTMLInputElement>) => {
    setRealEstateType(e.target.value);
    updateRealEstateContent(subjectId, e.target.value, 'type');
  };

  return (
    <div className="realEstateForm">
      <div>
        <p>
          {title}
          {subNum}
        </p>
        <button
          type="button"
          onClick={deleteCardFunc}
          className={minusBtnView ? 'realEstateopen' : 'realEstateclose'}
        >
          <div>
            <img src={minusBtn} alt="minus-Button" />
            <p>削除</p>
          </div>
        </button>
      </div>
      <div className="checkbox-container">
        <div className="checkBox-wrapper">
          <p className="checkBoxTitle">所有しているものを選択してください。</p>
          <div className="checkBox">
            {btnInfos.map((btnInfo, index) => (
              <div className="checktxt-switch" key={`btnInfos${index}`}>
                <input
                  type="radio"
                  key={`check${index}${keyValue}`}
                  id={`radio${index}${keyValue}`}
                  name={`realEstate${keyValue}`}
                  value={btnInfo.btnName}
                  onClick={() => {
                    setOpenCard(true);
                    handleCheck(index);
                  }}
                  checked={
                    realEstateType !== ''
                      ? realEstateType === btnInfo.btnName
                      : realEstateCheck === btnInfo.btnName
                  }
                  onChange={updateTypeData}
                />
                <label htmlFor={`radio${index}${keyValue}`} id="txt-button1">
                  {btnInfo.btnName}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="bottomForm">
          <div
            className={`bottomFormArea ${
              openCard ? 'realEstateBottomopen' : 'realEstateBottomclose'
            }`}
          >
            <p>
              所在地情報を選択してください。
              <br className="sp-only" />
              都道府県だけでも大丈夫です！
            </p>
            <div>
              <label htmlFor="prefecture">
                <InputItem title="都道府県" name="require" />
                <select
                  id="prefecture"
                  name="prefecture"
                  ref={prefectureRef}
                  onChange={async () => {
                    await getCity();
                    updateAddressData();
                  }}
                  onBlur={scroll}
                  defaultValue={prefectureSelected}
                >
                  <option value="" selected hidden>
                    都道府県を選択
                  </option>
                  {PrefectureArray.map((prefectureEle: Prefecture) => (
                    <option
                      value={prefectureEle.label}
                      key={`realEstate${prefectureEle.label}${prefecturekeyValue}`}
                    >
                      {prefectureEle.label}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div>
              <label htmlFor="cities">
                <InputItem title="市区町村" name="optional" />
                <select
                  name="cities"
                  id="cities"
                  ref={citiesRef}
                  onChange={updateAddressData}
                  onBlur={scroll}
                >
                  <option value="" selected hidden>
                    市区町村を選択
                  </option>
                  {city.map((ele) => (
                    <option
                      value={ele.cityName}
                      key={`realEstate${ele.cityCode}${keyValue}`}
                      selected={citySelected === ele.cityName}
                    >
                      {ele.cityName}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div>
              <label htmlFor={`address_${form}`}>
                <InputItem title="町名番地" name="optional" />
                <input
                  type="text"
                  id={`address_${form}`}
                  name="address"
                  placeholder="〇〇町〇丁目〇番地"
                  ref={addressRef}
                  onBlur={scroll}
                  onChange={updateAddressData}
                  defaultValue={houseNumberValue}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealEstateFormCard;
