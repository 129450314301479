/* eslint-disable no-nested-ternary */
import { FC, ChangeEvent } from 'react';
import minusBtn from '../images/minusBtn.svg';
import InputItem from '../atoms/InputItem';
import './stockFormCard.css';

type Props = {
  title: string;
  form: string;
  minusBtnView: boolean;
  subNum: number;
  stockValue: string;
  customerNumberValue: string;
  updateStockContent: (
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement>,
    target: string,
  ) => void;
  subjectId: string;
  deleteCard: (deleteSubject: string) => void;
};

const StockFormCard: FC<Props> = (props) => {
  const {
    title,
    form,
    minusBtnView,
    subNum,
    stockValue,
    customerNumberValue,
    updateStockContent,
    subjectId,
    deleteCard,
  } = props;

  const scroll = () => {
    window.scrollBy({
      top: 100,
      behavior: 'smooth',
    });
  };

  const updateStockData = (e: ChangeEvent<HTMLInputElement>) => {
    updateStockContent(subjectId, e, 'name');
  };

  const updateCustomerNumberData = (e: ChangeEvent<HTMLInputElement>) => {
    updateStockContent(subjectId, e, 'customerNumber');
  };

  const deleteCardFunc = () => {
    deleteCard(subjectId);
  };

  return (
    <div className="stockForm">
      <div>
        <p>
          {title}
          {subNum}
        </p>
        <button
          type="button"
          onClick={deleteCardFunc}
          className={minusBtnView ? 'stockopen' : 'stockclose'}
        >
          <div>
            <img src={minusBtn} alt="minus-Button" />
            <p>削除</p>
          </div>
        </button>
      </div>
      <div>
        <div>
          <label htmlFor={`stock_${form}`}>
            <InputItem title="証券会社名" name="require" />
            <input
              type="text"
              id={`stock_${form}`}
              key={`stock_${form}`}
              name="stock"
              placeholder="入力してください"
              defaultValue={stockValue}
              onChange={updateStockData}
              onBlur={scroll}
            />
          </label>
          <p>架空の名前で構いません</p>
        </div>
        <div>
          <label htmlFor={`customerNumber_${form}`}>
            <InputItem title="顧客番号" name="optional" />
            <input
              type="text"
              id={`customerNumber_${form}`}
              key={`customerNumber_${form}`}
              name="customerNumber"
              placeholder="入力してください"
              onChange={updateCustomerNumberData}
              onBlur={scroll}
              defaultValue={customerNumberValue}
              autoComplete="off"
            />
          </label>
        </div>
        <div>
          <label htmlFor={`accountNumber_${form}`}>
            <InputItem title="口座番号" name="blank" />
            <input
              type="number"
              id={`accountNumber_${form}`}
              name={`accountNumber_${form}`}
              placeholder=""
              readOnly
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default StockFormCard;
