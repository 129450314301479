const PaginationData = <T>(
  array: T[],
  selectedNum: number,
  dataPerPage?: number,
): T[] => {
  const pagiNationArray = dataPerPage
    ? array.slice(
        selectedNum * dataPerPage,
        selectedNum * dataPerPage + dataPerPage,
      )
    : array.slice(selectedNum * 10, selectedNum * 10 + 10);

  return pagiNationArray;
};

export default PaginationData;
