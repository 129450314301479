import { FC, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { User } from 'domains/apigateway/services/get-user';
import Spinner from 'components/molecules/Spinner';
import ExecutoTextHeader from '../organisms/executorTextHeader';
import WillChargeContent from '../organisms/willChargeContent';
import ExecutorLogoHeader from '../../../common/apply/organisms/executorLogoHeader';
import CommonFooter from '../../../common/apply/templates/commonFooter';

type State = {
  client: User;
};

const WillCharge: FC = () => {
  const location = useLocation();
  const { client } = location.state as State;

  return (
    <>
      <ExecutorLogoHeader />
      <ExecutoTextHeader title="担当遺言者の詳細" />
      <Suspense fallback={<Spinner size="large" />}>
        <WillChargeContent client={client} />
      </Suspense>
      <CommonFooter />
    </>
  );
};

export default WillCharge;
