/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import Slider from 'react-slick';
import pageData from '../data/pageData';
import PageDescription from '../organisms/pageDescription';
import PropertyAllocationBack from '../images/PropertyAllocationBack.svg';
import './PropertyAllocationDescription.css';

const PropertyAllocationDescription: FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplayspeed: 200,
    arrows: false,
  };

  return (
    <div className="PropertyAllocationDescription">
      <Slider {...settings}>
        <PageDescription
          page="realEstatePage"
          description={pageData[20].description}
          NotParamDescription="NotParamDescription"
        />
      </Slider>
      <img src={PropertyAllocationBack} alt="消極財産負債背景画像" />
    </div>
  );
};

export default PropertyAllocationDescription;
