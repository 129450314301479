import { FC, useState, useRef, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { GetUser, ChangeEmail } from 'domains/cognite/services/manageUser';
import NavigateBtn from 'common/components/atoms/navigateBtn';
import { logger } from 'utils/logger';

import './changeMailContent.css';

const ChangeMailContent: FC = () => {
  const { data: executor } = useQuery(['current_auth_executor_email'], GetUser);
  const navigate = useNavigate();
  const emailRef = useRef<HTMLInputElement>(null);
  const [alertEmail, setAlertEmail] = useState(false);
  const emailAlertMessage = alertEmail ? 'alertMessage' : '';

  if (!executor) {
    return <></>;
  }

  const changeEmail = async (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!emailRef.current?.value) {
      setAlertEmail(true);
    } else {
      try {
        if (emailRef.current && emailRef.current.value) {
          const email = emailRef.current.value;
          await ChangeEmail(emailRef.current.value).then(() => {
            logger.info('メールアドレス変更成功');
            navigate('/executor/email/confirm', { state: { email } });
          });
        }
      } catch (error) {
        logger.error('メールアドレス変更失敗');
      }
    }
  };

  return (
    <div className="changeMail">
      <div>
        <p>
          新しいメールアドレスを
          <br className="sp-only" />
          入力してください。
          <br />
          手続きの案内を送ります。
        </p>
        <div>
          <div>
            <p>メールアドレス</p>
            <p>{executor.attributes.email}</p>
          </div>
          <form className="changeMailForm">
            <div>
              <label htmlFor="mail">
                <p>新しいメールアドレス</p>
                <input
                  type="text"
                  id="mail"
                  ref={emailRef}
                  placeholder="メールアドレスを入力"
                />
              </label>
              <p className={`deleteMessage ${emailAlertMessage}`}>
                メールアドレスが入力されていません
              </p>
            </div>
            <input type="submit" value="送信する" onClick={changeEmail} />
          </form>
          <NavigateBtn />
          {/* <input type="submit" value="送信する" /> */}
        </div>
      </div>
    </div>
  );
};

export default ChangeMailContent;
