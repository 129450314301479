import { FC, Suspense } from 'react';
import Spinner from 'components/molecules/Spinner';
import ExecutoTextHeader from '../organisms/executorTextHeader';
import ChangeMailContent from '../organisms/changeMailContent';
import ExecutorLogoHeader from '../../../common/apply/organisms/executorLogoHeader';
import CommonFooter from '../../../common/apply/templates/commonFooter';

const ChangeMail: FC = () => (
  <>
    <ExecutorLogoHeader />
    <ExecutoTextHeader title="メールアドレスの変更" />
    <Suspense fallback={<Spinner size="large" />}>
      <ChangeMailContent />
    </Suspense>
    <CommonFooter />
  </>
);

export default ChangeMail;
