import { FC } from 'react';
import UserLogoHeader from '../organisms/userLogoHeader';
import { WillQuestionData } from '../data/willQuestionData';
import QuestionCard from '../organisms/questionCard';
import CommonFooter from '../../apply/templates/commonFooter';
import NavigateBtn from '../../../user/components/atoms/navigateBtn';
import './willQuestion.css';

const WillQuestion: FC = () => (
  <>
    <UserLogoHeader />
    <div className="faq">
      <p>よくあるご質問</p>
      {WillQuestionData.map((data) => (
        <QuestionCard
          questionNumber={data.questionNumber}
          question={data.question}
          answer={data.answer}
          key={data.questionNumber}
        />
      ))}
      <NavigateBtn />
    </div>
    <CommonFooter />
  </>
);

export default WillQuestion;
