import { Paragraph, TextRun } from 'docx';
import { Deposits, DepositsContent } from 'models';
import nonNullable from 'utils/nonNullable';
import circledNumbers from 'user/components/data/circledNumbers';

const depositsView = (
  deposits: Deposits | null,
  depositsContent: DepositsContent[],
): Paragraph[] => {
  if (deposits && !!depositsContent.length) {
    const depositsContentParagraph = depositsContent
      .filter(nonNullable)
      .map((ele) => [
        new Paragraph({
          text: `${circledNumbers(ele.subNum) || 0} ${ele.bankName || ''} ${
            ele.branchName || ''
          }`,
          style: 'subItem',
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: `${ele.accountType || ''}口座`,
            }),
            new TextRun({
              text: '口座番号    ○○○○',
              break: 1,
            }),
            new TextRun({
              text: `${ele.accountHolderName || ''}`,
              break: 1,
            }),
          ],
          style: 'GrandChild',
        }),
      ])
      .flat();

    return [
      new Paragraph({
        text: `${deposits.majorNum || 0}. 預貯金`,
        style: 'Default',
      }),
      ...depositsContentParagraph,
      new Paragraph({ style: 'Default' }),
    ];
  }

  return [];
};

export default depositsView;
