/* eslint-disable react/require-default-props */
import { FC } from 'react';
// import { Link } from 'react-router-dom';
import './radioCustomSingle.css';

type Props = {
  title: string;
  name: string;
  // eslint-disable-next-line react/require-default-props
  yesFunc?: () => void;
  // href: string;
  // func: () => void;
  yesCheck?: boolean;
  noFunc?: () => void;
  noCheck?: boolean;
  lineBreakTitle?: string;
};

const RadioCustomSingle: FC<Props> = (props) => {
  // const { title, name, yesFunc, href, func, yesCheck, noCheck } = props;
  const { title, name, yesFunc, yesCheck, noCheck, noFunc, lineBreakTitle } =
    props;
  // const handleChange = (e: SyntheticEvent) => setValue(e.target.value);
  const scroll = () => {
    window.scrollBy({
      top: 150,
      behavior: 'smooth',
    });
  };

  return (
    <div className="radioCustomSingle">
      <p className="yesno-title">{title}</p>
      <p className="yesno-title">{lineBreakTitle}</p>
      <div className="yesnosingle">
        <input
          type="radio"
          id={`${name}1`}
          name={name}
          value="はい"
          onChange={yesFunc}
          onClick={scroll}
          defaultChecked={!!yesCheck}
        />
        <label htmlFor={`${name}1`} id="yes">
          はい{}
        </label>
        <input
          type="radio"
          id={`${name}2`}
          name={name}
          value="いいえ"
          onChange={noFunc}
          defaultChecked={!!noCheck}
        />
        <label htmlFor={`${name}2`} id="no">
          いいえ{}
        </label>
        {/* <Link to={href} className="noLink" onClick={func} defaultChecked={!!noCheck} >
          いいえ
        </Link> */}
      </div>
    </div>
  );
};

export default RadioCustomSingle;
