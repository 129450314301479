import { FC } from 'react';
import HeaderLogo from '../images/headerLogo.svg';
import FooterMenuBeforeLoginContent from '../organisms/footerMenuBeforeLoginContent';
import minusBtn from '../images/minusBtn.svg';
import './footerMenuBeforeLogin.css';

type Props = {
  show: boolean;
  setShow: () => void;
  homeLink: string;
};

const FooterMenuBeforeLogin: FC<Props> = (props) => {
  const { show, setShow, homeLink } = props;

  if (show) {
    return (
      <div id="footerBeforeLoginOverlay" className="footerMenuBeforeLogin">
        <div>
          <img src={HeaderLogo} alt="" />
        </div>
        <FooterMenuBeforeLoginContent setShow={setShow} homeLink={homeLink} />
        <button type="button" onClick={() => setShow()}>
          <img src={minusBtn} alt="minus-Button" />
          <p>閉じる</p>
        </button>
      </div>
    );
  }

  return null;
};

export default FooterMenuBeforeLogin;
