/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import Slider from 'react-slick';
import { Properties } from 'models';
import pageData from '../data/pageData';
import PageDescription from '../organisms/pageDescription';
import ParamCheckmark from '../atoms/paramCheckmarkSp';
import BondsBack from '../images/BondsBack.svg';
import './bondsDescription.css';

type Props = {
  data: Properties | undefined;
};

const BondsDescription: FC<Props> = (props) => {
  const { data } = props;
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  return (
    <div className="BondsDescription">
      <div>
        <div className="BondsParam">
          <ParamCheckmark
            checkMarkSize6
            key="bondsDescription"
            propertyData={data}
          />
        </div>
        <Slider {...settings}>
          <PageDescription
            page="realEstatePage"
            description={pageData[11].description}
          />
        </Slider>
      </div>
      <img src={BondsBack} alt="相続財産【債権】背景画像" />
    </div>
  );
};

export default BondsDescription;
