/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import { FC, ChangeEvent } from 'react';
import minusBtn from '../images/minusBtn.svg';
import InputItem from '../atoms/InputItem';
import './liabilitiesFormCard.css';

type Props = {
  title: string;
  form: string;
  minusBtnView: boolean;
  subNum: number;
  nameValue: string;
  initialAmountValue: number | string;
  initialDateValue: string;
  guarantorValue: string;
  updateLiabilitiesContent: (
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement>,
    target: string,
  ) => void;
  subjectId: string;
  deleteCard: (deleteSubject: string) => void;
};

const LiabilitiesFormCard: FC<Props> = (props) => {
  const {
    title,
    form,
    minusBtnView,
    subNum,
    nameValue,
    initialAmountValue,
    initialDateValue,
    guarantorValue,
    updateLiabilitiesContent,
    subjectId,
    deleteCard,
  } = props;

  const scroll = () => {
    window.scrollBy({
      top: 100,
      behavior: 'smooth',
    });
  };

  const updateNameData = (e: ChangeEvent<HTMLInputElement>) => {
    updateLiabilitiesContent(subjectId, e, 'name');
  };

  const updateInitialAmountData = (e: ChangeEvent<HTMLInputElement>) => {
    updateLiabilitiesContent(subjectId, e, 'initialAmount');
  };

  const updateInitialDateData = (e: ChangeEvent<HTMLInputElement>) => {
    updateLiabilitiesContent(subjectId, e, 'initialDate');
  };

  const updateGuarantorData = (e: ChangeEvent<HTMLInputElement>) => {
    updateLiabilitiesContent(subjectId, e, 'guarantor');
  };

  const deleteCardFunc = () => {
    deleteCard(subjectId);
  };

  return (
    <div className="liabilitiesForm">
      <div>
        <p>
          {title}
          {subNum}
        </p>
        <button
          type="button"
          onClick={deleteCardFunc}
          className={minusBtnView ? 'liabilitiesopen' : 'liabilitiesclose'}
        >
          <div>
            <img src={minusBtn} alt="minus-Button" />
            <p>削除</p>
          </div>
        </button>
      </div>
      <div>
        <div>
          <label htmlFor={`name_${form}`}>
            <InputItem title="貸主名" name="require" />
            <input
              type="text"
              id={`name_${form}`}
              key={`name_${form}`}
              name="name"
              placeholder="入力してください"
              onBlur={scroll}
              defaultValue={nameValue}
              onChange={updateNameData}
            />
          </label>
          <p>架空名義で構いません</p>
        </div>
        <div>
          <label htmlFor={`initialAmount_${form}`}>
            <InputItem title="当初借入金額" name="require" />
            <input
              type="text"
              id={`initialAmount_${form}`}
              key={`initialAmount_${form}`}
              name="initialAmount"
              placeholder="1,000,000"
              onBlur={scroll}
              defaultValue={initialAmountValue}
              onChange={updateInitialAmountData}
            />
            円
          </label>
        </div>
        <div>
          <label htmlFor={`initialDate_${form}`}>
            <InputItem title="当初借入年月日" name="optional" />
            <input
              type="date"
              id={`initialDate_${form}`}
              key={`initialDate_${form}`}
              name="initialDate"
              onBlur={scroll}
              defaultValue={initialDateValue}
              onChange={updateInitialDateData}
            />
          </label>
        </div>
        <div>
          <label htmlFor={`guarantor_${form}`}>
            <InputItem title="保証人" name="optional" />
            <input
              type="text"
              id={`guarantor_${form}`}
              key={`guarantor_${form}`}
              name="guarantor"
              placeholder="入力してください"
              onBlur={scroll}
              defaultValue={guarantorValue}
              onChange={updateGuarantorData}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default LiabilitiesFormCard;
