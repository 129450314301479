/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-alert */
import { FC, useState, useRef, ChangeEvent, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { logger } from 'utils/logger';
import PsignUp from 'common/components/signUp';

type Props = {
  saveEmail: (email: string) => void;
};

const SignUp: FC<Props> = (props) => {
  const { saveEmail } = props;
  const navigate = useNavigate();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const passwordComfirmRef = useRef<HTMLInputElement>(null);
  const [gender, setGender] = useState('男性');
  const prefectureRef = useRef<HTMLSelectElement>(null);
  const ageRef = useRef<HTMLSelectElement>(null);

  // const [inputValid, setInputValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);

  // const inputValidClassName = inputValid ? 'validSuccess' : 'validFailed';
  const passwordValidClassName = passwordValid ? 'validSuccess' : 'validFailed';
  const [refValueArray, setRefValueArray] = useState<boolean[]>([]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGender(e.target.value);
  };

  const [errorValue, setErrorValue] = useState('');
  const [PolicyBtn, setPolicyBtn] = useState(false);
  const [PrivacyPolicyBtn, setPrivacyPolicyBtn] = useState(false);
  const changePolicyBtn = () => {
    setPolicyBtn(!PolicyBtn);
  };
  const changePrivacyPolicyBtn = () => {
    setPrivacyPolicyBtn(!PrivacyPolicyBtn);
  };

  const passwordLengthValid =
    'Password did not conform with policy: Password not long enough';
  const emailAlreadyExists = 'An account with the given email already exists.';
  const emailNotFormat = 'Invalid email address format.';

  const [emailDuplicate, setEmailDuplicate] = useState(false);

  const emailDuplicateCheck = async (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    try {
      if (emailRef.current && emailRef.current.value) {
        await Auth.signUp({
          username: emailRef.current.value,
          password: 'xxxx',
          attributes: {
            email: emailRef.current.value,
            address: '',
            gender,
            'custom:ages': '',
          },
        })
          .then(() => {
            setEmailDuplicate(false);
          })
          .catch((error) => {
            if (error.message !== emailAlreadyExists) {
              setEmailDuplicate(false);
            } else {
              setEmailDuplicate(true);
            }
          });
      }
    } catch (error) {
      logger.info('メールアドレスチェック失敗');
    }
  };

  const signUp = async (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    const refValueData: (string | undefined)[] = [
      emailRef.current?.value,
      passwordRef.current?.value,
      passwordComfirmRef.current?.value,
      prefectureRef.current?.value,
      ageRef.current?.value,
    ];

    const refValueDataArray: boolean[] = refValueData.map((ele) =>
      ele === '' ? false : true,
    );
    setRefValueArray(refValueDataArray);

    try {
      if (
        emailRef.current &&
        emailRef.current.value &&
        passwordRef.current &&
        passwordRef.current.value &&
        passwordComfirmRef.current &&
        passwordComfirmRef.current.value &&
        prefectureRef.current &&
        prefectureRef.current.value &&
        ageRef.current &&
        ageRef.current.value
      ) {
        if (passwordRef.current.value === passwordComfirmRef.current.value) {
          saveEmail(emailRef.current.value);
          await Auth.signUp({
            username: emailRef.current.value,
            password: passwordRef.current.value,
            attributes: {
              email: emailRef.current.value,
              address: prefectureRef.current.value,
              gender,
              'custom:ages': ageRef.current.value,
            },
          })
            .then(() => {
              setPasswordValid(true);
              navigate('/signUp/confirm', {
                state: {
                  Ref: emailRef.current?.value,
                  Pass: passwordRef.current?.value,
                },
              });
            })
            .catch((error) => {
              if (error instanceof Error) {
                setErrorValue(error.message);
              }
              window.alert('入力漏れ、または入力内容に誤りがあります。');
            });
        } else {
          setPasswordValid(false);
          logger.info('サインアップ失敗3');
          window.alert('入力漏れ、または入力内容に誤りがあります。');
        }
      } else {
        logger.info('サインアップ失敗2');
        window.alert('入力漏れ、または入力内容に誤りがあります。');
      }
    } catch (error) {
      logger.info('サインアップ失敗1');
      logger.info('error signing in', error);
    }
  };

  const signUpProps = {
    // inputValidClassName,
    passwordValidClassName,
    emailRef,
    passwordRef,
    passwordComfirmRef,
    gender,
    prefectureRef,
    ageRef,
    handleChange,
    signUp,
    refValueArray,
    errorValue,
    passwordLengthValid,
    emailAlreadyExists,
    emailNotFormat,
    changePolicyBtn,
    PolicyBtn,
    changePrivacyPolicyBtn,
    PrivacyPolicyBtn,
    emailDuplicate,
    emailDuplicateCheck,
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <PsignUp {...signUpProps} />;
};

export default SignUp;
