/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC, useState, useRef, ChangeEvent, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
// import { useSync } from 'hooks';
import { GetUser, UpdateUser } from 'domains/cognite/services/manageUser';
import { logger } from 'utils/logger';
import { PrefectureArray, Prefecture } from 'user/components/data/prefecture';
import UserLogoHeader from '../organisms/userLogoHeader';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import NavigateBtn from '../atoms/navigateBtn';
import './userProfile.css';

const UserProfile: FC = () => {
  // useSync();
  const navigate = useNavigate();
  const { data: user, isFetching } = useQuery(
    ['current_auth_user_profile'],
    GetUser,
  );
  const attributes = user ? user.attributes : undefined;
  const [gender, setGender] = useState(
    attributes?.gender ? attributes?.gender : '男性',
  );
  const prefectureRef = useRef<HTMLSelectElement>(null);
  const ageRef = useRef<HTMLSelectElement>(null);
  const [refValueArray, setRefValueArray] = useState<boolean[]>([]);

  if (!user) {
    return <></>;
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGender(e.target.value);
  };

  const chengeProfile = async (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    const refValueData: (string | undefined)[] = [
      prefectureRef.current?.value,
      ageRef.current?.value,
    ];

    const refValueDataArray: boolean[] = refValueData.map((ele) =>
      ele === '' ? false : true,
    );
    setRefValueArray(refValueDataArray);

    try {
      if (
        prefectureRef.current &&
        prefectureRef.current.value &&
        ageRef.current &&
        ageRef.current.value
      ) {
        const instance = {
          ...user.attributes,
          gender,
          address: prefectureRef.current.value,
          'custom:ages': ageRef.current.value,
        };
        await UpdateUser(instance).then(() => {
          logger.info('プロフィール変更成功');
          navigate('/user');
        });
      }
    } catch (error) {
      logger.error('プロフィール変更失敗');
    }
  };

  return isFetching ? (
    <p>Loading...</p>
  ) : (
    <>
      <UserLogoHeader />
      <div className="UserProfile">
        <h1>プロフィールの編集</h1>
        <form className="UserProfileForm">
          <div>
            <div className="UserProfileWrapper">
              <p>性別</p>
              <div className="ProfileMenAndWomen">
                <input
                  type="radio"
                  id="man"
                  value="男性"
                  name="gender"
                  defaultChecked={gender === '男性'}
                  onChange={handleChange}
                />
                <label htmlFor="man">男性</label>
                <input
                  type="radio"
                  id="woman"
                  value="女性"
                  name="gender"
                  defaultChecked={gender === '女性'}
                  onChange={handleChange}
                />
                <label htmlFor="woman">女性</label>
              </div>
            </div>
            <div className="prefectureFormWrapper">
              <div>
                <p>住所</p>
                <label htmlFor="prefecture" className="prefectureForm">
                  <select
                    name="prefecture"
                    id="prefecture"
                    ref={prefectureRef}
                    defaultValue={attributes?.address}
                  >
                    <option value="" disabled selected hidden>
                      都道府県を選択
                    </option>
                    {PrefectureArray.map((prefectureEle: Prefecture) => (
                      <option value={prefectureEle.label}>
                        {prefectureEle.label}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              {refValueArray[0] === false ? (
                <p className="alertMessage">都道府県が選択されていません</p>
              ) : (
                ''
              )}
            </div>
            <div className="prefectureFormWrapper">
              <div>
                <p>年代</p>
                <label htmlFor="age" className="ageForm">
                  <select
                    name="age"
                    id="age"
                    ref={ageRef}
                    defaultValue={attributes?.['custom:ages']}
                  >
                    <option value="" disabled selected hidden>
                      年代を選択
                    </option>
                    <option value="10代">10代</option>
                    <option value="20代">20代</option>
                    <option value="30代">30代</option>
                    <option value="40代">40代</option>
                    <option value="50代">50代</option>
                    <option value="60代">60代</option>
                    <option value="70代">70代</option>
                    <option value="80代">80代</option>
                    <option value="90代">90代</option>
                  </select>
                </label>
              </div>
              {refValueArray[1] === false ? (
                <p className="alertMessage">年代が選択されていません</p>
              ) : (
                ''
              )}
            </div>
            <input type="submit" value="変更する" onClick={chengeProfile} />
          </div>
        </form>
        <NavigateBtn />
        <CommonFooter />
      </div>
    </>
  );
};

export default UserProfile;
