import { Paragraph, TextRun } from 'docx';
import { RealEstate, RealEstateContent } from 'models';
import nonNullable from 'utils/nonNullable';
import circledNumbers from 'user/components/data/circledNumbers';

const realEstateView = (
  realEstate: RealEstate | null,
  realEstateContent: RealEstateContent[],
): Paragraph[] => {
  if (realEstate && !!realEstateContent.length) {
    const realEstateContentParagraph = realEstateContent
      .filter(nonNullable)
      .map((ele) => [
        new Paragraph({
          text: `${circledNumbers(ele.subNum) || 0} ${ele.type || ''}`,
          style: 'subItem',
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: `所在    ${ele.address || ''}`,
            }),
          ],
          style: 'GrandChild',
        }),
      ])
      .flat();

    return [
      new Paragraph({
        text: `${realEstate.majorNum || 0}. 不動産`,
        style: 'Default',
      }),
      ...realEstateContentParagraph,
      new Paragraph({ style: 'Default' }),
    ];
  }

  return [];
};

export default realEstateView;
