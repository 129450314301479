/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import Linkify from 'linkify-react';
import './topicsViewArea.css';

type Props = {
  updatedAt: string;
  title: string;
  imageUrl: string;
  content: string;
  isDraft: boolean;
};

const TopicsViewArea: FC<Props> = (props) => {
  const { updatedAt, title, imageUrl, content, isDraft } = props;

  const hideDraft = isDraft ? '' : 'topics-view_draft-hide';

  return (
    <>
      <div className="topics-view_top-info">
        <p className={`topics-view_draft ${hideDraft}`}>下書き</p>
        <p className="topics-view_created-at">最終更新日：{updatedAt}</p>
      </div>
      <h2 className="topics-view_title">{title}</h2>
      <img src={imageUrl} alt="" className="topics-view_image" />
      <p className="topics-view_content">
        <Linkify
          options={{
            target: 'target_blank',
            formatHref: (href) =>
              href.includes('）') ? href.replace(/）/g, '') : href,
            render: ({ attributes, content: _content }) => {
              if (_content.includes('）')) {
                return (
                  <>
                    <a {...attributes}>{_content.replace(/）/g, '')}</a>）
                  </>
                );
              }

              return <a {...attributes}>{_content}</a>;
            },
          }}
        >
          {content}
        </Linkify>
      </p>
    </>
  );
};

export default TopicsViewArea;
