/* eslint-disable no-nested-ternary */
import { FC, ChangeEvent } from 'react';
import { AllocationReceiver } from 'models';
import PlusBtn from '../atoms/plusBtn';
import LegalHeirSingleFormCard from '../molecules/legalHeirSingleFormCard';
import LegalHeirFormCard from '../molecules/legalHeirFormCard';
import './legalHeirFormArea.css';

type Props = {
  open: boolean | undefined;
  formType: string;
  formMessage: string;
  legalHeirData: AllocationReceiver[];
  updateLegalHeirNameContent: (
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement>,
    target: string,
  ) => void;
  updateLegalHeirBirthContent: (
    subjectKey: string,
    updateData: string,
    target: string,
  ) => void;
  legalHeirContentData: AllocationReceiver[];
  subjectLegalHeirQuestionId: number;
  addCard: (subjectLegalHeirQuestionId: number) => void;
  deleteCard: (deleteSubject: string) => void;
};

const LegalHeirFormArea: FC<Props> = (props) => {
  const {
    open,
    formType,
    formMessage,
    legalHeirData,
    updateLegalHeirNameContent,
    updateLegalHeirBirthContent,
    legalHeirContentData,
    subjectLegalHeirQuestionId,
    addCard,
    deleteCard,
  } = props;

  // questionIdの一致する法定相続人データのみ抽出
  const legalHeriContentDataMatchQuestionId = legalHeirContentData.filter(
    (ele) => ele.legalHeirQuestionId === subjectLegalHeirQuestionId,
  );

  const addLegalHeirCard = () => {
    addCard(subjectLegalHeirQuestionId);
  };

  return (
    <div
      className={`legalHeirFormArea ${open ? 'legalHeiropen' : 'close'} ${
        !formType ? 'legalHeirFormAreaMulti' : ''
      }`}
    >
      {formType === 'single' ? (
        <div>
          <p>{formMessage}</p>
          <LegalHeirSingleFormCard
            legalHeirData={legalHeirData[0]}
            updateLegalHeirNameContent={updateLegalHeirNameContent}
            updateLegalHeirBirthContent={updateLegalHeirBirthContent}
            subjectId={
              legalHeriContentDataMatchQuestionId[0]
                ? legalHeriContentDataMatchQuestionId[0].id
                : ''
            }
          />
        </div>
      ) : (
        <div>
          <p className="LegalHeirFormCycle">{formMessage}</p>
          {legalHeriContentDataMatchQuestionId.map((ele, index, theForms) => (
            <LegalHeirFormCard
              key={ele.id}
              minusBtnView={theForms.length !== 1}
              legalHeirDataItem={legalHeirData[index]}
              updateLegalHeirNameContent={updateLegalHeirNameContent}
              updateLegalHeirBirthContent={updateLegalHeirBirthContent}
              subjectId={ele.id}
              deleteCard={deleteCard}
            />
          ))}
          <button
            type="button"
            className="plusBtn-wrapper"
            onClick={addLegalHeirCard}
          >
            <PlusBtn />
          </button>
        </div>
      )}
    </div>
  );
};

export default LegalHeirFormArea;
