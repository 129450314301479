import { FC } from 'react';
import { Link } from 'react-router-dom';
import './topicsActionLinks.css';

type Props = {
  prevId: string | undefined;
  nextId: string | undefined;
};

const TopicsActionLinks: FC<Props> = (props) => {
  const { prevId, nextId } = props;

  const availPrev = prevId ? '' : 'disable';
  const availNext = nextId ? '' : 'disable';

  return (
    <div className="topics-action-user">
      <Link to={`/topics/${prevId || ''}`} className={availPrev}>
        前のトピック
      </Link>
      <Link to={`/topics/${nextId || ''}`} className={availNext}>
        次のトピック
      </Link>
      <Link to="/topics/list">トピックス一覧へ</Link>
    </div>
  );
};

export default TopicsActionLinks;
