import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Topics } from 'models';
import { GetS3 } from 'domains/aws/services/get-s3';
import { formatDateToCleanStr } from 'utils/dateFormat';
import sampleImage from '../images/post_sample_image.png';
import './topicsContainerLink.css';

type Props = Topics;

const TopicsContainerLink: FC<Props> = (props) => {
  const { id, title, updatedAt, thumbnail } = props;

  const { data: getImage } = useQuery(['thumbnail', thumbnail], () =>
    GetS3(thumbnail, 'public'),
  );

  const imageUrl = getImage || sampleImage;

  const updatedAtDate = new Date(updatedAt || '');
  const updatedAtFormated = updatedAt
    ? formatDateToCleanStr(updatedAtDate)
    : '';

  return (
    <Link to={`topics/${id}`} className="topics-container-link">
      <div className="topics-container-link_img-wrapper">
        <img src={imageUrl} alt="" className="topics-container-link_image" />
      </div>
      <div className="topics-container-link_text">
        <p>{updatedAtFormated}</p>
        <p>{title}</p>
      </div>
    </Link>
  );
};

export default TopicsContainerLink;
