import { useState } from 'react';
import { useQuery } from 'react-query';
import { Group, GetGroup } from 'domains/cognite/services/manageUser';

type UserAuth = () => {
  isFetching: boolean;
  doQuery: () => void;
  group: Group;
};

const useAuth: UserAuth = () => {
  const [authQuery, setAuthQuery] = useState(false);
  const doQuery = () => setAuthQuery(!authQuery);
  const checkAuthenticated = async () => {
    try {
      const group = await GetGroup();

      return group;
    } catch (error) {
      const group: Group = 'Sign Out';

      return group;
    }
  };

  const { data: group, isFetching } = useQuery(
    ['current_auth_user', authQuery],
    checkAuthenticated,
  );

  if (!group) {
    const signOut: Group = 'Sign Out';

    return {
      isFetching,
      doQuery,
      group: signOut,
    };
  }

  return {
    isFetching,
    doQuery,
    group,
  };
};

export default useAuth;
