import { Paragraph, TextRun } from 'docx';
import { Stock, StockContent } from 'models';
import nonNullable from 'utils/nonNullable';
import circledNumbers from 'user/components/data/circledNumbers';

const stockView = (
  stock: Stock | null,
  stockContent: StockContent[],
): Paragraph[] => {
  if (stock && !!stockContent.length) {
    const stockContentParagraph = stockContent
      .filter(nonNullable)
      .map((ele) => [
        new Paragraph({
          text: `${circledNumbers(ele.subNum) || 0} ${ele.name || ''}`,
          style: 'subItem',
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: `顧客番号    ${ele.customerNumber || ''}`,
            }),
            new TextRun({
              text: '口座番号    ○○○○',
              break: 1,
            }),
          ],
          style: 'GrandChild',
        }),
      ])
      .flat();

    return [
      new Paragraph({
        text: `${stock.majorNum || 0}. 株式・債券`,
        style: 'Default',
      }),
      ...stockContentParagraph,
      new Paragraph({ style: 'Default' }),
    ];
  }

  return [];
};

export default stockView;
