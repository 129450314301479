import { FC } from 'react';
import './questionCard.css';

type Props = {
  questionNumber: number;
  question: string;
  answer: string;
};

const QuestionCard: FC<Props> = (props) => {
  const { questionNumber, question, answer } = props;

  return (
    <div className="question-card">
      <div>
        <p>Q{questionNumber}</p>
        <p>{question}</p>
      </div>
      <div>
        <p>{answer}</p>
      </div>
    </div>
  );
};

export default QuestionCard;
