/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import Slider from 'react-slick';
import pageData from '../data/pageData';
import PageDescription from '../organisms/pageDescription';
import './executorApprovalDescription.css';
import suitWoman1 from '../images/suit-woman1.svg';

const ExecutorApprovalDescription: FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: false,
  };

  return (
    <div className="executorApprovalDescription">
      <div>
        <Slider {...settings}>
          <PageDescription
            page=""
            description={pageData[32].description}
            NotParamDescription="NotParamDescription"
          />
          <PageDescription
            page=""
            description={pageData[33].description}
            NotParamDescription="NotParamDescription"
          />
          <PageDescription
            page=""
            description={pageData[34].description}
            NotParamDescription="NotParamDescription"
          />
        </Slider>
      </div>
      <div>
        <img src={suitWoman1} alt="" />
      </div>
    </div>
  );
};

export default ExecutorApprovalDescription;
