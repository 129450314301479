/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { DataStore } from 'aws-amplify';
import { WillInfo, WillInfoMetaData } from 'models';
import { logger } from 'utils/logger';
import { ModelInit } from '@aws-amplify/datastore';

export const MutateWillInfoAndGetId = async (
  id: string,
  name: string,
  address: string,
  birth: string,
  inputFlag: boolean,
): Promise<string> => {
  try {
    logger.info('遺言者情報の作成または更新を開始します');
    const original = await DataStore.query(WillInfo, id);

    if (original) {
      logger.info(original);
      const data = await DataStore.save(
        WillInfo.copyOf(original, (updated) => {
          updated.name = name;
          updated.address = address;
          updated.birth = birth;
          updated.inputFlag = inputFlag;
        }),
      );
      logger.info(data);
      logger.info('遺言者情報の更新が完了しました');

      return data.id;
    }
    logger.info('遺言者情報の作成を開始します');
    const instance = {
      name,
      address,
      birth,
      inputFlag,
    };
    const data = await DataStore.save(new WillInfo(instance));
    logger.debug(data);
    logger.info('遺言者情報の作成が完了しました');

    return data.id;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const QueryWillInfo = async (): Promise<WillInfo[]> => {
  try {
    const queryWillInfoData = await DataStore.query(WillInfo);

    const queryWillInfoResult = !queryWillInfoData ? [] : queryWillInfoData;

    return queryWillInfoResult;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteWillInfo = async (): Promise<void> => {
  try {
    // logger.info('遺言者情報の全項目の削除を開始します');
    const data = await DataStore.query(WillInfo);
    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);
        }),
      );
    }
    logger.debug(data);
    logger.info('遺言者情報の全項目削除');

    // return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetWillInfo = async (): Promise<WillInfo[]> => {
  try {
    logger.info('遺言者情報の項目のフェッチを開始します');
    const data = await DataStore.query(WillInfo);
    logger.debug(data);
    logger.info('遺言者情報の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const MutateWillInfo = async (
  id: string,
  instance: ModelInit<WillInfo, WillInfoMetaData>,
): Promise<WillInfo> => {
  try {
    logger.info('遺言者情報の作成または更新を開始します');
    const original = await DataStore.query(WillInfo, id);

    if (original) {
      logger.info('遺言者情報の更新を開始します');
      const data = await DataStore.save(
        WillInfo.copyOf(original, (updated) => {
          // ワンチャンここがバグ
          updated.name = instance.name;
          updated.address = instance.address;
          updated.birth = instance.birth;
        }),
      );

      return data;
    }
    logger.info('遺言者情報の作成を開始します');
    const data = await DataStore.save(new WillInfo(instance));

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
