/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { ChangeEvent, FC, useState } from 'react';
import { useUsersListFromAdmin } from 'hooks';
import { Pie } from 'react-chartjs-2';
import { PrefectureArray } from 'user/components/data/prefecture';
import AdminNav from '../organisms/adminNav';
import DataBtns from '../organisms/dataBtns';
import './addressUserData.css';

const AddressUserData: FC = () => {
  const UserDatas = useUsersListFromAdmin(['addressUserData_admin']);

  // today取得（2022-01-01の型）
  const date = new Date();
  const dateYear = date.getFullYear();
  const dateMonth = `0${date.getMonth() + 1}`.slice(-2);
  const dateDay = `0${date.getDate()}`.slice(-2);
  const dateToday = `${dateYear}-${dateMonth}-${dateDay}`;

  const [inputDate, setInputDate] = useState(dateToday);

  const getValue1 = (data: ChangeEvent<HTMLInputElement>) => {
    setInputDate(data.currentTarget.value);
  };

  const [selectAge, setSelectAge] = useState('全年代');

  const getSelectValue = (data: ChangeEvent<HTMLSelectElement>) => {
    setSelectAge(data.currentTarget.value);
  };

  // 都道府県順
  const prefecture = PrefectureArray.map((ele) => ele.label);

  // inputに入力した日付以前に作成されたデータを取得
  // ここリファクタリング
  const beforeInputData = UserDatas?.filter((ele) => {
    if (ele.createdAt! <= inputDate && !undefined) {
      return ele;
    }
  });

  const age = [
    '10代',
    '20代',
    '30代',
    '40代',
    '50代',
    '60代',
    '70代',
    '80代',
    '90代',
  ];

  // 各都道府県の内訳を取得
  const peopleInPrefecture = prefecture.map((x) => {
    const peopleInPrefectureFilter = beforeInputData?.filter((y) => {
      if (x === y.address && !undefined && selectAge === y['custom:ages']) {
        return { y };
      }

      if (x === y.address && !undefined && selectAge === '全年代') {
        return { y };
      }
    });

    return peopleInPrefectureFilter?.length;
  });

  // 0のデータまで表示すると項目を47分割して見にくいので0のデータ以外で配列を作る
  const prefectureWithoutZeroNumber: number[] = [];

  const zeroFilter = () =>
    peopleInPrefecture.filter((ele, index) => {
      if (ele !== 0) {
        prefectureWithoutZeroNumber.push(index);
      }
    });

  zeroFilter();

  const prefectureWithoutZero = prefectureWithoutZeroNumber.map(
    (ele) => prefecture[ele],
  );

  const prefectureDataWithoutZero = prefectureWithoutZeroNumber.map(
    (ele) => peopleInPrefecture[ele],
  );

  // chart
  // =============================================

  const chartData = {
    // x 軸のラベル
    labels: prefectureWithoutZero,
    datasets: [
      {
        label: '地域別登録者数',
        // データの値
        data: prefectureDataWithoutZero,
        // グラフの背景色
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(179, 34, 255, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(201, 203, 207, 0.2)',
          'rgba(34, 255, 153, 0.2)',
        ],
        // グラフの枠線の色
        borderColor: [
          'rgba(255, 99, 132)',
          'rgba(255, 159, 64)',
          'rgba(179, 34, 255)',
          'rgba(255, 205, 86)',
          'rgba(75, 192, 192)',
          'rgba(54, 162, 235)',
          'rgba(153, 102, 255)',
          'rgba(201, 203, 207)',
          'rgba(34, 255, 153)',
        ],
        // グラフの枠線の太さ
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <AdminNav />
      <div className="address-user-data">
        <div className="address-user-data-wrapper">
          <h1>遺言者のデータ分析</h1>
          <p>地域別登録者数</p>
          <div className="address-user-data-chart-btns">
            <div className="address-user-data-chart">
              <div>
                <input
                  value={inputDate}
                  onChange={(e) => {
                    getValue1(e);
                  }}
                  type="date"
                />
              </div>
              <select
                onChange={(e) => {
                  getSelectValue(e);
                }}
              >
                <option value="全年代">全年代</option>
                {age.map((ele) => (
                  <option value={ele}>{ele}</option>
                ))}
              </select>
              <Pie data={chartData} />
            </div>
            <DataBtns />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressUserData;
