import { useQuery } from 'react-query';

import {
  BondsContent,
  DepositsContent,
  LiabilitiesContent,
  LifeInsuranceContent,
  Properties,
  RealEstateContent,
  StockContent,
} from 'models';
import { GetBondsContentByParent } from 'domains/cognite/services/manageBondsContent';
import { GetDepositsContentByParent } from 'domains/cognite/services/manageDepositsContent';
import { GetLiabilitiesContentByParent } from 'domains/cognite/services/manageLiabilitiesContent';
import { GetLifeInsuranceContentByParent } from 'domains/cognite/services/manageLifeInsuranceContent';
import { GetRealEstateContentByParent } from 'domains/cognite/services/manageRealEstateContent';
import { GetStockContentByParent } from 'domains/cognite/services/manageStockContent';

type ContentsAndNumber = {
  realEstateContent: RealEstateContent[];
  depositsContent: DepositsContent[];
  stockContent: StockContent[];
  lifeInsuranceContent: LifeInsuranceContent[];
  bondsContent: BondsContent[];
  liabilitiesContent: LiabilitiesContent[];
  realEstateMajorNum: number;
  movingPropertyMajorNum: number;
  depositsMajorNum: number;
  stockMajorNum: number;
  lifeInsuranceMajorNum: number;
  bondsMajorNum: number;
  liabilitiesMajorNum: number;
};

const useQueryContents = (
  key: string,
  properties: Properties | undefined,
): ContentsAndNumber => {
  const realEstate =
    properties && properties.realEstate ? properties.realEstate : null;
  const deposits =
    properties && properties.deposits ? properties.deposits : null;
  const stock = properties && properties.stock ? properties.stock : null;
  const lifeInsurance =
    properties && properties.lifeInsurance ? properties.lifeInsurance : null;
  const bonds = properties && properties.bonds ? properties.bonds : null;
  const liabilities =
    properties && properties.liabilities ? properties.liabilities : null;

  const { data: realEstateContentByQuery } = useQuery(
    `realEstate_content_${key}`,
    () => GetRealEstateContentByParent(realEstate),
    { cacheTime: 500 },
  );
  const realEstateContent = realEstateContentByQuery || [];

  const { data: depositsContentByQuery } = useQuery(
    `deposits_content_${key}`,
    () => GetDepositsContentByParent(deposits),
    { cacheTime: 500 },
  );
  const depositsContent = depositsContentByQuery || [];

  const { data: stockContentByQuery } = useQuery(
    `stock_content_${key}`,
    () => GetStockContentByParent(stock),
    { cacheTime: 500 },
  );
  const stockContent = stockContentByQuery || [];

  const { data: lifeInsuranceContentByQuery } = useQuery(
    `lifeInsurance_content_${key}`,
    () => GetLifeInsuranceContentByParent(lifeInsurance),
    { cacheTime: 500 },
  );
  const lifeInsuranceContent = lifeInsuranceContentByQuery || [];

  const { data: bondsContentByQuery } = useQuery(
    `bonds_content_${key}`,
    () => GetBondsContentByParent(bonds),
    { cacheTime: 500 },
  );
  const bondsContent = bondsContentByQuery || [];

  const { data: liabilitiesContentByQuery } = useQuery(
    `liabilities_content_${key}`,
    () => GetLiabilitiesContentByParent(liabilities),
    { cacheTime: 500 },
  );
  const liabilitiesContent = liabilitiesContentByQuery || [];

  const checkMajorNum = (where: 0 | 1 | 2 | 3 | 4 | 5 | 6) => {
    const realEstateCheck = !!realEstateContent.length;
    const movingPropertyCheck = !!properties && !!properties.movingProperty;
    const depositsCheck = !!depositsContent.length;
    const stockCheck = !!stockContent.length;
    const lifeInsuranceCheck = !!lifeInsuranceContent.length;
    const bondsCheck = !!bondsContent.length;
    const liabilitiesCheck = !!liabilitiesContent.length;

    const checkArr = [
      realEstateCheck,
      movingPropertyCheck,
      depositsCheck,
      stockCheck,
      lifeInsuranceCheck,
      bondsCheck,
      liabilitiesCheck,
    ];

    return checkArr.slice(0, where).filter((ele) => ele).length + 1;
  };

  return {
    realEstateContent,
    depositsContent,
    stockContent,
    lifeInsuranceContent,
    bondsContent,
    liabilitiesContent,
    realEstateMajorNum: checkMajorNum(0),
    movingPropertyMajorNum: checkMajorNum(1),
    depositsMajorNum: checkMajorNum(2),
    stockMajorNum: checkMajorNum(3),
    lifeInsuranceMajorNum: checkMajorNum(4),
    bondsMajorNum: checkMajorNum(5),
    liabilitiesMajorNum: checkMajorNum(6),
  };
};

export default useQueryContents;
