import { FC } from 'react';
import UserLogoHeader from '../organisms/userLogoHeader';
import CompanyInfoContent from '../templates/companyInfoContent';
import CommonFooter from '../../apply/templates/commonFooter';
import NavigateBtn from '../../../user/components/atoms/navigateBtn';
import './companyInfo.css';

const CompanyInfo: FC = () => (
  <>
    <UserLogoHeader />
    <div className="company-info">
      <CompanyInfoContent />
      <NavigateBtn />
    </div>
    <CommonFooter />
  </>
);

export default CompanyInfo;
