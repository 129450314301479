/* eslint-disable import/prefer-default-export */
import './statusColorData.css';

const StatusColorData = [
  {
    percent: '0',
    message: (
      <>
        <h2 className="zeroPer">こんにちは</h2>
        <p className="statusText">
          {/* 入力済みの項目が<span className="statusSpan">ありません</span>。
          <br /> */}
          お好きなところから入力を開始してください。
        </p>
      </>
    ),
    color: [
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
    ],
  },
  {
    percent: '9',
    message: (
      <p className="statusText">
        {/* まずは<span className="statusSpan">半分達成</span>を目指しましょう！
        <br /> */}
        お好きなところから入力を続けてください。
      </p>
    ),
    color: [
      '#FAE45A',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
    ],
  },
  {
    percent: '18',
    message: (
      <p className="statusText">
        {/* まずは<span className="statusSpan">半分達成</span>を目指しましょう！
        <br /> */}
        お好きなところから入力を続けてください。
      </p>
    ),
    color: [
      '#FAE45A',
      '#FEDB00',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
    ],
  },
  {
    percent: '27',
    message: (
      <p className="statusText">
        {/* まずは<span className="statusSpan">半分達成</span>を目指しましょう！
        <br /> */}
        お好きなところから入力を続けてください。
      </p>
    ),
    color: [
      '#FAE45A',
      '#FEDB00',
      '#F8C900',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
    ],
  },
  {
    percent: '36',
    message: (
      <p className="statusText">
        {/* まずは<span className="statusSpan">半分達成</span>を目指しましょう！
        <br /> */}
        お好きなところから入力を続けてください。
      </p>
    ),
    color: [
      '#FAE45A',
      '#FEDB00',
      '#F8C900',
      '#FDBB00',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
    ],
  },
  {
    percent: '45',
    message: (
      <p className="statusText">
        {/* まずは<span className="statusSpan">半分達成</span>を目指しましょう！
        <br /> */}
        お好きなところから入力を続けてください。
      </p>
    ),
    color: [
      '#FAE45A',
      '#FEDB00',
      '#F8C900',
      '#FDBB00',
      '#FDAB00',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
    ],
  },
  {
    percent: '54',
    message: (
      <p className="statusText">
        {/* 入力完了まで<span className="statusSpan">もう一息</span>です！
        <br /> */}
        お好きなところから入力を続けてください。
      </p>
    ),
    color: [
      '#FAE45A',
      '#FEDB00',
      '#F8C900',
      '#FDBB00',
      '#FDAB00',
      '#FD9B00',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
    ],
  },
  {
    percent: '63',
    message: (
      <p className="statusText">
        {/* 入力完了まで<span className="statusSpan">もう一息</span>です！
        <br /> */}
        お好きなところから入力を続けてください。
      </p>
    ),
    color: [
      '#FAE45A',
      '#FEDB00',
      '#F8C900',
      '#FDBB00',
      '#FDAB00',
      '#FD9B00',
      '#FD8C00',
      '#FFF',
      '#FFF',
      '#FFF',
      '#FFF',
    ],
  },
  {
    percent: '72',
    message: (
      <p className="statusText">
        {/* 入力完了まで<span className="statusSpan">もう一息</span>です！
        <br /> */}
        お好きなところから入力を続けてください。
      </p>
    ),
    color: [
      '#FAE45A',
      '#FEDB00',
      '#F8C900',
      '#FDBB00',
      '#FDAB00',
      '#FD9B00',
      '#FD8C00',
      '#FC7C00',
      '#FFF',
      '#FFF',
      '#FFF',
    ],
  },
  {
    percent: '81',
    message: (
      <p className="statusText">
        {/* 入力完了まで<span className="statusSpan">もう一息</span>です！
        <br /> */}
        お好きなところから入力を続けてください。
      </p>
    ),
    color: [
      '#FAE45A',
      '#FEDB00',
      '#F8C900',
      '#FDBB00',
      '#FDAB00',
      '#FD9B00',
      '#FD8C00',
      '#FC7C00',
      '#FC6C00',
      '#FFF',
      '#FFF',
    ],
  },
  {
    percent: '90',
    message: (
      <p className="statusText">
        {/* 入力完了まで<span className="statusSpan">もう一息</span>です！
        <br /> */}
        お好きなところから入力を続けてください。
      </p>
    ),
    color: [
      '#FAE45A',
      '#FEDB00',
      '#F8C900',
      '#FDBB00',
      '#FDAB00',
      '#FD9B00',
      '#FD8C00',
      '#FC7C00',
      '#FC6C00',
      '#FC5C00',
      '#FFF',
    ],
  },
  {
    percent: '100',
    message: (
      <p className="statusText">
        <span className="statusSpan">入力完了</span>です！お疲れ様です。
        {/* <br />
        「遺言執行者の選定」から、担当の遺言執行者に連絡しましょう。 */}
      </p>
    ),
    color: [
      '#FAE45A',
      '#FEDB00',
      '#F8C900',
      '#FDBB00',
      '#FDAB00',
      '#FD9B00',
      '#FD8C00',
      '#FC7C00',
      '#FC6C00',
      '#FC5C00',
      '#FC4C00',
    ],
  },
];

export default StatusColorData;
