/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import { FC, useState, ChangeEvent } from 'react';
import { Properties, BondsContent } from 'models';
import { useQuery } from 'react-query';
import { usePropertiesItem, useQueryContents } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { MutateProperties } from 'domains/cognite/services/manageProperties';
import { DeleteBonds, MutateBonds } from 'domains/cognite/services/manageBonds';
import Dialog from 'common/apply/organisms/dialog';
import {
  MutateBondsContentAndGet,
  GetBondsContent,
  DeleteBondsContent,
  GetBondsContentByParent,
  DeleteBondsContentById,
} from 'domains/cognite/services/manageBondsContent';
import { CognitoUserExt } from 'domains/cognite/user';
import { logger } from 'utils/logger';
import PlusBtn from '../atoms/plusBtn';
import RadioCustom from '../molecules/radioCustom';
import RadioCustomScroll from '../molecules/radioCustomScroll';
import BondsFormCard from '../organisms/bondsFormCard';
import RequireNextReturn from '../molecules/requireNextReturn';
import Param6Checkmark from '../atoms/paramCheckmarkPc';
import './bondsTemp.css';

type Props = {
  data: Properties | undefined;
  user: CognitoUserExt;
};

const BondsTemp: FC<Props> = (props) => {
  const { data, user } = props;

  const { bonds } = usePropertiesItem(data);
  const { data: bondsContentByQuery } = useQuery(
    ['bonds_content'],
    () => GetBondsContentByParent(bonds),
    {
      cacheTime: 200,
    },
  );

  const [isDialogShow, setIsDialogShow] = useState(false);

  const toggelDialog = () => {
    setIsDialogShow(!isDialogShow);
  };

  // 初期状態のオブジェクト
  const initialBondsContentArrData: BondsContent[] = [
    {
      id: '0',
      subNum: null,
      type: '',
      loanerName: '',
      initialLoanAmount: '',
      loanDate: '',
      loanAmount: '',
      loanCertificate: false,
      loanStorageLocation: '',
      investmenterName: '',
      investmentUnitNumber: '',
      investmentAmount: '',
      investmentDate: '',
      redemptionDeadline: '',
      investmentCertificate: false,
      investmentStorageLocation: '',
      bonds: undefined,
    },
  ];

  const initialBondsContentArr =
    bondsContentByQuery === undefined ||
    (bondsContentByQuery !== undefined && bondsContentByQuery.length === 0)
      ? initialBondsContentArrData
      : bondsContentByQuery;

  const [bondsContent, setBondsContent] = useState<BondsContent[]>(
    initialBondsContentArr,
  );

  const updateBondsContent = (
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement>,
    target: string,
  ) => {
    const newBondsContent = bondsContent.map((ele) => {
      if (ele.id === subjectKey) {
        return {
          id: ele.id,
          subNum: ele.subNum ? ele.subNum : null,
          type: ele.type === null || ele.type === undefined ? '' : ele.type,
          loanerName:
            target === 'loanerName'
              ? event.target.value
              : ele.loanerName === null || ele.loanerName === undefined
              ? ''
              : ele.loanerName,
          initialLoanAmount:
            target === 'initialLoanAmount'
              ? event.target.value
              : ele.initialLoanAmount === null ||
                ele.initialLoanAmount === undefined
              ? ''
              : ele.initialLoanAmount,
          loanDate:
            target === 'loanDate'
              ? event.target.value
              : ele.loanDate === null || ele.loanDate === undefined
              ? ''
              : ele.loanDate,
          loanAmount:
            target === 'loanAmount'
              ? event.target.value
              : ele.loanAmount === null || ele.loanAmount === undefined
              ? ''
              : ele.loanAmount,
          loanCertificate:
            target === 'loanCertificate'
              ? true
              : ele.loanCertificate === null ||
                ele.loanCertificate === undefined
              ? false
              : ele.loanCertificate,
          loanStorageLocation:
            target === 'loanStorageLocation'
              ? event.target.value
              : ele.loanStorageLocation === null ||
                ele.loanStorageLocation === undefined
              ? ''
              : ele.loanStorageLocation,
          investmenterName:
            target === 'investmenterName'
              ? event.target.value
              : ele.investmenterName === null ||
                ele.investmenterName === undefined
              ? ''
              : ele.investmenterName,
          investmentUnitNumber:
            target === 'investmentUnitNumber'
              ? event.target.value
              : ele.investmentUnitNumber === null ||
                ele.investmentUnitNumber === undefined
              ? ''
              : ele.investmentUnitNumber,
          investmentAmount:
            target === 'investmentAmount'
              ? event.target.value
              : ele.investmentAmount === null ||
                ele.investmentAmount === undefined
              ? ''
              : ele.investmentAmount,
          investmentDate:
            target === 'investmentDate'
              ? event.target.value
              : ele.investmentDate === null || ele.investmentDate === undefined
              ? ''
              : ele.investmentDate,
          redemptionDeadline:
            target === 'redemptionDeadline'
              ? event.target.value
              : ele.redemptionDeadline === null ||
                ele.redemptionDeadline === undefined
              ? ''
              : ele.redemptionDeadline,
          investmentCertificate:
            target === 'investmentCertificate'
              ? true
              : ele.investmentCertificate === null ||
                ele.investmentCertificate === undefined
              ? false
              : ele.investmentCertificate,
          investmentStorageLocation:
            target === 'investmentStorageLocation'
              ? event.target.value
              : ele.investmentStorageLocation === null ||
                ele.investmentStorageLocation === undefined
              ? ''
              : ele.investmentStorageLocation,
          bonds: ele.bonds,
        };
      }

      return {
        id: ele.id,
        subNum: ele.subNum,
        type: ele.type,
        loanerName: ele.loanerName,
        initialLoanAmount: ele.initialLoanAmount,
        loanDate: ele.loanDate,
        loanAmount: ele.loanAmount,
        loanCertificate: ele.loanCertificate,
        loanStorageLocation: ele.loanStorageLocation,
        investmenterName: ele.investmenterName,
        investmentUnitNumber: ele.investmentUnitNumber,
        investmentAmount: ele.initialLoanAmount,
        investmentDate: ele.investmentDate,
        redemptionDeadline: ele.redemptionDeadline,
        investmentCertificate: ele.investmentCertificate,
        investmentStorageLocation: ele.investmentStorageLocation,
        bonds: undefined,
      };
    });

    return setBondsContent(newBondsContent);
  };

  const addcard = () => {
    const randomId = Math.random().toString();
    const addBondsContentObject = {
      id: randomId,
      subNum: null,
      type: '',
      loanerName: '',
      initialLoanAmount: '',
      loanDate: '',
      loanAmount: '',
      loanCertificate: false,
      loanStorageLocation: '',
      investmenterName: '',
      investmentUnitNumber: '',
      investmentAmount: '',
      investmentDate: '',
      redemptionDeadline: '',
      investmentCertificate: false,
      investmentStorageLocation: '',
      bonds: undefined,
    };

    setBondsContent([...bondsContent, addBondsContentObject]);
  };

  const deleteCard = (deleteSubjectId: string) => {
    const newBondsContentAfterDelete = bondsContent.filter(
      (ele) => ele.id !== deleteSubjectId,
    );

    return setBondsContent(newBondsContentAfterDelete);
  };

  const switchingCard = (subjectKey: string, type: string) => {
    const clearBondsContent = bondsContent.map((ele) => {
      if (ele.id === subjectKey) {
        return {
          id: ele.id,
          subNum: ele.subNum,
          type,
          loanerName: '',
          initialLoanAmount: '',
          loanDate: '',
          loanAmount: '',
          loanCertificate: false,
          loanStorageLocation: '',
          investmenterName: '',
          investmentUnitNumber: '',
          investmentAmount: '',
          investmentDate: '',
          redemptionDeadline: '',
          investmentCertificate: false,
          investmentStorageLocation: '',
          bonds: ele.bonds,
        };
      }

      return {
        id: ele.id,
        subNum: ele.subNum,
        type: ele.type,
        loanerName: ele.loanerName,
        initialLoanAmount: ele.initialLoanAmount,
        loanDate: ele.loanDate,
        loanAmount: ele.loanAmount,
        loanCertificate: ele.loanCertificate,
        loanStorageLocation: ele.loanStorageLocation,
        investmenterName: ele.investmenterName,
        investmentUnitNumber: ele.investmentUnitNumber,
        investmentAmount: ele.initialLoanAmount,
        investmentDate: ele.investmentDate,
        redemptionDeadline: ele.redemptionDeadline,
        investmentCertificate: ele.investmentCertificate,
        investmentStorageLocation: ele.investmentStorageLocation,
        bonds: ele.bonds,
      };
    });

    return setBondsContent(clearBondsContent);
  };

  const switchingStorageLocationData = (
    subjectKey: string,
    type: string,
    yesNo: boolean,
  ) => {
    const switchBondsContent = bondsContent.map((ele) => {
      if (ele.id === subjectKey) {
        return {
          id: ele.id,
          subNum: ele.subNum,
          type: ele.type,
          loanerName: ele.loanerName,
          initialLoanAmount: ele.initialLoanAmount,
          loanDate: ele.loanDate,
          loanAmount: ele.loanAmount,
          loanCertificate: type === 'loanCertificate' ? yesNo : false,
          loanStorageLocation:
            type === 'loanCertificate' && yesNo === false
              ? ''
              : ele.loanStorageLocation,
          investmenterName: ele.investmenterName,
          investmentUnitNumber: ele.investmentUnitNumber,
          investmentAmount: ele.investmentAmount,
          investmentDate: ele.investmentDate,
          redemptionDeadline: ele.redemptionDeadline,
          investmentCertificate:
            type === 'investmentCertificate' ? yesNo : false,
          investmentStorageLocation:
            yesNo === false ? '' : ele.investmentStorageLocation,
          bonds: ele.bonds,
        };
      }

      return {
        id: ele.id,
        subNum: ele.subNum,
        type: ele.type,
        loanerName: ele.loanerName,
        initialLoanAmount: ele.initialLoanAmount,
        loanDate: ele.loanDate,
        loanAmount: ele.loanAmount,
        loanCertificate: ele.loanCertificate,
        loanStorageLocation: ele.loanStorageLocation,
        investmenterName: ele.investmenterName,
        investmentUnitNumber: ele.investmentUnitNumber,
        investmentAmount: ele.initialLoanAmount,
        investmentDate: ele.investmentDate,
        redemptionDeadline: ele.redemptionDeadline,
        investmentCertificate: ele.investmentCertificate,
        investmentStorageLocation: ele.investmentStorageLocation,
        bonds: ele.bonds,
      };
    });

    return setBondsContent(switchBondsContent);
  };

  const defaultData = bondsContentByQuery;
  const bondsArr = defaultData || [];

  // はい、いいえの初期状態管理
  const openOrClose = bondsContent[0].id !== '0';
  const [open, setOpen] = useState(openOrClose);
  const yesCheck = bondsContent[0].id !== '0';
  const [yesCheckState, setYesCheckState] = useState(yesCheck);
  const noCheck = !!(bonds && bonds.noCheck && bonds.noCheck === true);
  const [noCheckState, setNoCheckState] = useState(noCheck);
  const digitalAssetsCheck =
    bonds && bonds !== null && bonds.digitalAssetsCheckFlag !== null
      ? bonds.digitalAssetsCheckFlag
      : undefined;
  const [digitalAssetsCheckState, setDigitalAssetsCheckState] =
    useState(digitalAssetsCheck);

  const inputCheckArr = bondsContent.map((ele) => {
    const inputCheck = !(ele.loanerName === '' && ele.investmenterName === '');

    return inputCheck;
  });

  const loanCheckArr = bondsContent.map((ele) => {
    const loanCheck = ele.type === '貸付金';

    return loanCheck;
  });

  const investmentCheckArr = bondsContent.map((ele) => {
    const investmentCheck = ele.type === '出資金';

    return investmentCheck;
  });

  // 債権者名の有無チェック
  const loanerNameArr = bondsContent.map((ele) => {
    const loanerName =
      ele.loanerName === undefined || ele.loanerName === null
        ? ''
        : ele.loanerName;

    return loanerName;
  });

  // 当初貸付金額の有無チェック
  const initialLoanAmountArr = bondsContent.map((ele) => {
    const initialLoanAmount =
      ele.initialLoanAmount === undefined || ele.initialLoanAmount === null
        ? ''
        : ele.initialLoanAmount;

    return initialLoanAmount;
  });

  // 当初貸付年月日の有無チェック
  const loanDateArr = bondsContent.map((ele) => {
    const loanDate =
      ele.loanDate === undefined || ele.loanDate === null ? 0 : ele.loanDate;

    return loanDate;
  });

  // 貸付金残高の有無チェック
  const loanAmountArr = bondsContent.map((ele) => {
    const loanAmount =
      ele.loanAmount === undefined || ele.loanAmount === null
        ? ''
        : ele.loanAmount;

    return loanAmount;
  });

  // 貸付金の証書の保管場所チェック
  const loanStorageLocationArr = bondsContent.map((ele) => {
    const loanStorageLocation =
      ele.loanStorageLocation === undefined || ele.loanStorageLocation === null
        ? ''
        : ele.loanStorageLocation;

    return loanStorageLocation;
  });

  // 貸付金の証書の保管場所（はい）チェック
  const loanStorageLocationYesArr = bondsContent.map((ele) => {
    const loanStorageLocationYes = ele.loanCertificate === true;

    return loanStorageLocationYes;
  });

  // 貸付金の証書の保管場所（いいえ）チェック
  const loanStorageLocationNoArr = bondsContent.map((ele) => {
    const loanStorageLocationNo = !!(
      ele.loanerName !== '' && ele.loanCertificate === false
    );

    return loanStorageLocationNo;
  });

  // 出資金先名の有無チェック
  const investmenterNameArr = bondsContent.map((ele) => {
    const investmenterName =
      ele.investmenterName === undefined || ele.investmenterName === null
        ? ''
        : ele.investmenterName;

    return investmenterName;
  });

  // 出資口数の有無チェック
  const investmentUnitNumberArr = bondsContent.map((ele) => {
    const investmentUnitNumber =
      ele.investmentUnitNumber === undefined ||
      ele.investmentUnitNumber === null
        ? ''
        : ele.investmentUnitNumber;

    return investmentUnitNumber;
  });

  // 出資金額の有無チェック
  const investmentAmountArr = bondsContent.map((ele) => {
    const investmentAmount =
      ele.investmentAmount === undefined || ele.investmentAmount === null
        ? ''
        : ele.investmentAmount;

    return investmentAmount;
  });

  // 出資年月日の有無チェック
  const investmentDateArr = bondsContent.map((ele) => {
    const investmentDate =
      ele.investmentDate === undefined || ele.investmentDate === null
        ? ''
        : ele.investmentDate;

    return investmentDate;
  });

  // 償還期限の有無チェック
  const redemptionDeadlineArr = bondsContent.map((ele) => {
    const redemptionDeadline =
      ele.redemptionDeadline === undefined || ele.redemptionDeadline === null
        ? ''
        : ele.redemptionDeadline;

    return redemptionDeadline;
  });

  // 出資金の証書の保管場所チェック
  const investmentStorageLocationArr = bondsContent.map((ele) => {
    const investmentStorageLocation =
      ele.investmentStorageLocation === undefined ||
      ele.investmentStorageLocation === null
        ? ''
        : ele.investmentStorageLocation;

    return investmentStorageLocation;
  });

  // 貸付金の証書の保管場所（はい）チェック
  const investmentStorageLocationYesArr = bondsContent.map((ele) => {
    const investmentStorageLocationYes = ele.investmentCertificate === true;

    return investmentStorageLocationYes;
  });

  // 貸付金の証書の保管場所（いいえ）チェック
  const investmentStorageLocationNoArr = bondsContent.map((ele) => {
    const investmentStorageLocationNo = !!(
      ele.investmenterName !== '' && ele.investmentCertificate === false
    );

    return investmentStorageLocationNo;
  });

  // 最初に開くカードのチェック
  const loanOrInvestmentCardArr = bondsContent.map((ele) => {
    const loanOrInvestmentCard =
      ele.type === undefined || ele.type === null
        ? true
        : ele.type === '貸付金';

    return loanOrInvestmentCard;
  });

  // 財産目録のデータ取得
  const propertiesId = data ? data.id : '';

  const { bondsMajorNum } = useQueryContents('bonds_check', data);

  const cardDeleteAll = async () => {
    await DeleteBondsContent();
    await DeleteBonds();
    await MutateProperties(propertiesId, {
      userId: user.attributes.sub,
      bonds: undefined,
    });

    setOpen(false);
    setYesCheckState(false);
    setDigitalAssetsCheckState(false);
    window.location.reload();
  };

  const navigate = useNavigate();

  const save = async (link: string) => {
    const reservedBondsContent = await GetBondsContent();
    const bondsContentId = bondsContent.map((ele) => ele.id);
    reservedBondsContent.map((ele) => {
      if (bondsContentId.includes(ele.id) === false) {
        return DeleteBondsContentById(ele.id);
      }

      return ele;
    });

    const inputNameCheckArr = bondsContent.map((ele) => {
      const inputNameCheck = !!(
        (ele.type === '貸付金' && ele.loanerName === '') ||
        (ele.type === '出資金' && ele.investmenterName === '')
      );

      return inputNameCheck;
    });

    if (open === true && inputNameCheckArr.includes(true)) {
      window.alert('必須項目の入力が漏れています。');
    } else {
      const id = data
        ? data.propertiesBondsId
          ? data.propertiesBondsId
          : ''
        : '';
      const createdBonds = await MutateBonds(id, {
        majorNum: bondsMajorNum,
        inputFlag: true,
        noCheck: noCheckState,
        digitalAssetsCheckFlag: digitalAssetsCheckState,
      });

      const newBondsContent = open === false ? [] : bondsContent;

      const newContent = await Promise.all(
        newBondsContent.map(async (ele, index) => {
          const contentItem = await MutateBondsContentAndGet(
            ele.id,
            index + 1,
            ele.type || '',
            ele.loanerName || '',
            ele.initialLoanAmount || '',
            ele.loanDate || '',
            ele.loanAmount || '',
            ele.loanCertificate || false,
            ele.loanStorageLocation || '',
            ele.investmenterName || '',
            ele.investmentUnitNumber || '',
            ele.investmentAmount || '',
            ele.investmentDate || '',
            ele.redemptionDeadline || '',
            ele.investmentCertificate || false,
            ele.investmentStorageLocation || '',
            createdBonds,
          );

          return contentItem;
        }),
      );

      logger.info(newContent);

      await MutateProperties(propertiesId, {
        userId: user.attributes.sub,
        bonds: createdBonds,
      });
      navigate(link);
    }
  };

  const saveNotLink = async (check: boolean) => {
    const reservedBondsContent = await GetBondsContent();
    const bondsContentId = bondsContent.map((ele) => ele.id);
    reservedBondsContent.map((ele) => {
      if (bondsContentId.includes(ele.id) === false) {
        return DeleteBondsContentById(ele.id);
      }

      return ele;
    });
    // const inputNameCheckArr = bondsContent.map((ele) => {
    //   const inputNameCheck = !!(
    //     (ele.investmenterName === undefined && ele.loanerName === undefined) ||
    //     (ele.investmenterName === undefined && ele.loanerName === '') ||
    //     (ele.investmenterName === '' && ele.loanerName === undefined) ||
    //     (ele.investmenterName === '' && ele.loanerName === '')
    //   );

    //   return inputNameCheck;
    // });

    const id = data
      ? data.propertiesBondsId
        ? data.propertiesBondsId
        : ''
      : '';
    const createdBonds = await MutateBonds(id, {
      majorNum: bondsMajorNum,
      inputFlag: true,
      noCheck: noCheckState,
      digitalAssetsCheckFlag: check,
    });

    const newContent = await Promise.all(
      bondsContent.map(async (ele, index) => {
        const contentItem = await MutateBondsContentAndGet(
          ele.id,
          index + 1,
          ele.type || '',
          ele.loanerName || '',
          ele.initialLoanAmount || '',
          ele.loanDate || '',
          ele.loanAmount || '',
          ele.loanCertificate || false,
          ele.loanStorageLocation || '',
          ele.investmenterName || '',
          ele.investmentUnitNumber || '',
          ele.investmentAmount || '',
          ele.investmentDate || '',
          ele.redemptionDeadline || '',
          ele.investmentCertificate || false,
          ele.investmentStorageLocation || '',
          createdBonds,
        );

        return contentItem;
      }),
    );

    logger.info(newContent);

    await MutateProperties(propertiesId, {
      userId: user.attributes.sub,
      bonds: createdBonds,
    });

    // if (inputNameCheckArr.includes(true)) {
    //   window.alert('必須項目の入力が漏れています。');
    // } else {
    // この中で、bondsContentのデータを保存していた
    // }
  };

  const noCheckFunc = async () => {
    await DeleteBondsContent();
    const noCheckId = data
      ? data.propertiesBondsId
        ? data.propertiesBondsId
        : ''
      : '';
    const createdBondsByNoCheck = await MutateBonds(noCheckId, {
      majorNum: bondsMajorNum,
      inputFlag: true,
      noCheck: true,
      digitalAssetsCheckFlag: digitalAssetsCheckState,
    });

    await MutateProperties(propertiesId, {
      userId: user.attributes.sub,
      bonds: createdBondsByNoCheck,
    });

    setOpen(false);

    window.scrollBy({
      top: 150,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {isDialogShow ? (
        <Dialog
          dialogText="入力内容を削除しますがよろしいですか？"
          dialogYesFunc={cardDeleteAll}
          dialogNoFunc={toggelDialog}
        />
      ) : (
        ''
      )}
      <div className="bondsTop">
        <div className="bonds">
          <div className="pc-only depositsParam1">
            <Param6Checkmark
              StyleClass="styleParam6"
              key="bonds_pc"
              checkMarkSize6
              propertyData={data}
            />
          </div>
          <RadioCustom
            title="あなた個人が債権者として権利を有する債権はありますか？"
            name="bonds"
            yesFunc={() => {
              setNoCheckState(false);
              setOpen(true);
            }}
            noFunc={async () => {
              setNoCheckState(true);
              await noCheckFunc();
            }}
            yesCheck={yesCheckState}
            noCheck={noCheckState}
          />
        </div>
        <div
          className={`bondsFormCardArea ${open ? 'bondsopen' : 'bondsclose'}`}
        >
          <div>
            {bondsContent.map((ele, index, theForms) => (
              <BondsFormCard
                key={ele.id}
                title="債権"
                form={ele.id}
                minusBtnView={theForms.length !== 1}
                num={index}
                subNum={index + 1}
                investmenterNameValue={investmenterNameArr[index]}
                loanerNameValue={loanerNameArr[index]}
                initialLoanAmountValue={initialLoanAmountArr[index]}
                loanDateValue={loanDateArr[index]}
                loanAmountValue={loanAmountArr[index]}
                investmentUnitNumberValue={investmentUnitNumberArr[index]}
                investmentAmountValue={investmentAmountArr[index]}
                investmentDateValue={investmentDateArr[index]}
                redemptionDeadlineValue={redemptionDeadlineArr[index]}
                loanCheck={loanCheckArr[index]}
                investmentCheck={investmentCheckArr[index]}
                openOrCloseAboutType={inputCheckArr[index]}
                loanStorageLocation={loanStorageLocationArr[index]}
                investmentStorageLocation={investmentStorageLocationArr[index]}
                loanOrInvestmentCard={loanOrInvestmentCardArr[index]}
                defaultBondsContent={bondsArr[index]}
                updateBondsContent={updateBondsContent}
                subjectId={ele.id}
                deleteCard={deleteCard}
                switchingCard={switchingCard}
                switchingStorageLocationData={switchingStorageLocationData}
                loanStorageLocationYes={loanStorageLocationYesArr[index]}
                loanStorageLocationNo={loanStorageLocationNoArr[index]}
                investmentStorageLocationYes={
                  investmentStorageLocationYesArr[index]
                }
                investmentStorageLocationNo={
                  investmentStorageLocationNoArr[index]
                }
              />
            ))}
          </div>
          <button type="button" className="plusBtn-wrapper" onClick={addcard}>
            <PlusBtn />
          </button>
        </div>
        <div className="bondsBottomWrapper">
          <RadioCustomScroll
            title="あなた個人名義で所有しているデジタル資産(電子マネー、暗号資産など)がありますか？"
            name="bondsBottomRadio"
            yesFunc={async () => {
              setDigitalAssetsCheckState(true);
              await saveNotLink(true);
            }}
            noFunc={async () => {
              setDigitalAssetsCheckState(false);
              await saveNotLink(false);
            }}
            yesCheck={digitalAssetsCheckState === true}
            noCheck={digitalAssetsCheckState === false}
          />
          <p className="bondsBottomWrapperText">
            はい、の場合は遺言書に残す必要があります。
            <br />
            インターネット上の各種アカウント、使っている電子マネー、パソコンやスマートフォンの中のデータについて、パスワードと一緒に記録しておく必要があります。
            <br />
            非常に重要な情報ですから、記録方法については専門家に相談されることをお勧めします。
          </p>
        </div>
      </div>
      <RequireNextReturn
        returnFunc={async () => {
          await save('../lifeinsurance');
        }}
        nextFunc={async () => {
          await save('../liabilities');
        }}
        deleteFunc={toggelDialog}
      />
    </>
  );
};

export default BondsTemp;
