/* eslint-disable no-param-reassign */
import { DataStore } from 'aws-amplify';
import {
  AssignRelationship,
  AssignRelationshipMetaData,
  AssignStatus,
} from 'models';
import { logger } from 'utils/logger';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ModelInit } from '@aws-amplify/datastore';

export const CreateAssignRelationship = async (
  instance: ModelInit<AssignRelationship, AssignRelationshipMetaData>,
): Promise<AssignRelationship> => {
  try {
    logger.info('執行者への申請の作成を開始します');
    const data = await DataStore.save(new AssignRelationship(instance));

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

// 使わないので削除かも
export const UpdateAssignRelationship = async (
  id: string,
  instance: ModelInit<AssignRelationship, AssignRelationshipMetaData>,
): Promise<void> => {
  try {
    logger.info('執行者への申請の更新を開始します');
    const original = await DataStore.query(AssignRelationship, id);

    if (original) {
      const data = await DataStore.save(
        AssignRelationship.copyOf(original, (updated) => {
          updated.clientId = instance.clientId;
          updated.assigneeId = instance.assigneeId;
          updated.status = instance.status;
        }),
      );

      logger.info('執行者への申請の更新が完了しました');
      logger.info(data);
    } else {
      logger.info('更新対象の申請データが見つかりませんでした');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

// 使わないので削除かも
export const UpdateAssignStatus = async (
  id: string,
  status: AssignStatus,
): Promise<void> => {
  try {
    logger.info('執行者への申請の更新を開始します');
    const original = await DataStore.query(AssignRelationship, id);

    if (original) {
      const data = await DataStore.save(
        AssignRelationship.copyOf(original, (updated) => {
          updated.status = status;
        }),
      );

      logger.info('執行者への申請の更新が完了しました - 0');
      logger.info(data);
    } else {
      logger.info('更新対象の申請データが見つかりませんでした');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const UpdateAssignStatusByClient = async (
  clientId: string,
  status: AssignStatus,
): Promise<void> => {
  try {
    logger.info('執行者への申請の更新を開始します');
    const original = await DataStore.query(AssignRelationship, (c) =>
      c.clientId('eq', clientId),
    );

    if (original && original.length >= 1) {
      const data = await DataStore.save(
        AssignRelationship.copyOf(original[0], (updated) => {
          updated.status = status;
        }),
      );

      logger.info('執行者への申請の更新が完了しました - 1');
      logger.info(data);
    } else {
      logger.error('更新対象の申請データが見つかりませんでした');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const UpdateAssignStatusByAssignee = async (
  clientId: string,
  assigneeId: string,
  status: AssignStatus,
): Promise<void> => {
  try {
    logger.info('執行者への申請の更新を開始します');
    const original = await DataStore.query(AssignRelationship, (c) =>
      c.assigneeId('eq', assigneeId).clientId('eq', clientId),
    );

    if (original) {
      const data = await DataStore.save(
        AssignRelationship.copyOf(original[0], (updated) => {
          updated.status = status;
        }),
      );

      logger.info('執行者への申請の更新が完了しました - 2');
      logger.info(data);
    } else {
      logger.error('更新対象の申請データが見つかりませんでした');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const UpdateAllAssignStatusOfAssignee = async (
  assigneeId: string,
  status: AssignStatus,
): Promise<void> => {
  try {
    logger.info('執行者への申請の更新を開始します');
    const original = await DataStore.query(AssignRelationship, (c) =>
      c.assigneeId('eq', assigneeId),
    );

    if (original) {
      const data = await Promise.all(
        original.map(async (ele) => {
          await DataStore.save(
            AssignRelationship.copyOf(ele, (updated) => {
              updated.status = status;
            }),
          );
        }),
      );

      logger.info('執行者への申請の更新が完了しました - 2');
      logger.info(data);
    } else {
      logger.error('更新対象の申請データが見つかりませんでした');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const QueryAssignRelationshipFromClient = async (
  id: string,
): Promise<AssignRelationship[]> => {
  try {
    logger.info('申請者の申請内容を取得します');
    const data = await DataStore.query(AssignRelationship, (c) =>
      c.clientId('eq', id),
    );
    logger.info('申請者の申請内容を取得が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const QueryAssignRelationshipFromClientFilteredStatus = async (
  id: string,
  status: AssignStatus,
): Promise<AssignRelationship[]> => {
  try {
    logger.info('申請者の申請内容を取得します');
    const data = await DataStore.query(AssignRelationship, (c) =>
      c.clientId('eq', id).status('eq', status),
    );
    logger.info('申請者の申請内容を取得が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const QueryAssignRelationshipFromAssignee = async (
  id: string,
): Promise<AssignRelationship[]> => {
  try {
    logger.info('被申請者の申請内容を取得します');
    const data = await DataStore.query(AssignRelationship, (c) =>
      c.assigneeId('eq', id),
    );
    logger.info('被申請者の申請内容を取得が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const QueryAssignRelationshipFromBoth = async (
  clientId: string,
  assigneeId: string,
): Promise<AssignRelationship> => {
  try {
    logger.info('被申請者の申請情報を取得します');
    const data = await DataStore.query(AssignRelationship, (c) =>
      c.assigneeId('eq', assigneeId).clientId('eq', clientId),
    );
    logger.info('被申請者の申請情報の取得が完了しました');

    return data[0];
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteAssignRelationshipFromClient = async (
  clientId: string,
): Promise<void> => {
  try {
    const data = await DataStore.query(AssignRelationship, (c) =>
      c.clientId('eq', clientId),
    );

    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);
        }),
      );
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteAssignRelationshipFromAdmin = async (
  assigneeId: string,
): Promise<void> => {
  try {
    const data = await DataStore.query(AssignRelationship, (c) =>
      c.assigneeId('eq', assigneeId),
    );

    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);
        }),
      );
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
