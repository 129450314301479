import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { GetUser } from 'domains/cognite/services/manageUser';
import {
  CreateStayingTimeAtList,
  UpdateStayingTimeAtList,
  GetStayingTimeAtListByUserId,
} from 'domains/cognite/services/manageStayingTimeAtList';

const useStayingTimeAtList = (): void => {
  const startTime = useMemo(() => new Date(), []);
  const startTimeDate = `${startTime.getFullYear()}-${
    startTime.getMonth() + 1
  }-${startTime.getDate()}`;
  const { data: user } = useQuery(['authenticated_user'], GetUser);

  useEffect(() => {
    const checkStayingTimeAndMutate = async () => {
      const currentTime = new Date();
      const diffTime = currentTime.getTime() - startTime.getTime();
      const diffSecond = diffTime / (1000 * 60);

      if (user && diffSecond >= 5) {
        const oldTimes = await GetStayingTimeAtListByUserId(
          user.attributes.sub,
        );
        if (oldTimes && oldTimes.some((ele) => ele.date === startTimeDate)) {
          const theOldTime = oldTimes.find((ele) => ele.date === startTimeDate);
          if (theOldTime) {
            await UpdateStayingTimeAtList(theOldTime.id, theOldTime.time + 5);
          }
        } else {
          await CreateStayingTimeAtList({
            userId: user.attributes.sub,
            date: startTimeDate,
            time: 5,
          });
        }
      }
    };

    void checkStayingTimeAndMutate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  });
};

export default useStayingTimeAtList;
