import { FC } from 'react';
import HeaderLogo from '../images/headerLogo.svg';
import ExecutorFooterMenuAfterLoginContent from '../organisms/executorFooterMenuAfterLoginContent';
import minusBtn from '../images/minusBtn.svg';
import './executorFooterMenu.css';

type Props = {
  show: boolean;
  setShow: () => void;
};

const ExecutorFooterMenuAfterLogin: FC<Props> = (props) => {
  const { show, setShow } = props;

  if (show) {
    return (
      <div id="executorFooterOverlay" className="executorFooterMenuAfterLogin">
        <div>
          <img src={HeaderLogo} alt="" />
        </div>
        <ExecutorFooterMenuAfterLoginContent setShow={setShow} />
        <button type="button" onClick={() => setShow()}>
          <img src={minusBtn} alt="minus-Button" />
          <p>閉じる</p>
        </button>
      </div>
    );
  }

  return null;
};

export default ExecutorFooterMenuAfterLogin;
