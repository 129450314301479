import { FC } from 'react';
// import { useSync } from 'hooks';
import WillHeader from '../organisms/willHeader';
import ExecutorChangeDescription from '../templates/executorChangeDescription';
// import Footer from '../organisms/footer';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import ExecutorChangeContent from '../organisms/executorChangeContent';
import ExecutorPcDescription from '../organisms/executorPcDescription';
import pageData from '../data/pageData';
import './executorChange.css';

const ExecutorChange: FC = () => (
  <>
    <WillHeader title="担当遺言執行者の変更" />
    <div className="executorChange">
      <ExecutorChangeDescription />
      <ExecutorChangeContent />
    </div>
    {/* <Footer /> */}
    <CommonFooter />
    <ExecutorPcDescription description={pageData[37].description} />
  </>
);

export default ExecutorChange;
