import { useQuery } from 'react-query';
import { Allocation } from 'models';
import { QueryAllocation } from 'domains/cognite/services/manageAllocation';
import { CognitoUserExt } from 'domains/cognite/user';
import { User } from 'domains/apigateway/services/get-user';
import { logger } from 'utils/logger';

type UseQueryAllocation = (
  key: string,
  user: CognitoUserExt | User | undefined,
) => {
  data: Allocation | undefined;
  isFetching: boolean;
};

const useQueryAllocationQuick: UseQueryAllocation = (
  key: string,
  user: CognitoUserExt | User | undefined,
) => {
  if (!user) {
    logger.error(user);
    throw Error;
  }

  const hasAttributes = (
    theUser: CognitoUserExt | User,
  ): theUser is CognitoUserExt => !!(theUser as CognitoUserExt)?.attributes;

  const QueryUserAllocation = async () => {
    const userId = hasAttributes(user) ? user.attributes.sub : user.id;
    const allocation = await QueryAllocation(userId);

    return allocation;
  };
  const { data, isFetching } = useQuery([key], QueryUserAllocation, {
    cacheTime: 200,
  });

  return {
    data,
    isFetching,
  };
};

export default useQueryAllocationQuick;
