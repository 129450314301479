import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const FrontTemporaryEnter: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <button
        type="button"
        onClick={() => {
          navigate('/');
        }}
      >
        Sign out
      </button>
      <Link to="/user">userへ</Link>
      <Link to="/executor/procedure">遺言執行者へ</Link>
      <Link to="/executor/willchargelist">遺言執行者マイページ</Link>
      <Link to="/executor/applicantlist">申請者一覧</Link>
      <Link to="/executor/profile">プロフィール</Link>
      <Link to="/executor/changemail">メール変更</Link>
      <Link to="/executor/changepass">パスワード変更</Link>
      <Link to="/user/executorapproval">☆承認済み担当遺言執行者</Link>
      <Link to="/user/question">■よくある質問</Link>
      <Link to="/user/companyinfo">○法人概要</Link>
      <Link to="/user/privacypolicy">※プライバシーポリシー</Link>
      <Link to="/user/willpolicy">•利用規約（遺言者用）</Link>
      <Link to="/user/contactThanks">お問い合わせありがとう</Link>
      <Link to="/user/deleteThanks">退会ありがとう</Link>
    </>
  );
};

export default FrontTemporaryEnter;
