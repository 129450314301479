import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { DataStore } from 'aws-amplify';
import { GetUser as GetExecutor } from 'domains/cognite/services/manageUser';
import { AssignRelationship } from 'models';
import { QueryAssignRelationshipFromAssignee } from 'domains/cognite/services/manageAssignRelationship';
import { logger } from 'utils/logger';

type UseRelationshpFromExecutor = (key: string) => {
  data: AssignRelationship[] | undefined;
  isFetching: boolean;
  error: unknown;
};

const useRelationshpFromExecutor: UseRelationshpFromExecutor = (
  key: string,
) => {
  useEffect(() => {
    const subscription = DataStore.observe(AssignRelationship).subscribe(
      (msg) => {
        logger.info(msg.model, msg.opType, msg.element);
        logger.info(msg.element.status);
        // const watchRelation = async () => {
        //   const relationInSubscription = await getRelation();
        //   setRelation(relationInSubscription);
        // };
        // void watchRelation();
        // console.log('変更検知 --aaaa');
        // console.log(relation);
      },
    );

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRelationship = async () => {
    const executor = await GetExecutor();
    const relationship = await QueryAssignRelationshipFromAssignee(
      executor.attributes.sub,
    );

    return relationship;
  };

  const { data, isFetching, error } = useQuery(
    ['clients', key],
    getRelationship,
    {
      cacheTime: 2000,
    },
  );

  return { data, isFetching, error };
};

export default useRelationshpFromExecutor;
