import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { GetS3 } from 'domains/aws/services/get-s3';
import { formatDateToCleanStr } from 'utils/dateFormat';
import sampleImage from '../images/post_sample_image.png';
import './topicsListLink.css';

type Props = {
  postId: string;
  title: string;
  thumbnail: string;
  updatedAt: string | null | undefined;
  isDraft: boolean;
};

const TopicsListLink: FC<Props> = (props) => {
  const { postId, title, thumbnail, updatedAt, isDraft } = props;

  const { data: getImage } = useQuery(['thumbnail', thumbnail], () =>
    GetS3(thumbnail, 'public'),
  );

  const imageUrl = getImage || sampleImage;

  const updatedAtDate = new Date(updatedAt || '');
  const updatedAtDateFormated = updatedAt
    ? formatDateToCleanStr(updatedAtDate)
    : '';

  const hideDraft = isDraft ? '' : 'hide-draft';

  return (
    <Link to={`/admin/topics/detail/${postId}`} className="topics-link">
      <div className="topics-link-container">
        <div className="topics-link_img-wrapper">
          <img src={imageUrl} alt="" />
        </div>
        <div>
          <p>
            {updatedAtDateFormated}
            <span className={`draft ${hideDraft}`}>下書き</span>
          </p>
          <p>{title}</p>
        </div>
      </div>
    </Link>
  );
};

export default TopicsListLink;
