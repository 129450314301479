/* eslint-disable no-alert */
import { FC, Suspense } from 'react';
import { Document, Packer } from 'docx';
import {
  Allocation,
  AllocationProperties,
  AssignExecutorNotOnTheList,
  BondsContent,
  DepositsContent,
  LiabilitiesContent,
  LifeInsuranceContent,
  Properties,
  RealEstateContent,
  StockContent,
} from 'models';
import { usePropertiesItem } from 'hooks';
import PutS3 from 'domains/aws/services/put-s3';
import DeleteS3 from 'domains/aws/services/delete-s3';
import { Executor } from 'domains/apigateway/services/get-Executor';
import { createOrUpdateTestamentFile } from 'domains/cognite/services/manageTestamentFile';
import numbering from '../document/word/numbering';
import styles from '../document/word/styles';
import sections, { Contents } from '../document/word/sections';
import PdfDownload from '../document/pdfDownload';
import WordDownload from '../document/wordDownload';
import LastUpdated from '../molecules/lastUpdated';

type AllocationReceiverExtra = {
  allocationProperties: AllocationProperties[];
  id: string;
  num?: number | null | undefined;
  name?: string | null | undefined;
  birth?: string | null | undefined;
  type?: string | null | undefined;
  allocation?: Allocation | null | undefined;
  createdAt?: string | null | undefined;
  updatedAt?: string | null | undefined;
};

type Props = {
  userId: string;
  allocationReceiver: AllocationReceiverExtra[];
  propertyData: Properties | undefined;
  realEstateContent: RealEstateContent[];
  depositsContent: DepositsContent[];
  stockContent: StockContent[];
  lifeInsuranceContent: LifeInsuranceContent[];
  bondsContent: BondsContent[];
  liabilitiesContent: LiabilitiesContent[];
  // enableClickUpdateBtn: string;
  enableUpdateFiles: boolean;
  executor: Executor | undefined;
  executorNotList: AssignExecutorNotOnTheList | undefined;
  startUpload: boolean;
  toggleStartUpload: (set: boolean) => void;
};

const DownloadBtnContainer: FC<Props> = (props) => {
  const {
    userId,
    allocationReceiver,
    propertyData,
    realEstateContent,
    depositsContent,
    stockContent,
    lifeInsuranceContent,
    bondsContent,
    liabilitiesContent,
    // enableClickUpdateBtn,
    enableUpdateFiles,
    executor,
    executorNotList,
    startUpload,
    toggleStartUpload,
  } = props;

  const allocationReceiverSorted = allocationReceiver.sort((a, b) => {
    if (a.type === b.type) {
      return 0;
    }

    if (a.type === '法定相続人') {
      return -1;
    }

    return 1;
  });

  const propertyItems = usePropertiesItem(propertyData);

  const contents: Contents = {
    realEstateContent,
    depositsContent,
    stockContent,
    lifeInsuranceContent,
    bondsContent,
    liabilitiesContent,
  };

  const doc = new Document({
    numbering,
    styles,
    sections: sections(
      contents,
      allocationReceiverSorted,
      propertyItems,
      executor,
      executorNotList,
    ),
  });

  const uploadWord = async () => {
    try {
      toggleStartUpload(true);
      const fileName = `遺言書_${userId}`;
      await DeleteS3(`${fileName}.docx`);
      await DeleteS3(`${fileName}.pdf`);
      await createOrUpdateTestamentFile(fileName);
      await Packer.toBlob(doc).then(async (blob) => {
        await PutS3(`${fileName}.docx`, blob, 'public');
      });
      window.alert('遺言書の内容を更新しました。反映まで少々お待ちください。');
      // eslint-disable-next-line no-useless-catch
    } catch (error) {
      throw error;
    } finally {
      setTimeout(() => {
        toggleStartUpload(false);
      }, 4000);
    }
    // window.location.reload();
  };

  const enableClickUpdateBtn =
    enableUpdateFiles && !startUpload ? '' : 'notClick';

  return (
    <>
      <button
        type="button"
        onClick={uploadWord}
        className={`updateBtn ${enableClickUpdateBtn}`}
      >
        遺言書に反映させる
      </button>
      <div className="confirmBtnContainer">
        <Suspense fallback={<p>読み込み中</p>}>
          <PdfDownload userId={userId} startUpload={startUpload} />
        </Suspense>
        <Suspense fallback={<p>読み込み中</p>}>
          <WordDownload userId={userId} startUpload={startUpload} />
        </Suspense>
      </div>
      <Suspense fallback={<p>読み込み中</p>}>
        <LastUpdated userId={userId} />
      </Suspense>
    </>
  );
};

export default DownloadBtnContainer;
