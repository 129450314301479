/* eslint-disable no-param-reassign */
import { DataStore } from 'aws-amplify';
import {
  AssignExecutorNotOnTheList,
  AssignExecutorNotOnTheListMetaData,
} from 'models';
import { logger } from 'utils/logger';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ModelInit } from '@aws-amplify/datastore';

export const QueryAssignExecutorNotOnTheList = async (): Promise<
  AssignExecutorNotOnTheList[]
> => {
  try {
    const queryData = await DataStore.query(AssignExecutorNotOnTheList);

    const queryResult = !queryData ? [] : queryData;

    return queryResult;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const CreateAssignExecutorNotOnTheList = async (
  instance: ModelInit<
    AssignExecutorNotOnTheList,
    AssignExecutorNotOnTheListMetaData
  >,
): Promise<void> => {
  try {
    logger.info('名簿以外の遺言執行者の項目の作成を開始します');
    const data = await DataStore.save(new AssignExecutorNotOnTheList(instance));
    logger.debug(data);
    logger.info('名簿以外の遺言執行者の項目の作成が完了しました');
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const UpdateAssignExecutorNotOnTheListById = async (
  id: string,
  userId: string,
  name: string,
  birth: string,
  address: string,
  relationshipOrpassport: string,
): Promise<void> => {
  try {
    logger.info('名簿以外の遺言執行者の項目の更新を開始します');

    const original = await DataStore.query(AssignExecutorNotOnTheList, id);

    if (original) {
      logger.info(original);
      const data = await DataStore.save(
        AssignExecutorNotOnTheList.copyOf(original, (updated) => {
          updated.name = name;
          updated.birth = birth;
          updated.address = address;
          updated.relationshipOrpassport = relationshipOrpassport;
        }),
      );
      logger.info(data);
      logger.info('名簿以外の遺言執行者の項目の更新が完了しました');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteAssignExecutorNotOnTheListById = async (
  id: string,
): Promise<void> => {
  try {
    logger.info('名簿以外の遺言執行者の項目の削除を開始します');
    const original = await DataStore.query(AssignExecutorNotOnTheList, id);
    // const original1 = original[0];
    if (original) {
      logger.info(original);
      await DataStore.delete(original);
      logger.info('名簿以外の遺言執行者の項目の削除が完了しました');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetAssignExecutorNotOnTheList = async (): Promise<
  AssignExecutorNotOnTheList[]
> => {
  try {
    logger.info('名簿以外の遺言執行者の項目のフェッチを開始します');
    const data = await DataStore.query(AssignExecutorNotOnTheList);
    logger.debug(data);
    logger.info('名簿以外の遺言執行者の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
