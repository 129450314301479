/* eslint-disable no-param-reassign */
import { DataStore } from 'aws-amplify';
import { RealEstate, RealEstateMetaData } from 'models';
import { logger } from 'utils/logger';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ModelInit } from '@aws-amplify/datastore';

// DBにデータ保存
export const CreateRealEstate = async (
  instance: ModelInit<RealEstate, RealEstateMetaData>,
): Promise<RealEstate> => {
  try {
    logger.info('不動産の作成を開始します');
    const data = await DataStore.save(new RealEstate(instance));
    logger.info('不動産の作成が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const queryRealEstate = async (): Promise<RealEstate[]> => {
  try {
    const queryRealEstateData = await DataStore.query(RealEstate);

    const queryRealEstateResult = !queryRealEstateData
      ? []
      : queryRealEstateData;

    return queryRealEstateResult;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const MutateRealEstate = async (
  id: string,
  instance: ModelInit<RealEstate, RealEstateMetaData>,
): Promise<RealEstate> => {
  try {
    logger.info('不動産の作成または更新を開始します');
    const original = await DataStore.query(RealEstate, id);

    if (original) {
      logger.info('不動産の更新を開始します');
      const data = await DataStore.save(
        RealEstate.copyOf(original, (updated) => {
          // ワンチャンここがバグ
          updated.content = instance.content;
          updated.majorNum = instance.majorNum;
          updated.noCheck = instance.noCheck;
        }),
      );

      return data;
    }
    logger.info('不動産の作成を開始します');
    const data = await DataStore.save(new RealEstate(instance));

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const MutateRealEstateOnlyMajorNum = async (
  id: string,
  instance: ModelInit<RealEstate, RealEstateMetaData>,
): Promise<RealEstate> => {
  try {
    logger.info('不動産の作成または更新を開始します');
    const original = await DataStore.query(RealEstate, id);

    if (original) {
      logger.info('不動産の更新を開始します');
      const data = await DataStore.save(
        RealEstate.copyOf(original, (updated) => {
          updated.majorNum = instance.majorNum;
        }),
      );

      return data;
    }
    logger.info('不動産の作成を開始します');
    const data = await DataStore.save(new RealEstate(instance));

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetRealEstate = async (): Promise<RealEstate[]> => {
  try {
    logger.info('不動産の全項目のフェッチを開始します');
    const data = await DataStore.query(RealEstate);
    logger.debug(data);
    logger.info('不動産の全項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteRealEstate = async (): Promise<void> => {
  try {
    logger.info('不動産の削除を開始します');
    const data = await DataStore.query(RealEstate);
    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);

          return data;
        }),
      );
    }
    logger.debug(data);
    logger.info('不動産の削除が完了しました');

    // return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
