/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router';
import { User } from 'domains/apigateway/services/get-user';
import './chatView.css';
import {
  alreadyReadMesssage,
  GetChatRoomFromAssigneeToAdmin,
  listMessage,
} from 'domains/cognite/services/manageChat';
import { useQuery } from 'react-query';
import { logger } from 'utils/logger';
import AdminNav from '../organisms/adminNav';
import defaultPlofileIcon from '../images/defaultProfileIcon.svg';

type State = {
  client: User;
  assigneeId: string;
};

const ExecutorChatView: FC = () => {
  const location = useLocation();
  const { client, assigneeId } = location.state as State;

  const { data: chatRoom } = useQuery(
    ['executor_chatroom', client.id],
    () => GetChatRoomFromAssigneeToAdmin(client.id),
    { enabled: !!client, cacheTime: 200 },
  );

  if (!chatRoom) {
    logger.info(chatRoom);
    throw Error('chatroomが未定義です');
  }

  if (!client) {
    logger.info(client);
    throw Error('clientが見つかりません');
  }

  const { data: messages } = useQuery(
    ['executor_message', client.id],
    () => listMessage(chatRoom),
    { cacheTime: 2000 },
  );

  if (messages === undefined) {
    logger.info(messages);
    throw Error('messageが未定義です');
  }

  useEffect(() => {
    void alreadyReadMesssage(client.id, assigneeId);
  });

  const chatOrderChange = messages.slice().sort((a, b) => {
    const createdDateA = Date.parse(a.createdAt!);
    const createdDateB = Date.parse(b.createdAt!);

    return createdDateA > createdDateB
      ? 1
      : createdDateA < createdDateB
      ? -1
      : 0;
  });

  return (
    <>
      <AdminNav />
      <div className="admin-executor-chat-list">
        <div className="admin-executor-chat-list-wrapper">
          <h1>遺言執行者管理</h1>
          <p>遺言者と遺言執行者のチャット履歴</p>
          <div className="admin-executor-chat-container">
            <div>
              <p>{client.email}</p>
            </div>
            <div className="admin-executor-chat-area">
              {chatOrderChange.map((ele, index) => {
                const myMesageClassName =
                  ele.ownerId === assigneeId
                    ? 'admin-executor-my-message'
                    : 'admin-executor-other-message';

                return (
                  <div
                    key={`message_${index}`}
                    className={`adminExecutorMessage ${myMesageClassName}`}
                  >
                    <div>
                      <div className="adminExecutorChatPlofileIcon">
                        <img src={defaultPlofileIcon} alt="" />
                      </div>
                      <div className="adminExecutorChatAndName">
                        <p>{client.email}</p>
                        <p key={`message_content_${ele.id}`}>{ele.content}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExecutorChatView;
