import { FC, useRef, FormEvent, useState } from 'react';
// import { useSync } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { GetUser, ChangeEmail } from 'domains/cognite/services/manageUser';
import { logger } from 'utils/logger';
import './userEmail.css';
import UserLogoHeader from '../organisms/userLogoHeader';
import NavigateBtn from '../atoms/navigateBtn';
import CommonFooter from '../../../common/apply/templates/commonFooter';

const UserEmail: FC = () => {
  // useSync();
  const { data: user } = useQuery(['current_auth_user_email'], GetUser);
  const navigate = useNavigate();
  const emailRef = useRef<HTMLInputElement>(null);
  const [alertEmail, setAlertEmail] = useState(false);
  const emailAlertMessage = alertEmail ? 'alertMessage' : '';

  if (!user) {
    return <></>;
  }

  const changeEmail = async (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!emailRef.current?.value) {
      setAlertEmail(true);
    } else {
      try {
        if (emailRef.current && emailRef.current.value) {
          const email = emailRef.current.value;
          await ChangeEmail(emailRef.current.value).then(() => {
            logger.info('メールアドレス変更成功');
            navigate('/user/email/confirm', { state: { email } });
          });
        }
      } catch (error) {
        logger.error('メールアドレス変更失敗');
      }
    }
  };

  return (
    <>
      <UserLogoHeader />
      <div className="user-email">
        <h1>メールアドレスの変更</h1>
        <form>
          <p>
            新しいメールアドレスを
            <br className="sp-only" />
            入力してください。
          </p>
          <p>手続きの案内をお送りします。</p>
          <div>
            <p>現在のメールアドレス</p>
            <p>{user.attributes.email}</p>
          </div>
          <div>
            <label htmlFor="email">
              <p>新しいメールアドレス</p>
              <input
                type="email"
                id="email"
                ref={emailRef}
                placeholder="メールアドレスを入力"
              />
            </label>
            <p className={`deleteMessage ${emailAlertMessage}`}>
              メールアドレスが入力されていません
            </p>
          </div>
          <input type="submit" value="変更する" onClick={changeEmail} />
        </form>
        <NavigateBtn />
        <CommonFooter />
      </div>
    </>
  );
};

export default UserEmail;
