import { FC, ReactElement } from 'react';
import { Navigate } from 'react-router';
import { useAuth } from 'hooks';

type Props = {
  children: ReactElement;
};

const SignOutGuard: FC<Props> = (props) => {
  const { children } = props;
  const { group } = useAuth();

  if (group === 'User') {
    return <Navigate to="/user" replace />;
  }

  if (group === 'Executor') {
    return <Navigate to="/executor" replace />;
  }

  if (group === 'Admin') {
    return <Navigate to="/admin/executor/list" replace />;
  }

  return children;
};

export default SignOutGuard;
