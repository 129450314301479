import { FC } from 'react';
import { Link } from 'react-router-dom';
import { WillQuestionData } from 'common/components/data/willQuestionData';
import UserLogoHeader from 'common/components/organisms/userLogoHeader';
import QuestionCard from 'common/components/organisms/questionCard';
import CommonFooter from 'common/apply/templates/commonFooter';
import NavigateBtn from '../../../user/components/atoms/navigateBtn';
import './willQuestionAfterLogin.css';

const WillQuestionAfterLogin: FC = () => (
  <>
    <UserLogoHeader />
    <div className="faq willfaq">
      <p>よくあるご質問</p>
      <div>
        <p>お客様用</p>
      </div>
      <Link to="/executor/executorquestion">
        <p>登録資格者用のよくあるご質問＞</p>
      </Link>
      {WillQuestionData.map((data) => (
        <QuestionCard
          questionNumber={data.questionNumber}
          question={data.question}
          answer={data.answer}
          key={data.questionNumber}
        />
      ))}
      <NavigateBtn />
    </div>
    <CommonFooter />
  </>
);

export default WillQuestionAfterLogin;
