/* eslint-disable react/require-default-props */
import { FC } from 'react';
import HeaderLogo from '../images/headerLogo.svg';
import WillFooterMenuAfterLoginContent from '../organisms/willFooterMenuAfterLoginContent';
import minusBtn from '../images/minusBtn.svg';
import './willFooterMenu.css';

type Props = {
  show: boolean;
  setShow: () => void;
  normalLink: (link: string) => void;
  onClickFunc?: (link: string) => Promise<void> | void;
};

const WillFooterMenuAfterLogin: FC<Props> = (props) => {
  const { show, setShow, onClickFunc, normalLink } = props;

  if (show) {
    return (
      <div id="footerOverlay" className="willFooterMenuAfterLogin">
        <div>
          <img src={HeaderLogo} alt="" />
        </div>
        <WillFooterMenuAfterLoginContent
          onClickFunc={onClickFunc}
          normalLink={normalLink}
          setShow={setShow}
        />
        <button type="button" onClick={() => setShow()}>
          <img src={minusBtn} alt="minus-Button" />
          <p>閉じる</p>
        </button>
      </div>
    );
  }

  return null;
};

export default WillFooterMenuAfterLogin;
