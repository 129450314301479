/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import { DataStore } from 'aws-amplify';
import { LegalHeirQuestion, LegalHeirQuestionMetaData } from 'models';
import { logger } from 'utils/logger';
import { ModelInit } from '@aws-amplify/datastore';

export const MutateLegalHeirQuestion = async (
  id: string,
  instance: ModelInit<LegalHeirQuestion, LegalHeirQuestionMetaData>,
): Promise<LegalHeirQuestion> => {
  try {
    logger.info('法定相続人の質問の作成または更新を開始します');
    const original = await DataStore.query(LegalHeirQuestion, id);

    if (original) {
      logger.info('法定相続人の質問の更新を開始します');
      const data = await DataStore.save(
        LegalHeirQuestion.copyOf(original, (updated) => {
          updated.question1 = instance.question1;
          updated.question2 = instance.question2;
          updated.question3 = instance.question3;
          updated.question4 = instance.question4;
          updated.question5 = instance.question5;
          updated.question6 = instance.question6;
          updated.question7 = instance.question7;
          updated.question8 = instance.question8;
          updated.question9 = instance.question9;
          updated.question10 = instance.question10;
          updated.question11 = instance.question11;
        }),
      );

      return data;
    }
    logger.info('法定相続人の質問の作成を開始します');
    const data = await DataStore.save(new LegalHeirQuestion(instance));

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetLegalHeirQuestion = async (): Promise<LegalHeirQuestion[]> => {
  try {
    logger.info('法定相続人の質問のフェッチを開始します');
    const data = await DataStore.query(LegalHeirQuestion);
    logger.debug(data);
    logger.info('法定相続人の質問のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteLegalHeirQuestion = async (): Promise<
  LegalHeirQuestion[]
> => {
  try {
    logger.info('法定相続人の質問の削除を開始します');
    const data = await DataStore.query(LegalHeirQuestion);
    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);

          return data;
        }),
      );
    }
    logger.debug(data);
    logger.info('法定相続人の質問のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
