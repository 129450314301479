/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { FC, useRef, useState } from 'react';
import './executorSearchContent.css';
import { Link } from 'react-router-dom';
import { Prefecture, PrefectureArray } from '../data/prefecture';
import SearchIcon from '../images/searchIcon.svg';

type Props = {
  getExecutorAndChangePage: (
    name?: string | undefined,
    zone?: string | undefined,
    passport?: string | undefined,
  ) => Promise<void>;
  scrollTopForSearch: () => void;
};

type cityProps = {
  prefCode: string;
  cityCode: string;
  cityName: string;
  bigCityFlag: string;
};

const ExecutorSearchContent: FC<Props> = (props) => {
  const { getExecutorAndChangePage, scrollTopForSearch } = props;

  const [city, setCity] = useState<cityProps[]>([]);
  const prefectureRef = useRef<HTMLSelectElement>(null);
  const citiesRef = useRef<HTMLSelectElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  // const zoneRef = useRef<HTMLSelectElement>(null);
  const passportRef1 = useRef<HTMLInputElement>(null);
  const passportRef2 = useRef<HTMLInputElement>(null);
  const passportRef3 = useRef<HTMLInputElement>(null);
  const passportRef4 = useRef<HTMLInputElement>(null);
  const passportRef5 = useRef<HTMLInputElement>(null);
  const passportRef6 = useRef<HTMLInputElement>(null);

  const queryParamPassport = (
    check1: boolean | undefined,
    check2: boolean | undefined,
    check3: boolean | undefined,
    check4: boolean | undefined,
    check5: boolean | undefined,
    check6: boolean | undefined,
  ) => {
    const shikkoushi = check1 ? '遺言執行士,' : '';
    const bengoshi = check2 ? '弁護士,' : '';
    const shihoushoshi = check3 ? '司法書士,' : '';
    const gyouseishoshi = check4 ? '行政書士,' : '';
    const zeirishi = check5 ? '税理士,' : '';
    const other = check6 ? '宅地建物取引士' : '';
    const passports =
      shikkoushi + bengoshi + shihoushoshi + gyouseishoshi + zeirishi + other;

    return passports;
  };

  const getCity = async () => {
    const selectLabel = prefectureRef.current
      ? prefectureRef.current.value
      : '';

    const selectArr = PrefectureArray.find((ele) => ele.label === selectLabel);
    const selectId = selectArr ? selectArr.id : 48;

    await fetch(
      `https://opendata.resas-portal.go.jp/api/v1/cities?prefCode=${selectId}`,
      {
        headers: { 'X-API-KEY': 'yvet69MOZSiyzRoinuvNJaWjcXaqhoP118HTqciA' },
      },
    )
      .then((response) => response.json())
      .then((citiesData) => {
        setCity(citiesData.result);
      });
  };

  return (
    <div className="executor-search-content">
      <div>
        <div>
          <p>検索条件</p>
          <p>検索したい条件に入力してください。</p>
          <div className="executor-search-content-require-inputs">
            <label htmlFor="bank">
              <p>名前を指定して検索する</p>
              <input
                type="search"
                id="bank"
                name="bank"
                placeholder="入力してください"
                ref={nameRef}
              />
            </label>
            <label htmlFor="prefecture">
              <p>遺言執行者の活動地域から検索する</p>
              <div>
                <select
                  id="prefecture"
                  name="prefecture"
                  className="prefecture"
                  ref={prefectureRef}
                  onChange={async () => {
                    await getCity();
                  }}
                >
                  <option value="" selected hidden>
                    都道府県を選択
                  </option>
                  <option value="全国">全国</option>
                  {PrefectureArray.map((prefecture: Prefecture) => (
                    <option key={prefecture.label} value={prefecture.label}>
                      {prefecture.label}
                    </option>
                  ))}
                </select>
                <select name="cities" id="cities" ref={citiesRef}>
                  <option value="" selected hidden>
                    市区町村を選択
                  </option>
                  {city.map((ele) => (
                    <option value={ele.cityName} key={ele.cityName}>
                      {ele.cityName}
                    </option>
                  ))}
                </select>
              </div>
            </label>
          </div>
          <div className="executor-search-content-require-btns">
            <p>所持資格から検索する（複数選択可能）</p>
            <div>
              <input
                type="checkbox"
                id="check1"
                name="group1"
                ref={passportRef1}
              />
              <label htmlFor="check1">遺言執行士{}</label>
              <input
                type="checkbox"
                id="check2"
                name="group1"
                ref={passportRef2}
              />
              <label htmlFor="check2">弁護士{}</label>
              <input
                type="checkbox"
                id="check3"
                name="group1"
                ref={passportRef3}
              />
              <label htmlFor="check3">司法書士{}</label>
              <input
                type="checkbox"
                id="check4"
                name="group1"
                ref={passportRef4}
              />
              <label htmlFor="check4">行政書士{}</label>
              <input
                type="checkbox"
                id="check5"
                name="group1"
                ref={passportRef5}
              />
              <label htmlFor="check5">税理士{}</label>
              <input
                type="checkbox"
                id="check6"
                name="group1"
                ref={passportRef6}
              />
              <label htmlFor="check6">宅地建物取引士{}</label>
            </div>
            <button
              type="submit"
              className="searchBtn"
              onClick={async () => {
                await getExecutorAndChangePage(
                  nameRef.current?.value || undefined,
                  `${prefectureRef.current ? prefectureRef.current.value : ''}${
                    citiesRef.current ? citiesRef.current.value : ''
                  }`,
                  queryParamPassport(
                    passportRef1.current?.checked,
                    passportRef2.current?.checked,
                    passportRef3.current?.checked,
                    passportRef4.current?.checked,
                    passportRef5.current?.checked,
                    passportRef6.current?.checked,
                  ),
                );
                scrollTopForSearch();
              }}
            >
              <img src={SearchIcon} alt="" />
              <p>検索</p>
            </button>
            <Link
              to="/user/executorallocation"
              className="back-to-executor-change"
            >
              選定方法を変更
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExecutorSearchContent;
