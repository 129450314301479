import { VFC } from 'react';
import { Route, Routes, Navigate } from 'react-router';
// これはファイル置く場所考える
import ScrollToTop from 'ScrollToTop';
import { Amplify, AuthModeStrategyType } from 'aws-amplify';
import awsExports from 'aws-exports';
import UserRouter from 'routes/userRouter';
import CommonRouter from 'routes/commonRouter';
import ExecutorRouter from 'routes/executorRouter';
import AdminRouter from 'routes/adminRouter';
import './App.css';

Amplify.configure({
  ...awsExports,
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  },
});

const App: VFC = () => (
  <>
    <ScrollToTop />
    <Routes>
      <Route path="/*" element={<CommonRouter />} />
      <Route path="user/*" element={<UserRouter />} />
      <Route path="executor/*" element={<ExecutorRouter />} />
      <Route path="admin/*" element={<AdminRouter />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  </>
);

export default App;
