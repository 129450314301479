/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import { FC, useRef, FormEvent, useState } from 'react';
import { Auth } from 'aws-amplify';
import { logger } from 'utils/logger';
import { useNavigate, useLocation } from 'react-router-dom';
import '../reset.css';
import './changePassword.css';
import UserLogoHeader from 'user/components/organisms/userLogoHeader';
import CommonFooter from './apply/templates/commonFooter';
import NavigateBtn from '../user/components/atoms/navigateBtn';

type Props = {
  emailForForget: string | null;
};

type LocationState = {
  userType: 'user' | 'executor';
} | null;

const ChangePassword: FC<Props> = (props) => {
  const { emailForForget } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as LocationState;
  const userType = locationState ? locationState.userType : 'user';
  // const emailRef = useRef<HTMLInputElement>(null);
  const verificationCodeRef = useRef<HTMLInputElement>(null);
  const newPasswordRef = useRef<HTMLInputElement>(null);
  const [alertEmail, setAlertEmail] = useState(false);
  const [alertPassword, setAlertPassword] = useState(false);
  const emailAlertMessage = alertEmail ? 'alertMessage' : '';
  const passwordAlertMessage = alertPassword ? 'alertMessage' : '';

  const changePassword = async (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!verificationCodeRef.current?.value && !newPasswordRef.current?.value) {
      setAlertEmail(true);
      setAlertPassword(true);
    } else if (!verificationCodeRef.current?.value) {
      setAlertEmail(true);
      setAlertPassword(false);
    } else if (!newPasswordRef.current?.value) {
      setAlertEmail(false);
      setAlertPassword(true);
    } else {
      try {
        if (
          emailForForget &&
          verificationCodeRef.current &&
          newPasswordRef.current &&
          verificationCodeRef.current.value &&
          newPasswordRef.current.value
        ) {
          await Auth.forgotPasswordSubmit(
            emailForForget,
            verificationCodeRef.current.value, // 認証コード
            newPasswordRef.current.value, // 新しいパスワード
          );
          if (userType === 'user') {
            navigate('/willSignIn');
          } else if (userType === 'executor') {
            navigate('/executorSignIn');
          }
          // navigate('/signIn');
        }
      } catch (error) {
        logger.error('パスワード変更失敗');
        logger.error('error signing in', error);
      }
      setAlertEmail(false);
      setAlertPassword(false);
    }
  };

  return (
    <>
      <UserLogoHeader />
      <div className="ChangePassword">
        <h1>パスワードの変更</h1>
        <p>
          新しいパスワードを
          <br className="sp-only" />
          設定してください
        </p>
        <form action="" className="changePasswordForm">
          {/* <label htmlFor="email">
            メールアドレス
            <input
              type="email"
              id="email"
              ref={emailRef}
              placeholder="メールアドレスを入力"
            />
          </label> */}
          <div>
            <label htmlFor="verificationCode">
              認証コード
              <input
                type="text"
                id="verificationCode"
                ref={verificationCodeRef}
                placeholder="認証コードを入力"
              />
            </label>
            <p className={`deleteMessage ${emailAlertMessage}`}>
              メールアドレスが入力されていません
            </p>
          </div>
          <div>
            <label htmlFor="newPassword">
              新しいパスワード
              <input
                type="password"
                id="newPassword"
                ref={newPasswordRef}
                placeholder="新しいパスワードを入力"
              />
            </label>
            <p className={`deleteMessage ${passwordAlertMessage}`}>
              メールアドレスが入力されていません
            </p>
          </div>
          <input
            type="submit"
            value="新しいパスワードを設定する"
            onClick={async (e) => {
              await changePassword(e);
            }}
          />
        </form>
        <NavigateBtn />
        <CommonFooter />
      </div>
    </>
  );
};

export default ChangePassword;
