/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import { FC, useState, useEffect, useRef, FormEvent, ChangeEvent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { DataStore } from 'aws-amplify';
import { Message, AssignStatus } from 'models';
import { GetUser } from 'domains/cognite/services/manageUser';
import {
  GetChatRoomFromAssignee,
  listMessage,
  CreateMessage,
  alreadyReadMesssage,
} from 'domains/cognite/services/manageChat';
import { QueryAssignRelationshipFromBoth } from 'domains/cognite/services/manageAssignRelationship';
import { User } from 'domains/apigateway/services/get-user';
import {
  sendEmailForChat,
  SendEmailForChatReqBody,
} from 'domains/apigateway/services/sendEmail';
import { logger } from 'utils/logger';
import './chat.css';
import ExecutorLogoHeader from 'common/apply/organisms/executorLogoHeader';
import ExecutorTextHeader from '../organisms/executorTextHeader';
import defaultPlofileIcon from '../images/defaultProfileIcon.svg';
import adminIcon from '../images/admin_icon.svg';
import chatBackAllow from '../images/chatBackAllow.svg';

type State = {
  client: User;
  assigneeId: string;
};

const Chat: FC = () => {
  const location = useLocation();
  const { client, assigneeId } = location.state as State;

  const { data: executor } = useQuery(['authenticated_user'], GetUser);
  if (!executor) {
    logger.error(executor);
    throw Error;
  }

  const { data: chatRoom } = useQuery(
    ['executor_chatroom', client.id],
    () => GetChatRoomFromAssignee(client.id),
    { enabled: !!client, cacheTime: 200 },
  );

  if (!chatRoom) {
    logger.info(chatRoom);
    throw Error('chatroomが未定義です');
  }

  if (!client) {
    logger.info(client);
    throw Error('clientが見つかりません');
  }

  const { data: messages } = useQuery(
    ['executor_message', client.id],
    () => listMessage(chatRoom),
    { cacheTime: 200 },
  );

  if (messages === undefined) {
    logger.info(messages);
    throw Error('messageが未定義です');
  }

  const { data: bothRelationshipOfApprovalCheck } = useQuery(
    ['both_relation_Approval_check'],
    () => QueryAssignRelationshipFromBoth(client.id, assigneeId),
  );

  if (!bothRelationshipOfApprovalCheck) {
    logger.info(bothRelationshipOfApprovalCheck);
    throw Error('対象のIDが見つかりません');
  }

  const botnRelationshipOfApproval =
    bothRelationshipOfApprovalCheck.status === AssignStatus.APPROVAL;

  const [input, setInput] = useState<string>('');
  const [chatMessages, setChatMessages] = useState<Message[]>(messages);

  const chatAreaRef = useRef<HTMLDivElement>(null);

  const scrollChatAreaToBottom = () => {
    if (chatAreaRef.current) {
      chatAreaRef.current.scrollIntoView();
    }
  };

  const messagesOfMine = chatMessages.filter(
    (ele) => ele.ownerId === assigneeId,
  );

  const messagesLimitJudge = messagesOfMine.length < 5;

  const enableOrNotTextarea =
    messagesLimitJudge && botnRelationshipOfApproval
      ? 'enable-textarea'
      : 'disabled-textarea';

  useEffect(() => {
    void alreadyReadMesssage(client.id, assigneeId);
  });

  useEffect(() => {
    const subscription = DataStore.observe(Message).subscribe((msg) => {
      logger.info(msg.model, msg.opType, msg.element);
      const list = async () => {
        const listmessages = await listMessage(chatRoom);
        setChatMessages(listmessages);
      };
      void list();
    });

    scrollChatAreaToBottom();

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    scrollChatAreaToBottom();
  }, [chatMessages]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await CreateMessage(assigneeId, client.id, chatRoom, input);
    setInput('');

    const body: SendEmailForChatReqBody = {
      fromEmail: executor.attributes.email,
      toEmail: client.email,
      senderType: 'executor',
    };

    await sendEmailForChat(body);
  };

  const chatDummyRef = useRef<HTMLDivElement>(null);

  const ChatTextContent = document.getElementById('exeChatDummyTextContent');

  const TextareaReset = () => {
    ChatTextContent ? (ChatTextContent.textContent = '') : '';
  };

  const chatOrderChange = chatMessages.slice().sort((a, b) => {
    const createdDateA = Date.parse(a.createdAt!);
    const createdDateB = Date.parse(b.createdAt!);

    return createdDateA > createdDateB
      ? 1
      : createdDateA < createdDateB
      ? -1
      : 0;
  });

  const textareaIndention = (e: ChangeEvent<HTMLTextAreaElement>) => {
    handleChange(e);
    chatDummyRef.current
      ? (chatDummyRef.current.textContent = `${e.target.value}\u200b`)
      : null;
  };

  const chatTextareaInner = useRef<HTMLTextAreaElement | null>(null);
  const chatTextareaBtn = useRef<HTMLButtonElement | null>(null);

  const chatTextarelimit = () => {
    if (chatTextareaInner.current?.innerHTML) {
      chatTextareaInner.current.innerHTML.length > 141
        ? chatTextareaBtn.current?.classList.add('disabled-textarea')
        : chatTextareaBtn.current?.classList.remove('disabled-textarea');
    }
  };

  return (
    <>
      <ExecutorLogoHeader />
      <ExecutorTextHeader title="チャット" />
      <div className="executorChat">
        <div className="executorChatContent">
          <div className="executorNameFixed">
            <p>{client.email}</p>
          </div>
          <div className="executor-chat-area">
            <div className="executor-chat-default-message">
              <div>
                <p className="message-content">
                  メッセージが可能な回数は
                  <span className="chat-orange">お一人様5回まで</span>
                  となっております。
                  <br />
                  5回を超える場合は、直接担当の遺言者にご連絡してください。
                  <br />
                  尚、1度に送信可能な文字数は140字となっております。
                </p>
              </div>
            </div>
            {chatOrderChange.map((ele, index) => {
              const myMesageClassName =
                ele.ownerId === assigneeId
                  ? 'executor-my-message'
                  : 'executor-other-message';

              return (
                <div
                  key={`message_${ele.id}`}
                  className={`executorMessage ${myMesageClassName}`}
                  ref={index === chatMessages.length - 1 ? chatAreaRef : null}
                >
                  <div>
                    <div className="executorChatPlofileIcon">
                      <img src={defaultPlofileIcon} alt="" />
                    </div>
                    <div className="executorChatAndName">
                      <p>{client.email}</p>
                      <p
                        key={`message_content_${ele.id}`}
                        className="executor-message-content"
                      >
                        {ele.content}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
            {!botnRelationshipOfApproval ? (
              <div className="executorMessage">
                <div>
                  <div className="executorChatPlofileIcon">
                    <img src={defaultPlofileIcon} alt="" />
                  </div>
                  <div className="executorChatAndName ExitChatroomsMessage">
                    <p>{client.email}</p>
                    <p className="executor-message-content">
                      遺言者がチャットを退出しました。
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            {!messagesLimitJudge ? (
              <div className="executorMessage">
                <div>
                  <div className="executorChatPlofileIcon">
                    <img src={adminIcon} alt="" />
                  </div>
                  <div className="executorChatAndName ExitChatroomsMessage">
                    <p>運営</p>
                    <p className="executor-message-content">
                      メッセージ可能上限数に達しました。
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="exeChatFormWrap">
          <form onSubmit={handleSubmit} className="exeChatForm">
            <div ref={chatDummyRef} id="exeChatDummyTextContent">
              {}
            </div>
            <textarea
              maxLength={140}
              className={enableOrNotTextarea}
              value={input}
              onChange={(e) => textareaIndention(e)}
              onKeyUp={chatTextarelimit}
              ref={chatTextareaInner}
            />
            <button
              type="submit"
              ref={chatTextareaBtn}
              onClick={TextareaReset}
              disabled={!input}
            >
              送信
            </button>
          </form>
        </div>
        <div className="executorChatBack chat-pc-only">
          <Link to="/executor/chatList">メッセージ一覧へ</Link>
        </div>
        <Link
          className="executorChatBackAllow chat-sp-only"
          to="/executor/chatList"
        >
          <img src={chatBackAllow} alt="" />
        </Link>
      </div>
    </>
  );
};

export default Chat;
