/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import Slider from 'react-slick';
import pageData from '../data/pageData';
import PageDescription from '../organisms/pageDescription';
import WillInfoBack from '../images/descriptionBackImg.svg';
import './WillInfoDescription.css';
import '../atoms/descriptionInput.css';

const WillInfoDescription: FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  return (
    <div className="WillInfoDescription">
      <div>
        <Slider {...settings}>
          <PageDescription
            page="realEstatePage"
            description={pageData[0].description}
            NotParamDescription="NotParamDescription"
          />
          {/* <PageDescription
            page="realEstatePage"
            description={pageData[1].description}
          /> */}
        </Slider>
      </div>
      <img src={WillInfoBack} alt="遺言者情報背景画像" />
    </div>
  );
};

export default WillInfoDescription;
