import { FC } from 'react';
import ExecutorLogoHeader from '../organisms/executorLogoHeader';
import ExecutorProcedureSendContent from '../organisms/executorProcedureSendContent';
import CommonFooter from '../templates/commonFooter';

const ExecutorProcedureSend: FC = () => (
  <>
    <ExecutorLogoHeader />
    <ExecutorProcedureSendContent />
    <CommonFooter />
  </>
);

export default ExecutorProcedureSend;
