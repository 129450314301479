import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Topics } from 'models';
import {
  GetOneTopic,
  ListTopicsAll,
  ListTopicsReleased,
} from 'domains/cognite/services/manageTopics';
import { GetS3 } from 'domains/aws/services/get-s3';
import { formatDateToCleanStr } from 'utils/dateFormat';
import sampleImage from 'common/components/images/post_sample_image.png';

type UseTopicDetail = {
  updatedAt: string;
  title: string;
  content: string;
  imageUrl: string;
  isDraft: boolean;
  prevId: string | undefined;
  nextId: string | undefined;
};

const useTopicDetail = (args: {
  onlyReleased: boolean;
}): UseTopicDetail | undefined => {
  const { onlyReleased } = args;
  const { postId } = useParams();

  const inputPostId = postId || '';

  const { data: theTopic } = useQuery(['topics', postId], () =>
    GetOneTopic(inputPostId),
  );

  const listTopics = onlyReleased ? ListTopicsReleased : ListTopicsAll;

  const { data: topicsFromQuery } = useQuery(['topics', 'list'], listTopics);

  const topics = topicsFromQuery || ([] as Topics[]);

  const thumbnail = theTopic ? theTopic.thumbnail : '';

  const { data: getImage } = useQuery(['thumbnail', thumbnail], () =>
    GetS3(thumbnail, 'public'),
  );

  if (!theTopic) {
    return undefined;
  }

  const { updatedAt, title, content, id, isDraft } = theTopic;

  const imageUrl = getImage || sampleImage;

  const updatedAtDate = new Date(updatedAt || '');
  const updatedAtDateFormated = updatedAt
    ? formatDateToCleanStr(updatedAtDate)
    : '';

  const currentIndex = topics.findIndex((ele) => ele.id === id);
  const firstIndex = 0;
  const lastIndex = topics.length - 1;

  const prevTopic =
    currentIndex !== firstIndex ? topics[currentIndex - 1] : undefined;
  const nextTopic =
    currentIndex !== lastIndex ? topics[currentIndex + 1] : undefined;

  const prevId = prevTopic ? prevTopic.id : undefined;
  const nextId = nextTopic ? nextTopic.id : undefined;

  return {
    updatedAt: updatedAtDateFormated,
    title,
    content,
    imageUrl,
    isDraft,
    prevId,
    nextId,
  };
};

export default useTopicDetail;
