import { ChangeEvent, FC, useState } from 'react';
import { Link } from 'react-router-dom';
import InputItem from 'user/components/atoms/InputItem';
import UserLogoHeader from 'user/components/organisms/userLogoHeader';
import CommonFooter from './apply/templates/commonFooter';
import NavigateBtn from '../user/components/atoms/navigateBtn';
import './contact.css';

const Contact: FC = () => {
  const [name, setName] = useState('-');
  const [company, setCompany] = useState('-');
  const [position, setPosition] = useState('-');
  const [phoneNumber, setPhoneNumber] = useState('-');
  const [email, setEmail] = useState('-');
  const [inquiry, setInquiry] = useState('-');

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const handleChangeCompany = (e: ChangeEvent<HTMLInputElement>) => {
    setCompany(e.target.value);
  };
  const handleChangePosition = (e: ChangeEvent<HTMLInputElement>) => {
    setPosition(e.target.value);
  };
  const handleChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  };
  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleChangeContent = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInquiry(e.target.value);
  };

  return (
    <>
      <UserLogoHeader />
      <div className="ContactForm">
        <h1>お問い合わせ</h1>
        <div>
          <div className="ContactFormWrapper">
            <div>
              <InputItem title="お名前" name="require" />
              <input
                type="text"
                onChange={handleChangeName}
                placeholder="名前を入力"
              />
            </div>
            <div>
              <InputItem title="会社名" name="optional" />
              <input
                type="text"
                onChange={handleChangeCompany}
                placeholder="会社名を入力"
              />
            </div>
            <div>
              <InputItem title="役職" name="optional" />
              <input
                type="text"
                onChange={handleChangePosition}
                placeholder="役職を入力"
              />
            </div>
            <div>
              <InputItem title="電話番号" name="optional" />
              <input
                type="tel"
                onChange={handleChangePhone}
                placeholder="電話番号を入力"
              />
            </div>
            <div>
              <InputItem title="メールアドレス" name="require" />
              <input
                type="email"
                onChange={handleChangeEmail}
                placeholder="メールアドレスを入力"
              />
            </div>
            <div>
              <InputItem title="お問い合わせ" name="require" />
              <textarea
                onChange={handleChangeContent}
                placeholder="お問い合わせ内容を入力"
              />
            </div>
          </div>
        </div>
        {/* tordo 後で消す↓↓ */}
        {/* <button type="button" onClick={sendEmail}>
          メール送信
        </button> */}
        <Link
          to="/contact/confirm"
          state={{
            name,
            company,
            position,
            phoneNumber,
            email,
            inquiry,
          }}
        >
          確認する
        </Link>
        <NavigateBtn />
      </div>
      <CommonFooter />
    </>
  );
};

export default Contact;
