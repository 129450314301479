/* eslint-disable no-alert */
import { FC } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  GetOneTopic,
  DeleteOneTopic,
} from 'domains/cognite/services/manageTopics';
import { GetS3File } from 'domains/aws/services/get-s3';
import DeleteS3 from 'domains/aws/services/delete-s3';
import editIcon from '../images/edit-icon.svg';
import delateIcon from '../images/delete-icon.svg';
import './topicsEditRemoveBtns.css';

const TopicsOparationBtns: FC = () => {
  const location = useLocation();
  const { postId } = useParams();
  const navigate = useNavigate();

  const inputPostId = postId || '';

  const { data: targetTopic } = useQuery(['topics', postId], () =>
    GetOneTopic(inputPostId),
  );

  const thumbnail = targetTopic ? targetTopic.thumbnail : undefined;

  const { data: file } = useQuery(
    ['thumbnail', 'file', thumbnail],
    () => GetS3File(thumbnail || '', 'public'),
    {
      enabled: !!thumbnail,
    },
  );

  if (!location.pathname.includes('/detail/') || !targetTopic) {
    return <></>;
  }

  const toEditPage = () => {
    const state = {
      id: targetTopic.id,
      thumbnail: targetTopic.thumbnail,
      file,
      title: targetTopic.title,
      content: targetTopic.content,
      isDraft: targetTopic.isDraft,
    };

    navigate(`/admin/topics/edit/${targetTopic.id}`, { state });
  };

  const remove = async () => {
    const confirm = window.confirm('記事を削除してもよろしいですか？');
    if (confirm) {
      await DeleteS3(targetTopic.thumbnail);
      await DeleteOneTopic(targetTopic.id);
      window.alert('記事を削除しました');
      navigate('/admin/topics/list');
    }
  };

  return (
    <div className="topicsOperationBtns">
      <button type="button" className="edit-btn" onClick={toEditPage}>
        編集
        <img src={editIcon} alt="" />
      </button>
      <button type="button" className="delete-btn" onClick={remove}>
        削除
        <img src={delateIcon} alt="" />
      </button>
    </div>
  );
};

export default TopicsOparationBtns;
