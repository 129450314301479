import { FC } from 'react';
import { Link } from 'react-router-dom';
// import NormalBtn from 'atoms/normalBtn';
import suitWoman from '../images/suit-woman1.svg';
import './executorProcedureContent.css';

// type Props = {
//   href: string;
// };

const ExecutorProcedureContent: FC = () => (
  <div className="executorProcedure">
    <div>
      <p>
        遺言執行者名簿
        <br className="sp-only" />
        登録申込みページ
      </p>
      <div className="executorProcedure-greet">
        <p>遺言執行者名簿登録申込みページへようこそ</p>
        <p>
          このページでは、遺言執行者の名簿登録を受け付けます。
          <br />
          遺言執行者として、遺言書作成のサポートから遺言執行まで、依頼者に寄り添って仕事をしたい人は、本遺言アプリに登録し、アプリ利用者からの相談を受け、更に遺言執行者の選定を受けることができます。
          <br />
          但し、名簿登録申込できる方は、次のいずれかの資格所持者で、その業務を行うのに必要な要件を整えている方に限らせていただきます。
        </p>
        <div>
          <ol>
            <li>遺言執行士</li>
            <li>弁護士</li>
            <li>司法書士</li>
            <li>税理士</li>
            <li>行政書士</li>
            <li>宅地建物取引士</li>
          </ol>
          <img src={suitWoman} alt="" />
        </div>
      </div>
      <p>
        登録期間は1年間とし、登録料の全額前払い が必要です。また、
        <span>登録後1年を経過すると 更新手続きが必要</span>となります。
        <br />
        詳細は、規約をご覧ください。
      </p>
      <div>
        <Link to="/apply/procedureform">
          <p>登録する</p>
        </Link>
      </div>
    </div>
  </div>
);

export default ExecutorProcedureContent;
