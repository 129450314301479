/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { ChangeEvent, FC, useState } from 'react';
import { useUsersListFromAdmin } from 'hooks';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { PrefectureArray } from 'user/components/data/prefecture';
import './newUserData.css';
import { getFormatDate1, getFormatDate2 } from 'utils/dateFormat';
import DataBtns from '../organisms/dataBtns';
import AdminNav from '../organisms/adminNav';

Chart.register(...registerables);

const NewUserData: FC = () => {
  const UserDatas = useUsersListFromAdmin(['newUserData_admin']);

  // today取得（2022-01-01の型）
  const date = new Date();
  const dateYear = date.getFullYear();
  const dateMonth = `0${date.getMonth() + 1}`.slice(-2);
  const dateDay = `0${date.getDate()}`.slice(-2);
  const dateToday = `${dateYear}-${dateMonth}-${dateDay}`;

  const [inputDateString1, setInputDateString1] = useState(dateToday);
  const [inputDateString2, setInputDateString2] = useState(dateToday);

  const [selectAddress, setSelectAddress] = useState('全国');

  const getValue1 = (data: ChangeEvent<HTMLInputElement>) => {
    setInputDateString1(data.currentTarget.value);
  };

  const getValue2 = (data: ChangeEvent<HTMLInputElement>) => {
    setInputDateString2(data.currentTarget.value);
  };

  const getSelectValue = (data: ChangeEvent<HTMLSelectElement>) => {
    setSelectAddress(data.currentTarget.value);
  };

  const inputDate1 = getFormatDate1(inputDateString1);
  const inputDate2 = getFormatDate2(inputDateString2);

  const rangeData = UserDatas?.filter((ele) => {
    const date2 = new Date(ele.createdAt!);
    const usersAddress = ele.address;

    if (
      inputDate1 <= date2 &&
      date2 < inputDate2 &&
      usersAddress === selectAddress
    ) {
      return ele.createdAt;
    }

    if (inputDate1 <= date2 && date2 < inputDate2 && selectAddress === '全国') {
      return ele.createdAt;
    }
  });

  const age = [
    '10代',
    '20代',
    '30代',
    '40代',
    '50代',
    '60代',
    '70代',
    '80代',
    '90代',
  ];

  const ageBaseData: string[] = [];

  const getAgeDatas = () =>
    rangeData?.map((x) => {
      age.findIndex((y) => {
        if (x['custom:ages'] === y) {
          ageBaseData.push(y);
        }
      });
    });

  getAgeDatas();

  const ageFilterData = age.map((y) => {
    const ageFilter = ageBaseData.filter((x) => x === y).length;

    return ageFilter;
  });

  // chart
  // =============================================
  const chartData = {
    // x 軸のラベル
    labels: age,
    datasets: [
      {
        label: '新規登録者',
        // データの値
        data: ageFilterData,
        // グラフの背景色
        backgroundColor: ['rgba(255, 99, 132, 0.2)'],
        // グラフの枠線の色
        borderColor: ['rgba(255, 99, 132)'],
        // グラフの枠線の太さ
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <AdminNav />
      <div className="new-user-data">
        <div className="new-user-data-wrapper">
          <h1>遺言者のデータ分析</h1>
          <p>新規登録者数</p>
          <div className="new-user-data-chart-btns">
            <div className="new-user-data-chart">
              <div>
                <input
                  value={inputDateString1}
                  onChange={(e) => {
                    getValue1(e);
                  }}
                  type="date"
                />
                <p>〜</p>
                <input
                  value={inputDateString2}
                  onChange={(e) => {
                    getValue2(e);
                  }}
                  type="date"
                />
              </div>
              <select
                onChange={(e) => {
                  getSelectValue(e);
                }}
              >
                <option value="全国">全国</option>
                {PrefectureArray.map((ele) => (
                  <option value={ele.label}>{ele.label}</option>
                ))}
              </select>
              <Bar data={chartData} />
            </div>
            <DataBtns />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewUserData;
