/* eslint-disable react/require-default-props */
import { FC } from 'react';
import './requireCheckBtn.css';

type Props = {
  title: string;
  func?: () => void;
};

const RequireCheckBtn: FC<Props> = (props) => {
  const { title, func } = props;

  return (
    <button type="button" className="requireCheckBtn" onClick={func}>
      {title}
    </button>
  );
};

export default RequireCheckBtn;
