/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC } from 'react';
import './signUpExecutorPolicy.css';

type Props = {
  changeExecutorPolicyBtn: () => void;
};

const SignUpExecutorPolicy: FC<Props> = (props) => {
  const { changeExecutorPolicyBtn } = props;

  return (
    <>
      <div
        id="signUp-executor-policy-overlay"
        onClick={changeExecutorPolicyBtn}
      >
        <div
          className="signUp-executor-policy"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="signUp-executor-policy-content">
            <div>
              <p>遺言アプリ利用規約</p>
            </div>
            <div>
              <p>
                一般社団法人日本遺言執行士協会（以下、「当協会」という）は、当協会が提供するサービスである遺言アプリの利用について、以下のとおり利用規約を定める（以下「本規約」という）。
                遺言アプリの遺言執行者名簿に、資格者として登録した者（以下、「登録資格者」という）は、予め本規約に同意した上で、遺言アプリを利用するものとする。なお、本規約の内容は、6、（2）に従い変更することがあるので、登録資格者は最新の利用規約を参照すること。
              </p>
              <div className="signUp-executor-policy-sentence">
                <div>
                  <p>1、遺言アプリの目的</p>
                  <p>
                    遺言アプリは、遺言アプリを利用して、気軽に自身の状況に応じた遺言書の原稿を作成しようとする遺言者（以下、単に「遺言者」という）の手助けとなるとともに、遺言者が遺言書を法律上有効にするため個別のアドバイスを求める場合に、遺言執行者名簿から資格者を選んで、質問や依頼を申し込むことができるアプリである。
                    <br />
                    同時に、遺言者の登録資格者に関するコメント（以下、遺言者から遺言アプリに投稿された情報を総称して「口コミ」という）を公開することにより、他の遺言者が登録資格者を選ぶ際の参考としていただくことを目的とする。
                  </p>
                </div>
                <div>
                  <p>２、登録資格者登録手続き（入会手続き）</p>
                  <p>
                    遺言アプリの遺言執行者名簿に、登録資格者として搭載を希望する者は、遺言アプリ内の遺言執行者名簿登録申請フォームに所定の項目を入力し、協会に対して登録申請を行う。
                    <br />
                    協会は、登録申請があったら所定の登録審査を行い、申請から2週間以内に申請者に対して登録の可否を連絡する。申請者は、当協会の登録決定通知を受け取ってから2週間以内に所定の名簿登録料を支払うことにより登録資格者となる。
                    <br />
                    当協会は、登録拒否する場合でもその理由を明らかにしません。
                    <br />
                    また、当協会は登録した資格者に対し、当協会が提供するサービスの内容を異ならせることがあるが、提供するサービスの内容等は協会が任意に決定し、又は随時変更ができるものとし、その理由を明らかにしません。
                  </p>
                </div>
                <div>
                  <p>３、セキュリティーについて</p>
                  <p>
                    登録資格者の登録については、インターネット上の暗号通信技術SSL（Secure
                    Socket
                    Layer）を使用し、送信される情報は第三者に見られることのないよう暗号化される。また、コンピューターウィルスについても、ウィルス対策ソフトの導入などを行っている。
                  </p>
                </div>
                <div>
                  <p>４、ログインアカウント等の登録・管理</p>
                  <p>
                    登録資格者は、ログインアカウント及びパスワード（以下「ログインアカウント等」という。）の登録・管理等をご自身の責任において行うものとし、登録資格者の管理不十分、使用上の過誤、第三者の使用等により生じた損害について、当協会は一切の責任を負わない。また、登録資格者は、ログインアカウント等にかかるサービス（当協会以外が運営するサービスを含み、以降同様とする）の利用をご自身の責任において利用するものとし、当該サービスの利用については当該サービスの運営者が規定する各規約の定めに従うものとする。ログインアカウント等にかかるサービスを利用したことにより生じた損害、当該サービス運営者と登録資格者の間に生じたトラブル等について当協会は一切の責任を負わないものとする。
                  </p>
                </div>
                <div>
                  <p>５、個人情報について</p>
                  <p>（１）個人情報の取り扱い</p>
                  <p className="indent1 li-indent2">
                    ①
                    事業者の名称、個人情報保護管理責任者の職名、連絡先は下記のとおり。
                  </p>
                  <ul className="indent2 li-indent0">
                    <li>・事業者：一般社団法人日本遺言執行士協会</li>
                    <li>・個人情報保護管理責任者：石田 泉</li>
                    <li>・連絡先：ishida@igon.co.jp 03-6264-5005</li>
                  </ul>
                  <p className="indent1">② 利用目的</p>
                  <p className="indent2">
                    当協会は、登録資格者に対して遺言アプリを提供するにあたり、必要な一定の登録資格者の個人情報を取得しますが、これらの個人情報の利用目的は以下のとおりです。
                  </p>
                  <ul className="indent2 li-indent0">
                    <li>・登録資格者の登録に際し、所持資格の確認のため</li>
                    <li>・ログイン時における本人確認のため</li>
                    <li>
                      ・郵送、電話、電子メール等の方法により、当協会のサービスを案内するため
                    </li>
                    <li>
                      ・個人を識別できない形式に加工した上、統計データを作成するなど当事務所サービスの利用促進のため
                    </li>
                    <li>
                      ・遺言アプリ利用に関し、必要に応じて登録資格者に対し連絡をするため
                    </li>
                  </ul>
                  <p>（２）第三者提供</p>
                  <p className="indent1">
                    当協会は、以下のいずれかに該当する場合を除き、登録資格者の個人情報を第三者へ開示又は提供いたしません。
                  </p>
                  <ul className="indent1 li-indent2">
                    <li>① 登録資格者の同意があるとき</li>
                    <li>
                      ②
                      登録資格者を識別することができない状態で統計的なデータとして開示・提供するとき
                    </li>
                    <li>③ 法令に基づくとき</li>
                    <li>
                      ④
                      人の生命、身体又は財産の保護のために必要な場合で、登録資格者の同意を得ることが困難なとき
                    </li>
                    <li>
                      ⑤
                      公共の利益のため特に必要があるときで、登録資格者の同意を得ることが困難なとき
                    </li>
                    <li>
                      ⑥
                      国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要があり、登録資格者の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
                    </li>
                  </ul>
                  <p>（３）業務委託</p>
                  <p className="indent1">
                    当協会は取得した個人情報の取扱いの全部又は一部を、必要な保護措置を講じた上、上記利用目的に必要な範囲において、第三者へ委託する場合がある。
                  </p>
                  <p>（４）開示、訂正・削除、利用停止等</p>
                  <p className="indent1">
                    登録資格者が、ご自身の個人情報の利用目的の通知、開示、訂正・追加・削除、利用停止又は消去の請求を行う場合は「6、登録資格者情報について(2)」に定めた方法にてお受けいたします。
                  </p>
                  <p>（５）その他</p>
                  <p className="indent1">
                    登録に際し、すべての必須項目にご入力いただけなかった場合、遺言アプリの全部又は一部が利用できない場合がありますのでご了解下さい。
                  </p>
                </div>
                <div>
                  <p>6、登録資格者情報について</p>
                  <p>（１）登録資格者情報の利用</p>
                  <p className="indent1">
                    遺言アプリを利用する登録資格者により良いサービスを提供するために、クッキー（Cookie）、Webビーコン、端末識別子、位置情報などの登録資格者に関する情報（以下、総称して「登録資格者情報」という）を取得及び利用することがあります。
                    <br />
                    クッキーは、登録資格者のブラウザ設定によりクッキーの受け取りを拒否し、制限できますが、その際は、遺言アプリの利用において制約を受ける場合があります。Webビーコンの使用に関しては、登録資格者側で制限することが出来ない場合があります。
                    <br />
                    当協会はこれらの技術を登録資格者の個人情報を取得する目的では使用しません。
                  </p>
                  <p>（２）登録資格者の登録事項の変更等</p>
                  <p className="indent1">
                    登録資格者が、ご自身の個人情報の利用目的の通知、開示、訂正・追加・削除、利用停止又は消去の請求を行う場合は、遺言アプリ・登録資格者用ページの「メニュー」「登録変更・抹消」の釦を押して、「お問い合わせ」ページをご利用ください。
                  </p>
                </div>
                <div>
                  <p>7、IR情報としての利用について</p>
                  <p>
                    当協会は、登録資格者による遺言アプリの利用に関しクッキー又はWebビーコンを用いて計測された遺言者数、ページビュー数などの統計データを、IR情報として公開することがあります。
                  </p>
                </div>
                <div>
                  <p>8、遺言アプリの利用について</p>
                  <p>（１）アプリの転用・転売の禁止</p>
                  <p className="indent1">
                    登録資格者は、当協会が提供する遺言アプリについて、その全部あるいは一部を問わず、宗教活動、政治活動などの目的での利用又はアクセスも行ってはならないものとします。
                  </p>
                  <ul className="indent2 li-indent1">
                    <li>
                      1
                      遺言アプリへ投稿された口コミを無断転載・無断利用することは禁止します。
                    </li>
                    <li>
                      2
                      口コミを投稿した本人による当該口コミの利用等本規約が特に認めた場合を除き、遺言アプリに掲載されている口コミを登録資格者が利用して利益を得た場合は、当協会はその利益相当額の金員を請求できる権利を有するものとします。
                    </li>
                  </ul>
                  <p>（２）遺言アプリの変更・停止</p>
                  <p className="indent1">
                    当協会は登録資格者へご提供している遺言アプリを当協会の都合により変更することがあります。また、災害・事故、その他緊急事態が発生した際には、遺言アプリを停止する場合がありますので、予めご了承ください。なお、事前に登録資格者の承諾を得ることなく遺言アプリを変更・停止等したことにより、登録資格者・遺言者又は第三者が損害を受けたとしても、当協会は一切の補償はいたしません。
                  </p>
                  <p>（３）遺言アプリの利用に必要となる設備</p>
                  <p className="indent1">
                    登録資格者が遺言アプリをご利用になるためには、webにアクセスしていただく必要があります。なおwebへのアクセスについては、登録資格者は自らの責任と費用で必要な機器、ソフトウェアを適切に準備、操作していただく必要があり、当協会は登録資格者がwebにアクセスするための準備、方法などについては一切関与いたしておりません。
                  </p>
                </div>
                <div>
                  <p>9、免責事項</p>
                  <p>
                    遺言アプリは、登録資格者ご自身の責任においてご利用いただきます。また、以下の各号につき予めご了承いただくものとします。
                  </p>
                  <p>（１）登録情報</p>
                  <p className="indent1">
                    当協会は、登録資格者が掲載した、登録事項に関する情報については、いかなる保証もいたしません。遺言者からのオファーに対するレスポンスや、契約締結については、登録資格者の責任において誠実に対応願います。
                    また、掲載された登載情報によって登録資格者に生じた損害や、遺言者と登録資格者、登録資格者と登録資格者の所属団体、登録資格者同士のトラブル等について当協会は一切の補償及び関与をいたしません。
                  </p>
                  <p>（２）口コミの内容</p>
                  <p className="indent1">
                    当協会は、掲載された口コミの内容に関していかなる保証もいたしません。また、掲載された口コミによって生じた登録資格者の損害（登録資格者が作成した各種コンテンツによるコンピュータ・ウィルス感染被害なども含みます）や、遺言者と登録資格者、登録資格者同士のトラブル等に対し、当協会は一切の補償及び関与をいたしません。
                  </p>
                  <p>（３）リンク先サイト</p>
                  <p className="indent1">
                    当協会は、遺言アプリからリンクされた第三者が運営するサイトに関して、いかなる保証もいたしません。登録資格者のご判断でご利用ください。また、リンク先で生じた損害や、登録資格者同士のトラブル等に対し、当協会は一切の補償及び関与をいたしません。
                  </p>
                  <p>（４）口コミの削除</p>
                  <p className="indent1">
                    口コミは、遺言者からの投稿で成り立っている情報源ですが、登録資格者の皆様の業務運営がスムースに行われるよう、下記に該当・類似する口コミが発見された場合、予告無く、当該口コミを遺言アプリ上から削除する場合があります。
                    なお、削除対象に該当するか否かは、全て当協会が判断いたします。
                  </p>
                  <p className="center">記</p>
                  <ol className="indent3 li-indent2">
                    <li>1. ガイドラインに反するもの</li>
                    <li>2. 公序良俗に反するもの</li>
                    <li>
                      3.
                      遺言アプリの趣旨、又は口コミの対象となる登録資格者と関係のないもの
                    </li>
                    <li>4. 有害なプログラム・スクリプト等を含むもの</li>
                    <li>
                      5.
                      営利を目的としたものや、個人的な取引や勧誘を持ちかける内容、宣伝行為に関するもの
                    </li>
                    <li>
                      6.
                      その他、遺言アプリの管理運営を妨げる等、当事務所が不適切と判断したもの
                    </li>
                  </ol>
                </div>
                <div>
                  <p>10、著作権、財産権その他の権利</p>
                  <ol className="li-indent3">
                    <li>
                      （１）
                      遺言アプリに含まれているコンテンツ及び個々の情報､商標､画像､広告､デザイン等に関する著作権､商標権その他の知的財産権、及びその他の財産権は全て当協会又は正当な権利者に帰属しています｡
                    </li>
                    <li>
                      （２）
                      遺言アプリ及び関連して使用されている全てのソフトウェアは､知的財産権に関する法令等により保護されている財産権を含んでいます｡
                    </li>
                    <li>
                      （３）
                      登録資格者は､当協会若しくは著作権その他の知的財産権及びその他の財産権を有する第三者から利用･使用を許諾されている場合､又は､法令により権利者からの許諾なく利用若しくは使用することを許容されている場合を除き、遺言アプリ及び遺言アプリの内容について複製､編集､改変､掲載､転載､公衆送信､配布､販売､提供､翻訳・翻案その他あらゆる利用又は使用を行ってはなりません｡
                    </li>
                    <li>
                      （４）
                      登録資格者が前各号に反する行為によって被った損害については､当協会は一切の責任を負わないものとします｡また､登録資格者がこれらの行為によって利益を得た場合､当協会はその利益相当額を請求できる権利を有するものとします｡
                    </li>
                  </ol>
                </div>
                <div>
                  <p>11、禁止行為</p>
                  <p>
                    （１）登録資格者が遺言アプリを利用するに際し次の行為を行うことを禁止します。
                  </p>
                  <ol className="indent1 li-indent1">
                    <li>
                      1
                      法令上又は本規約若しくはガイドライン上特に認められている場合を除き、遺言アプリの提供する情報を当協会の事前の同意なく、複写、若しくはその他の方法により再生、複製、送付、譲渡、頒布、配布、転売、又はこれらの目的で使用するために保管すること
                    </li>
                    <li>2 本規約又はガイドラインに違反すること</li>
                    <li>3 公序良俗に反すること</li>
                    <li>
                      4
                      違法行為・犯罪的行為・重大な危険行為に結びつくこと又はこれらを助長すること
                    </li>
                    <li>
                      5
                      当協会、他の登録資格者又は第三者の知的財産権（著作権、意匠権、実用新案権、商標権、特許権、ノウハウが含まれますがこれに限定されません）を侵害すること
                    </li>
                    <li>
                      6
                      他の登録資格者又は第三者の権利又は利益を違法に侵害し、又はそのおそれがあること
                    </li>
                    <li>
                      7
                      遺言アプリの運営を妨げること、又は当協会の信用を毀損すること
                    </li>
                    <li>
                      8 遺言アプリ登録資格者が登録時に虚偽の申告をすること
                    </li>
                    <li>9 同一人物が複数の資格者登録を行うこと</li>
                    <li>
                      10
                      ID及びパスワードの不正使用又は他の登録資格者又は第三者に使用させること
                    </li>
                    <li>
                      11
                      面識のない異性との出会い等を目的として遺言アプリの機能を利用すること
                    </li>
                    <li>12 その他、当協会が不適切と判断すること</li>
                    <li>
                      13
                      登録資格者により前項各号に該当する行為又は遺言アプリの趣旨・目的に照らして不適切であると当協会が判断する行為がなされた場合、当協会は当該登録資格者に対して、遺言アプリの利用の停止その他当協会が適切と判断する措置（以下「利用停止措置」といいます。）をとることができるものとします｡なお、利用停止措置は登録資格者の帰責性の有無にかかわらず当協会の裁量・判断に基づき行うことができるものとし、利用停止措置を行った理由については､その如何を問わず登録資格者に対して一切お答えいたしかねます｡
                      <br />
                      加えて、利用停止措置に起因して登録資格者に生じた損害については､当事務所は一切の責任を負わないものとします｡
                      登録資格者が、禁止行為を行ったことにより、利用停止措置を受けた場合、遺言執行者名簿への掲載料は返金いたしません。
                    </li>
                  </ol>
                </div>
                <div>
                  <p>12、名簿からの抹消手続き</p>
                  <p className="li-indent3">
                    （１）
                    登録資格者が名簿からの抹消を希望するときは、次の要領による。
                  </p>
                  <ol className="indent1 li-indent2">
                    <li>
                      ① 当協会所定の「名簿からの抹消届」を、当協会に提出する。
                    </li>
                    <li>
                      ②
                      当協会は、登録資格者からの名簿からの抹消届を受理した日から1週間以内に名簿からの抹消処理を行う。
                    </li>
                  </ol>
                  <p className="li-indent3">
                    （２）
                    登録資格者が名簿からの抹消を希望する場合は抹消手続を行うものとする。登録資格者が死亡した場合、当協会は登録資格者の死亡を知った時点をもって、名簿からの抹消届があった場合と同様に取り扱う。
                  </p>
                  <p className="li-indent3">
                    （３）
                    登録資格者は、名簿からの抹消手続を行った場合、当協会の登録資格者のアカウントに関する一切の権利・特典を失い、かつ、支払済遺言執行者名簿登録料の払い戻し請求もできない。
                  </p>
                  <p className="li-indent3">
                    （４）
                    登録資格者は、名簿からの抹消手続によっても、登録資格者として登録していた記事の全体、並びに遺言者からの口コミ記事部分に重ねて、網掛け文字で「年月日名簿からの抹消済み」との表示がされるのみで、名簿からの抹消後も1年間は削除されないことを予め承諾するものとします。
                  </p>
                  <p className="li-indent3">
                    （５）
                    登録資格者が遺言アプリを介して遺言者と契約している場合、もしくは過去の契約についての契約の履行・解約等は全て登録資格者の責任において処理するものとし、万一、当協会が迷惑を被った場合、登録資格者は自己の責任と費用において処理することを予め承諾する。
                  </p>
                  <p className="li-indent3">
                    （６）
                    登録資格者が名簿からの抹消手続を完了した場合、又は、当協会と登録資格者との間の本規約に基づく契約が終了した場合であっても、4、ログインアカウント等の登録・管理、5、個人情報について、9、免責事項、10、著作権、財産権その他の権利、11、禁止行為、13、準拠法及び裁判管轄はなお有効とする。
                  </p>
                </div>
                <div>
                  <p>13、本規約の変更</p>
                  <p className="li-indent3">
                    （１）
                    当協会は、本規約を変更できるものとし、当協会が任意に定めた効力発生から変更後の本規約の効力が発生するものとします。
                  </p>
                  <p className="li-indent3">
                    （２）
                    前項の場合、当協会は、効力発生日の1ヶ月以上前の相当な時期までに、登録資格者に対して、本規約の変更の内容及び効力発生日を通知いたします。但し、当該変更による登録資格者の不利益の程度が軽微であると当協会が判断した場合、その期間を短縮することができるものとします。
                  </p>
                  <p className="li-indent3">
                    （３）
                    前項の規定は、本規約の変更が登録資格者の一般の利益に適合する場合には適用いたしません。
                  </p>
                </div>
                <div>
                  <p>14、準拠法及び裁判管轄</p>
                  <p>
                    本規約は、日本法に基づき解釈されるものとし、本規約に関して訴訟の必要が生じた場合には東京地方裁判所を第一審の専属的合意管轄裁判所とする。
                  </p>
                </div>
                <div>
                  <p>15、お問い合わせ</p>
                  <p>ご不明な点は、下記にお問い合わせください。</p>
                  <p className="indent1">＜受付窓口＞</p>
                  <p className="indent2">一般社団法人日本遺言執行士協会</p>
                  <p className="indent2">石田 泉</p>
                  <p className="indent2">e-Mail ishida@igon.co.jp</p>
                  <p className="indent2">電話 03-6264-5005</p>
                </div>
              </div>
            </div>
          </div>
          <div className="signUp-executor-Policy-Close-Wrap">
            <button
              className="signUp-executor-Policy-Close-Btn"
              onClick={changeExecutorPolicyBtn}
              type="button"
            >
              閉じる
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpExecutorPolicy;
