import { FC, Suspense } from 'react';
import TopicsTemplate from '../templates/topicsTemplate';
import TopicsListContent from '../organisms/topicsListContent';

const TopicsList: FC = () => (
  <TopicsTemplate
    subTitle="トピック一覧"
    sideBtnPath="/admin/topics/create"
    sideBtnText="新規投稿"
  >
    <Suspense fallback={<div>...読み込み中</div>}>
      <TopicsListContent />
    </Suspense>
  </TopicsTemplate>
);

export default TopicsList;
