/* eslint-disable no-alert */
import { FC, FormEvent, useRef } from 'react';
import { useUsersListFromAdmin } from 'hooks';
import './newsMessage.css';
import {
  sendEmailFromAdmin,
  SendEmailFromAdminReqBody,
} from 'domains/apigateway/services/sendEmail';
import AdminNav from '../organisms/adminNav';

const NewsMessage: FC = () => {
  const subRef = useRef<HTMLInputElement>(null);
  const contentRef = useRef<HTMLTextAreaElement>(null);

  const userDatas = useUsersListFromAdmin(['userList_admin']);
  const userEmails = userDatas.map((ele) => [ele.email]);

  const submit = async (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (
      subRef.current &&
      subRef.current.value.length &&
      contentRef.current &&
      contentRef.current.value.length
    ) {
      const confirmSend = window.confirm('この内容で配信しますか？');
      if (confirmSend) {
        const reqBody: SendEmailFromAdminReqBody = {
          emails: userEmails,
          subject: subRef.current.value,
          content: contentRef.current.value,
        };
        await sendEmailFromAdmin(reqBody);
      }
    } else {
      window.alert('未入力の項目があります');
    }
  };

  return (
    <>
      <AdminNav />
      <div className="admin-news-message">
        <div className="admin-news-message-wrapper">
          <h1>遺言者管理</h1>
          <p>お知らせ配信</p>
          <form className="news-message-form">
            <input ref={subRef} type="text" placeholder="件名" />
            <textarea
              ref={contentRef}
              placeholder="お知らせ配信の内容を入力してください。"
            />
            <input onClick={submit} type="submit" />
          </form>
        </div>
      </div>
    </>
  );
};

export default NewsMessage;
