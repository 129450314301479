/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { FC, ChangeEvent, useState } from 'react';
// import { useQuery } from 'react-query';
import { useUsersListFromAdmin } from 'hooks';
// import { getUserFromAdmin } from 'domains/apigateway/services/get-UserFromAdmin';
import { getFormatDate1, getFormatDate2 } from 'utils/dateFormat';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { PrefectureArray } from 'user/components/data/prefecture';
import AdminNav from '../organisms/adminNav';
import DataBtns from '../organisms/dataBtns';
import './userData.css';

Chart.register(...registerables);

const UserData: FC = () => {
  // const getUsers = async () => {
  //   let value = await getUserFromAdmin();
  //   let results = value.Users;

  //   while (value.Users.length >= 60) {
  //     // eslint-disable-next-line no-await-in-loop
  //     const newValue = await getUserFromAdmin(value.token);
  //     value = newValue;
  //     results = results.concat(newValue.Users);
  //   }

  //   return results;
  // };

  // const { data: UserDatas } = useQuery(['userData_admin'], getUsers);

  const UserDatas = useUsersListFromAdmin(['userData_admin']);

  // today取得（2022-01-01の型）
  const date = new Date();
  const dateYear = date.getFullYear();
  const dateMonth = `0${date.getMonth() + 1}`.slice(-2);
  const dateDay = `0${date.getDate()}`.slice(-2);
  const dateToday = `${dateYear}-${dateMonth}-${dateDay}`;

  const [inputDateString1, setInputDateString1] = useState(dateToday);
  const [inputDateString2, setInputDateString2] = useState(dateToday);

  const [selectAddress, setSelectAddress] = useState('全国');

  const getValue1 = (data: ChangeEvent<HTMLInputElement>) => {
    setInputDateString1(data.currentTarget.value);
  };

  const getValue2 = (data: ChangeEvent<HTMLInputElement>) => {
    setInputDateString2(data.currentTarget.value);
  };

  const getSelectValue2 = (data: ChangeEvent<HTMLSelectElement>) => {
    setSelectAddress(data.currentTarget.value);
  };

  const CalendarValueDates: (string | Date)[] = [];

  const firstCalendarDate = getFormatDate1(inputDateString1);

  const lastCalendarDate = getFormatDate2(inputDateString2);

  for (
    let d = firstCalendarDate;
    d <= lastCalendarDate;
    d.setDate(d.getDate() + 1)
  ) {
    const formatedDate = `${d.getFullYear().toString().padStart(2, '0')}-${(
      d.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}T0:00:00`;

    CalendarValueDates.push(formatedDate);
  }

  const [selectAge, setSelectAge] = useState('全年代');

  const getSelectValue = (data: ChangeEvent<HTMLSelectElement>) => {
    setSelectAge(data.currentTarget.value);
  };

  const age = [
    '10代',
    '20代',
    '30代',
    '40代',
    '50代',
    '60代',
    '70代',
    '80代',
    '90代',
  ];

  const valueArray = CalendarValueDates.map((x) => {
    const filterArray = UserDatas?.filter((y) => {
      // console.log(y.createdAt);
      // console.log(x.toString());
      if (
        x.toString() >= y.createdAt! &&
        !undefined &&
        y['custom:ages'] === selectAge &&
        y.address === selectAddress
      ) {
        return y;
      }

      if (
        x.toString() >= y.createdAt! &&
        !undefined &&
        y['custom:ages'] === selectAge &&
        selectAddress === '全国'
      ) {
        return y;
      }

      if (
        x.toString() >= y.createdAt! &&
        !undefined &&
        selectAge === '全年代' &&
        y.address === selectAddress
      ) {
        return y;
      }

      if (
        x.toString() >= y.createdAt! &&
        !undefined &&
        selectAge === '全年代' &&
        selectAddress === '全国'
      ) {
        return y;
      }
    });

    return filterArray?.length;
  });

  // chart
  // =============================================
  const chartData = {
    // x 軸のラベル
    labels: CalendarValueDates,
    datasets: [
      {
        label: '遺言者数',
        // データの値
        data: valueArray,
        // グラフの背景色
        backgroundColor: ['rgba(255, 99, 132, 0.2)'],
        // グラフの枠線の色
        borderColor: ['rgba(255, 99, 132)'],
        // グラフの枠線の太さ
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <AdminNav />
      <div className="user-data">
        <div className="user-data-wrapper">
          <h1>遺言者のデータ分析</h1>
          <p>登録者数</p>
          <div className="user-data-chart-btns">
            <div className="user-data-chart">
              <div>
                <input
                  value={inputDateString1}
                  onChange={(e) => {
                    getValue1(e);
                  }}
                  type="date"
                />
                <input
                  value={inputDateString2}
                  onChange={(e) => {
                    getValue2(e);
                  }}
                  type="date"
                />
              </div>
              <div>
                <select
                  onChange={(e) => {
                    getSelectValue(e);
                  }}
                >
                  <option value="全年代">全年代</option>
                  {age.map((ele) => (
                    <option value={ele}>{ele}</option>
                  ))}
                </select>
                <select
                  onChange={(e) => {
                    getSelectValue2(e);
                  }}
                >
                  <option value="全国">全国</option>
                  {PrefectureArray.map((ele) => (
                    <option value={ele.label}>{ele.label}</option>
                  ))}
                </select>
              </div>
              <Line data={chartData} />
            </div>
            <DataBtns />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserData;
