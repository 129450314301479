import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Properties } from 'models';
import { QueryProperties } from 'domains/cognite/services/manageProperties';
import { CognitoUserExt } from 'domains/cognite/user';
import { logger } from 'utils/logger';

type UseQueryProperties = (
  key: string,
  user: CognitoUserExt | undefined,
) => {
  data: Properties | undefined;
  isFetching: boolean;
};

const useQueryProperties: UseQueryProperties = (
  key: string,
  user: CognitoUserExt | undefined,
) => {
  if (!user) {
    logger.error(user);
    throw Error;
  }
  const [enabled, setenabled] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setenabled(true);
    }, 0.15 * 1000);

    return () => {
      setenabled(false);
      clearTimeout(timeoutId);
    };
  }, [key]);

  const QueryUserProperties = async () => {
    const properties = await QueryProperties(user.attributes.sub);

    return properties;
  };

  const { data, isFetching } = useQuery([key], QueryUserProperties, {
    cacheTime: 200,
    enabled,
  });

  return { data, isFetching };
};

export default useQueryProperties;
