/* eslint-disable no-param-reassign */
import { DataStore } from 'aws-amplify';
import { LifeInsurance, LifeInsuranceMetaData } from 'models';
import { logger } from 'utils/logger';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ModelInit } from '@aws-amplify/datastore';

const CreateLifeInsurance = async (
  instance: ModelInit<LifeInsurance, LifeInsuranceMetaData>,
): Promise<LifeInsurance> => {
  try {
    logger.info('生命保険の作成を開始します');
    const data = await DataStore.save(new LifeInsurance(instance));
    logger.info('生命保険の作成が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export default CreateLifeInsurance;

export const queryLifeInsurance = async (): Promise<LifeInsurance[]> => {
  try {
    const queryLifeInsuranceData = await DataStore.query(LifeInsurance);

    const queryLifeInsuranceResult = !queryLifeInsuranceData
      ? []
      : queryLifeInsuranceData;

    return queryLifeInsuranceResult;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const MutateLifeInsurance = async (
  id: string,
  instance: ModelInit<LifeInsurance, LifeInsuranceMetaData>,
): Promise<LifeInsurance> => {
  try {
    logger.info('生命保険の作成または更新を開始します');
    const original = await DataStore.query(LifeInsurance, id);

    if (original) {
      logger.info('生命保険の更新を開始します');
      const data = await DataStore.save(
        LifeInsurance.copyOf(original, (updated) => {
          // ワンチャンここがバグ
          updated.content = instance.content;
          updated.majorNum = instance.majorNum;
          updated.noCheck = instance.noCheck;
        }),
      );

      return data;
    }
    logger.info('生命保険の作成を開始します');
    const data = await DataStore.save(new LifeInsurance(instance));

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const MutateLifeInsuranceOnlyMajorNum = async (
  id: string,
  instance: ModelInit<LifeInsurance, LifeInsuranceMetaData>,
): Promise<LifeInsurance> => {
  try {
    logger.info('生命保険の作成または更新を開始します');
    const original = await DataStore.query(LifeInsurance, id);

    if (original) {
      logger.info('生命保険の更新を開始します');
      const data = await DataStore.save(
        LifeInsurance.copyOf(original, (updated) => {
          updated.majorNum = instance.majorNum;
        }),
      );

      return data;
    }
    logger.info('生命保険の作成を開始します');
    const data = await DataStore.save(new LifeInsurance(instance));

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetLifeInsurance = async (): Promise<LifeInsurance[]> => {
  try {
    logger.info('生命保険の全項目のフェッチを開始します');
    const data = await DataStore.query(LifeInsurance);
    logger.debug(data);
    logger.info('生命保険の全項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteLifeInsurance = async (): Promise<LifeInsurance[]> => {
  try {
    logger.info('生命保険の削除を開始します');
    const data = await DataStore.query(LifeInsurance);
    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);

          return data;
        }),
      );
    }
    logger.debug(data);
    logger.info('生命保険の削除を開始します');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
