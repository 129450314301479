/* eslint-disable no-param-reassign */
import { Auth, API } from 'aws-amplify';
import { logger } from 'utils/logger';

export type Executor = {
  id: string;
  name: string;
  email: string;
  zone: string[];
  passport: string[];
  birthdate: string;
  phoneNumber: string;
  address: string;
  prefecture: string;
  city: string;
  addressNumbers: string;
  building: string;
};

type QueryParams = {
  name?: string;
  zone?: string;
  passport?: string;
};

export const getExecutors = async (
  queryParams: QueryParams,
): Promise<Executor[]> => {
  const { name, zone, passport } = queryParams;
  const apiName = 'UserQueries';
  const path = '/getExecutor';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    queryStringParameters: {
      name,
      zone,
      passport,
    },
  };

  try {
    const executors = (await API.get(apiName, path, params)) as Executor[];

    return executors;
  } catch (error) {
    logger.info(error);

    return [] as Executor[];
  }
};

export const getOneExecutor = async (username: string): Promise<Executor> => {
  const apiName = 'UserQueries';
  const path = '/getExecutorFromId';
  const params = {
    queryStringParameters: {
      username,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  };
  const executor = (await API.get(apiName, path, params)) as Executor;

  return executor;
};

export const getOneExecutorCatch = async (
  username: string,
): Promise<Executor | undefined> => {
  const apiName = 'UserQueries';
  const path = '/getExecutorFromId';
  const params = {
    queryStringParameters: {
      username,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  };

  try {
    const executor = (await API.get(apiName, path, params)) as Executor;

    return executor;
  } catch (error) {
    return undefined;
  }
};
