/* eslint-disable no-alert */
import { FC, useState } from 'react';
import { DataStore } from 'aws-amplify';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { DeleteMyself, GetUser } from 'domains/cognite/services/manageUser';
import { UpdateAssignStatusByClient } from 'domains/cognite/services/manageAssignRelationship';
import { DeleteChatRoomsByClient } from 'domains/cognite/services/manageChat';
import { logger } from 'utils/logger';
import Dialog from 'common/apply/organisms/dialog';
import './delete.css';
import { AssignStatus } from 'models';
import UserLogoHeader from '../organisms/userLogoHeader';
import CommonFooter from '../../../common/apply/templates/commonFooter';

const Delete: FC = () => {
  const navigate = useNavigate();

  const { data: user } = useQuery(['authenticated_user'], GetUser);
  if (!user) {
    logger.error(user);
    throw Error;
  }

  const userId = user.attributes.sub;

  const [isDialogShow, setIsDialogShow] = useState(false);

  const toggleDialog = () => {
    setIsDialogShow(!isDialogShow);
  };

  const deleteMe = async () => {
    try {
      await UpdateAssignStatusByClient(userId, AssignStatus.DISABLE);
      await DeleteChatRoomsByClient(userId);

      await DeleteMyself();
      await DataStore.clear();
      navigate('/delete/thanks/');
    } catch (error) {
      logger.error('退出に失敗しました');
    }
  };

  return (
    <>
      <UserLogoHeader />
      {isDialogShow ? (
        <Dialog
          dialogText="本当に退出してもよろしいですか？"
          dialogYesFunc={deleteMe}
          dialogNoFunc={toggleDialog}
        />
      ) : (
        ''
      )}
      <div className="delete">
        <div>
          <h1>退出について</h1>
          <p>
            退出手続き前に
            <br className="sp-only" />
            ご確認ください
          </p>
          <p>
            遺言アプリを退出された場合には、登録いただいた情報（メールアドレス、パスワード）、入力したデータがすべて削除され、元に戻すことが出来なくなります。
          </p>
          <Link to="/user">ホーム</Link>
          <button type="button" onClick={toggleDialog}>
            退出する
          </button>
        </div>
      </div>
      <CommonFooter />
    </>
  );
};

export default Delete;
