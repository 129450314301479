import { Suspense } from 'react';
import { createRoot } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './reset.css';

// import '@aws-amplify/ui-react/dist/styles.css';
// import 'semantic-ui-css/semantic.min.css';
import './index.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      suspense: true,
    },
    mutations: {
      retry: 0,
    },
  },
});
const root = document.getElementById('root') as HTMLElement;

createRoot(root).render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<div>Loading....</div>}>
        <App />
      </Suspense>
      {process.env.NODE_ENV === 'development' && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
  </BrowserRouter>,
);

reportWebVitals();
