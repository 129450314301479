import { FC, useState } from 'react';
import { useQueryPdf } from 'hooks';
import { Document, Page, pdfjs } from 'react-pdf';
import './preview.css';
import Loading from '../atoms/loading';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {
  userId: string;
  startUpload: boolean;
};

const Processing: FC = () => (
  <div className="react-pdf__Document react-pdf__Document_loading">
    <Loading />
  </div>
);

const NoData: FC = () => (
  <div className="react-pdf__Document react-pdf__Document_default">
    <p>
      各データを入力して、遺言書に反映をすると
      <br />
      ここにプレビューが表示されます
    </p>
  </div>
);

const Preview: FC<Props> = (props) => {
  const { userId, startUpload } = props;

  const { data, error, isLoading, fileCheck, isLoadingCheck } =
    useQueryPdf(userId);

  const [numPagesState, setNumPagesState] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPagesState(numPages);
  };

  if (startUpload) {
    return <Processing />;
  }

  if (!fileCheck) {
    if (isLoadingCheck) {
      return <Processing />;
    }

    return <NoData />;
  }

  const { exist, alreadyUploaded } = fileCheck;

  if (!alreadyUploaded && !exist) {
    return <NoData />;
  }

  if (alreadyUploaded && !exist) {
    return <Processing />;
  }

  if (!data) {
    if (isLoading) {
      return <Processing />;
    }

    if (error) {
      return <NoData />;
    }

    return <NoData />;
  }

  const { url } = data;

  const numPagesArray = Array.from(new Array(numPagesState)).map(
    (_, index) => index,
  );

  return (
    <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
      {numPagesArray.map((ele, index) => (
        <Page key={`page_${ele}`} pageNumber={index + 1} />
      ))}
    </Document>
  );
};

export default Preview;
