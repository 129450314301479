/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Auth, API } from 'aws-amplify';
import { UserAttributes } from 'domains/cognite/user';

type ExecutorDatas = {
  executorDatas: UserAttributes[];
  token: string;
};

// eslint-disable-next-line import/prefer-default-export
export const getExecutorFromAdmin = async (
  nextToken?: string,
): Promise<ExecutorDatas> => {
  const apiName = 'AdminQueries';
  const path = '/listUsersInGroup';
  const params = {
    queryStringParameters: {
      groupname: 'Executor',
      limit: 60,
      token: nextToken,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };

  const executorDatasNotFormatted = await API.get(apiName, path, params);

  const executorDatas = executorDatasNotFormatted.Users.map(
    (executorData: {
      Attributes: { Name: string; Value: string }[];
      UserCreateDate: string;
    }) => {
      const arrayChangedFormat = executorData.Attributes.reduce(
        (
          prev: { [x: string]: string },
          current: { Name: string; Value: string },
        ) => {
          prev[current.Name] = current.Value;

          return prev;
        },
        {},
      );

      return {
        ...arrayChangedFormat,
        createdAt: executorData.UserCreateDate,
      };
    },
  ) as UserAttributes[];

  const { NextToken } = executorDatasNotFormatted;

  return {
    executorDatas,
    token: NextToken,
  };
};

export const getOneExecutorFromAdmin = async (
  username: string,
): Promise<UserAttributes> => {
  const apiName = 'AdminQueries';
  const path = '/getUser';
  const params = {
    queryStringParameters: {
      username,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };

  const theExecutor = await API.get(apiName, path, params);
  const theExecutorAttributes = theExecutor.UserAttributes as UserAttributes;

  return theExecutorAttributes;
};
