import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Topics } from 'models';
import { ListTopicsAll } from 'domains/cognite/services/manageTopics';
import TopicsListLink from '../molcules/topicsListLink';

type LocationState =
  | {
      mutated: true;
    }
  | undefined;

const TopicsListContent: FC = () => {
  const location = useLocation();
  const locationState = location.state as LocationState;

  const [fetchTimes, setFetchTimes] = useState(0);
  const { data: topicsFromQuery } = useQuery(
    ['topics', 'list'],
    ListTopicsAll,
    {
      cacheTime: 200,
      onSettled: () => setFetchTimes((c) => c + 1),
      refetchInterval: fetchTimes <= 1 && locationState ? 1000 : false,
    },
  );

  const topics = topicsFromQuery || ([] as Topics[]);

  return (
    <>
      {topics.map((ele) => (
        <TopicsListLink
          key={ele.id}
          postId={ele.id}
          title={ele.title}
          thumbnail={ele.thumbnail}
          updatedAt={ele.updatedAt}
          isDraft={ele.isDraft}
        />
      ))}
    </>
  );
};

export default TopicsListContent;
