import { FC } from 'react';
import ExecutorTextHeader from '../organisms/executorTextHeader';
import ExecutorProfileEditContent from '../organisms/executorProfileEditContent';
import ExecutorLogoHeader from '../../../common/apply/organisms/executorLogoHeader';
import CommonFooter from '../../../common/apply/templates/commonFooter';

const ExecutorProfileEdit: FC = () => (
  <>
    <ExecutorLogoHeader />
    <ExecutorTextHeader title="編集" />
    <ExecutorProfileEditContent />
    <CommonFooter />
  </>
);

export default ExecutorProfileEdit;
