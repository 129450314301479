import { FC, Suspense } from 'react';
// import useQueryApplyToBeExecutor from 'hooks/useQueryApplyToBeExecutor';
import ExecutorLogoHeader from '../organisms/executorLogoHeader';
import ExecutorProcedureConfirmContent from '../organisms/executorProcedureConfirmContent';
// import CommonFooter from '../templates/commonFooter';
import FooterBeforeLogin from '../organisms/footerBeforeLogin';

const ExecutorProcedureConfirm: FC = () => (
  <>
    <ExecutorLogoHeader />
    <Suspense fallback={<div>...読み込み中</div>}>
      <ExecutorProcedureConfirmContent />
    </Suspense>
    <FooterBeforeLogin homeLink="/apply" />
  </>
);

export default ExecutorProcedureConfirm;
