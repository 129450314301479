import { FC } from 'react';
import { Link } from 'react-router-dom';
import HeaderLogo from '../images/headerLogo.svg';
import './willHeader.css';

type Props = {
  title: string;
};

const WillHeader: FC<Props> = (props) => {
  const { title } = props;

  return (
    <header className="willHeader">
      <div>
        <Link to="/user" className="pc-only">
          <img src={HeaderLogo} alt="" />
        </Link>
        <p>{title}</p>
      </div>
    </header>
  );
};

export default WillHeader;
