import { FC, useRef, FormEvent, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { logger } from 'utils/logger';
import '../reset.css';
import './forgetPassword.css';
import UserLogoHeader from 'user/components/organisms/userLogoHeader';
import CommonFooter from './apply/templates/commonFooter';
import NavigateBtn from '../user/components/atoms/navigateBtn';

type Props = {
  saveEmail: (email: string) => void;
};

type LocationState = {
  userType: 'user' | 'executor';
} | null;

const ForgetPassword: FC<Props> = (props) => {
  const { saveEmail } = props;
  const location = useLocation();
  const locationState = location.state as LocationState;
  const userType = locationState ? locationState.userType : 'user';
  const navigate = useNavigate();
  const emailRef = useRef<HTMLInputElement>(null);
  const [alertEmail, setAlertEmail] = useState(false);
  const emailAlertMessage = alertEmail ? 'alertMessage' : '';

  const forgetPassword = async (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!emailRef.current?.value) {
      setAlertEmail(true);
    } else {
      try {
        if (emailRef.current?.value) {
          logger.info(emailRef.current.value);
          saveEmail(emailRef.current.value);
          await Auth.forgotPassword(emailRef.current.value);
          navigate('/password/change', {
            state: {
              userType,
            },
          });
        }
      } catch (error) {
        logger.error('認証コード送信失敗');
        logger.error('error signing in', error);
      }
    }
  };

  return (
    <>
      <UserLogoHeader />
      <div className="ForgetPassword">
        <h1>パスワードを忘れた方</h1>
        <p>
          登録しているEメールアドレスを
          <br className="sp-only" />
          ご入力ください
        </p>
        <form action="" className="ForgetPasswordWrapper">
          <label htmlFor="email">
            メールアドレス
            <input
              type="email"
              id="email"
              ref={emailRef}
              placeholder="メールアドレスを入力"
            />
          </label>
          <p className={`deleteMessage ${emailAlertMessage}`}>
            メールアドレスが入力されていません
          </p>
          <input
            type="submit"
            value="リセット用URLを送信する"
            onClick={forgetPassword}
          />
        </form>
        <NavigateBtn />
        <CommonFooter />
      </div>
    </>
  );
};

export default ForgetPassword;
