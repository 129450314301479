// import { Auth, API } from 'aws-amplify';
import { API } from 'aws-amplify';
// import { RefObject } from 'react';
import { logger } from 'utils/logger';

type InqueryBody = {
  name: string;
  email: string;
  inquiry: string;
  company?: string;
  position?: string;
  phoneNumber?: string;
};

export const sendEmailInquiry = async (body: InqueryBody): Promise<void> => {
  const apiName = 'CommonQueries';
  const path = '/sendEmail';
  const params = {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  };

  await API.post(apiName, path, params)
    .then(() => {
      logger.info('メール送信成功');
    })
    .catch((error) => {
      logger.error('メール送信失敗');
      logger.error(error);
    });
};

export type SendEmailForAssignReqBody = {
  fromEmail: string;
  toEmail: string;
  action: string;
};

export const sendEmailForAssign = async (
  body: SendEmailForAssignReqBody,
): Promise<void> => {
  const apiName = 'CommonQueries';
  const path = '/sendEmailForAssignNotion';

  const params = {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  };

  await API.post(apiName, path, params)
    .then(() => {
      logger.info('メール送信成功');
    })
    .catch((error) => {
      logger.error('メール送信失敗');
      logger.error(error);
    });
};

export type SendEmailForApplyExecutorReqBody = {
  name: string;
  email: string;
  phoneNumber: string;
  address: string;
  birthday: string;
  passportAndCertificate: {
    passport: string;
    certificate: string;
  }[];
  area: string;
  // 2024/01/25 priceをコメントアウト
  // TODO: 指示があったら元に戻す
  // price: string;
  fileParams: string[];
};

export const sendEmailForApplyExecutor = async (
  body: SendEmailForApplyExecutorReqBody,
): Promise<void> => {
  const apiName = 'CommonQueries';
  const path = '/sendEmailForApplyExecutor';

  const params = {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  };

  await API.post(apiName, path, params)
    .then(() => {
      logger.info('メール送信成功');
    })
    .catch((error) => {
      logger.error('メール送信失敗');
      logger.error(error);
    });
};

export type SendEmailForChatReqBody = {
  fromEmail: string;
  toEmail: string;
  senderType: string;
};

export const sendEmailForChat = async (
  body: SendEmailForChatReqBody,
): Promise<void> => {
  const apiName = 'CommonQueries';
  const path = '/sendEmailForChat';

  const params = {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  };

  await API.post(apiName, path, params)
    .then(() => {
      logger.info('メール送信成功');
    })
    .catch((error) => {
      logger.error('メール送信失敗');
      logger.error(error);
    });
};

export type SendEmailFromAdminReqBody = {
  emails: string[][];
  subject: string;
  content: string;
};

export const sendEmailFromAdmin = async (
  body: SendEmailFromAdminReqBody,
): Promise<void> => {
  const apiName = 'CommonQueries';
  const path = '/sendEmailFromAdmin';
  const params = {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  };

  await API.post(apiName, path, params)
    .then(() => {
      logger.info('メール送信成功');
    })
    .catch((error) => {
      logger.error('メール送信失敗');
      logger.error(error);
    });
};
