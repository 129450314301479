/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC, Suspense } from 'react';
import { useQuery } from 'react-query';
import { useQueryAllocation } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { GetUser } from 'domains/cognite/services/manageUser';
import { GetLegalHeirQuestion } from 'domains/cognite/services/manageLegalHeirQuestion';
import { logger } from 'utils/logger';
import LegalHeirDescription from '../templates/legalHeirDescription';
import WillHeader from '../organisms/willHeader';
import LegalHeirTemp from '../templates/legalHeirTemp';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import PageDescriptionPc from '../organisms/pageDescriptionPc';
import pageData from '../data/pageData';
import PcDescriptionBackImg from '../images/PcLegalHeirBack.svg';
import './legalHeir.css';

const LegalHeir: FC = () => {
  // useSync();
  const { data: user } = useQuery(['authenticated_user'], GetUser);
  if (!user) {
    logger.error(user);
    throw Error;
  }
  const { data } = useQueryAllocation('legalHier_allocation', user);
  const { data: legalHeirQuestionData } = useQuery(
    ['legalHeirQuestionData'],
    GetLegalHeirQuestion,
    { cacheTime: 200 },
  );

  const legalHeirQuestionDataArr = legalHeirQuestionData || [];

  const defaultLegalHeirQuestionId = legalHeirQuestionDataArr.map((ele) => {
    const defaultId = ele.id === undefined || ele.id === null ? '' : ele.id;

    return defaultId;
  });

  const navigate = useNavigate();

  const onClickFunc = (link: string) => {
    navigate(link);
  };

  const descriptionPc = (
    <>
      {pageData[16].description}
      {pageData[17].description}
    </>
  );

  return (
    <>
      <WillHeader title="法定相続人" />
      <div className="sp-only">
        <LegalHeirDescription data={data} />
      </div>
      <Suspense fallback={<div>読み込み中</div>}>
        <LegalHeirTemp
          data={data}
          user={user}
          idCheck={defaultLegalHeirQuestionId[0]}
        />
      </Suspense>
      <CommonFooter onClickFunc={onClickFunc} />
      <PageDescriptionPc
        page="LegalHeirDescriptionPc"
        description={descriptionPc}
        descriptionPcImg={PcDescriptionBackImg}
      />
    </>
  );
};
export default LegalHeir;
