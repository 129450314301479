import { FC, Suspense } from 'react';
import { QueryWillInfo } from 'domains/cognite/services/manageWillInfo';
import Spinner from '../../../components/molecules/Spinner';
import { yourInfoData } from '../data/homeData';
import AdvicePop from '../atoms/advicePop';
import ItemLink from '../molecules/itemLink';
import yourInfo from '../images/yourInfo.svg';
import './yourInfo.css';

const YourInfo: FC = () => {
  const getWillInfoQuery = async () => {
    const getWillInfoQueryContent = await QueryWillInfo();
    const data = getWillInfoQueryContent[0];

    return data;
  };

  return (
    <div className="yourInfo">
      <div>
        <h2>あなた(遺言者)の情報</h2>
        <div>
          <img src={yourInfo} alt="あなた(遺言者)の情報" />
          <p>仮名・ニックネーム、架空の生年月日で構いません。</p>
        </div>
      </div>
      <div>
        <div>
          <Suspense fallback={<div>{Spinner}</div>}>
            <ItemLink
              name={yourInfoData.name}
              href={yourInfoData.href}
              icon={yourInfoData.icon}
              getQuery={getWillInfoQuery}
              keyId="willInfo"
            />
          </Suspense>
          <AdvicePop />
        </div>
      </div>
    </div>
  );
};

export default YourInfo;
