/* eslint-disable no-param-reassign */
import { DataStore } from 'aws-amplify';
import { Bonds, BondsMetaData } from 'models';
import { logger } from 'utils/logger';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ModelInit } from '@aws-amplify/datastore';

const CreateBonds = async (
  instance: ModelInit<Bonds, BondsMetaData>,
): Promise<Bonds> => {
  try {
    logger.info('債権の作成を開始します');
    const data = await DataStore.save(new Bonds(instance));
    logger.info('債権の作成が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export default CreateBonds;

export const queryBonds = async (): Promise<Bonds[]> => {
  try {
    const queryBondsData = await DataStore.query(Bonds);

    const queryBpndsResult = !queryBondsData ? [] : queryBondsData;

    return queryBpndsResult;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const MutateBonds = async (
  id: string,
  instance: ModelInit<Bonds, BondsMetaData>,
): Promise<Bonds> => {
  try {
    logger.info('債権の作成または更新を開始します');
    const original = await DataStore.query(Bonds, id);

    if (original) {
      logger.info('債権の更新を開始します');
      const data = await DataStore.save(
        Bonds.copyOf(original, (updated) => {
          // ワンチャンここがバグ
          updated.bondsContent = instance.bondsContent;
          updated.majorNum = instance.majorNum;
          updated.noCheck = instance.noCheck;
          updated.digitalAssetsCheckFlag = instance.digitalAssetsCheckFlag;
        }),
      );

      return data;
    }
    logger.info('債権の作成を開始します');
    const data = await DataStore.save(new Bonds(instance));

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const MutateBondsOnlyMajorNum = async (
  id: string,
  instance: ModelInit<Bonds, BondsMetaData>,
): Promise<Bonds> => {
  try {
    logger.info('債権の作成または更新を開始します');
    const original = await DataStore.query(Bonds, id);

    if (original) {
      logger.info('債権の更新を開始します');
      const data = await DataStore.save(
        Bonds.copyOf(original, (updated) => {
          updated.majorNum = instance.majorNum;
        }),
      );

      return data;
    }
    logger.info('債権の作成を開始します');
    const data = await DataStore.save(new Bonds(instance));

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetBonds = async (): Promise<Bonds[]> => {
  try {
    logger.info('債権の全項目のフェッチを開始します');
    const data = await DataStore.query(Bonds);
    logger.debug(data);
    logger.info('債権の全項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteBonds = async (): Promise<Bonds[]> => {
  try {
    logger.info('債権の削除を開始します');
    const data = await DataStore.query(Bonds);
    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);

          return data;
        }),
      );
    }
    logger.debug(data);
    logger.info('債権の削除を開始します');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
