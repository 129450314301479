import { FC } from 'react';
import { Link } from 'react-router-dom';
import './executorDataBtns.css';

const ExecutorDataBtns: FC = () => (
  <div className="executor-data-btns">
    <div>
      <Link to="/admin/data/executor/timeOnPage">滞在時間</Link>
      <Link to="/admin/data/executor/pageView">足跡数</Link>
      <Link to="/admin/data/executor/search">検索項目</Link>
    </div>
  </div>
);

export default ExecutorDataBtns;
