import { FC } from 'react';
import ExecutorLogoHeader from '../organisms/executorLogoHeader';
import ExecutorProcedureFormContent from '../organisms/executorProcedureFormContent';
// import CommonFooter from '../templates/commonFooter';
import FooterBeforeLogin from '../organisms/footerBeforeLogin';

const ExecutorProcedureForm: FC = () => (
  <>
    <ExecutorLogoHeader />
    <ExecutorProcedureFormContent />
    <FooterBeforeLogin homeLink="/apply" />
  </>
);

export default ExecutorProcedureForm;
