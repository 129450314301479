/* eslint-disable no-param-reassign */
import { DataStore } from 'aws-amplify';
import {
  RealEstate,
  RealEstateContent,
  RealEstateContentMetaData,
} from 'models';
import { logger } from 'utils/logger';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ModelInit } from '@aws-amplify/datastore';

export const MutateRealEstateContentAndGet = async (
  id: string,
  subNum: number,
  type: string,
  address: string,
  realEstate?: RealEstate,
): Promise<RealEstateContent> => {
  try {
    logger.info('不動産の項目の作成または更新を開始します');
    const original = await DataStore.query(RealEstateContent, id);

    if (original) {
      const data = await DataStore.save(
        RealEstateContent.copyOf(original, (updated) => {
          updated.subNum = subNum;
          updated.type = type;
          updated.address = address;
          updated.realEstate = realEstate;
        }),
      );
      logger.info(data);
      logger.info('不動産の項目の更新が完了しました');

      return data;
    }
    logger.info('不動産の項目の作成を開始します');
    const instance = {
      subNum,
      type,
      address,
      realEstate,
    };
    const data = await DataStore.save(new RealEstateContent(instance));
    logger.debug(data);
    logger.info('不動産の項目の作成が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const MutateRealEstateContentAndGet2 = async (
  id: string,
  subNum: number,
  type: string,
  address: string,
  realEstate?: RealEstate,
): Promise<RealEstateContent> => {
  try {
    logger.info('不動産の項目の作成または更新を開始します');
    const original = await DataStore.query(RealEstateContent, id);

    if (original) {
      const data = await DataStore.save(
        RealEstateContent.copyOf(original, (updated) => {
          updated.subNum = subNum;
          updated.type = original.type;
          updated.address = address;
          updated.realEstate = realEstate;
        }),
      );
      logger.info(data);
      logger.info('不動産の項目の更新が完了しました');

      return data;
    }
    logger.info('不動産の項目の作成を開始します');
    const instance = {
      subNum,
      type,
      address,
      realEstate,
    };
    const data = await DataStore.save(new RealEstateContent(instance));
    logger.debug(data);
    logger.info('不動産の項目の作成が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const CreateRealEstateContentAndGetId = async (
  instance: ModelInit<RealEstateContent, RealEstateContentMetaData>,
): Promise<string> => {
  try {
    logger.info('不動産の項目の作成を開始します');
    const data = await DataStore.save(new RealEstateContent(instance));
    logger.debug(data);
    logger.info('不動産の項目の作成が完了しました');

    return data.id;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const UpdateRealEstateContentById = async (
  id: string,
  type: string,
  address: string,
): Promise<void> => {
  try {
    logger.info('不動産の項目の更新を開始します');

    const original = await DataStore.query(RealEstateContent, id);

    if (original) {
      logger.info(original);
      const data = await DataStore.save(
        RealEstateContent.copyOf(original, (updated) => {
          updated.type = type;
          updated.address = address;
        }),
      );
      logger.info(data);
      logger.info('不動産の項目の更新が完了しました');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteRealEstateContentById = async (
  id: string,
): Promise<void> => {
  try {
    logger.info('不動産の項目の削除を開始します');
    const original = await DataStore.query(RealEstateContent, id);
    if (original) {
      logger.info(original);
      await DataStore.delete(original);
      logger.info('不動産の項目の削除が完了しました');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetRealEstateContent = async (): Promise<RealEstateContent[]> => {
  try {
    logger.info('不動産の項目のフェッチを開始します');
    const data = await DataStore.query(RealEstateContent);
    logger.debug(data);
    logger.info('不動産の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetRealEstateContentByParent = async (
  realEstate: RealEstate | null,
): Promise<RealEstateContent[]> => {
  try {
    logger.info('不動産の項目のフェッチを開始します');
    const data = (await DataStore.query(RealEstateContent)).filter((c) => {
      if (c.realEstate === null || c.realEstate === undefined || !realEstate) {
        return false;
      }
      const realEstateId = c.realEstate.id;

      return realEstate.id === realEstateId;
    });
    logger.debug(data);
    logger.info('不動産の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteRealEstateContent = async (): Promise<
  RealEstateContent[]
> => {
  try {
    logger.info('不動産の項目の全削除を開始します');
    const data = await DataStore.query(RealEstateContent);
    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);

          return data;
        }),
      );
    }
    logger.debug(data);
    logger.info('不動産の項目の全削除が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
