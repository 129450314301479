import { FC } from 'react';
import { Link } from 'react-router-dom';
import HeaderLogo from '../images/headerLogo.svg';
import './executorLogoHeader.css';

const ExecutorLogoHeader: FC = () => (
  <header className="executorLogoHeader">
    <div>
      <Link to="/executor">
        <img src={HeaderLogo} alt="" />
      </Link>
    </div>
  </header>
);

export default ExecutorLogoHeader;
