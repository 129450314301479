import { Dispatch, FC, SetStateAction } from 'react';
import './paginationLayout.css';

type Props = {
  arrayLength: number;
  setSelectedNum: Dispatch<SetStateAction<number>>;
  selectedNum: number;
};

const PaginationLayout: FC<Props> = (props) => {
  const { arrayLength, selectedNum, setSelectedNum } = props;

  // 1ページに物を10個表示する場合、配列を10で割った数 = ページネーションのボタンの数
  const getNumberOfBtn = () => {
    if (arrayLength % 10 === 0) {
      return Math.floor(arrayLength / 10);
    }

    return Math.floor(arrayLength / 10 + 1);
  };

  const numberOfBtn = getNumberOfBtn();

  // sliceで削るために配列が必要なので[1,2,3,..]と増えていくだけの配列を用意([1,2,3...]はbuttonのvalueに使われる)
  const paginationBtnArray = Array.from(
    Array(numberOfBtn).keys(),
    (ele) => ele,
  );

  const btnArraySlice = () => {
    if (selectedNum < 4) {
      return paginationBtnArray.slice(0, 5);
    }
    if (selectedNum >= numberOfBtn - 4) {
      return paginationBtnArray.slice(numberOfBtn - 5, numberOfBtn);
    }

    // 選択しているボタンを真ん中に表示する
    return paginationBtnArray.slice(selectedNum - 2, selectedNum + 3);
  };

  const slicedBtnArray = btnArraySlice();

  return (
    <div className="pagination">
      <button onClick={() => setSelectedNum(0)} type="button">
        最初へ
      </button>
      <div className="number-btns">
        {slicedBtnArray.map((ele) => (
          <button
            className={selectedNum === ele ? 'selected-btn' : ''}
            onClick={() => setSelectedNum(ele)}
            type="button"
          >
            {ele + 1}
          </button>
        ))}
      </div>
      <button onClick={() => setSelectedNum(numberOfBtn - 1)} type="button">
        最後へ
      </button>
    </div>
  );
};

export default PaginationLayout;
