/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import { useQuery } from 'react-query';
import { GetUser } from 'domains/cognite/services/manageUser';
import { GetExecutorViewHistoryFromExecutorId } from 'domains/cognite/services/manageExecutorViewHistory';
import { AssignStatus } from 'models';
import { useRelationshpFromExecutor } from 'hooks';
import WillListCard from '../molecules/willListCard';
import './willChargeListContent.css';

const WillChargeListContent: FC = () => {
  const { data: relationship, error } =
    useRelationshpFromExecutor('clients_APPROVAL');

  const relationshipOfApproval =
    relationship && !!relationship.length
      ? relationship.filter((ele) => ele.status === AssignStatus.APPROVAL)
      : [];

  const { data: executorData } = useQuery(
    ['get_executorData_fromWillchargeListContent'],
    GetUser,
    {
      cacheTime: 200,
    },
  );

  const attributes = executorData ? executorData.attributes : undefined;
  const familyName =
    attributes && attributes.name
      ? attributes.name.substring(0, attributes.name.indexOf(' '))
      : '〇〇';

  if (!executorData) {
    throw Error();
  }

  const { data: ExecutorViewHistory } = useQuery(
    ['get_executorViewHistory'],
    () => GetExecutorViewHistoryFromExecutorId(executorData.attributes.sub),
  );

  if (!ExecutorViewHistory) {
    throw Error();
  }

  const today = new Date();
  const todayDate = today.getDate();

  const FilteredExecutorViewHistory = ExecutorViewHistory.filter((ele) => {
    if (ele.createdAt) {
      const createdAt = new Date(ele.createdAt);
      const createdAtDate = createdAt.getDate();

      if (createdAtDate === todayDate) {
        return ele;
      }
    }

    return null;
  });

  return (
    <div className="willChargeList">
      <div>
        <div>
          <p>
            {familyName} 先生、こんにちは
            <br />
            本日、先生の登録名簿を閲覧した人は
            {FilteredExecutorViewHistory.length}人です。
          </p>
        </div>
        <div className="willChargeListCard">
          <p>担当遺言者リスト</p>
          <div>
            {relationshipOfApproval.length === 0 || error ? (
              <p>
                現在担当している
                <br className="sp-only" />
                遺言者はいません
              </p>
            ) : (
              relationshipOfApproval.map((ele) => (
                <WillListCard key={ele.id} clientId={ele.clientId} />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WillChargeListContent;
