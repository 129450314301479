import { FC } from 'react';
import ProfileLink from '../molecules/profileLink';
import HomeLink from '../molecules/homeLink';
import ChatLink from '../molecules/chatLink';
import './header.css';

const Header: FC = () => (
  <header>
    <HomeLink />
    <div>
      <ChatLink />
      <ProfileLink />
    </div>
  </header>
);

export default Header;
