/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import { FC, useState, ChangeEvent } from 'react';
import { useQuery } from 'react-query';
import { DataStore } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { Allocation, AllocationReceiver } from 'models';
import { logger } from 'utils/logger';
import { MutateAllocation } from 'domains/cognite/services/manageAllocation';
import Dialog from 'common/apply/organisms/dialog';
import {
  MutateAllocationReceiverAndGetId,
  GetAllocationReceiver,
  DeleteRecipient,
  GetAllocationReceiverByParent,
  DeleteAllocationReceiverById,
} from 'domains/cognite/services/manageAllocationReceiver';
import { CognitoUserExt } from 'domains/cognite/user';
import nonNullable from 'utils/nonNullable';
import PlusBtn from '../atoms/plusBtn';
import Param2Checkmark from '../atoms/paramCheckmarkPc';
import RadioCustomSingle from '../molecules/radioCustomSingle';
import RecipientFormCard from '../organisms/recipientFormCard';
import RequireNextReturn from '../molecules/requireNextReturn';
import './recipientTemp.css';

type Props = {
  data: Allocation | undefined;
  user: CognitoUserExt;
};

const RecipientTemp: FC<Props> = (props) => {
  const { data, user } = props;

  const { data: heirAllArr } = useQuery(
    ['legalHeir_AllArr'],
    () => GetAllocationReceiverByParent(data),
    {
      cacheTime: 200,
    },
  );

  // 受遺者のデータのみ取得
  const recipientArr =
    heirAllArr === undefined || heirAllArr.length === 0
      ? []
      : heirAllArr.filter((ele) => ele.type === '受遺者').filter(nonNullable);

  const [isDialogShow, setIsDialogShow] = useState(false);

  const toggleDialog = () => {
    setIsDialogShow(!isDialogShow);
  };

  // 初期状態のオブジェクト
  const initialRecipientContentArrData: AllocationReceiver[] = [
    {
      id: '0',
      legalHeirQuestionId: 0,
      num: null,
      name: '',
      birth: '',
      type: '受遺者',
      allocation: undefined,
    },
  ];

  const initialRecipientContentArr =
    recipientArr.length === 0 ? initialRecipientContentArrData : recipientArr;

  const [recipientContent, setRecipientContent] = useState<
    AllocationReceiver[]
  >(initialRecipientContentArr);

  const updateRecipientContent = (
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement>,
    target: string,
  ) => {
    const newRecipientContent = recipientContent.map((ele) => {
      if (ele.id === subjectKey) {
        return {
          id: ele.id,
          legalHeirQuestionId: ele.legalHeirQuestionId,
          num: ele.num,
          name:
            target === 'name'
              ? event.target.value
              : ele.name === null || ele.name === undefined
              ? ''
              : ele.name,
          birth: ele.birth,
          type: ele.type,
          allocation: ele.allocation,
        };
      }

      return {
        id: ele.id,
        legalHeirQuestionId: ele.legalHeirQuestionId,
        num: ele.num,
        name: ele.name,
        birth: ele.birth,
        type: ele.type,
        allocation: ele.allocation,
      };
    });

    return setRecipientContent(newRecipientContent);
  };

  const addcard = () => {
    const randomId = Math.random().toString();
    const addRecipientContentObject = {
      id: randomId,
      legalHeirQuestionId: 0,
      num: null,
      name: '',
      birth: '',
      type: '受遺者',
      allocation: undefined,
    };

    setRecipientContent([...recipientContent, addRecipientContentObject]);
  };

  const deleteCard = (deleteSubjectId: string) => {
    const newRecipientContentAfterDelete = recipientContent.filter(
      (ele) => ele.id !== deleteSubjectId,
    );

    setRecipientContent(newRecipientContentAfterDelete);
  };

  // はい、いいえの初期状態管理
  const openOrClose = recipientContent[0].id !== '0';
  const [open, setOpen] = useState(openOrClose);
  const yesCheck = recipientContent[0].id !== '0';
  const [yesCheckState, setYesCheckState] = useState(yesCheck);
  const noCheck = !!(data && data.noCheck && data.noCheck === true);
  const [noCheckState, setNoCheckState] = useState(noCheck);

  // 名前のチェック
  const recipientNameArr = recipientContent.map((ele) => {
    const recipientName =
      ele.name === undefined || ele.name === null ? '' : ele.name;

    return recipientName;
  });

  const cardDeleteAll = async () => {
    await DeleteRecipient();
    await MutateAllocation(data ? data.id : '', {
      userId: user.attributes.sub,
      inputLegalHeirFlag: data?.inputLegalHeirFlag,
      inputRecipientFlag: null,
    });

    setOpen(false);
    setYesCheckState(false);
    window.location.reload();
  };

  const navigate = useNavigate();

  const save = async (link: string) => {
    const reservedAllocationReceiverContent = await GetAllocationReceiver();
    const reservedRecipientContent = reservedAllocationReceiverContent.filter(
      (ele) => ele.type === '受遺者',
    );
    const recipientContentId = recipientContent.map((ele) => ele.id);
    reservedRecipientContent.map((ele) => {
      if (recipientContentId.includes(ele.id) === false) {
        return DeleteAllocationReceiverById(ele.id);
      }

      return ele;
    });

    const recipientNameCheckArr = recipientContent.map((elm) => {
      const recipientNameCheck =
        elm.name === undefined || elm.name === null ? '' : elm.name;

      return recipientNameCheck;
    });

    if (open === true && recipientNameCheckArr.includes('')) {
      window.alert('必須項目の入力が漏れています。');
    } else {
      const id = data ? data.id : '';
      const mutatedAllocation = await MutateAllocation(id, {
        userId: user.attributes.sub,
        inputLegalHeirFlag: data?.inputLegalHeirFlag,
        inputRecipientFlag: true,
        noCheck: noCheckState,
      });

      const newRecipientContent = open === false ? [] : recipientContent;

      const newReceiverId = await Promise.all(
        newRecipientContent.map(async (ele, index) => {
          const receiver = await MutateAllocationReceiverAndGetId(
            ele.id,
            0,
            index + 1,
            ele.name || '',
            ele.birth || '',
            ele.type || '',
            mutatedAllocation,
          );

          return receiver;
        }),
      );

      logger.info(newReceiverId);

      navigate(link);
    }
  };

  const noCheckFunc = async () => {
    const deleteSubjectData = await GetAllocationReceiver();
    const deleteRecipientData = deleteSubjectData.filter(
      (e) => e.type === '受遺者',
    );
    deleteRecipientData.map(async (ele) => {
      await DataStore.delete(ele);
    });

    const noCheckId = data && data?.id ? data?.id : '';
    const createdRecipientByNoCheck = await MutateAllocation(noCheckId, {
      userId: user.attributes.sub,
      inputLegalHeirFlag: data?.inputLegalHeirFlag,
      inputRecipientFlag: true,
      noCheck: true,
    });
    logger.info(createdRecipientByNoCheck);

    navigate('../propertyallocation');
  };

  const next = async () => {
    await save('../propertyallocation');
  };

  const back = async () => {
    await save('../legalheir');
  };

  return (
    <>
      {isDialogShow ? (
        <Dialog
          dialogText="入力内容を削除しますがよろしいですか？"
          dialogYesFunc={cardDeleteAll}
          dialogNoFunc={toggleDialog}
        />
      ) : (
        ''
      )}
      <div className="recipientTop">
        <div className="recipient">
          <div className="pc-only realEstateParam1">
            <Param2Checkmark
              StyleClass="styleParam2-2"
              key="recipient"
              checkMarkSize6={false}
              allocationData={data}
            />
          </div>
          <RadioCustomSingle
            title="法定相続人以外の人や団体に対して財産を贈りたいと考えていますか？"
            name="recipient"
            yesFunc={() => {
              setOpen(true);
              setNoCheckState(false);
            }}
            noFunc={noCheckFunc}
            yesCheck={yesCheckState}
            noCheck={noCheckState}
          />
        </div>
        <div
          className={`recipientFormCardArea ${
            open ? 'recipientopen' : 'recipientclose'
          }`}
        >
          <div>
            {recipientContent.map((ele, index, theForms) => (
              <RecipientFormCard
                title="受遺者"
                form={ele.id}
                subNum={index + 1}
                key={ele.id}
                minusBtnView={theForms.length !== 1}
                recipientNameValue={recipientNameArr[index]}
                updateRecipientContent={updateRecipientContent}
                subjectId={ele.id}
                deleteCard={deleteCard}
              />
            ))}
          </div>
          <button type="button" className="plusBtn-wrapper" onClick={addcard}>
            <PlusBtn />
          </button>
        </div>
        <RequireNextReturn
          returnFunc={back}
          nextFunc={next}
          deleteFunc={toggleDialog}
        />
      </div>
    </>
  );
};

export default RecipientTemp;
