/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import { DataStore } from 'aws-amplify';
import { Bonds, BondsContent, BondsContentMetaData } from 'models';
import { logger } from 'utils/logger';
import { ModelInit } from '@aws-amplify/datastore';

export const MutateBondsContentAndGet = async (
  id: string,
  subNum: number,
  type: string | undefined,
  loanerName: string,
  initialLoanAmount: string,
  loanDate: string,
  loanAmount: string,
  loanCertificate: boolean,
  loanStorageLocation: string,
  investmenterName: string,
  investmentUnitNumber: string,
  investmentAmount: string,
  investmentDate: string,
  redemptionDeadline: string,
  investmentCertificate: boolean,
  investmentStorageLocation: string,
  bonds?: Bonds,
): Promise<BondsContent> => {
  try {
    logger.info('債権の項目の作成または更新');
    const original = await DataStore.query(BondsContent, id);

    if (original) {
      logger.info('債権の項目の更新');
      const data = await DataStore.save(
        BondsContent.copyOf(original, (updated) => {
          updated.subNum = subNum;
          updated.type = type;
          updated.loanerName = loanerName;
          updated.initialLoanAmount = initialLoanAmount;
          updated.loanDate = loanDate;
          updated.loanAmount = loanAmount;
          updated.loanCertificate = loanCertificate;
          updated.loanStorageLocation = loanStorageLocation;
          updated.investmenterName = investmenterName;
          updated.investmentUnitNumber = investmentUnitNumber;
          updated.investmentAmount = investmentAmount;
          updated.investmentDate = investmentDate;
          updated.redemptionDeadline = redemptionDeadline;
          updated.investmentCertificate = investmentCertificate;
          updated.investmentStorageLocation = investmentStorageLocation;
          updated.bonds = bonds;
        }),
      );

      return data;
    }
    logger.info('債権の項目の作成');
    const instance = {
      subNum,
      type,
      loanerName,
      initialLoanAmount,
      loanDate,
      loanAmount,
      loanCertificate,
      loanStorageLocation,
      investmenterName,
      investmentUnitNumber,
      investmentAmount,
      investmentDate,
      redemptionDeadline,
      investmentCertificate,
      investmentStorageLocation,
      bonds,
    };

    const data = await DataStore.save(new BondsContent(instance));

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const MutateBondsLoanContentAndGet = async (
  id: string,
  subNum: number,
  type: string | undefined,
  loanerName: string,
  initialLoanAmount: string,
  loanAmount: string,
  loanCertificate: boolean,
  loanStorageLocation: string,
  loanDate: string,
  bonds?: Bonds,
): Promise<BondsContent> => {
  try {
    logger.info('債権の項目の作成または更新を開始します');
    const original = await DataStore.query(BondsContent, id);

    if (original) {
      logger.info(original);
      const data = await DataStore.save(
        BondsContent.copyOf(original, (updated) => {
          updated.type = type;
          updated.loanerName = loanerName;
          updated.initialLoanAmount = initialLoanAmount;
          updated.loanDate = loanDate;
          updated.loanAmount = loanAmount;
          updated.loanCertificate = loanCertificate;
          updated.loanStorageLocation = loanStorageLocation;
          updated.bonds = bonds;
        }),
      );
      logger.info(data);
      logger.info('債権の項目の更新が完了しました');

      return data;
    }
    logger.info('債権の項目の作成を開始します');
    const instance = {
      subNum,
      type,
      loanerName,
      initialLoanAmount,
      loanDate,
      loanAmount,
      loanCertificate,
      loanStorageLocation,
      bonds,
    };
    const data = await DataStore.save(new BondsContent(instance));
    logger.debug(data);
    logger.info('債権の項目の作成が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const MutateBondsInvestmentContentAndGetId = async (
  id: string,
  subNum: number,
  type: string | undefined,
  investmenterName: string,
  investmentUnitNumber: string,
  investmentAmount: string,
  investmentDate: string,
  redemptionDeadline: string,
  investmentCertificate: boolean,
  investmentStorageLocation: string,
  bonds?: Bonds,
): Promise<BondsContent> => {
  try {
    logger.info('債権の項目の作成または更新を開始します');
    const original = await DataStore.query(BondsContent, id);

    if (original) {
      logger.info(original);
      const data = await DataStore.save(
        BondsContent.copyOf(original, (updated) => {
          updated.type = type;
          updated.investmenterName = investmenterName;
          updated.investmentUnitNumber = investmentUnitNumber;
          updated.investmentAmount = investmentAmount;
          updated.investmentDate = investmentDate;
          updated.redemptionDeadline = redemptionDeadline;
          updated.investmentCertificate = investmentCertificate;
          updated.investmentStorageLocation = investmentStorageLocation;
          updated.bonds = bonds;
        }),
      );
      logger.info(data);
      logger.info('債権の項目の更新が完了しました');

      return data;
    }
    logger.info('債権の項目の作成を開始します');
    const instance = {
      subNum: 5,
      type,
      investmenterName,
      investmentUnitNumber,
      investmentAmount,
      investmentDate,
      redemptionDeadline,
      investmentCertificate,
      investmentStorageLocation,
      bonds,
    };
    const data = await DataStore.save(new BondsContent(instance));
    logger.debug(data);
    logger.info('債権の項目の作成が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const CreateBondsContentAndGetId = async (
  instance: ModelInit<BondsContent, BondsContentMetaData>,
): Promise<string> => {
  try {
    logger.info('債権の項目の作成を開始します');
    const data = await DataStore.save(new BondsContent(instance));
    logger.debug(data);
    logger.info('債権の項目の作成が完了しました');

    return data.id;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const UpdateBondsContentById = async (
  id: string,
  type: string,
  loanerName: string,
  initialLoanAmount: string,
  loanDate: string,
  loanAmount: string,
  loanCertificate: boolean,
  loanStorageLocation: string,
  investmenterName: string,
  investmentUnitNumber: string,
  investmentAmount: string,
  investmentDate: string,
  redemptionDeadline: string,
  investmentCertificate: boolean,
  investmentStorageLocation: string,
  bonds?: Bonds,
): Promise<void> => {
  try {
    logger.info('債権の項目の更新を開始します');

    const original = await DataStore.query(BondsContent, id);

    if (original) {
      logger.info(original);
      const data = await DataStore.save(
        BondsContent.copyOf(original, (updated) => {
          updated.type = type;
          updated.loanerName = loanerName;
          updated.initialLoanAmount = initialLoanAmount;
          updated.loanDate = loanDate;
          updated.loanAmount = loanAmount;
          updated.loanCertificate = loanCertificate;
          updated.loanStorageLocation = loanStorageLocation;
          updated.investmenterName = investmenterName;
          updated.investmentUnitNumber = investmentUnitNumber;
          updated.investmentAmount = investmentAmount;
          updated.investmentDate = investmentDate;
          updated.redemptionDeadline = redemptionDeadline;
          updated.investmentCertificate = investmentCertificate;
          updated.investmentStorageLocation = investmentStorageLocation;
          updated.bonds = bonds;
        }),
      );
      logger.info(data);
      logger.info('債権の項目の更新が完了しました');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteBondsContentById = async (id: string): Promise<void> => {
  try {
    logger.info('債権の項目の削除を開始します');
    const original = await DataStore.query(BondsContent, id);
    if (original) {
      logger.info(original);
      await DataStore.delete(original);
      logger.info('債権の項目の削除が完了しました');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetBondsContent = async (): Promise<BondsContent[]> => {
  try {
    logger.info('債権の項目のフェッチを開始します');
    const data = await DataStore.query(BondsContent);
    logger.debug(data);
    logger.info('債権の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetBondsContentByParent = async (
  bonds: Bonds | null,
): Promise<BondsContent[]> => {
  try {
    logger.info('債権の項目のフェッチを開始します');
    const data = (await DataStore.query(BondsContent)).filter((c) => {
      if (c.bonds === null || c.bonds === undefined || !bonds) {
        return false;
      }
      const bondsId = c.bonds.id;

      return bonds.id === bondsId;
    });
    logger.debug(data);
    logger.info('株式・債券の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteBondsContent = async (): Promise<BondsContent[]> => {
  try {
    logger.info('債権の全項目の削除を開始します');
    const data = await DataStore.query(BondsContent);
    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);

          return data;
        }),
      );
    }
    logger.debug(data);
    logger.info('債権の全項目の削除を開始します');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
