export type LegalHeirOrBeneficiary = '法定相続人' | '受遺者' | null;

export const legalHeirOrBeneficiary = (
  type: string | null | undefined,
): LegalHeirOrBeneficiary => {
  if (type === '法定相続人') {
    return '法定相続人';
  }
  if (type === '受遺者') {
    return '受遺者';
  }

  return null;
};
