import { FC, useRef, useEffect } from 'react';
import adviceIcon from '../images/adviceIcon.svg';
import './advicePop.css';

const AdvicePop: FC = () => {
  const advicePopRef = useRef<HTMLDivElement>(null);

  // const advicePopOff = () => {
  //   advicePopRef.current.classList.remove('advice-pop-on');
  // };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (advicePopRef.current) {
        const innnerHeight = Math.floor(window.innerHeight * 0.8);
        const TopDistance = advicePopRef.current?.getBoundingClientRect().top;
        if (TopDistance < innnerHeight) {
          advicePopRef.current?.classList.add('advice-pop-on');
          window.setTimeout(() => {
            advicePopRef.current?.classList.remove('advice-pop-on');
          }, 30000);
        }
      }
    });
  }, []);

  return (
    <div className="advicePop" ref={advicePopRef}>
      <div>
        <img src={adviceIcon} alt="アドバイス" />
        <p>
          各ボタンクリックで、
          <br />
          各該当ページTOPに遷移します
        </p>
      </div>
    </div>
  );
};

export default AdvicePop;
