/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import { FC, useState, ChangeEvent } from 'react';
import { Properties, LiabilitiesContent } from 'models';
import { usePropertiesItem, useQueryContents } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { MutateProperties } from 'domains/cognite/services/manageProperties';
import Dialog from 'common/apply/organisms/dialog';
import {
  DeleteLiabilities,
  MutateLiabilities,
} from 'domains/cognite/services/manageLiabilities';
import {
  MutateLiabilitiesContentAndGet,
  GetLiabilitiesContent,
  DeleteLiabilitiesContent,
  GetLiabilitiesContentByParent,
  DeleteLiabilitiesContentById,
} from 'domains/cognite/services/manageLiabilitiesContent';
import { logger } from 'utils/logger';
import { CognitoUserExt } from 'domains/cognite/user';
import PlusBtn from '../atoms/plusBtn';
import RadioCustomSingle from '../molecules/radioCustomSingle';
import LiabilitiesFormCard from '../organisms/liabilitiesFormCard';
import RequireNextReturn from '../molecules/requireNextReturn';
import './liabilitiesTemp.css';

type Props = {
  data: Properties | undefined;
  user: CognitoUserExt;
};

const LiabilitiesTemp: FC<Props> = (props) => {
  const { data, user } = props;

  const { liabilities } = usePropertiesItem(data);
  const { data: liabilitiesContentByQuery } = useQuery(
    ['liabilities_content'],
    () => GetLiabilitiesContentByParent(liabilities),
    {
      cacheTime: 200,
    },
  );

  const [isDialogShow, setIsDialogShow] = useState(false);

  const toggelDialog = () => {
    setIsDialogShow(!isDialogShow);
  };

  // 初期状態のオブジェクト
  const initialLiabilitiesContentArrData: LiabilitiesContent[] = [
    {
      id: '0',
      subNum: null,
      name: '',
      initialAmount: '',
      initialDate: '',
      guarantor: '',
      liabilities: undefined,
    },
  ];

  const initialLiabilitiesContentArr =
    liabilitiesContentByQuery === undefined ||
    (liabilitiesContentByQuery !== undefined &&
      liabilitiesContentByQuery.length === 0)
      ? initialLiabilitiesContentArrData
      : liabilitiesContentByQuery;

  const [liabilitiesContent, setLiabilitiesContent] = useState<
    LiabilitiesContent[]
  >(initialLiabilitiesContentArr);

  const updateLiabilitiesContent = (
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement>,
    target: string,
  ) => {
    const newLiabilitiesContent = liabilitiesContent?.map((ele) => {
      if (ele.id === subjectKey) {
        return {
          id: ele.id,
          subNum: ele.subNum ? ele.subNum : null,
          name:
            target === 'name'
              ? event.target.value
              : ele.name === null || ele.name === undefined
              ? ''
              : ele.name,
          initialAmount:
            target === 'initialAmount'
              ? event.target.value
              : ele.initialAmount === null || ele.initialAmount === undefined
              ? ''
              : ele.initialAmount,
          initialDate:
            target === 'initialDate'
              ? event.target.value
              : ele.initialDate === null || ele.initialDate === undefined
              ? ''
              : ele.initialDate,
          guarantor:
            target === 'guarantor'
              ? event.target.value
              : ele.guarantor === null || ele.guarantor === undefined
              ? null
              : ele.guarantor,
          liabilities: ele.liabilities,
        };
      }

      return {
        id: ele.id,
        subNum: ele.subNum,
        name: ele.name,
        initialAmount: ele.initialAmount,
        initialDate: ele.initialDate,
        guarantor: ele.guarantor,
        liabilities: ele.liabilities,
      };
    });

    return setLiabilitiesContent(newLiabilitiesContent);
  };

  const addcard = () => {
    const randomId = Math.random().toString();
    const addLiabilitiesContentObject = {
      id: randomId,
      subNum: null,
      name: '',
      initialAmount: '',
      initialDate: '',
      guarantor: '',
      liabilities: undefined,
    };

    setLiabilitiesContent([...liabilitiesContent, addLiabilitiesContentObject]);
  };

  const deleteCard = (deleteSubjectId: string) => {
    const newLiabilitiesContentAfterDelete = liabilitiesContent.filter(
      (ele) => ele.id !== deleteSubjectId,
    );

    return setLiabilitiesContent(newLiabilitiesContentAfterDelete);
  };

  // はい、いいえの初期状態管理
  const openOrClose = liabilitiesContent[0].id !== '0';
  const [open, setOpen] = useState(openOrClose);
  const yesCheck = liabilitiesContent[0].id !== '0';
  const [yesCheckState, setYesCheckState] = useState(yesCheck);
  const noCheck = !!(
    liabilities &&
    liabilities.noCheck &&
    liabilities.noCheck === true
  );
  const [noCheckState, setNoCheckState] = useState(noCheck);

  // 貸主名の有無のチェック
  const nameArr = liabilitiesContent.map((ele) => {
    const name = ele.name === undefined || ele.name === null ? '' : ele.name;

    return name;
  });

  // 当初借入金額の有無のチェック
  const initialAmountArr = liabilitiesContent.map((ele) => {
    const initialAmount =
      ele.initialAmount === undefined || ele.initialAmount === null
        ? ''
        : ele.initialAmount;

    return initialAmount;
  });

  // 当初借入金額の有無のチェック
  const initialDateArr = liabilitiesContent.map((ele) => {
    const initialDate =
      ele.initialDate === undefined || ele.initialDate === null
        ? ''
        : ele.initialDate;

    return initialDate;
  });

  // 保証人の有無のチェック
  const guarantorArr = liabilitiesContent.map((ele) => {
    const guarantor =
      ele.guarantor === undefined || ele.guarantor === null
        ? ''
        : ele.guarantor;

    return guarantor;
  });

  const propertiesId = data ? data.id : '';

  const { liabilitiesMajorNum } = useQueryContents('liabilities_check', data);

  const cardDeleteAll = async () => {
    await DeleteLiabilitiesContent();
    await DeleteLiabilities();
    await MutateProperties(propertiesId, {
      userId: user.attributes.sub,
      liabilities: undefined,
    });

    setOpen(false);
    setYesCheckState(false);
    window.location.reload();
  };

  const navigate = useNavigate();

  const save = async (link: string) => {
    const reservedLiabilitiesContent = await GetLiabilitiesContent();
    const liabilitiesContentId = liabilitiesContent.map((ele) => ele.id);
    reservedLiabilitiesContent.map((ele) => {
      if (liabilitiesContentId.includes(ele.id) === false) {
        return DeleteLiabilitiesContentById(ele.id);
      }

      return ele;
    });

    const nameCheckArr = liabilitiesContent.map((ele) => {
      const name = ele.name === undefined || ele.name === null ? '' : ele.name;

      return name;
    });

    if (
      (open === true && nameCheckArr.includes('')) ||
      (open === true && initialAmountArr.includes(''))
    ) {
      window.alert('必須項目を入力してください');
    } else {
      const id = data
        ? data.propertiesLiabilitiesId
          ? data.propertiesLiabilitiesId
          : ''
        : '';
      const createdLiabilities = await MutateLiabilities(id, {
        majorNum: liabilitiesMajorNum,
        inputFlag: true,
        noCheck: noCheckState,
      });

      const newLiabilitiesContent = open === false ? [] : liabilitiesContent;

      const newContent = await Promise.all(
        newLiabilitiesContent.map(async (ele, index) => {
          const contentItem = await MutateLiabilitiesContentAndGet(
            ele.id,
            index + 1,
            ele.name || '',
            ele.initialAmount || '',
            ele.initialDate || '',
            ele.guarantor || '',
            createdLiabilities,
          );

          return contentItem;
        }),
      );

      logger.info(newContent);

      await MutateProperties(propertiesId, {
        userId: user.attributes.sub,
        liabilities: createdLiabilities,
      });
      navigate(link);
    }
  };

  const noCheckFunc = async () => {
    await DeleteLiabilitiesContent();
    const noCheckId = data
      ? data.propertiesLiabilitiesId
        ? data.propertiesLiabilitiesId
        : ''
      : '';
    const createdLiabilitiesByNoCheck = await MutateLiabilities(noCheckId, {
      majorNum: liabilitiesMajorNum,
      inputFlag: true,
      noCheck: true,
    });

    await MutateProperties(propertiesId, {
      userId: user.attributes.sub,
      liabilities: createdLiabilitiesByNoCheck,
    });

    navigate('../legalheir');
  };

  return (
    <>
      {isDialogShow ? (
        <Dialog
          dialogText="入力内容を削除しますがよろしいですか？"
          dialogYesFunc={cardDeleteAll}
          dialogNoFunc={toggelDialog}
        />
      ) : (
        ''
      )}
      <div className="liabilitiesTop">
        <div className="liabilities">
          <RadioCustomSingle
            title="あなたが債務者（借主）として借りているお金、または会社経営者の方の帳簿上の借入金はありますか？"
            name="deposits"
            yesFunc={() => {
              setOpen(true);
              setNoCheckState(false);
            }}
            noFunc={noCheckFunc}
            yesCheck={yesCheckState}
            noCheck={noCheckState}
          />
        </div>
        <div
          className={`liabilitiesFormCardArea ${
            open ? 'liabilitiesopen' : 'liabilitiesclose'
          }`}
        >
          <div>
            {liabilitiesContent.map((ele, index, theForms) => (
              <LiabilitiesFormCard
                key={ele.id}
                title="負債"
                form={ele.id}
                minusBtnView={theForms.length !== 1}
                subNum={index + 1}
                nameValue={nameArr[index]}
                initialAmountValue={initialAmountArr[index]}
                initialDateValue={initialDateArr[index]}
                guarantorValue={guarantorArr[index]}
                updateLiabilitiesContent={updateLiabilitiesContent}
                subjectId={ele.id}
                deleteCard={deleteCard}
              />
            ))}
          </div>
          <button type="button" className="plusBtn-wrapper" onClick={addcard}>
            <PlusBtn />
          </button>
        </div>
      </div>
      <RequireNextReturn
        returnFunc={async () => {
          await save('../bonds');
        }}
        nextFunc={async () => {
          await save('../legalheir');
        }}
        deleteFunc={toggelDialog}
      />
    </>
  );
};

export default LiabilitiesTemp;
