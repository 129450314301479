/* eslint-disable import/prefer-default-export */
export const WillQuestionData = [
  {
    questionNumber: 1,
    question: '遺言アプリに入力した情報が、外部に漏れる心配はありませんか？',
    answer:
      '遺言アプリは、仮名で登録していただき、架空の情報を入力していただくシステムですから、万一、流出した場合でも実害は発生しません。なお、ご本人が敢えて事実を入力した場合でも、個人情報管理規定に基づいて適切に管理しますから、漏洩の可能性は極めて低いと言えます。',
  },
  {
    questionNumber: 2,
    question:
      '遺言アプリは、仮名記入、架空の財産目録・相続人を入力することになっていますが、これで何の意味があるのですか？',
    answer:
      '仮名、架空の財産・相続人を入力しても、何の効力もありませんが、遺言書とはどのようなものか、見てわかる仕組みになっています。また、仮名・架空のものであっても、ご本人が、事実はどうだと認識していらっしゃれば、遺産全体の把握やおおまかな配分方法なども考えやすくなります。作成途中の遺言書を、うっかり相続人に見られてしまって、生きているうちから相続争いが発生するといったことも防げます。',
  },
  {
    questionNumber: 3,
    question:
      '遺言アプリの、遺言執行者名簿に記載された人たちは、どなたでも質問に答えてくれるのですか。同時に、複数の遺言執行者に質問や、見積り依頼をすることは可能ですか？',
    answer:
      '遺言執行者の都合さえ合えば、どなたでも答えてもらえます。但し、遺言執行者の方から質問内容の確認や補足を求められ、質問者が混乱する可能性がありますから、同じ内容の質問を同時に多数の遺言執行者へ送ることはご遠慮ください。一人の遺言執行者へ質問したらその回答を待って、よく理解したうえで（もしくは回答がない場合）他の遺言執行者へ質問するという方法をお勧めします。',
  },
  {
    questionNumber: 4,
    question: '遺言アプリはなぜ無料で使用できるのですか？',
    answer:
      '遺言アプリは、一般社団法人日本遺言執行士協会がボランティア目的で資金提供し、登録遺言執行者の名簿登録料（広告料）で運営管理されていますから、日本遺言執行士協会がアプリ利用者へ料金請求することはありません。但し、アプリ利用者が直接遺言執行者に依頼した場合は、一定の質問事項以外は原則有料となりますので遺言執行者に確認してください。',
  },
];
