import { FC, Suspense } from 'react';
import { Link, useLocation } from 'react-router-dom';
import TopicsEditRemoveBtns from '../molcules/topicsEditRemoveBtns';
import AdminNav from '../organisms/adminNav';
import './topicsTemplate.css';

type Props = {
  subTitle: string;
  sideBtnText: string;
  sideBtnPath: string;
};

const TopicsTemplate: FC<Props> = (props) => {
  const { children, subTitle, sideBtnText, sideBtnPath } = props;

  const location = useLocation();
  const topicsListClassName = location.pathname.includes('/list')
    ? 'topics-page-content_topics-list'
    : '';

  return (
    <>
      <AdminNav />
      <div className="topics-template-main">
        <div className="topics-template-wrapper">
          <h1>トピックス</h1>
          <p>{subTitle}</p>
          <Suspense fallback={<div>...読み込み中</div>}>
            <TopicsEditRemoveBtns />
          </Suspense>
          <div className={`topics-page-content ${topicsListClassName}`}>
            {children}
          </div>
        </div>
      </div>
      <div className="side-btn-area">
        <Link to={sideBtnPath}>{sideBtnText}</Link>
      </div>
    </>
  );
};

export default TopicsTemplate;
