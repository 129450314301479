/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import Slider from 'react-slick';
import pageData from '../data/pageData';
import PageDescription from '../organisms/pageDescription';
import LiabilitiesBack from '../images/LiabilitiesBack.svg';
import './liabilitiesDescription.css';

const LiabilitiesDescription: FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  return (
    <div className="LiabilitiesDescription">
      <div>
        <Slider {...settings}>
          <PageDescription
            page="realEstatePage"
            description={pageData[13].description}
            NotParamDescription="NotParamDescription"
          />
          {/* <PageDescription
            page="realEstatePage"
            description={pageData[14].description}
          /> */}
        </Slider>
      </div>
      <img src={LiabilitiesBack} alt="消極財産負債背景画像" />
    </div>
  );
};

export default LiabilitiesDescription;
