import { LevelFormat } from 'docx';

const numbering = {
  config: [
    {
      reference: 'ref1',
      levels: [
        {
          level: 0,
          format: LevelFormat.DECIMAL,
          text: '第%1条',
          start: 1,
        },
      ],
    },
    {
      reference: 'ref2',
      levels: [
        {
          level: 0,
          format: LevelFormat.DECIMAL_ENCLOSED_CIRCLE,
          text: '%1',
          start: 1,
        },
      ],
    },
  ],
};

export default numbering;
