import { DataStore } from 'aws-amplify';
import { SearchHistory, SearchHistoryMetaData } from 'models';
import { logger } from 'utils/logger';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ModelInit } from '@aws-amplify/datastore';

export const CreateSearchHistory = async (
  instance: ModelInit<SearchHistory, SearchHistoryMetaData>,
): Promise<void> => {
  try {
    logger.info('aaaa');
    await DataStore.save(new SearchHistory(instance));
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetSearchHistory = async (): Promise<SearchHistory[]> => {
  try {
    const searchHistories = await DataStore.query(SearchHistory);

    return searchHistories;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
