/* eslint-disable import/prefer-default-export */
import { API } from 'aws-amplify';
import { logger } from 'utils/logger';

type Response = {
  exist: boolean;
};

export const checkUserExist = async (email: string): Promise<Response> => {
  const apiName = 'CommonQueries';
  const path = '/checkUserExist';
  const params = {
    headers: {
      'Content-Type': 'application/json',
    },
    queryStringParameters: {
      username: email,
    },
  };

  const responce = await API.get(apiName, path, params)
    .then((res: Response) => {
      logger.info('ユーザー存在チェック成功');

      return res;
    })
    .catch((error) => {
      logger.error('ユーザー存在チェック失敗');
      logger.error(error);

      // return {
      //   exist: false,
      // } as Response;

      throw error;
    });

  return responce;
};
