import { Auth, DataStore } from 'aws-amplify';
import { logger } from 'utils/logger';
import { Chatroom, Message } from 'models';
import { CognitoUserExt } from '../user';

// 遺言者が実行
export const CreateChatRoom = async (assigneeId: string): Promise<Chatroom> => {
  try {
    logger.info('チャットルームの作成を開始します');
    const currentuser: CognitoUserExt =
      (await Auth.currentAuthenticatedUser()) as CognitoUserExt;

    const checkdata = await DataStore.query(Chatroom, (c) =>
      c.clientId('eq', currentuser.getUsername()).assigneeId('eq', assigneeId),
    );
    logger.debug('チャットルーム事前確認', checkdata);

    if (checkdata.length) {
      logger.info('すでに存在しているためチャットルームの作成をスキップします');

      return checkdata[0];
    }

    const data = await DataStore.save(
      new Chatroom({
        clientId: currentuser.getUsername(),
        assigneeId,
      }),
    );
    logger.debug('チャットルーム作成後データ', data);
    logger.info('チャットルームの作成が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

// 執行者が実行
export const CreateChatRoomFromAssignee = async (
  clientId: string,
): Promise<Chatroom> => {
  try {
    logger.info('チャットルームの作成を開始します');
    const currentuser: CognitoUserExt =
      (await Auth.currentAuthenticatedUser()) as CognitoUserExt;

    const data = await DataStore.save(
      new Chatroom({
        clientId,
        assigneeId: currentuser.getUsername(),
      }),
    );
    logger.debug('チャットルーム作成後データ', data);
    logger.info('チャットルームの作成が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const CreateMessage = async (
  ownerId: string,
  listenerId: string,
  chatroom: Chatroom,
  content: string,
): Promise<Message> => {
  try {
    logger.info('メッセージを送信します');
    const data = await DataStore.save(
      new Message({
        ownerId,
        listenerId,
        chatroom,
        content,
        alreadyRead: false,
      }),
    );
    logger.debug(data);
    logger.info('メッセージの送信が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const listMessage = async (chatroom: Chatroom): Promise<Message[]> => {
  try {
    logger.info('メッセージのフェッチを開始します');
    logger.debug('チャットルーム確認', chatroom);
    const data = (await DataStore.query(Message)).filter(
      (c) => c.chatroom?.id === chatroom.id,
    );

    logger.debug('チャットメッセージ', data);
    logger.info('メッセージのフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const listMessageFromlistener = async (): Promise<Message[]> => {
  try {
    logger.info('メッセージのフェッチを開始します');
    const currentuser: CognitoUserExt =
      (await Auth.currentAuthenticatedUser()) as CognitoUserExt;
    const data = await DataStore.query(Message, (c) =>
      c.listenerId('eq', currentuser.getUsername()),
    );

    logger.debug('チャットメッセージ', data);
    logger.info('メッセージのフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const alreadyReadMesssage = async (
  ownerId: string,
  listenerId: string,
): Promise<void> => {
  try {
    const messages = await DataStore.query(Message, (c) =>
      c.ownerId('eq', ownerId).listenerId('eq', listenerId),
    );
    const messagesAlredyRead = Promise.all(
      messages.map(async (ele) => {
        const data = await DataStore.save(
          Message.copyOf(ele, (updated) => {
            // eslint-disable-next-line no-param-reassign
            updated.alreadyRead = true;
          }),
        );

        return data;
      }),
    );
    logger.info(messagesAlredyRead);
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetChatRoomFromClient = async (): Promise<
  Chatroom | undefined
> => {
  try {
    logger.info('チャットルームのフェッチを開始します');
    const currentuser: CognitoUserExt =
      (await Auth.currentAuthenticatedUser()) as CognitoUserExt;

    const data = await DataStore.query(Chatroom, (c) =>
      c.clientId('eq', currentuser.getUsername()),
    );

    if (!data.length) {
      logger.info('チャットルームの作成が完了していません');

      return undefined;
    }
    logger.info('チャットルームのフェッチが完了しました');

    return data[0];
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

// 執行者が実行
export const GetChatRoomFromAssignee = async (
  clientId: string | undefined,
): Promise<Chatroom | undefined> => {
  try {
    if (!clientId) return undefined;

    logger.info('チャットルームのフェッチを開始します');
    const currentuser: CognitoUserExt =
      (await Auth.currentAuthenticatedUser()) as CognitoUserExt;

    const data = await DataStore.query(Chatroom, (c) =>
      c.clientId('eq', clientId).assigneeId('eq', currentuser.getUsername()),
    );
    logger.info('checkdata', data);

    if (!data.length) {
      logger.info('チャットルームの作成が完了していません');

      return undefined;
    }
    logger.info('チャットルームのフェッチが完了しました');

    return data[0];
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

// 執行者が実行
export const ListChatRooms = async (): Promise<Chatroom[] | undefined> => {
  try {
    logger.info('チャットルームのリストのフェッチを開始します。');
    const currentuser: CognitoUserExt =
      (await Auth.currentAuthenticatedUser()) as CognitoUserExt;
    const data = await DataStore.query(Chatroom, (c) =>
      c.assigneeId('eq', currentuser.getUsername()),
    );
    logger.info('checkdata', data);

    if (!data.length) {
      logger.info('チャットルームの作成が完了していません');

      return undefined;
    }
    logger.info('チャットルームのフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

// 管理者が実行(遺言者)
export const GetChatRoomFromClientToAdmin = async (
  clientId: string | undefined,
): Promise<Chatroom | undefined> => {
  try {
    if (!clientId) return undefined;
    logger.info('チャットルームのフェッチを開始します');

    const data = await DataStore.query(Chatroom, (c) =>
      c.clientId('eq', clientId),
    );
    logger.info('checkdata', data);

    if (!data.length) {
      logger.info('チャットルームの作成が完了していません');

      return undefined;
    }
    logger.info('チャットルームのフェッチが完了しました');

    return data[0];
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

// 管理者が実行(執行者)
export const GetChatRoomFromAssigneeToAdmin = async (
  clientId: string | undefined,
): Promise<Chatroom | undefined> => {
  try {
    if (!clientId) return undefined;

    logger.info('チャットルームのフェッチを開始します');

    const data = await DataStore.query(Chatroom, (c) =>
      c.clientId('eq', clientId),
    );
    logger.info('checkdata', data);

    if (!data.length) {
      logger.info('チャットルームの作成が完了していません');

      return undefined;
    }
    logger.info('チャットルームのフェッチが完了しました');

    return data[0];
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

// 管理者が実行
export const ListChatRoomsByAdmin = async (
  assigneeId: string,
): Promise<Chatroom[] | undefined> => {
  try {
    logger.info('チャットルームのリストのフェッチを開始します。');
    const data = await DataStore.query(Chatroom, (c) =>
      c.assigneeId('eq', assigneeId),
    );
    logger.info('checkdata', data);

    if (!data.length) {
      logger.info('チャットルームの作成が完了していません');

      return undefined;
    }
    logger.info('チャットルームのフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteChatRoomsByClient = async (
  clientId: string,
): Promise<void> => {
  try {
    const data = await DataStore.query(Chatroom, (c) =>
      c.clientId('eq', clientId),
    );

    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);
        }),
      );
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteChatRoomsFromAdmin = async (
  assigneeId: string,
): Promise<void> => {
  try {
    const data = await DataStore.query(Chatroom, (c) =>
      c.assigneeId('eq', assigneeId),
    );

    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);
        }),
      );
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
