// import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Allocation, AllocationProperties } from 'models';
// import { QueryAllocation } from 'domains/cognite/services/manageAllocation';
import { GetAllocationReceiverByParent } from 'domains/cognite/services/manageAllocationReceiver';
import { GetAllocationPropertiesByParent } from 'domains/cognite/services/manageAllocationProperties';
// import { CognitoUserExt } from 'domains/cognite/user';
// import { logger } from 'utils/logger';

type AllocationReceiverAndProperties = {
  allocationProperties: AllocationProperties[];
  id: string;
  num?: number | null | undefined;
  name?: string | null | undefined;
  birth?: string | null | undefined;
  type?: string | null | undefined;
  allocation?: Allocation | null | undefined;
  createdAt?: string | null | undefined;
  updatedAt?: string | null | undefined;
};

const useQueryAllocationReceiverAndProperties = (
  key: string,
  allocation: Allocation | undefined,
): {
  allocationReceiverAndProperties: AllocationReceiverAndProperties[];
} => {
  const getAllocationrReceiverWithProperties = async () => {
    const receiver = await GetAllocationReceiverByParent(allocation);

    const receiverWithProperties = await Promise.all(
      receiver.map(async (ele) => {
        const properties = await GetAllocationPropertiesByParent(ele);

        return {
          ...ele,
          allocationProperties: properties,
        };
      }),
    );

    return receiverWithProperties;
  };

  const { data: allcationReceiverAndPropertiesData } = useQuery(
    [`allcationReceiverAndPropertiesData_${key}`],
    async () => getAllocationrReceiverWithProperties(),
    {
      enabled: !!allocation,
      cacheTime: 200,
    },
  );

  const allocationReceiverAndProperties =
    allcationReceiverAndPropertiesData || [];

  return { allocationReceiverAndProperties };
};

export default useQueryAllocationReceiverAndProperties;
