/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import { FC, useState } from 'react';
import { Properties, RealEstateContent } from 'models';
import { useQuery } from 'react-query';
import { usePropertiesItem, useQueryContents } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { logger } from 'utils/logger';
import { CognitoUserExt } from 'domains/cognite/user';
import { MutateProperties } from 'domains/cognite/services/manageProperties';
import {
  DeleteRealEstate,
  MutateRealEstate,
} from 'domains/cognite/services/manageRealEstate';
import {
  MutateRealEstateContentAndGet,
  GetRealEstateContent,
  DeleteRealEstateContent,
  GetRealEstateContentByParent,
  DeleteRealEstateContentById,
} from 'domains/cognite/services/manageRealEstateContent';
import Dialog from 'common/apply/organisms/dialog';
import RequireNextReturn from '../molecules/requireNextReturn';
import PlusBtn from '../atoms/plusBtn';
import RadioCustomSingle from '../molecules/radioCustomSingle';
import RealEstateFormCard from '../organisms/realEstateFormCard';
import Param6Checkmark from '../atoms/paramCheckmarkPc';
import './realEstateTemp.css';

type Props = {
  data: Properties | undefined;
  user: CognitoUserExt;
};

const RealEstateTemp: FC<Props> = (props) => {
  const { data, user } = props;

  const { realEstate } = usePropertiesItem(data);
  const { data: realEstateContentByQuery } = useQuery(
    ['realEstate_content'],
    () => GetRealEstateContentByParent(realEstate),
    {
      cacheTime: 200,
    },
  );

  const [isDialogShow, setIsDialogShow] = useState(false);
  const toggleDialog = () => {
    setIsDialogShow(!isDialogShow);
  };

  // 初期状態のオブジェクト
  const initialRealEstateContentArrData: RealEstateContent[] = [
    {
      id: '0',
      subNum: null,
      type: '',
      address: '',
      realEstate: undefined,
    },
  ];

  const initialRealEstateContentArr =
    realEstateContentByQuery === undefined ||
    (realEstateContentByQuery !== undefined &&
      realEstateContentByQuery.length === 0)
      ? initialRealEstateContentArrData
      : realEstateContentByQuery;

  const [realEstateContent, setRealEstateContent] = useState<
    RealEstateContent[]
  >(initialRealEstateContentArr);

  const updateRealEstateContent = (
    subjectKey: string,
    updateData: string,
    target: string,
  ) => {
    const newRealEstateContent = realEstateContent.map((ele) => {
      if (ele.id === subjectKey) {
        return {
          id: ele.id,
          subNum: ele.subNum ? ele.subNum : null,
          type:
            target === 'type'
              ? updateData
              : ele.type === null || ele.type === undefined
              ? ''
              : ele.type,
          address:
            target === 'address'
              ? updateData
              : ele.address === null || ele.address === undefined
              ? ''
              : ele.address,
          realEstate: ele.realEstate,
        };
      }

      return {
        id: ele.id,
        subNum: ele.subNum,
        type: ele.type,
        address: ele.address,
        realEstate: ele.realEstate,
      };
    });

    return setRealEstateContent(newRealEstateContent);
  };

  const addcard = () => {
    const randomId = Math.random().toString();
    const addRealEstateContentObject = {
      id: randomId,
      subNum: null,
      type: '',
      address: '',
      realEstate: undefined,
    };

    setRealEstateContent([...realEstateContent, addRealEstateContentObject]);
  };

  const deleteCard = (deleteSubjectId: string) => {
    const newRealEstateContentAfterDelete = realEstateContent.filter(
      (ele) => ele.id !== deleteSubjectId,
    );

    return setRealEstateContent(newRealEstateContentAfterDelete);
  };

  // はい、いいえの初期状態管理
  const openOrClose = realEstateContent[0].id !== '0';
  const [open, setOpen] = useState(openOrClose);
  const yesCheck = realEstateContent[0].id !== '0';
  const [yesCheckState, setYesCheckState] = useState(yesCheck);
  const noCheck = !!(
    realEstate &&
    realEstate.noCheck &&
    realEstate.noCheck === true
  );
  const [noCheckState, setNoCheckState] = useState(noCheck);

  // 所有物としてチェックした項目の情報取得
  const realEstateCheckArr = realEstateContent.map((ele) => {
    const realEstateType =
      ele.type === undefined || ele.type === null ? '' : ele.type;

    return realEstateType;
  });

  const realEstateTypeCheckArr = realEstateCheckArr.map((ele) => {
    const realEstateTypeCheck = ele !== '';

    return realEstateTypeCheck;
  });

  // 住所の情報のチェック
  // 都道府県の情報取得
  const prefectureArr = realEstateContent.map((ele) => {
    const addressName =
      ele.address === undefined || ele.address === null ? '' : ele.address;
    const whiteSpace = /\s+/;
    const separateAddress = addressName.split(whiteSpace);

    return separateAddress[0];
  });

  // 市区町村の情報取得
  const citiesArr = realEstateContent.map((ele) => {
    const addressName =
      ele.address === undefined || ele.address === null ? '' : ele.address;
    const whiteSpace = /\s+/;
    const separateAddress = addressName.split(whiteSpace);

    return separateAddress[1];
  });

  // 番地以下の情報
  const houseNumberArr = realEstateContent.map((ele) => {
    const addressName =
      ele.address === undefined || ele.address === null ? '' : ele.address;
    const whiteSpace = /\s+/;
    const separateAddress = addressName.split(whiteSpace);

    return separateAddress[2];
  });

  const propertiesId = data ? data.id : '';
  const { realEstateMajorNum } = useQueryContents('realEstate_check', data);

  const cardDeleteAll = async () => {
    await DeleteRealEstateContent();
    await DeleteRealEstate();
    await MutateProperties(propertiesId, {
      userId: user.attributes.sub,
      realEstate: undefined,
    });

    setOpen(false);
    setYesCheckState(false);
    window.location.reload();
  };

  const navigate = useNavigate();

  const save = async (link: string) => {
    const reservedRealEstateContent = await GetRealEstateContent();
    const realEstateContentId = realEstateContent.map((ele) => ele.id);
    reservedRealEstateContent.map((ele) => {
      if (realEstateContentId.includes(ele.id) === false) {
        return DeleteRealEstateContentById(ele.id);
      }

      return ele;
    });
    const prefectureCheckArr = realEstateContent.map((ele) => {
      const addressCheckName =
        ele.address === undefined || ele.address === null ? '' : ele.address;
      const prefectureCheck = !!(
        addressCheckName.includes('都') ||
        addressCheckName.includes('道') ||
        addressCheckName.includes('府') ||
        addressCheckName.includes('県')
      );

      return prefectureCheck;
    });

    if (open === true && prefectureCheckArr.includes(false)) {
      window.alert('必須項目を入力してください');
    } else {
      const id = data
        ? data.propertiesRealEstateId
          ? data.propertiesRealEstateId
          : ''
        : '';

      const createdRealEstate = await MutateRealEstate(id, {
        majorNum: realEstateMajorNum,
        inputFlag: true,
        noCheck: noCheckState,
      });

      const newRealEstateContent = open === false ? [] : realEstateContent;

      const newContent = await Promise.all(
        newRealEstateContent.map(async (ele, index) => {
          const contentItem = await MutateRealEstateContentAndGet(
            ele.id,
            index + 1,
            ele.type || '不明',
            ele.address || '不明',
            createdRealEstate,
          );

          return contentItem;
        }),
      );

      logger.info(newContent);

      await MutateProperties(propertiesId, {
        userId: user.attributes.sub,
        realEstate: createdRealEstate,
      });
      navigate(link);
    }
  };

  const noCheckFunc = async () => {
    await DeleteRealEstateContent();
    const noCheckId = data
      ? data.propertiesRealEstateId
        ? data.propertiesRealEstateId
        : ''
      : '';
    const createdRealEstateByNoCheck = await MutateRealEstate(noCheckId, {
      majorNum: realEstateMajorNum,
      inputFlag: true,
      noCheck: true,
    });

    await MutateProperties(propertiesId, {
      userId: user.attributes.sub,
      realEstate: createdRealEstateByNoCheck,
    });

    navigate('../movingproperty');
  };

  return (
    <>
      {isDialogShow ? (
        <Dialog
          dialogText="入力内容を削除しますがよろしいですか？"
          dialogYesFunc={cardDeleteAll}
          dialogNoFunc={toggleDialog}
        />
      ) : (
        ''
      )}
      <div className="realEstateTemp">
        <div className="realEstateTop">
          <div className="realEstate">
            <div className="pc-only realEstateParam1">
              <Param6Checkmark
                StyleClass="styleParam1"
                key="realEstateDescription_pc"
                checkMarkSize6
                propertyData={data}
              />
            </div>
            <RadioCustomSingle
              title="不動産を所有していますか？"
              name="real-estate"
              yesFunc={() => {
                setOpen(true);
                setNoCheckState(false);
              }}
              noFunc={noCheckFunc}
              yesCheck={yesCheckState}
              noCheck={noCheckState}
            />
          </div>
          <div
            className={`realEstateFormCardArea ${
              open ? 'realEstateopen' : 'realEstateclose'
            }`}
          >
            <div>
              {realEstateContent.map((ele, index, theForms) => (
                <RealEstateFormCard
                  key={ele.id}
                  title="所有不動産"
                  form={ele.id}
                  minusBtnView={theForms.length !== 1}
                  subNum={index + 1}
                  realEstateCheck={realEstateCheckArr[index]}
                  prefectureSelected={prefectureArr[index]}
                  citySelected={citiesArr[index]}
                  houseNumberValue={houseNumberArr[index]}
                  realEstateTypeCheck={realEstateTypeCheckArr[index]}
                  keyValue={`realEstate${index}`}
                  prefecturekeyValue={`RealEstateFormCardPrefecture_${ele.id}`}
                  updateRealEstateContent={updateRealEstateContent}
                  subjectId={ele.id}
                  deleteCard={deleteCard}
                />
              ))}
            </div>
            <button type="button" className="plusBtn-wrapper" onClick={addcard}>
              <PlusBtn />
            </button>
          </div>
        </div>
      </div>
      <RequireNextReturn
        returnFunc={async () => {
          await save('../willinfo');
        }}
        nextFunc={async () => {
          await save('../movingproperty');
        }}
        deleteFunc={toggleDialog}
      />
    </>
  );
};

export default RealEstateTemp;
