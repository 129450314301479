/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { ChangeEvent, FC, useState } from 'react';
import { useQuery } from 'react-query';
import { useUsersListFromAdmin } from 'hooks';
import { getExecutorFromAdmin } from 'domains/apigateway/services/get-ExecutorFromAdmin';
import { GetExecutorViewHistory } from 'domains/cognite/services/manageExecutorViewHistory';
import AdminNav from '../organisms/adminNav';
import ExecutorDataBtns from '../organisms/executorDataBtns';

import './executorPageView.css';

const ExecutorPageView: FC = () => {
  const getExecutors = async () => {
    let value = await getExecutorFromAdmin();
    let results = value.executorDatas;

    while (value.executorDatas.length >= 60) {
      // eslint-disable-next-line no-await-in-loop
      const newValue = await getExecutorFromAdmin(value.token);
      value = newValue;
      results = results.concat(newValue.executorDatas);
    }

    return results;
  };

  const { data: executorDatas } = useQuery(
    ['get_executorDatasFromAdminExecutorPagaView'],
    getExecutors,
  );

  const { data: ExecutorViewHistory } = useQuery(
    ['get_executorViewHistoryFromAdminExecutorPagaView'],
    GetExecutorViewHistory,
  );

  if (!executorDatas) {
    throw Error();
  }

  if (!ExecutorViewHistory) {
    throw Error();
  }

  const UserDatas = useUsersListFromAdmin([
    'get_userDatasFromAdminExecutorPagaView',
  ]);

  const [executorSub, setExecutorSub] = useState(executorDatas[0].sub);

  const getSelectedExecutor = (data: ChangeEvent<HTMLSelectElement>) => {
    setExecutorSub(data.currentTarget.value);
  };

  const historyAddedEmail = ExecutorViewHistory.map((x) => {
    const sameIdUser = UserDatas.find((y) => {
      if (x.userId === y.sub) {
        return y;
      }
    });

    return {
      ...x,
      userEmail: sameIdUser?.email,
    };
  });

  const filteredhistory = historyAddedEmail.filter((ele) => {
    if (ele.executorId === executorSub) {
      return ele;
    }
  });

  const filteredhistoryFormatDate = filteredhistory.map((ele) => {
    if (ele.createdAt !== undefined && ele.createdAt !== null) {
      const date = new Date(ele.createdAt);
      const getMonth = (date.getMonth() + 1).toString();
      const getDate = date.getDate().toString();
      const getHours = date.getHours().toString();
      const formateHours =
        getHours.length < 2 ? getHours.padStart(2, '0') : getHours;
      const getMinutes = date.getMinutes().toString();
      const formateMinutes =
        getMinutes.length < 2 ? getMinutes.padStart(2, '0') : getMinutes;
      const formatDate = `${getMonth}/${getDate} ${formateHours}:${formateMinutes}`;

      return {
        ...ele,
        createdAt: formatDate,
      };
    }

    return {
      ...ele,
      createdAt: '不明',
    };
  });

  return (
    <>
      <AdminNav />
      <div className="executor-page-view">
        <div className="executor-page-view-wrapper">
          <h1>遺言者のデータ分析</h1>
          <p>遺言執行者名簿の足跡数</p>
          <div className="executor-page-view-chart-btns">
            <div className="executor-page-view-chart">
              <select
                onChange={(e) => {
                  getSelectedExecutor(e);
                }}
              >
                {executorDatas.map((ele) => (
                  <option value={ele.sub}>{ele.name}</option>
                ))}
              </select>

              <p>
                【登録名簿を閲覧した遺言者は「{filteredhistoryFormatDate.length}
                」人】
              </p>
              <div className="page-view-list">
                {filteredhistoryFormatDate.map((ele) => (
                  <div>
                    <p>{ele.userEmail}</p>
                    <p>閲覧日時 : {ele.createdAt}</p>
                  </div>
                ))}
              </div>
            </div>
            <ExecutorDataBtns />
          </div>
        </div>
      </div>
    </>
  );
};
export default ExecutorPageView;
