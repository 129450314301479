import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { GetS3, ListS3 } from 'domains/aws/services/get-s3';
import wordIcon from '../images/word-icon.svg';
import './wordDownload.css';

type Props = {
  userId: string;
  startUpload: boolean;
};

const WordDownload: FC<Props> = (props) => {
  const { userId, startUpload } = props;

  const getWord = async () => {
    const existWord = await ListS3(`遺言書_${userId}.docx`);
    const existPdf = await ListS3(`遺言書_${userId}.pdf`);
    const exist = !!existWord && !!existPdf;
    const url = await GetS3(`遺言書_${userId}.docx`, 'public');

    return {
      exist,
      url,
    };
  };

  const { data, error } = useQuery(['user_download_word', userId], getWord, {
    refetchInterval: 5000,
  });

  if (!data || error) {
    return (
      <Link to="/user/confirm" className="wordBtnNotClick">
        <img src={wordIcon} alt="" />
        Word出力
      </Link>
    );
  }

  const { url, exist } = data;
  const enableClick = exist && !startUpload ? '' : 'wordBtnNotClick';

  return (
    <a href={url} className={enableClick}>
      <img src={wordIcon} alt="" />
      Word出力
    </a>
  );
};

export default WordDownload;
