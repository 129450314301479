/* eslint-disable no-unneeded-ternary */
import { FC } from 'react';
import { Properties, Allocation } from 'models';
import InheritanceParam from '../images/inheritanceParam.svg';
import inputNone from '../images/inputNone.svg';
import inputCompleted from '../images/inputCompleted.svg';
import heirParam from '../images/heirParam.svg';
import inputOrangeCircle from '../images/inputOrangeCircle.svg';

type Props = {
  checkMarkSize6: boolean;
  propertyData?: Properties | undefined;
  allocationData?: Allocation | undefined;
};

const ParamCheckmark: FC<Props> = (props) => {
  const { checkMarkSize6, propertyData, allocationData } = props;

  const checkMark = [];

  const changeCheckMark = checkMarkSize6 ? InheritanceParam : heirParam;
  const CheckClassContainer = checkMarkSize6
    ? 'inputCompletedMark'
    : 'inputCompletedMark2';
  const paramNumberChange = checkMarkSize6 ? 6 : 2;

  for (let i = 0; i < paramNumberChange; i += 1) {
    if (checkMarkSize6) {
      if (propertyData) {
        const realEstateData = !!propertyData.realEstate;
        const movingPropertyData = !!propertyData.movingProperty;
        const depositsData = !!propertyData.deposits;
        const stockData = !!propertyData.stock;
        const lifeInsuranceData = !!propertyData.lifeInsurance;
        const bondsData = !!propertyData.bonds;
        const param = [
          realEstateData,
          movingPropertyData,
          depositsData,
          stockData,
          lifeInsuranceData,
          bondsData,
        ];
        if (param[i] === true) {
          checkMark.push(
            <div key={i}>
              <img src={inputCompleted} alt="チェックマーク" />
            </div>,
          );
        } else {
          checkMark.push(
            <div key={i}>
              <img src={inputNone} alt="チェックマーク" />
            </div>,
          );
        }
      } else {
        const param = [false, false, false, false, false, false];
        if (param[i] === true) {
          checkMark.push(
            <div key={`part${i}`}>
              <img src={inputCompleted} alt="チェックマーク" />
            </div>,
          );
        } else {
          checkMark.push(
            <div key={`part${i}`}>
              <img src={inputNone} alt="チェックマーク" />
            </div>,
          );
        }
      }
    } else if (allocationData) {
      const param2 = [
        allocationData.inputLegalHeirFlag,
        allocationData.inputRecipientFlag,
      ];
      if (param2[i] === true) {
        checkMark.push(
          <div key={`part2${i}`}>
            <img src={inputCompleted} alt="チェックマーク" />
          </div>,
        );
      } else {
        checkMark.push(
          <div key={`part2${i}`}>
            <img src={inputNone} alt="チェックマーク" />
          </div>,
        );
      }
    } else {
      const param2 = [false, false];
      if (param2[i] === true) {
        checkMark.push(
          <div key={`part3${i}`}>
            <img src={inputCompleted} alt="チェックマーク" />
          </div>,
        );
      } else {
        checkMark.push(
          <div key={`part3${i}`}>
            <img src={inputNone} alt="チェックマーク" />
          </div>,
        );
      }
    }
  }

  // 念の為のこす
  // return fetching ? (
  //   <div>Loading</div>
  // ) : (
  //   <>
  //     <img src={changeCheckMark} alt="" />
  //     <div className={CheckClassContainer}>{checkMark}</div>
  //     <img src={inputOrangeCircle} alt="" />
  //   </>
  // );

  return (
    <>
      <img src={changeCheckMark} alt="" />
      <div className={CheckClassContainer}>{checkMark}</div>
      <img src={inputOrangeCircle} alt="" />
    </>
  );
};

ParamCheckmark.defaultProps = {
  propertyData: undefined,
  allocationData: undefined,
};

export default ParamCheckmark;
