import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useQueryPdf } from 'hooks';
import pdfIcon from '../images/pdf-icon.svg';
import './pdfDownload.css';

type Props = {
  userId: string;
  startUpload: boolean;
};

const PdfDownload: FC<Props> = (props) => {
  const { userId, startUpload } = props;

  const { data, error, fileCheck, isLoadingCheck } = useQueryPdf(userId);

  if (!data || error) {
    return (
      <Link to="/user/confirm" className="pdfBtnNotClick">
        <img src={pdfIcon} alt="" />
        PDF出力
      </Link>
    );
  }

  const { url } = data;
  const enableClick =
    !startUpload && fileCheck && fileCheck.exist && !isLoadingCheck
      ? ''
      : 'pdfBtnNotClick';

  return (
    <a href={url} className={enableClick}>
      <img src={pdfIcon} alt="" />
      PDF出力
    </a>
  );
};

export default PdfDownload;
