import { FC } from 'react';
import { Navigate } from 'react-router';
import { useQuery } from 'react-query';
import ReactStars from 'react-stars';
import { useProfileIcon } from 'hooks';
import { AssignStatus } from 'models';
import { getOneExecutorCatch } from 'domains/apigateway/services/get-Executor';
import calcBirthDate from 'utils/calcBirthDate';
import defaultProfileIcon from '../images/defaultProfileIcon.svg';
import './executorFormCardApproved.css';

type Props = {
  executorId: string;
  currentStatus: AssignStatus;
  evaluationValueAveStar: number;
  evaluationValueAveDisplay: number;
  evaluationValueCount: number;
};

const ExecutorFormCardApproved: FC<Props> = (props) => {
  const {
    executorId,
    currentStatus,
    evaluationValueAveStar,
    evaluationValueAveDisplay,
    evaluationValueCount,
  } = props;

  const getTheExecutor = () => getOneExecutorCatch(executorId);

  const { data: executor } = useQuery(
    ['assignee_profile', currentStatus],
    getTheExecutor,
  );

  const { imageUrl } = useProfileIcon(defaultProfileIcon, executorId);

  if (!executor) {
    return <Navigate to="/user/executorallocation" replace />;
  }

  const { name, birthdate, prefecture, city, passport, zone, phoneNumber } =
    executor;

  const aboutAge = calcBirthDate(birthdate);

  const addressFromUser = prefecture + city;

  return (
    <div className="executorFormCardApproved">
      <div>
        <img
          src={imageUrl}
          alt="担当執行者のアイコン画像"
          className="executor-icon"
        />
        <table>
          <tr>
            <td>氏名</td>
            <td>{name}</td>
          </tr>
          <tr>
            <td>年齢</td>
            <td>{aboutAge}</td>
          </tr>
          <tr>
            <td>住所</td>
            <td>{addressFromUser}</td>
          </tr>
          <tr>
            <td>所持資格</td>
            <td>{passport.toString()}</td>
          </tr>
          <tr>
            <td>活動地域</td>
            <td>{zone.toString()}</td>
          </tr>
          <tr>
            <td>電話番号</td>
            <td>{phoneNumber.replace('+81', '0')}</td>
          </tr>
          <tr className="FormCardReactStarsWrapApproved">
            <td>評価</td>
            <td className="executorFormCardReactStarsApproved">
              <ReactStars
                count={5}
                value={evaluationValueAveStar}
                size={20}
                color1="#DCDDDD"
                color2="#F8B62D"
                half={false}
                edit={false}
              />
              <p>
                {evaluationValueAveDisplay}({evaluationValueCount})
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default ExecutorFormCardApproved;
