/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-alert */
import { ChangeEvent, FC, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useMutation, useQueryClient } from 'react-query';
import { logger } from 'utils/logger';
import {
  createUser,
  checkPassword,
  addUserToGroupExecutor,
} from 'domains/apigateway/services/createExecutorFromAdmin';
import { getExecutorFromAdmin } from 'domains/apigateway/services/get-ExecutorFromAdmin';
import { PrefectureArray, Prefecture } from 'admin/components/data/prefecture';
import AdminActivityAreaForm from '../templates/adminActivityAreaForm';
import AdminNav from '../organisms/adminNav';
import './executorCreate.css';

type cityProps = {
  prefCode: string;
  cityCode: string;
  cityName: string;
  bigCityFlag: string;
};

const ExecutorCreate: FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [prefecture, setPrefecture] = useState('');
  const [city, setCity] = useState('');
  const [addressNum, setAddressNum] = useState('');
  const [building, setBuilding] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [passport, setPassport] = useState('');

  const [cityValue, setCityValue] = useState<cityProps[]>([]);

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.currentTarget.value);
  };

  const handleChangePhoneNumber = (e: ChangeEvent<HTMLInputElement>): void => {
    setPhoneNumber(`+81${e.currentTarget.value.slice(1)}`);
  };
  const handleChangeName = (e: ChangeEvent<HTMLInputElement>): void => {
    setName(e.currentTarget.value);
  };
  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.currentTarget.value);
  };
  const handleChangePrefecture = (e: ChangeEvent<HTMLSelectElement>): void => {
    setPrefecture(e.currentTarget.value);
  };
  const handleChangeCity = (e: ChangeEvent<HTMLSelectElement>): void => {
    setCity(e.currentTarget.value);
  };
  const handleChangeAddressNum = (e: ChangeEvent<HTMLInputElement>): void => {
    setAddressNum(e.currentTarget.value);
  };
  const handleChangeBuilding = (e: ChangeEvent<HTMLInputElement>): void => {
    setBuilding(e.currentTarget.value);
  };
  const handleChangeBirthdate = (e: ChangeEvent<HTMLInputElement>): void => {
    setBirthdate(e.currentTarget.value);
  };

  const getCity = async (inputPrefecture: string) => {
    const selectArr = PrefectureArray.find(
      (ele) => ele.label === inputPrefecture,
    );
    const selectId = selectArr ? selectArr.id : 1000;

    await fetch(
      `https://opendata.resas-portal.go.jp/api/v1/cities?prefCode=${selectId}`,
      {
        headers: { 'X-API-KEY': 'yvet69MOZSiyzRoinuvNJaWjcXaqhoP118HTqciA' },
      },
    )
      .then((response) => response.json())
      .then((citiesData) => {
        setCityValue(citiesData.result);
      });
  };

  const changePrefectureAndGetCity = async (
    e: ChangeEvent<HTMLSelectElement>,
  ) => {
    handleChangePrefecture(e);
    await getCity(e.currentTarget.value);
  };

  const passportRef1 = useRef<HTMLInputElement>(null);
  const passportRef2 = useRef<HTMLInputElement>(null);
  const passportRef3 = useRef<HTMLInputElement>(null);
  const passportRef4 = useRef<HTMLInputElement>(null);
  const passportRef5 = useRef<HTMLInputElement>(null);
  const passportRef6 = useRef<HTMLInputElement>(null);

  const queryParamPassport = () => {
    const shikkoushi = passportRef1.current?.checked ? '遺言執行士,' : '';
    const bengoshi = passportRef2.current?.checked ? '弁護士,' : '';
    const shihoushoshi = passportRef3.current?.checked ? '司法書士,' : '';
    const gyouseishoshi = passportRef4.current?.checked ? '行政書士,' : '';
    const zeirishi = passportRef5.current?.checked ? '税理士,' : '';
    const other = passportRef6.current?.checked ? '宅地建物取引士' : '';
    setPassport(
      shikkoushi + bengoshi + shihoushoshi + gyouseishoshi + zeirishi + other,
    );
  };

  const [show, setShow] = useState(false);
  const [NewZoneinfo, setNewZoneinfo] = useState<string[]>([]);
  const setShowFunc = () => {
    setShow(false);
  };

  const NewZoneinfoStr = NewZoneinfo.join(',');

  const getExecutors = async () => {
    let value = await getExecutorFromAdmin();
    let results = value.executorDatas;

    while (value.executorDatas.length >= 60 && value.token) {
      // eslint-disable-next-line no-await-in-loop
      const newValue = await getExecutorFromAdmin(value.token);
      value = newValue;
      results = results.concat(newValue.executorDatas);
    }

    return results;
  };

  const createExecutor = async () => {
    if (
      email.length &&
      phoneNumber.length &&
      password.length &&
      name.length &&
      prefecture.length &&
      city.length &&
      addressNum.length &&
      building.length &&
      birthdate.length &&
      passport.length &&
      NewZoneinfo.length
    ) {
      const emailFormat =
        /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
      const executorsAlreadyExist = await getExecutors();
      const emailsAlreadyExist = executorsAlreadyExist.map((ele) => ele.email);
      const emailExistCheck = emailsAlreadyExist.includes(email);
      if (!emailFormat.test(email)) {
        window.alert('正しい形式のメールアドレスを入力して下さい');
      } else if (emailExistCheck) {
        window.alert('指定したメールアドレスの執行者は既に存在します');
      } else if (password.length < 8) {
        window.alert('パスワードは8文字以上で入力して下さい');
      } else if (phoneNumber.length < 10) {
        window.alert('電話番号は10文字以上で入力して下さい');
      } else if (!name.includes(' ')) {
        window.alert('姓名の間に半角スペースを一つ開けてください');
      } else if (name.startsWith(' ')) {
        window.alert('姓名の先頭の文字列に空白を入れないでください');
      } else {
        const confilm = window.confirm('下記の内容で追加しますか？');
        if (confilm === true) {
          try {
            const attrs = [
              { Name: 'name', Value: name },
              { Name: 'email', Value: email },
              { Name: 'phone_number', Value: phoneNumber },
              { Name: 'custom:address_prefecture', Value: prefecture },
              { Name: 'custom:address_city', Value: city },
              { Name: 'custom:address_numbers', Value: addressNum },
              { Name: 'custom:address_building', Value: building },
              { Name: 'birthdate', Value: birthdate },
              { Name: 'custom:passport', Value: passport },
              { Name: 'zoneinfo', Value: NewZoneinfoStr },
            ];
            await createUser(email, attrs);
            await addUserToGroupExecutor(email);
            await checkPassword(email, password);
            logger.info('更新完了');
            // navigate('/admin/executor/list');
          } catch (error) {
            logger.error('更新失敗');
          }
        }
      }
    } else {
      window.alert('未記入の項目があります');
    }
  };

  const { mutate } = useMutation(createExecutor, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['get_executorListFromGetData']);
      await queryClient.invalidateQueries(['executorList_admin']);
      navigate('/admin/executor/list');
      window.location.reload();
    },
  });

  return (
    <>
      <AdminNav />
      <div className="adminExecutorCreate">
        <div className="ExecutorCreate">
          <h1>遺言執行者新規登録</h1>
          <form>
            <div className="ExecutorCreateForm">
              <div>
                <p>メールアドレス</p>
                <label htmlFor="Mail">
                  <input
                    type="email"
                    id="Mail"
                    placeholder="メールアドレス"
                    onChange={handleChangeEmail}
                  />
                </label>
              </div>
              <div>
                <p>電話番号</p>
                <label htmlFor="PhoneNumber">
                  <input
                    type="phoneNumber"
                    id="PhoneNumber"
                    placeholder="電話番号"
                    onChange={handleChangePhoneNumber}
                  />
                </label>
              </div>
              <div>
                <p>パスワード</p>
                <label htmlFor="Password">
                  <input
                    type="password"
                    id="Password"
                    placeholder="パスワード"
                    onChange={handleChangePassword}
                  />
                </label>
              </div>
              <div>
                <p>名前</p>
                <label htmlFor="Name">
                  <input
                    type="text"
                    id="Name"
                    placeholder="名前"
                    onChange={handleChangeName}
                  />
                </label>
                <p className="name-notice">
                  姓と名の間に半角スペースを入れてください
                </p>
              </div>
              {/* <p className='name-notice'>姓と名の間に半角スペースを入れてください</p> */}
              <div>
                <p>住所</p>
                <label htmlFor="Address">
                  <select
                    name="prefecture"
                    id="Address"
                    onChange={changePrefectureAndGetCity}
                  >
                    <option value="" selected disabled hidden>
                      都道府県を選択
                    </option>
                    {PrefectureArray.map((ele: Prefecture) => (
                      <option value={ele.label} key={ele.label}>
                        {ele.label}
                      </option>
                    ))}
                  </select>
                  <select id="city" name="city" onChange={handleChangeCity}>
                    <option>市町村を選択</option>
                    {cityValue.map((ele) => (
                      <option
                        value={ele.cityName}
                        key={ele.cityName}
                        selected={city === ele.cityName}
                      >
                        {ele.cityName}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    id="Address"
                    placeholder="町名以下を入力"
                    value={addressNum}
                    onChange={handleChangeAddressNum}
                  />
                  <input
                    type="text"
                    id="Address"
                    placeholder="建物・マンション名を入力"
                    value={building}
                    onChange={handleChangeBuilding}
                  />
                </label>
              </div>
              <div>
                <p>生年月日</p>
                <label htmlFor="birth">
                  <input
                    type="date"
                    id="calender"
                    onChange={handleChangeBirthdate}
                  />
                </label>
              </div>
              <div className="ExecutorCreateLicense">
                <p>所持資格</p>
                <div>
                  <div>
                    <input
                      type="checkbox"
                      id="check1"
                      name="license"
                      value="遺言執行士"
                      ref={passportRef1}
                      onChange={queryParamPassport}
                    />
                    <label htmlFor="check1">遺言執行士{}</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="check2"
                      name="license"
                      value="弁護士"
                      ref={passportRef2}
                      onChange={queryParamPassport}
                    />
                    <label htmlFor="check2">弁護士{}</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="check3"
                      name="license"
                      value="司法書士"
                      ref={passportRef3}
                      onChange={queryParamPassport}
                    />
                    <label htmlFor="check3">司法書士{}</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="check4"
                      name="license"
                      value="行政書士"
                      ref={passportRef4}
                      onChange={queryParamPassport}
                    />
                    <label htmlFor="check4">行政書士{}</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="check5"
                      name="license"
                      value="税理士"
                      ref={passportRef5}
                      onChange={queryParamPassport}
                    />
                    <label htmlFor="check5">税理士{}</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="check6"
                      name="license"
                      value="宅地建物取引士"
                      ref={passportRef6}
                      onChange={queryParamPassport}
                    />
                    <label htmlFor="check6">宅地建物取引士{}</label>
                  </div>
                </div>
              </div>
              <div className="ExecutorCreatePublish">
                <p>活動地域の選択</p>
                <div>
                  {NewZoneinfo && NewZoneinfo.length !== 0 && NewZoneinfo[0] ? (
                    NewZoneinfo.map((ele) => <p>{ele}</p>)
                  ) : (
                    <p>選択なし</p>
                  )}
                  <button type="button" onClick={() => setShow(true)}>
                    活動地域の選択
                  </button>
                  <AdminActivityAreaForm
                    show={show}
                    setShow={setShowFunc}
                    NewZoneinfo={NewZoneinfo}
                    setNewZoneinfo={setNewZoneinfo}
                  />
                </div>
              </div>
            </div>
            <button type="button" onClick={() => mutate()}>
              完了
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ExecutorCreate;
