/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import ReactStars from 'react-stars';
import {
  MutateEvaluation,
  GetEvaluationFromClientId,
} from 'domains/cognite/services/manageEvaluation';
import minusBtn from '../images/minusBtn.svg';
import './executorEvaluationCard.css';

type Props = {
  show: boolean;
  setShow: () => void;
  clientId: string;
  assigneeId: string;
};

const ExecutorEvaluationCard: FC<Props> = (props) => {
  const { show, setShow, clientId, assigneeId } = props;
  const GetEvaluationOfMine = async () => {
    const theEvaluation = await GetEvaluationFromClientId(clientId);

    return theEvaluation;
  };
  const { data: evalationOfMine } = useQuery(
    ['evaluation_from_client'],
    GetEvaluationOfMine,
  );
  const id = evalationOfMine ? evalationOfMine.id : '';
  const initialStarValue1 =
    evalationOfMine && evalationOfMine.response ? evalationOfMine.response : 0;
  const initialStarValue2 =
    evalationOfMine && evalationOfMine.explanation
      ? evalationOfMine.explanation
      : 0;
  const initialStarValue3 =
    evalationOfMine && evalationOfMine.answer ? evalationOfMine.answer : 0;
  const initialStarValue4 =
    evalationOfMine && evalationOfMine.claritOfyPrice
      ? evalationOfMine.claritOfyPrice
      : 0;
  const initialStarValue5 =
    evalationOfMine && evalationOfMine.validityOfPrice
      ? evalationOfMine.validityOfPrice
      : 0;
  const [starValue1, setStarValue1] = useState(initialStarValue1);
  const [starValue2, setStarValue2] = useState(initialStarValue2);
  const [starValue3, setStarValue3] = useState(initialStarValue3);
  const [starValue4, setStarValue4] = useState(initialStarValue4);
  const [starValue5, setStarValue5] = useState(initialStarValue5);
  const ratingChanged1 = (newRating: number) => {
    setStarValue1(newRating);
  };
  const ratingChanged2 = (newRating: number) => {
    setStarValue2(newRating);
  };
  const ratingChanged3 = (newRating: number) => {
    setStarValue3(newRating);
  };
  const ratingChanged4 = (newRating: number) => {
    setStarValue4(newRating);
  };
  const ratingChanged5 = (newRating: number) => {
    setStarValue5(newRating);
  };
  const mutate = async () => {
    await MutateEvaluation(
      id,
      clientId,
      assigneeId,
      starValue1,
      starValue2,
      starValue3,
      starValue4,
      starValue5,
    );
    // changeId(newId);
    setShow();
  };

  if (show) {
    return (
      <div
        className="executorEvaluationCard"
        id="overlay"
        // onClick={() => setShow()}
      >
        <div>
          <p>担当遺言執行者の評価</p>
          <div>
            <p>対応は早かったですか？</p>
            <ReactStars
              count={5}
              value={starValue1}
              onChange={ratingChanged1}
              size={24}
              color1="#DCDDDD"
              color2="#F8B62D"
              edit
              half={false}
            />
          </div>
          <div>
            <p>説明はわかりやすかったですか？</p>
            <ReactStars
              count={5}
              value={starValue2}
              onChange={ratingChanged2}
              size={20}
              color1="#DCDDDD"
              color2="#F8B62D"
              edit
              half={false}
            />
          </div>
          <div>
            <p>質問に対する回答は的確でしたか？</p>
            <ReactStars
              count={5}
              value={starValue3}
              onChange={ratingChanged3}
              size={20}
              color1="#DCDDDD"
              color2="#F8B62D"
              edit
              half={false}
            />
          </div>
          <div>
            <p>料金設定は明確でしたか？</p>
            <ReactStars
              count={5}
              value={starValue4}
              onChange={ratingChanged4}
              size={20}
              color1="#DCDDDD"
              color2="#F8B62D"
              edit
              half={false}
            />
          </div>
          <div>
            <p>料金設定は妥当でしたか？</p>
            <ReactStars
              count={5}
              value={starValue5}
              onChange={ratingChanged5}
              size={20}
              color1="#DCDDDD"
              color2="#F8B62D"
              edit
              half={false}
            />
          </div>
          <div>
            <button type="button" onClick={mutate}>
              保存
            </button>
          </div>
          <button type="button" onClick={() => setShow()}>
            <img src={minusBtn} alt="minus-Button" />
            <p>閉じる</p>
          </button>
        </div>
      </div>
    );
  }

  return null;
};

export default ExecutorEvaluationCard;
