/* eslint-disable react/require-default-props */
import { FC } from 'react';
import './radioCustomClear.css';

type Props = {
  title: string;
  name: string;
  yesFunc: () => void;
  noFunc: () => void;
  defaultCheckedData: boolean | undefined;
  yesCheck?: () => void;
  noCheck?: () => void;
  yesBlur?: () => void;
};

const RadioCustomClear: FC<Props> = (props) => {
  const {
    title,
    name,
    yesFunc,
    noFunc,
    defaultCheckedData,
    yesCheck,
    noCheck,
    yesBlur,
  } = props;

  // const scroll = () => {
  //   window.scrollBy({
  //     top: 250,
  //     behavior: 'smooth',
  //   });
  // };

  return (
    <div className="radioCustomClear">
      <p className="yesno-title">{title}</p>
      <div className="yesno">
        <input
          type="radio"
          id={`${name}1`}
          name={name}
          value="はい"
          // checked={radio === 'yes' && checked}
          defaultChecked={defaultCheckedData === true}
          onChange={() => yesFunc()}
          onClick={yesCheck}
          onBlur={yesBlur}
        />
        <label htmlFor={`${name}1`} id="yes">
          はい{}
        </label>
        <input
          type="radio"
          id={`${name}2`}
          name={name}
          value="いいえ"
          // checked={radio === 'no' && checked}
          defaultChecked={defaultCheckedData === false}
          onChange={() => noFunc()}
          onClick={noCheck}
        />
        <label htmlFor={`${name}2`} id="no">
          いいえ{}
        </label>
      </div>
    </div>
  );
};

export default RadioCustomClear;
