import { FC, ChangeEvent } from 'react';
import minusBtn from '../images/minusBtn.svg';
import InputItem from '../atoms/InputItem';
import './recipientFormCard.css';

type Props = {
  title: string;
  form: string;
  minusBtnView: boolean;
  recipientNameValue: string;
  updateRecipientContent: (
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement>,
    target: string,
  ) => void;
  subjectId: string;
  deleteCard: (deleteSubject: string) => void;
  subNum: number;
};

const RecipientFormCard: FC<Props> = (props) => {
  const {
    title,
    form,
    minusBtnView,
    recipientNameValue,
    updateRecipientContent,
    subjectId,
    deleteCard,
    subNum,
  } = props;

  const scroll = () => {
    window.scrollBy({
      top: 100,
      behavior: 'smooth',
    });
  };

  const updateNameData = (e: ChangeEvent<HTMLInputElement>) => {
    updateRecipientContent(subjectId, e, 'name');
  };

  const deleteCardFunc = () => {
    deleteCard(subjectId);
  };

  return (
    <div className="recipientForm">
      <div>
        <p>
          {title}
          {subNum}
        </p>
        <button
          type="button"
          onClick={deleteCardFunc}
          className={minusBtnView ? 'stockopen' : 'stockclose'}
        >
          <div>
            <img src={minusBtn} alt="minus-Button" />
            <p>削除</p>
          </div>
        </button>
      </div>
      <div>
        <p className="recipientFormText">
          法定相続人以外の人または団体に対して財産を贈りたいと考えている場合は、その人の氏名又は団体名を記入してください。
        </p>
        <div>
          <label htmlFor={`name_${form}`}>
            <InputItem title="お名前（団体名）" name="require" />
            <input
              type="text"
              id={`name_${form}`}
              key={`name_${form}`}
              name="name"
              placeholder="名前を入力"
              onBlur={scroll}
              defaultValue={recipientNameValue}
              onChange={updateNameData}
            />
          </label>
          <p>架空の名前で構いません</p>
        </div>
      </div>
    </div>
  );
};

export default RecipientFormCard;
