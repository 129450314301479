import { useQuery } from 'react-query';
import { getUserFromAdmin } from 'domains/apigateway/services/get-UserFromAdmin';
import { UserAttributes } from 'domains/cognite/user';

const useUsersListFromAdmin = (keys: string[]): UserAttributes[] => {
  const getUsers = async () => {
    let value = await getUserFromAdmin();
    let results = value.Users;

    while (value.Users.length >= 60 && value.token) {
      // eslint-disable-next-line no-await-in-loop
      const newValue = await getUserFromAdmin(value.token);
      value = newValue;
      results = results.concat(newValue.Users);
    }

    return results;
  };

  const { data: users } = useQuery(keys, getUsers);
  const zeroUsers = [] as UserAttributes[];

  return users || zeroUsers;
};

export default useUsersListFromAdmin;
