import { FC } from 'react';
import plusBtn from '../images/plusBtn.svg';
import './lifeInsurancePlusBtn.css';

type Props = {
  text: string;
};

const LifeInsurancePlusBtn: FC<Props> = (props) => {
  const { text } = props;

  return (
    <div className="lifeInsutancePlusBtn">
      <img src={plusBtn} alt="lifeInsutancePlus-Button" />
      <p>{text}</p>
    </div>
  );
};

export default LifeInsurancePlusBtn;
