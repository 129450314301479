import { FC, Suspense } from 'react';
import { queryRealEstate } from 'domains/cognite/services/manageRealEstate';
import { queryMovingProperty } from 'domains/cognite/services/manageMovingProperty';
import { QueryDeposits } from 'domains/cognite/services/manageDeposits';
import { queryStock } from 'domains/cognite/services/manageStock';
import { queryLifeInsurance } from 'domains/cognite/services/manageLifeInsurance';
import { queryBonds } from 'domains/cognite/services/manageBonds';
import { queryLiabilities } from 'domains/cognite/services/manageLiabilities';
import Spinner from '../../../components/molecules/Spinner';
import { PropertyInfoDatas } from '../data/homeData';
import ItemLink from '../molecules/itemLink';
import inheritance from '../images/inheritance.svg';
import negativeProperty from '../images/negativeProperty.svg';
import './propertyInfo.css';

const PropertyInfo: FC = () => {
  const getRealEstateQuery = async () => {
    const getRealEstateQueryContent = await queryRealEstate();
    const data = getRealEstateQueryContent[0];

    return data;
  };

  const getMovingPropertyQuery = async () => {
    const getMovingPropertyQueryContent = await queryMovingProperty();
    const data = getMovingPropertyQueryContent[0];

    return data;
  };

  const getDepositsQuery = async () => {
    const getDepositsQueryContent = await QueryDeposits();
    const data = getDepositsQueryContent[0];

    return data;
  };

  const getStockQuery = async () => {
    const getStockQueryContent = await queryStock();
    const data = getStockQueryContent[0];

    return data;
  };

  const getLifeInsuranceQuery = async () => {
    const getLifeInsuranceQueryContent = await queryLifeInsurance();
    const data = getLifeInsuranceQueryContent[0];

    return data;
  };

  const getBondsQuery = async () => {
    const getBondsQueryContent = await queryBonds();
    const data = getBondsQueryContent[0];

    return data;
  };

  const getLiabilitiesQuery = async () => {
    const getLiabilitiesQueryContent = await queryLiabilities();
    const data = getLiabilitiesQueryContent[0];

    return data;
  };

  return (
    <div className="propertyInfo">
      <div>
        <h2>あなた名義の財産の情報</h2>
        <div className="inheritance">
          <div>
            <h3>&lt; 相続財産 &gt;</h3>
            <div>
              <img src={inheritance} alt="相続財産" />
              <p>
                お持ちの財産の情報を入力する項目です。以下の６つの項目のうちいずれかの好きな箇所から始められます。
              </p>
            </div>
          </div>
          <div>
            <Suspense fallback={<div>{Spinner}</div>}>
              <ItemLink
                name={PropertyInfoDatas[0][0].name}
                href={PropertyInfoDatas[0][0].href}
                icon={PropertyInfoDatas[0][0].icon}
                key={PropertyInfoDatas[0][0].name}
                keyId="realEstate"
                getQuery={getRealEstateQuery}
              />
            </Suspense>
            <Suspense fallback={<div>{Spinner}</div>}>
              <ItemLink
                name={PropertyInfoDatas[0][1].name}
                href={PropertyInfoDatas[0][1].href}
                icon={PropertyInfoDatas[0][1].icon}
                key={PropertyInfoDatas[0][1].name}
                keyId="movingProperty"
                getQuery={getMovingPropertyQuery}
              />
            </Suspense>
            <Suspense fallback={<div>{Spinner}</div>}>
              <ItemLink
                name={PropertyInfoDatas[0][2].name}
                href={PropertyInfoDatas[0][2].href}
                icon={PropertyInfoDatas[0][2].icon}
                key={PropertyInfoDatas[0][2].name}
                keyId="deposits"
                getQuery={getDepositsQuery}
              />
            </Suspense>
            <Suspense fallback={<div>{Spinner}</div>}>
              <ItemLink
                name={PropertyInfoDatas[0][3].name}
                href={PropertyInfoDatas[0][3].href}
                icon={PropertyInfoDatas[0][3].icon}
                key={PropertyInfoDatas[0][3].name}
                keyId="stock"
                getQuery={getStockQuery}
              />
            </Suspense>
            <Suspense fallback={<div>{Spinner}</div>}>
              <ItemLink
                name={PropertyInfoDatas[0][4].name}
                href={PropertyInfoDatas[0][4].href}
                icon={PropertyInfoDatas[0][4].icon}
                key={PropertyInfoDatas[0][4].name}
                keyId="lifeInsurance"
                getQuery={getLifeInsuranceQuery}
              />
            </Suspense>
            <Suspense fallback={<div>{Spinner}</div>}>
              <ItemLink
                name={PropertyInfoDatas[0][5].name}
                href={PropertyInfoDatas[0][5].href}
                icon={PropertyInfoDatas[0][5].icon}
                key={PropertyInfoDatas[0][5].name}
                keyId="bonds"
                getQuery={getBondsQuery}
              />
            </Suspense>
          </div>
        </div>
        <div className="negativeProperty">
          <div>
            <h3>
              &lt; 負債<span>（借入金・預かり金など）</span>&gt;
            </h3>
            <div>
              <img src={negativeProperty} alt="消極財産" />
              <p>
                金融機関からの借入だけでなく、個人からの借入や、会社経営者の方の帳簿上の債務も含みます。
              </p>
            </div>
          </div>
          <div>
            <Suspense fallback={<div>{Spinner}</div>}>
              <ItemLink
                name={PropertyInfoDatas[1][0].name}
                href={PropertyInfoDatas[1][0].href}
                icon={PropertyInfoDatas[1][0].icon}
                keyId="liabilities"
                getQuery={getLiabilitiesQuery}
              />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyInfo;
