import { Paragraph, TextRun } from 'docx';
import { AllocationReceiver } from 'models';
import nonNullable from 'utils/nonNullable';
import circledNumbers from 'user/components/data/circledNumbers';
import { legalHeirOrBeneficiary } from '../utils/legalHeirOrBeneficiary';

type AllocationReceiverView = (
  allocationReceiver: AllocationReceiver[],
) => Paragraph[];

const allocationReceiverView: AllocationReceiverView = (allocationReceiver) => {
  const allocationReceiverNonNullable = allocationReceiver.filter(nonNullable);

  // 割当している人の中に、法定相続人で、割当財産がある人がいないこととを判定
  const legalHeirNotExistWithPropertiesCheck =
    !allocationReceiverNonNullable.some(
      (ele) =>
        legalHeirOrBeneficiary(ele.type) === '法定相続人' &&
        ele.allocationProperties &&
        ele.allocationProperties.length > 0,
    );

  // 割当てている人のうち、受遺者で、割当財産がある人が一人だけかどうか判定する
  const onlyOnebeneficiaryWithProperties = allocationReceiverNonNullable.filter(
    (ele) =>
      legalHeirOrBeneficiary(ele.type) === '受遺者' &&
      ele.allocationProperties &&
      ele.allocationProperties.length > 0,
  );

  if (
    legalHeirNotExistWithPropertiesCheck &&
    onlyOnebeneficiaryWithProperties.length === 1
  ) {
    return [
      new Paragraph({
        text: `遺言者は、相続開始時に有するすべての財産を換金して得られた金銭から、遺言者が負担すべき債務、公租公課、葬儀・埋葬費用、この遺言の執行費用、遺言執行者の報酬を控除した残余金を、${
          onlyOnebeneficiaryWithProperties[0].name || '○○○○'
        }（所在；○○市○○□丁目□番□号）に遺贈する。`,
        style: 'numberedPara',
      }),
    ];
  }

  const result = allocationReceiver
    .map((ele) => {
      const allocationProperties = ele.allocationProperties
        ? ele.allocationProperties.filter(nonNullable)
        : null;

      if (!allocationProperties) {
        return [];
      }

      const liabilitiesCheck = allocationProperties
        .filter(nonNullable)
        .every((propertiesEle) => propertiesEle.category === '負債');

      if (liabilitiesCheck) {
        return [];
      }

      const allocationPropertiesChildren = allocationProperties
        .filter(
          (propertiesEle) =>
            !!propertiesEle.category && propertiesEle.category !== '負債',
        )
        .sort((firstEle, secondEle) => {
          if (
            firstEle.majorNum &&
            secondEle.majorNum &&
            firstEle.subNum &&
            secondEle.subNum
          ) {
            if (firstEle.majorNum === secondEle.majorNum) {
              return firstEle.subNum - secondEle.subNum;
            }

            return firstEle.majorNum - secondEle.majorNum;
          }

          return 0;
        })
        .map((propertiesEle) => {
          const circledNumber = circledNumbers(propertiesEle.subNum);

          return new TextRun({
            text: `相続財産目録${propertiesEle.majorNum || ''}. ${
              circledNumber || ''
            }記載の${propertiesEle.category || ''}の${
              propertiesEle.ratio || ''
            }%`,
            break: 1,
          });
        });

      const firstParagraphText = () => {
        const judge = legalHeirOrBeneficiary(ele.type);
        if (!judge) {
          return '';
        }

        if (judge === '受遺者') {
          return `遺言者は、${
            ele.name || '○○○○'
          }（○○○○/○○/○○生）に、次の財産を遺贈する。`;
        }

        if (!ele.birth) {
          return `遺言者は、${
            ele.name || '○○○○'
          }（○○○○/○○/○○生）に、次の財産を相続させる。`;
        }

        const dateOfBirth = new Date(ele.birth);

        const japaneseBirthday = new Intl.DateTimeFormat(
          'ja-JP-u-ca-japanese',
          {
            era: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          },
        ).format(dateOfBirth);

        return `遺言者は、${
          ele.name || '○○○○'
        }（${japaneseBirthday}生）に、次の財産を相続させる。`;
      };

      return [
        new Paragraph({
          text: firstParagraphText(),
          style: 'numberedPara',
        }),
        new Paragraph({
          children: allocationPropertiesChildren,
          style: 'Child',
        }),
        new Paragraph({ style: 'Default' }),
      ];
    })
    .flat();

  return result;
};

export default allocationReceiverView;
