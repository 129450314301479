import { Paragraph, TextRun } from 'docx';
import { LifeInsurance, LifeInsuranceContent } from 'models';
import nonNullable from 'utils/nonNullable';
import circledNumbers from 'user/components/data/circledNumbers';

const lifeInsuranceView = (
  lifeInsurance: LifeInsurance | null,
  lifeInsuranceContent: LifeInsuranceContent[],
): Paragraph[] => {
  if (lifeInsurance && !!lifeInsuranceContent.length) {
    const lifeInsuranceContentParagraph = lifeInsuranceContent
      .filter(nonNullable)
      .map((ele) => [
        new Paragraph({
          text: `${circledNumbers(ele.subNum) || 0} ${
            ele.insuranceCompanyName || ''
          }`,
          style: 'subItem',
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: `保険の名称    ${ele.insuranceName || ''}`,
            }),
            new TextRun({
              text: `保険金額    ${ele.insuranceAmount || ''}`,
              break: 1,
            }),
            ...(ele.receiver
              ? ele.receiver.filter(nonNullable).map(
                  (receiverEle, index) =>
                    new TextRun({
                      text: `${
                        index === 0 ? '死亡保険金受取人' : '　　　　　　　　'
                      } ${receiverEle.name || ''} ${receiverEle.ratio || ''}%`,
                      break: 1,
                    }),
                )
              : []),
          ].flat(),
          style: 'GrandChild',
        }),
      ])
      .flat();

    return [
      new Paragraph({
        text: `${lifeInsurance.majorNum || 0}. 生命保険`,
        style: 'Default',
      }),
      ...lifeInsuranceContentParagraph,
      new Paragraph({ style: 'Default' }),
    ];
  }

  return [];
};

export default lifeInsuranceView;
