/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import { FC, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router';
import {
  alreadyReadMesssage,
  GetChatRoomFromClientToAdmin,
  listMessage,
} from 'domains/cognite/services/manageChat';
import { getExecutorFromAdmin } from 'domains/apigateway/services/get-ExecutorFromAdmin';
import { logger } from 'utils/logger';
import AdminNav from '../organisms/adminNav';
import defaultPlofileIcon from '../images/defaultProfileIcon.svg';
import './userChatView.css';

type State = { sub: string };

const UserChatView: FC = () => {
  const location = useLocation();
  const { sub } = location.state as State;

  const listChatRoomsAndClientsInfoAdmin = async () => {
    const chatRooms = await GetChatRoomFromClientToAdmin(sub);
    if (!chatRooms) {
      logger.info(chatRooms);
      window.alert('chatRoomが存在しません。');
      throw Error('chatroomが未定義です');
    }

    return chatRooms;
  };

  const getExecutors = async () => {
    let value = await getExecutorFromAdmin();
    let results = value.executorDatas;

    while (value.executorDatas.length >= 60) {
      // eslint-disable-next-line no-await-in-loop
      const newValue = await getExecutorFromAdmin(value.token);
      value = newValue;
      results = results.concat(newValue.executorDatas);
    }

    return results;
  };

  const { data: ExecutorDatas } = useQuery(['executor_datas'], getExecutors);

  const { data } = useQuery(
    ['user_chat', sub],
    listChatRoomsAndClientsInfoAdmin,
  );

  if (!data) {
    logger.info(data);
    throw Error('chatroomが未定義です');
  }

  if (!data.clientId) {
    logger.info(!data.clientId);
    throw Error('clientが見つかりません');
  }

  const ExecutorDatasFromAdmin = ExecutorDatas?.map((ExecutorData) =>
    ExecutorData.sub === data?.assigneeId ? <p>{ExecutorData.name}</p> : '',
  );

  const { data: messages } = useQuery(
    ['executor_message', !data.clientId],
    () => listMessage(data),
    { cacheTime: 200 },
  );

  if (messages === undefined) {
    logger.info(messages);
    throw Error('messageが未定義です');
  }

  useEffect(() => {
    void alreadyReadMesssage(data.clientId, data.assigneeId);
  });

  const chatOrderChange = messages.slice().sort((a, b) => {
    const createdDateA = Date.parse(a.createdAt!);
    const createdDateB = Date.parse(b.createdAt!);

    return createdDateA > createdDateB
      ? 1
      : createdDateA < createdDateB
      ? -1
      : 0;
  });

  return (
    <>
      <AdminNav />
      <div className="admin-User-chat-list">
        <div className="admin-User-chat-list-wrapper">
          <h1>遺言執行者管理</h1>
          <p>遺言者と遺言執行者のチャット履歴</p>
          <div className="admin-User-chat-container">
            <div>
              <p>{ExecutorDatasFromAdmin}</p>
            </div>
            <div className="admin-User-chat-area">
              {chatOrderChange.map((ele) => {
                const myMesageClassName =
                  ele.ownerId === data.assigneeId
                    ? 'admin-User-other-message'
                    : 'admin-User-my-message';

                return (
                  <div
                    key={`message_${ele.id}`}
                    className={`adminUserMessage ${myMesageClassName}`}
                  >
                    <div>
                      <div className="adminUserChatPlofileIcon">
                        <img src={defaultPlofileIcon} alt="" />
                      </div>
                      <div className="adminUserChatAndName">
                        <p>{ExecutorDatasFromAdmin}</p>
                        <p key={`message_content_${ele.id}`}>{ele.content}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserChatView;
