import { FC, useState, useEffect } from 'react';
import { GetS3, ListS3 } from 'domains/aws/services/get-s3';
import { logger } from 'utils/logger';
import wordIcon from '../images/word-icon.svg';
import './adminWordDownload.css';

type Props = {
  userId: string;
};

const AdminWordDownload: FC<Props> = (props) => {
  const { userId } = props;

  const [fileUrl, setFileUrl] = useState<string>('');
  const [enableClick, setEnableClick] = useState('');

  useEffect(() => {
    const uploadFileAndSetUrl = async () => {
      try {
        const theFileExistCheck = await ListS3(`遺言書_${userId}.docx`);
        if (!theFileExistCheck) {
          setEnableClick('notClick');
        } else {
          setEnableClick('');
          const theFile = await GetS3(`遺言書_${userId}.docx`, 'public');
          setFileUrl(theFile);
        }
      } catch (error) {
        logger.error('アップロード失敗');
      }
    };

    void uploadFileAndSetUrl();
  });

  return (
    <a href={fileUrl} className={enableClick}>
      <img src={wordIcon} alt="" />
      Word出力
    </a>
  );
};

export default AdminWordDownload;
