import { FC } from 'react';
import './executorTextHeader.css';

type Props = {
  title: string;
};

const ExecutorTextHeader: FC<Props> = (props) => {
  const { title } = props;

  return (
    <header className="executorTextHeader">
      <div>
        <p>{title}</p>
      </div>
    </header>
  );
};

export default ExecutorTextHeader;
