import { FC } from 'react';
import './dialog.css';

type Props = {
  dialogText: string;
  dialogYesFunc: () => void;
  dialogNoFunc: () => void;
};

const Dialog: FC<Props> = (props) => {
  const { dialogText, dialogYesFunc, dialogNoFunc } = props;

  return (
    <div className="dialog-wrapper">
      <div className="dialog">
        <p>{dialogText}</p>
        <div>
          <button onClick={dialogNoFunc} type="button">
            いいえ
          </button>
          <button onClick={dialogYesFunc} type="button">
            はい
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dialog;
