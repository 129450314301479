/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC, Suspense } from 'react';
import {
  QueryLegalHeirAllocation,
  QueryRecipientAllocation,
} from 'domains/cognite/services/manageAllocation';
import Spinner from '../../../components/molecules/Spinner';
import { heirInfoDatas } from '../data/homeData';
import ItemLink from '../molecules/itemLink';
import heirInfo from '../images/heirInfo.svg';
import './heirInfo.css';

const HeirInfo: FC = () => {
  const getLegalHeirQuery = async () => {
    const getLegalHeirQueryContent = await QueryLegalHeirAllocation();
    const data = getLegalHeirQueryContent[0];

    return data;
  };

  const getRecipientQuery = async () => {
    const getRecipientQueryContent = await QueryRecipientAllocation();
    const data = getRecipientQueryContent[0];

    return data;
  };

  return (
    <div className="heirInfo">
      <div>
        <h2>あなたの相続人の情報</h2>
        <div>
          <img src={heirInfo} alt="あなたの相続人の情報" />
          <p>相続人の方の情報を入力する項目です。</p>
        </div>
      </div>
      <div>
        <div>
          <Suspense fallback={<div>{Spinner}</div>}>
            <ItemLink
              name={heirInfoDatas[0].name}
              href={heirInfoDatas[0].href}
              icon={heirInfoDatas[0].icon}
              description={heirInfoDatas[0].description}
              keyId="legalHeir"
              getQuery={getLegalHeirQuery}
            />
          </Suspense>
          <Suspense fallback={<div>{Spinner}</div>}>
            <ItemLink
              name={heirInfoDatas[1].name}
              href={heirInfoDatas[1].href}
              icon={heirInfoDatas[1].icon}
              description={heirInfoDatas[1].description}
              keyId="heirInfo"
              getQuery={getRecipientQuery}
            />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default HeirInfo;
