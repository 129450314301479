/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import { DataStore } from 'aws-amplify';
import {
  LifeInsurance,
  LifeInsuranceContent,
  LifeInsuranceContentMetaData,
  Receiver,
} from 'models';
import { logger } from 'utils/logger';
import { ModelInit } from '@aws-amplify/datastore';
import nonNullable from 'utils/nonNullable';

export const MutateLifeInsuranceContentAndGet = async (
  id: string,
  subNum: number,
  insuranceCompanyName: string,
  insuranceName: string,
  insuranceAmount: string,
  lifeInsurance?: LifeInsurance,
): Promise<LifeInsuranceContent> => {
  try {
    logger.info('生命保険の項目の作成または更新を開始します');
    const original = await DataStore.query(LifeInsuranceContent, id);

    if (original) {
      logger.info(original);
      const data = await DataStore.save(
        LifeInsuranceContent.copyOf(original, (updated) => {
          updated.subNum = subNum;
          updated.insuranceCompanyName = insuranceCompanyName;
          updated.insuranceName = insuranceName;
          updated.insuranceAmount = insuranceAmount;
          updated.receiver = original.receiver;
          updated.lifeInsurance = lifeInsurance;
        }),
      );
      logger.info(data);
      logger.info('生命保険の項目の更新が完了しました');

      return data;
    }
    logger.info('生命保険の項目の作成を開始します');
    const instance = {
      subNum,
      insuranceCompanyName,
      insuranceName,
      insuranceAmount,
      receiver: [],
      lifeInsurance,
    };
    const data = await DataStore.save(new LifeInsuranceContent(instance));
    logger.debug(data);
    logger.info('生命保険の項目の作成が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const MutateReceiverOfLifeInsuranceContent = async (
  id: string,
  newReceiver: Receiver,
): Promise<void> => {
  try {
    const original = await DataStore.query(LifeInsuranceContent, id);
    if (original && original.receiver) {
      if (
        original.receiver
          .filter(nonNullable)
          .some((ele) => ele.id === newReceiver.id)
      ) {
        const newReceivers = original.receiver
          .filter(nonNullable)
          .map((ele) => {
            if (ele.id === newReceiver.id) {
              return newReceiver;
            }

            return ele;
          });
        const data = await DataStore.save(
          LifeInsuranceContent.copyOf(original, (updated) => {
            updated.receiver = newReceivers;
          }),
        );
        logger.info(data);
      } else {
        const data = await DataStore.save(
          LifeInsuranceContent.copyOf(original, (updated) => {
            updated.receiver = original.receiver
              ? [...original.receiver, newReceiver]
              : [newReceiver];
          }),
        );
        logger.info(data);
      }
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const CreateLifeInsuranceContentAndGetId = async (
  instance: ModelInit<LifeInsuranceContent, LifeInsuranceContentMetaData>,
): Promise<string> => {
  try {
    logger.info('生命保険の項目の作成を開始します');
    const data = await DataStore.save(new LifeInsuranceContent(instance));
    logger.debug(data);
    logger.info('生命保険の項目の作成が完了しました');

    return data.id;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const UpdateLifeInsuranceContentById = async (
  id: string,
  insuranceCompanyName: string,
  insuranceName: string,
  insuranceAmount: string,
  // receiver: string[],
): Promise<void> => {
  try {
    logger.info('生命保険の項目の更新を開始します');

    const original = await DataStore.query(LifeInsuranceContent, id);

    if (original) {
      logger.info(original);
      const data = await DataStore.save(
        LifeInsuranceContent.copyOf(original, (updated) => {
          updated.insuranceCompanyName = insuranceCompanyName;
          updated.insuranceName = insuranceName;
          updated.insuranceAmount = insuranceAmount;
          // updated.receiver = receiver;
        }),
      );
      logger.info(data);
      logger.info('生命保険の項目の更新が完了しました');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteLifeInsuranceContentById = async (
  id: string,
): Promise<void> => {
  try {
    logger.info('生命保険の項目の削除を開始します');
    const original = await DataStore.query(LifeInsuranceContent, id);
    if (original) {
      logger.info(original);
      await DataStore.delete(original);
      logger.info('生命保険の項目の削除が完了しました');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteReceiverOfLifeInsuranceContent = async (
  id: string,
  deleteReceiverId: string,
): Promise<void> => {
  try {
    const original = await DataStore.query(LifeInsuranceContent, id);
    if (original && original.receiver) {
      const ramainReceivers = original.receiver
        .filter(nonNullable)
        .filter((ele) => ele.id !== deleteReceiverId);
      const data = await DataStore.save(
        LifeInsuranceContent.copyOf(original, (updated) => {
          updated.receiver = ramainReceivers;
        }),
      );
      logger.info(data);
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetLifeInsuranceContent = async (): Promise<
  LifeInsuranceContent[]
> => {
  try {
    logger.info('生命保険の項目のフェッチを開始します');
    const data = await DataStore.query(LifeInsuranceContent);
    logger.debug(data);
    logger.info('生命保険の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetLifeInsuranceContentById = async (
  id: string,
): Promise<LifeInsuranceContent | undefined> => {
  try {
    logger.info('生命保険の項目のフェッチを開始します');
    const data = await DataStore.query(LifeInsuranceContent, id);
    logger.debug(data);
    logger.info('生命保険の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteLifeInsuranceContent = async (): Promise<
  LifeInsuranceContent[]
> => {
  try {
    logger.info('生命保険の全項目の削除を開始します');
    const data = await DataStore.query(LifeInsuranceContent);
    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);

          return data;
        }),
      );
    }
    logger.debug(data);
    logger.info('生命保険の全項目の削除を開始します');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetLifeInsuranceContentByParent = async (
  lifeInsurance: LifeInsurance | null,
): Promise<LifeInsuranceContent[]> => {
  try {
    logger.info('生命保険の項目のフェッチを開始します');
    const data = (await DataStore.query(LifeInsuranceContent)).filter((c) => {
      if (
        c.lifeInsurance === null ||
        c.lifeInsurance === undefined ||
        !lifeInsurance
      ) {
        return false;
      }
      const lifeInsuranceId = c.lifeInsurance.id;

      return lifeInsurance.id === lifeInsuranceId;
    });
    logger.debug(data);
    logger.info('株式・債券の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
