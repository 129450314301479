import { FC, Suspense } from 'react';
import ExecutorLogoHeader from '../../../common/apply/organisms/executorLogoHeader';
import WillchargeListContent from '../organisms/willChargeListContent';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import ExecutorTextHeader from '../organisms/executorTextHeader';

const WillChargeList: FC = () => (
  <>
    <ExecutorLogoHeader />
    <ExecutorTextHeader title="担当遺言者リスト　[ホーム]" />
    <Suspense fallback={<div>...Loading</div>}>
      <WillchargeListContent />
    </Suspense>
    <CommonFooter />
  </>
);

export default WillChargeList;
