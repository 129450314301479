/* eslint-disable react/require-default-props */
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth, DataStore } from 'aws-amplify';
import { useAuth } from 'hooks';
import { logger } from 'utils/logger';
import './willFooterMenuAfterLoginContent.css';

type Props = {
  onClickFunc?: (link: string) => Promise<void> | void;
  normalLink: (link: string) => void;
  setShow: () => void;
};

const WillFooterMenuAfterLoginContent: FC<Props> = (props) => {
  const { onClickFunc, normalLink, setShow } = props;
  const { doQuery } = useAuth();
  const navigate = useNavigate();
  const signOut = async () => {
    try {
      await Auth.signOut()
        .then(async () => {
          logger.info('ログアウト成功');
          await DataStore.clear();
        })
        .then(() => doQuery())
        .then(() => {
          navigate('/');
          window.location.reload();
        });
    } catch (error) {
      logger.error('ログアウト失敗');
      logger.error(error);
    }
  };

  const linkFunc = async (link: string) => {
    await (onClickFunc ? onClickFunc(link) : normalLink(link));
    setShow();
  };

  return (
    <div className="willFooterMenuAfterLoginContent">
      <div>
        <div>
          <div>
            <button
              type="button"
              onClick={async () => {
                await linkFunc('/user');
              }}
            >
              <p>ホーム</p>
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={async () => {
                await linkFunc('/user/profile');
              }}
            >
              <p>プロフィールの変更</p>
            </button>
            <button
              type="button"
              onClick={async () => {
                await linkFunc('/user/email');
              }}
            >
              <p>メールアドレスの変更</p>
            </button>
            <button
              type="button"
              onClick={async () => {
                await linkFunc('/user/password');
              }}
            >
              <p>パスワードの変更</p>
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={async () => {
                await linkFunc('/topics/list');
              }}
            >
              <p>トピックス</p>
            </button>
            <button
              type="button"
              onClick={async () => {
                await linkFunc('/user/question');
              }}
            >
              <p>よくあるご質問</p>
            </button>
            <button
              type="button"
              onClick={async () => {
                await linkFunc('/user/companyinfo');
              }}
            >
              <p>法人概要</p>
            </button>
            <button
              type="button"
              onClick={async () => {
                await linkFunc('/user/contact');
              }}
            >
              <p>お問い合わせ</p>
            </button>
          </div>
          <div>
            <button type="button" onClick={signOut}>
              <p>ログアウト</p>
            </button>
          </div>
          {/* あとでリンク直す */}
        </div>
        <div>
          <p>
            <button
              type="button"
              onClick={async () => {
                await linkFunc('/user/willpolicy');
              }}
            >
              <p>利用規約</p>
            </button>
          </p>
          <p>
            <button
              type="button"
              onClick={async () => {
                await linkFunc('/user/willprivacypolicy');
              }}
            >
              <p>プライバシーポリシー</p>
            </button>
          </p>
          <button
            type="button"
            onClick={async () => {
              await linkFunc('/user/delete');
            }}
          >
            <p>退出する</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default WillFooterMenuAfterLoginContent;
