import { Auth, API } from 'aws-amplify';

export type User = {
  id: string;
  name: string;
  email: string;
  gender: string;
  address: string;
  age: string;
};

export const getUser = async (username: string): Promise<User> => {
  const apiName = 'ExecutorQueries';
  const path = '/getUser';
  const params = {
    queryStringParameters: {
      username,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  };
  const user = (await API.get(apiName, path, params)) as User;

  return user;
};
