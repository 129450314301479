/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC, ChangeEvent, FormEvent, RefObject } from 'react';
import { PrefectureArray, Prefecture } from 'user/components/data/prefecture';
import UserLogoHeader from '../../user/components/organisms/userLogoHeader';
import descriptionBackImg from '../../user/components/images/descriptionBackImg.svg';
import PcdescriptionBackImg from '../../user/components/images/PcdescriptionBackImg.svg';
// import WillFooter from '../../user/components/organisms/willfooter';
import SignUpPolicy from './pages/signUpPolicy';
import CommonFooter from '../apply/templates/commonFooter';
import NavigateBtn from '../../user/components/atoms/navigateBtn';
import '../../reset.css';
import './signUp.css';
import SignUpPrivacyPolicy from './pages/signUpPrivacyPolicy';

type Props = {
  // inputValidClassName: string;
  passwordValidClassName: string;
  emailRef: RefObject<HTMLInputElement>;
  passwordRef: RefObject<HTMLInputElement>;
  passwordComfirmRef: RefObject<HTMLInputElement>;
  gender: string;
  prefectureRef: RefObject<HTMLSelectElement>;
  ageRef: RefObject<HTMLSelectElement>;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  signUp: (e: FormEvent<HTMLInputElement>) => Promise<void>;
  refValueArray: boolean[];
  errorValue: string;
  passwordLengthValid: string;
  emailAlreadyExists: string;
  emailNotFormat: string;
  PolicyBtn: boolean;
  PrivacyPolicyBtn: boolean;
  changePolicyBtn: () => void;
  changePrivacyPolicyBtn: () => void;
  emailDuplicate: boolean;
  emailDuplicateCheck: (e: FormEvent<HTMLInputElement>) => Promise<void>;
};

const SignUp: FC<Props> = (props) => {
  const {
    // inputValidClassName,
    passwordValidClassName,
    emailRef,
    passwordRef,
    passwordComfirmRef,
    gender,
    prefectureRef,
    ageRef,
    handleChange,
    signUp,
    refValueArray,
    errorValue,
    passwordLengthValid,
    emailAlreadyExists,
    emailNotFormat,
    PolicyBtn,
    PrivacyPolicyBtn,
    changePolicyBtn,
    changePrivacyPolicyBtn,
    emailDuplicate,
    emailDuplicateCheck,
  } = props;

  return (
    <>
      <UserLogoHeader />
      <div className="signUpTitle">
        <h1>新規登録</h1>
        <p>遺言アプリへようこそ</p>
        <img src={descriptionBackImg} alt="" className="sp-only" />
        <img src={PcdescriptionBackImg} alt="" className="pc-only" />
      </div>
      <form className="signUpForm">
        <div>
          <div className="signUpWrapper">
            <div>
              <label htmlFor="email">
                メールアドレス
                <input
                  type="email"
                  id="email"
                  ref={emailRef}
                  placeholder="メールアドレスを入力"
                  onBlur={emailDuplicateCheck}
                />
              </label>
              {refValueArray[0] === false ? (
                <p className="alertMessage">
                  メールアドレスが入力されていません
                </p>
              ) : (
                ''
              )}
              {/* {errorValue === emailAlreadyExists ? (
                <p className="alertMessage">
                  そのメールアドレスは既に使われています。
                </p>
              ) : (
                ''
              )} */}
              {emailDuplicate === true || errorValue === emailAlreadyExists ? (
                <p className="alertMessage">
                  そのメールアドレスは既に使われています。
                </p>
              ) : (
                ''
              )}
              {errorValue === emailNotFormat ? (
                <p className="alertMessage">
                  メールアドレスを正しく入力してください。
                </p>
              ) : (
                ''
              )}
            </div>
            <p>遺言執行者として登録済みのメールアドレスは使用できません。</p>
            <div>
              <label htmlFor="password">
                パスワード
                <input
                  type="password"
                  id="password"
                  ref={passwordRef}
                  placeholder="パスワードを入力"
                />
              </label>
              {refValueArray[1] === false ? (
                <p className="alertMessage">パスワードが入力されていません</p>
              ) : (
                ''
              )}
              {errorValue === passwordLengthValid ? (
                <p className="alertMessage">
                  8文字以上のパスワードを入力してください。
                </p>
              ) : (
                ''
              )}
            </div>
            <p>
              半角英数字と記号を2種類以上含めた、8文字以上のパスワードを入力してください。
            </p>
            <div>
              <label htmlFor="passwordComfirm">
                パスワードの再入力
                <input
                  type="password"
                  id="passwordComfirm"
                  ref={passwordComfirmRef}
                  placeholder="パスワードを入力"
                />
              </label>
              {refValueArray[2] === false ? (
                <p className="alertMessage">パスワードを再入力してください</p>
              ) : (
                <p className={`alertMessage ${passwordValidClassName}`}>
                  パスワードが一致していません
                </p>
              )}
            </div>
            <div className="MenAndWomen">
              <p>性別</p>
              <div>
                <input
                  type="radio"
                  id="man"
                  value="男性"
                  name="gender"
                  checked={gender === '男性'}
                  onChange={handleChange}
                />
                <label htmlFor="man">男性</label>
                <input
                  type="radio"
                  id="woman"
                  value="女性"
                  name="gender"
                  checked={gender === '女性'}
                  onChange={handleChange}
                />
                <label htmlFor="woman">女性</label>
              </div>
            </div>
            <div>
              <label htmlFor="prefecture" className="prefectureForm">
                住所
                <select name="prefecture" id="prefecture" ref={prefectureRef}>
                  <option value="" disabled selected hidden>
                    都道府県を選択
                  </option>
                  {PrefectureArray.map((prefectureEle: Prefecture) => (
                    <option value={prefectureEle.label}>
                      {prefectureEle.label}
                    </option>
                  ))}
                </select>
              </label>
              {refValueArray[3] === false ? (
                <p className="alertMessage">都道府県が選択されていません</p>
              ) : (
                ''
              )}
            </div>
            <div>
              <label htmlFor="age" className="ageForm">
                年代
                <select name="age" id="age" ref={ageRef}>
                  <option value="" disabled selected hidden>
                    年代を選択
                  </option>
                  <option value="10代">10代</option>
                  <option value="20代">20代</option>
                  <option value="30代">30代</option>
                  <option value="40代">40代</option>
                  <option value="50代">50代</option>
                  <option value="60代">60代</option>
                  <option value="70代">70代</option>
                  <option value="80代">80代</option>
                  <option value="90代">90代</option>
                </select>
              </label>
              {refValueArray[4] === false ? (
                <p className="alertMessage">年代が選択されていません</p>
              ) : (
                ''
              )}
            </div>
            <p>
              下記の<span>［同意して続行する］</span>を選択すると、
              <input
                type="button"
                value="『遺言書アプリの利用規約』"
                onClick={changePolicyBtn}
                className="signUp-polic-yBtn"
              />
              {PolicyBtn ? (
                <SignUpPolicy changePolicyBtn={changePolicyBtn} />
              ) : (
                ''
              )}
              に同意し、
              <input
                type="button"
                value="『プライバシーポリシー』"
                onClick={changePrivacyPolicyBtn}
                className="signUp-polic-yBtn"
              />
              {PrivacyPolicyBtn ? (
                <SignUpPrivacyPolicy
                  changePrivacyPolicyBtn={changePrivacyPolicyBtn}
                />
              ) : (
                ''
              )}
              を承諾したものとみなされ、次へ進みます。
            </p>
          </div>
          <input type="submit" value="同意して続行する" onClick={signUp} />
          {/* <Link to="/apply">
            遺言執行士として新規登録
          <Link to="/apply">
            遺言執行者として新規登録
            <br className="sp-only" />
            する場合はコチラ
          </Link> */}
          <p className="mailText">
            「同意」すると、入力されたメールアドレスに<span>認証コード</span>
            が送信されます
          </p>
        </div>
        <NavigateBtn />
      </form>
      {/* <WillFooter /> */}
      <CommonFooter />
    </>
  );
};

export default SignUp;
