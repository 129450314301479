import { AlignmentType } from 'docx';

const styles = {
  default: {
    heading1: {
      run: {
        font: 'ＭＳ 明朝',
        size: 40,
        bold: true,
      },
      paragraph: {
        alignment: AlignmentType.CENTER,
        spacing: { line: 340 },
      },
    },
  },
  paragraphStyles: [
    {
      id: 'Default',
      name: 'Default',
      run: {
        font: 'ＭＳ 明朝',
        size: 24,
      },
    },
    {
      id: 'Child',
      name: 'Child',
      basedOn: 'Default',
      next: 'Default',
      quickFormat: true,
      paragraph: {
        indent: {
          left: 720,
        },
        spacing: {
          line: 276,
        },
      },
    },
    {
      id: 'GrandChild',
      name: 'GrandChild',
      basedOn: 'Default',
      next: 'Default',
      quickFormat: true,
      paragraph: {
        indent: {
          left: 1440,
        },
        spacing: {
          line: 276,
        },
      },
    },
    {
      id: 'GrandGrandChild',
      name: 'GrandGrandChild',
      basedOn: 'Default',
      next: 'Default',
      quickFormat: true,
      paragraph: {
        indent: {
          left: 4320,
        },
        spacing: {
          line: 276,
        },
      },
    },
    {
      id: 'numberedPara',
      name: 'Numbered Para',
      basedOn: 'Default',
      next: 'Default',
      quickFormat: true,
      paragraph: {
        spacing: { line: 276, before: 20 * 72 * 0.1, after: 20 * 72 * 0.05 },
        // rightTabStop: 80, ※要不要判断
        // leftTabStop: 30, ※要不要判断
        numbering: {
          reference: 'ref1',
          instance: 0,
          level: 0,
        },
      },
    },
    // 不要かも
    {
      id: 'numberedPara2',
      name: 'Numbered Para2',
      basedOn: 'Default',
      next: 'Default',
      quickFormat: true,
      paragraph: {
        indent: { left: 720 },
        spacing: { line: 276, before: 20 * 72 * 0.1, after: 20 * 72 * 0.05 },
        // rightTabStop: 80, ※要不要判断
        // leftTabStop: 30, ※要不要判断
        numbering: {
          reference: 'ref2',
          instance: 0,
          level: 0,
        },
      },
    },
    {
      id: 'subItem',
      name: 'sub item',
      basedOn: 'Default',
      next: 'Default',
      quickFormat: true,
      paragraph: {
        indent: { left: 720 },
        spacing: { line: 276, before: 20 * 72 * 0.1, after: 20 * 72 * 0.05 },
      },
    },
  ],
};

export default styles;
