/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Topics } from 'models';
import { ListTopicsReleased } from 'domains/cognite/services/manageTopics';
import paginationData from '../molcules/paginationData';
import PaginationBtns from '../molcules/paginationBtns';
import TopicsListLink from '../molcules/topicsListLink';
import './topicsListContent.css';

const TopicsListContent: FC = () => {
  const navigate = useNavigate();
  const [selectedNum, setSelectedNum] = useState(0);
  const { data: topicsFromQuery } = useQuery(
    ['topics', 'list'],
    ListTopicsReleased,
  );

  const topics = topicsFromQuery || ([] as Topics[]);

  const topicsDisplayed = paginationData(topics, selectedNum, 6);

  return (
    <div className="topics-list">
      <h1 className="topics-list_title">トピックス一覧</h1>
      <div className="topics-list_container">
        {topicsDisplayed.map((ele) => (
          <TopicsListLink
            key={ele.id}
            postId={ele.id}
            title={ele.title}
            thumbnail={ele.thumbnail}
            updatedAt={ele.updatedAt}
          />
        ))}
      </div>
      <PaginationBtns
        arrayLength={topics.length}
        setSelectedNum={setSelectedNum}
        selectedNum={selectedNum}
        divisor={6}
        allowIcon
        size="M"
        activeBackground="#555"
        activeColor="white"
      />
      <button
        type="button"
        onClick={() => navigate(-1)}
        className="topics-list_backlink"
      >
        戻る
      </button>
    </div>
  );
};

export default TopicsListContent;
