import {
  Bonds,
  Deposits,
  Liabilities,
  LifeInsurance,
  MovingProperty,
  Properties,
  RealEstate,
  Stock,
  WillInfo,
} from 'models';

type UsePropertiesItem = (propertyData: Properties | undefined) => {
  willInfo: WillInfo | null;
  realEstate: RealEstate | null;
  movingProperty: MovingProperty | null;
  deposits: Deposits | null;
  stock: Stock | null;
  lifeInsurance: LifeInsurance | null;
  bonds: Bonds | null;
  liabilities: Liabilities | null;
};

const usePropertiesItem: UsePropertiesItem = (
  propertyData: Properties | undefined,
) => {
  if (!propertyData) {
    return {
      willInfo: null,
      realEstate: null,
      movingProperty: null,
      deposits: null,
      stock: null,
      lifeInsurance: null,
      bonds: null,
      liabilities: null,
    };
  }

  return {
    willInfo: propertyData.willInfo || null,
    realEstate: propertyData.realEstate || null,
    movingProperty: propertyData.movingProperty || null,
    deposits: propertyData.deposits || null,
    stock: propertyData.stock || null,
    lifeInsurance: propertyData.lifeInsurance || null,
    bonds: propertyData.bonds || null,
    liabilities: propertyData.liabilities || null,
  };
};

export default usePropertiesItem;
