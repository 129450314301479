/* eslint-disable no-param-reassign */
import { DataStore } from 'aws-amplify';
import { StayingTime, StayingTimeMetaData } from 'models';
import { logger } from 'utils/logger';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ModelInit } from '@aws-amplify/datastore';

export const MutateStayingTime = async (
  userId: string,
  instance: ModelInit<StayingTime, StayingTimeMetaData>,
): Promise<void> => {
  try {
    const original = await DataStore.query(StayingTime, (c) =>
      c.userId('eq', userId),
    );
    if (original.length !== 0) {
      await DataStore.save(
        StayingTime.copyOf(original[0], (updated) => {
          updated.time = instance.time;
        }),
      );
    } else {
      await DataStore.save(new StayingTime(instance));
    }
  } catch (error) {
    logger.error(error);
    logger.error('mutateのエラー');
    throw error;
  }
};

export const CreateStayingTime = async (
  instance: ModelInit<StayingTime, StayingTimeMetaData>,
): Promise<void> => {
  try {
    await DataStore.save(new StayingTime(instance));
  } catch (error) {
    logger.error(error);
    logger.error('mutateのエラー');
    throw error;
  }
};

export const UpdateStayingTime = async (
  id: string,
  addTime: number,
): Promise<void> => {
  try {
    const original = await DataStore.query(StayingTime, id);
    if (original) {
      await DataStore.save(
        StayingTime.copyOf(original, (updated) => {
          updated.time = addTime;
        }),
      );
    }
  } catch (error) {
    logger.error(error);
    logger.error('mutateのエラー');
    throw error;
  }
};

export const GetStayingTime = async (): Promise<StayingTime[]> => {
  try {
    logger.info('株式・債券の全項目のフェッチを開始します');
    const data = await DataStore.query(StayingTime);
    logger.debug(data);
    logger.info('株式・債券の全項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetStayingTimeByUserId = async (
  userId: string,
): Promise<StayingTime[] | undefined> => {
  try {
    logger.info('株式・債券の全項目のフェッチを開始します');
    const data = await DataStore.query(StayingTime, (c) =>
      c.userId('eq', userId),
    );
    logger.debug(data);
    logger.info('株式・債券の全項目のフェッチが完了しました');

    if (!data.length) {
      return undefined;
    }

    return data;
  } catch (error) {
    logger.error(error);
    logger.error('getのエラー');
    throw error;
  }
};
