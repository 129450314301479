const topData = [
  {
    point: 1,
    text: '遺言書とはどんなものか、実際に見てみたい方',
  },
  {
    point: 2,
    text: '遺言書の下書きだけ作って保存しておきたい方',
  },
  {
    point: 3,
    text: '下書きを専門家にチェックしてもらい、法的に有効な遺言書を作成し保管しておきたい方',
  },
];

export default topData;
