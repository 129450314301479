/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/require-default-props */
import { FC } from 'react';
// import DescriptionImg from '../images/descriptionImg.svg';
import './pageDescription.css';

type Props = {
  // title: JSX.Element;
  page: string;
  description: JSX.Element;
  NotParamDescription?: string;
};

const PageDescription: FC<Props> = (props) => {
  const { page, description, NotParamDescription } = props;

  return (
    <div className={`pageDescription ${page} ${NotParamDescription}`}>
      <div>{description}</div>
    </div>
  );
};

export default PageDescription;
