import { useQuery } from 'react-query';
import { AssignStatus, Chatroom } from 'models';
import { GetUser } from 'domains/cognite/services/manageUser';
import { ListChatRooms } from 'domains/cognite/services/manageChat';
import { QueryAssignRelationshipFromAssignee } from 'domains/cognite/services/manageAssignRelationship';
import { logger } from 'utils/logger';

type UseExecutorChatroomsAvailable = {
  chatrooms: Chatroom[] | undefined;
};

const useExecutorChatroomsAvailable = (
  key: string,
): UseExecutorChatroomsAvailable => {
  const { data: executor } = useQuery(['authenticated_user'], GetUser);
  if (!executor) {
    logger.error(executor);
    throw Error;
  }

  const { data: chatrooms } = useQuery(`executor_${key}`, ListChatRooms, {
    cacheTime: 200,
  });

  const getRelationship = () =>
    QueryAssignRelationshipFromAssignee(executor.attributes.sub);

  const { data: relationship } = useQuery(
    [`relationship_${key}`],
    getRelationship,
  );

  if (!chatrooms || !relationship) {
    return {
      chatrooms: undefined,
    };
  }

  const relationshipAvailable = relationship.filter(
    (ele) => ele.status !== AssignStatus.DISABLE,
  );

  const chatroomsAvailable = chatrooms.filter((ele) =>
    relationshipAvailable.some(
      (childEle) => childEle.clientId === ele.clientId,
    ),
  );

  return {
    chatrooms: chatroomsAvailable,
  };
};

export default useExecutorChatroomsAvailable;
