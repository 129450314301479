export interface Year {
  id: number;
  year: number;
}

export const YearArray: Year[] = [
  { id: 1, year: 1920 },
  { id: 2, year: 1921 },
  { id: 3, year: 1922 },
  { id: 4, year: 1923 },
  { id: 5, year: 1924 },
  { id: 6, year: 1925 },
  { id: 7, year: 1926 },
  { id: 8, year: 1927 },
  { id: 9, year: 1928 },
  { id: 10, year: 1929 },
  { id: 11, year: 1930 },
  { id: 12, year: 1931 },
  { id: 13, year: 1932 },
  { id: 14, year: 1933 },
  { id: 15, year: 1934 },
  { id: 16, year: 1935 },
  { id: 17, year: 1936 },
  { id: 18, year: 1937 },
  { id: 19, year: 1938 },
  { id: 20, year: 1939 },
  { id: 21, year: 1940 },
  { id: 22, year: 1941 },
  { id: 23, year: 1942 },
  { id: 24, year: 1943 },
  { id: 25, year: 1944 },
  { id: 26, year: 1945 },
  { id: 27, year: 1946 },
  { id: 28, year: 1947 },
  { id: 29, year: 1948 },
  { id: 30, year: 1949 },
  { id: 31, year: 1950 },
  { id: 32, year: 1951 },
  { id: 33, year: 1952 },
  { id: 34, year: 1953 },
  { id: 35, year: 1954 },
  { id: 36, year: 1955 },
  { id: 37, year: 1956 },
  { id: 38, year: 1957 },
  { id: 39, year: 1958 },
  { id: 40, year: 1959 },
  { id: 41, year: 1960 },
  { id: 42, year: 1961 },
  { id: 43, year: 1962 },
  { id: 44, year: 1963 },
  { id: 45, year: 1964 },
  { id: 46, year: 1965 },
  { id: 47, year: 1966 },
  { id: 48, year: 1967 },
  { id: 49, year: 1968 },
  { id: 50, year: 1969 },
  { id: 51, year: 1970 },
  { id: 52, year: 1971 },
  { id: 53, year: 1972 },
  { id: 54, year: 1973 },
  { id: 55, year: 1974 },
  { id: 56, year: 1975 },
  { id: 57, year: 1976 },
  { id: 58, year: 1977 },
  { id: 0, year: 0 },
  { id: 59, year: 1978 },
  { id: 60, year: 1979 },
  { id: 61, year: 1980 },
  { id: 62, year: 1981 },
  { id: 63, year: 1983 },
  { id: 64, year: 1984 },
  { id: 65, year: 1985 },
  { id: 66, year: 1986 },
  { id: 67, year: 1987 },
  { id: 68, year: 1988 },
  { id: 69, year: 1989 },
  { id: 70, year: 1990 },
  { id: 71, year: 1991 },
  { id: 72, year: 1992 },
  { id: 73, year: 1993 },
  { id: 74, year: 1994 },
  { id: 75, year: 1995 },
  { id: 76, year: 1996 },
  { id: 77, year: 1997 },
  { id: 78, year: 1998 },
  { id: 79, year: 1999 },
  { id: 80, year: 2000 },
  { id: 81, year: 2001 },
  { id: 82, year: 2002 },
  { id: 83, year: 2003 },
  { id: 84, year: 2004 },
  { id: 85, year: 2005 },
  { id: 86, year: 2006 },
  { id: 87, year: 2007 },
  { id: 88, year: 2008 },
  { id: 89, year: 2009 },
  { id: 90, year: 2010 },
  { id: 91, year: 2011 },
  { id: 92, year: 2012 },
  { id: 93, year: 2013 },
  { id: 94, year: 2014 },
  { id: 95, year: 2015 },
  { id: 96, year: 2016 },
  { id: 97, year: 2017 },
  { id: 98, year: 2018 },
  { id: 99, year: 2019 },
  { id: 100, year: 2020 },
  { id: 101, year: 2021 },
  { id: 102, year: 2022 },
  { id: 103, year: 2023 },
];
