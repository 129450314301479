/* eslint-disable no-param-reassign */
import { DataStore } from 'aws-amplify';
import { Topics, TopicsMetaData } from 'models';
import { logger } from 'utils/logger';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ModelInit } from '@aws-amplify/datastore';

export const MutateTopic = async (
  id: string,
  instance: ModelInit<Topics, TopicsMetaData>,
): Promise<Topics> => {
  try {
    const original = await DataStore.query(Topics, id);

    if (original) {
      logger.info('topics更新開始');
      const data = await DataStore.save(
        Topics.copyOf(original, (updated) => {
          updated.title = instance.title;
          updated.content = instance.content;
          updated.isDraft = instance.isDraft;
          updated.thumbnail = instance.thumbnail;
        }),
      );

      return data;
    }

    logger.info('topics作成開始');
    const data = await DataStore.save(new Topics(instance));

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const ListTopicsAll = async (): Promise<Topics[]> => {
  try {
    const data = await DataStore.query(Topics);
    const dataSorted = data.sort((first, second) => {
      const firstUpdatedAt = first.updatedAt
        ? new Date(first.updatedAt)
        : new Date();
      const secondUpdatedAt = second.updatedAt
        ? new Date(second.updatedAt)
        : new Date();
      if (secondUpdatedAt > firstUpdatedAt) {
        return 1;
      }
      if (firstUpdatedAt > secondUpdatedAt) {
        return -1;
      }

      return 0;
    });

    return dataSorted;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const ListTopicsReleased = async (): Promise<Topics[]> => {
  try {
    const data = await DataStore.query(Topics);
    const dataSorted = data.sort((first, second) => {
      const firstUpdatedAt = first.updatedAt
        ? new Date(first.updatedAt)
        : new Date();
      const secondUpdatedAt = second.updatedAt
        ? new Date(second.updatedAt)
        : new Date();
      if (secondUpdatedAt > firstUpdatedAt) {
        return 1;
      }
      if (firstUpdatedAt > secondUpdatedAt) {
        return -1;
      }

      return 0;
    });
    const releasedData = dataSorted.filter((ele) => !ele.isDraft);

    return releasedData;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetOneTopic = async (id: string): Promise<Topics | undefined> => {
  try {
    const data = await DataStore.query(Topics, id);

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteOneTopic = async (id: string): Promise<void> => {
  try {
    const data = await DataStore.query(Topics, id);

    if (data) {
      await DataStore.delete(data);
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
