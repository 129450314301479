import { useState, useEffect } from 'react';
import { GetS3, ListS3 } from 'domains/aws/services/get-s3';

type UseProfileIcon = (
  defaultProfileIcon: string,
  sub: string,
) => {
  imageUrl: string;
  changeImageUrl: (newUrl: string) => void;
};

const useProfileIcon: UseProfileIcon = (
  defaultProfileIcon: string,
  sub: string,
) => {
  const [imageUrl, setImageUrl] = useState('');
  const changeImageUrl = (newUrl: string) => setImageUrl(newUrl);

  useEffect(() => {
    const fetchImageUrl = async () => {
      try {
        const list = await ListS3(`profile_${sub}`);

        const profile =
          list !== 0
            ? await GetS3(`profile_${sub}`, 'public')
            : defaultProfileIcon;
        setImageUrl(profile);
      } catch (error) {
        setImageUrl(defaultProfileIcon);
      }
    };
    void fetchImageUrl();
  }, [defaultProfileIcon, imageUrl, sub]);

  return { imageUrl, changeImageUrl };
};

export default useProfileIcon;
