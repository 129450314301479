/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  FC,
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import minusBtn from '../images/minusBtn.svg';
import {
  Prefecture,
  PrefectureArray,
} from '../../../executor/components/data/prefecture';
import './adminListingAreaFormCard.css';

type Props = {
  theIndex: number;
  cardDelete: () => void;
  minusBtnView: boolean;
  NewZoneinfo: string[];
  setNewZoneinfo: Dispatch<SetStateAction<string[]>>;
  prefectureSelectedArr: string[];
  citySelectedArr: string[];
  prefectureSelected: string;
  citySelected: string;
};

type cityProps = {
  prefCode: string;
  cityCode: string;
  cityName: string;
  bigCityFlag: string;
};

const AdminListingAreaFormCard: FC<Props> = (props) => {
  const {
    theIndex,
    cardDelete,
    minusBtnView,
    NewZoneinfo,
    setNewZoneinfo,
    prefectureSelectedArr,
    citySelectedArr,
    prefectureSelected,
    citySelected,
  } = props;

  const prefectureRef = useRef<HTMLSelectElement>(null);
  const cityRef = useRef<HTMLSelectElement>(null);
  const [cityGroup, setCityGroup] = useState<cityProps[]>([]);

  const changeZoneInfo = () => {
    const newContent = `${
      prefectureRef.current ? prefectureRef.current.value : ''
    }${cityRef.current ? cityRef.current.value : ''}`;
    if (theIndex > NewZoneinfo.length - 1) {
      setNewZoneinfo([...NewZoneinfo, newContent]);
    } else {
      const changedZoneinfo = NewZoneinfo.map((ele, index) => {
        if (theIndex === index) {
          return newContent;
        }

        return ele;
      });
      setNewZoneinfo(changedZoneinfo);
    }
  };

  const getCity = async () => {
    const selectLabel = prefectureRef.current
      ? prefectureRef.current.value
      : '';

    const selectArr = PrefectureArray.find((ele) => ele.label === selectLabel);
    const selectId = selectArr ? selectArr.id : 1;

    await fetch(
      `https://opendata.resas-portal.go.jp/api/v1/cities?prefCode=${selectId}`,
      {
        headers: { 'X-API-KEY': 'yvet69MOZSiyzRoinuvNJaWjcXaqhoP118HTqciA' },
      },
    )
      .then((response) => response.json())
      .then((citiesData) => {
        setCityGroup(citiesData.result);
      });
  };

  useEffect(() => {
    const selectLabel = prefectureRef.current
      ? prefectureRef.current.value
      : prefectureSelected;

    const selectArr = PrefectureArray.find((ele) => ele.label === selectLabel);
    const selectId = selectArr ? selectArr.id : 1000;

    void fetch(
      `https://opendata.resas-portal.go.jp/api/v1/cities?prefCode=${selectId}`,
      {
        headers: { 'X-API-KEY': 'yvet69MOZSiyzRoinuvNJaWjcXaqhoP118HTqciA' },
      },
    )
      .then((response) => response.json())
      .then((citiesData) => {
        setCityGroup(citiesData.result);
      });
  }, [prefectureSelected]);

  // 対象カードの都道府県欄で全国が選択できるか判定する関数
  const checkDisableWholeCountry = () => {
    const wholeCountryIndexCheck =
      prefectureSelectedArr.indexOf('全国') === theIndex;
    if (wholeCountryIndexCheck) {
      return false;
    }
    const onlyWholeCountryCheck = prefectureSelectedArr.every(
      (ele) => ele === '全国',
    );

    if (onlyWholeCountryCheck) {
      return false;
    }

    return true;
  };

  // 対象カードの市区町村欄で全市町村が選択できるか判定する関数
  const checkDisableWholeCity = () => {
    const wholeCityIndexCheck = citySelectedArr.indexOf('全域') === theIndex;
    if (wholeCityIndexCheck) {
      return false;
    }

    const currentPrefecture = prefectureRef.current
      ? prefectureRef.current.value
      : '';

    const otherPrefectureNotMatchCheck = prefectureSelectedArr.every(
      (ele, index) => {
        if (index === theIndex) {
          return true;
        }

        return ele !== currentPrefecture;
      },
    );

    if (otherPrefectureNotMatchCheck) {
      return false;
    }

    const otherCityCheck = citySelectedArr.every((ele) => ele === '全域');

    if (otherCityCheck) {
      return false;
    }

    return true;
  };

  return (
    <div>
      <div className="adminActivityAreaFormCard">
        <div>
          <p>活動地域{theIndex + 1}</p>
          <button
            type="button"
            onClick={cardDelete}
            className={`adminActivityAreaFormCardBtn ${
              minusBtnView ? 'lifeInsuranceopen' : 'lifeInsuranceclose'
            }`}
          >
            <div>
              <img src={minusBtn} alt="minus-Button" />
              <p>削除</p>
            </div>
          </button>
        </div>
        <div>
          <label htmlFor="prefectures">
            <p>都道府県</p>
            <select
              id="prefecture"
              name="prefecture"
              ref={prefectureRef}
              onChange={async () => {
                // await mutate();
                await getCity();
              }}
            >
              <option value="" disabled selected hidden>
                都道府県を選択
              </option>
              <option
                value="全国"
                disabled={checkDisableWholeCountry()}
                selected={prefectureSelected === '全国'}
              >
                全国
              </option>
              {PrefectureArray.map((prefectureEle: Prefecture) =>
                !NewZoneinfo.includes('全国') ? (
                  <option
                    value={prefectureEle.label}
                    key={prefectureEle.label}
                    selected={prefectureSelected === prefectureEle.label}
                    disabled={NewZoneinfo.includes(prefectureEle.label)}
                  >
                    {prefectureEle.label}
                  </option>
                ) : (
                  ''
                ),
              )}
            </select>
          </label>
          <label htmlFor="cities">
            <p>市区町村</p>
            <select
              name="cities"
              id="cities"
              onChange={changeZoneInfo}
              ref={cityRef}
            >
              <option value="" selected hidden>
                市区町村を選択
              </option>
              <option
                value=""
                disabled={checkDisableWholeCity()}
                selected={citySelected === ''}
              >
                全域
              </option>
              {prefectureRef.current && prefectureRef.current.value !== '全国'
                ? cityGroup.map((ele) => (
                    <option
                      value={ele.cityName}
                      selected={citySelected === ele.cityName}
                      disabled={citySelectedArr.includes(ele.cityName)}
                    >
                      {ele.cityName}
                    </option>
                  ))
                : ''}
            </select>
          </label>
        </div>
      </div>
    </div>
  );
};

export default AdminListingAreaFormCard;
