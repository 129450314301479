import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getUser } from 'domains/apigateway/services/get-user';
import './willListCard.css';

type Props = {
  clientId: string;
};

const WillListCard: FC<Props> = (props) => {
  const { clientId } = props;

  const getTheClient = async () => {
    try {
      const theClient = await getUser(clientId);

      return theClient;
    } catch (error) {
      return undefined;
    }
  };

  const { data: client, error: clientError } = useQuery(
    ['willList_client', clientId],
    getTheClient,
  );

  if (!client || clientError) {
    return <></>;
  }

  const { id, name, email, gender, age, address } = client;

  return (
    <Link key={id} to="/executor/willcharge" state={{ client }}>
      <div className="willListCard">
        <p>{name}</p>
        <p>{email}</p>
        <p>
          {gender}/{age}
        </p>
        <p>住所:{address}</p>
      </div>
    </Link>
  );
};

export default WillListCard;
