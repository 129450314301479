import { FC, Suspense } from 'react';
// import Spinner from 'components/molecules/Spinner';
import ExecutoTextHeader from '../organisms/executorTextHeader';
// import Footer from '../organisms/footer';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import ApplicantListContent from '../organisms/applicantListContent';
import ExecutorLogoHeader from '../../../common/apply/organisms/executorLogoHeader';

const ApplicantList: FC = () => (
  <>
    <ExecutorLogoHeader />
    <ExecutoTextHeader title="申請者一覧" />
    <Suspense fallback={<div>...Loading</div>}>
      <ApplicantListContent />
    </Suspense>
    {/* <Footer /> */}
    <CommonFooter />
  </>
);

export default ApplicantList;
