import { FC } from 'react';
import ReactStars from 'react-stars';
import { useProfileIcon } from 'hooks';
import { Executor } from 'domains/apigateway/services/get-Executor';
import calcBirthDate from 'utils/calcBirthDate';
import defaultProfileIcon from '../images/defaultProfileIcon.svg';
import './executorFormCard.css';

type Props = {
  executor: Executor;
  evaluationValueAveStar: number;
  evaluationValueAveDisplay: number;
  evaluationValueCount: number;
};

const ExecutorFormCard: FC<Props> = (props) => {
  const {
    executor,
    evaluationValueAveStar,
    evaluationValueAveDisplay,
    evaluationValueCount,
  } = props;

  const { id, name, birthdate, prefecture, city, passport, zone, phoneNumber } =
    executor;

  const aboutAge = calcBirthDate(birthdate);
  const addressFromUser = prefecture + city;

  const { imageUrl } = useProfileIcon(defaultProfileIcon, id);

  return (
    <div className="executorFormCard">
      <div>
        <img
          src={imageUrl}
          alt="検索した執行者のアイコン画像"
          className="executor-icon"
        />
        <table>
          <tr>
            <td>氏名</td>
            <td>{name}</td>
          </tr>
          <tr>
            <td>年齢</td>
            <td>{aboutAge}</td>
          </tr>
          <tr>
            <td>住所</td>
            <td>{addressFromUser}</td>
          </tr>
          <tr>
            <td>所持資格</td>
            <td>{passport.toString()}</td>
          </tr>
          <tr>
            <td>活動地域</td>
            <td>{zone.toString()}</td>
          </tr>
          <tr>
            <td>電話番号</td>
            <td>{phoneNumber.replace('+81', '0')}</td>
          </tr>
          <tr className="FormCardReactStarsWrap">
            <td>評価</td>
            <td className="executorFormCardReactStars">
              <ReactStars
                count={5}
                value={evaluationValueAveStar}
                size={20}
                color1="#DCDDDD"
                color2="#F8B62D"
                half={false}
                edit={false}
              />
              {/* <StarRating /> */}
              <p>
                {evaluationValueAveDisplay}({evaluationValueCount})
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default ExecutorFormCard;
