import { DataStore } from 'aws-amplify';
import { ExecutorViewHistory, ExecutorViewHistoryMetaData } from 'models';
import { logger } from 'utils/logger';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ModelInit } from '@aws-amplify/datastore';

export const CreateExecutorViewHistory = async (
  instance: ModelInit<ExecutorViewHistory, ExecutorViewHistoryMetaData>,
): Promise<void> => {
  try {
    logger.info('aaaa');
    await DataStore.save(new ExecutorViewHistory(instance));
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetExecutorViewHistory = async (): Promise<
  ExecutorViewHistory[]
> => {
  try {
    const executorViewHistory = await DataStore.query(ExecutorViewHistory);

    return executorViewHistory;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetExecutorViewHistoryFromExecutorId = async (
  executorId: string,
): Promise<ExecutorViewHistory[]> => {
  try {
    const executorViewHistory = await DataStore.query(
      ExecutorViewHistory,
      (c) => c.executorId('eq', executorId),
    );

    return executorViewHistory;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
