import { FC } from 'react';
import UserLogoHeader from '../organisms/userLogoHeader';
import WillPolicyContent from '../templates/willPolicyContent';
import CommonFooter from '../../apply/templates/commonFooter';
import NavigateBtn from '../../../user/components/atoms/navigateBtn';
import './willPolicy.css';

const WillPolicy: FC = () => (
  <>
    <UserLogoHeader />
    <div className="will-policy">
      <WillPolicyContent />
      <NavigateBtn />
    </div>
    <CommonFooter />
  </>
);

export default WillPolicy;
