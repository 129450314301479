/* eslint-disable no-param-reassign */
import { DataStore } from 'aws-amplify';
import { Liabilities, LiabilitiesMetaData } from 'models';
import { logger } from 'utils/logger';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ModelInit } from '@aws-amplify/datastore';

const CreateLiabilities = async (
  instance: ModelInit<Liabilities, LiabilitiesMetaData>,
): Promise<Liabilities> => {
  try {
    logger.info('負債の作成を開始します');
    const data = await DataStore.save(new Liabilities(instance));
    logger.info('負債の作成が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export default CreateLiabilities;

export const queryLiabilities = async (): Promise<Liabilities[]> => {
  try {
    const queryLiabilitiesData = await DataStore.query(Liabilities);

    const queryLiabilitiesResult = !queryLiabilitiesData
      ? []
      : queryLiabilitiesData;

    return queryLiabilitiesResult;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const MutateLiabilities = async (
  id: string,
  instance: ModelInit<Liabilities, LiabilitiesMetaData>,
): Promise<Liabilities> => {
  try {
    logger.info('負債の作成または更新を開始します');
    const original = await DataStore.query(Liabilities, id);

    if (original) {
      logger.info('負債の更新を開始します');
      const data = await DataStore.save(
        Liabilities.copyOf(original, (updated) => {
          // ワンチャンここがバグ
          updated.content = instance.content;
          updated.majorNum = instance.majorNum;
          updated.noCheck = instance.noCheck;
        }),
      );

      return data;
    }
    logger.info('負債の作成を開始します');
    const data = await DataStore.save(new Liabilities(instance));

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const MutateLiabilitiesOnluMajorNum = async (
  id: string,
  instance: ModelInit<Liabilities, LiabilitiesMetaData>,
): Promise<Liabilities> => {
  try {
    logger.info('負債の作成または更新を開始します');
    const original = await DataStore.query(Liabilities, id);

    if (original) {
      logger.info('負債の更新を開始します');
      const data = await DataStore.save(
        Liabilities.copyOf(original, (updated) => {
          updated.majorNum = instance.majorNum;
        }),
      );

      return data;
    }
    logger.info('負債の作成を開始します');
    const data = await DataStore.save(new Liabilities(instance));

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetLiabilities = async (): Promise<Liabilities[]> => {
  try {
    logger.info('負債の全項目のフェッチを開始します');
    const data = await DataStore.query(Liabilities);
    logger.debug(data);
    logger.info('負債の全項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteLiabilities = async (): Promise<Liabilities[]> => {
  try {
    logger.info('負債の削除を開始します');
    const data = await DataStore.query(Liabilities);
    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);

          return data;
        }),
      );
    }
    logger.debug(data);
    logger.info('負債の削除を開始します');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
