import { FC } from 'react';
import './pointCard.css';

type Props = {
  point: number;
  text: string;
};

const PointCard: FC<Props> = (props) => {
  const { point, text } = props;

  return (
    <div className="pointCard">
      <p>ポイント{point}</p>
      <p>{text}</p>
    </div>
  );
};

export default PointCard;
