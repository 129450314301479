/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC, useState, useEffect, Suspense } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AssignStatus } from 'models';
import { useRelationshipWithAssignee, useWatchAssignStatus } from 'hooks';
import { GetEvaluation } from 'domains/cognite/services/manageEvaluation';
import { GetChatRoomFromClient } from 'domains/cognite/services/manageChat';
import NormalBtn from '../atoms/normalBtn';
import ExecutorFormCardApproved from '../molecules/executorFormCardApproved';
import ExecutorEvaluationCard from '../molecules/executorEvaluationCard';
import './executorApprovalContent.css';

const ExecutorApprovalContent: FC = () => {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const path = useWatchAssignStatus('executor_approval_content');

  const { data } = useRelationshipWithAssignee(
    'assignee_APPROVAL',
    AssignStatus.APPROVAL,
  );

  const { data: evaluation } = useQuery(
    ['executor_approval_content_evaluation'],
    GetEvaluation,
    {
      cacheTime: 200,
    },
  );

  const { data: chatroomFromQuery } = useQuery(
    ['user_chatroom_footer'],
    GetChatRoomFromClient,
    { cacheTime: 2000 },
  );

  const enableClick = !!chatroomFromQuery;

  useEffect(() => {
    if (location.pathname !== path) {
      window.alert('データの更新があります');
      navigate(path);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const evaluationByAssigneeId = evaluation
    ? evaluation.filter(
        (ele) => data && ele.assigneeId === data.relationship.assigneeId,
      )
    : [];

  const evaluationValue = evaluationByAssigneeId.map((ele1) => {
    const evaluationValueSum =
      (ele1.response !== undefined && ele1.response !== null
        ? ele1.response
        : 0) +
      (ele1.explanation !== undefined && ele1.explanation !== null
        ? ele1.explanation
        : 0) +
      (ele1.answer !== undefined && ele1.answer !== null ? ele1.answer : 0) +
      (ele1.claritOfyPrice !== undefined && ele1.claritOfyPrice !== null
        ? ele1.claritOfyPrice
        : 0) +
      (ele1.validityOfPrice !== undefined && ele1.validityOfPrice !== null
        ? ele1.validityOfPrice
        : 0);

    return evaluationValueSum;
  });

  const evaluationValueTotal = evaluationValue.reduce(
    (sum, element) => sum + element,
    0,
  );

  const evaluationValueTotalAve =
    evaluationValueTotal / evaluationByAssigneeId.length / 5;

  // 評価の星に渡す数字（整数）
  const evaluationValueAveStar = Math.round(evaluationValueTotalAve);

  // 表示用の数字（小数第一位）
  const evaluationValueAveDisplay =
    Math.round(evaluationValueTotalAve * 10) / 10;

  if (!data) {
    return (
      <div className="executorApprovalContent">
        <div />
      </div>
    );
  }

  const { clientId, assigneeId } = data.relationship;

  return (
    <div className="executorApprovalContent">
      <div>
        <Suspense fallback={<div>...読み込み中</div>}>
          <ExecutorFormCardApproved
            executorId={data.relationship.assigneeId}
            currentStatus={AssignStatus.APPROVAL}
            evaluationValueAveStar={evaluationValueAveStar || 0}
            evaluationValueAveDisplay={evaluationValueAveDisplay || 0}
            evaluationValueCount={evaluationByAssigneeId.length || 0}
          />
        </Suspense>
        <div>
          {enableClick && (
            <NormalBtn
              title="チャットで相談"
              className="normalBtn ExecutorFormCardApprovedBtn"
              href="/user/chat"
            />
          )}
          <button
            className="assess-btn ExecutorFormCardApprovedBtn"
            type="button"
            onClick={() => setShow(true)}
          >
            評価する
          </button>
          <ExecutorEvaluationCard
            // href="/home"
            show={show}
            setShow={() => setShow(false)}
            clientId={clientId}
            assigneeId={assigneeId || ''}
          />
          <div className="executorApprovalContentChangeBtn">
            <NormalBtn
              title="担当遺言執行者を変更する"
              className="normalBtn"
              href="/user/executorchange"
              state={{
                assignStatus: AssignStatus.APPROVAL,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExecutorApprovalContent;
