import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { DataStore } from 'aws-amplify';
import { AssignStatus } from 'models';
import { GetUser } from 'domains/cognite/services/manageUser';
import { QueryAssignRelationshipFromClient } from 'domains/cognite/services/manageAssignRelationship';
import { QueryAssignExecutorNotOnTheList } from 'domains/cognite/services/manageAssignExecutorNotOnTheList';
// import { logger } from 'utils/logger';

const useWatchAssignStatus = (key: string): string => {
  // const navigate = useNavigate();
  const location = useLocation();
  const { data: user } = useQuery(['authenticated_user'], GetUser);

  if (!user) {
    throw Error('認証エラー');
  }

  const getRelation = async () => {
    // await DataStore.stop();
    await DataStore.start();
    const relationData = await QueryAssignRelationshipFromClient(
      user.attributes.sub,
    );

    return relationData;
  };

  const { data: relationFromQuery } = useQuery(
    ['assign_status_from_client', key],
    getRelation,
    {
      cacheTime: 500,
      refetchInterval: 5050,
      refetchIntervalInBackground: true,
      refetchOnMount: 'always',
      refetchOnReconnect: 'always',
      refetchOnWindowFocus: 'always',
    },
  );

  // const initialRelation: AssignRelationship[] = relationFromQuery || []
  // const [relation, setRelation] = useState(initialRelation)

  const { data: AssigneeNotOntheList } = useQuery(
    'AssigneeNotOntheList',
    QueryAssignExecutorNotOnTheList,
    { cacheTime: 500 },
  );

  // useEffect(() => {
  //   const subscription = DataStore.observe(AssignRelationship).subscribe(
  //     (msg) => {
  //       logger.info(msg.model, msg.opType, msg.element);
  //       logger.info(msg.element.status);
  //       // const watchRelation = async () => {
  //       //   const relationInSubscription = await getRelation();
  //       //   setRelation(relationInSubscription);
  //       // };
  //       // void watchRelation();
  //       // console.log(relation);
  //     },
  //   );

  //   return () => subscription.unsubscribe();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const [path, setPath] = useState(location.pathname);

  const path1 = '/user/executorallocation';
  const path2 = '/user/executorawaitingapproval';
  const path3 = '/user/executorapproval';
  const path4 = '/user/executorcharge';

  useEffect(() => {
    if (relationFromQuery && !!relationFromQuery.length) {
      const approvalCheck = relationFromQuery.some(
        (ele) => ele.status === AssignStatus.APPROVAL,
      );
      const registerCheck = relationFromQuery.some(
        (ele) => ele.status === AssignStatus.REGISTERD,
      );
      if (approvalCheck) {
        setPath(path3);
        // navigate(path3);
      } else if (registerCheck) {
        setPath(path2);
        // navigate(path2);
      } else if (AssigneeNotOntheList && !!AssigneeNotOntheList.length) {
        setPath(path4);
        // if (location.pathname !== path4) {
        //   navigate(path4);
        // }
      } else {
        setPath(path1);
        // navigate(path1);
      }
    } else if (AssigneeNotOntheList && !!AssigneeNotOntheList.length) {
      setPath(path4);
      // if (location.pathname !== path4) {
      //   navigate(path4);
      // }
    } else {
      // navigate(path1);
      setPath(path1);
    }

    // console.log('現在の状態 +++++++++');
    // console.log(location.pathname);
    // console.log(relationFromQuery);
    // console.log(path);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AssigneeNotOntheList, relationFromQuery]);

  return path;
};

export default useWatchAssignStatus;
