/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProfileIcon } from 'hooks';
import { UserAttributes } from 'domains/cognite/user';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import NavigateBtn from 'common/components/atoms/navigateBtn';
import NormalBtn from '../atoms/normalBtn';
import defaultProfileIcon from '../images/defaultProfileIcon.svg';
import './executorProfileContent.css';

const defaultAttribute: UserAttributes = {
  sub: '',
  name: '',
  address: '',
  'custom:address_prefecture': '',
  'custom:address_city': '',
  'custom:address_numbers': '',
  'custom:address_building': '',
  email: '',
  email_verified: true,
  gender: '',
  phone_number: '',
  phone_number_verified: true,
  'custom:ages': '',
  zoneinfo: '',
  'custom:passport': '',
  'custom:reward': '',
  'custom:notion': '',
  'custom:introduce': '',
};

type Props = {
  attributes?: UserAttributes;
};

const ExecutorProfileContent: FC<Props> = (props) => {
  const { attributes = defaultAttribute } = props;
  const navigate = useNavigate();
  const navigateToContact = () => {
    navigate('/executor/contact');
  };

  const prefecture = attributes['custom:address_prefecture']
    ? attributes['custom:address_prefecture']
    : '';

  const city = attributes['custom:address_city']
    ? attributes['custom:address_city']
    : '';

  const numbers = attributes['custom:address_numbers']
    ? attributes['custom:address_numbers']
    : '';

  const building = attributes['custom:address_building']
    ? attributes['custom:address_building']
    : '';

  const fullAddress = `${prefecture}${city}${numbers}${building}`;

  const { imageUrl } = useProfileIcon(defaultProfileIcon, attributes.sub);

  const phoneNumber = attributes.phone_number
    ? formatPhoneNumber(attributes.phone_number)
    : '';

  return (
    <div className="executorProfile">
      <div>
        <div className="plofile-icon-name">
          <img src={imageUrl} alt="" />
          <div>
            <p>氏名</p>
            <p>{attributes.name}</p>
          </div>
        </div>
        <div className="executorProfileTable">
          <div>
            <div>
              <p>生年月日</p>
              <p>{attributes.birthdate}</p>
            </div>
            <div>
              <p>住所または事務所</p>
              <p>{fullAddress}</p>
            </div>
          </div>
          <div>
            <div>
              <p>電話番号</p>
              <p>{phoneNumber}</p>
            </div>
            <div>
              <p>所持資格</p>
              <p>{attributes['custom:passport']}</p>
            </div>
          </div>
          <div>
            <div>
              <p>活動地域</p>
              <p>{attributes.zoneinfo}</p>
            </div>
            <div>
              <p>遺言執行報酬</p>
              <p>{attributes['custom:reward']}</p>
            </div>
          </div>
          <div>
            <div>
              <p>登録名簿閲覧数の通知</p>
              <p>{attributes['custom:notion']}</p>
            </div>
            <div>
              <p>紹介文</p>
              <p>{attributes['custom:introduce']}</p>
            </div>
          </div>
        </div>
        <NormalBtn
          title="編集"
          className="normalBtn"
          href="/executor/profile/edit"
          state={{
            name: attributes.name,
            birthdate: attributes.birthdate,
            // address: attributes.address,
            defaultPrefecture: prefecture,
            defaultCity: city,
            defaultNumbers: numbers,
            defaultBuilding: building,
            phoneNumber: attributes.phone_number,
            passport: attributes['custom:passport'],
            zoneInfo: attributes.zoneinfo,
            sub: attributes.sub,
            defaultReward: attributes['custom:reward'] || '',
            defaultNotion: attributes['custom:notion'] || '',
            defaultIntroduce: attributes['custom:introduce'] || '',
            initialImageUrl: imageUrl,
          }}
        />
        <p>
          <span>氏名、生年月日、電話番号、所持資格、活動地域</span>
          を変更する場合は下記よりご連絡ください。
        </p>
        <button type="button" onClick={navigateToContact}>
          連絡先
        </button>
        <p>
          所持資格を変更する場合は、提出して頂く書類があります。
          <br />
          活動地域を変更する場合は、料金の変更が伴います。
        </p>
      </div>
      <NavigateBtn />
    </div>
  );
};

ExecutorProfileContent.defaultProps = {
  attributes: defaultAttribute,
};

export default ExecutorProfileContent;
