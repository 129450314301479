import { Suspense, VFC } from 'react';
import { Route, Routes } from 'react-router';
import SignInGuard from 'auth/signInGuard';
import Spinner from 'components/molecules/Spinner';
import Executor from 'executor/executor';
import ExecutorQuestion from 'executor/components/pages/executorQuestion';
import WillQuestionAfterLogin from 'executor/components/pages/willQuestionAfterLogin';
import WillChargeList from 'executor/components/pages/willChargeList';
import ApplicantList from 'executor/components/pages/applicantList';
import WillCharge from 'executor/components/pages/willCharge';
import ExecutorProfile from 'executor/components/pages/executorProfile';
import ExecutorProfileEdit from 'executor/components/pages/executorProfileEdit';
import ExecutorProfileConfirm from 'executor/components/pages/executorProfileConfirm';
import ChangeMail from 'executor/components/pages/changeMail';
import ChangeEmailConfirm from 'executor/components/pages/changeEmailConfirm';
import ChangePass from 'executor/components/pages/changePass';
import ChatList from 'executor/components/pages/chatList';
import Chat from 'executor/components/pages/chat';
import CompanyInfo from 'common/components/pages/companyInfo';
import ContactThanks from 'common/components/pages/contactThanks';
import Contact from 'common/contact';
import ContactConfirm from 'common/contactConfirm';
import DeleteThanks from 'common/components/pages/deleteThanks';
import ExecutorPolicy from 'common/components/pages/executorPolicy';
// import WillPolicy from 'common/components/pages/willPolicy';
import FrontTemporaryEnter from 'common/frontTemporaryEnter';
import Delete from 'user/components/pages/delete';
import ExecutorPrivacyPolicy from 'common/components/pages/executorPrivacyPolicy';
// import WillPrivacyPolicy from 'common/components/pages/willPrivacyPolicy';

const ExecutorRouter: VFC = () => (
  <Routes>
    <Route
      path="/"
      element={
        <SignInGuard theGroup="Executor">
          <Executor />
        </SignInGuard>
      }
    >
      <Route path="" element={<WillChargeList />} />
      <Route path="applicantList" element={<ApplicantList />} />
      <Route path="willcharge" element={<WillCharge />} />
      <Route
        path="chatList"
        element={
          <Suspense fallback={<Spinner size="large" />}>
            <ChatList />
          </Suspense>
        }
      />
      <Route
        path="chat"
        element={
          <Suspense fallback={<Spinner size="large" />}>
            <Chat />
          </Suspense>
        }
      />

      <Route
        path="profile"
        element={
          <Suspense fallback={<div>{Spinner}</div>}>
            <ExecutorProfile />
          </Suspense>
        }
      />
      <Route path="profile/edit" element={<ExecutorProfileEdit />} />
      <Route path="profile/confirm" element={<ExecutorProfileConfirm />} />
      <Route path="executorquestion" element={<ExecutorQuestion />} />
      <Route path="willquestion" element={<WillQuestionAfterLogin />} />
      <Route path="companyinfo" element={<CompanyInfo />} />
      <Route path="contact" element={<Contact />} />
      <Route path="contact/confirm" element={<ContactConfirm />} />
      <Route path="contact/thanks" element={<ContactThanks />} />
      <Route path="email" element={<ChangeMail />} />
      <Route path="email/confirm" element={<ChangeEmailConfirm />} />
      <Route path="password" element={<ChangePass />} />
      <Route path="executorpolicy" element={<ExecutorPolicy />} />
      {/* <Route path="willpolicy" element={<WillPolicy />} /> */}
      {/* <Route path="willprivacypolicy" element={<WillPrivacyPolicy />} /> */}
      <Route path="executorprivacypolicy" element={<ExecutorPrivacyPolicy />} />
      <Route path="delete/thanks" element={<DeleteThanks />} />
      <Route path="delete" element={<Delete />} />
      <Route path="front_temporary_enter" element={<FrontTemporaryEnter />} />
    </Route>
  </Routes>
);

export default ExecutorRouter;
