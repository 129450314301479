import { FC, Suspense } from 'react';
import { useQuery } from 'react-query';
import { useRelationshpFromExecutor } from 'hooks';
import { AssignStatus } from 'models';
import { GetUser } from 'domains/cognite/services/manageUser';
import { logger } from 'utils/logger';
import WillInfoCard from '../molecules/willIInfoCard';
import './applicantListContent.css';

const ApplicantListContent: FC = () => {
  const { data: executor } = useQuery(['authenticated_user'], GetUser);
  if (!executor) {
    logger.error(executor);
    throw Error;
  }

  const { data: relationship, error } =
    useRelationshpFromExecutor('clients_REGISTERD');

  if (!relationship || relationship.length === 0 || error) {
    return (
      <div className="applicantList">
        <div>
          <p>現在、申請者はいません</p>
        </div>
      </div>
    );
  }

  const relationshipOfRegister = relationship.filter(
    (ele) => ele.status === AssignStatus.REGISTERD,
  );

  if (relationshipOfRegister.length === 0) {
    return (
      <div className="applicantList">
        <div>
          <p>現在、申請者はいません</p>
        </div>
      </div>
    );
  }

  return (
    <div className="applicantList">
      <div>
        {relationshipOfRegister.map((ele) => (
          <Suspense
            key={`applicant_load_${ele.id}`}
            fallback={<p>...読み込み中</p>}
          >
            <WillInfoCard
              key={`WillInfoCard_${ele.id}`}
              // user={ele.client}
              executorEmail={executor.attributes.email}
              relationship={ele}
            />
          </Suspense>
        ))}
      </div>
    </div>
  );
};
export default ApplicantListContent;
