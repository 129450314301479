import { FC } from 'react';
import './willFormCard.css';

type Props = {
  name: string;
  sex: string;
  address: string;
  age: string;
  // filledInItem: string;
};

const WillFormCard: FC<Props> = (props) => {
  const { name, sex, address, age } = props;

  return (
    <div className="willFormCard">
      <div>
        <table>
          <tr>
            <td>氏名</td>
            <td>{name}</td>
          </tr>
          <tr>
            <td>性別</td>
            <td>{sex}</td>
          </tr>
          <tr>
            <td>住所</td>
            <td>{address}</td>
          </tr>
          <tr>
            <td>年代</td>
            <td>{age}</td>
          </tr>
          {/* <tr>
            <td>記入済みの項目</td>
            <td>{filledInItem}</td>
          </tr> */}
        </table>
      </div>
    </div>
  );
};

export default WillFormCard;
