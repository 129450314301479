import { FC } from 'react';
import UserLogoHeader from '../organisms/userLogoHeader';
import ExecutorPolicyContent from '../templates/executorPolicyContent';
import CommonFooter from '../../apply/templates/commonFooter';
import NavigateBtn from '../../../user/components/atoms/navigateBtn';
import './executorPolicy.css';

const ExecutorPolicy: FC = () => (
  <>
    <UserLogoHeader />
    <div className="executor-policy">
      <ExecutorPolicyContent />
      <NavigateBtn />
    </div>
    <CommonFooter />
  </>
);

export default ExecutorPolicy;
