import { FC, Suspense } from 'react';
import { QueryAllocationProperties } from 'domains/cognite/services/manageAllocationProperties';
import ItemLink from '../molecules/itemLink';
import Spinner from '../../../components/molecules/Spinner';
import { propertyAllocationData } from '../data/homeData';
import allocationInfo from '../images/allocationInfo.svg';
import './allocationInfo.css';

const AllocationInfo: FC = () => {
  const getAllocationPropertiesQuery = async () => {
    const getAllocationPropertiesQueryContent =
      await QueryAllocationProperties();
    const data = getAllocationPropertiesQueryContent[0];

    return data;
  };

  return (
    <div className="allocationInfo">
      <div>
        <h2>相続財産の割り振り</h2>
        <div>
          <img src={allocationInfo} alt="財産割り当ての情報" />
          <p>誰に何を相続させるか財産の割り当てを決める項目です。</p>
        </div>
      </div>
      <div>
        <Suspense fallback={<div>{Spinner}</div>}>
          <ItemLink
            name={propertyAllocationData.name}
            href={propertyAllocationData.href}
            icon={propertyAllocationData.icon}
            getQuery={getAllocationPropertiesQuery}
            keyId="property"
          />
        </Suspense>
      </div>
    </div>
  );
};

export default AllocationInfo;
