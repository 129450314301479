/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import { FC, useState, ChangeEvent } from 'react';
import {
  AllocationProperties,
  RealEstate,
  Deposits,
  Stock,
  LifeInsurance,
  Bonds,
  Liabilities,
  DepositsContent,
  RealEstateContent,
  LifeInsuranceContent,
  BondsContent,
  StockContent,
  LiabilitiesContent,
} from 'models';
import AccordionIcon from '../images/accordionIcon.svg';
import AccordionFormCard from '../organisms/accordionFormCard';
import './accordionTemp.css';

type Props = {
  title: string;
  text: string;
  id: number;
  realEstate?: RealEstate | null;
  deposits?: Deposits | null;
  stock?: Stock | null;
  lifeInsurance?: LifeInsurance | null;
  bonds?: Bonds | null;
  liabilities?: Liabilities | null;
  filter?: string;
  realEstateContent?: RealEstateContent[] | undefined;
  depositsContent?: DepositsContent[] | undefined;
  lifeInsuranceContent?: LifeInsuranceContent[] | undefined;
  bondsContent?: BondsContent[] | undefined;
  stockContent?: StockContent[] | undefined;
  liabilitiesContent?: LiabilitiesContent[] | undefined;
  realEstateReceiverData?: AllocationProperties[];
  depositsReceiverData?: AllocationProperties[];
  stockReceiverData?: AllocationProperties[];
  lifeInsuranceReceiverData?: AllocationProperties[];
  bondsReceiverInvestmentData?: AllocationProperties[];
  bondsReceiverLoanData?: AllocationProperties[];
  liabilitiesReceiverData?: AllocationProperties[];
  defaultisAccordionOpen: boolean;
  updateAllocationProperties: (
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    target: string | number,
  ) => void;
  deleteCard: (deleteSubject: string) => void;
  addCard: (
    contentId: string,
    category: string,
    majorNum: number,
    subNum: number,
  ) => void;
};

const AccordionTemp: FC<Props> = (props) => {
  const {
    title,
    text,
    id,
    realEstate,
    deposits,
    stock,
    lifeInsurance,
    bonds,
    liabilities,
    filter,
    realEstateContent,
    depositsContent,
    lifeInsuranceContent,
    bondsContent,
    stockContent,
    liabilitiesContent,
    realEstateReceiverData,
    depositsReceiverData,
    stockReceiverData,
    lifeInsuranceReceiverData,
    bondsReceiverInvestmentData,
    bondsReceiverLoanData,
    liabilitiesReceiverData,
    defaultisAccordionOpen,
    updateAllocationProperties,
    deleteCard,
    addCard,
  } = props;
  const [isAccordionOpen, setisAccordionOpen] = useState(
    defaultisAccordionOpen,
  );
  const openAccordion = () => {
    setisAccordionOpen(!isAccordionOpen);
    window.scrollBy({
      top: 200,
      behavior: 'smooth',
    });
  };

  return (
    <div className="accordionBox">
      <label htmlFor={`Button${id}`}>
        <div className={isAccordionOpen ? 'radiusNon' : 'radiusOn'}>
          <p>{title}</p>
          <button
            id={`Button${id}`}
            type="button"
            onClick={() => openAccordion()}
          >
            <img
              src={AccordionIcon}
              alt="矢印"
              className={isAccordionOpen ? 'openIcon' : 'closeIcon'}
            />
          </button>
        </div>
      </label>
      <div className={isAccordionOpen ? 'accordionOpen' : 'accordionClose'}>
        <p>{text}</p>
        <div>
          {/* 不動産の場合 */}
          {realEstate && realEstateContent ? (
            realEstateContent
              .filter((ele) => ele && ele?.type === filter)
              .map((ele) => {
                const realEstateContentId = ele && ele ? ele.id : '';
                const address = ele && ele && ele.address ? ele.address : '';
                const majorNum =
                  realEstate && realEstate.majorNum ? realEstate.majorNum : 0;
                const subNum = ele && ele && ele.subNum ? ele.subNum : 0;

                return (
                  <AccordionFormCard
                    key={realEstateContentId}
                    title={address}
                    defaultData={
                      realEstateReceiverData
                        ? realEstateReceiverData.filter(
                            (e) => e.contentId === realEstateContentId,
                          )
                        : []
                    }
                    updateAllocationProperties={updateAllocationProperties}
                    deleteCard={deleteCard}
                    contentId={realEstateContentId}
                    category="不動産"
                    majorNum={majorNum}
                    subNum={subNum}
                    addCard={addCard}
                  />
                );
              })
          ) : (
            <></>
          )}
          {deposits && depositsContent ? (
            depositsContent.map((ele) => {
              const depositsContentId = ele && ele.id ? ele.id : '';
              const bankName = ele && ele.bankName ? ele.bankName : '';
              const branchName = ele && ele.branchName ? ele.branchName : '';
              const accountType = ele && ele.accountType ? ele.accountType : '';
              const majorNum =
                deposits && deposits.majorNum ? deposits.majorNum : 0;
              const subNum = ele && ele && ele.subNum ? ele.subNum : 0;

              return (
                <AccordionFormCard
                  key={depositsContentId}
                  title={`${bankName} ${branchName} ${accountType}`}
                  defaultData={
                    depositsReceiverData
                      ? depositsReceiverData.filter(
                          (e) => e.contentId === depositsContentId,
                        )
                      : []
                  }
                  updateAllocationProperties={updateAllocationProperties}
                  deleteCard={deleteCard}
                  contentId={depositsContentId}
                  category="預貯金"
                  majorNum={majorNum}
                  subNum={subNum}
                  addCard={addCard}
                />
              );
            })
          ) : (
            <></>
          )}
          {/* 株式・債券の場合 */}
          {stock && stockContent ? (
            stockContent.map((ele) => {
              const stockContentId = ele && ele.id ? ele.id : '';
              const name = ele && ele.name ? ele.name : '';
              const customerNumber =
                ele && ele.customerNumber ? ele.customerNumber : '';
              const majorNum = stock && stock.majorNum ? stock.majorNum : 0;
              const subNum = ele && ele && ele.subNum ? ele.subNum : 0;

              return (
                <AccordionFormCard
                  key={stockContentId}
                  title={`${name} ${customerNumber}`}
                  defaultData={
                    stockReceiverData
                      ? stockReceiverData.filter(
                          (e) => e.contentId === stockContentId,
                        )
                      : []
                  }
                  updateAllocationProperties={updateAllocationProperties}
                  deleteCard={deleteCard}
                  contentId={stockContentId}
                  category="株式・債券"
                  majorNum={majorNum}
                  subNum={subNum}
                  addCard={addCard}
                />
              );
            })
          ) : (
            <></>
          )}
          {/* 生命保険の場合 */}
          {lifeInsurance && lifeInsuranceContent ? (
            lifeInsuranceContent.map((ele) => {
              const lifeInsuranceContentId = ele && ele.id ? ele.id : '';
              const insuranceCompanyName =
                ele && ele.insuranceCompanyName ? ele.insuranceCompanyName : '';
              const majorNum =
                lifeInsurance && lifeInsurance.majorNum
                  ? lifeInsurance.majorNum
                  : 0;
              const subNum = ele && ele && ele.subNum ? ele.subNum : 0;

              return (
                <AccordionFormCard
                  key={lifeInsuranceContentId}
                  title={insuranceCompanyName}
                  defaultData={
                    lifeInsuranceReceiverData
                      ? lifeInsuranceReceiverData.filter(
                          (e) => e.contentId === lifeInsuranceContentId,
                        )
                      : []
                  }
                  updateAllocationProperties={updateAllocationProperties}
                  deleteCard={deleteCard}
                  contentId={lifeInsuranceContentId}
                  category="生命保険"
                  majorNum={majorNum}
                  subNum={subNum}
                  addCard={addCard}
                />
              );
            })
          ) : (
            <></>
          )}
          {/* 債権の場合 */}
          {bonds && bondsContent && filter === '貸付金' ? (
            bondsContent
              .filter((ele) => ele && ele.type === '貸付金')
              .map((ele) => {
                const bondsContentId = ele && ele.id ? ele.id : '';
                const loanerName = ele && ele.loanerName ? ele.loanerName : '';
                const majorNum = bonds && bonds.majorNum ? bonds.majorNum : 0;
                const subNum = ele && ele && ele.subNum ? ele.subNum : 0;

                return (
                  <AccordionFormCard
                    key={bondsContentId}
                    title={loanerName}
                    defaultData={
                      bondsReceiverLoanData
                        ? bondsReceiverLoanData.filter(
                            (e) => e.contentId === bondsContentId,
                          )
                        : []
                    }
                    updateAllocationProperties={updateAllocationProperties}
                    deleteCard={deleteCard}
                    contentId={bondsContentId}
                    category="貸付金"
                    majorNum={majorNum}
                    subNum={subNum}
                    addCard={addCard}
                  />
                );
              })
          ) : bonds && bondsContent && filter === '出資金' ? (
            bondsContent
              .filter((ele) => ele && ele.type === '出資金')
              .map((ele) => {
                const bondsContentId = ele && ele.id ? ele.id : '';
                const investmenterName =
                  ele && ele.investmenterName ? ele.investmenterName : '';
                const majorNum = bonds && bonds.majorNum ? bonds.majorNum : 0;
                const subNum = ele && ele && ele.subNum ? ele.subNum : 0;

                return (
                  <AccordionFormCard
                    key={bondsContentId}
                    title={investmenterName}
                    defaultData={
                      bondsReceiverInvestmentData
                        ? bondsReceiverInvestmentData.filter(
                            (e) => e.contentId === bondsContentId,
                          )
                        : []
                    }
                    updateAllocationProperties={updateAllocationProperties}
                    deleteCard={deleteCard}
                    contentId={bondsContentId}
                    category="出資金"
                    majorNum={majorNum}
                    subNum={subNum}
                    addCard={addCard}
                  />
                );
              })
          ) : (
            <></>
          )}
          {/* 負債の場合 */}
          {liabilities && liabilitiesContent ? (
            liabilitiesContent.map((ele) => {
              const liabilitiesContentId = ele && ele.id ? ele.id : '';
              const name = ele && ele.name ? ele.name : '';
              const majorNum =
                liabilities && liabilities.majorNum ? liabilities.majorNum : 0;
              const subNum: number = ele && ele && ele.subNum ? ele.subNum : 0;

              return (
                <AccordionFormCard
                  key={liabilitiesContentId}
                  title={`${name}`}
                  defaultData={
                    liabilitiesReceiverData
                      ? liabilitiesReceiverData.filter(
                          (e) => e.contentId === liabilitiesContentId,
                        )
                      : []
                  }
                  updateAllocationProperties={updateAllocationProperties}
                  deleteCard={deleteCard}
                  liabilitiesCheck
                  contentId={liabilitiesContentId}
                  category="負債"
                  majorNum={majorNum}
                  subNum={subNum}
                  addCard={addCard}
                />
              );
            })
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

AccordionTemp.defaultProps = {
  realEstate: null,
  deposits: null,
  stock: null,
  lifeInsurance: null,
  bonds: null,
  liabilities: null,
  filter: '',
};

export default AccordionTemp;
