// eslint-disable-next-line import/no-extraneous-dependencies
import { PutResult, StorageAccessLevel } from '@aws-amplify/storage';
import { Storage } from 'aws-amplify';
import { logger } from 'utils/logger';

const PutS3 = async (
  name: string,
  image: File | Blob,
  level: StorageAccessLevel,
): Promise<PutResult> => {
  try {
    logger.info('S3へのアップロード処理を開始します');
    const result = await Storage.put(name, image, {
      level,
    });
    logger.debug(result);
    logger.info('S3へのアップロード処理を完了しました');

    return result;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export default PutS3;
