/* eslint-disable no-param-reassign */
import { DataStore } from 'aws-amplify';
import { StayingTimeAtList, StayingTimeAtListMetaData } from 'models';
import { logger } from 'utils/logger';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ModelInit } from '@aws-amplify/datastore';

export const CreateStayingTimeAtList = async (
  instance: ModelInit<StayingTimeAtList, StayingTimeAtListMetaData>,
): Promise<void> => {
  try {
    await DataStore.save(new StayingTimeAtList(instance));
  } catch (error) {
    logger.error(error);
    logger.error('mutateのエラー');
    throw error;
  }
};

export const UpdateStayingTimeAtList = async (
  id: string,
  addTime: number,
): Promise<void> => {
  try {
    const original = await DataStore.query(StayingTimeAtList, id);
    if (original) {
      await DataStore.save(
        StayingTimeAtList.copyOf(original, (updated) => {
          updated.time = addTime;
        }),
      );
    }
  } catch (error) {
    logger.error(error);
    logger.error('mutateのエラー');
    throw error;
  }
};

export const GetStayingTimeAtList = async (): Promise<StayingTimeAtList[]> => {
  try {
    logger.info('株式・債券の全項目のフェッチを開始します');
    const data = await DataStore.query(StayingTimeAtList);
    logger.debug(data);
    logger.info('株式・債券の全項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetStayingTimeAtListByUserId = async (
  userId: string,
): Promise<StayingTimeAtList[] | undefined> => {
  try {
    logger.info('株式・債券の全項目のフェッチを開始します');
    const data = await DataStore.query(StayingTimeAtList, (c) =>
      c.userId('eq', userId),
    );
    logger.debug(data);
    logger.info('株式・債券の全項目のフェッチが完了しました');

    if (!data.length) {
      return undefined;
    }

    return data;
  } catch (error) {
    logger.error(error);
    logger.error('getのエラー');
    throw error;
  }
};
