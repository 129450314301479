import { FC, Suspense } from 'react';
// import { useSync } from 'hooks';
import WillHeader from '../organisms/willHeader';
import ExecutorApprovalDescription from '../templates/executorApprovalDescription';
import ExectorApprovalContent from '../organisms/executorApprovalContent';
import ExecutorPcDescription from '../organisms/executorPcDescription';
import pageData from '../data/pageData';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import './executorApproval.css';

const ExectorApproval: FC = () => {
  // useSync();
  const descriptionPc = (
    <>
      {pageData[32].description}
      {pageData[33].description}
      {pageData[34].description}
    </>
  );

  return (
    <>
      <WillHeader title="担当遺言執行者" />
      <div className="sp-only">
        <ExecutorApprovalDescription />
      </div>
      <Suspense fallback={<div>...Loading</div>}>
        <ExectorApprovalContent />
      </Suspense>
      <CommonFooter />
      <ExecutorPcDescription
        description={descriptionPc}
        pageTitle="ExectorApprovalDes"
      />
    </>
  );
};

export default ExectorApproval;
