import { FC, Suspense } from 'react';
import { useQuery } from 'react-query';
import { User } from 'domains/apigateway/services/get-user';
import { ListS3 } from 'domains/aws/services/get-s3';
// import { QueryAllocationProperties } from 'domains/cognite/services/manageAllocationProperties';
// import ConfirmList from 'user/components/templates/confirmList';
import ExecutorWordDownload from '../molecules/executorWordDownload';
import ExecutorPdfDownload from '../molecules/executorPdfDownload';
import NormalBtn from '../atoms/normalBtn';
import './willChargeContent.css';
// import { useQuery } from 'react-query';

type Props = {
  client: User;
};

const WillChargeContent: FC<Props> = (props) => {
  const { client } = props;
  const { id } = client;

  const pdfExist = async () => {
    const length = await ListS3(`遺言書_${id}.pdf`);
    const check = !!length;

    return check;
  };

  const wordExist = async () => {
    const length = await ListS3(`遺言書_${id}.docx`);
    const check = !!length;

    return check;
  };

  const { data: pdfExistCheck } = useQuery([`pdf_exist_check_${id}`], pdfExist);
  const { data: wordExistCheck } = useQuery(
    [`word_exist_check_${id}`],
    wordExist,
  );

  // const { allocationReceiverAndProperties } =
  //   useQueryAllocationReceiverAndProperties(`${client.id}_charge`, allocation);

  const pdfNameCheck = pdfExistCheck ? '' : 'pdfBtnNotClick';
  const wordNameCheck = wordExistCheck ? '' : 'wordBtnNotClick';

  // const { data: allocationPropertiesData } = useQuery(
  //   ['allocationPropertiesData_executor', client.id],
  //   QueryAllocationProperties,
  //   {
  //     cacheTime: 2000,
  //   },
  // );

  // const allocationProperties = allocationPropertiesData || [];

  return (
    <div className="willCharge">
      <div>
        <p>担当遺言者のプロフィール</p>
        <table>
          <tr>
            <td>ユーザーネーム</td>
            <td>{client.email}</td>
          </tr>
          <tr>
            <td>氏名</td>
            <td>{client.name}</td>
          </tr>
          <tr>
            <td>性別</td>
            <td>{client.gender}</td>
          </tr>
          <tr>
            <td>住所</td>
            <td>{client.address}</td>
          </tr>
          <tr>
            <td>年代</td>
            <td>{client.age}</td>
          </tr>
        </table>
        {/* <div>
          <p>遺言書</p>
          <ConfirmList
            properties={properties}
            willInfo={willInfo}
            realEstateContent={realEstateContent}
            movingProperty={movingProperty}
            depositsContent={depositsContent}
            stockContent={stockContent}
            lifeInsuranceContent={lifeInsuranceContent}
            bondsContent={bondsContent}
            liabilitiesContent={liabilitiesContent}
            allocationReceiver={allocationReceiverAndProperties}
            allocationProperties={allocationProperties}
          />
        </div> */}
        <div className="willChargeBtn">
          <Suspense fallback={<div>..。読み込み中</div>}>
            <ExecutorPdfDownload userId={client.id} pdfName={pdfNameCheck} />
          </Suspense>
          <ExecutorWordDownload userId={client.id} wordName={wordNameCheck} />
        </div>
        <NormalBtn
          title="戻る"
          className="returnBtn willChargeReturnBtn"
          href="/executor"
        />
      </div>
    </div>
  );
};

export default WillChargeContent;
