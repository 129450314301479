import { FC, Suspense } from 'react';
// import { useSync } from 'hooks';
import WillHeader from '../organisms/willHeader';
import ExecutorAwaitingApprovalDescription from '../templates/executorAwaitingApprovalDescription';
// import Footer from '../organisms/footer';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import ExectorAwaitingApprovalContent from '../organisms/executorAwaitingApprovalContent';
import ExecutorPcDescription from '../organisms/executorPcDescription';
import pageData from '../data/pageData';
import './executorAwaitingApproval.css';

const ExectorAwaitingApproval: FC = () => (
  <>
    <WillHeader title="担当遺言執行者" />
    <div className="executorAwaitingApproval">
      <div className="sp-only">
        <ExecutorAwaitingApprovalDescription />
      </div>
      <Suspense fallback={<div>...Loading</div>}>
        <ExectorAwaitingApprovalContent />
      </Suspense>
    </div>
    {/* <Footer /> */}
    <CommonFooter />
    <ExecutorPcDescription description={pageData[31].description} />
  </>
);

export default ExectorAwaitingApproval;
