import { FC } from 'react';
import { Link } from 'react-router-dom';
import './dataBtns.css';

const DataBtns: FC = () => (
  <div className="data-btns">
    <div>
      <Link to="/admin/user/newUserData">新規登録者数</Link>
      <Link to="/admin/user/userData">登録者数</Link>
      <Link to="/admin/user/addressNewUserData">地域別新規登録者数</Link>
      <Link to="/admin/user/addressUserData">地域別登録者数</Link>
      <Link to="/admin/user/timeOnPage">アプリ内滞在時間</Link>
    </div>
  </div>
);

export default DataBtns;
