/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import { DataStore } from 'aws-amplify';
import { Stock, StockContent, StockContentMetaData } from 'models';
import { logger } from 'utils/logger';
import { ModelInit } from '@aws-amplify/datastore';

export const MutateStockContentAndGet = async (
  id: string,
  subNum: number,
  name: string,
  customerNumber: string,
  stock?: Stock,
): Promise<StockContent> => {
  try {
    logger.info('株式・債券の項目の作成または更新を開始します');
    const original = await DataStore.query(StockContent, id);

    if (original) {
      logger.info(original);
      const data = await DataStore.save(
        StockContent.copyOf(original, (updated) => {
          updated.subNum = subNum;
          updated.name = name;
          updated.customerNumber = customerNumber;
          updated.stock = stock;
        }),
      );
      logger.info(data);
      logger.info('株式・債券の項目の更新が完了しました');

      return data;
    }
    logger.info('株式・債券の項目の作成を開始します');
    const instance = {
      subNum,
      name,
      customerNumber,
      stock,
    };
    const data = await DataStore.save(new StockContent(instance));
    logger.debug(data);
    logger.info('株式・債券の項目の作成が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const CreateStockContentAndGetId = async (
  instance: ModelInit<StockContent, StockContentMetaData>,
): Promise<string> => {
  try {
    logger.info('株式・債券の項目の作成を開始します');
    const data = await DataStore.save(new StockContent(instance));
    logger.debug(data);
    logger.info('株式・債券の項目の作成が完了しました');

    return data.id;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const UpdateStockContentById = async (
  id: string,
  name: string,
  customerNumber: string,
): Promise<void> => {
  try {
    logger.info('株式・債券の項目の更新を開始します');

    const original = await DataStore.query(StockContent, id);

    if (original) {
      logger.info(original);
      const data = await DataStore.save(
        StockContent.copyOf(original, (updated) => {
          updated.name = name;
          updated.customerNumber = customerNumber;
        }),
      );
      logger.info(data);
      logger.info('株式・債券の項目の更新が完了しました');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteStockContentById = async (id: string): Promise<void> => {
  try {
    logger.info('株式・債券の項目の削除を開始します');
    const original = await DataStore.query(StockContent, id);
    if (original) {
      logger.info(original);
      await DataStore.delete(original);
      logger.info('株式・債券の項目の削除が完了しました');
    }
  } catch (error) {
    logger.error(error);
    logger.info('失敗');
    throw error;
  }
};

export const GetStockContent = async (): Promise<StockContent[]> => {
  try {
    logger.info('株式・債券の項目のフェッチを開始します');
    const data = await DataStore.query(StockContent);
    logger.debug(data);
    logger.info('株式・債券の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetStockContentByParent = async (
  stock: Stock | null,
): Promise<StockContent[]> => {
  try {
    logger.info('株式・債券の項目のフェッチを開始します');
    const data = (await DataStore.query(StockContent)).filter((c) => {
      if (c.stock === null || c.stock === undefined || !stock) {
        return false;
      }
      const stockId = c.stock.id;

      return stock.id === stockId;
    });
    logger.debug(data);
    logger.info('株式・債券の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteStockContent = async (): Promise<StockContent[]> => {
  try {
    logger.info('株式・債券の全項目の削除を開始します');
    const data = await DataStore.query(StockContent);
    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);

          return data;
        }),
      );
    }
    logger.debug(data);
    logger.info('株式・債券の全項目の削除を開始します');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
