/* eslint-disable no-alert */
import { FC, useRef, FormEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { VerifyOTP } from 'domains/cognite/services/manageUser';
import { logger } from 'utils/logger';
import UserLogoHeader from 'user/components/organisms/userLogoHeader';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import './userEmailConfirm.css';
import NavigateBtn from '../atoms/navigateBtn';

type State = {
  email: string;
};

const UserEmailConfirm: FC = () => {
  const location = useLocation();
  const { email } = location.state as State;
  const navigate = useNavigate();
  const codeRef = useRef<HTMLInputElement>(null);
  const [alertEmail, setAlertEmail] = useState(false);
  const emailAlertMessage = alertEmail ? 'alertMessage' : '';

  const confirmEmail = async (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!codeRef.current?.value) {
      setAlertEmail(true);
    } else {
      try {
        if (email && codeRef.current && codeRef.current.value) {
          await VerifyOTP(email, codeRef.current.value)
            .then(() => {
              logger.info('メールアドレス変更成功');
              navigate('/user');
            })
            .catch(() => {
              window.alert('メールアドレス変更成功に失敗しました');
            });
        } else {
          logger.error('認証失敗');
        }
      } catch (error) {
        logger.error('認証失敗');
        logger.error('error signing in', error);
      }
    }
  };

  return (
    <>
      <UserLogoHeader />
      <div className="userEmailConfirm">
        <h1>認証コードの送信</h1>
        <p>
          認証コードを
          <br className="sp-only" />
          入力してください
        </p>
        <form action="" className="userEmailConfirmWrapper">
          <label htmlFor="email">
            認証コード
            <input
              type="email"
              id="email"
              ref={codeRef}
              placeholder="認証コードを入力"
            />
          </label>
          <p className={`deleteMessage ${emailAlertMessage}`}>
            メールアドレスが入力されていません
          </p>
          <input type="submit" value="認証する" onClick={confirmEmail} />
        </form>
        <NavigateBtn />
        <div className="pc-only">
          <CommonFooter />
        </div>
      </div>
    </>
  );
};

export default UserEmailConfirm;
