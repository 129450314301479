import { FC } from 'react';
import TopicsTemplate from '../templates/topicsTemplate';
import TopicCreateOrEditConfirmContent from '../organisms/topicCreateOrEditConfirmContent';

const TopicCreateConfirm: FC = () => (
  <TopicsTemplate
    subTitle="新規投稿確認"
    sideBtnPath="/admin/topics/list"
    sideBtnText="トピック一覧"
  >
    <TopicCreateOrEditConfirmContent prevPath="/admin/topics/create" />
  </TopicsTemplate>
);

export default TopicCreateConfirm;
