/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import { DataStore } from 'aws-amplify';
import {
  Liabilities,
  LiabilitiesContent,
  LiabilitiesContentMetaData,
} from 'models';
import { logger } from 'utils/logger';
import { ModelInit } from '@aws-amplify/datastore';

export const MutateLiabilitiesContentAndGet = async (
  id: string,
  subNum: number,
  name: string,
  initialAmount: string,
  initialDate: string,
  guarantor: string,
  liabilities?: Liabilities,
): Promise<LiabilitiesContent> => {
  try {
    logger.info('負債の項目の作成または更新を開始します');
    const original = await DataStore.query(LiabilitiesContent, id);

    if (original) {
      logger.info(original);
      const data = await DataStore.save(
        LiabilitiesContent.copyOf(original, (updated) => {
          updated.subNum = subNum;
          updated.name = name;
          updated.initialAmount = initialAmount;
          updated.initialDate = initialDate;
          updated.guarantor = guarantor;
          updated.liabilities = liabilities;
        }),
      );
      logger.info(data);
      logger.info('負債の項目の更新が完了しました');

      return data;
    }
    logger.info('負債の項目の作成を開始します');
    const instance = {
      subNum,
      name,
      initialAmount,
      initialDate,
      guarantor,
      liabilities,
    };
    const data = await DataStore.save(new LiabilitiesContent(instance));
    logger.debug(data);
    logger.info('負債の項目の作成が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const CreateLiabilitiesContentAndGetId = async (
  instance: ModelInit<LiabilitiesContent, LiabilitiesContentMetaData>,
): Promise<string> => {
  try {
    logger.info('負債の項目の作成を開始します');
    const data = await DataStore.save(new LiabilitiesContent(instance));
    logger.debug(data);
    logger.info('負債の項目の作成が完了しました');

    return data.id;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const UpdateLiabilitiesContentById = async (
  id: string,
  name: string,
  initialAmount: string,
  initialDate: string,
  guarantor: string,
): Promise<void> => {
  try {
    logger.info('負債の項目の更新を開始します');

    const original = await DataStore.query(LiabilitiesContent, id);

    if (original) {
      logger.info(original);
      const data = await DataStore.save(
        LiabilitiesContent.copyOf(original, (updated) => {
          updated.name = name;
          updated.initialAmount = initialAmount;
          updated.initialDate = initialDate;
          updated.guarantor = guarantor;
        }),
      );
      logger.info(data);
      logger.info('負債の項目の更新が完了しました');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteLiabilitiesContentById = async (
  id: string,
): Promise<void> => {
  try {
    logger.info('負債の項目の削除を開始します');
    const original = await DataStore.query(LiabilitiesContent, id);
    if (original) {
      logger.info(original);
      await DataStore.delete(original);
      logger.info('負債の項目の削除が完了しました');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetLiabilitiesContent = async (): Promise<
  LiabilitiesContent[]
> => {
  try {
    logger.info('負債の項目のフェッチを開始します');
    const data = await DataStore.query(LiabilitiesContent);
    logger.debug(data);
    logger.info('負債の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetLiabilitiesContentByParent = async (
  liabilities: Liabilities | null,
): Promise<LiabilitiesContent[]> => {
  try {
    logger.info('負債の項目のフェッチを開始します');
    const data = (await DataStore.query(LiabilitiesContent)).filter((c) => {
      if (
        c.liabilities === null ||
        c.liabilities === undefined ||
        !liabilities
      ) {
        return false;
      }
      const liabilitiesId = c.liabilities.id;

      return liabilities.id === liabilitiesId;
    });
    logger.debug(data);
    logger.info('負債の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteLiabilitiesContent = async (): Promise<
  LiabilitiesContent[]
> => {
  try {
    logger.info('負債の全項目の削除を開始します');
    const data = await DataStore.query(LiabilitiesContent);
    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);

          return data;
        }),
      );
    }
    logger.debug(data);
    logger.info('負債の全項目の削除を開始します');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
