/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import { useQuery } from 'react-query';
import { useQueryAllocation, useQueryAllocationQuick } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { GetUser } from 'domains/cognite/services/manageUser';
import { logger } from 'utils/logger';
import RecipientDescription from '../templates/recipientDescription';
import RecipientTemp from '../templates/recipientTemp';
import WillHeader from '../organisms/willHeader';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import PageDescriptionPc from '../organisms/pageDescriptionPc';
import pageData from '../data/pageData';
import PcDescriptionBackImg from '../images/PcLegalHeirBack.svg';

const Recipient: FC = () => {
  // useSync();
  const { data: user } = useQuery(['authenticated_user'], GetUser);
  if (!user) {
    logger.error(user);
    throw Error;
  }
  const { data } = useQueryAllocation('recipient_allocation', user);
  const { data: dataQuick } = useQueryAllocationQuick(
    'recipient_allocation_quick',
    user,
  );

  const navigate = useNavigate();

  const onClickFunc = (link: string) => {
    navigate(link);
  };

  const descriptionPc = <>{pageData[18].description}</>;

  return (
    <>
      <WillHeader title="受遺者" />
      <div className="sp-only">
        <RecipientDescription data={data} />
      </div>
      <RecipientTemp data={dataQuick} user={user} />
      <CommonFooter onClickFunc={onClickFunc} />
      <PageDescriptionPc
        page="RecipientDescriptionPc"
        description={descriptionPc}
        descriptionPcImg={PcDescriptionBackImg}
      />
    </>
  );
};

export default Recipient;
