/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import { FC, useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Properties, StockContent } from 'models';
import { usePropertiesItem, useQueryContents } from 'hooks';
import { logger } from 'utils/logger';
import { CognitoUserExt } from 'domains/cognite/user';
import { MutateProperties } from 'domains/cognite/services/manageProperties';
import { DeleteStock, MutateStock } from 'domains/cognite/services/manageStock';
import Dialog from 'common/apply/organisms/dialog';
import {
  MutateStockContentAndGet,
  GetStockContent,
  DeleteStockContent,
  GetStockContentByParent,
  DeleteStockContentById,
} from 'domains/cognite/services/manageStockContent';
import PlusBtn from '../atoms/plusBtn';
import RadioCustomSingle from '../molecules/radioCustomSingle';
import StockFormCard from '../organisms/stockFormCard';
import RequireNextReturn from '../molecules/requireNextReturn';
import Param6Checkmark from '../atoms/paramCheckmarkPc';
import './stockTemp.css';

type Props = {
  data: Properties | undefined;
  user: CognitoUserExt;
};

const StockTemp: FC<Props> = (props) => {
  const { data, user } = props;

  const { stock } = usePropertiesItem(data);
  const { data: stockContentByQuery } = useQuery(
    ['stock_content'],
    () => GetStockContentByParent(stock),
    {
      cacheTime: 200,
    },
  );

  const [isDialogShow, setIsDialogShow] = useState(false);

  const toggelDialog = () => {
    setIsDialogShow(!isDialogShow);
  };

  // 初期状態のオブジェクト
  const initialStockContentArrData: StockContent[] = [
    {
      id: '0',
      subNum: null,
      name: '',
      customerNumber: '',
      stock: undefined,
    },
  ];

  const initialStockContentArr =
    stockContentByQuery === undefined ||
    (stockContentByQuery !== undefined && stockContentByQuery.length === 0)
      ? initialStockContentArrData
      : stockContentByQuery;

  const [stockContent, setStockContent] = useState<StockContent[]>(
    initialStockContentArr,
  );

  const updateStockContent = (
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement>,
    target: string,
  ) => {
    const newStockContent = stockContent?.map((ele) => {
      if (ele.id === subjectKey) {
        return {
          id: ele.id,
          subNum: ele.subNum ? ele.subNum : null,
          name:
            target === 'name'
              ? event.target.value
              : ele.name === null || ele.name === undefined
              ? ''
              : ele.name,
          customerNumber:
            target === 'customerNumber'
              ? event.target.value
              : ele.customerNumber === null || ele.customerNumber === undefined
              ? ''
              : ele.customerNumber,
          stock: ele.stock,
        };
      }

      return {
        id: ele.id,
        subNum: ele.subNum,
        name: ele.name,
        customerNumber: ele.customerNumber,
        stock: ele.stock,
      };
    });

    return setStockContent(newStockContent);
  };

  const addcard = () => {
    const randomId = Math.random().toString();
    const addStockContentObject = {
      id: randomId,
      subNum: null,
      name: '',
      customerNumber: '',
      stock: undefined,
    };

    setStockContent([...stockContent, addStockContentObject]);
  };

  const deleteCard = (deleteSubjectId: string) => {
    const newStockContentAfterDelete = stockContent.filter(
      (ele) => ele.id !== deleteSubjectId,
    );

    return setStockContent(newStockContentAfterDelete);
  };

  // はい、いいえの初期状態管理
  const openOrClose = stockContent[0].id !== '0';
  const [open, setOpen] = useState(openOrClose);
  const yesCheck = stockContent[0].id !== '0';
  const [yesCheckState, setYesCheckState] = useState(yesCheck);
  const noCheck = !!(stock && stock.noCheck && stock.noCheck === true);
  const [noCheckState, setNoCheckState] = useState(noCheck);

  // 証券会社名のチェック
  const stockNameArr = stockContent.map((ele) => {
    const stockName =
      ele.name === undefined || ele.name === null ? '' : ele.name;

    return stockName;
  });

  // 顧客番号のチェック
  const customerNumberArr = stockContent.map((ele) => {
    const customerNumber =
      ele.customerNumber === undefined || ele.customerNumber === null
        ? ''
        : ele.customerNumber.toString();

    return customerNumber;
  });

  const propertiesId = data ? data.id : '';

  const { stockMajorNum } = useQueryContents('stock_check', data);

  const cardDeleteAll = async () => {
    await DeleteStockContent();
    await DeleteStock();
    await MutateProperties(propertiesId, {
      userId: user.attributes.sub,
      stock: undefined,
    });

    setOpen(false);
    setYesCheckState(false);
    window.location.reload();
  };

  const navigate = useNavigate();

  const save = async (link: string) => {
    const reservedStockContent = await GetStockContent();
    const stockContentId = stockContent.map((ele) => ele.id);
    reservedStockContent.map((ele) => {
      if (stockContentId.includes(ele.id) === false) {
        return DeleteStockContentById(ele.id);
      }

      return ele;
    });

    const stockNameCheckArr = stockContent.map((ele) => {
      const stockNameCheck =
        ele.name === undefined || ele.name === null ? '' : ele.name;

      return stockNameCheck;
    });

    if (open === true && stockNameCheckArr.includes('')) {
      window.alert('必須項目を入力してください');
    } else {
      const id = data
        ? data.propertiesStockId
          ? data.propertiesStockId
          : ''
        : '';

      const createdStocks = await MutateStock(id, {
        majorNum: stockMajorNum,
        inputFlag: true,
        noCheck: noCheckState,
      });

      const newStockContent = open === false ? [] : stockContent;

      const newContent = await Promise.all(
        newStockContent.map(async (ele, index) => {
          const contentItem = await MutateStockContentAndGet(
            ele.id,
            index + 1,
            ele.name || '',
            ele.customerNumber || '',
            createdStocks,
          );

          return contentItem;
        }),
      );

      logger.info(newContent);

      await MutateProperties(propertiesId, {
        userId: user.attributes.sub,
        stock: createdStocks,
      });
      navigate(link);
    }
  };

  const noCheckFunc = async () => {
    await DeleteStockContent();
    const noCheckId = data
      ? data.propertiesStockId
        ? data.propertiesStockId
        : ''
      : '';
    const createdStocksByNoCheck = await MutateStock(noCheckId, {
      majorNum: stockMajorNum,
      inputFlag: true,
      noCheck: true,
    });

    await MutateProperties(propertiesId, {
      userId: user.attributes.sub,
      stock: createdStocksByNoCheck,
    });

    navigate('../lifeInsurance');
  };

  return (
    <>
      {isDialogShow ? (
        <Dialog
          dialogText="入力内容を削除しますがよろしいですか？"
          dialogYesFunc={cardDeleteAll}
          dialogNoFunc={toggelDialog}
        />
      ) : (
        ''
      )}
      <div className="stockTop">
        <div className="stock">
          <div className="pc-only realEstateParam1">
            <Param6Checkmark
              StyleClass="styleParam4"
              key="stock_pc"
              checkMarkSize6
              propertyData={data}
            />
          </div>
          <RadioCustomSingle
            title="証券会社へ取引口座を開設していますか？"
            name="stock"
            yesFunc={() => {
              setOpen(true);
              setNoCheckState(false);
            }}
            noFunc={noCheckFunc}
            yesCheck={yesCheckState}
            noCheck={noCheckState}
          />
        </div>
        <div
          className={`stockFormCardArea ${open ? 'stockopen' : 'stockclose'}`}
        >
          <div>
            {stockContent.map((ele, index, theForms) => (
              <StockFormCard
                key={ele.id}
                title="株式・債券"
                form={ele.id}
                minusBtnView={theForms.length !== 1}
                subNum={index + 1}
                stockValue={stockNameArr[index]}
                customerNumberValue={customerNumberArr[index]}
                updateStockContent={updateStockContent}
                subjectId={ele.id}
                deleteCard={deleteCard}
              />
            ))}
          </div>
          <button type="button" className="plusBtn-wrapper" onClick={addcard}>
            <PlusBtn />
          </button>
        </div>
      </div>
      <RequireNextReturn
        returnFunc={async () => {
          await save('../deposits');
        }}
        nextFunc={async () => {
          await save('../lifeInsurance');
        }}
        deleteFunc={toggelDialog}
      />
    </>
  );
};

export default StockTemp;
