/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import { FC, useState, ChangeEvent } from 'react';
import { Properties, LifeInsuranceContent, Receiver } from 'models';
import { useQuery } from 'react-query';
import { usePropertiesItem, useQueryContents } from 'hooks';
import { useNavigate } from 'react-router-dom';
import nonNullable from 'utils/nonNullable';
import { CognitoUserExt } from 'domains/cognite/user';
import { MutateProperties } from 'domains/cognite/services/manageProperties';
import {
  DeleteLifeInsurance,
  MutateLifeInsurance,
} from 'domains/cognite/services/manageLifeInsurance';
import Dialog from 'common/apply/organisms/dialog';
import {
  MutateLifeInsuranceContentAndGet,
  GetLifeInsuranceContent,
  DeleteLifeInsuranceContent,
  GetLifeInsuranceContentByParent,
  DeleteLifeInsuranceContentById,
} from 'domains/cognite/services/manageLifeInsuranceContent';
import {
  DeleteReceiver,
  GetReceiver,
  GetReceiverByParent,
  MutateReceiverAndGetReceiver,
  DeleteReceiverById,
} from 'domains/cognite/services/manageReceiver';
import LifeInsurancePlusBtn from '../atoms/lifeInsurancePlusBtn';
import RadioCustomSingle from '../molecules/radioCustomSingle';
import LifeInsuranceFormCard from '../organisms/lifeInsuranceFormCard';
import RequireNextReturn from '../molecules/requireNextReturn';
import Param6Checkmark from '../atoms/paramCheckmarkPc';
import './lifeInsuranceTemp.css';

type Props = {
  data: Properties | undefined;
  user: CognitoUserExt;
};

const LifeInsuranceTemp: FC<Props> = (props) => {
  const { data, user } = props;

  const { lifeInsurance } = usePropertiesItem(data);
  const { data: lifeInsuranceContentByQuery } = useQuery(
    ['lifeInsurance_content'],
    () => GetLifeInsuranceContentByParent(lifeInsurance),
    {
      cacheTime: 200,
    },
  );

  const [isDialogShow, setIsDialogShow] = useState(false);

  const toggelDialog = () => {
    setIsDialogShow(!isDialogShow);
  };

  // 初期状態のオブジェクト
  const initialLifeInsuranceContentArrData: LifeInsuranceContent[] = [
    {
      id: '0',
      subNum: null,
      insuranceCompanyName: '',
      insuranceName: '',
      insuranceAmount: '',
      lifeInsurance: undefined,
      receiver: undefined,
    },
  ];

  const initialLifeInsuranceContentArr =
    lifeInsuranceContentByQuery === undefined ||
    (lifeInsuranceContentByQuery !== undefined &&
      lifeInsuranceContentByQuery.length === 0)
      ? initialLifeInsuranceContentArrData
      : lifeInsuranceContentByQuery;

  const [lifeInsuranceContent, setLifeInsuranceContent] = useState<
    LifeInsuranceContent[]
  >(initialLifeInsuranceContentArr);

  // 初期状態のオブジェクト（受取人）
  const initialReceiverArrData: Receiver[][] = [
    [
      {
        id: '0',
        cardNum: null,
        name: '',
        ratio: '',
        lifeInsuranceContent: undefined,
      },
    ],
  ];

  const receiverDataGetFunc = async () => {
    if (
      lifeInsuranceContent.length === 1 &&
      lifeInsuranceContent[0].id === '0'
    ) {
      return initialReceiverArrData;
    }

    const lifeInsuranceReceiver = Promise.all(
      lifeInsuranceContent.map(async (ele) => {
        const receiverData = await GetReceiverByParent(ele);

        return receiverData;
      }),
    );

    return lifeInsuranceReceiver;
  };

  const { data: lifeInsuranceReceiverByQuery } = useQuery(
    ['lifeInsurance_receiver'],
    receiverDataGetFunc,
    {
      cacheTime: 200,
    },
  );

  const initialReceiverContentArr =
    lifeInsuranceReceiverByQuery === undefined
      ? initialReceiverArrData
      : lifeInsuranceReceiverByQuery;

  const [receiverContent, setReceiverContent] = useState(
    initialReceiverContentArr,
  );

  const updateLifeInsuranceContent = (
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement>,
    target: string,
  ) => {
    const newLifeInsuranceContent = lifeInsuranceContent.map((ele) => {
      if (ele.id === subjectKey) {
        return {
          id: ele.id,
          subNum: ele.subNum,
          insuranceCompanyName:
            target === 'insuranceCompanyName'
              ? event.target.value
              : ele.insuranceCompanyName === null ||
                ele.insuranceCompanyName === undefined
              ? ''
              : ele.insuranceCompanyName,
          insuranceName:
            target === 'insuranceName'
              ? event.target.value
              : ele.insuranceName === null || ele.insuranceName === undefined
              ? ''
              : ele.insuranceName,
          insuranceAmount:
            target === 'insuranceAmount'
              ? event.target.value
              : ele.insuranceAmount === null ||
                ele.insuranceAmount === undefined
              ? ''
              : ele.insuranceAmount,
          lifeInsurance: ele.lifeInsurance,
          receiver: ele.receiver,
        };
      }

      return {
        id: ele.id,
        subNum: ele.subNum,
        insuranceCompanyName: ele.insuranceCompanyName,
        insuranceName: ele.insuranceName,
        insuranceAmount: ele.insuranceAmount,
        lifeInsurance: ele.lifeInsurance,
        receiver: ele.receiver,
      };
    });

    return setLifeInsuranceContent(newLifeInsuranceContent);
  };

  const addLifeInsuranceCard = () => {
    const randomId = Math.random().toString();
    const addlifeInsuranceContentObject = {
      id: randomId,
      subNum: null,
      insuranceCompanyName: '',
      insuranceName: '',
      insuranceAmount: '',
      lifeInsurance: undefined,
      receiver: undefined,
    };

    setLifeInsuranceContent([
      ...lifeInsuranceContent,
      addlifeInsuranceContentObject,
    ]);

    const addReceiverContentArr = [
      {
        id: '0',
        cardNum: null,
        name: '',
        ratio: '',
        lifeInsuranceContent: undefined,
      },
    ];

    setReceiverContent([...receiverContent, addReceiverContentArr]);
  };

  const deleteLifeInsuranceCard = (deleteSubjectId: string) => {
    const lifeInsuranceContentOnlyId = lifeInsuranceContent.map(
      (ele) => ele.id,
    );
    const deleteSubjectLifeInsuranceContentOrder =
      lifeInsuranceContentOnlyId.indexOf(deleteSubjectId);
    const newLifeInsuranceContentAfterDelete = lifeInsuranceContent.filter(
      (ele) => ele.id !== deleteSubjectId,
    );
    const newReceiverContentAfterDelete = receiverContent.filter(
      (ele) =>
        receiverContent.indexOf(ele) !== deleteSubjectLifeInsuranceContentOrder,
    );

    setLifeInsuranceContent(newLifeInsuranceContentAfterDelete);
    setReceiverContent(newReceiverContentAfterDelete);
  };

  const updateReceiverContent = (
    sortKey: string,
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement>,
    target: string,
  ) => {
    const newReceiverContentArr = receiverContent.map((ele, index) => {
      const lifeInsuranceContentId = lifeInsuranceContent.map((e) => e.id);
      const lifeInsuranceContentOrder = lifeInsuranceContentId.indexOf(sortKey);
      if (index === lifeInsuranceContentOrder) {
        const newReceiverContent = ele.map((receiverEle) => {
          if (receiverEle.id === subjectKey) {
            return {
              id: receiverEle.id,
              cardNum: receiverEle.cardNum,
              name:
                target === 'name'
                  ? event.target.value
                  : receiverEle.name === null || receiverEle.name === undefined
                  ? ''
                  : receiverEle.name,
              ratio:
                target === 'ratio'
                  ? event.target.value
                  : receiverEle.ratio === null ||
                    receiverEle.ratio === undefined
                  ? ''
                  : receiverEle.ratio,
              lifeInsuranceContent: receiverEle.lifeInsuranceContent,
            };
          }

          return {
            id: receiverEle.id,
            cardNum: receiverEle.cardNum,
            name: receiverEle.name,
            ratio: receiverEle.ratio,
            lifeInsuranceContent: receiverEle.lifeInsuranceContent,
          };
        });

        return newReceiverContent;
      }
      const noChangeReceiverContent = ele.map((e) => ({
        id: e.id,
        cardNum: e.cardNum,
        name: e.name,
        ratio: e.ratio,
        lifeInsuranceContent: e.lifeInsuranceContent,
      }));

      return noChangeReceiverContent;
    });

    return setReceiverContent(newReceiverContentArr);
  };

  const addReceiverCard = (subjectId: string) => {
    const receiverRandomId = Math.random().toString();
    const addReceiverContentObject = {
      id: receiverRandomId,
      cardNum: null,
      name: '',
      ratio: '',
      lifeInsuranceContent: undefined,
    };
    const lifeInsuranceContentId = lifeInsuranceContent.map((e) => e.id);
    const lifeInsuranceContentOrder = lifeInsuranceContentId.indexOf(subjectId);
    const newReceiverContentArr = receiverContent.map((ele, index) => {
      if (index === lifeInsuranceContentOrder) {
        return [...ele, addReceiverContentObject];
      }

      return ele;
    });
    setReceiverContent(newReceiverContentArr);
  };

  const deleteReceiverCard = (sortKey: string, subjectKey: string) => {
    const lifeInsuranceContentId = lifeInsuranceContent.map((e) => e.id);
    const lifeInsuranceContentOrder = lifeInsuranceContentId.indexOf(sortKey);
    const newReceiverContentArrAfterDelete = receiverContent.map(
      (ele, index) => {
        if (index === lifeInsuranceContentOrder) {
          return ele.filter((e) => e.id !== subjectKey);
        }

        return ele;
      },
    );

    return setReceiverContent(newReceiverContentArrAfterDelete);
  };

  // はい、いいえの初期状態管理
  const openOrClose = lifeInsuranceContent[0].id !== '0';
  const [open, setOpen] = useState(openOrClose);
  const yesCheck = lifeInsuranceContent[0].id !== '0';
  const [yesCheckState, setYesCheckState] = useState(yesCheck);
  const noCheck = !!(
    lifeInsurance &&
    lifeInsurance.noCheck &&
    lifeInsurance.noCheck === true
  );
  const [noCheckState, setNoCheckState] = useState(noCheck);

  // 保険会社名の有無のチェック
  const insuranceCompanyArr = lifeInsuranceContent.map((ele) => {
    const insuranceCompany =
      ele.insuranceCompanyName === undefined ||
      ele.insuranceCompanyName === null
        ? ''
        : ele.insuranceCompanyName;

    return insuranceCompany;
  });

  // 保険の名称の有無のチェック
  const insuranceNameArr = lifeInsuranceContent.map((ele) => {
    const insuranceName =
      ele.insuranceName === undefined || ele.insuranceName === null
        ? ''
        : ele.insuranceName;

    return insuranceName;
  });

  // 保険金額の有無のチェック
  const insuranceAmountArr = lifeInsuranceContent.map((ele) => {
    const insuranceAmount =
      ele.insuranceAmount === undefined || ele.insuranceAmount === null
        ? ''
        : ele.insuranceAmount;

    return insuranceAmount;
  });

  const propertiesId = data ? data.id : '';

  const { lifeInsuranceMajorNum } = useQueryContents(
    'lifeInsurance_check',
    data,
  );

  const cardDeleteAll = async () => {
    await DeleteLifeInsuranceContent();
    await DeleteReceiver();
    await DeleteLifeInsurance();
    await MutateProperties(propertiesId, {
      userId: user.attributes.sub,
      lifeInsurance: undefined,
    });

    setOpen(false);
    setYesCheckState(false);
    window.location.reload();
  };

  const navigate = useNavigate();

  const save = async (link: string) => {
    const reservedLifeInsuranceContent = await GetLifeInsuranceContent();
    const lifeInsuranceContentId = lifeInsuranceContent.map((ele) => ele.id);
    reservedLifeInsuranceContent.map((ele) => {
      if (lifeInsuranceContentId.includes(ele.id) === false) {
        return DeleteLifeInsuranceContentById(ele.id);
      }

      return ele;
    });

    const reservedReceiverContent = await GetReceiver();
    const receiverContentFlat = receiverContent.flat();
    const receiverContentId = receiverContentFlat.map((ele) => ele.id);
    reservedReceiverContent.map((ele) => {
      if (receiverContentId.includes(ele.id) === false) {
        return DeleteReceiverById(ele.id);
      }

      return ele;
    });
    const insuranceCompanyCheckArr = lifeInsuranceContent.map((ele) => {
      const insuranceCompanyCheck =
        ele.insuranceCompanyName === undefined ||
        ele.insuranceCompanyName === null
          ? ''
          : ele.insuranceCompanyName;

      return insuranceCompanyCheck;
    });
    const receiverCheckArr = receiverContentFlat.map((ele) => {
      const receiverNameCheck =
        ele.name === undefined || ele.name === null ? '' : ele.name;

      return receiverNameCheck;
    });

    if (
      open === true &&
      (insuranceCompanyCheckArr.includes('') || receiverCheckArr.includes(''))
    ) {
      window.alert('必須項目を入力してください');
    } else {
      const id = data
        ? data.propertiesLifeInsuranceId
          ? data.propertiesLifeInsuranceId
          : ''
        : '';
      const createdLifeInsurance = await MutateLifeInsurance(id, {
        majorNum: lifeInsuranceMajorNum,
        inputFlag: true,
        noCheck: noCheckState,
      });

      const newLifeInsuranceContent =
        open === false ? [] : lifeInsuranceContent;

      const newContent = await Promise.all(
        newLifeInsuranceContent.map(async (ele, index) => {
          const contentItem = await MutateLifeInsuranceContentAndGet(
            ele.id,
            index + 1,
            ele.insuranceCompanyName || '',
            ele.insuranceName || '',
            ele.insuranceAmount || '',
            createdLifeInsurance,
          );

          return contentItem;
        }),
      );

      console.log(newContent);

      const newReceiverContent = open === false ? [[]] : receiverContent;

      const newReceiverContentArr = newReceiverContent.map(
        (receiverContentEle, index) => {
          const newReceiverItem = receiverContentEle.map(async (ele) => {
            const newReceiverContentData = await MutateReceiverAndGetReceiver(
              ele.id,
              ele.cardNum || 0,
              ele.name || '',
              ele.ratio || '',
              newContent[index],
            );

            return newReceiverContentData;
          });

          return newReceiverItem;
        },
      );

      console.log(newReceiverContentArr);

      void MutateProperties(propertiesId, {
        userId: user.attributes.sub,
        lifeInsurance: createdLifeInsurance,
      });
      navigate(link);
    }
  };

  const noCheckFunc = async () => {
    await DeleteLifeInsuranceContent();
    const noCheckId = data
      ? data.propertiesLifeInsuranceId
        ? data.propertiesLifeInsuranceId
        : ''
      : '';
    const createdLifeInsuranceByNoCheck = await MutateLifeInsurance(noCheckId, {
      majorNum: lifeInsuranceMajorNum,
      inputFlag: true,
      noCheck: true,
    });

    await MutateProperties(propertiesId, {
      userId: user.attributes.sub,
      lifeInsurance: createdLifeInsuranceByNoCheck,
    });

    navigate('../bonds');
  };

  return (
    <>
      {isDialogShow ? (
        <Dialog
          dialogText="入力内容を削除しますがよろしいですか？"
          dialogYesFunc={cardDeleteAll}
          dialogNoFunc={toggelDialog}
        />
      ) : (
        ''
      )}
      <div className="lifeInsuranceTop">
        <div className="lifeInsurance">
          <div className="pc-only realEstateParam1">
            <Param6Checkmark
              StyleClass="styleParam5"
              key="lifeInsurance_pc"
              checkMarkSize6
              propertyData={data}
            />
          </div>
          <RadioCustomSingle
            title="あなたを被保険者とする生命保険に加入していますか？"
            name="lifeInsurance"
            yesFunc={() => {
              setOpen(true);
              setNoCheckState(false);
            }}
            noFunc={noCheckFunc}
            yesCheck={yesCheckState}
            noCheck={noCheckState}
          />
        </div>
        <div
          className={`lifeInsuranceFormCardArea ${
            open ? 'lifeInsuranceopen' : 'lifeInsuranceclose'
          }`}
        >
          <div>
            {lifeInsuranceContent.map((ele, index, theForms) => {
              const receiverArr =
                lifeInsuranceReceiverByQuery &&
                lifeInsuranceReceiverByQuery.length !== 0
                  ? lifeInsuranceReceiverByQuery[index]
                    ? lifeInsuranceReceiverByQuery[index].filter(nonNullable)
                    : []
                  : [];

              return (
                <LifeInsuranceFormCard
                  key={ele.id}
                  title="保険"
                  form={ele.id}
                  minusBtnView={theForms.length !== 1}
                  subNum={index + 1}
                  insuranceCompanyValue={insuranceCompanyArr[index]}
                  insuranceNameValue={insuranceNameArr[index]}
                  insuranceAmountValue={insuranceAmountArr[index]}
                  receiverArr={receiverArr}
                  updateLifeInsuranceContent={updateLifeInsuranceContent}
                  subjectId={ele.id}
                  deleteLifeInsuranceCard={deleteLifeInsuranceCard}
                  updateReceiverContent={updateReceiverContent}
                  sortId={ele.id}
                  subjectReceiverContentId={receiverContent[index]}
                  addReceiverCard={addReceiverCard}
                  deleteReceiverCard={deleteReceiverCard}
                  receiverContentDataArr={receiverContent[index]}
                />
              );
            })}
          </div>
          <button
            type="button"
            className="plusBtn-wrapper"
            onClick={addLifeInsuranceCard}
          >
            <LifeInsurancePlusBtn text="生命保険会社を追加" />
          </button>
        </div>
      </div>
      <RequireNextReturn
        returnFunc={async () => {
          await save('../stock');
        }}
        nextFunc={async () => {
          await save('../bonds');
        }}
        deleteFunc={toggelDialog}
      />
    </>
  );
};

export default LifeInsuranceTemp;
