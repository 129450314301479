import { Paragraph, TextRun } from 'docx';
import { Executor } from 'domains/apigateway/services/get-Executor';
import { AssignExecutorNotOnTheList } from 'models';
// import birthdayToJapanese from 'utils/birthdayToJapanese';

const executorView = (
  executor: Executor | undefined,
  executorNotList: AssignExecutorNotOnTheList | undefined,
): Paragraph[] => {
  if (!executor) {
    if (!executorNotList) {
      return [
        new Paragraph({
          children: [
            // new TextRun({
            //   text: `住所 ○○○○○○○○`, // 住所
            // }),
            new TextRun({
              text: '遺言執行士 ○○ ○○（住所；○○県○○市○○ □丁目□番□号 生年月日；○○○○/○○/○○）',
              break: 1,
            }),
          ],
          style: 'GrandChild',
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: '',
            }),
          ],
          style: 'Child',
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: 'なお、遺言執行者が病気等により遺言執行を行うことができない場合は、次の者を遺言執行者に指定する。',
            }),
          ],
          style: 'Child',
        }),
      ];
    }

    const { name, birth, address } = executorNotList;

    const dateOfBirth = new Date(birth || '');
    // const japaneseBirthday = birthdayToJapanese(new Date(birth || ''));
    const japaneseBirthday = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {
      era: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(dateOfBirth);

    return [
      new Paragraph({
        children: [
          // new TextRun({
          //   text: `住所 ${address || ''}`, // 住所
          // }),
          // new TextRun({
          //   text: `氏名 ${name || ''} ${japaneseBirthday}`, // 氏名
          //   break: 1,
          // }),
          new TextRun({
            text: `遺言執行士 ${name || '○○ ○○'}（住所；${
              address || ''
            } 生年月日；${japaneseBirthday}）`,
            break: 1,
          }),
        ],
        style: 'GrandChild',
      }),
      new Paragraph({
        children: [
          new TextRun({
            text: '',
          }),
        ],
        style: 'Child',
      }),
      new Paragraph({
        children: [
          new TextRun({
            text: 'なお、遺言執行者が病気等により遺言執行を行うことができない場合は、次の者を遺言執行者に指定する。',
          }),
        ],
        style: 'Child',
      }),
    ];
  }

  const { name, prefecture, city, addressNumbers, building, birthdate } =
    executor;

  // const japaneseBirthday = birthdayToJapanese(new Date(birthdate));

  const dateOfBirth = new Date(birthdate);
  const japaneseBirthday = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {
    era: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(dateOfBirth);

  return [
    new Paragraph({
      children: [
        // new TextRun({
        //   text: `住所 ${prefecture} ${city} ${addressNumbers} ${building}`, // 住所
        // }),
        // new TextRun({
        //   text: `氏名 ${name} ${japaneseBirthday}`, // 氏名
        //   break: 1,
        // }),
        new TextRun({
          text: `遺言執行士 ${
            name || '○○ ○○'
          }（住所；${prefecture} ${city} ${addressNumbers} ${building} 生年月日；${japaneseBirthday}）`,
          break: 1,
        }),
      ],
      style: 'GrandChild',
    }),
    new Paragraph({ style: 'Default' }),
    new Paragraph({
      children: [
        new TextRun({
          text: 'なお、遺言執行者が病気等により遺言執行を行うことができない場合は、次の者を遺言執行者に指定する。',
        }),
      ],
      style: 'Child',
    }),
  ];
};

export default executorView;
