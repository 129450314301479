/* eslint-disable no-nested-ternary */
import { FC, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';
import {
  sendEmailForApplyExecutor,
  SendEmailForApplyExecutorReqBody,
} from 'domains/apigateway/services/sendEmail';
import PutS3 from 'domains/aws/services/put-s3';
import NormalBtn from '../../../executor/components/atoms/normalBtn';
import './executorProcedureConfirmContent.css';

type LocationState = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  year: number;
  month: number;
  date: number;
  prefecture: string;
  city: string;
  houseNumber: string;
  buildingName: string;
  check1: boolean;
  check2: boolean;
  check3: boolean;
  check4: boolean;
  check5: boolean;
  check6: boolean;
  certificate1: string;
  certificate2: string;
  certificate3: string;
  certificate4: string;
  certificate5: string;
  certificate6: string;
  listingArea: string[];
  listingAreaPrice: number;
  acceptedFiles: File[];
};

const ExecutorProcedureConfirmContent: FC = () => {
  const location = useLocation();
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    year,
    month,
    date,
    prefecture,
    city,
    houseNumber,
    buildingName,
    check1,
    check2,
    check3,
    check4,
    check5,
    check6,
    certificate1,
    certificate2,
    certificate3,
    certificate4,
    certificate5,
    certificate6,
    listingArea,
    listingAreaPrice,
    acceptedFiles,
  } = location.state as LocationState;

  const [paths, setPaths] = useState<null | string[]>(null);

  useEffect(() => {
    const acceptedFilesPath = acceptedFiles.map((ele) =>
      URL.createObjectURL(ele),
    );
    setPaths(acceptedFilesPath);
  }, [acceptedFiles]);

  const name = `${firstName} ${lastName}`;
  const birthday = `${year}/${month}/${date}`;
  const address = `${prefecture} ${city} ${houseNumber} ${buildingName}`;

  // 2024/01/25 listingAreaPriceFormattedをコメントアウト
  // TODO: 指示があったら元に戻す
  // const listingAreaPriceFormatted =
  //   listingAreaPrice < 1000000
  //     ? listingAreaPrice.toLocaleString()
  //     : '1,000,000';

  const passport1 = check1 ? '遺言執行士 ' : '';
  const passport2 = check2 ? '弁護士 ' : '';
  const passport3 = check3 ? '司法書士 ' : '';
  const passport4 = check4 ? '行政書士 ' : '';
  const passport5 = check5 ? '税理士 ' : '';
  const passport6 = check6 ? '宅地建物取引士' : '';

  const passport =
    passport1 + passport2 + passport3 + passport4 + passport5 + passport6;

  const passportCheckArr = [
    {
      passport: '遺言執行士',
      check: check1,
      certificate: certificate1,
    },
    {
      passport: '弁護士',
      check: check2,
      certificate: certificate2,
    },
    {
      passport: '司法書士',
      check: check3,
      certificate: certificate3,
    },
    {
      passport: '行政書士',
      check: check4,
      certificate: certificate4,
    },
    {
      passport: '税理士',
      check: check5,
      certificate: certificate5,
    },
    {
      passport: '宅地建物取引士',
      check: check6,
      certificate: certificate6,
    },
  ];

  const passportAndCertificate = passportCheckArr
    .filter((ele) => ele.check)
    .map((ele) => ({
      passport: ele.passport,
      certificate: ele.certificate,
    }));

  const state = {
    firstName,
    lastName,
    email,
    phoneNumber,
    year,
    month,
    date,
    prefecture,
    city,
    houseNumber,
    buildingName,
    check1,
    check2,
    check3,
    check4,
    check5,
    check6,
    certificate1,
    certificate2,
    certificate3,
    certificate4,
    certificate5,
    certificate6,
    acceptedFiles,
    listingArea,
    listingAreaPrice,
  };

  const sendEmail = async () => {
    const fileNamesForSend = acceptedFiles.map((ele) => {
      const originalFileName = ele.name;
      const fileExtension = originalFileName.split('.').pop() || '.png';
      const id = uuidV4();

      const newFileName = `${id}.${fileExtension}`;

      return newFileName;
    });

    const body: SendEmailForApplyExecutorReqBody = {
      name,
      email,
      phoneNumber,
      address,
      birthday,
      passportAndCertificate,
      area: listingArea.join(','),
      // 2024/01/25 listingAreaPriceFormattedをコメントアウト
      // TODO: 指示があったら元に戻す
      // price: listingAreaPriceFormatted,
      fileParams: fileNamesForSend,
    };

    await Promise.all(
      acceptedFiles.map(async (ele, index) => {
        await PutS3(fileNamesForSend[index], ele, 'public');
      }),
    );

    await sendEmailForApplyExecutor(body);
  };

  return (
    <div className="executorProcedureConfirm">
      <div>
        <p>遺言士登録申請フォーム</p>
        <table className="executorProcedureConfirmTable">
          <tr>
            <th>お名前</th>
            <td>{name}</td>
          </tr>
          <tr>
            <th>メールアドレス</th>
            <td>{email}</td>
          </tr>
          <tr>
            <th>電話番号</th>
            <td>{phoneNumber}</td>
          </tr>
          <tr>
            <th>生年月日</th>
            <td>{birthday}</td>
          </tr>
          <tr>
            <th>住所</th>
            <td>{address}</td>
          </tr>
          <tr>
            <th>所持資格</th>
            <td>{passport}</td>
          </tr>
          <tr className="executor-file-upload-tr">
            <th>写真</th>
            <td>
              {paths &&
                paths.map((path) => (
                  <div>
                    <img src={path} alt="" />
                  </div>
                ))}
            </td>
          </tr>
          <tr>
            <th>所持資格の登録番号または証明書番号</th>
            <td>
              <p>{certificate1}</p>
              <p>{certificate2}</p>
              <p>{certificate3}</p>
              <p>{certificate4}</p>
              <p>{certificate5}</p>
              <p>{certificate6}</p>
            </td>
          </tr>
          <tr>
            <th>名簿に掲載する活動地域</th>
            <td>
              {listingArea.length !== 0 ? (
                listingArea.map((ele) => <li>{ele}</li>)
              ) : (
                <p>選択なし</p>
              )}
            </td>
          </tr>
          {/* 2024/01/25 コメントアウト */}
          {/* TODO: 指示があったら元に戻す */}
          {/* ここから */}
          {/* <tr>
            <th>
              名簿掲載料
              <br className="pc-only" />
              (1年間分)
            </th>
            <td>{listingAreaPriceFormatted}円</td>
          </tr> */}
          {/* ここまで */}
          <tr>
            <th>ご利用規約</th>
            <td>利用規約に同意する</td>
          </tr>
        </table>
        <div className="executorProcedureConfirmBtn">
          <NormalBtn
            title="修正する"
            className="normalBtn"
            href="/apply/procedureform"
            state={state}
          />
          <NormalBtn
            title="申請内容を送信する"
            className="normalBtn"
            href="/apply/complete"
            func={sendEmail}
          />
        </div>
      </div>
    </div>
  );
};

export default ExecutorProcedureConfirmContent;
