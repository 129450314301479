export const getFormatDate1 = (inputDate: string): Date => {
  const date = new Date(inputDate);
  const getYear = date.getFullYear();
  const getMonth = date.getMonth();
  const getDay = date.getDate();
  const getTodayDate = new Date(getYear, getMonth, getDay);

  return getTodayDate;
};

export const getFormatDate2 = (inputDate: string): Date => {
  const date = new Date(inputDate);
  const getYear = date.getFullYear();
  const getMonth = date.getMonth();
  const getDay = date.getDate() + 1;
  const getTodayDate = new Date(getYear, getMonth, getDay);

  return getTodayDate;
};

export const formatDateToCleanStr = (date: Date): string => {
  const yyyy = date.getFullYear();
  const mm = date.getMonth() + 1;
  const dd = date.getDate();

  return `${yyyy}-${mm}-${dd}`;
};

// YYYY-MM-DD形式の文字列をYYYY年MM月DD日形式に変換する
// ただし、MM、DDは0がある場合は0を削除する
export const formatDateToJapaneseStr = (date: string): string => {
  // dateがYYYY-MM-DD形式でない場合はそのまま返す
  // matchは使わない
  if (date.length !== 10) return date;

  const yyyy = date.slice(0, 4);
  const mm = date.slice(5, 7).replace(/0/, '');
  const dd = date.slice(8, 10).replace(/0/, '');

  return `${yyyy}年${mm}月${dd}日`;
};
