import { FC } from 'react';
import { useQuery } from 'react-query';
import { GetS3 } from 'domains/aws/services/get-s3';
import pdfIcon from '../images/pdf-icon.svg';
import './executorPdfDownload.css';

type Props = {
  userId: string;
  pdfName: string;
};

const ExecutorPdfDownload: FC<Props> = (props) => {
  const { userId, pdfName } = props;
  const getPdfFromS3 = async () => {
    const theFile = await GetS3(`遺言書_${userId}.pdf`, 'public');

    return theFile;
  };
  const { data: fileUrl } = useQuery(['download_pdf', userId], getPdfFromS3);

  return (
    <a href={fileUrl} className={pdfName}>
      <img src={pdfIcon} alt="" />
      PDF出力
    </a>
  );
};

export default ExecutorPdfDownload;
