import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Allocation } from 'models';
import { QueryAllocation } from 'domains/cognite/services/manageAllocation';
import { CognitoUserExt } from 'domains/cognite/user';
import { logger } from 'utils/logger';

type UseQueryAllocation = (
  key: string,
  user: CognitoUserExt | undefined,
) => {
  data: Allocation | undefined;
  isFetching: boolean;
};

const useQueryAllocation: UseQueryAllocation = (
  key: string,
  user: CognitoUserExt | undefined,
) => {
  if (!user) {
    logger.error(user);
    throw Error;
  }
  const [enabled, setenabled] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setenabled(true);
    }, 0.15 * 1000);

    return () => {
      setenabled(false);
      clearTimeout(timeoutId);
    };
  }, [key]);

  const QueryUserAllocation = async () => {
    const allocation = await QueryAllocation(user.attributes.sub);

    return allocation;
  };

  const { data, isFetching } = useQuery([key], QueryUserAllocation, {
    cacheTime: 200,
    enabled,
  });

  return {
    data,
    isFetching,
  };
};

export default useQueryAllocation;
