import { useQuery } from 'react-query';
import { GetUser } from 'domains/cognite/services/manageUser';
import { AssignRelationship, AssignStatus } from 'models';
import { QueryAssignRelationshipFromClient } from 'domains/cognite/services/manageAssignRelationship';

type UseRelationshipWithAssignee = (
  key: string,
  status: AssignStatus,
) => {
  data:
    | {
        relationship: AssignRelationship;
        clientId: string;
      }
    | undefined;
  isFetching: boolean;
};

const useRelationshipWithAssignee: UseRelationshipWithAssignee = (
  key: string,
  status: AssignStatus,
) => {
  const { data: user } = useQuery(['get_user'], GetUser);

  if (!user) {
    throw Error('認証エラー');
  }

  const getAssignee = async () => {
    const assigneeRelationshipArr = await QueryAssignRelationshipFromClient(
      user.attributes.sub,
    );

    if (
      assigneeRelationshipArr.length === 0 ||
      !assigneeRelationshipArr[0].assigneeId
    ) {
      return undefined;
    }

    const assigneeRelationship = assigneeRelationshipArr.find(
      (ele) => ele.status === status,
    );

    if (!assigneeRelationship) {
      return undefined;
    }

    return {
      relationship: assigneeRelationship,
      clientId: user.attributes.sub,
    };
  };

  const { data, isFetching } = useQuery(['assinee', key], getAssignee, {
    cacheTime: 500,
  });

  return { data, isFetching };
};

export default useRelationshipWithAssignee;
