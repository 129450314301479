import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
// import { useAuth } from 'hooks';
import FooterMenuBeforeLogin from '../templates/footerMenuBeforeLogin';
// import WillFooterMenuAfterLogin from '../templates/willFooterMenuAfterLogin';
import './footerBeforeLogin.css';
import homeIcon from '../images/homeIcon.svg';
import menuIcon from '../images/menuIcon.svg';

type Props = {
  homeLink: string;
};

const FooterBeforeLogin: FC<Props> = (props) => {
  const { homeLink } = props;

  const [position, setPosition] = useState<number | null>(0);
  const [show, setShow] = useState(false);
  const [ClassSwitch, setClassSwitch] = useState(false);
  const FooterScroll = document.getElementById('footerScroll');

  window.addEventListener('scroll', () => {
    const ScrollTop = document.documentElement.scrollTop;

    if (!FooterScroll) {
      <p>取得中</p>;
    } else if (position! < ScrollTop && !show) {
      setClassSwitch(true);
    } else {
      setClassSwitch(false);
    }

    setPosition(ScrollTop);
  });

  return (
    <>
      <footer
        className={`footerBeforeLogin ${
          ClassSwitch
            ? 'footerBeforeLoginScrollUp'
            : 'footerBeforeLoginScrollDown'
        }`}
        id="footerScroll"
      >
        <div className="footerBeforeLoginNav">
          <Link to={homeLink}>
            <img src={homeIcon} alt="ホームアイコン" />
            <p>トップ</p>
          </Link>
          <button type="button" onClick={() => setShow(!show)}>
            <img src={menuIcon} alt="メニューアイコン" />
            <p>メニュー</p>
          </button>
        </div>
      </footer>
      <FooterMenuBeforeLogin
        show={show}
        setShow={() => setShow(false)}
        homeLink={homeLink}
      />
    </>
  );
};

export default FooterBeforeLogin;
