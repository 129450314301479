/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, Suspense, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AssignRelationship, AssignStatus } from 'models';
import { UpdateAssignStatusByAssignee } from 'domains/cognite/services/manageAssignRelationship';
import { CreateChatRoomFromAssignee } from 'domains/cognite/services/manageChat';
import { getUser } from 'domains/apigateway/services/get-user';
import { ListS3 } from 'domains/aws/services/get-s3';
import {
  sendEmailForAssign,
  SendEmailForAssignReqBody,
} from 'domains/apigateway/services/sendEmail';
import ExecutorWordDownload from './executorWordDownload';
import ExecutorPdfDownload from './executorPdfDownload';
import minusBtn from '../images/minusBtn.svg';
import WillCard from './willCard';
import WillFormCard from './willFormCard';
import './willInfoCard.css';

type Props = {
  executorEmail: string;
  relationship: AssignRelationship;
};

const WillInfoCard: FC<Props> = (props) => {
  const { executorEmail, relationship } = props;
  const { clientId } = relationship;

  const navigate = useNavigate();

  const getTheClient = async () => {
    try {
      const theClient = await getUser(clientId);

      return theClient;
    } catch (error) {
      return undefined;
    }
  };

  const { data: client, error: clientError } = useQuery(
    ['willinfo_client', clientId],
    getTheClient,
  );

  const pdfExist = async () => {
    const length = await ListS3(`遺言書_${clientId}.pdf`);
    const check = !!length;

    return check;
  };

  const wordExist = async () => {
    const length = await ListS3(`遺言書_${clientId}.docx`);
    const check = !!length;

    return check;
  };

  const { data: wordExistCheck } = useQuery(
    [`word_exist_check_${clientId}`],
    wordExist,
  );
  const { data: pdfExistCheck } = useQuery(
    [`pdf_exist_check_${clientId}`],
    pdfExist,
  );

  const pdfNameCheck = pdfExistCheck ? '' : 'pdfBtnNotClick';
  const wordNameCheck = wordExistCheck ? '' : 'wordBtnNotClick';

  const [show, setShow] = useState(false);

  if (!client || clientError) {
    return <></>;
  }

  const { id, name, email, gender, age, address } = client;

  const approval = async () => {
    if (relationship.assigneeId) {
      const body: SendEmailForAssignReqBody = {
        fromEmail: executorEmail,
        toEmail: email,
        action: 'approval',
      };
      await sendEmailForAssign(body);

      await UpdateAssignStatusByAssignee(
        relationship.clientId,
        relationship.assigneeId,
        AssignStatus.APPROVAL,
      );
      await CreateChatRoomFromAssignee(relationship.clientId)
        .then(() => {
          // setShow(false);

          navigate('/executor');
          // window.location.reload();
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
  };

  const decline = async () => {
    if (relationship.assigneeId) {
      const body: SendEmailForAssignReqBody = {
        fromEmail: executorEmail,
        toEmail: email,
        action: 'decline',
      };
      await sendEmailForAssign(body);
      await UpdateAssignStatusByAssignee(
        relationship.clientId,
        relationship.assigneeId,
        AssignStatus.DECLINE,
      ).then(() => {
        setShow(false);
        window.location.reload();
      });
    }
  };

  return (
    <>
      <button type="button" onClick={() => setShow(true)}>
        <WillCard name={email} sex={gender} age={age} address={address} />
      </button>
      {show ? (
        <>
          <div
            className="willInfoCard"
            id="overlay2"
            onClick={() => setShow(false)}
          >
            <div>
              <p>申請者情報</p>
              <WillFormCard
                name={name}
                sex={gender}
                address={address}
                age={age}
              />
              <div>
                <div className="pdf-word-btn">
                  <Suspense fallback={<div>..。読み込み中</div>}>
                    <ExecutorPdfDownload
                      key={`WillInfoCard_pdf_${id}`}
                      userId={id}
                      pdfName={pdfNameCheck}
                    />
                  </Suspense>
                  <ExecutorWordDownload
                    key={`WillInfoCard_word_${id}`}
                    userId={id}
                    wordName={wordNameCheck}
                  />
                </div>
                <div className="approve-btn">
                  <button type="button" onClick={approval}>
                    承認する
                  </button>
                  <button type="button" onClick={decline}>
                    承認しない
                  </button>
                </div>
                <p>
                  ※承認するしないの通知は協会からされますが、
                  <br />
                  承認した後の連絡は遺言執行者からしてください。
                </p>
              </div>
              <div>
                <button type="button" onClick={() => setShow(false)}>
                  <img src={minusBtn} alt="minus-Button" />
                  <p>閉じる</p>
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default WillInfoCard;
