/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import { ChangeEvent, FC, useState } from 'react';
import { useExecutorsListFromAdmin } from 'hooks';
import { useQuery } from 'react-query';
// import { getExecutorFromAdmin } from 'domains/apigateway/services/get-ExecutorFromAdmin';
import './executorList.css';
import { Link } from 'react-router-dom';
import { GetEvaluationFromAssigneeId } from 'domains/cognite/services/manageEvaluation';
import { QueryAssignRelationshipFromAssignee } from 'domains/cognite/services/manageAssignRelationship';
import PaginationFunc from 'utils/paginationFunc';
import AdminNav from '../organisms/adminNav';
import PaginationLayout from '../organisms/paginationLayout';

const ExecutorList: FC = () => {
  const executorAttributes = useExecutorsListFromAdmin([
    'get_executorListFromGetData',
  ]);

  const getDatas = async () => {
    const executorAttributesAndEvaluation = Promise.all(
      executorAttributes.map(async (ele) => {
        // 評価============================================================
        const evaluationDataByFilterId = await GetEvaluationFromAssigneeId(
          ele.sub,
        );

        const evaluationDataByFilter = evaluationDataByFilterId.map((x) => {
          const evaluationData = [
            x.response!,
            x.explanation!,
            x.answer!,
            x.claritOfyPrice!,
            x.validityOfPrice!,
          ];

          return evaluationData;
        });

        const evaluations0 = evaluationDataByFilter.map((evaluation) =>
          evaluation[0] !== undefined && evaluation[0] !== null
            ? evaluation[0]
            : 0,
        );
        const evaluations1 = evaluationDataByFilter.map((evaluation) =>
          evaluation[1] !== undefined && evaluation[1] !== null
            ? evaluation[1]
            : 0,
        );
        const evaluations2 = evaluationDataByFilter.map((evaluation) =>
          evaluation[2] !== undefined && evaluation[2] !== null
            ? evaluation[2]
            : 0,
        );
        const evaluations3 = evaluationDataByFilter.map((evaluation) =>
          evaluation[3] !== undefined && evaluation[3] !== null
            ? evaluation[3]
            : 0,
        );
        const evaluations4 = evaluationDataByFilter.map((evaluation) =>
          evaluation[4] !== undefined && evaluation[4] !== null
            ? evaluation[4]
            : 0,
        );

        const evaluationsDataAlls = [
          evaluations0,
          evaluations1,
          evaluations2,
          evaluations3,
          evaluations4,
        ];

        const evaluationsDatasAll = evaluationsDataAlls.map(
          (evaluationsAll) => {
            const evaluationsAverages = evaluationsAll.reduce(
              (sum, element) => sum + element,
              0,
            );

            const evaluationsAverage =
              evaluationsAverages / evaluationDataByFilter.length;

            return Math.round(evaluationsAverage);
          },
        );

        const evaluationValue = evaluationDataByFilterId.map((ele1) => {
          const evaluationValueSum =
            (ele1.response !== undefined && ele1.response !== null
              ? ele1.response
              : 0) +
            (ele1.explanation !== undefined && ele1.explanation !== null
              ? ele1.explanation
              : 0) +
            (ele1.answer !== undefined && ele1.answer !== null
              ? ele1.answer
              : 0) +
            (ele1.claritOfyPrice !== undefined && ele1.claritOfyPrice !== null
              ? ele1.claritOfyPrice
              : 0) +
            (ele1.validityOfPrice !== undefined && ele1.validityOfPrice !== null
              ? ele1.validityOfPrice
              : 0);

          return evaluationValueSum;
        });

        const evaluationValueTotal = evaluationValue.reduce(
          (sum, element) => sum + element,
          0,
        );

        const evaluationValueTotalAve =
          evaluationValueTotal / evaluationDataByFilterId.length / 5;

        // 登録者数============================================================
        const assigneeDataByFilterId =
          await QueryAssignRelationshipFromAssignee(ele.sub);

        const assigneeDataApprovaled = assigneeDataByFilterId.filter(
          (elm) => elm.status === 'approval',
        );

        // 配列にして返す===================================================
        return {
          attributes: ele,

          evaluation:
            evaluationDataByFilterId.length !== 0 ? evaluationValueTotalAve : 0,
          evaluationArray: evaluationsDatasAll,
          approval: assigneeDataApprovaled.length,
        };
      }),
    );

    return executorAttributesAndEvaluation;
  };

  const { data: executorDatas } = useQuery(['executorList_admin'], getDatas, {
    cacheTime: 500,
  });

  if (!executorDatas) {
    throw Error();
  }

  // 作成日でソート
  const createdAtArray = executorDatas.slice().sort((a, b) => {
    const createdDateA = Date.parse(a.attributes.createdAt!);
    const createdDateB = Date.parse(b.attributes.createdAt!);

    return createdDateA < createdDateB
      ? 1
      : createdDateA > createdDateB
      ? -1
      : 0;
  });

  // 評価数でソート
  const evaluationArray = executorDatas.slice().sort((a, b) => {
    const evaluationValueA = a.evaluation;
    const evaluationValueB = b.evaluation;

    return evaluationValueA < evaluationValueB
      ? 1
      : evaluationValueA > evaluationValueB
      ? -1
      : 0;
  });

  // 年齢（生年月日）でソート
  const birthDateArray = executorDatas.slice().sort((a, b) => {
    const birthDateA = Date.parse(a.attributes.birthdate!);
    const birthDateB = Date.parse(b.attributes.birthdate!);

    return birthDateA > birthDateB ? 1 : birthDateA < birthDateB ? -1 : 0;
  });

  // 担当遺言者数でソート
  const approvalArray = executorDatas.slice().sort((a, b) => {
    const approvalValueA = a.approval;
    const approvalValueB = b.approval;

    return approvalValueA < approvalValueB
      ? 1
      : approvalValueA > approvalValueB
      ? -1
      : 0;
  });

  // ソートの条件分岐
  const [sortData, setSortData] = useState(createdAtArray);

  const changeSort = (data: ChangeEvent<HTMLSelectElement>) => {
    data.target.value === 'createdAtArray'
      ? setSortData(createdAtArray)
      : data.target.value === 'evaluationArray'
      ? setSortData(evaluationArray)
      : data.target.value === 'approvalArray'
      ? setSortData(approvalArray)
      : setSortData(birthDateArray);
  };

  const [sortUpDown, setSortUpDown] = useState(true);

  const reverseSort = () => {
    setSortData(sortData.reverse());
    setSortUpDown(!sortUpDown);
  };

  const resetReverse = () => {
    setSortUpDown(true);
  };

  const [selectedNum, setSelectedNum] = useState(0);

  const paginationData = PaginationFunc(sortData, selectedNum);

  return (
    <>
      <AdminNav />
      <div className="admin-executor-list">
        <div className="admin-executor-list-wrapper">
          <h1>遺言執行者一覧</h1>
          <div>
            <p>遺言執行者一覧</p>
            <div className="admin-exector-list-sort">
              <select
                onChange={(e) => {
                  changeSort(e);
                  resetReverse();
                }}
              >
                <option value="createdAtArray">登録順</option>
                <option value="evaluationArray">評価順</option>
                <option value="approvalArray">担当遺言者数順</option>
                <option value="birthDateArray">年齢順</option>
              </select>
              <button
                className={
                  sortUpDown === true ? 'admin-executor-sort-down-button' : ''
                }
                onClick={reverseSort}
                type="button"
              >
                {sortUpDown === true ? '降順' : '昇順'}
              </button>
            </div>
          </div>
          <div className="admin-executor-list-cards">
            {paginationData.map((executorData) => (
              <Link
                className="admin-executor-list-card"
                to="/admin/executor/detail"
                state={{
                  Name: executorData.attributes.name,
                  Address: executorData.attributes.address,
                  Prefecture:
                    executorData.attributes['custom:address_prefecture'],
                  City: executorData.attributes['custom:address_city'],
                  AddressNum: executorData.attributes['custom:address_numbers'],
                  Building: executorData.attributes['custom:address_building'],
                  Birthdate: executorData.attributes.birthdate,
                  Passport: executorData.attributes['custom:passport'],
                  PhoneNumber: executorData.attributes.phone_number,
                  Reward: executorData.attributes['custom:reward'],
                  Zoneinfo: executorData.attributes.zoneinfo,
                  Notion: executorData.attributes['custom:notion'],
                  Introduce: executorData.attributes['custom:introduce'],
                  sub: executorData.attributes.sub,
                  Email: executorData.attributes.email,
                  evaluationArray: executorData.evaluationArray,
                }}
                key={`executor_list${executorData.attributes.sub}`}
              >
                <p>{executorData.attributes.name}</p>
                <p>活動地域：{executorData.attributes.zoneinfo}</p>
                <p>所持資格 : {executorData.attributes['custom:passport']}</p>
              </Link>
            ))}
          </div>
        </div>
        <PaginationLayout
          arrayLength={sortData.length}
          setSelectedNum={setSelectedNum}
          selectedNum={selectedNum}
        />
      </div>
    </>
  );
};

export default ExecutorList;
