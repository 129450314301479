/* eslint-disable import/no-unresolved */
import { FC, Suspense } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { GetChatRoomFromClientToAdmin } from 'domains/cognite/services/manageChat';
import AdminNav from '../organisms/adminNav';
import AdminWordDownload from '../organisms/adminWordDownload';
import AdminPdfDownload from '../organisms/adminPdfDownload';
import './userSpecifics.css';

type State = {
  email: string;
  gender: string;
  age: string;
  address: string;
  sub: string;
};

const UserDetail: FC = () => {
  const location = useLocation();
  const { email, gender, age, address, sub } = location.state as State;

  const checkChatroom = async () => {
    const chatroom = await GetChatRoomFromClientToAdmin(sub);

    return !!chatroom;
  };

  const { data: chatroomCheck } = useQuery(
    ['user_chat_check', sub],
    checkChatroom,
  );

  const existChatroom = chatroomCheck ? '' : 'notClick';

  const PageDelayTheStart = () => {
    setTimeout(() => {
      window.location.href = '/admin/data/userChatView';
    }, 10);
  };

  return (
    <>
      <AdminNav />
      <div className="adminUserDetail">
        <div className="UserDetail">
          <h1>遺言者管理</h1>
          <p>遺言者詳細</p>
          <div>
            <div className="UserDetailWrap">
              <div>
                <p>メールアドレス</p>
                <p>{email}</p>
              </div>
              <div>
                <p>性別</p>
                <p>{gender}</p>
              </div>
              <div>
                <p>年代</p>
                <p>{age}</p>
              </div>
              <div>
                <p>地域</p>
                <p>{address}</p>
              </div>
              <div className="UserDetailDoneitem">
                <p>記入済みの項目</p>
                <p>不動産</p>
              </div>
            </div>
            <div className="User-Detail-output">
              <Suspense fallback={<div>...読み込み中</div>}>
                <AdminPdfDownload
                  key={`AdminPdfDownload_${sub}`}
                  userId={sub}
                />
              </Suspense>
              <AdminWordDownload
                key={`AdminWordDownload_${sub}`}
                userId={sub}
              />
            </div>
            <Link
              to="/admin/data/userChatView"
              state={{ sub }}
              onClick={() => PageDelayTheStart()}
              className={existChatroom}
            >
              遺言執行者とのメッセージを閲覧
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetail;
