import { Paragraph, TextRun } from 'docx';
import { AllocationReceiver } from 'models';
import nonNullable from 'utils/nonNullable';
import circledNumbers from 'user/components/data/circledNumbers';
import { legalHeirOrBeneficiary } from '../utils/legalHeirOrBeneficiary';

type AllocationReceiverLiabilitiesView = (
  allocationReceiver: AllocationReceiver[],
) => Paragraph[];

const allocationReceiverLiabilitiesView: AllocationReceiverLiabilitiesView = (
  allocationReceiver,
) => {
  const allocationReceiverNonNullable = allocationReceiver.filter(nonNullable);

  // 割当している人の中に、法定相続人で、割当財産がある人がいないこととを判定
  const legalHeirNotExistWithPropertiesCheck =
    !allocationReceiverNonNullable.some(
      (ele) =>
        legalHeirOrBeneficiary(ele.type) === '法定相続人' &&
        ele.allocationProperties &&
        ele.allocationProperties.length > 0,
    );

  // 割当てている人のうち、受遺者で、割当財産がある人が一人だけかどうか判定する
  const onlyOnebeneficiaryWithProperties = allocationReceiverNonNullable.filter(
    (ele) =>
      legalHeirOrBeneficiary(ele.type) === '受遺者' &&
      ele.allocationProperties &&
      ele.allocationProperties.length > 0,
  );

  if (
    legalHeirNotExistWithPropertiesCheck &&
    onlyOnebeneficiaryWithProperties.length === 1
  ) {
    return [new Paragraph({ style: 'Default' })];
  }

  const receiverHavingLiabilities = allocationReceiver.filter((ele) => {
    if (!ele.allocationProperties) {
      return false;
    }
    const propertiesOfLiabilities =
      ele.allocationProperties.filter(nonNullable);

    return propertiesOfLiabilities.some(
      (propertiesEle) => propertiesEle.category === '負債',
    );
  });

  return receiverHavingLiabilities
    .map((ele) => {
      const liabilities =
        ele && ele.allocationProperties
          ? ele.allocationProperties
              .filter(nonNullable)
              .filter(
                (propertiesEle) =>
                  propertiesEle.category && propertiesEle.category === '負債',
              )
          : null;

      const liabilitiesChildren = liabilities
        ? liabilities.map((propertiesEle) => {
            const circledNumber = circledNumbers(propertiesEle.subNum);

            return new TextRun({
              text: `相続財産目録${propertiesEle.majorNum || ''}. ${
                circledNumber || ''
              }記載の${propertiesEle.category || ''}の${
                propertiesEle.ratio || ''
              }%`,
              break: 1,
            });
          })
        : [];

      return [
        new Paragraph({
          text: `遺言者は、${
            ele.name || ''
          }に次の債務を負担させる。また、相続財産目録に記載されていない財産または負債が発見されたときは、${
            ele.name || ''
          }が全て相続し、または負担すること。`,
          style: 'numberedPara',
        }),
        new Paragraph({
          children: liabilitiesChildren,
          style: 'Child',
        }),
        new Paragraph({ style: 'Default' }),
      ];
    })
    .flat();
};

export default allocationReceiverLiabilitiesView;
