/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import { DataStore } from 'aws-amplify';
import { LifeInsuranceContent, Receiver, ReceiverMetaData } from 'models';
import { logger } from 'utils/logger';
import { ModelInit } from '@aws-amplify/datastore';

export const MutateReceiverAndGetReceiver = async (
  id: string,
  cardNum: number,
  name: string,
  ratio: string,
  lifeInsuranceContent?: LifeInsuranceContent,
): Promise<Receiver> => {
  try {
    logger.info('保険受取人の項目の作成または更新を開始します');
    const original = await DataStore.query(Receiver, id);

    if (original) {
      logger.info('保険受取人の項目の更新を開始します');
      const data = await DataStore.save(
        Receiver.copyOf(original, (updated) => {
          updated.cardNum = cardNum;
          updated.name = name;
          updated.ratio = ratio;
          updated.lifeInsuranceContent = lifeInsuranceContent;
        }),
      );
      logger.info('保険受取人の項目の更新が完了しました');

      return data;
    }
    logger.info('保険受取人の項目の作成を開始します');
    const instance = {
      cardNum,
      name,
      ratio,
      lifeInsuranceContent,
    };
    const data = await DataStore.save(new Receiver(instance));
    logger.info('保険受取人の項目の作成が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const CreateReceiverAndGetId = async (
  instance: ModelInit<Receiver, ReceiverMetaData>,
): Promise<string> => {
  try {
    logger.info('保険受取人の項目の作成を開始します');
    const data = await DataStore.save(new Receiver(instance));
    logger.debug(data);
    logger.info('保険受取人の項目の作成が完了しました');

    return data.id;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const UpdateReceiverById = async (
  id: string,
  name: string,
  ratio: string,
): Promise<void> => {
  try {
    logger.info('保険受取人の項目の更新を開始します');

    const original = await DataStore.query(Receiver, id);

    if (original) {
      logger.info(original);
      const data = await DataStore.save(
        Receiver.copyOf(original, (updated) => {
          updated.name = name;
          updated.ratio = ratio;
        }),
      );
      logger.info(data);
      logger.info('保険受取人の項目の更新が完了しました');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteReceiverById = async (id: string): Promise<void> => {
  try {
    logger.info('保険受取人の項目の削除を開始します');
    const original = await DataStore.query(Receiver, id);
    if (original) {
      logger.info(original);
      await DataStore.delete(original);
      logger.info('保険受取人の項目の削除が完了しました');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetReceiver = async (): Promise<Receiver[]> => {
  try {
    logger.info('保険受取人の項目のフェッチを開始します');
    const data = await DataStore.query(Receiver);
    logger.debug(data);
    logger.info('保険受取人の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetReceiverByParent = async (
  lifeInsuranceContent: LifeInsuranceContent | undefined,
): Promise<Receiver[]> => {
  try {
    logger.info('生命保険の受取人のフェッチを開始します');
    const data = (await DataStore.query(Receiver)).filter((c) => {
      if (
        c.lifeInsuranceContent === null ||
        c.lifeInsuranceContent === undefined ||
        !lifeInsuranceContent
      ) {
        return false;
      }
      const lifeInsuranceContentId = c.lifeInsuranceContent.id;

      return lifeInsuranceContent.id === lifeInsuranceContentId;
    });
    logger.debug(data);
    logger.info('生命保険の受取人のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteReceiver = async (): Promise<Receiver[]> => {
  try {
    logger.info('保険受取人の全項目のさくじょを開始します');
    const data = await DataStore.query(Receiver);
    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);

          return data;
        }),
      );
    }
    logger.debug(data);
    logger.info('保険受取人の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
