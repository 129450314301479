import { FC } from 'react';
// import { Link } from 'react-router-dom';
import profileLogo from '../images/profileLogo.svg';
import './profileLink.css';

// const ProfileLink: FC = () => (
//   <Link to="/confirm">
//     <div className="profile">
//       <img src={profileLogo} alt="profile-logo" />
//       <p>プロフ</p>
//     </div>
//   </Link>
// );

const ProfileLink: FC = () => (
  <div className="profile">
    <img src={profileLogo} alt="profile-logo" />
    <p>プロフ</p>
  </div>
);

export default ProfileLink;
