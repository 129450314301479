import { FC } from 'react';
import { useQuery } from 'react-query';
import { useAssignPath } from 'hooks';
import { QueryAssignExecutorNotOnTheList } from 'domains/cognite/services/manageAssignExecutorNotOnTheList';
import { Link } from 'react-router-dom';
import './otherItemExecutor.css';
import './otherItemResponsive.css';

type Props = {
  name: string;
  icon: string;
  linkName: string;
};

const OtherItemExecutor: FC<Props> = (props) => {
  const { name, icon, linkName } = props;
  const path = useAssignPath();
  const getExecutorCharge = async () => {
    const getExecutorChargeQueryContent =
      await QueryAssignExecutorNotOnTheList();
    const data = getExecutorChargeQueryContent[0];

    return data;
  };

  const { data: getQueryData, isFetching: fetchQuery } = useQuery(
    'AssignExecutorNotOnTheList',
    getExecutorCharge,
  );

  const className = getQueryData ? `otherItem ${linkName}` : 'otherItem';

  return fetchQuery ? (
    <div>
      <p>データ取得中</p>
    </div>
  ) : (
    <>
      <Link to={path} className={linkName}>
        <div className={className}>
          <img src={icon} alt="" />
          <p>{name}</p>
        </div>
      </Link>
    </>
  );
};

export default OtherItemExecutor;
