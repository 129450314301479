/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import { FC, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useQueryProperties, useQueryPropertiesQuick } from 'hooks';
import { GetUser } from 'domains/cognite/services/manageUser';
import { logger } from 'utils/logger';
import ErrorBoundary from 'ErrorBoundary';
import Spinner from 'components/molecules/Spinner';
import WillHeader from '../organisms/willHeader';
import RealEstateDescription from '../templates/realEstateDescription';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import RealEstateTemp from '../templates/realEstateTemp';
import PageDescriptionPc from '../organisms/pageDescriptionPc';
import pageData from '../data/pageData';
import PcDescriptionBackImg from '../images/PcRealEstateBack.svg';
import './realEstate.css';

const RealEstate: FC = () => {
  // useSync();
  const { data: user } = useQuery(['authenticated_user'], GetUser);
  if (!user) {
    logger.error(user);
    throw Error;
  }
  const { data } = useQueryProperties('realEstate_properties', user);
  const { data: dataQuick } = useQueryPropertiesQuick(
    'realEstate_properties_quick',
    user,
  );

  const navigate = useNavigate();

  const onClickFunc = (link: string) => {
    navigate(link);
  };

  const descriptionPc = <>{pageData[2].description}</>;

  return (
    <>
      <WillHeader title="相続財産【不動産】" />
      <div className="sp-only">
        <RealEstateDescription data={data} />
      </div>
      <ErrorBoundary>
        <Suspense fallback={<Spinner size="large" />}>
          <RealEstateTemp data={dataQuick} user={user} />
        </Suspense>
      </ErrorBoundary>
      <CommonFooter onClickFunc={onClickFunc} />
      <PageDescriptionPc
        page="RealEstateDescriptionPc"
        description={descriptionPc}
        descriptionPcImg={PcDescriptionBackImg}
      />
    </>
  );
};

export default RealEstate;
