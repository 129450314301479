/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ChangeEvent, FC, useState } from 'react';
import { useQuery } from 'react-query';
import { getFormatDate1, getFormatDate2 } from 'utils/dateFormat';
import { Bar } from 'react-chartjs-2';
import { useUsersListFromAdmin } from 'hooks';
import { GetSearchHistory } from 'domains/cognite/services/manageSearch';
import { PrefectureArray } from 'executor/components/data/prefecture';
import AdminNav from '../organisms/adminNav';
import ExecutorDataBtns from '../organisms/executorDataBtns';
import './executorSearchData.css';

const ExecutorSearchData: FC = () => {
  const { data: searchTerm } = useQuery(['get_searchTerms'], GetSearchHistory);

  if (!searchTerm) {
    throw Error();
  }

  const UserDatas = useUsersListFromAdmin([
    'get_userDatasFromAdminExecutorPagaView',
  ]);

  const [selectPrefecture, setSelectPrefecture] = useState('全国');
  const [selectAge, setSelectAge] = useState('全年代');

  const getAgeValue = (data: ChangeEvent<HTMLSelectElement>) => {
    setSelectAge(data.currentTarget.value);
  };

  const getPrefectureValue = (data: ChangeEvent<HTMLSelectElement>) => {
    setSelectPrefecture(data.currentTarget.value);
  };

  const addAddressAndAge = searchTerm.map((x) => {
    const findsameId = UserDatas.find((y) => x.userId === y.sub);

    return {
      ...x,
      address: findsameId?.address,
      age: findsameId?.['custom:ages'],
    };
  });

  // today取得（2022-01-01の型）
  const date = new Date();
  const dateYear = date.getFullYear();
  const dateMonth = `0${date.getMonth() + 1}`.slice(-2);
  const dateDay = `0${date.getDate()}`.slice(-2);
  const dateToday = `${dateYear}-${dateMonth}-${dateDay}`;

  const [inputDateString1, setInputDateString1] = useState(dateToday);
  const [inputDateString2, setInputDateString2] = useState(dateToday);

  const getDate1 = (data: ChangeEvent<HTMLInputElement>) => {
    setInputDateString1(data.currentTarget.value);
  };

  const getDate2 = (data: ChangeEvent<HTMLInputElement>) => {
    setInputDateString2(data.currentTarget.value);
  };

  const inputDate1 = getFormatDate1(inputDateString1);
  const inputDate2 = getFormatDate2(inputDateString2);

  const filteredSearchTerm = addAddressAndAge.filter((ele) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const eleCreatedAt = new Date(ele.createdAt!);

    if (inputDate1 <= eleCreatedAt && eleCreatedAt < inputDate2) {
      if (selectAge === '全年代' && selectPrefecture === '全国') {
        return ele;
      }

      if (selectAge === '全年代' && selectPrefecture === ele.address) {
        return ele;
      }

      if (selectAge === ele.age && selectPrefecture === '全国') {
        return ele;
      }

      if (selectAge === ele.age && selectPrefecture === ele.address) {
        return ele;
      }
    }

    return false;
  });

  const age = [
    '10代',
    '20代',
    '30代',
    '40代',
    '50代',
    '60代',
    '70代',
    '80代',
    '90代',
  ];

  const prefecture = PrefectureArray.map((ele) => ele.label);

  const passport = filteredSearchTerm.filter(
    (ele) => ele.searchTerm === 'passport',
  );
  const zone = filteredSearchTerm.filter((ele) => ele.searchTerm === 'zone');
  const name = filteredSearchTerm.filter((ele) => ele.searchTerm === 'name');

  const x = ['所持資格', '活動地域', '名前'];

  const filteredSearchTermLength = [passport.length, zone.length, name.length];

  const sumLength = passport.length + zone.length + name.length;

  const dammyChart = {
    // x 軸のラベル
    labels: x,
    datasets: [
      {
        label: '遺言執行者検索項目',
        // データの値
        data: filteredSearchTermLength,
        // グラフの背景色
        backgroundColor: ['rgba(255, 99, 132, 0.2)'],
        // グラフの枠線の色
        borderColor: ['rgba(255, 99, 132)'],
        // グラフの枠線の太さ
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <AdminNav />
      <div className="executor-search-data">
        <div className="executor-search-data-wrapper">
          <h1>遺言者のデータ分析</h1>
          <p>遺言執行者検索項目</p>
          <div className="executor-search-data-chart-btns">
            <div className="executor-search-data-chart">
              <div>
                <input
                  type="date"
                  value={inputDateString1}
                  onChange={(e) => {
                    getDate1(e);
                  }}
                />
                <p>〜</p>
                <input
                  type="date"
                  value={inputDateString2}
                  onChange={(e) => {
                    getDate2(e);
                  }}
                />
              </div>
              <div>
                <select
                  onChange={(e) => {
                    getAgeValue(e);
                  }}
                >
                  <option value="全年代">全年代</option>
                  {age.map((ele) => (
                    <option value={ele}>{ele}</option>
                  ))}
                </select>
                <select
                  onChange={(e) => {
                    getPrefectureValue(e);
                  }}
                >
                  <option value="全国">全国</option>
                  {prefecture.map((ele) => (
                    <option value={ele}>{ele}</option>
                  ))}
                </select>
              </div>
              <p>【遺言執行者を検索した回数は「{sumLength}」回】</p>
              <Bar data={dammyChart} />
            </div>
            <ExecutorDataBtns />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExecutorSearchData;
