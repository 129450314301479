/* eslint-disable react/require-default-props */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { State } from 'history';
import './normalBtn.css';
import './normalBtnResponsive.css';

type Props = {
  title: string;
  className: string;
  href: string;
  func?: () => void;
  state?: State;
};

const NormalBtn: FC<Props> = (props) => {
  const { title, className, href, func, state = {} } = props;

  return (
    <Link to={href} className={className} onClick={func} state={state}>
      <p className="btnTitle">{title}</p>
    </Link>
  );
};

export default NormalBtn;
