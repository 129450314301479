/* eslint-disable react/require-default-props */
import { FC } from 'react';
import './applicationConfirmBtn.css';

type Props = {
  title: string;
  func?: () => void;
};

const ApplicationConfirmBtn: FC<Props> = (props) => {
  const { title, func } = props;

  return (
    <button type="button" className="applicationConfirmBtn" onClick={func}>
      {title}
    </button>
  );
};

export default ApplicationConfirmBtn;
