import { FC } from 'react';
import { useQuery } from 'react-query';
import { GetS3, ListS3 } from 'domains/aws/services/get-s3';
import pdfIcon from '../images/pdf-icon.svg';
import './adminPdfDownload.css';

type Props = {
  userId: string;
};

const AdminPdfDownload: FC<Props> = (props) => {
  const { userId } = props;
  const getPdfFromS3 = async () => {
    const theFileExistCheck = await ListS3(`遺言書_${userId}.pdf`);
    const enableClick = theFileExistCheck ? '' : 'notClick';
    if (!theFileExistCheck) {
      return {
        fileUrl: '',
        enableClick,
      };
    }
    const fileUrl = await GetS3(`遺言書_${userId}.pdf`, 'public');

    return {
      fileUrl,
      enableClick,
    };
  };
  const { data } = useQuery(['download_pdf', userId], getPdfFromS3);

  const fileUrl = data ? data.fileUrl : '';
  const enableClick = data ? data.enableClick : 'notClick';

  return (
    <a href={fileUrl} className={enableClick}>
      <img src={pdfIcon} alt="" />
      PDF出力
    </a>
  );
};

export default AdminPdfDownload;
