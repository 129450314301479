/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import { FC, ChangeEvent } from 'react';
import { Receiver } from 'models';
import LifeInsurancePlusBtn from '../atoms/lifeInsurancePlusBtn';
import minusBtn from '../images/minusBtn.svg';
import InputItem from '../atoms/InputItem';
import LifeInsuranceReceiverFormCard from '../molecules/lifeInsuranceReceiverFormCard';
import './lifeInsuranceFormCard.css';

type Props = {
  title: string;
  form: string;
  minusBtnView: boolean;
  subNum: number;
  insuranceCompanyValue: string;
  insuranceNameValue: string;
  insuranceAmountValue: number | string;
  receiverArr: Receiver[];
  updateLifeInsuranceContent: (
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement>,
    target: string,
  ) => void;
  subjectId: string;
  deleteLifeInsuranceCard: (deleteSubject: string) => void;
  updateReceiverContent: (
    sortKey: string,
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement>,
    target: string,
  ) => void;
  sortId: string;
  subjectReceiverContentId: Receiver[];
  addReceiverCard: (subjectId: string) => void;
  deleteReceiverCard: (
    sortId: string,
    subjectReceiverContentId: string,
  ) => void;
  receiverContentDataArr: Receiver[];
};

const LifeInsuranceFormCard: FC<Props> = (props) => {
  const {
    title,
    form,
    minusBtnView,
    subNum,
    insuranceCompanyValue,
    insuranceNameValue,
    insuranceAmountValue,
    receiverArr,
    updateLifeInsuranceContent,
    subjectId,
    deleteLifeInsuranceCard,
    updateReceiverContent,
    sortId,
    subjectReceiverContentId,
    addReceiverCard,
    deleteReceiverCard,
    receiverContentDataArr,
  } = props;

  const scroll = () => {
    window.scrollBy({
      top: 100,
      behavior: 'smooth',
    });
  };

  const updateInsuranceCompanyNameData = (e: ChangeEvent<HTMLInputElement>) => {
    updateLifeInsuranceContent(subjectId, e, 'insuranceCompanyName');
  };

  const updateInsuranceNameData = (e: ChangeEvent<HTMLInputElement>) => {
    updateLifeInsuranceContent(subjectId, e, 'insuranceName');
  };

  const updateInsuranceAmountData = (e: ChangeEvent<HTMLInputElement>) => {
    updateLifeInsuranceContent(subjectId, e, 'insuranceAmount');
  };

  const deleteLifeInsuiranceCardFunc = () => {
    deleteLifeInsuranceCard(subjectId);
  };

  const addReceiverCardFunc = () => {
    addReceiverCard(subjectId);
  };

  const receiverContentData = receiverContentDataArr;

  const displayReceiverArr = receiverArr;
  const receiverContentId = subjectReceiverContentId.map((ele) => ele.id);

  return (
    <>
      <div className="lifeInsuranceForm">
        <div>
          <p>
            {title}
            {subNum}
          </p>
          <button
            type="button"
            onClick={deleteLifeInsuiranceCardFunc}
            className={
              minusBtnView ? 'lifeInsuranceopen' : 'lifeInsuranceclose'
            }
          >
            <div>
              <img src={minusBtn} alt="minus-Button" />
              <p>削除</p>
            </div>
          </button>
        </div>
        <div>
          <div>
            <label htmlFor={`insuranceCompany_${form}`}>
              <InputItem title="保険会社名" name="require" />
              <input
                type="text"
                id={`insuranceCompany_${form}`}
                key={`insuranceCompany_${form}`}
                name="insuranceCompany"
                placeholder="入力してください"
                onBlur={scroll}
                defaultValue={insuranceCompanyValue}
                onChange={updateInsuranceCompanyNameData}
              />
            </label>
            <p>架空の名前で構いません</p>
          </div>
          <div>
            <label htmlFor={`insuranceName_${form}`}>
              <InputItem title="保険の名称" name="optional" />
              <input
                type="text"
                id={`insuranceName_${form}`}
                key={`insuranceName_${form}`}
                name="insuranceName"
                placeholder="入力してください"
                onBlur={scroll}
                defaultValue={insuranceNameValue}
                onChange={updateInsuranceNameData}
              />
            </label>
          </div>
          <div>
            <label htmlFor={`insuranceAmount_${form}`}>
              <InputItem title="保険金額" name="optional" />
              <input
                type="text"
                id={`insuranceAmount_${form}`}
                key={`insuranceAmount_${form}`}
                name="insuranceAmount"
                placeholder="入力してください"
                onBlur={scroll}
                defaultValue={insuranceAmountValue}
                onChange={updateInsuranceAmountData}
              />
            </label>
          </div>
        </div>
      </div>
      <div className="LifeInsuranceReceiver">
        <p>受取人を複数名入力できます。</p>
        <div className="LifeInsuranceReceiverTemp">
          {receiverContentData.map((ele, index, theForms) => (
            <LifeInsuranceReceiverFormCard
              key={`formCard${ele.id}`}
              title="受取人"
              conponentIndex={index}
              form={ele.id}
              minusBtnView={theForms.length !== 1}
              displayReceiver={
                displayReceiverArr.length
                  ? displayReceiverArr[index]
                  : undefined
              }
              updateReceiverContent={updateReceiverContent}
              sortId={sortId}
              subjectReceiverContentId={receiverContentId[index]}
              deleteReceiverCard={deleteReceiverCard}
            />
          ))}
        </div>
        <button
          type="button"
          className="plusBtn-wrapper"
          onClick={addReceiverCardFunc}
        >
          <LifeInsurancePlusBtn text="受取人を追加" />
        </button>
      </div>
    </>
  );
};

export default LifeInsuranceFormCard;
