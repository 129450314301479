/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, useState, ChangeEvent } from 'react';
import { BondsContent } from 'models';
import FormContentBonds from '../molecules/formContentBonds';
import minusBtn from '../images/minusBtn.svg';
import InputItem from '../atoms/InputItem';
import './bondsFormCard.css';

type Props = {
  title: string;
  form: string;
  minusBtnView: boolean;
  num: number;
  subNum: number;
  loanerNameValue: string;
  initialLoanAmountValue: string;
  loanDateValue: string | number;
  loanAmountValue: string;
  investmenterNameValue: string;
  investmentUnitNumberValue: string;
  investmentAmountValue: string;
  investmentDateValue: string | number;
  redemptionDeadlineValue: string | number;
  loanCheck: boolean;
  investmentCheck: boolean;
  openOrCloseAboutType: boolean;
  loanStorageLocation: string;
  investmentStorageLocation: string;
  loanOrInvestmentCard: boolean;
  defaultBondsContent: BondsContent;
  updateBondsContent: (
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement>,
    target: string,
  ) => void;
  subjectId: string;
  deleteCard: (deleteSubject: string) => void;
  switchingCard: (subjectKey: string, type: string) => void;
  switchingStorageLocationData: (
    subjectKey: string,
    type: string,
    yesNo: boolean,
  ) => void;
  loanStorageLocationYes: boolean;
  loanStorageLocationNo: boolean;
  investmentStorageLocationYes: boolean;
  investmentStorageLocationNo: boolean;
};

const FormCardBonds: FC<Props> = (props) => {
  const {
    title,
    form,
    minusBtnView,
    num,
    subNum,
    loanerNameValue,
    initialLoanAmountValue,
    loanDateValue,
    loanAmountValue,
    investmenterNameValue,
    investmentUnitNumberValue,
    investmentAmountValue,
    investmentDateValue,
    redemptionDeadlineValue,
    loanCheck,
    investmentCheck,
    openOrCloseAboutType,
    loanStorageLocation,
    investmentStorageLocation,
    loanOrInvestmentCard,
    defaultBondsContent,
    updateBondsContent,
    subjectId,
    deleteCard,
    switchingCard,
    switchingStorageLocationData,
    loanStorageLocationYes,
    loanStorageLocationNo,
    investmentStorageLocationYes,
    investmentStorageLocationNo,
  } = props;

  const scroll = () => {
    window.scrollBy({
      top: 100,
      behavior: 'smooth',
    });
  };

  const [open, setOpen] = useState(openOrCloseAboutType);
  // ↓ trueのときは貸付金
  const [loanOrInvestment, setLoanOrInvestment] =
    useState(loanOrInvestmentCard);
  const [bondsId, setBondsId] = useState('');

  const updateLoanerNameData = (e: ChangeEvent<HTMLInputElement>) => {
    updateBondsContent(subjectId, e, 'loanerName');
  };
  const updateInitialLoanAmountData = (e: ChangeEvent<HTMLInputElement>) => {
    updateBondsContent(subjectId, e, 'initialLoanAmount');
  };
  const updateLoanDateData = (e: ChangeEvent<HTMLInputElement>) => {
    updateBondsContent(subjectId, e, 'loanDate');
  };
  const updateLoanAmountData = (e: ChangeEvent<HTMLInputElement>) => {
    updateBondsContent(subjectId, e, 'loanAmount');
  };
  const updateLoanCertificateYes = () => {
    switchingStorageLocationData(subjectId, 'loanCertificate', true);
    scroll();
  };
  const updateLoanCertificateNo = () => {
    switchingStorageLocationData(subjectId, 'loanCertificate', false);
    scroll();
  };
  const updateLoanStorageLocationData = (e: ChangeEvent<HTMLInputElement>) => {
    updateBondsContent(subjectId, e, 'loanStorageLocation');
  };
  const updateInvestmenterNameData = (e: ChangeEvent<HTMLInputElement>) => {
    updateBondsContent(subjectId, e, 'investmenterName');
  };
  const updateInvestmentUnitNumberData = (e: ChangeEvent<HTMLInputElement>) => {
    updateBondsContent(subjectId, e, 'investmentUnitNumber');
  };
  const updateInvestmentAmountData = (e: ChangeEvent<HTMLInputElement>) => {
    updateBondsContent(subjectId, e, 'investmentAmount');
  };
  const updateInvestmentDateData = (e: ChangeEvent<HTMLInputElement>) => {
    updateBondsContent(subjectId, e, 'investmentDate');
  };
  const updateRedemptionDeadlineData = (e: ChangeEvent<HTMLInputElement>) => {
    updateBondsContent(subjectId, e, 'redemptionDeadline');
  };
  const updateInvestmentCertificateYes = () => {
    switchingStorageLocationData(subjectId, 'investmentCertificate', true);
    scroll();
  };
  const updateInvestmentCertificateNo = () => {
    switchingStorageLocationData(subjectId, 'investmentCertificate', false);
    scroll();
  };
  const updateInvestmentStorageLocationData = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    updateBondsContent(subjectId, e, 'investmentStorageLocation');
  };

  const deleteCardFunc = () => {
    deleteCard(subjectId);
  };

  const switchingCardToLoan = () => {
    switchingCard(subjectId, '貸付金');
    setOpen(true);
    setLoanOrInvestment(true);
  };

  const switchingCardToInvestment = () => {
    switchingCard(subjectId, '出資金');
    setOpen(true);
    setLoanOrInvestment(false);
  };

  return (
    <>
      <div className="bondsForm">
        <div>
          <p>
            {title}
            {subNum}
          </p>
          <button
            type="button"
            onClick={deleteCardFunc}
            className={minusBtnView ? 'bondsopen' : 'bondsclose'}
          >
            <div>
              <img src={minusBtn} alt="minus-Button" />
              <p>削除</p>
            </div>
          </button>
        </div>
        <div>
          <div className="loanOrInvestment">
            <p>あなたの有する債権は、貸付金ですか、出資金ですか？</p>
            <div>
              <div>
                <input
                  type="radio"
                  id={`bonds${form}loan`}
                  name={`bonds${form}`}
                  value="貸付金"
                  onChange={switchingCardToLoan}
                  onClick={() => [
                    setOpen(true),
                    setLoanOrInvestment(true),
                    scroll,
                  ]}
                  defaultChecked={loanCheck}
                />
                <label htmlFor={`bonds${form}loan`}>貸付金{}</label>
              </div>
              <div>
                <input
                  type="radio"
                  id={`bonds${form}investment`}
                  name={`bonds${form}`}
                  value="出資金"
                  onClick={() => [
                    setOpen(true),
                    setLoanOrInvestment(false),
                    scroll,
                  ]}
                  onChange={switchingCardToInvestment}
                  defaultChecked={investmentCheck}
                />
                <label htmlFor={`bonds${form}investment`}>出資金{}</label>
              </div>
            </div>
          </div>
          <div className={`${open ? 'bondsopen' : 'bondsclose'}`}>
            {loanOrInvestment ? (
              <div className="loanArea">
                <p>貸付金情報を入力してください。</p>
                <div>
                  <label htmlFor={`loanerName_${form}`}>
                    <InputItem title="債務者名" name="require" />
                    <input
                      type="text"
                      id={`loanerName_${form}`}
                      key={`loanerName_${form}`}
                      name="loanerName"
                      placeholder="入力してください"
                      onBlur={scroll}
                      defaultValue={loanerNameValue}
                      onChange={updateLoanerNameData}
                    />
                  </label>
                  <p>架空の名前で構いません</p>
                </div>
                <div>
                  <label htmlFor={`initialLoanAmount_${form}`}>
                    <InputItem title="当初貸付金額" name="optional" />
                    <input
                      type="text"
                      id={`initialLoanAmount_${form}`}
                      key={`initialLoanAmount_${form}`}
                      name="initialLoanAmount"
                      onBlur={scroll}
                      defaultValue={initialLoanAmountValue}
                      onChange={updateInitialLoanAmountData}
                    />
                    円
                  </label>
                </div>
                <div>
                  <label htmlFor={`loanDate_${form}`}>
                    <InputItem title="貸付年月日" name="optional" />
                    <input
                      type="date"
                      id={`loanDate_${form}`}
                      key={`loanDate_${form}`}
                      name="loanDate"
                      onBlur={scroll}
                      onChange={updateLoanDateData}
                      defaultValue={loanDateValue}
                    />
                  </label>
                </div>
                <div>
                  <label htmlFor={`loanAmount_${form}`}>
                    <InputItem title="貸付金残高" name="optional" />
                    <input
                      type="text"
                      id={`loanAmount_${form}`}
                      key={`loanAmount_${form}`}
                      name="loanAmount"
                      onBlur={scroll}
                      onChange={updateLoanAmountData}
                      defaultValue={loanAmountValue}
                    />
                    円
                  </label>
                </div>
                <FormContentBonds
                  name={`loan${num}`}
                  title="この貸付金の証書（金銭消費貸借契約書・借用書など）はありますか"
                  loanOrInvestmentStorageLocation={loanStorageLocation}
                  defaultBondsContent={defaultBondsContent}
                  bondsId={bondsId}
                  setBondsId={setBondsId}
                  updateCertificateData={updateLoanStorageLocationData}
                  yesFunc={updateLoanCertificateYes}
                  noFunc={updateLoanCertificateNo}
                  loanOrInvestmentStorageLocationYes={loanStorageLocationYes}
                  loanOrInvestmentStorageLocationNo={loanStorageLocationNo}
                />
              </div>
            ) : (
              <div className="investmentArea">
                <p>
                  債権情報を入力してください。
                  <br />
                  債権者名だけでも大丈夫です。
                </p>
                <div>
                  <label htmlFor={`investmenterName_${form}`}>
                    <InputItem title="出資金先名" name="require" />
                    <input
                      type="text"
                      id={`investmenterName_${form}`}
                      key={`investmenterName_${form}`}
                      name="investmenterName"
                      placeholder="入力してください"
                      onBlur={scroll}
                      onChange={updateInvestmenterNameData}
                      defaultValue={investmenterNameValue}
                    />
                  </label>
                  <p>架空の名前で構いません</p>
                </div>
                <div>
                  <label htmlFor={`investmentUnitNumber_${form}`}>
                    <InputItem title="出資口数・金額" name="optional" />
                    <input
                      type="text"
                      id={`investmentUnitNumber_${form}`}
                      key={`investmentUnitNumber_${form}`}
                      name="investmentUnitNumber"
                      placeholder="50"
                      onBlur={scroll}
                      onChange={updateInvestmentUnitNumberData}
                      defaultValue={investmentUnitNumberValue}
                    />
                    口
                    <input
                      type="text"
                      id={`investmentUnitAmount_${form}`}
                      key={`investmentUnitAmount_${form}`}
                      name="investmentUnitAmount"
                      onBlur={scroll}
                      onChange={updateInvestmentAmountData}
                      defaultValue={investmentAmountValue}
                    />
                    円
                  </label>
                </div>
                <div>
                  <label htmlFor={`investmentDate_${form}`}>
                    <InputItem title="出資年月日" name="optional" />
                    <input
                      type="date"
                      id={`investmentDate_${form}`}
                      key={`investmentDate_${form}`}
                      name="investmentDate"
                      onBlur={scroll}
                      onChange={updateInvestmentDateData}
                      defaultValue={investmentDateValue}
                    />
                  </label>
                </div>
                <div>
                  <label htmlFor={`redemptionDeadline_${form}`}>
                    <InputItem title="償還期限" name="optional" />
                    <input
                      type="date"
                      id={`redemptionDeadline_${form}`}
                      key={`redemptionDeadline_${form}`}
                      name="redemptionDeadline"
                      onBlur={scroll}
                      onChange={updateRedemptionDeadlineData}
                      defaultValue={redemptionDeadlineValue}
                    />
                  </label>
                </div>
                <FormContentBonds
                  name={`capital${num}`}
                  title="この出資金の証書（預かり書・出資金領収書など）はありますか"
                  loanOrInvestmentStorageLocation={investmentStorageLocation}
                  defaultBondsContent={defaultBondsContent}
                  bondsId={bondsId}
                  setBondsId={setBondsId}
                  updateCertificateData={updateInvestmentStorageLocationData}
                  yesFunc={updateInvestmentCertificateYes}
                  noFunc={updateInvestmentCertificateNo}
                  loanOrInvestmentStorageLocationYes={
                    investmentStorageLocationYes
                  }
                  loanOrInvestmentStorageLocationNo={
                    investmentStorageLocationNo
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FormCardBonds;
