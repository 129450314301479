import tutorialImg0 from '../images/tutorial_0.svg';
import tutorialImg1 from '../images/tutorial_1.svg';
import tutorialImg2 from '../images/tutorial_2.svg';
import tutorialImg3 from '../images/tutorial_3.svg';
import tutorialImg4 from '../images/tutorial_4.svg';
import tutorialImg5 from '../images/tutorial_5.svg';
import tutorialImg6 from '../images/tutorial_6.svg';
import tutorialImg7 from '../images/tutorial_7.svg';
import tutorialImgPc0 from '../images/tutorial-pc_0.svg';
import tutorialImgPc1 from '../images/tutorial-pc_1.svg';
import tutorialImgPc2 from '../images/tutorial-pc_2.svg';
import tutorialImgPc3 from '../images/tutorial-pc_3.svg';
import tutorialImgPc4 from '../images/tutorial-pc_4.svg';
import tutorialImgPc5 from '../images/tutorial-pc_5.svg';
import tutorialImgPc6 from '../images/tutorial-pc_6.svg';
import tutorialImgPc7 from '../images/tutorial-pc_7.svg';

const TopTutorialDatas = [
  {
    description: [
      '遺言アプリの使い方を確認しましょう。',
      '不要な方は「閉じる」を押して、遺言書の作成を開始しましょう！',
    ],
    imgLink: tutorialImg0,
    imgLinkPc: tutorialImgPc0,
  },
  {
    description: ['お好きな項目から入力をはじめられます。'],
    imgLink: tutorialImg1,
    imgLinkPc: tutorialImgPc1,
  },
  {
    description: [
      'アイコンを選択（タップまたはクリック）すると各該当ページの入力画面に遷移します。',
    ],
    imgLink: tutorialImg2,
    imgLinkPc: tutorialImgPc2,
  },
  {
    description: [
      '左上にあるナビゲーション部分のホームを押すといつでもこの画面に戻ってこれます。',
    ],
    descriptionSp: [
      '下部にあるナビゲーション部分のホームを押すといつでもこの画面に戻ってこれます。',
    ],
    imgLink: tutorialImg3,
    imgLinkPc: tutorialImgPc3,
  },
  {
    description: [
      '複数項目の入力のある場合は、「＋追加」ボタンを選択（タップまたはクリック）すると追加の項目が表示され複数入力が可能になります。',
    ],
    imgLink: tutorialImg4,
    imgLinkPc: tutorialImgPc4,
  },
  {
    description: [
      '右上にある「削除」ボタンを押すとその入力した項目のみ削除できます。',
    ],
    imgLink: tutorialImg5,
    imgLinkPc: tutorialImgPc5,
  },
  {
    description: ['入力の完了した項目は✓が入ります。'],
    imgLink: tutorialImg6,
    imgLinkPc: tutorialImgPc6,
  },
  {
    description: [
      'すべての入力を完了あるいは作成途中でも下書きのPDFを確認できます。',
    ],
    imgLink: tutorialImg7,
    imgLinkPc: tutorialImgPc7,
  },
];

export default TopTutorialDatas;
