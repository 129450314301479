/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import {
  FC,
  useState,
  useRef,
  useEffect,
  useCallback,
  ChangeEvent,
  FormEvent,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { checkUserExist } from 'domains/apigateway/services/checkUserExist';
import { logger } from 'utils/logger';
import { Year, YearArray } from '../data/year';
import { Month, MonthArray } from '../data/month';
import { Day, DayArray } from '../data/day';
import PopulaionData from '../data/popuLationData.json';
import ActivityAreaForm from '../templates/activityAreaForm';
import SignUpExecutorPolicy from '../../components/pages/signUpExecutorPolicy';
import InputItem from '../../../executor/components/atoms/InputItem';
import ApplicationConfirmBtn from '../atoms/applicationConfirmBtn';
import {
  Prefecture,
  PrefectureArray,
} from '../../../executor/components/data/prefecture';
import removeBtn from '../images/removeBtn.svg';
import './executorProcedureFormContent.css';

type LocationState = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  year: number;
  month: number;
  date: number;
  prefecture: string;
  city: string;
  houseNumber: string;
  buildingName: string;
  check1: boolean;
  check2: boolean;
  check3: boolean;
  check4: boolean;
  check5: boolean;
  check6: boolean;
  certificate1: string;
  certificate2: string;
  certificate3: string;
  certificate4: string;
  certificate5: string;
  certificate6: string;
  file: File | null;
  listingArea: string[];
  listingAreaPrice: number;
  acceptedFiles: File[];
} | null;

type cityProps = {
  prefCode: string;
  cityCode: string;
  cityName: string;
  bigCityFlag: string;
};

const ExecutorProcedureFormContent: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const locationState = location.state as LocationState;

  const initialFirstName = locationState ? locationState.firstName : '';
  const initialLastName = locationState ? locationState.lastName : '';
  const initialEmail = locationState ? locationState.email : '';
  const initialPhoneNumber = locationState ? locationState.phoneNumber : '';
  const initialYear = locationState ? locationState.year : 1990;
  const initialMonth = locationState ? locationState.month : 1;
  const initialDate = locationState ? locationState.date : 1;
  const initialPrefecture = locationState ? locationState.prefecture : '';
  const initialCity = locationState ? locationState.city : '';
  const initialHouseNumber = locationState ? locationState.houseNumber : '';
  const initialBuildingName = locationState ? locationState.buildingName : '';

  const initialCheck1 = locationState ? locationState.check1 : false;
  const initialCheck2 = locationState ? locationState.check2 : false;
  const initialCheck3 = locationState ? locationState.check3 : false;
  const initialCheck4 = locationState ? locationState.check4 : false;
  const initialCheck5 = locationState ? locationState.check5 : false;
  const initialCheck6 = locationState ? locationState.check6 : false;
  const initialAcceptedFiles = locationState
    ? locationState.acceptedFiles
    : null;
  const initialCertificate1 = locationState ? locationState.certificate1 : '';
  const initialCertificate2 = locationState ? locationState.certificate2 : '';
  const initialCertificate3 = locationState ? locationState.certificate3 : '';
  const initialCertificate4 = locationState ? locationState.certificate4 : '';
  const initialCertificate5 = locationState ? locationState.certificate5 : '';
  const initialCertificate6 = locationState ? locationState.certificate6 : '';
  const initialListingArea = locationState ? locationState.listingArea : [];

  const [files, setFiles] = useState<File[]>([]);
  const [paths, setPaths] = useState<null | string[]>(null);

  useEffect(() => {
    if (initialAcceptedFiles) {
      setFiles(initialAcceptedFiles);
      const acceptedFilesPath = initialAcceptedFiles.map((file: File) =>
        URL.createObjectURL(file),
      );
      setPaths(acceptedFilesPath);
    }
  }, [initialAcceptedFiles]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFiles((prevFiles) => [...(prevFiles || []), ...acceptedFiles]);
      const acceptedFilesPath = acceptedFiles.map((file: File) =>
        URL.createObjectURL(file),
      );
      setPaths((prevPath) => [...(prevPath || []), ...acceptedFilesPath]);
    },
    [setPaths],
  );

  const removePath = useCallback(
    (index: number) => {
      if (!paths || !files) {
        return;
      }
      const newFiles = files.filter((_, i) => i !== index);
      setFiles(newFiles);
      const newPaths = paths.filter((_, i) => i !== index);
      setPaths(newPaths);
    },
    [files, paths],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/webp': [],
      'image/heic': [],
    },
    // ファイルサイズを制限： 5MB以下
    maxSize: 5242880,
    // ファイルサイズを超えた場合のエラーメッセージ
    validator: (file) => {
      if (file.size > 5242880) {
        alert(
          'ファイルサイズが大きすぎます。5MB以下のファイルを選択してください。',
        );
      }

      return null;
    },
  });

  const [newListingArea, setNewListingArea] =
    useState<string[]>(initialListingArea);

  const listingAreaPriceArr = newListingArea.map((ele) => {
    const listingAreaPriceAllArr = PopulaionData.map((Ele) => {
      const listingAreaPrice = Ele.PrefecturesCity === ele ? Ele.price : '';

      return listingAreaPrice;
    });

    const listingAreaPriceArrInfo = listingAreaPriceAllArr.filter(
      (x) => x !== '',
    )[0];

    return listingAreaPriceArrInfo;
  });

  const listingAreaPriceTotal = listingAreaPriceArr
    .map((ele) => {
      const price = ele.replace(/,/g, '');
      const numberPrice = Number(price);

      return numberPrice;
    })
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

  const [show, setShow] = useState(false);
  const [cityGroup, setCityGroup] = useState<cityProps[]>([]);

  const setShowFunc = () => {
    setShow(false);
  };

  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const [year, setYear] = useState<number>(initialYear);
  const [month, setMonth] = useState<number>(initialMonth);
  const [date, setDate] = useState(initialDate);
  const houseNumberRef = useRef<HTMLInputElement>(null);
  const buildingNameRef = useRef<HTMLInputElement>(null);
  const [prefecture, setPrefecture] = useState(initialPrefecture);
  const [city, setCity] = useState(initialCity);
  const certificateRef1 = useRef<HTMLTextAreaElement>(null);
  const certificateRef2 = useRef<HTMLTextAreaElement>(null);
  const certificateRef3 = useRef<HTMLTextAreaElement>(null);
  const certificateRef4 = useRef<HTMLTextAreaElement>(null);
  const certificateRef5 = useRef<HTMLTextAreaElement>(null);
  const certificateRef6 = useRef<HTMLTextAreaElement>(null);
  const [check1, setCheck1] = useState(initialCheck1);
  const [check2, setCheck2] = useState(initialCheck2);
  const [check3, setCheck3] = useState(initialCheck3);
  const [check4, setCheck4] = useState(initialCheck4);
  const [check5, setCheck5] = useState(initialCheck5);
  const [check6, setCheck6] = useState(initialCheck6);
  const [policyCheck, setPolicyCheck] = useState(false);

  const handleChangeYear = (e: ChangeEvent<HTMLSelectElement>) => {
    setYear(Number(e.currentTarget.value));
  };

  const handleChangeMonth = (e: ChangeEvent<HTMLSelectElement>) => {
    setMonth(Number(e.currentTarget.value));
  };

  const handleChangeDate = (e: ChangeEvent<HTMLSelectElement>) => {
    setDate(Number(e.currentTarget.value));
  };

  const handleChangePrefecture = (e: ChangeEvent<HTMLSelectElement>): void => {
    setPrefecture(e.currentTarget.value);
  };

  const handleChangeCity = (e: ChangeEvent<HTMLSelectElement>): void => {
    setCity(e.currentTarget.value);
  };

  const handleChangePolicyCheck = () => {
    setPolicyCheck(!policyCheck);
  };

  const [userExist, setUserExist] = useState(false);

  const emailDuplicateCheck = async (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    try {
      if (emailRef.current && emailRef.current.value) {
        const existCheck = await checkUserExist(emailRef.current.value);

        if (existCheck.exist) {
          setUserExist(true);
        } else {
          setUserExist(false);
        }
      }
    } catch (error) {
      logger.info('メールアドレスチェック失敗');
    }
  };

  // 名前のバリデーション state
  const [nameValid, setNameValid] = useState(true);
  // Emailのバリデーション state
  const [emailValid, setEmailValid] = useState(true);
  // 電話番号のバリデーション state
  const [phoneValid, setPhoneValid] = useState(true);
  // 住所のバリデーション state
  const [addressValid, setAddressValid] = useState(true);
  // 所持資格のバリデーション state
  const [licenseValid, setLicenseValid] = useState(true);
  // 所持資格の番号のバリデーション state
  const [licenseNumberValid, setLicenseNumberValid] = useState(true);
  // 所持資格を証明する書類のバリデーション state
  const [certificateValid, setCertificateValid] = useState(true);
  // 活動地域のバリデーション state
  const [activityAreaValid, setActivityAreaValid] = useState(true);
  // 利用規約のバリデーション state
  const [policyValid, setPolicyValid] = useState(true);

  const save = async () => {
    const cannotSubmitCondition =
      (firstNameRef.current && firstNameRef.current.value === '') ||
      (lastNameRef.current && lastNameRef.current.value === '') ||
      (emailRef.current && emailRef.current.value === '') ||
      (phoneRef.current && phoneRef.current.value === '') ||
      prefecture === '' ||
      city === '' ||
      (!check1 && !check2 && !check3 && !check4 && !check5 && !check6) ||
      // || (certificateRef.current && certificateRef.current.value === '')
      (check1 &&
        certificateRef1.current &&
        certificateRef1.current.value === '') ||
      (!check1 &&
        certificateRef1.current &&
        certificateRef1.current.value !== '') ||
      (check2 &&
        certificateRef2.current &&
        certificateRef2.current.value === '') ||
      (!check2 &&
        certificateRef2.current &&
        certificateRef2.current.value !== '') ||
      (check3 &&
        certificateRef3.current &&
        certificateRef3.current.value === '') ||
      (!check3 &&
        certificateRef3.current &&
        certificateRef3.current.value !== '') ||
      (check4 &&
        certificateRef4.current &&
        certificateRef4.current.value === '') ||
      (!check4 &&
        certificateRef4.current &&
        certificateRef4.current.value !== '') ||
      (check5 &&
        certificateRef5.current &&
        certificateRef5.current.value === '') ||
      (!check5 &&
        certificateRef5.current &&
        certificateRef5.current.value !== '') ||
      (check6 &&
        certificateRef6.current &&
        certificateRef6.current.value === '') ||
      (!check6 &&
        certificateRef6.current &&
        certificateRef6.current.value !== '') ||
      listingAreaPriceArr.length === 0 ||
      !paths ||
      paths.length === 0;
    if (cannotSubmitCondition || !policyCheck) {
      if (
        (firstNameRef.current && firstNameRef.current.value === '') ||
        (lastNameRef.current && lastNameRef.current.value === '')
      ) {
        setNameValid(false);
      } else {
        setNameValid(true);
      }
      if (emailRef.current && emailRef.current.value === '') {
        setEmailValid(false);
      } else {
        setEmailValid(true);
      }
      if (phoneRef.current && phoneRef.current.value === '') {
        setPhoneValid(false);
      } else {
        setPhoneValid(true);
      }
      if (prefecture === '' || city === '') {
        setAddressValid(false);
      } else {
        setAddressValid(true);
      }
      if (!check1 && !check2 && !check3 && !check4 && !check5 && !check6) {
        setLicenseValid(false);
      } else {
        setLicenseValid(true);
      }
      if (
        (check1 &&
          certificateRef1.current &&
          certificateRef1.current.value === '') ||
        (!check1 &&
          certificateRef1.current &&
          certificateRef1.current.value !== '') ||
        (check2 &&
          certificateRef2.current &&
          certificateRef2.current.value === '') ||
        (!check2 &&
          certificateRef2.current &&
          certificateRef2.current.value !== '') ||
        (check3 &&
          certificateRef3.current &&
          certificateRef3.current.value === '') ||
        (!check3 &&
          certificateRef3.current &&
          certificateRef3.current.value !== '') ||
        (check4 &&
          certificateRef4.current &&
          certificateRef4.current.value === '') ||
        (!check4 &&
          certificateRef4.current &&
          certificateRef4.current.value !== '') ||
        (check5 &&
          certificateRef5.current &&
          certificateRef5.current.value === '') ||
        (!check5 &&
          certificateRef5.current &&
          certificateRef5.current.value !== '') ||
        (check6 &&
          certificateRef6.current &&
          certificateRef6.current.value === '') ||
        (!check6 &&
          certificateRef6.current &&
          certificateRef6.current.value !== '')
      ) {
        setLicenseNumberValid(false);
      } else {
        setLicenseNumberValid(true);
      }

      if (!files || files.length === 0) {
        setCertificateValid(false);
      } else {
        setCertificateValid(true);
      }

      if (listingAreaPriceArr.length === 0) {
        setActivityAreaValid(false);
      } else {
        setActivityAreaValid(true);
      }

      if (!policyCheck) {
        setPolicyValid(false);
      } else {
        setPolicyValid(true);
      }

      if (cannotSubmitCondition && !policyCheck) {
        window.alert(
          '入力内容に過不足、または誤りがあります。また、利用規約に同意してください。',
        );
      } else if (cannotSubmitCondition) {
        window.alert('入力内容に過不足、または誤りがあります。');
      } else if (!policyCheck) {
        window.alert('利用規約に同意してください。');
      }
    } else {
      if (emailRef.current && emailRef.current.value !== '') {
        const existCheck = await checkUserExist(emailRef.current.value);

        if (existCheck.exist) {
          setUserExist(true);
          window.alert('既に登録されているメールアドレスです。');

          return;
        }

        setUserExist(false);
      }

      const state = {
        firstName: firstNameRef.current ? firstNameRef.current.value : '',
        lastName: lastNameRef.current ? lastNameRef.current.value : '',
        email: emailRef.current ? emailRef.current.value : '',
        phoneNumber: phoneRef.current ? phoneRef.current.value : '',
        year,
        month,
        date,
        prefecture,
        city,
        houseNumber: houseNumberRef.current ? houseNumberRef.current.value : '',
        buildingName: buildingNameRef.current
          ? buildingNameRef.current.value
          : '',
        check1,
        check2,
        check3,
        check4,
        check5,
        check6,
        acceptedFiles: files,
        certificate1: certificateRef1.current
          ? certificateRef1.current.value
          : '',
        certificate2: certificateRef2.current
          ? certificateRef2.current.value
          : '',
        certificate3: certificateRef3.current
          ? certificateRef3.current.value
          : '',
        certificate4: certificateRef4.current
          ? certificateRef4.current.value
          : '',
        certificate5: certificateRef5.current
          ? certificateRef5.current.value
          : '',
        certificate6: certificateRef6.current
          ? certificateRef6.current.value
          : '',
        listingArea: newListingArea,
        listingAreaPrice: listingAreaPriceTotal || 0,
      };
      navigate('../confirm', { state });
    }
  };

  const getCity = async (inputPrefecture: string) => {
    const selectArr = PrefectureArray.find(
      (ele) => ele.label === inputPrefecture,
    );
    const selectId = selectArr ? selectArr.id : 1;

    await fetch(
      `https://opendata.resas-portal.go.jp/api/v1/cities?prefCode=${selectId}`,
      {
        headers: { 'X-API-KEY': 'yvet69MOZSiyzRoinuvNJaWjcXaqhoP118HTqciA' },
      },
    )
      .then((response) => response.json())
      .then((citiesData) => {
        setCityGroup(citiesData.result);
      });
  };

  useEffect(() => {
    const selectLabel = initialPrefecture;

    const selectArr = PrefectureArray.find((ele) => ele.label === selectLabel);
    const selectId = selectArr ? selectArr.id : 1000;

    void fetch(
      `https://opendata.resas-portal.go.jp/api/v1/cities?prefCode=${selectId}`,
      {
        headers: { 'X-API-KEY': 'yvet69MOZSiyzRoinuvNJaWjcXaqhoP118HTqciA' },
      },
    )
      .then((response) => response.json())
      .then((citiesData) => {
        setCityGroup(citiesData.result);
      });
  }, [initialPrefecture]);

  const changePrefectureAndGetCity = async (
    e: ChangeEvent<HTMLSelectElement>,
  ) => {
    handleChangePrefecture(e);
    await getCity(e.currentTarget.value);
  };

  const [executorPolicyBtn, setExecutorPolicyBtn] = useState(false);

  const changeExecutorPolicyBtn = () => {
    setExecutorPolicyBtn(!executorPolicyBtn);
  };

  return (
    <div className="executorProcedureForm">
      <div>
        <p>遺言執行者登録申請フォーム</p>
        <form action="">
          <label className="executorProcedureForm-name" htmlFor="name">
            <InputItem title="お名前" name="require" />
            <div>
              <input
                type="text"
                id="name"
                placeholder="姓"
                ref={firstNameRef}
                defaultValue={initialFirstName}
              />
              <input
                type="text"
                id="name"
                placeholder="名"
                ref={lastNameRef}
                defaultValue={initialLastName}
              />
            </div>
            {!nameValid && (
              <p className="alertMessage executorProcedureForm-alertMessage">
                お名前を入力してください
              </p>
            )}
          </label>
          <label className="executorProcedureForm-mail" htmlFor="mail">
            <InputItem title="メールアドレス" name="require" />
            <input
              type="text"
              id="mail"
              placeholder="メールアドレス"
              ref={emailRef}
              defaultValue={initialEmail}
              onBlur={emailDuplicateCheck}
            />
            {userExist && emailValid && (
              <p className="alertMessage">
                そのメールアドレスは既に使われています。
              </p>
            )}
            {!emailValid && (
              <p className="alertMessage executorProcedureForm-alertMessage">
                メールアドレスを入力してください
              </p>
            )}
            <p className="alertMessage alertMessage-black">
              遺言者として登録済みのメールアドレスは使用できません。
            </p>
          </label>
          <label className="executorProcedureForm-phone" htmlFor="phone">
            <InputItem title="電話番号" name="require" />
            <input
              type="text"
              id="phone"
              placeholder="電話番号"
              ref={phoneRef}
              defaultValue={initialPhoneNumber}
            />
            {!phoneValid && (
              <p className="alertMessage executorProcedureForm-alertMessage">
                電話番号を入力してください
              </p>
            )}
          </label>
          <div>
            <label htmlFor="birth" className="executorProcedureForm-year">
              <InputItem title="生年月日" name="require" />
              <div className="birthSelect birthYear execProcedureBirth">
                <select name="year" id="year" onChange={handleChangeYear}>
                  {YearArray.map((yearEle: Year) => (
                    <option
                      value={yearEle.year}
                      key={yearEle.id}
                      selected={year === yearEle.year}
                    >
                      {yearEle.year}
                    </option>
                  ))}
                </select>
                <p>年</p>
              </div>
              <div className="MonthAndDay execProcedureMonthAndDay">
                <div className="birthSelect birthMonth execProcedureBirth">
                  <select
                    name="month"
                    id="month"
                    // value={birthMonth}
                    onChange={handleChangeMonth}
                    // onBlur={mutate}
                    // ref={monthRef}
                  >
                    {MonthArray.map((monthEle: Month) => (
                      <option
                        value={monthEle.month}
                        key={monthEle.id}
                        selected={month === monthEle.month}
                      >
                        {monthEle.month}
                      </option>
                    ))}
                  </select>
                  <p>月</p>
                </div>
                <div className="birthSelect birthDay execProcedureBirth">
                  <select name="day" id="day" onChange={handleChangeDate}>
                    {DayArray.map((dayEle: Day) => {
                      if (year % 4 === 0 && month === 2) {
                        return (
                          <option
                            value={dayEle.day28}
                            key={dayEle.id}
                            selected={date === dayEle.day28}
                          >
                            {dayEle.day28}
                          </option>
                        );
                      }
                      if (year % 4 !== 0 && month === 2) {
                        return (
                          <option
                            value={dayEle.day29}
                            key={dayEle.id}
                            selected={date === dayEle.day29}
                          >
                            {dayEle.day31}
                          </option>
                        );
                      }
                      if (
                        month === 4 ||
                        month === 6 ||
                        month === 9 ||
                        month === 11
                      ) {
                        return (
                          <option
                            value={dayEle.day30}
                            key={dayEle.id}
                            selected={date === dayEle.day30}
                          >
                            {dayEle.day30}
                          </option>
                        );
                      }

                      return (
                        <option
                          value={dayEle.day31}
                          key={dayEle.id}
                          selected={date === dayEle.day31}
                        >
                          {dayEle.day31}
                        </option>
                      );
                    })}
                    :
                  </select>
                  <p>日</p>
                </div>
              </div>
            </label>
          </div>
          <label className="executorProcedureForm-address" htmlFor="address">
            <InputItem title="住所" name="require" />
            <div>
              <div>
                <select
                  id="prefecture"
                  name="prefecture"
                  onChange={changePrefectureAndGetCity}
                  defaultValue={initialPrefecture}
                >
                  <option value="" disabled selected hidden>
                    都道府県を選択
                  </option>
                  {PrefectureArray.map((ele: Prefecture) => (
                    <option key={ele.label} value={ele.label}>
                      {ele.label}
                    </option>
                  ))}
                </select>
                <select
                  id="cities"
                  name="cities"
                  onChange={handleChangeCity}
                  defaultValue={initialCity}
                >
                  <option value="" disabled selected hidden>
                    市区町村を選択
                  </option>
                  {cityGroup.map((ele) => (
                    <option
                      value={ele.cityName}
                      key={ele.cityName}
                      selected={initialCity === ele.cityName}
                    >
                      {ele.cityName}
                    </option>
                  ))}
                </select>
              </div>
              <input
                type="text"
                id="address"
                placeholder="町名以下を入力"
                ref={houseNumberRef}
                defaultValue={initialHouseNumber}
              />
              <input
                type="text"
                id="address"
                placeholder="建物・マンション名を入力"
                ref={buildingNameRef}
                defaultValue={initialBuildingName}
              />
            </div>
            {!addressValid && (
              <p className="alertMessage executorProcedureForm-alertMessage">
                住所を入力してください
              </p>
            )}
          </label>
          <div className="license">
            <InputItem title="所持資格" name="require" />
            <div>
              <div>
                <input
                  type="checkbox"
                  id="check1"
                  name="group1"
                  checked={check1}
                  onChange={() => {
                    setCheck1(!check1);
                  }}
                  value="遺言執行士"
                />
                <label htmlFor="check1">遺言執行士{}</label>
              </div>
              <input
                type="checkbox"
                id="check2"
                name="group1"
                checked={check2}
                onChange={() => {
                  setCheck2(!check2);
                }}
                value="弁護士"
              />
              <label htmlFor="check2">弁護士{}</label>
              <input
                type="checkbox"
                id="check3"
                name="group1"
                checked={check3}
                onChange={() => {
                  setCheck3(!check3);
                }}
                value="司法書士"
              />
              <label htmlFor="check3">司法書士{}</label>
              <input
                type="checkbox"
                id="check4"
                name="group1"
                checked={check4}
                onChange={() => {
                  setCheck4(!check4);
                }}
                value="行政書士"
              />
              <label htmlFor="check4">行政書士{}</label>
              <input
                type="checkbox"
                id="check5"
                name="group1"
                checked={check5}
                onChange={() => {
                  setCheck5(!check5);
                }}
                value="税理士"
              />
              <label htmlFor="check5">税理士{}</label>
              <input
                type="checkbox"
                id="check6"
                name="group1"
                checked={check6}
                onChange={() => {
                  setCheck6(!check6);
                }}
                value="宅地建物取引士"
              />
              <label htmlFor="check6">宅地建物取引士{}</label>
              {!licenseValid && (
                <p className="alertMessage executorProcedureForm-alertMessage">
                  所持資格を選択してください
                </p>
              )}
            </div>
          </div>
          <div className="licensenumber">
            <InputItem
              title="所持資格の登録番号または証明書番号"
              name="require"
            />
            <div>
              <textarea
                name="licensenumber"
                id="licensenumber"
                rows={1}
                ref={certificateRef1}
                placeholder="遺言執行士の番号を入力してください"
                defaultValue={initialCertificate1}
              />
              <textarea
                name="licensenumber"
                id="licensenumber"
                rows={1}
                ref={certificateRef2}
                placeholder="弁護士の番号を入力してください"
                defaultValue={initialCertificate2}
              />
              <textarea
                name="licensenumber"
                id="licensenumber"
                rows={1}
                ref={certificateRef3}
                placeholder="司法書士の番号を入力してください"
                defaultValue={initialCertificate3}
              />
              <textarea
                name="licensenumber"
                id="licensenumber"
                rows={1}
                ref={certificateRef4}
                placeholder="行政書士の番号を入力してください"
                defaultValue={initialCertificate4}
              />
              <textarea
                name="licensenumber"
                id="licensenumber"
                rows={1}
                ref={certificateRef5}
                placeholder="税理士の番号を入力してください"
                defaultValue={initialCertificate5}
              />
              <textarea
                name="licensenumber"
                id="licensenumber"
                rows={1}
                ref={certificateRef6}
                placeholder="宅地建物取引士の番号を入力してください"
                defaultValue={initialCertificate6}
              />
              {!licenseNumberValid && (
                <p className="alertMessage executorProcedureForm-alertMessage">
                  所持資格の番号を入力してください
                </p>
              )}
            </div>
          </div>
          <label className="certificate" htmlFor="certificate">
            <InputItem title="所持資格を証明する書類" name="require" />
            <p>
              （登録しなければ活動できない
              <br className="pc-only" />
              資格の場合は登録証）
            </p>
            {/* <div className="file-upload-btn">
                アップロード
                <input
                  type="file"
                  id="file"
                  name="certificate"
                  ref={uploadFile}
                  onChange={changeFileHandler}
                />
              </div>
              {uploadFile.current ? (
                <p>{uploadFile.current.value.slice(12)}</p>
              ) : (
                ''
              )} */}

            <div className="file-upload-Data">
              <div className="file-upload-btn" {...getRootProps()}>
                <input {...getInputProps()} />
                <p>アップロード</p>
              </div>
              <div className="executor-upload-images">
                {paths &&
                  paths.map((path, index) => (
                    <div>
                      <button type="button" onClick={() => removePath(index)}>
                        <img src={removeBtn} alt="" className="removeBtn" />
                      </button>
                      <img key={path} src={path} alt="" />
                    </div>
                  ))}
              </div>
            </div>
            {!certificateValid && (
              <p className="alertMessage executorProcedureForm-alertMessage">
                所持資格を証明する書類を
                <br className="sp-only" />
                アップロードしてください
              </p>
            )}
          </label>
          <div className="FormActivityArea">
            <InputItem title="名簿に掲載する活動地域" name="require" />
            <div>
              {newListingArea.length !== 0 && newListingArea[0] ? (
                newListingArea.map((ele) => <p>{ele}</p>)
              ) : (
                <p />
              )}
            </div>
            <div>
              <button
                type="button"
                onClick={() => {
                  setShow(true);
                }}
              >
                活動地域の選択
              </button>
              <p>&#8251;活動地域によって名簿掲載料が異なります</p>
              {!activityAreaValid && (
                <p className="alertMessage executorProcedureForm-alertMessage">
                  活動地域を選択してください
                </p>
              )}
            </div>
          </div>
          <div className="PublicationFee">
            {/* 2024/01/25 コメントアウト */}
            {/* TODO: 指示があったら元に戻す */}
            {/* ここから */}
            {/* <p>
              名簿掲載料
              <br className="pc-only" />
              (1年間分)
            </p>
            {listingAreaPriceArr.length === 0 ? (
              <p />
            ) : listingAreaPriceTotal < 1000000 ? (
              <p>{listingAreaPriceTotal.toLocaleString()}円</p>
            ) : (
              <p>1,000,000円</p>
            )} */}
            {/* ここまで */}
            <ActivityAreaForm
              show={show}
              setShow={setShowFunc}
              newListingArea={newListingArea}
              setNewListingArea={setNewListingArea}
            />
          </div>
          <div className="TermsOfService">
            <InputItem title="ご利用規約" name="require" />
            <label className="policy" htmlFor="policy">
              <input
                type="checkbox"
                // id="policy"
                name="policy"
                checked={policyCheck}
                onChange={handleChangePolicyCheck}
              />
              <button type="button" onClick={changeExecutorPolicyBtn}>
                <span>利用規約</span>に同意する
              </button>
              {/* <Link to="/executorpolicy">利用規約</Link>に同意する{} */}
            </label>
            {executorPolicyBtn ? (
              <SignUpExecutorPolicy
                changeExecutorPolicyBtn={changeExecutorPolicyBtn}
              />
            ) : (
              ''
            )}
            {!policyValid && (
              <p className="alertMessage executorProcedureForm-alertMessage">
                申請には利用規約への同意が必要です
              </p>
            )}
          </div>
          <ApplicationConfirmBtn title="申請内容を確認する" func={save} />
        </form>
      </div>
    </div>
  );
};

export default ExecutorProcedureFormContent;
