import { FC } from 'react';
import { useParams } from 'react-router-dom';
import TopicsTemplate from '../templates/topicsTemplate';
import TopicCreateOrEditConfirmContent from '../organisms/topicCreateOrEditConfirmContent';

const TopicEditConfirm: FC = () => {
  const { postId } = useParams();
  const inputPostId = postId || '';

  return (
    <TopicsTemplate
      subTitle="新規投稿確認"
      sideBtnPath="/admin/topics/list"
      sideBtnText="トピック一覧"
    >
      <TopicCreateOrEditConfirmContent
        prevPath={`/admin/topics/edit/${inputPostId}`}
      />
    </TopicsTemplate>
  );
};

export default TopicEditConfirm;
