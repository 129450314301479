import { FC } from 'react';
import TopicsTemplate from '../templates/topicsTemplate';
import TopicCreateOrEditContent from '../organisms/topicCreateOrEditContent';

const TopicCreate: FC = () => (
  <TopicsTemplate
    subTitle="新規投稿"
    sideBtnPath="/admin/topics/list"
    sideBtnText="トピック一覧"
  >
    <TopicCreateOrEditContent confirmPath="/admin/topics/create/confirm" />
  </TopicsTemplate>
);

export default TopicCreate;
