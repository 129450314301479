/* eslint-disable no-param-reassign */
import { DataStore } from 'aws-amplify';
import { Evaluation } from 'models';
import { logger } from 'utils/logger';

export const MutateEvaluation = async (
  id: string,
  clientId: string,
  assigneeId: string,
  response: number,
  explanation: number,
  answer: number,
  claritOfyPrice: number,
  validityOfPrice: number,
): Promise<string> => {
  try {
    logger.info('評価項目の作成または更新を開始します');
    const original = await DataStore.query(Evaluation, id);

    if (original) {
      logger.info('評価項目の更新を開始します');
      logger.info(original);
      const data = await DataStore.save(
        Evaluation.copyOf(original, (updated) => {
          updated.clientId = clientId;
          updated.assigneeId = assigneeId;
          updated.response = response;
          updated.explanation = explanation;
          updated.answer = answer;
          updated.claritOfyPrice = claritOfyPrice;
          updated.validityOfPrice = validityOfPrice;
        }),
      );
      logger.info(data);
      logger.info('評価項目の更新が完了しました');

      return data.id;
    }
    logger.info('評価項目の作成を開始します');
    const instance = {
      clientId,
      assigneeId,
      response,
      explanation,
      answer,
      claritOfyPrice,
      validityOfPrice,
    };
    const data = await DataStore.save(new Evaluation(instance));
    logger.debug(data);
    logger.info('評価項目の作成が完了しました');

    return data.id;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetEvaluation = async (): Promise<Evaluation[]> => {
  try {
    logger.info('遺言執行者の評価のフェッチを開始します');
    const data = await DataStore.query(Evaluation);
    logger.debug(data);
    logger.info('遺言執行者の評価のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetEvaluationFromClientId = async (
  clientId: string,
): Promise<Evaluation | undefined> => {
  try {
    logger.info('遺言執行者の評価のフェッチを開始します');
    const data = await DataStore.query(Evaluation, (c) =>
      c.clientId('eq', clientId),
    );
    logger.debug(data);
    logger.info('遺言執行者の評価のフェッチが完了しました');

    if (data.length === 0) {
      return undefined;
    }

    return data[0];
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetEvaluationFromAssigneeId = async (
  assigneeId: string,
): Promise<Evaluation[]> => {
  try {
    logger.info('遺言執行者の評価のフェッチを開始します');
    const data = await DataStore.query(Evaluation, (c) =>
      c.assigneeId('eq', assigneeId),
    );
    logger.debug(data);
    logger.info('遺言執行者の評価のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
