/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { DataStore } from 'aws-amplify';
import { Message, AssignStatus } from 'models';
import { GetUser } from 'domains/cognite/services/manageUser';
import { QueryAssignRelationshipFromClient } from 'domains/cognite/services/manageAssignRelationship';
import {
  GetChatRoomFromClient,
  listMessage,
} from 'domains/cognite/services/manageChat';
import { logger } from 'utils/logger';
import WillFooterMenuAfterLogin from '../templates/willFooterMenuAfterLogin';
import './willfooter.css';
import homeIcon from '../images/homeIcon.svg';
import chatIcon from '../images/chatIcon.svg';
import menuIcon from '../images/menuIcon.svg';
import redDot from '../images/redDot.svg';

type Props = {
  onClickFunc?: (link: string) => Promise<void> | void;
};

const WillFooter: FC<Props> = (props) => {
  const { onClickFunc } = props;

  const { data: user } = useQuery(['authenticated_user'], GetUser);

  if (!user) {
    throw Error('認証エラー');
  }

  const { data: relatonship } = useQuery(
    ['assign_status_from_client_footer'],
    () => QueryAssignRelationshipFromClient(user.attributes.sub),
    { cacheTime: 2000 },
  );

  const getChatRoomAndMessage = async (): Promise<Message[]> => {
    const chatroom = await GetChatRoomFromClient();

    if (chatroom) {
      const messages = await listMessage(chatroom);

      return messages;
    }

    return [] as Message[];
  };

  const { data: chatroomFromQuery } = useQuery(
    ['user_chatroom_footer'],
    GetChatRoomFromClient,
    { cacheTime: 2000 },
  );

  const { data: messagesFromQuery } = useQuery(
    'user_message_footer',
    getChatRoomAndMessage,
    { cacheTime: 2000 },
  );

  const [chatMessages, setChatMessages] = useState<Message[]>(
    messagesFromQuery || ([] as unknown[] as Message[]),
  );

  const enableClick = useMemo(() => {
    const approvalCheck =
      relatonship &&
      relatonship.length !== 0 &&
      relatonship.some((ele) => ele.status === AssignStatus.APPROVAL);

    if (approvalCheck && chatroomFromQuery) {
      return '';
    }

    return 'notChatLink';
  }, [chatroomFromQuery, relatonship]);

  useEffect(() => {
    const subscription = DataStore.observe(Message).subscribe((msg) => {
      logger.info(msg.model, msg.opType, msg.element);
      if (chatroomFromQuery) {
        const list = async () => {
          const listmessages = await listMessage(chatroomFromQuery);
          setChatMessages(listmessages);
        };
        void list();
      }
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [position, setPosition] = useState<number | null>(0);
  const [show, setShow] = useState(false);
  const [ClassSwitch, setClassSwitch] = useState(false);
  const FooterScroll = document.getElementById('footerScroll');

  window.addEventListener('scroll', () => {
    const ScrollTop = document.documentElement.scrollTop;

    if (!FooterScroll) {
      <p>取得中</p>;
    } else if (position! < ScrollTop && !show) {
      setClassSwitch(true);
    } else {
      setClassSwitch(false);
    }

    setPosition(ScrollTop);
  });

  const navigate = useNavigate();
  const normalLink = (link: string) => {
    navigate(link);
  };

  return (
    <>
      <footer
        className={`willfooter ${
          ClassSwitch ? 'willfooterScrollUp' : 'willfooterScrollDown'
        }`}
        id="footerScroll"
      >
        <div className="willfooternav">
          <button
            type="button"
            onClick={() =>
              onClickFunc ? onClickFunc('/user') : normalLink('/user')
            }
          >
            <img src={homeIcon} alt="ホームアイコン" />
            <p>ホーム</p>
          </button>
          <button
            type="button"
            className={enableClick}
            onClick={() =>
              onClickFunc ? onClickFunc('/user/chat') : normalLink('/user/chat')
            }
          >
            <img src={chatIcon} alt="チャットアイコン" />
            <p>チャット</p>
            <img
              src={redDot}
              className={
                chatMessages.length !== 0 &&
                chatMessages.some((ele) => ele.alreadyRead === false)
                  ? ''
                  : 'no-red-dot'
              }
              alt="赤点"
            />
          </button>
          <button type="button" onClick={() => setShow(!show)}>
            <img src={menuIcon} alt="メニューアイコン" />
            <p>メニュー</p>
          </button>
        </div>
      </footer>
      <WillFooterMenuAfterLogin
        show={show}
        setShow={() => setShow(false)}
        onClickFunc={onClickFunc}
        normalLink={normalLink}
      />
    </>
  );
};

export default WillFooter;
