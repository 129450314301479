import { FC } from 'react';
import './companyInfoContent.css';

const CompanyInfoContent: FC = () => (
  <div className="company-info-content">
    <div>
      <p>法人概要</p>
      <p>
        当協会は、遺言者の意思を最大限尊重し、相続人のために正確・迅速に遺言を執行することにより、遺産相続をめぐる紛争を予防し、円滑な資産移転を図り、広く社会に貢献することを目的として設立されました。
      </p>
      <div className="conmapy-info-sentence">
        <div>
          <div>
            <p>名称</p>
            <p>一般社団法人 日本遺言執行士協会</p>
          </div>
          <div>
            <p>略称</p>
            <p>ＮＩＳＡ（Nippon Igon Shikkoshi Association）</p>
          </div>
          <div>
            <p>設立</p>
            <p>平成26年（2014年）5月2日</p>
          </div>
        </div>
        <div>
          <div>
            <p>代表理事</p>
            <p>石田 泉（司法書士・遺言執行士）</p>
          </div>
          {/* <div>
            <p>理事</p>
            <p>平良 真吾（土地家屋調査士・遺言執行士）</p>
          </div>
          <div>
            <p>理事</p>
            <p>東村 謙（司法書士・遺言執行士）</p>
          </div>
          <div>
            <p>理事</p>
            <p>高久 尙彦（弁護士）</p>
          </div>
          <div>
            <p>理事</p>
            <p>星川 匡（土地家屋調査士・遺言執行士）</p>
          </div>
          <div>
            <p>監事</p>
            <p>川邉 憲一（税理士・遺言執行士・行政書士）</p>
          </div>
          <div>
            <p>事務局長</p>
            <p>見口 千明（遺言執行士）</p>
          </div> */}
          <div>
            <p>所属団体</p>
            <p>東京経営者協会会員／東京商工会議所会員</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CompanyInfoContent;
