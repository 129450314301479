import { FC } from 'react';
import './willCard.css';

type Props = {
  name: string;
  sex: string;
  age: string;
  address: string;
};

const WillCard: FC<Props> = (props) => {
  const { name, sex, age, address } = props;

  return (
    <div className="willCard">
      <p>{name}</p>
      <p>
        {sex}/{age}
      </p>
      <p>住所:{address}</p>
    </div>
  );
};

export default WillCard;
