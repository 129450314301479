/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import { FC, useState, ChangeEvent } from 'react';
import { Properties, DepositsContent } from 'models';
import { useQuery } from 'react-query';
import { usePropertiesItem, useQueryContents } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { logger } from 'utils/logger';
import { CognitoUserExt } from 'domains/cognite/user';
import { MutateProperties } from 'domains/cognite/services/manageProperties';
import {
  DeleteDeposits,
  MutateDeposits,
} from 'domains/cognite/services/manageDeposits';
import Dialog from 'common/apply/organisms/dialog';
import {
  MutateDepositsContentAndGet,
  GetDepositsContent,
  DeleteDepositsContent,
  GetDepositsContentByParent,
  DeleteDepositsContentById,
} from 'domains/cognite/services/manageDepositsContent';
import PlusBtn from '../atoms/plusBtn';
import RadioCustomSingle from '../molecules/radioCustomSingle';
import DepositsFormCard from '../organisms/depositsFormCard';
import RequireNextReturn from '../molecules/requireNextReturn';
import Param6Checkmark from '../atoms/paramCheckmarkPc';
import './depositsTemp.css';

type Props = {
  data: Properties | undefined;
  user: CognitoUserExt;
};

const DepositsTemp: FC<Props> = (props) => {
  const { data, user } = props;

  const { deposits } = usePropertiesItem(data);
  const { data: depositsContentByQuery } = useQuery(
    ['deposits_content'],
    () => GetDepositsContentByParent(deposits),
    {
      cacheTime: 200,
    },
  );

  const [isDialogShow, setIsDialogShow] = useState(false);

  const toggleDialog = () => {
    setIsDialogShow(!isDialogShow);
  };

  // 初期状態のオブジェクト
  const initialDepositContentArrData: DepositsContent[] = [
    {
      id: '0',
      subNum: null,
      bankName: '',
      branchName: '',
      accountType: '',
      accountHolderName: '',
      deposits: undefined,
    },
  ];

  const initialDepositsContentArr =
    depositsContentByQuery === undefined ||
    (depositsContentByQuery !== undefined &&
      depositsContentByQuery.length === 0)
      ? initialDepositContentArrData
      : depositsContentByQuery;

  const [depositsContent, setDepositsContent] = useState<DepositsContent[]>(
    initialDepositsContentArr,
  );

  const updateDepositsContent = (
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement>,
    target: string,
  ) => {
    const newDepositsContent = depositsContent.map((ele) => {
      if (ele.id === subjectKey) {
        return {
          id: ele.id,
          subNum: ele.subNum ? ele.subNum : null,
          bankName:
            target === 'bankName'
              ? event.target.value
              : ele.bankName === null || ele.bankName === undefined
              ? ''
              : ele.bankName,
          branchName:
            target === 'branchName'
              ? event.target.value
              : ele.branchName === null || ele.branchName === undefined
              ? ''
              : ele.branchName,
          accountType:
            target === 'accountType'
              ? event.target.value
              : ele.accountType === null || ele.accountType === undefined
              ? ''
              : ele.accountType,
          accountHolderName: ele.accountHolderName,
          deposits: ele.deposits,
        };
      }

      return {
        id: ele.id,
        subNum: ele.subNum,
        bankName: ele.bankName,
        branchName: ele.branchName,
        accountType: ele.accountType,
        accountHolderName: ele.accountHolderName,
        deposits: ele.deposits,
      };
    });

    return setDepositsContent(newDepositsContent);
  };

  const addcard = () => {
    const randomId = Math.random().toString();
    const addDepositsContentObject = {
      id: randomId,
      subNum: null,
      bankName: '',
      branchName: '',
      accountType: '',
      accountHolderName: '',
      deposits: undefined,
    };

    setDepositsContent([...depositsContent, addDepositsContentObject]);
  };

  const deleteCard = (deleteSubjectId: string) => {
    const newDepositsContentAfterDelete = depositsContent.filter(
      (ele) => ele.id !== deleteSubjectId,
    );

    return setDepositsContent(newDepositsContentAfterDelete);
  };

  // はい、いいえの初期状態管理
  // const openOrClose = depositsArr.length !== 0;
  const openOrClose = depositsContent[0].id !== '0';
  const [open, setOpen] = useState(openOrClose);
  const yesCheck = depositsContent[0].id !== '0';
  const [yesCheckState, setYesCheckState] = useState(yesCheck);
  const noCheck = !!(deposits && deposits.noCheck && deposits.noCheck === true);
  const [noCheckState, setNoCheckState] = useState(noCheck);

  // 銀行名の有無チェック
  const bankNameArr = depositsContent.map((ele) => {
    const bankName =
      ele.bankName === undefined || ele.bankName === null ? '' : ele.bankName;

    return bankName;
  });

  const branchNameArr = depositsContent.map((ele) => {
    const branchName =
      ele.branchName === undefined || ele.branchName === null
        ? ''
        : ele.branchName;

    return branchName;
  });

  const accountTypeNameArr = depositsContent.map((ele) => {
    const aacoutTypeName =
      ele.accountType === undefined || ele.accountType === null
        ? ''
        : ele.accountType;

    return aacoutTypeName;
  });

  const accountHolderNameArr = depositsContent.map((ele) => {
    const accountHolderName =
      ele.accountHolderName === undefined || ele.accountHolderName === null
        ? ''
        : ele.accountHolderName;

    return accountHolderName;
  });

  const propertiesId = data ? data.id : '';

  const { depositsMajorNum } = useQueryContents('deposits_check', data);

  const cardDeleteAll = async () => {
    await DeleteDepositsContent();
    await DeleteDeposits();
    await MutateProperties(propertiesId, {
      userId: user.attributes.sub,
      deposits: undefined,
    });

    setOpen(false);
    setYesCheckState(false);
    window.location.reload();
  };

  const navigate = useNavigate();

  const save = async (link: string) => {
    const reservedDepositsContent = await GetDepositsContent();
    const depositsContentId = depositsContent.map((ele) => ele.id);
    reservedDepositsContent.map((ele) => {
      if (depositsContentId.includes(ele.id) === false) {
        return DeleteDepositsContentById(ele.id);
      }

      return ele;
    });

    const bankNameCheckArr = depositsContent.map((ele) => {
      const bankNameCheck =
        ele.bankName === undefined || ele.bankName === null ? '' : ele.bankName;

      return bankNameCheck;
    });

    if (open === true && bankNameCheckArr.includes('')) {
      window.alert('必須項目を入力してください');
    } else {
      const id = data
        ? data.propertiesDepositsId
          ? data.propertiesDepositsId
          : ''
        : '';

      const createdDeposits = await MutateDeposits(id, {
        majorNum: depositsMajorNum,
        inputFlag: true,
        noCheck: noCheckState,
      });

      const newDepositsContent = open === false ? [] : depositsContent;

      const newContent = await Promise.all(
        newDepositsContent.map(async (ele, index) => {
          const contentItem = await MutateDepositsContentAndGet(
            ele.id,
            index + 1,
            ele.bankName || '',
            ele.branchName || '',
            ele.accountType || '',
            ele.accountHolderName || '',
            createdDeposits,
          );

          return contentItem;
        }),
      );

      logger.info(newContent);

      await MutateProperties(propertiesId, {
        userId: user.attributes.sub,
        deposits: createdDeposits,
      });
      navigate(link);
    }
  };

  const noCheckFunc = async () => {
    await DeleteDepositsContent();
    const noCheckId = data
      ? data.propertiesDepositsId
        ? data.propertiesDepositsId
        : ''
      : '';
    const createdDepositsByNoCheck = await MutateDeposits(noCheckId, {
      majorNum: depositsMajorNum,
      inputFlag: true,
      noCheck: true,
    });

    await MutateProperties(propertiesId, {
      userId: user.attributes.sub,
      deposits: createdDepositsByNoCheck,
    });

    navigate('../stock');
  };

  return (
    <>
      {isDialogShow ? (
        <Dialog
          dialogText="入力内容を削除しますがよろしいですか？"
          dialogYesFunc={cardDeleteAll}
          dialogNoFunc={toggleDialog}
        />
      ) : (
        ''
      )}
      <div className="depositsTop">
        <div className="deposits">
          <div className="pc-only depositsParam1">
            <Param6Checkmark
              StyleClass="styleParam3"
              key="deposits_pc"
              checkMarkSize6
              propertyData={data}
            />
          </div>
          <RadioCustomSingle
            title="あなたの名義の預貯金口座はありますか？"
            lineBreakTitle="（現在の口座残高がゼロでも口座があれば［はい］を押してください。）"
            name="deposits"
            yesFunc={() => {
              setOpen(true);
              setNoCheckState(false);
            }}
            noFunc={noCheckFunc}
            yesCheck={yesCheckState}
            noCheck={noCheckState}
          />
        </div>
        <div
          className={`depositsFormCardArea ${
            open ? 'depositsopen' : 'depositsclose'
          }`}
        >
          <div>
            {depositsContent.map((ele, index, theForms) => (
              <DepositsFormCard
                key={ele.id}
                title="口座情報"
                form={ele.id}
                minusBtnView={theForms.length !== 1}
                name={`CardDeposits${index}`}
                subNum={index + 1}
                bankValue={bankNameArr[index]}
                branchValue={branchNameArr[index]}
                accountHolderValue={accountHolderNameArr[index]}
                accountTypeName={accountTypeNameArr[index]}
                updateDepositsContent={updateDepositsContent}
                subjectId={ele.id}
                deleteCard={deleteCard}
              />
            ))}
          </div>
          <button type="button" className="plusBtn-wrapper" onClick={addcard}>
            <PlusBtn />
          </button>
        </div>
      </div>
      <RequireNextReturn
        returnFunc={async () => {
          await save('../movingproperty');
        }}
        nextFunc={async () => {
          await save('../stock');
        }}
        deleteFunc={toggleDialog}
      />
    </>
  );
};

export default DepositsTemp;
