/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import Slider from 'react-slick';
import { Allocation } from 'models';
import pageData from '../data/pageData';
import PageDescription from '../organisms/pageDescription';
import recipientBack from '../images/recipientImg.svg';
import ParamCheckmark from '../atoms/paramCheckmarkSp';
import './recipientDescription.css';

type Props = {
  data: Allocation | undefined;
};

const RecipientDescription: FC<Props> = (props) => {
  const { data } = props;
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  return (
    <div className="RecipientDescription">
      <div>
        <div className="RecipientParam">
          <ParamCheckmark
            checkMarkSize6={false}
            key="recipientDescription"
            allocationData={data}
          />
        </div>
        <Slider {...settings}>
          <PageDescription
            page="realEstatePage"
            description={pageData[18].description}
          />
          {/* <PageDescription
            page="realEstatePage"
            description={pageData[19].description}
          /> */}
        </Slider>
      </div>
      <img src={recipientBack} alt="受遺者背景画像" />
    </div>
  );
};

export default RecipientDescription;
