import { Storage } from 'aws-amplify';
import { logger } from 'utils/logger';

const DeleteS3 = async (name: string): Promise<void> => {
  try {
    await Storage.remove(name);
    logger.info('削除成功');
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export default DeleteS3;
