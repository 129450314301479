import { FC } from 'react';
import { useQuery } from 'react-query';
import { GetUser } from 'domains/cognite/services/manageUser';
import ExecutorTextHeader from '../organisms/executorTextHeader';
import ExecutorProfileContent from '../organisms/executorProfileContent';
import ExecutorLogoHeader from '../../../common/apply/organisms/executorLogoHeader';
import CommonFooter from '../../../common/apply/templates/commonFooter';

const ExecutorProfile: FC = () => {
  const { data: executor, isFetching } = useQuery(
    ['current_auth_executor_profile'],
    GetUser,
    {
      cacheTime: 200,
    },
  );

  const attributes = executor ? executor.attributes : undefined;

  return isFetching ? (
    <p>Loading...</p>
  ) : (
    <>
      <ExecutorLogoHeader />
      <ExecutorTextHeader title="遺言執行者プロフィール" />
      <ExecutorProfileContent attributes={attributes} />
      <CommonFooter />
    </>
  );
};

export default ExecutorProfile;
