/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { FC, ChangeEvent, useState } from 'react';
import { useQuery } from 'react-query';
import { Bar } from 'react-chartjs-2';
import { getFormatDate1, getFormatDate2 } from 'utils/dateFormat';
import { useUsersListFromAdmin } from 'hooks';
import { GetStayingTime } from 'domains/cognite/services/manageStayingTime';
import { PrefectureArray } from 'user/components/data/prefecture';
import AdminNav from '../organisms/adminNav';
import DataBtns from '../organisms/dataBtns';
import './userTimeOnPage.css';

const UserTimeOnPage: FC = () => {
  const { data: stayingTime } = useQuery(
    ['get_userTimeOnPage'],
    GetStayingTime,
  );

  if (!stayingTime) {
    throw Error();
  }

  const UserDatas = useUsersListFromAdmin(['get_userDatasFromUserTimeOnPage']);

  const addAddressAndAge = stayingTime.map((x) => {
    const findsameId = UserDatas.find((y) => x.userId === y.sub);

    return {
      ...x,
      address: findsameId?.address,
      age: findsameId?.['custom:ages'],
    };
  });

  // today取得（2022-01-01の型）
  const date = new Date();
  const dateYear = date.getFullYear();
  const dateMonth = `0${date.getMonth() + 1}`.slice(-2);
  const dateDay = `0${date.getDate()}`.slice(-2);
  const dateToday = `${dateYear}-${dateMonth}-${dateDay}`;

  const [inputDateString1, setInputDateString1] = useState(dateToday);
  const [inputDateString2, setInputDateString2] = useState(dateToday);

  const getDate1 = (data: ChangeEvent<HTMLInputElement>) => {
    setInputDateString1(data.currentTarget.value);
  };

  const getDate2 = (data: ChangeEvent<HTMLInputElement>) => {
    setInputDateString2(data.currentTarget.value);
  };

  const inputDate1 = getFormatDate1(inputDateString1);
  const inputDate2 = getFormatDate2(inputDateString2);

  const [selectPrefecture, setSelectPrefecture] = useState('全国');
  const [selectAge, setSelectAge] = useState('全年代');

  const getAgeValue = (data: ChangeEvent<HTMLSelectElement>) => {
    setSelectAge(data.currentTarget.value);
  };

  const getPrefectureValue = (data: ChangeEvent<HTMLSelectElement>) => {
    setSelectPrefecture(data.currentTarget.value);
  };

  const filteredStayingTime = addAddressAndAge.filter((ele) => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const eleDate = new Date(ele.date);

    if (inputDate1 <= eleDate && eleDate < inputDate2) {
      if (selectAge === '全年代' && selectPrefecture === '全国') {
        return ele;
      }

      if (selectAge === '全年代' && selectPrefecture === ele.address) {
        return ele;
      }

      if (selectAge === ele.age && selectPrefecture === '全国') {
        return ele;
      }

      if (selectAge === ele.age && selectPrefecture === ele.address) {
        return ele;
      }
    }

    return false;
  });

  const age = [
    '10代',
    '20代',
    '30代',
    '40代',
    '50代',
    '60代',
    '70代',
    '80代',
    '90代',
  ];

  const prefecture = PrefectureArray.map((ele) => ele.label);

  const min5 = filteredStayingTime.filter((ele) => ele.time <= 5);

  const min10 = filteredStayingTime.filter(
    (ele) => ele.time > 5 && ele.time <= 10,
  );

  const min15 = filteredStayingTime.filter(
    (ele) => ele.time > 15 && ele.time <= 20,
  );

  const min20 = filteredStayingTime.filter(
    (ele) => ele.time > 20 && ele.time <= 25,
  );

  const min25 = filteredStayingTime.filter(
    (ele) => ele.time > 25 && ele.time <= 30,
  );

  const min30 = filteredStayingTime.filter((ele) => ele.time > 30);

  const userTimeOnPageFinalData = [
    min5.length,
    min10.length,
    min15.length,
    min20.length,
    min25.length,
    min30.length,
  ];

  const timeArray = [
    '0 - 5分',
    '5 - 10分',
    '10 - 15分',
    '15 - 20分',
    '20 - 25分',
    '25 - 30分',
    '30分以上',
  ];

  const chartData = {
    // x 軸のラベル
    labels: timeArray,
    datasets: [
      {
        label: 'アプリ内滞在時間',
        // データの値
        data: userTimeOnPageFinalData,
        // グラフの背景色
        backgroundColor: ['rgba(255, 99, 132, 0.2)'],
        // グラフの枠線の色
        borderColor: ['rgba(255, 99, 132)'],
        // グラフの枠線の太さ
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <AdminNav />
      <div className="user-time-on-page">
        <div className="user-time-on-page-wrapper">
          <h1>遺言者のデータ分析</h1>
          <p>アプリ内滞在時間</p>
          <div className="user-time-on-page-chart-btns">
            <div className="user-time-on-page-chart">
              <div>
                <input
                  type="date"
                  value={inputDateString1}
                  onChange={(e) => {
                    getDate1(e);
                  }}
                />
                <p>〜</p>
                <input
                  type="date"
                  value={inputDateString2}
                  onChange={(e) => {
                    getDate2(e);
                  }}
                />
              </div>
              <div>
                <select
                  onChange={(e) => {
                    getAgeValue(e);
                  }}
                >
                  <option value="全年代">全年代</option>
                  {age.map((ele) => (
                    <option value={ele}>{ele}</option>
                  ))}
                </select>
                <select
                  onChange={(e) => {
                    getPrefectureValue(e);
                  }}
                >
                  <option value="全国">全国</option>
                  {prefecture.map((ele) => (
                    <option value={ele}>{ele}</option>
                  ))}
                </select>
              </div>
              <Bar data={chartData} />
            </div>
            <DataBtns />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserTimeOnPage;
