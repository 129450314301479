// import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { AssignStatus } from 'models';
import { GetUser } from 'domains/cognite/services/manageUser';
import { QueryAssignRelationshipFromClient } from 'domains/cognite/services/manageAssignRelationship';
import { QueryAssignExecutorNotOnTheList } from 'domains/cognite/services/manageAssignExecutorNotOnTheList';

const useAssignPath = (): string => {
  // const queryClient = useQueryClient();
  // useEffect(() => {
  //   void queryClient.invalidateQueries('AssigneeInList');
  // });

  const getAssignee = async () => {
    const user = await GetUser();
    const data = await QueryAssignRelationshipFromClient(user.attributes.sub);

    return data;
  };

  const { data: AssigneeInList } = useQuery('AssigneeInList', getAssignee, {
    cacheTime: 500,
  });

  const getAssigneeNotOnTheList = async () => {
    const getExecutorChargeQueryContent =
      await QueryAssignExecutorNotOnTheList();
    const data = getExecutorChargeQueryContent[0];

    return data;
  };

  const { data: AssigneeNotOntheList } = useQuery(
    'AssigneeNotOntheList',
    getAssigneeNotOnTheList,
    { cacheTime: 500 },
  );

  const judgeIntheList = !!(AssigneeInList && !!AssigneeInList.length);
  const judgeNotOntheList = !!AssigneeNotOntheList;

  const path1 = '/user/executorallocation';
  const path2 = '/user/executorawaitingapproval';
  const path3 = '/user/executorapproval';
  const path4 = '/user/executorcharge';

  if (judgeIntheList) {
    // 断ったときどうする
    // const judgeStatusIntheList =
    //   AssigneeInList[0].status === AssignStatus.APPROVAL;
    const judgeStatusIntheList = AssigneeInList.some(
      (ele) => ele.status === AssignStatus.APPROVAL,
    );
    if (judgeStatusIntheList) {
      return path3;
    }

    return path2;
  }
  if (judgeNotOntheList) {
    return path4;
  }

  return path1;
};

export default useAssignPath;
