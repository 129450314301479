import { FC } from 'react';
import { Link } from 'react-router-dom';
import headerLogo from '../images/headerLogo.svg';
import './homeLink.css';

const HomeLink: FC = () => (
  <Link to="/">
    <div className="home">
      <img src={headerLogo} alt="logo" />
    </div>
  </Link>
);

export default HomeLink;
