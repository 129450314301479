/* eslint-disable react/require-default-props */
import { FC } from 'react';
import './radioCustomScroll.css';

type Props = {
  title: string;
  name: string;
  yesFunc?: () => void;
  noFunc?: () => void;
  yesCheck?: boolean;
  noCheck?: boolean;
  // ref?:HTMLInputElement;
};

const RadioCustomScroll: FC<Props> = (props) => {
  const { title, name, yesFunc, noFunc, yesCheck, noCheck } = props;
  const scroll = () => {
    window.scrollBy({
      top: 80,
      behavior: 'smooth',
    });
  };

  return (
    <div className="radioCustomScroll">
      <p className="yesno-title">{title}</p>
      <div className="yesno">
        <input
          type="radio"
          id={`${name}1`}
          name={name}
          value="はい"
          onChange={yesFunc}
          onClick={scroll}
          defaultChecked={!!yesCheck}
          // ref={ref}
        />
        <label htmlFor={`${name}1`} id="yes">
          はい{}
        </label>
        <input
          type="radio"
          id={`${name}2`}
          name={name}
          value="いいえ"
          onChange={noFunc}
          onClick={scroll}
          defaultChecked={!!noCheck}
        />
        <label htmlFor={`${name}2`} id="no">
          いいえ{}
        </label>
      </div>
    </div>
  );
};

export default RadioCustomScroll;
