import { Auth } from 'aws-amplify';
import { logger } from 'utils/logger';
import { CognitoIdentityProvider } from '@aws-sdk/client-cognito-identity-provider';
import { CognitoUserExt, UserAttributes } from '../user';

export const DeleteMyself = async (): Promise<void> => {
  try {
    logger.info('退出処理を開始します');
    // FIX次第、ユーザ操作以外のDataStoreの削除処理を追加

    const UserSession = await Auth.currentSession();
    const cognitoIdentityProvider = new CognitoIdentityProvider({
      region: 'ap-northeast-1',
    });
    const params = {
      AccessToken: UserSession.getAccessToken().getJwtToken(),
    };
    const result = await cognitoIdentityProvider.deleteUser(params);
    logger.debug(result);
    logger.info('退出処理を完了しました');

    await Auth.signOut({ global: true });
    // window.location.reload();
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetUser = async (): Promise<CognitoUserExt> => {
  try {
    logger.info('ユーザプロファイルのフェッチを開始します');
    const user: CognitoUserExt =
      (await Auth.currentAuthenticatedUser()) as CognitoUserExt;
    logger.debug(user);
    logger.info('ユーザプロファイルのフェッチが完了しました');

    return user;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export type Group = 'User' | 'Executor' | 'Admin' | 'Sign Out';

export const GetGroup = async (): Promise<Group> => {
  try {
    const session = await Auth.currentSession();
    const { payload } = session.getIdToken();

    if (!(payload && payload['cognito:groups'])) {
      return 'Sign Out';
    }

    const group: Group = (payload['cognito:groups'] as Group[])[0];

    return group;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

// 削除していいかも 使ってない
export const GetSessionAndGroup = async (): Promise<{
  sessionCheck: boolean;
  group: string;
}> => {
  try {
    const session = await Auth.currentSession();
    const { payload } = session.getIdToken();

    const group: string =
      payload && payload['cognito:groups']
        ? (payload['cognito:groups'] as string[])[0]
        : '';

    return { sessionCheck: !!session, group };
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const UpdateUser = async (instance: UserAttributes): Promise<void> => {
  try {
    logger.info('ユーザプロファイルのアップデートを開始します');
    const currentuser: unknown = await Auth.currentAuthenticatedUser();
    const updateduser = await Auth.updateUserAttributes(currentuser, instance);
    logger.debug(updateduser);
    logger.info('ユーザプロファイルのアップデートが完了しました');
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const ChangeEmail = async (email: string): Promise<void> => {
  try {
    logger.info('Eメールアドレスの変更を開始します');
    const currentuser: CognitoUserExt =
      (await Auth.currentAuthenticatedUser()) as CognitoUserExt;
    logger.debug(currentuser);
    logger.debug(email);
    const result = await Auth.updateUserAttributes(currentuser, {
      email,
      'custom:validated_email': currentuser.attributes.email,
    });
    logger.debug(result);
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const ChangePassword = async (
  currentPassword: string,
  newPassword: string,
): Promise<void> => {
  try {
    logger.info('パスワードの変更を開始します');
    const currentuser: CognitoUserExt =
      (await Auth.currentAuthenticatedUser()) as CognitoUserExt;
    const result = await Auth.changePassword(
      currentuser,
      currentPassword,
      newPassword,
    );
    logger.debug(result);
  } catch (error) {
    logger.error(error);
    // throw error;
  }
};

export const VerifyOTP = async (email: string, code: string): Promise<void> => {
  try {
    logger.info('OTPの確認を開始します');
    const currentuser: CognitoUserExt =
      (await Auth.currentAuthenticatedUser()) as CognitoUserExt;
    const VerifyResult = await Auth.verifyUserAttributeSubmit(
      currentuser,
      'email',
      code,
    );
    if (VerifyResult === 'SUCCESS') {
      const UpdateResult = await Auth.updateUserAttributes(currentuser, {
        email,
        'custom:validated_email': email,
      });
      logger.info('OTPの確認が完了しました');
      logger.debug(UpdateResult);
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
