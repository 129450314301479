import { FC } from 'react';
import { Link } from 'react-router-dom';
import NormalBtn from '../atoms/normalBtn';
import './footerMenuBeforeLoginContent.css';

type Props = {
  setShow: () => void;
  homeLink: string;
};

const FooterMenuBeforeLoginContent: FC<Props> = (props) => {
  const { setShow, homeLink } = props;

  return (
    <div className="footerMenuBeforeLoginContent">
      <div>
        <div>
          <Link to={homeLink} onClick={() => setShow()}>
            <p>トップ</p>
          </Link>
          <Link to="/topics/list">
            <p>トピックス</p>
          </Link>
          <Link to="/question" onClick={() => setShow()}>
            <p>よくあるご質問</p>
          </Link>
          <Link to="/companyinfo" onClick={() => setShow()}>
            <p>法人概要</p>
          </Link>
          <Link to="/contact" onClick={() => setShow()}>
            <p>お問い合わせ</p>
          </Link>
          <NormalBtn
            title="ログイン"
            className="normalBtn"
            href="/willSignIn"
          />
        </div>
      </div>
    </div>
  );
};

export default FooterMenuBeforeLoginContent;
