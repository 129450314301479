import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { logger } from 'utils/logger';
import './navigateBtn.css';

const NavigateBtn: FC = () => {
  const navigate = useNavigate();

  return (
    <button className="normalBtn" type="button" onClick={() => navigate(-1)}>
      戻る
    </button>
  );
};

NavigateBtn.defaultProps = {
  func: () => {
    logger.info('ボタンを押しました');
  },
};

export default NavigateBtn;
