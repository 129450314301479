/* eslint-disable react/require-default-props */
import { Dispatch, FC, SetStateAction } from 'react';
import './paginationBtns.css';

type Props = {
  arrayLength: number;
  setSelectedNum: Dispatch<SetStateAction<number>>;
  selectedNum: number;
  divisor?: number;
  prevWord?: string;
  nextWord?: string;
  allowIcon?: boolean;
  allowColor?: string;
  size?: string;
  background?: string;
  activeBackground?: string;
  color?: string;
  activeColor?: string;
};

const PaginationBtns: FC<Props> = (props) => {
  const {
    arrayLength,
    selectedNum,
    setSelectedNum,
    divisor,
    prevWord,
    nextWord,
    allowIcon,
    allowColor,
    size,
    background,
    activeBackground,
    color,
    activeColor,
  } = props;

  // 1ページに物を10個表示する場合、配列を10で割った数 = ページネーションのボタンの数
  const getNumberOfBtn = () => {
    if (!divisor) {
      if (arrayLength % 10 === 0) {
        return Math.floor(arrayLength / 10);
      }

      return Math.floor(arrayLength / 10 + 1);
    }
    if (arrayLength % divisor === 0) {
      return Math.floor(arrayLength / divisor);
    }

    return Math.floor(arrayLength / divisor + 1);
  };

  const numberOfBtn = getNumberOfBtn();

  // sliceで削るために配列が必要なので[1,2,3,..]と増えていくだけの配列を用意([1,2,3...]はbuttonのvalueに使われる)
  const paginationBtnArray = Array.from(
    Array(numberOfBtn).keys(),
    (ele) => ele,
  );

  const btnArraySlice = () => {
    if (selectedNum < 4) {
      return paginationBtnArray.slice(0, 5);
    }
    if (selectedNum >= numberOfBtn - 4) {
      return paginationBtnArray.slice(numberOfBtn - 5, numberOfBtn);
    }

    // 選択しているボタンを真ん中に表示する
    return paginationBtnArray.slice(selectedNum - 2, selectedNum + 3);
  };

  const slicedBtnArray = btnArraySlice();

  const sizeState =
    // eslint-disable-next-line no-nested-ternary
    size === 'S' ? 'small' : size === 'L' ? 'large' : 'midium';

  const backgroundState = background || '#fff';
  const activeBackgroundState = activeBackground || '#555';
  const colorState = color || '#555';
  const activeColorState = activeColor || '#fff';

  const allowColorState = allowColor || '#555';

  return (
    <div className={`pagination-b pagination-${sizeState}`}>
      <div className="allow" />
      <button
        className={selectedNum === 0 ? 'btn-hidden' : ''}
        onClick={() => setSelectedNum(0)}
        type="button"
      >
        {}
        {allowIcon ? (
          <div
            className="pagination-allow pagination-prev-allow"
            style={{
              borderTop: `3px solid ${allowColorState}`,
              borderRight: `3px solid ${allowColorState}`,
            }}
          />
        ) : (
          prevWord || '最初へ'
        )}
      </button>
      <div className="number-btns">
        {slicedBtnArray.map((ele) => (
          <button
            style={
              selectedNum === ele
                ? {
                    backgroundColor: `${activeBackgroundState}`,
                    color: `${activeColorState}`,
                  }
                : {
                    backgroundColor: `${backgroundState}`,
                    color: `${colorState}`,
                  }
            }
            onClick={() => setSelectedNum(ele)}
            type="button"
          >
            {ele + 1}
          </button>
        ))}
      </div>
      <button
        className={selectedNum + 1 === numberOfBtn ? 'btn-hidden' : ''}
        onClick={() => setSelectedNum(numberOfBtn - 1)}
        type="button"
      >
        {allowIcon ? (
          <div
            className="pagination-allow pagination-next-allow"
            style={{
              borderTop: `3px solid ${allowColorState}`,
              borderRight: `3px solid ${allowColorState}`,
            }}
          />
        ) : (
          nextWord || '最後へ'
        )}
      </button>
    </div>
  );
};

export default PaginationBtns;
