/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import Slider from 'react-slick';
import pageData from '../data/pageData';
import PageDescription from '../organisms/pageDescription';
import ConfirmBack from '../images/ConfirmBack.svg';
import './confirmDescription.css';

const ConfirmDescription: FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplayspeed: 200,
    arrows: false,
  };

  return (
    <div className="ConfirmDescription">
      <Slider {...settings}>
        <PageDescription
          page="realEstatePage"
          description={pageData[22].description}
          NotParamDescription="NotParamDescription"
        />
      </Slider>
      <div>
        <img src={ConfirmBack} alt="遺言書の確認背景画像" />
      </div>
    </div>
  );
};

export default ConfirmDescription;
