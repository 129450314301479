/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import { DataStore } from 'aws-amplify';
import { Deposits, DepositsContent, DepositsContentMetaData } from 'models';
import { logger } from 'utils/logger';
import { ModelInit } from '@aws-amplify/datastore';

export const MutateDepositsContentAndGet = async (
  id: string,
  subNum: number,
  bankName: string,
  branchName: string,
  accountType: string,
  accountHolderName: string,
  deposits?: Deposits,
): Promise<DepositsContent> => {
  try {
    logger.info('預貯金の項目の作成または更新を開始します');
    const original = await DataStore.query(DepositsContent, id);

    if (original) {
      logger.info(original);
      const data = await DataStore.save(
        DepositsContent.copyOf(original, (updated) => {
          updated.subNum = subNum;
          updated.bankName = bankName;
          updated.branchName = branchName;
          updated.accountType = accountType;
          updated.accountHolderName = accountHolderName;
          updated.deposits = deposits;
        }),
      );
      logger.info(data);
      logger.info('預貯金の項目の更新が完了しました');

      return data;
    }
    logger.info('預貯金の項目の作成を開始します');
    const instance = {
      subNum,
      bankName,
      branchName,
      accountType,
      accountHolderName,
      deposits,
    };
    const data = await DataStore.save(new DepositsContent(instance));
    logger.debug(data);
    logger.info('預貯金の項目の作成が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const CreateDepositsContentAndGetId = async (
  instance: ModelInit<DepositsContent, DepositsContentMetaData>,
): Promise<string> => {
  try {
    logger.info('預貯金の項目の作成を開始します');
    const data = await DataStore.save(new DepositsContent(instance));
    logger.debug(data);
    logger.info('預貯金の項目の作成が完了しました');

    return data.id;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const UpdateDepositsContentById = async (
  id: string,
  bankName: string,
  branchName: string,
  accountType: string,
  accountHolderName: string,
): Promise<void> => {
  try {
    logger.info('預貯金の項目の更新を開始します');

    const original = await DataStore.query(DepositsContent, id);

    if (original) {
      logger.info(original);
      const data = await DataStore.save(
        DepositsContent.copyOf(original, (updated) => {
          updated.bankName = bankName;
          updated.branchName = branchName;
          updated.accountType = accountType;
          updated.accountHolderName = accountHolderName;
        }),
      );
      logger.info(data);
      logger.info('預貯金の項目の更新が完了しました');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteDepositsContentById = async (id: string): Promise<void> => {
  try {
    logger.info('預貯金の項目の削除を開始します');
    const original = await DataStore.query(DepositsContent, id);
    if (original) {
      logger.info(original);
      await DataStore.delete(original);
      logger.info('預貯金の項目の削除が完了しました');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetDepositsContent = async (): Promise<DepositsContent[]> => {
  try {
    logger.info('預貯金の項目のフェッチを開始します');
    const data = await DataStore.query(DepositsContent);
    logger.debug(data);
    logger.info('預貯金の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetDepositsContentByParent = async (
  deposits: Deposits | null,
): Promise<DepositsContent[]> => {
  try {
    logger.info('預貯金の項目のフェッチを開始します');
    const data = (await DataStore.query(DepositsContent)).filter((c) => {
      if (c.deposits === null || c.deposits === undefined || !deposits) {
        return false;
      }
      const depositsId = c.deposits.id;

      return deposits.id === depositsId;
    });
    logger.debug(data);
    logger.info('預貯金の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteDepositsContent = async (): Promise<DepositsContent[]> => {
  try {
    logger.info('預貯金の項目のフェッチを開始します');
    const data = await DataStore.query(DepositsContent);
    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);

          return data;
        }),
      );
    }
    logger.debug(data);
    logger.info('預貯金の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
