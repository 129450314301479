/* eslint-disable no-alert */
import { FC, ChangeEvent } from 'react';
import { useQuery } from 'react-query';
import { AllocationProperties } from 'models';
import { GetAllocationReceiver } from 'domains/cognite/services/manageAllocationReceiver';
import minusBtn from '../images/minusBtn.svg';
import InputItem from '../atoms/InputItem';
import './accordionCard.css';

type Props = {
  title: string;
  form: number;
  minusBtnView: boolean;
  queryParam: number;
  defaultDataItem: AllocationProperties | undefined;
  liabilitiesCheck: boolean;
  updateAllocationProperties: (
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    target: string | number,
  ) => void;
  subjectId: string;
  deleteCard: (deleteSubject: string) => void;
};

const AccordionCard: FC<Props> = (props) => {
  const {
    title,
    form,
    minusBtnView,
    queryParam,
    defaultDataItem,
    liabilitiesCheck,
    updateAllocationProperties,
    subjectId,
    deleteCard,
  } = props;

  const scroll = () => {
    window.scrollBy({
      top: 100,
      behavior: 'smooth',
    });
  };

  const updateRatioData = (e: ChangeEvent<HTMLInputElement>) => {
    updateAllocationProperties(subjectId, e, 'ratio');
  };

  const updateReceiverNameData = (e: ChangeEvent<HTMLSelectElement>) => {
    updateAllocationProperties(subjectId, e, 'receiverName');
  };

  const deleteCardFunc = () => {
    deleteCard(subjectId);
  };

  const { data: allocationReceiversByQuery } = useQuery(
    [`receiver_${queryParam}`],
    GetAllocationReceiver,
  );

  const allocationReceivers = allocationReceiversByQuery || [];

  const defaultNameValue = defaultDataItem ? defaultDataItem.receiverName : '';
  const defaultRatioValue = defaultDataItem ? defaultDataItem.ratio : '';

  const checkedTitle = liabilitiesCheck ? '負担人' : title;
  const checkedOption = liabilitiesCheck ? '負担人' : '受取人';
  const checkedNameTitle = liabilitiesCheck ? '負担人名' : '受取人名';
  const checkedRatioTitle = liabilitiesCheck ? '負担割合' : '受取割合';

  return (
    <div className="accordionText accordionCard">
      <div>
        <p>
          {checkedTitle}
          {form + 1}
        </p>
        <button
          type="button"
          onClick={deleteCardFunc}
          className={minusBtnView ? 'accordionCardopen' : 'accordionCardclose'}
        >
          <div>
            <img src={minusBtn} alt="minus-Button" />
            <p>削除</p>
          </div>
        </button>
      </div>
      <div className="AccordionCardElement">
        <div>
          <label htmlFor="recipientName">
            <InputItem title={checkedNameTitle} name="require" />
            <select
              id="recipientName"
              name="recipientName"
              onBlur={scroll}
              onChange={updateReceiverNameData}
            >
              <option value="" disabled selected hidden>
                {checkedOption}を選択
              </option>
              {allocationReceivers.map((ele) => {
                const name = ele.name || '';

                return (
                  <option
                    key={ele.id}
                    defaultValue={name}
                    selected={defaultNameValue === name}
                  >
                    {name}
                  </option>
                );
              })}
            </select>
          </label>
        </div>
        <div>
          <label htmlFor="ratio">
            <InputItem title={checkedRatioTitle} name="require" />
            <input
              type="number"
              id="ratio"
              name="ratio"
              placeholder="50"
              onBlur={scroll}
              onChange={updateRatioData}
              autoComplete="off"
              defaultValue={defaultRatioValue || ''}
            />
            %
          </label>
        </div>
      </div>
    </div>
  );
};

export default AccordionCard;
