/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import { FC, useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Allocation, AllocationReceiver } from 'models';
import {
  GetAllocation,
  MutateAllocation,
} from 'domains/cognite/services/manageAllocation';
import {
  MutateAllocationReceiverAndGetId,
  GetAllocationReceiver,
  DeleteLegalHeir,
  GetAllocationReceiverByParent,
  DeleteAllocationReceiverById,
} from 'domains/cognite/services/manageAllocationReceiver';
import Dialog from 'common/apply/organisms/dialog';
import {
  DeleteLegalHeirQuestion,
  GetLegalHeirQuestion,
  MutateLegalHeirQuestion,
} from 'domains/cognite/services/manageLegalHeirQuestion';
import { CognitoUserExt } from 'domains/cognite/user';
import nonNullable from 'utils/nonNullable';
import { logger } from 'utils/logger';
import Param2Checkmark from '../atoms/paramCheckmarkPc';
import RadioCustomClear from '../molecules/radioCustomClear';
import LegalHeirFormArea from '../organisms/legalHeirFormArea';
import NextReturnLegalHeir from '../molecules/nextReturnLegalHeir';
import './legalHeirTemp.css';

type Props = {
  data: Allocation | undefined;
  user: CognitoUserExt;
  idCheck: string;
};

const LegalHeirTemp: FC<Props> = (props) => {
  const { data, user, idCheck } = props;
  const { data: legalHeirQuestionData } = useQuery(
    ['legalHeir_QuestionData'],
    () => GetLegalHeirQuestion(),
    {
      cacheTime: 200,
    },
  );

  const [isDialogShow, setIsDialogShow] = useState(false);

  const toggelDialog = () => {
    setIsDialogShow(!isDialogShow);
  };

  const legalHeirQuestionDataContent = legalHeirQuestionData || [];

  const [legalHeirQuestionDataId, setLegalHeirQuestionDataId] = useState('');

  const defaultQuestion1 =
    legalHeirQuestionDataContent &&
    legalHeirQuestionDataContent[0] &&
    legalHeirQuestionDataContent[0].question1 !== null
      ? legalHeirQuestionDataContent[0].question1
      : undefined;
  const defaultQuestion2 =
    legalHeirQuestionDataContent &&
    legalHeirQuestionDataContent[0] &&
    legalHeirQuestionDataContent[0].question2 !== null
      ? legalHeirQuestionDataContent[0].question2
      : undefined;
  const defaultQuestion3 =
    legalHeirQuestionDataContent &&
    legalHeirQuestionDataContent[0] &&
    legalHeirQuestionDataContent[0].question3 !== null
      ? legalHeirQuestionDataContent[0].question3
      : undefined;
  const defaultQuestion4 =
    legalHeirQuestionDataContent &&
    legalHeirQuestionDataContent[0] &&
    legalHeirQuestionDataContent[0].question4 !== null
      ? legalHeirQuestionDataContent[0].question4
      : undefined;
  const defaultQuestion5 =
    legalHeirQuestionDataContent &&
    legalHeirQuestionDataContent[0] &&
    legalHeirQuestionDataContent[0].question5 !== null
      ? legalHeirQuestionDataContent[0].question5
      : undefined;
  const defaultQuestion6 =
    legalHeirQuestionDataContent &&
    legalHeirQuestionDataContent[0] &&
    legalHeirQuestionDataContent[0].question6 !== null
      ? legalHeirQuestionDataContent[0].question6
      : undefined;
  const defaultQuestion7 =
    legalHeirQuestionDataContent &&
    legalHeirQuestionDataContent[0] &&
    legalHeirQuestionDataContent[0].question7 !== null
      ? legalHeirQuestionDataContent[0].question7
      : undefined;
  const defaultQuestion8 =
    legalHeirQuestionDataContent &&
    legalHeirQuestionDataContent[0] &&
    legalHeirQuestionDataContent[0].question8 !== null
      ? legalHeirQuestionDataContent[0].question8
      : undefined;
  const defaultQuestion9 =
    legalHeirQuestionDataContent &&
    legalHeirQuestionDataContent[0] &&
    legalHeirQuestionDataContent[0].question9 !== null
      ? legalHeirQuestionDataContent[0].question9
      : undefined;
  const defaultQuestion10 =
    legalHeirQuestionDataContent &&
    legalHeirQuestionDataContent[0] &&
    legalHeirQuestionDataContent[0].question10 !== null
      ? legalHeirQuestionDataContent[0].question10
      : undefined;
  const defaultQuestion11 =
    legalHeirQuestionDataContent &&
    legalHeirQuestionDataContent[0] &&
    legalHeirQuestionDataContent[0].question11 !== null
      ? legalHeirQuestionDataContent[0].question11
      : undefined;

  const [open, setOpen] = useState([
    true,
    defaultQuestion1 !== undefined,
    defaultQuestion3 !== undefined,
    defaultQuestion4 !== undefined,
    defaultQuestion5 !== undefined,
    defaultQuestion6 !== undefined,
    defaultQuestion7 !== undefined,
    defaultQuestion8 !== undefined,
    defaultQuestion9 !== undefined,
    defaultQuestion10 !== undefined,
    defaultQuestion11 !== undefined,
  ]);

  const [openForm, setOpenForm] = useState([
    defaultQuestion1 === true,
    false,
    defaultQuestion3 === true,
    defaultQuestion4 === true,
    defaultQuestion5 === true,
    defaultQuestion6 === true,
    defaultQuestion7 === true,
    defaultQuestion8 === true,
    false,
    defaultQuestion10 === true,
    !!(defaultQuestion11 === true || defaultQuestion11 === false),
  ]);

  const [checkedStatus, setCheckedStatus] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const allFalse = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];

  const [question1, setQuestion1] = useState<boolean | undefined>(
    defaultQuestion1,
  );
  const [question2, setQuestion2] = useState<boolean | undefined>(
    defaultQuestion2,
  );
  const [question3, setQuestion3] = useState<boolean | undefined>(
    defaultQuestion3,
  );
  const [question4, setQuestion4] = useState<boolean | undefined>(
    defaultQuestion4,
  );
  const [question5, setQuestion5] = useState<boolean | undefined>(
    defaultQuestion5,
  );
  const [question6, setQuestion6] = useState<boolean | undefined>(
    defaultQuestion6,
  );
  const [question7, setQuestion7] = useState<boolean | undefined>(
    defaultQuestion7,
  );
  const [question8, setQuestion8] = useState<boolean | undefined>(
    defaultQuestion8,
  );
  const [question9, setQuestion9] = useState<boolean | undefined>(
    defaultQuestion9,
  );
  const [question10, setQuestion10] = useState<boolean | undefined>(
    defaultQuestion10,
  );
  const [question11, setQuestion11] = useState<boolean | undefined>(
    defaultQuestion11,
  );

  const question1Click = (check: boolean) => {
    setQuestion1(check);
  };
  const question2Click = (check: boolean) => {
    setQuestion2(check);
  };
  const question3Click = (check: boolean) => {
    setQuestion3(check);
  };
  const question4Click = (check: boolean) => {
    setQuestion4(check);
  };
  const question5Click = (check: boolean) => {
    setQuestion5(check);
  };
  const question6Click = (check: boolean) => {
    setQuestion6(check);
  };
  const question7Click = (check: boolean) => {
    setQuestion7(check);
  };
  const question8Click = (check: boolean) => {
    setQuestion8(check);
  };
  const question9Click = (check: boolean) => {
    setQuestion9(check);
  };
  const question10Click = (check: boolean) => {
    setQuestion10(check);
  };
  const question11Click = (check: boolean) => {
    setQuestion11(check);
  };

  const [formMessage11, setFormMessage11] = useState<0 | 1>(0);
  // index 10 の文書の切り替え

  // 法定相続人と受遺者のデータ取得

  const { data: legalHeirAllArrData } = useQuery(
    ['legalHeirAllArrData'],
    () => GetAllocation(),
    {
      cacheTime: 200,
    },
  );

  const { data: legalHeirAllArr } = useQuery(
    ['legalHeirAllArr'],
    () =>
      GetAllocationReceiverByParent(
        legalHeirAllArrData ? legalHeirAllArrData[0] : null,
      ),
    {
      cacheTime: 200,
    },
  );

  const [allocationReceivers, setAllocationReceivers] = useState<
    AllocationReceiver[]
  >(legalHeirAllArr || []);

  // 法定相続人のデータのみ取得
  const legalHeirArr = allocationReceivers
    ? allocationReceivers
        .filter((ele) => ele.type === '法定相続人')
        .filter(nonNullable)
    : [];

  const legalHeirContentData = legalHeirArr;

  // 初期状態のオブジェクト
  const initialLegalHeirContentArrData: AllocationReceiver[] = [];

  const initialLegalHeirnContentArr =
    legalHeirArr.length === 0 ? initialLegalHeirContentArrData : legalHeirArr;

  const [legalHeirContent, setLegalHeirContent] = useState<
    AllocationReceiver[]
  >(initialLegalHeirnContentArr);

  const updateLegalHeirNameContent = (
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement>,
    target: string,
  ) => {
    const newLegalHeirContent = legalHeirContent.map((ele) => {
      if (ele.id === subjectKey) {
        return {
          id: ele.id,
          legalHeirQuestionId: ele.legalHeirQuestionId,
          num: ele.num,
          name:
            target === 'name'
              ? event.target.value
              : ele.name === null || ele.name === undefined
              ? ''
              : ele.name,
          birth: ele.birth,
          type: ele.type,
          allocation: ele.allocation,
        };
      }

      return {
        id: ele.id,
        legalHeirQuestionId: ele.legalHeirQuestionId,
        num: ele.num,
        name: ele.name,
        birth: ele.birth,
        type: ele.type,
        allocation: ele.allocation,
      };
    });

    return setLegalHeirContent(newLegalHeirContent);
  };

  const updateLegalHeirBirthContent = (
    subjectKey: string,
    updateData: string,
    target: string,
  ) => {
    const newLegalHeirContent = legalHeirContent.map((ele) => {
      if (ele.id === subjectKey) {
        return {
          id: ele.id,
          legalHeirQuestionId: ele.legalHeirQuestionId,
          num: ele.num,
          name: ele.name,
          birth:
            target === 'birth'
              ? updateData
              : ele.birth === null || ele.birth === undefined
              ? ''
              : ele.birth,
          type: ele.type,
          allocation: ele.allocation,
        };
      }

      return {
        id: ele.id,
        legalHeirQuestionId: ele.legalHeirQuestionId,
        num: ele.num,
        name: ele.name,
        birth: ele.birth,
        type: ele.type,
        allocation: ele.allocation,
      };
    });

    return setLegalHeirContent(newLegalHeirContent);
  };

  const addCard = (subjectLegalHeirQuestionId: number) => {
    const randomId = Math.random().toString();
    const addLegalHeirContentObject = {
      id: randomId,
      legalHeirQuestionId: subjectLegalHeirQuestionId,
      num: null,
      name: '',
      birth: '',
      type: '法定相続人',
      allocation: undefined,
    };

    setLegalHeirContent([...legalHeirContent, addLegalHeirContentObject]);
  };

  const deleteCard = (deleteSubjectId: string) => {
    const newLegalHeirContentAfterDelete = legalHeirContent.filter(
      (ele) => ele.id !== deleteSubjectId,
    );

    setLegalHeirContent(newLegalHeirContentAfterDelete);
  };

  const question1NoClick = () => {
    const legalHeirContentAfterDelete = legalHeirContent.filter(
      (ele) => ele.legalHeirQuestionId !== 1,
    );

    setLegalHeirContent(legalHeirContentAfterDelete);
  };

  const question2YesClick = () => {
    const legalHeirContentAfterDelete = legalHeirContent
      .filter((ele) => ele.legalHeirQuestionId !== 5)
      .filter((ele) => ele.legalHeirQuestionId !== 6)
      .filter((ele) => ele.legalHeirQuestionId !== 7)
      .filter((ele) => ele.legalHeirQuestionId !== 8)
      .filter((ele) => ele.legalHeirQuestionId !== 9)
      .filter((ele) => ele.legalHeirQuestionId !== 10)
      .filter((ele) => ele.legalHeirQuestionId !== 11);

    setLegalHeirContent(legalHeirContentAfterDelete);
  };

  const question2NoClick = () => {
    const legalHeirContentAfterDelete = legalHeirContent
      .filter((ele) => ele.legalHeirQuestionId !== 3)
      .filter((ele) => ele.legalHeirQuestionId !== 4);

    setLegalHeirContent(legalHeirContentAfterDelete);
  };

  const question3YesClick = () => {
    const randomId = Math.random().toString();
    const addLegalHeirContentObject = {
      id: randomId,
      legalHeirQuestionId: 3,
      num: null,
      name: '',
      birth: '',
      type: '法定相続人',
      allocation: undefined,
    };

    const legalHeirContentAfterAdd = [
      ...legalHeirContent,
      addLegalHeirContentObject,
    ];

    const legalHeirContentAfterDelete = legalHeirContentAfterAdd
      .filter((ele) => ele.legalHeirQuestionId !== 4)
      .filter((ele) => ele.legalHeirQuestionId !== 5)
      .filter((ele) => ele.legalHeirQuestionId !== 6)
      .filter((ele) => ele.legalHeirQuestionId !== 7)
      .filter((ele) => ele.legalHeirQuestionId !== 8)
      .filter((ele) => ele.legalHeirQuestionId !== 9)
      .filter((ele) => ele.legalHeirQuestionId !== 10)
      .filter((ele) => ele.legalHeirQuestionId !== 11);

    setLegalHeirContent(legalHeirContentAfterDelete);
  };

  const question3NoClick = () => {
    const legalHeirContentAfterDelete = legalHeirContent.filter(
      (ele) => ele.legalHeirQuestionId !== 3,
    );

    setLegalHeirContent(legalHeirContentAfterDelete);
  };

  const question4YesClick = () => {
    const randomId = Math.random().toString();
    const addLegalHeirContentObject = {
      id: randomId,
      legalHeirQuestionId: 4,
      num: null,
      name: '',
      birth: '',
      type: '法定相続人',
      allocation: undefined,
    };

    const legalHeirContentAfterAdd = [
      ...legalHeirContent,
      addLegalHeirContentObject,
    ];

    const legalHeirContentAfterDelete = legalHeirContentAfterAdd
      .filter((ele) => ele.legalHeirQuestionId !== 5)
      .filter((ele) => ele.legalHeirQuestionId !== 6)
      .filter((ele) => ele.legalHeirQuestionId !== 7)
      .filter((ele) => ele.legalHeirQuestionId !== 8)
      .filter((ele) => ele.legalHeirQuestionId !== 10)
      .filter((ele) => ele.legalHeirQuestionId !== 11);

    setLegalHeirContent(legalHeirContentAfterDelete);
  };

  const question4NoClick = () => {
    const legalHeirContentAfterDelete = legalHeirContent.filter(
      (ele) => ele.legalHeirQuestionId !== 4,
    );

    setLegalHeirContent(legalHeirContentAfterDelete);
  };

  const question5YesClick = () => {
    const randomId = Math.random().toString();
    const addLegalHeirContentObject = {
      id: randomId,
      legalHeirQuestionId: 5,
      num: null,
      name: '',
      birth: '',
      type: '法定相続人',
      allocation: undefined,
    };

    const legalHeirContentAfterAdd = [
      ...legalHeirContent,
      addLegalHeirContentObject,
    ];

    const legalHeirContentAfterDelete = legalHeirContentAfterAdd
      .filter((ele) => ele.legalHeirQuestionId !== 6)
      .filter((ele) => ele.legalHeirQuestionId !== 7)
      .filter((ele) => ele.legalHeirQuestionId !== 8)
      .filter((ele) => ele.legalHeirQuestionId !== 9)
      .filter((ele) => ele.legalHeirQuestionId !== 10)
      .filter((ele) => ele.legalHeirQuestionId !== 11);

    setLegalHeirContent(legalHeirContentAfterDelete);
  };

  const question5NoClick = () => {
    const legalHeirContentAfterDelete = legalHeirContent.filter(
      (ele) => ele.legalHeirQuestionId !== 5,
    );

    setLegalHeirContent(legalHeirContentAfterDelete);
  };

  const question6YesClick = () => {
    const randomId = Math.random().toString();
    const addLegalHeirContentObject = {
      id: randomId,
      legalHeirQuestionId: 6,
      num: null,
      name: '',
      birth: '',
      type: '法定相続人',
      allocation: undefined,
    };

    const legalHeirContentAfterAdd = [
      ...legalHeirContent,
      addLegalHeirContentObject,
    ];

    const legalHeirContentAfterDelete = legalHeirContentAfterAdd
      .filter((ele) => ele.legalHeirQuestionId !== 7)
      .filter((ele) => ele.legalHeirQuestionId !== 8)
      .filter((ele) => ele.legalHeirQuestionId !== 10)
      .filter((ele) => ele.legalHeirQuestionId !== 11);

    setLegalHeirContent(legalHeirContentAfterDelete);
  };

  const question6NoClick = () => {
    const legalHeirContentAfterDelete = legalHeirContent.filter(
      (ele) => ele.legalHeirQuestionId !== 6,
    );

    setLegalHeirContent(legalHeirContentAfterDelete);
  };

  const question7YesClick = () => {
    const randomId = Math.random().toString();
    const addLegalHeirContentObject = {
      id: randomId,
      legalHeirQuestionId: 7,
      num: null,
      name: '',
      birth: '',
      type: '法定相続人',
      allocation: undefined,
    };

    const legalHeirContentAfterAdd = [
      ...legalHeirContent,
      addLegalHeirContentObject,
    ];

    const legalHeirContentAfterDelete = legalHeirContentAfterAdd
      .filter((ele) => ele.legalHeirQuestionId !== 8)
      .filter((ele) => ele.legalHeirQuestionId !== 10)
      .filter((ele) => ele.legalHeirQuestionId !== 11);

    setLegalHeirContent(legalHeirContentAfterDelete);
  };

  const question7NoClick = () => {
    const legalHeirContentAfterDelete = legalHeirContent.filter(
      (ele) => ele.legalHeirQuestionId !== 7,
    );

    setLegalHeirContent(legalHeirContentAfterDelete);
  };

  const question8YesClick = () => {
    const randomId = Math.random().toString();
    const addLegalHeirContentObject = {
      id: randomId,
      legalHeirQuestionId: 8,
      num: null,
      name: '',
      birth: '',
      type: '法定相続人',
      allocation: undefined,
    };

    const legalHeirContentAfterAdd = [
      ...legalHeirContent,
      addLegalHeirContentObject,
    ];

    const legalHeirContentAfterDelete = legalHeirContentAfterAdd
      .filter((ele) => ele.legalHeirQuestionId !== 10)
      .filter((ele) => ele.legalHeirQuestionId !== 11);

    setLegalHeirContent(legalHeirContentAfterDelete);
  };

  const question8NoClick = () => {
    const legalHeirContentAfterDelete = legalHeirContent.filter(
      (ele) => ele.legalHeirQuestionId !== 8,
    );

    setLegalHeirContent(legalHeirContentAfterDelete);
  };

  const question9NoClick = () => {
    const legalHeirContentAfterDelete = legalHeirContent
      .filter((ele) => ele.legalHeirQuestionId !== 10)
      .filter((ele) => ele.legalHeirQuestionId !== 11);

    setLegalHeirContent(legalHeirContentAfterDelete);
  };

  const question10YesClick = () => {
    const randomId = Math.random().toString();
    const addLegalHeirContentObject = {
      id: randomId,
      legalHeirQuestionId: 10,
      num: null,
      name: '',
      birth: '',
      type: '法定相続人',
      allocation: undefined,
    };

    const legalHeirContentAfterAdd = [
      ...legalHeirContent,
      addLegalHeirContentObject,
    ];

    const legalHeirContentAfterDelete = legalHeirContentAfterAdd.filter(
      (ele) => ele.legalHeirQuestionId !== 11,
    );

    setLegalHeirContent(legalHeirContentAfterDelete);
  };

  const question10NoClick = () => {
    const legalHeirContentAfterDelete = legalHeirContent.filter(
      (ele) => ele.legalHeirQuestionId !== 10,
    );

    setLegalHeirContent(legalHeirContentAfterDelete);
  };

  const question11YesClick = () => {
    const randomId = Math.random().toString();
    const addLegalHeirContentObject = {
      id: randomId,
      legalHeirQuestionId: 11,
      num: null,
      name: '',
      birth: '',
      type: '法定相続人',
      allocation: undefined,
    };

    const legalHeirContentAfterDelete = legalHeirContent.filter(
      (ele) => ele.legalHeirQuestionId !== 11,
    );

    const legalHeirContentAfterAdd = [
      ...legalHeirContentAfterDelete,
      addLegalHeirContentObject,
    ];

    setLegalHeirContent(legalHeirContentAfterAdd);
  };

  const openChange = (trueIndexArray: number[], falseIndexArray: number[]) => {
    setOpen(
      open.map(
        (openEle, index) =>
          trueIndexArray.includes(index)
            ? true
            : falseIndexArray.includes(index)
            ? false
            : openEle,
        // エラー処理しよう
      ),
    );

    window.scrollBy({
      top: 100,
      behavior: 'smooth',
    });
  };
  // 質問項目をだすださないの関数．配列でだすださないの箇所を指定.trueで該当indexのfalseで消す

  const openFormChange = (
    trueIndexArray: number[],
    falseIndexArray: number[],
  ) =>
    setOpenForm(
      openForm.map(
        (openFormEle, index) =>
          trueIndexArray.includes(index)
            ? true
            : falseIndexArray.includes(index)
            ? false
            : openFormEle,
        // エラー処理しよう
      ),
    );
  // 入力項目をだすださないの関数．配列でだすださないの箇所を指定.trueで該当indexのfalseで消す

  const checkedStatusChange = (
    trueIndexArray: number[],
    falseIndexArray: number[],
  ) =>
    setCheckedStatus(
      checkedStatus.map((checkedEle, index) =>
        trueIndexArray.includes(index)
          ? true
          : falseIndexArray.includes(index)
          ? false
          : checkedEle,
      ),
    );
  // ラジオボタンの状態をいじる関数．配列で箇所を指定.trueの状態でラジオボタンを押せる．falseで選択状態リセット

  const mutate = async () => {
    const legalHeirQuestionType = {
      question1,
      question2: open[1] === false ? undefined : question2,
      question3: open[2] === false ? undefined : question3,
      question4: open[3] === false ? undefined : question4,
      question5: open[4] === false ? undefined : question5,
      question6: open[5] === false ? undefined : question6,
      question7: open[6] === false ? undefined : question7,
      question8: open[7] === false ? undefined : question8,
      question9: open[8] === false ? undefined : question9,
      question10: open[9] === false ? undefined : question10,
      question11: open[10] === false ? undefined : question11,
    };

    const newContent = await MutateLegalHeirQuestion(
      idCheck || legalHeirQuestionDataId,
      legalHeirQuestionType,
    );
    setLegalHeirQuestionDataId(newContent.id);
  };

  logger.info(mutate);

  type LegalHeirData = {
    id: number;
    title: string;
    name: string;
    formType: string;
    formMessage: string;
    legalHeirType: 0 | 1 | 2 | 3;
    open: boolean;
    openForm: boolean | undefined;
    checkedStatus: boolean;
    defaultCheck: boolean | undefined;
    yes: () => void[];
    no: () => void[];
    yescheck?: () => void;
    nocheck?: () => void | Promise<void> | Promise<void>[];
  }[];

  const legalHeirData: LegalHeirData = [
    {
      id: 1,
      title:
        '現在、配偶者はいますか？（別居でも戸籍上の配偶者は含む。死亡・離婚・事実婚は含まない。）',
      name: 'spouse',
      formType: 'single',
      formMessage: '配偶者の情報を入力してください。',
      legalHeirType: 0,
      open: open[0],
      openForm: openForm[0],
      checkedStatus: checkedStatus[0],
      defaultCheck: defaultQuestion1,
      yes: () => [
        openChange([1], []),
        openFormChange([0], []),
        checkedStatusChange([0], []),
        question1Click(true),
      ],
      no: () => [
        openChange([1], []),
        openFormChange([], [0]),
        checkedStatusChange([0], []),
        question1Click(false),
      ],
      yescheck: () => addCard(1),
      nocheck: () => question1NoClick(),
    },
    {
      id: 2,
      title: '子供はいますか？（養子、認知した子、前婚の子、死亡した子も含む）',
      name: 'child',
      formType: 'multi',
      formMessage: '',
      legalHeirType: 1,
      open: open[1],
      openForm: openForm[1],
      checkedStatus: checkedStatus[1],
      defaultCheck: defaultQuestion2,
      yes: () => [
        openChange([2], [3, 4, 5, 6, 7, 8, 9, 10]),
        openFormChange([], [4, 5, 6, 7, 8, 9, 10]),
        checkedStatusChange([1], [2, 3, 4, 5, 6, 7, 8, 9, 10]),
        question2Click(true),
      ],
      no: () => [
        openChange([4], [2, 3]),
        openFormChange([], [2, 3]),
        checkedStatusChange([1], [2, 3]),
        question2Click(false),
      ],
      yescheck: () => question2YesClick(),
      nocheck: () => question2NoClick(),
    },
    {
      id: 3,
      title: '子供は全員生きていますか？',
      name: 'child_live',
      formType: 'multi',
      legalHeirType: 1,
      formMessage: '子供全員の情報を入力してください。',
      open: open[2],
      openForm: openForm[2],
      checkedStatus: checkedStatus[2],
      defaultCheck: question3,
      yes: () => [
        openChange([], [3, 4, 5, 6, 7, 8, 9, 10]),
        openFormChange([2], [3, 4, 5, 6, 7, 8, 9, 10]),
        checkedStatusChange([2], [3, 4, 5, 6, 7, 8, 9, 10]),
        question3Click(true),
      ],
      no: () => [
        openChange([3], []),
        openFormChange([], [2]),
        checkedStatusChange([1], [2]),
        question3Click(false),
      ],
      yescheck: () => question3YesClick(),
      nocheck: () => question3NoClick(),
    },
    {
      id: 4,
      title:
        '子供が亡くなっている場合、亡くなった子供には、子供（養子、認知した子、前婚の子も含む）はいますか？',
      name: 'grandchild',
      formType: 'multi',
      legalHeirType: 1,
      formMessage:
        '生きている子供と、亡くなった子供の子供の情報を入力してください。',
      open: open[3],
      openForm: openForm[3],
      checkedStatus: checkedStatus[3],
      defaultCheck: defaultQuestion4,
      yes: () => [
        openChange([], [4, 5, 6, 7, 8, 9, 10]),
        openFormChange([3], [4, 5, 6, 7, 8, 9, 10]),
        checkedStatusChange([3], [4, 5, 6, 7, 8, 9, 10]),
        question4Click(true),
      ],
      no: () => [
        openChange([4], []),
        openFormChange([], [3]),
        checkedStatusChange([3], []),
        question4Click(false),
      ],
      yescheck: () => question4YesClick(),
      nocheck: () => question4NoClick(),
    },
    {
      id: 5,
      title: '父母は生きていますか？',
      name: 'parents',
      formType: 'multi',
      legalHeirType: 2,
      formMessage: '父母の情報を入力してください。',
      open: open[4],
      openForm: openForm[4],
      checkedStatus: checkedStatus[4],
      defaultCheck: defaultQuestion5,
      yes: () => [
        openChange([], [5, 6, 7, 8, 9, 10]),
        openFormChange([4], [5, 6, 7, 8, 9, 10]),
        checkedStatusChange([4], [5, 6, 7, 8, 9, 10]),
        question5Click(true),
      ],
      no: () => [
        openChange([5], []),
        openFormChange([], [4]),
        checkedStatusChange([4], [5, 6, 7]),
        question5Click(false),
      ],
      yescheck: () => question5YesClick(),
      nocheck: () => question5NoClick(),
    },
    {
      id: 6,
      title: '父母のどちらかが生きていますか？',
      name: 'parents_which',
      formType: 'multi',
      legalHeirType: 2,
      formMessage: '生きている方の情報を入力してください。',
      open: open[5],
      openForm: openForm[5],
      checkedStatus: checkedStatus[5],
      defaultCheck: defaultQuestion6,
      yes: () => [
        openChange([], [6, 7, 8, 9, 10]),
        openFormChange([5], [6, 7, 8, 9, 10]),
        checkedStatusChange([5], [6, 7, 8, 9, 10]),
        question6Click(true),
      ],
      no: () => [
        openChange([6], []),
        openFormChange([], [5]),
        checkedStatusChange([5], [6, 7]),
        question6Click(false),
      ],
      yescheck: () => question6YesClick(),
      nocheck: () => question6NoClick(),
    },
    {
      id: 7,
      title: '祖父母は生きていますか？',
      name: 'grandparents',
      formType: 'multi',
      legalHeirType: 2,
      formMessage: '祖父母の情報を入力してください。',
      open: open[6],
      openForm: openForm[6],
      checkedStatus: checkedStatus[6],
      defaultCheck: defaultQuestion7,
      yes: () => [
        openChange([], [7, 8, 9, 10]),
        openFormChange([6], [7, 8, 9, 10]),
        checkedStatusChange([6], [7, 8, 9, 10]),
        question7Click(true),
      ],
      no: () => [
        openChange([7], []),
        openFormChange([], [6]),
        checkedStatusChange([6], [7]),
        question7Click(false),
      ],
      yescheck: () => question7YesClick(),
      nocheck: () => question7NoClick(),
    },
    {
      id: 8,
      title: '祖父母のどちらかが生きていますか？',
      name: 'grandparents_which',
      formType: 'multi',
      legalHeirType: 3,
      formMessage: '生きている方の情報を入力してください。',
      open: open[7],
      openForm: openForm[7],
      checkedStatus: checkedStatus[7],
      defaultCheck: defaultQuestion8,
      yes: () => [
        openChange([], [8, 9, 10]),
        openFormChange([7], [8, 9, 10]),
        checkedStatusChange([7], [8, 9, 10]),
        question8Click(true),
      ],
      no: () => [
        openChange([8], []),
        openFormChange([], [7]),
        checkedStatusChange([7], []),
        question8Click(false),
      ],
      yescheck: () => question8YesClick(),
      nocheck: () => question8NoClick(),
    },
    {
      id: 9,
      title: '兄弟姉妹はいますか？（父母の一方が異なる者も含む）',
      name: 'brothers',
      formType: 'message',
      legalHeirType: 3,
      formMessage: '法定相続人はいません。',
      open: open[8],
      openForm: openForm[8],
      checkedStatus: checkedStatus[8],
      defaultCheck: defaultQuestion9,
      yes: () => [
        openChange([9], []),
        openFormChange([], []),
        checkedStatusChange([8], [9, 10]),
        question9Click(true),
      ],
      no: () => [
        openChange([], [9, 10]),
        openFormChange([], [9, 10]),
        checkedStatusChange([8], [9, 10]),
        question9Click(false),
      ],
      nocheck: () => question9NoClick(),
    },
    {
      id: 10,
      title: '兄弟姉妹は全員生きていますか？',
      name: 'brothers_all',
      formType: 'multi',
      legalHeirType: 3,
      formMessage: '生きている兄弟姉妹全員の情報を入力してください。',
      open: open[9],
      openForm: openForm[9],
      checkedStatus: checkedStatus[9],
      defaultCheck: defaultQuestion10,
      yes: () => [
        openChange([], [10]),
        openFormChange([9], [10]),
        checkedStatusChange([9], [10]),
        question10Click(true),
      ],
      no: () => [
        openChange([10], []),
        openFormChange([], [9]),
        checkedStatusChange([9], [10]),
        question10Click(false),
      ],
      yescheck: () => question10YesClick(),
      nocheck: () => question10NoClick(),
    },
    {
      id: 11,
      title:
        '兄弟姉妹に亡くなっている人がいる場合、その子供（認知した子、前婚の子も含む）はいますか？',
      name: 'brothers_child',
      formType: 'multi',
      legalHeirType: 3,
      formMessage:
        formMessage11 === 0
          ? '生きている兄弟姉妹、また亡くなった兄弟姉妹の子供全員の情報を入力してください。'
          : '生きている兄弟姉妹全員の情報を入力してください。',
      open: open[10],
      openForm: openForm[10],
      checkedStatus: checkedStatus[10],
      defaultCheck: defaultQuestion11,
      yes: () => [
        openFormChange([10], []),
        checkedStatusChange([10], []),
        setFormMessage11(0),
        question11Click(true),
      ],
      yescheck: () => question11YesClick(),
      no: () => [
        openFormChange([10], []),
        checkedStatusChange([10], []),
        setFormMessage11(1),
        question11Click(false),
      ],
    },
  ];

  const cardDeleteAll = async () => {
    await DeleteLegalHeir();
    await DeleteLegalHeirQuestion();

    await MutateAllocation(data ? data.id : '', {
      userId: user.attributes.sub,
      inputLegalHeirFlag: null,
      inputRecipientFlag: data?.inputRecipientFlag,
    });

    setOpen(allFalse);

    window.location.reload();
  };

  const save = async () => {
    const reservedAllocationReceiverContent = await GetAllocationReceiver();
    const reservedLegalHeirContent = reservedAllocationReceiverContent.filter(
      (ele) => ele.type === '法定相続人',
    );
    const legalHeirContentId = legalHeirContent.map((ele) => ele.id);
    reservedLegalHeirContent.map((ele) => {
      if (legalHeirContentId.includes(ele.id) === false) {
        return DeleteAllocationReceiverById(ele.id);
      }

      return ele;
    });

    const legalHeirNameCheckArr = legalHeirContent.map((elm) => {
      const legalHeirNameCheck =
        elm.name === undefined || elm.name === null ? '' : elm.name;

      return legalHeirNameCheck;
    });

    if (legalHeirNameCheckArr.includes('')) {
      window.alert('必須項目の入力が漏れています。');

      return false;
    }
    const legalHeirQuestion = {
      question1,
      question2: open[1] === false ? undefined : question2,
      question3: open[2] === false ? undefined : question3,
      question4: open[3] === false ? undefined : question4,
      question5: open[4] === false ? undefined : question5,
      question6: open[5] === false ? undefined : question6,
      question7: open[6] === false ? undefined : question7,
      question8: open[7] === false ? undefined : question8,
      question9: open[8] === false ? undefined : question9,
      question10: open[9] === false ? undefined : question10,
      question11: open[10] === false ? undefined : question11,
    };
    const legalHeirQuestionId =
      legalHeirQuestionDataContent.length !== 0
        ? legalHeirQuestionDataContent[0].id
        : '';
    await MutateLegalHeirQuestion(legalHeirQuestionId, legalHeirQuestion);

    const id = data ? data.id : '';
    const mutatedAllocation = await MutateAllocation(id, {
      userId: user.attributes.sub,
      inputLegalHeirFlag: true,
      inputRecipientFlag: data?.inputRecipientFlag,
      noCheck: data?.noCheck,
    });

    const newReceivers = await Promise.all(
      legalHeirContent.map(async (ele, index) => {
        const receiver = await MutateAllocationReceiverAndGetId(
          ele.id,
          ele.legalHeirQuestionId || 0,
          index + 1,
          ele.name || '',
          ele.birth || '',
          ele.type || '',
          mutatedAllocation,
        );

        return receiver;
      }),
    );

    setAllocationReceivers(newReceivers);

    return true;
  };

  const navigate = useNavigate();

  const saveAndBack = async () => {
    const reservedAllocationReceiverContent = await GetAllocationReceiver();
    const reservedLegalHeirContent = reservedAllocationReceiverContent.filter(
      (ele) => ele.type === '法定相続人',
    );
    const legalHeirContentId = legalHeirContent.map((ele) => ele.id);
    reservedLegalHeirContent.map((ele) => {
      if (legalHeirContentId.includes(ele.id) === false) {
        return DeleteAllocationReceiverById(ele.id);
      }

      return ele;
    });

    const legalHeirNameCheckArr = legalHeirContent.map((elm) => {
      const legalHeirNameCheck =
        elm.name === undefined || elm.name === null ? '' : elm.name;

      return legalHeirNameCheck;
    });

    if (legalHeirNameCheckArr.includes('')) {
      window.alert('必須項目の入力が漏れています。');
    } else {
      const legalHeirQuestion = {
        question1,
        question2: open[1] === false ? undefined : question2,
        question3: open[2] === false ? undefined : question3,
        question4: open[3] === false ? undefined : question4,
        question5: open[4] === false ? undefined : question5,
        question6: open[5] === false ? undefined : question6,
        question7: open[6] === false ? undefined : question7,
        question8: open[7] === false ? undefined : question8,
        question9: open[8] === false ? undefined : question9,
        question10: open[9] === false ? undefined : question10,
        question11: open[10] === false ? undefined : question11,
      };
      const legalHeirQuestionId =
        legalHeirQuestionDataContent.length !== 0
          ? legalHeirQuestionDataContent[0].id
          : '';
      await MutateLegalHeirQuestion(legalHeirQuestionId, legalHeirQuestion);

      const id = data ? data.id : '';
      const mutatedAllocation = await MutateAllocation(id, {
        userId: user.attributes.sub,
        inputLegalHeirFlag: true,
        inputRecipientFlag: data?.inputRecipientFlag,
        noCheck: data?.noCheck,
      });

      const newReceivers = await Promise.all(
        legalHeirContent.map(async (ele, index) => {
          const receiver = await MutateAllocationReceiverAndGetId(
            ele.id,
            ele.legalHeirQuestionId || 0,
            index + 1,
            ele.name || '',
            ele.birth || '',
            ele.type || '',
            mutatedAllocation,
          );

          return receiver;
        }),
      );

      setAllocationReceivers(newReceivers);

      navigate('../liabilities');
    }
  };

  return (
    <>
      {isDialogShow ? (
        <Dialog
          dialogText="入力内容を削除しますがよろしいですか？"
          dialogYesFunc={cardDeleteAll}
          dialogNoFunc={toggelDialog}
        />
      ) : (
        ''
      )}
      <div className="legalHeirTop">
        <div className="pc-only realEstateParam1">
          <Param2Checkmark
            StyleClass="styleParam2-1"
            key="legalHier_pc"
            checkMarkSize6={false}
            allocationData={data}
          />
        </div>
        {legalHeirData.map((ele) => (
          <>
            <div
              className={`legalHeirRadioArea ${ele.open ? 'open' : 'close'}`}
              key={ele.id}
            >
              <RadioCustomClear
                key={`RadioCustomClear-${ele.id}`}
                title={ele.title}
                name={ele.name}
                yesFunc={ele.yes}
                noFunc={ele.no}
                defaultCheckedData={ele.defaultCheck}
                yesCheck={ele.yescheck}
                noCheck={ele.nocheck}
              />
            </div>
            <LegalHeirFormArea
              key={`LegalHeirFormArea${ele.id}`}
              open={ele.openForm}
              formType={ele.formType}
              formMessage={ele.formMessage}
              legalHeirData={legalHeirContentData.filter(
                (e) => e.legalHeirQuestionId === ele.id,
              )}
              updateLegalHeirNameContent={updateLegalHeirNameContent}
              updateLegalHeirBirthContent={updateLegalHeirBirthContent}
              legalHeirContentData={legalHeirContent}
              subjectLegalHeirQuestionId={ele.id}
              addCard={addCard}
              deleteCard={deleteCard}
            />
          </>
        ))}
        <NextReturnLegalHeir
          returnFunc={saveAndBack}
          func={save}
          allocationReceivers={allocationReceivers}
          deleteFunc={toggelDialog}
        />
      </div>
    </>
  );
};

export default LegalHeirTemp;
