/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { GetUser } from 'domains/cognite/services/manageUser';
import {
  GetStayingTimeByUserId,
  CreateStayingTime,
  UpdateStayingTime,
} from 'domains/cognite/services/manageStayingTime';

const useStayingTime = (): void => {
  const startTime = useMemo(() => new Date(), []);
  const startTimeDate = `${startTime.getFullYear()}-${
    startTime.getMonth() + 1
  }-${startTime.getDate()}`;
  const { data: user } = useQuery(['authenticated_user'], GetUser);

  useEffect(() => {
    const checkStayingTimeAndMutate = async () => {
      const currentTime = new Date();
      const diffTime = currentTime.getTime() - startTime.getTime();
      const diffMinutes = diffTime / (1000 * 60);

      if (user && diffMinutes >= 5) {
        const oldTimes = await GetStayingTimeByUserId(user.attributes.sub);
        if (oldTimes && oldTimes.some((ele) => ele.date === startTimeDate)) {
          const theOldTime = oldTimes.find((ele) => ele.date === startTimeDate);
          if (theOldTime) {
            await UpdateStayingTime(theOldTime.id, theOldTime.time + 5);
          }
        } else {
          await CreateStayingTime({
            userId: user.attributes.sub,
            date: startTimeDate,
            time: 5,
          });
        }
      }
    };

    void checkStayingTimeAndMutate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  });
};

export default useStayingTime;
