import { FC } from 'react';
import { Properties, Allocation } from 'models';
import inputCompleted from '../images/inputCompleted.svg';
import inputNone from '../images/inputNone.svg';
import PcInheritanceParam6 from '../images/PcInheritanceParam.svg';
import PcInheritanceParam2 from '../images/PcInheritanceParam2.svg';
import inputOrangeCircle from '../images/inputOrangeCircle.svg';
import './paramCheckmarkPc.css';

type Props = {
  StyleClass: string;
  checkMarkSize6: boolean;
  propertyData?: Properties | undefined;
  allocationData?: Allocation | undefined;
};

const paramCheckmark: FC<Props> = (props) => {
  const { StyleClass, checkMarkSize6, propertyData, allocationData } = props;

  const checkMark = [];
  const changeCheckMark = checkMarkSize6
    ? PcInheritanceParam6
    : PcInheritanceParam2;
  const TopClassContainer = checkMarkSize6
    ? 'param6Checkmark'
    : 'param2Checkmark';
  const CheckClassContainer = checkMarkSize6
    ? 'Pc6inputCompletedMark'
    : 'Pc2inputCompletedMark';
  const paramNumberChange = checkMarkSize6 ? 6 : 2;

  for (let i = 0; i < paramNumberChange; i += 1) {
    if (checkMarkSize6) {
      if (propertyData) {
        const realEstateData = !!propertyData.realEstate;
        const movingPropertyData = !!propertyData.movingProperty;
        const depositsData = !!propertyData.deposits;
        const stockData = !!propertyData.stock;
        const lifeInsuranceData = !!propertyData.lifeInsurance;
        const bondsData = !!propertyData.bonds;
        const param = [
          realEstateData,
          movingPropertyData,
          depositsData,
          stockData,
          lifeInsuranceData,
          bondsData,
        ];
        if (param[i] === true) {
          checkMark.push(
            <div key={i}>
              <img src={inputCompleted} alt="チェックマーク" />
            </div>,
          );
        } else {
          checkMark.push(
            <div key={i}>
              <img src={inputNone} alt="チェックマーク" />
            </div>,
          );
        }
      } else {
        const param = [false, false, false, false, false, false];
        if (param[i] === true) {
          checkMark.push(
            <div key={`partPc${i}`}>
              <img src={inputCompleted} alt="チェックマーク" />
            </div>,
          );
        } else {
          checkMark.push(
            <div key={`partPc${i}`}>
              <img src={inputNone} alt="チェックマーク" />
            </div>,
          );
        }
      }
    } else if (allocationData) {
      const param2 = [
        allocationData.inputLegalHeirFlag,
        allocationData.inputRecipientFlag,
      ];
      if (param2[i] === true) {
        checkMark.push(
          <div key={`partPc2${i}`}>
            <img src={inputCompleted} alt="チェックマーク" />
          </div>,
        );
      } else {
        checkMark.push(
          <div key={`partPc2${i}`}>
            <img src={inputNone} alt="チェックマーク" />
          </div>,
        );
      }
    } else {
      const param2 = [false, false];
      if (param2[i] === true) {
        checkMark.push(
          <div key={`partPc3${i}`}>
            <img src={inputCompleted} alt="チェックマーク" />
          </div>,
        );
      } else {
        checkMark.push(
          <div key={`partPc3${i}`}>
            <img src={inputNone} alt="チェックマーク" />
          </div>,
        );
      }
    }
  }

  // 念のため残す
  // return fetching ? (
  //   <div>Loading</div>
  // ) : (
  //   <div className={TopClassContainer}>
  //     <img src={changeCheckMark} alt="" />
  //     <div className={CheckClassContainer}>{checkMark}</div>
  //     <img src={inputOrangeCircle} alt="" className={StyleClass} />
  //   </div>
  // );

  return (
    <div className={TopClassContainer}>
      <img src={changeCheckMark} alt="" />
      <div className={CheckClassContainer}>{checkMark}</div>
      <img src={inputOrangeCircle} alt="" className={StyleClass} />
    </div>
  );
};

export default paramCheckmark;
