import { FC } from 'react';
import { useTopicDetail } from 'hooks';
import TopicsViewArea from '../molcules/topicsViewArea';
import TopicsActionLinks from '../molcules/topicsActionLinks';

const TopicDetailContent: FC = () => {
  const topicsDetailData = useTopicDetail({ onlyReleased: false });

  if (!topicsDetailData) {
    return <></>;
  }

  const { updatedAt, title, content, imageUrl, isDraft, prevId, nextId } =
    topicsDetailData;

  return (
    <>
      <TopicsViewArea
        updatedAt={updatedAt}
        title={title}
        imageUrl={imageUrl}
        content={content}
        isDraft={isDraft}
      />
      <TopicsActionLinks prevId={prevId} nextId={nextId} />
    </>
  );
};

export default TopicDetailContent;
