import { FC, useRef, FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChangePassword } from 'domains/cognite/services/manageUser';
import { logger } from 'utils/logger';
import NavigateBtn from 'common/components/atoms/navigateBtn';
import './changePassContent.css';

const ChangePassContent: FC = () => {
  const navigate = useNavigate();

  const currentPasswordRef = useRef<HTMLInputElement>(null);
  const newPasswordRef = useRef<HTMLInputElement>(null);
  const newPasswordComfirmRef = useRef<HTMLInputElement>(null);
  const [refValueArray, setRefValueArray] = useState<boolean[]>([]);
  const [errorMessageSwitch, setErrorMessageSwitch] = useState(false);

  const changePassword = async (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    const refValueData: (string | undefined)[] = [
      currentPasswordRef.current?.value,
      newPasswordRef.current?.value,
      newPasswordComfirmRef.current?.value,
    ];

    const refValueDataArray: boolean[] = refValueData.map((ele) => ele !== '');
    setRefValueArray(refValueDataArray);

    try {
      if (
        currentPasswordRef.current &&
        currentPasswordRef.current.value &&
        newPasswordRef.current &&
        newPasswordRef.current.value &&
        newPasswordComfirmRef.current &&
        newPasswordComfirmRef.current.value
      ) {
        if (
          newPasswordRef.current.value === newPasswordComfirmRef.current.value
        ) {
          await ChangePassword(
            currentPasswordRef.current.value,
            newPasswordRef.current.value,
          )
            .then(() => {
              logger.info('パスワード変更成功');
              navigate('/executor');
            })
            .catch((err) => {
              logger.error('パスワード変更失敗1');
              logger.error(err);
            });
        } else {
          logger.error('パスワードが異なります');
          logger.error('パスワード変更失敗2');
          setErrorMessageSwitch(true);
        }
      } else {
        logger.error('空白あり');
        logger.error('パスワード変更失敗2');
        setErrorMessageSwitch(false);
      }
    } catch (error) {
      logger.error('パスワード変更失敗3');
      setErrorMessageSwitch(false);
    }
  };

  return (
    <div className="changePass">
      <div>
        <form>
          <label htmlFor="currentPass">
            <p>現在のパスワード</p>
            <input
              type="password"
              id="currentPass"
              ref={currentPasswordRef}
              placeholder="パスワードを入力"
            />
          </label>
          {refValueArray[0] === false ? (
            <p className="alertMessage">パスワードが入力されていません</p>
          ) : (
            ''
          )}
          <label htmlFor="newPass">
            <p>新しいパスワード</p>
            <input
              type="password"
              id="newPass"
              ref={newPasswordRef}
              placeholder="パスワードを入力"
            />
          </label>
          {refValueArray[1] === false ? (
            <p className="alertMessage">パスワードが入力されていません</p>
          ) : (
            ''
          )}
          <label htmlFor="RenewPass">
            <p>新しいパスワードを再入力</p>
            <input
              type="password"
              id="RenewPass"
              ref={newPasswordComfirmRef}
              placeholder="パスワードを再入力"
            />
          </label>
          {refValueArray[2] === false ? (
            <p className="alertMessage">パスワードが入力されていません</p>
          ) : (
            ''
          )}
          {errorMessageSwitch ? (
            <p className="alertMessage">
              新しいパスワードと新しいパスワード (確認) の値が違います。
            </p>
          ) : (
            ''
          )}
          <input type="submit" value="変更する" onClick={changePassword} />
        </form>
        <NavigateBtn />
        {/* <input type="submit" value="送信する" /> */}
      </div>
    </div>
  );
};

export default ChangePassContent;
