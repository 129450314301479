export interface Year {
  id: number;
  year: number;
}

export const YearArray: Year[] = [
  { id: 1920, year: 1920 },
  { id: 1921, year: 1921 },
  { id: 1922, year: 1922 },
  { id: 1923, year: 1923 },
  { id: 1924, year: 1924 },
  { id: 1925, year: 1925 },
  { id: 1926, year: 1926 },
  { id: 1927, year: 1927 },
  { id: 1928, year: 1928 },
  { id: 1929, year: 1929 },
  { id: 1930, year: 1930 },
  { id: 1931, year: 1931 },
  { id: 1932, year: 1932 },
  { id: 1933, year: 1933 },
  { id: 1934, year: 1934 },
  { id: 1935, year: 1935 },
  { id: 1936, year: 1936 },
  { id: 1937, year: 1937 },
  { id: 1938, year: 1938 },
  { id: 1939, year: 1939 },
  { id: 1940, year: 1940 },
  { id: 1941, year: 1941 },
  { id: 1942, year: 1942 },
  { id: 1943, year: 1943 },
  { id: 1944, year: 1944 },
  { id: 1945, year: 1945 },
  { id: 1946, year: 1946 },
  { id: 1947, year: 1947 },
  { id: 1948, year: 1948 },
  { id: 1949, year: 1949 },
  { id: 1950, year: 1950 },
  { id: 1951, year: 1951 },
  { id: 1952, year: 1952 },
  { id: 1953, year: 1953 },
  { id: 1954, year: 1954 },
  { id: 1955, year: 1955 },
  { id: 1956, year: 1956 },
  { id: 1957, year: 1957 },
  { id: 1958, year: 1958 },
  { id: 1959, year: 1959 },
  { id: 1960, year: 1960 },
  { id: 1961, year: 1961 },
  { id: 1962, year: 1962 },
  { id: 1963, year: 1963 },
  { id: 1964, year: 1964 },
  { id: 1965, year: 1965 },
  { id: 1966, year: 1966 },
  { id: 1967, year: 1967 },
  { id: 1968, year: 1968 },
  { id: 1969, year: 1969 },
  { id: 1970, year: 1970 },
  { id: 1971, year: 1971 },
  { id: 1972, year: 1972 },
  { id: 1973, year: 1973 },
  { id: 1974, year: 1974 },
  { id: 1975, year: 1975 },
  { id: 1976, year: 1976 },
  { id: 1977, year: 1977 },
  { id: 1978, year: 1978 },
  { id: 1979, year: 1979 },
  { id: 1980, year: 1980 },
  { id: 1981, year: 1981 },
  { id: 1982, year: 1982 },
  { id: 1983, year: 1983 },
  { id: 1984, year: 1984 },
  { id: 1985, year: 1985 },
  { id: 1986, year: 1986 },
  { id: 1987, year: 1987 },
  { id: 1988, year: 1988 },
  { id: 1989, year: 1989 },
  { id: 1990, year: 1990 },
  { id: 1991, year: 1991 },
  { id: 1992, year: 1992 },
  { id: 1993, year: 1993 },
  { id: 1994, year: 1994 },
  { id: 1995, year: 1995 },
  { id: 1996, year: 1996 },
  { id: 1997, year: 1997 },
  { id: 1998, year: 1998 },
  { id: 1999, year: 1999 },
  { id: 2000, year: 2000 },
  { id: 2001, year: 2001 },
  { id: 2002, year: 2002 },
  { id: 2003, year: 2003 },
  { id: 2004, year: 2004 },
  { id: 2005, year: 2005 },
  { id: 2006, year: 2006 },
  { id: 2007, year: 2007 },
  { id: 2008, year: 2008 },
  { id: 2009, year: 2009 },
  { id: 2010, year: 2010 },
  { id: 2011, year: 2011 },
  { id: 2012, year: 2012 },
  { id: 2013, year: 2013 },
  { id: 2014, year: 2014 },
  { id: 2015, year: 2015 },
  { id: 2016, year: 2016 },
  { id: 2017, year: 2017 },
  { id: 2018, year: 2018 },
  { id: 2019, year: 2019 },
  { id: 2020, year: 2020 },
  { id: 2021, year: 2021 },
  { id: 2022, year: 2022 },
];
