import { FC, useState } from 'react';
// import { useSync } from 'hooks';
import { SearchTerm, AssignStatus } from 'models';
import {
  getExecutors,
  Executor,
} from 'domains/apigateway/services/get-Executor';
import { CreateSearchHistory } from 'domains/cognite/services/manageSearch';
import { QueryAssignRelationshipFromClient } from 'domains/cognite/services/manageAssignRelationship';
import { GetUser } from 'domains/cognite/services/manageUser';
import { useQuery } from 'react-query';
import WillHeader from '../organisms/willHeader';
import ExecutorSearchDescription from '../templates/executorSearchDescription';
import './executorSearch.css';
import ExecutorSearchContent from '../organisms/executorSearchContent';
import ExecutorSearchResultDescription from '../templates/executorSearchResultDescription';
import ExecutorSearchResultContent from '../organisms/executorSearchResultContent';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import ExecutorPcDescription from '../organisms/executorPcDescription';
import pageData from '../data/pageData';

const ExecutorSearch: FC = () => {
  const descriptionPc = (
    <>
      {pageData[27].description}
      {pageData[28].description}
      {pageData[29].description}
      {pageData[30].description}
    </>
  );
  // useSync();
  const [page, setPage] = useState(true);
  const changePage = () => {
    setPage(!page);
  };

  const [executors, setExecutors] = useState<Executor[]>([]);
  const { data: user } = useQuery(['get_userId'], GetUser);

  if (!user) {
    throw Error('認証エラー');
  }

  const getExecutorAndChangePage = async (
    name?: string,
    zone?: string,
    passport?: string,
  ): Promise<void> => {
    const queryParams = {
      name,
      zone,
      passport,
    };
    const hitExecutors = await getExecutors(queryParams);
    const relationship = await QueryAssignRelationshipFromClient(
      user.attributes.sub,
    );

    const relationshipOfDecline = relationship.filter(
      (ele) => ele.status === AssignStatus.DECLINE,
    );

    if (relationshipOfDecline.length) {
      const executorNotDecline = hitExecutors.filter((ele) =>
        relationshipOfDecline.every(
          (relationEle) => relationEle.assigneeId !== ele.id,
        ),
      );
      setExecutors(executorNotDecline);
    } else {
      setExecutors(hitExecutors);
    }

    if (name) {
      await CreateSearchHistory({
        userId: user.attributes.sub,
        searchTerm: SearchTerm.NAME,
      });
    }
    if (zone) {
      await CreateSearchHistory({
        userId: user.attributes.sub,
        searchTerm: SearchTerm.ZONE,
      });
    }
    if (passport) {
      await CreateSearchHistory({
        userId: user.attributes.sub,
        searchTerm: SearchTerm.PASSPORT,
      });
    }

    setPage(!page);
  };

  const scrollTopForSearch = () => {
    window.scrollTo(0, 0);
  };

  return page ? (
    <>
      <WillHeader title="遺言執行者名簿" />
      <div className="executor-search">
        <div className="sp-only">
          <ExecutorSearchDescription />
        </div>

        <ExecutorSearchContent
          getExecutorAndChangePage={getExecutorAndChangePage}
          scrollTopForSearch={scrollTopForSearch}
        />
      </div>
      <CommonFooter />
      <ExecutorPcDescription
        description={pageData[26].description}
        pageTitle="executorSearchDes"
      />
    </>
  ) : (
    <>
      <WillHeader title="検索結果" />
      <div className="executorSearchResult">
        <div className="sp-only">
          <ExecutorSearchResultDescription />
        </div>
        <ExecutorSearchResultContent
          changePage={changePage}
          executors={executors}
          scrollTopforSearch={scrollTopForSearch}
        />
      </div>
      <CommonFooter />
      <ExecutorPcDescription description={descriptionPc} />
    </>
  );
};

export default ExecutorSearch;
