/* eslint-disable no-param-reassign */
import { DataStore } from 'aws-amplify';
import { TestamentFile } from 'models';
// eslint-disable-next-line import/no-extraneous-dependencies

export const listTestamentFiles = async (
  fileName: string,
): Promise<TestamentFile[]> => {
  const data = await DataStore.query(TestamentFile, (c) =>
    c.fileName('eq', fileName),
  );

  return data;
};

export const createOrUpdateTestamentFile = async (
  fileName: string,
): Promise<TestamentFile> => {
  const original = await DataStore.query(TestamentFile);

  if (original.length > 0) {
    const data = await DataStore.save(
      TestamentFile.copyOf(original[0], (updated) => {
        updated.fileName = fileName;
      }),
    );

    return data;
  }

  const data = await DataStore.save(new TestamentFile({ fileName }));

  return data;
};

export const DeleteTestamentFile = async (): Promise<void> => {
  const data = await DataStore.query(TestamentFile);
  if (data.length > 0) {
    await Promise.all(data.map((item) => DataStore.delete(item)));
  }
};
