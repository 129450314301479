import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Chatroom } from 'models';
import { getUser } from 'domains/apigateway/services/get-user';
import { listMessage } from 'domains/cognite/services/manageChat';
import defaultProfileIcon from '../images/defaultProfileIcon.svg';
import chatListNextAllow from '../images/chatList-next-allow.svg';

type Props = {
  chatRoom: Chatroom;
};

const ChatListLink: FC<Props> = (props) => {
  const { chatRoom } = props;
  const { clientId, assigneeId } = chatRoom;

  const getTheClient = async () => {
    try {
      const theClient = await getUser(clientId);

      return theClient;
    } catch (error) {
      return undefined;
    }
  };

  const getTheLastMessage = async () => {
    const theMessages = await listMessage(chatRoom);

    return theMessages.slice(-1)[0];
  };

  const { data: client, error: clientError } = useQuery(
    ['chatList_client', clientId],
    getTheClient,
  );

  const { data: lastMessage, error: messageError } = useQuery(
    ['chatList', chatRoom.id],
    getTheLastMessage,
  );

  if (!client || clientError) {
    return <></>;
  }

  if (messageError) {
    return <></>;
  }

  const locationState = {
    client,
    assigneeId,
  };

  return (
    <Link className="chatListUser" to="/executor/chat" state={locationState}>
      <div className="chatListUserIcon">
        <img src={defaultProfileIcon} alt="" />
      </div>
      <div className="chatListDatas">
        <div>
          <p>{client.email}</p>
          <div>
            <p>
              {lastMessage &&
                lastMessage.createdAt?.slice(0, 10).split('-').join('/')}
            </p>
            <img src={chatListNextAllow} alt="" />
          </div>
        </div>
        <p>{lastMessage && lastMessage.content}</p>
      </div>
    </Link>
  );
};

export default ChatListLink;
