/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import Slider from 'react-slick';
import { Properties } from 'models';
import pageData from '../data/pageData';
import PageDescription from '../organisms/pageDescription';
import LifeInsuranceBack from '../images/LifeInsuranceBack.svg';
import ParamCheckmark from '../atoms/paramCheckmarkSp';
import './lifeInsuranceDescription.css';

type Props = {
  data: Properties | undefined;
};

const LifeInsuranceDescription: FC<Props> = (props) => {
  const { data } = props;
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  return (
    <div className="LifeInsuranceDescription">
      <div>
        <div className="LifeInsuranceParam">
          <ParamCheckmark
            checkMarkSize6
            key="lifeInsuranceDescription"
            propertyData={data}
          />
        </div>
        <Slider {...settings}>
          <PageDescription
            page="realEstatePage"
            description={pageData[9].description}
          />
        </Slider>
      </div>
      <img src={LifeInsuranceBack} alt="相続財産【株式・債券 】背景画像" />
    </div>
  );
};

export default LifeInsuranceDescription;
