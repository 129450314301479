import { FC } from 'react';
import './topicsAction.css';

type Props = {
  firstText: string;
  firstFunc: (() => void) | (() => Promise<void>);
  secondText: string;
  secondFunc: (() => void) | (() => Promise<void>);
  thirdText: string;
  thirdFunc: (() => void) | (() => Promise<void>);
};

const TopicsActionBtns: FC<Props> = (props) => {
  const { firstText, firstFunc, secondText, secondFunc, thirdText, thirdFunc } =
    props;

  return (
    <div className="topics-action">
      <button type="button" onClick={firstFunc}>
        {firstText}
      </button>
      <button type="button" onClick={secondFunc}>
        {secondText}
      </button>
      <button type="button" onClick={thirdFunc}>
        {thirdText}
      </button>
    </div>
  );
};

export default TopicsActionBtns;
