import { FC } from 'react';
import './executorCard.css';

type Props = {
  name: string;
  zone: string;
  passport: string;
};

const ExecutorCard: FC<Props> = (props) => {
  const { name, zone, passport } = props;

  return (
    <div className="executorCard">
      <p>{name}</p>
      <p>活動地域:{zone}</p>
      <p>所持資格:{passport}</p>
    </div>
  );
};

export default ExecutorCard;
