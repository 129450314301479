// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const AssignStatus = {
  "REGISTERD": "registerd",
  "APPROVAL": "approval",
  "DECLINE": "decline",
  "CANCELED": "canceled",
  "DISABLE": "disable"
};

const SearchTerm = {
  "NAME": "name",
  "ZONE": "zone",
  "PASSPORT": "passport"
};

const { Testament, Properties, WillInfo, RealEstate, RealEstateContent, MovingProperty, Deposits, DepositsContent, Stock, StockContent, LifeInsurance, LifeInsuranceContent, Receiver, Bonds, BondsContent, Liabilities, LiabilitiesContent, Allocation, AllocationReceiver, AllocationProperties, Passports, AssignRelationship, AssignExecutorNotOnTheList, Evaluation, ApplyToBeExecutor, ListingPlace, ListingPlaceCities, ListingArea, ExecutorProfile, Chatroom, Message, LegalHeirQuestion, SearchHistory, ExecutorViewHistory, StayingTime, StayingTimeAtList, Topics, TestamentFile } = initSchema(schema);

export {
  Testament,
  Properties,
  WillInfo,
  RealEstate,
  RealEstateContent,
  MovingProperty,
  Deposits,
  DepositsContent,
  Stock,
  StockContent,
  LifeInsurance,
  LifeInsuranceContent,
  Receiver,
  Bonds,
  BondsContent,
  Liabilities,
  LiabilitiesContent,
  Allocation,
  AllocationReceiver,
  AllocationProperties,
  Passports,
  AssignRelationship,
  AssignExecutorNotOnTheList,
  Evaluation,
  ApplyToBeExecutor,
  ListingPlace,
  ListingPlaceCities,
  ListingArea,
  ExecutorProfile,
  Chatroom,
  Message,
  LegalHeirQuestion,
  SearchHistory,
  ExecutorViewHistory,
  StayingTime,
  StayingTimeAtList,
  Topics,
  TestamentFile,
  AssignStatus,
  SearchTerm
};