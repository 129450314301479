import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { useStayingTime } from 'hooks';

const User: FC = () => {
  useStayingTime();

  return <Outlet />;
};

export default User;
