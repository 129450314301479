/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import { FC, useRef, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { Auth } from 'aws-amplify';
import { logger } from 'utils/logger';
import { GetGroup } from 'domains/cognite/services/manageUser';
import AdminSignInLogo from '../images/admin-nav-logo.svg';
import './signIn.css';

const SignIn: FC = () => {
  const navigate = useNavigate();

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const signIn = async (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    try {
      if (emailRef.current && passwordRef.current) {
        await Auth.signIn(emailRef.current.value, passwordRef.current.value)
          .then(async () => {
            const group = await GetGroup();

            if (group !== 'Admin') {
              await Auth.signOut();
              throw new Error('custom error');
            }
          })
          .then(() => {
            logger.info('ログイン成功');
          })
          .then(() => {
            navigate('/admin/executor/list');
            // window.location.reload();
          });
      } else {
        throw new Error('custom error');
      }
    } catch (error) {
      window.alert('ログインに失敗しました');
      logger.error('ログイン失敗');
      logger.error('error signing in', error);
    }
  };

  const queryClient = useQueryClient();

  const { mutate } = useMutation(signIn, {
    onSuccess: () => {
      void queryClient.invalidateQueries(['current_auth_user']);
    },
  });

  return (
    <>
      <div className="admin-sign-in">
        <img src={AdminSignInLogo} alt="遺言アプリ" />
        <form>
          <input
            className="admin-email"
            type="email"
            id="email"
            ref={emailRef}
            placeholder="メールアドレスを入力"
          />
          <input
            className="admin-password"
            type="password"
            id="password"
            ref={passwordRef}
            placeholder="パスワードを入力"
          />
          <input
            className="admin-login"
            type="submit"
            value="ログイン"
            onClick={mutate}
          />
        </form>
      </div>
    </>
  );
};

export default SignIn;
