import { FC } from 'react';
import { Link } from 'react-router-dom';
import './topicsAction.css';

type Props = {
  prevId: string | undefined;
  nextId: string | undefined;
};

const TopicsActionLinks: FC<Props> = (props) => {
  const { prevId, nextId } = props;

  const availPrev = prevId ? '' : 'disable-admin';
  const availNext = nextId ? '' : 'disable-admin';

  return (
    <div className="topics-action">
      <Link to={`/admin/topics/detail/${prevId || ''}`} className={availPrev}>
        前のトピック
      </Link>
      <Link to={`/admin/topics/detail/${nextId || ''}`} className={availNext}>
        次のトピック
      </Link>
      <Link to="/admin/topics/list">トピックス一覧へ</Link>
    </div>
  );
};

export default TopicsActionLinks;
