import { FC, Suspense } from 'react';
import { useExecutorChatroomsAvailable } from 'hooks';
import ChatListLink from '../molecules/chatListLink';

const ChatListContent: FC = () => {
  const { chatrooms } = useExecutorChatroomsAvailable('chatlist');

  if (!chatrooms) {
    return (
      <div className="chatListContent">
        <p>チャットルーム取得失敗</p>
      </div>
    );
  }

  return (
    <div className="chatListContent">
      {chatrooms.map((ele) => (
        <Suspense fallback={<p>...読み込み中</p>}>
          <ChatListLink key={ele.id} chatRoom={ele} />
        </Suspense>
      ))}
    </div>
  );
};

export default ChatListContent;
