import { FC } from 'react';
import ExecutorLogoHeader from '../organisms/executorLogoHeader';
import ExecutorProcedureContent from '../organisms/executorProcedureContent';
import CommonFooter from '../templates/commonFooter';

const ExecutorProcedure: FC = () => (
  <>
    <ExecutorLogoHeader />
    <ExecutorProcedureContent />
    <CommonFooter />
  </>
);

export default ExecutorProcedure;
