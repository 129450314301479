import { useQuery } from 'react-query';
import { DataStore } from 'aws-amplify';
import { Properties } from 'models';
import { QueryProperties } from 'domains/cognite/services/manageProperties';
import { CognitoUserExt } from 'domains/cognite/user';
import { User } from 'domains/apigateway/services/get-user';
import { logger } from 'utils/logger';

type UseQueryProperties = (
  key: string,
  user: CognitoUserExt | User | undefined,
) => {
  data: Properties | undefined;
  isFetching: boolean;
};

const useQueryPropertiesQuick: UseQueryProperties = (
  key: string,
  user: CognitoUserExt | User | undefined,
) => {
  if (!user) {
    logger.error(user);
    throw Error;
  }

  const hasAttributes = (
    theUser: CognitoUserExt | User,
  ): theUser is CognitoUserExt => !!(theUser as CognitoUserExt)?.attributes;

  const QueryUserProperties = async () => {
    const result = await DataStore.start().then(async () => {
      const userId = hasAttributes(user) ? user.attributes.sub : user.id;
      const properties = await QueryProperties(userId);

      return properties;
    });

    return result;
    // const userId = hasAttributes(user) ? user.attributes.sub : user.id;
    // const properties = await QueryProperties(userId);

    // return properties;
  };
  const { data, isFetching } = useQuery([key], QueryUserProperties, {
    cacheTime: 200,
  });

  return { data, isFetching };
};

export default useQueryPropertiesQuick;
