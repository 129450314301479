/* eslint-disable no-alert */
import { FC } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import ReactStars from 'react-stars';
import { AssignStatus } from 'models';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import { useProfileIcon, useUsersListFromAdmin } from 'hooks';
import './executorDetail.css';
import {
  QueryAssignRelationshipFromAssignee,
  UpdateAllAssignStatusOfAssignee,
} from 'domains/cognite/services/manageAssignRelationship';
import { ListChatRoomsByAdmin } from 'domains/cognite/services/manageChat';
import { deleteExecutorFromAdmin } from 'domains/apigateway/services/deleteExecutorFromAdmin';
import {
  sendEmailFromAdmin,
  SendEmailFromAdminReqBody,
} from 'domains/apigateway/services/sendEmail';
import { logger } from 'utils/logger';
import AdminNav from '../organisms/adminNav';
import defaultProfileIcon from '../images/defaultProfileIcon.svg';

type State = {
  Name: string;
  Address: string;
  Prefecture: string;
  City: string;
  AddressNum: string;
  Building: string;
  Birthdate: string;
  Passport: string;
  PhoneNumber: string;
  Reward: string;
  Zoneinfo: string;
  Notion: string;
  Introduce: string;
  sub: string;
  Email: string;
  evaluationArray: number[];
};

const ExecutorDetail: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    Name,
    Address,
    Prefecture,
    City,
    AddressNum,
    Building,
    Birthdate,
    Passport,
    PhoneNumber,
    Reward,
    Zoneinfo,
    Notion,
    Introduce,
    sub,
    Email,
    evaluationArray,
  } = location.state as State;

  const { imageUrl } = useProfileIcon(defaultProfileIcon, sub);

  const checkChatroom = async () => {
    const chatRooms = await ListChatRoomsByAdmin(sub);

    return !!chatRooms;
  };

  const { data: chatroomCheck } = useQuery(
    ['executor_chatlist_check', sub],
    checkChatroom,
  );

  const userDatas = useUsersListFromAdmin(['clientList_of_assignee', sub]);

  const { data: clients } = useQuery(['clients_from_executor', sub], () =>
    QueryAssignRelationshipFromAssignee(sub),
  );

  const existChatroom = chatroomCheck ? '' : 'notClick';

  const DelayProcessScreen = () => {
    setTimeout(() => {
      window.location.href = '/admin/executor/edit';
    }, 100);
  };

  const deleteUser = async () => {
    const deleteUserDateConfilm = window.confirm(
      `${Name}の遺言執行者データを削除しますか？`,
    );
    if (deleteUserDateConfilm === true) {
      try {
        if (clients) {
          const clientIds = clients
            .filter(
              (ele) =>
                ele.status === AssignStatus.APPROVAL ||
                ele.status === AssignStatus.REGISTERD,
            )
            .map((ele) => ele.clientId);
          const clientEmails = userDatas
            .filter((ele) => clientIds.includes(ele.sub))
            .map((ele) => [ele.email]);

          const reqBody: SendEmailFromAdminReqBody = {
            emails: clientEmails,
            subject: '【遺言アプリ】遺言執行者退会のお知らせ',
            content:
              '担当または申請中の遺言執行者が退会しました。\nお手数ですが、再度遺言執行者の選定を行なってください。',
          };
          await sendEmailFromAdmin(reqBody);
        }

        await UpdateAllAssignStatusOfAssignee(sub, AssignStatus.DISABLE);
        await deleteExecutorFromAdmin(sub);

        navigate('/admin/executor/list');
        window.location.reload();
      } catch {
        logger.error('更新失敗');
      }
    }
  };

  const fullAddress = Prefecture + City + AddressNum + Building;
  const phoneNumerFormatted = formatPhoneNumber(PhoneNumber);

  return (
    <>
      <AdminNav />
      <div className="adminExecutorDetail">
        <div className="ExecutorDetail">
          <h1>遺言執行者管理</h1>
          <p>遺言執行者詳細</p>
          <div>
            <div className="ExecutorDetailWrap">
              <img src={imageUrl} alt="" />
              <div className="ExecutorDetailBox">
                <div>
                  <p>名前</p>
                  <p>{Name}</p>
                </div>
                <div>
                  <p>住所</p>
                  <p>{fullAddress}</p>
                </div>
                <div>
                  <p>生年月日</p>
                  <p>{Birthdate}</p>
                </div>
                <div>
                  <p>所持資格</p>
                  <p>{Passport}</p>
                </div>
                <div>
                  <p>電話番号</p>
                  <p>{phoneNumerFormatted}</p>
                </div>
                <div className="ExecutorDetailReward">
                  <p>遺言執行報酬</p>
                  <p>{Reward}</p>
                </div>
                <div>
                  <p>活動地域</p>
                  <p>{Zoneinfo}</p>
                </div>
                <div>
                  <p>登録名簿閲覧数の通知</p>
                  <p>{Notion}</p>
                </div>
                <div className="ExecutorDetailIntroduce">
                  <p>紹介文</p>
                  <p>{Introduce}</p>
                </div>
              </div>
              <div className="ExecutorDetailEvaluation">
                <p>担当遺言執行者の評価</p>
                <div>
                  <p>対応は早かったですか？</p>
                  <ReactStars
                    count={5}
                    size={38}
                    value={evaluationArray[0]}
                    color1="#DCDDDD"
                    color2="#F8B62D"
                    edit={false}
                    half={false}
                  />
                </div>
                <div>
                  <p>説明はわかりやすかったですか？</p>
                  <ReactStars
                    count={5}
                    size={38}
                    value={evaluationArray[1]}
                    color1="#DCDDDD"
                    color2="#F8B62D"
                    edit={false}
                    half={false}
                  />
                </div>
                <div>
                  <p>質問に対する回答は的確でしたか？</p>
                  <ReactStars
                    count={5}
                    size={38}
                    value={evaluationArray[2]}
                    color1="#DCDDDD"
                    color2="#F8B62D"
                    edit={false}
                    half={false}
                  />
                </div>
                <div>
                  <p>料金設定は明確でしたか？</p>
                  <ReactStars
                    count={5}
                    size={38}
                    value={evaluationArray[3]}
                    color1="#DCDDDD"
                    color2="#F8B62D"
                    edit={false}
                    half={false}
                  />
                </div>
                <div>
                  <p>料金設定は妥当でしたか？</p>
                  <ReactStars
                    count={5}
                    size={38}
                    value={evaluationArray[4]}
                    color1="#DCDDDD"
                    color2="#F8B62D"
                    edit={false}
                    half={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="EditAndDetail">
        <Link
          to="/admin/executor/edit"
          state={{
            Name,
            Address,
            Prefecture,
            City,
            AddressNum,
            Building,
            Birthdate,
            Passport,
            PhoneNumber,
            Reward,
            Zoneinfo,
            Notion,
            Introduce,
            sub,
            Email,
          }}
          onClick={() => DelayProcessScreen()}
        >
          編集
        </Link>
        <Link
          to="/admin/data/chatList"
          state={{ sub }}
          className={existChatroom}
        >
          メッセージ一覧
        </Link>
        <button type="button" onClick={deleteUser}>
          削除
        </button>
      </div>
    </>
  );
};

export default ExecutorDetail;
