/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import { FC, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useQueryPropertiesQuick } from 'hooks';
import { GetUser } from 'domains/cognite/services/manageUser';
import { logger } from 'utils/logger';
import ErrorBoundary from 'ErrorBoundary';
import Spinner from 'components/molecules/Spinner';
import LiabilitiesDescription from '../templates/liabilitiesDescription';
import WillHeader from '../organisms/willHeader';
import LiabilitiesTemp from '../templates/liabilitiesTemp';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import PageDescriptionPc from '../organisms/pageDescriptionPc';
import pageData from '../data/pageData';
import PcDescriptionBackImg from '../images/PcLiabilitiesBack.svg';
import './liabilitiesResponsive.css';

const Liabilities: FC = () => {
  // useSync();
  const { data: user } = useQuery(['authenticated_user'], GetUser);
  if (!user) {
    logger.error(user);
    throw Error;
  }
  const { data: dataQuick } = useQueryPropertiesQuick(
    'liabilities_quick',
    user,
  );
  const descriptionPc = <>{pageData[13].description}</>;

  const navigate = useNavigate();

  const onClickFunc = (link: string) => {
    navigate(link);
  };

  return (
    <>
      <WillHeader title="相続財産【消極財産・負債】" />
      <div className="sp-only">
        <LiabilitiesDescription />
      </div>
      <ErrorBoundary>
        <Suspense fallback={<Spinner size="large" />}>
          <LiabilitiesTemp data={dataQuick} user={user} />
        </Suspense>
      </ErrorBoundary>
      <CommonFooter onClickFunc={onClickFunc} />
      <PageDescriptionPc
        page="LiabilitiesDescriptionPc"
        description={descriptionPc}
        descriptionPcImg={PcDescriptionBackImg}
      />
    </>
  );
};

export default Liabilities;
