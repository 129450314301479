import { FC, Suspense } from 'react';
import UserLogoHeader from 'user/components/organisms/userLogoHeader';
import CommonFooter from 'common/apply/templates/commonFooter';
import TopicsDetailContent from '../organisms/topicsDetailContent';

const TopicDetail: FC = () => (
  <>
    <UserLogoHeader />
    <Suspense fallback={<div>...読み込み中</div>}>
      <TopicsDetailContent />
    </Suspense>
    <CommonFooter />
  </>
);

export default TopicDetail;
