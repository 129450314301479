/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import { FC, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Properties, AssignStatus } from 'models';
import {
  useQueryPropertiesQuick,
  useQueryAllocationQuick,
  useAssignPath,
  // usePropertiesItem,
  useQueryContents,
  useQueryAllocationReceiverAndProperties,
  // useSync,
  useRelationshipWithAssignee,
} from 'hooks';
import DeleteS3 from 'domains/aws/services/delete-s3';
import { getOneExecutorCatch } from 'domains/apigateway/services/get-Executor';
import {
  DeleteLegalHeir,
  DeleteRecipient,
  // GetAllocationReceiverByParent,
} from 'domains/cognite/services/manageAllocationReceiver';
import {
  DeleteAllocationProperties,
  QueryAllocationProperties,
  MutateAllocationProperties,
} from 'domains/cognite/services/manageAllocationProperties';
import { GetUser } from 'domains/cognite/services/manageUser';
import { logger } from 'utils/logger';
import {
  DeleteRealEstate,
  MutateRealEstateOnlyMajorNum,
} from 'domains/cognite/services/manageRealEstate';
import {
  DeleteMovingProperty,
  MutateMovingProperty,
} from 'domains/cognite/services/manageMovingProperty';
import {
  DeleteDeposits,
  MutateDepositsOnlyMajorNum,
} from 'domains/cognite/services/manageDeposits';
import {
  DeleteStock,
  MutateStockOnlyMajorNum,
} from 'domains/cognite/services/manageStock';
import {
  DeleteLifeInsurance,
  MutateLifeInsuranceOnlyMajorNum,
} from 'domains/cognite/services/manageLifeInsurance';
import {
  DeleteBonds,
  MutateBondsOnlyMajorNum,
} from 'domains/cognite/services/manageBonds';
import Dialog from 'common/apply/organisms/dialog';
import {
  DeleteLiabilities,
  MutateLiabilitiesOnluMajorNum,
} from 'domains/cognite/services/manageLiabilities';
import {
  DeleteProperties,
  MutateProperties,
} from 'domains/cognite/services/manageProperties';
import { DeleteWillInfo } from 'domains/cognite/services/manageWillInfo';
import { DeleteRealEstateContent } from 'domains/cognite/services/manageRealEstateContent';
import { DeleteDepositsContent } from 'domains/cognite/services/manageDepositsContent';
import { DeleteStockContent } from 'domains/cognite/services/manageStockContent';
import { DeleteLifeInsuranceContent } from 'domains/cognite/services/manageLifeInsuranceContent';
import { DeleteReceiver } from 'domains/cognite/services/manageReceiver';
import { DeleteBondsContent } from 'domains/cognite/services/manageBondsContent';
import { DeleteLiabilitiesContent } from 'domains/cognite/services/manageLiabilitiesContent';
import { DeleteLegalHeirQuestion } from 'domains/cognite/services/manageLegalHeirQuestion';
import { DeleteAllocation } from 'domains/cognite/services/manageAllocation';
import { DeleteTestamentFile } from 'domains/cognite/services/manageTestamentFile';

import { QueryAssignExecutorNotOnTheList } from 'domains/cognite/services/manageAssignExecutorNotOnTheList';

import WillHeader from '../organisms/willHeader';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import './confirm.css';
import ConfirmDescription from '../templates/confirmDescription';
import DownloadBtnContainer from '../organisms/downloadBtnContainer';
import NormalBtn from '../atoms/normalBtn';
import PageDescriptionPc from '../organisms/pageDescriptionPc';
import pageData from '../data/pageData';
import PcDescriptionBackImg from '../images/PcConfirmBack.svg';
import Preview from '../organisms/preview';

const Confirm: FC = () => {
  // useSync();
  const { data: user } = useQuery(['authenticated_user'], GetUser);
  if (!user) {
    logger.error(user);
    throw Error;
  }
  const path = useAssignPath();

  const { data: propertyData, isFetching: fetching } = useQueryPropertiesQuick(
    'confirm_properties',
    user,
  );

  const [isDialogShow, setIsDialogShow] = useState(false);

  const toggleDialog = () => {
    setIsDialogShow(!isDialogShow);
  };

  const {
    realEstateContent,
    depositsContent,
    stockContent,
    lifeInsuranceContent,
    bondsContent,
    liabilitiesContent,
    realEstateMajorNum,
    movingPropertyMajorNum,
    depositsMajorNum,
    stockMajorNum,
    lifeInsuranceMajorNum,
    bondsMajorNum,
    liabilitiesMajorNum,
  } = useQueryContents('confirm', propertyData);

  const [propertiesState, setPropertiesState] = useState(propertyData);

  useEffect(() => {
    const updateMajorNums = async (properties: Properties) => {
      const realEstate = properties.realEstate
        ? await MutateRealEstateOnlyMajorNum(properties.realEstate.id, {
            majorNum: realEstateMajorNum,
          })
        : undefined;

      const movingProperty = properties.movingProperty
        ? await MutateMovingProperty(properties.movingProperty.id, {
            ...properties.movingProperty,
            majorNum: movingPropertyMajorNum,
          })
        : undefined;

      const deposits = properties.deposits
        ? await MutateDepositsOnlyMajorNum(properties.deposits.id, {
            majorNum: depositsMajorNum,
          })
        : undefined;

      const stock = properties.stock
        ? await MutateStockOnlyMajorNum(properties.stock.id, {
            majorNum: stockMajorNum,
          })
        : undefined;

      const lifeInsurance = properties.lifeInsurance
        ? await MutateLifeInsuranceOnlyMajorNum(properties.lifeInsurance.id, {
            majorNum: lifeInsuranceMajorNum,
          })
        : undefined;

      const bonds = properties.bonds
        ? await MutateBondsOnlyMajorNum(properties.bonds.id, {
            majorNum: bondsMajorNum,
          })
        : undefined;

      const liabilities = properties.liabilities
        ? await MutateLiabilitiesOnluMajorNum(properties.liabilities.id, {
            majorNum: liabilitiesMajorNum,
          })
        : undefined;

      const newProperties = await MutateProperties(properties.id, {
        userId: user.attributes.sub,
        realEstate,
        movingProperty,
        deposits,
        stock,
        lifeInsurance,
        bonds,
        liabilities,
      });

      setPropertiesState(newProperties);
    };
    if (propertyData) {
      void updateMajorNums(propertyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyData]);

  // const { willInfo, movingProperty } = usePropertiesItem(propertyData);

  // 相続人情報
  const { data: allocation } = useQueryAllocationQuick(
    'confirm_allocation',
    user,
  );

  const { allocationReceiverAndProperties } =
    useQueryAllocationReceiverAndProperties(
      `confirm_${user.attributes.sub}`,
      allocation,
    );

  // 財産割り当て情報
  const { data: allocationPropertiesData } = useQuery(
    ['allocationPropertiesData'],
    QueryAllocationProperties,
    {
      cacheTime: 200,
    },
  );

  // const allocationProperties = allocationPropertiesData || [];
  const [allocationProperties, setAllocationProperties] = useState(
    allocationPropertiesData || [],
  );

  useEffect(() => {
    const updateMajorNumsOfAllocation = async () => {
      const newAllocationProperties = await Promise.all(
        allocationProperties.map(async (ele) => {
          const newMajorNum = () => {
            if (ele.category === '不動産') {
              return realEstateMajorNum;
            }
            if (ele.category === '預貯金') {
              return depositsMajorNum;
            }
            if (ele.category === '株式・債券') {
              return stockMajorNum;
            }
            if (ele.category === '生命保険') {
              return lifeInsuranceMajorNum;
            }
            if (ele.category === '貸付金' || ele.category === '出資金') {
              return bondsMajorNum;
            }

            return liabilitiesMajorNum;
          };
          const newElement = await MutateAllocationProperties(ele.id, {
            ...ele,
            majorNum: newMajorNum(),
          });

          return newElement;
        }),
      );
      setAllocationProperties(newAllocationProperties);
    };

    void updateMajorNumsOfAllocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocationPropertiesData]);

  // word, pdfの更新ができる
  const enableUpdateFiles = !!propertyData;

  const clickName = '';

  const confirmDelete = async () => {
    // pdf, wordの削除
    await DeleteS3(`遺言書_${user.attributes.sub}.docx`);
    await DeleteS3(`遺言書_${user.attributes.sub}.pdf`);
    // 相続財産のデータ削除
    await DeleteProperties();
    await DeleteWillInfo();
    await DeleteRealEstateContent();
    await DeleteRealEstate();
    await DeleteMovingProperty();
    await DeleteDepositsContent();
    await DeleteDeposits();
    await DeleteStockContent();
    await DeleteStock();
    await DeleteLifeInsuranceContent();
    await DeleteReceiver();
    await DeleteLifeInsurance();
    await DeleteBondsContent();
    await DeleteBonds();
    await DeleteLiabilitiesContent();
    await DeleteLiabilities();
    // await DeleteProperties();
    // 法定相続人・受遺者のデータ削除
    await DeleteLegalHeir();
    await DeleteLegalHeirQuestion();
    await DeleteRecipient();
    // 財産割り当てのデータ削除
    await DeleteAllocationProperties();
    await DeleteAllocation();
    await DeleteTestamentFile();
    // const wordExistLength = await ListS3(
    //   `遺言書_${user.attributes.sub}.docx`,
    // );
    // const wordExistCheck = !!wordExistLength;
    // setFileExist(wordExistCheck);
    window.location.reload();

    setPropertiesState(undefined);
  };

  const { data: queryExecutor } = useRelationshipWithAssignee(
    'assignee_APPROVAL',
    AssignStatus.APPROVAL,
  );

  const getTheExecutor = async () => {
    if (!queryExecutor) {
      return undefined;
    }

    const theExecutor = await getOneExecutorCatch(
      queryExecutor.relationship.assigneeId,
    );

    return theExecutor;
  };

  const { data: executor } = useQuery(
    ['assignee_profile', AssignStatus.APPROVAL],
    getTheExecutor,
  );

  const getTheExecutorNotList = async () => {
    const getQueryContent = await QueryAssignExecutorNotOnTheList();

    return getQueryContent[0];
  };

  const { data: executorNotList } = useQuery('executor_not_list', () =>
    getTheExecutorNotList(),
  );

  const [startUpload, setStartUpload] = useState(false);
  const toggleStartUpload = (set: boolean) => {
    setStartUpload(set);
  };

  return fetching ? (
    <div>Loading</div>
  ) : (
    <>
      {isDialogShow ? (
        <Dialog
          dialogText="これまでに入力した全ての内容が削除されます。よろしいですか？"
          dialogYesFunc={async () => {
            await confirmDelete();

            toggleDialog();
          }}
          dialogNoFunc={toggleDialog}
        />
      ) : (
        ''
      )}
      <WillHeader title="遺言書の確認" />
      <div className="sp-only">
        <ConfirmDescription />
      </div>
      <div className="confirmContainer">
        <DownloadBtnContainer
          userId={user.attributes.sub}
          realEstateContent={realEstateContent}
          depositsContent={depositsContent}
          stockContent={stockContent}
          lifeInsuranceContent={lifeInsuranceContent}
          bondsContent={bondsContent}
          liabilitiesContent={liabilitiesContent}
          allocationReceiver={allocationReceiverAndProperties}
          propertyData={propertiesState}
          enableUpdateFiles={enableUpdateFiles}
          executor={executor}
          executorNotList={executorNotList}
          startUpload={startUpload}
          toggleStartUpload={toggleStartUpload}
        />
        {/* <Suspense fallback={<p>読み込み中</p>}> */}
        <Preview userId={user.attributes.sub} startUpload={startUpload} />
        {/* </Suspense> */}
        <div className="consultationBtn">
          <NormalBtn
            title="遺言執行者を選定する"
            className="normalBtn"
            href={path}
            clickName={clickName}
          />
        </div>
        <div className="propertyAllocation-button">
          <NormalBtn
            title="相続財産の割り振りへ"
            className="normalBtn"
            href="/user/propertyAllocation"
          />
        </div>
        <button type="button" onClick={toggleDialog} className="deleteBtn">
          すべての項目を削除
        </button>
      </div>
      <CommonFooter />
      <PageDescriptionPc
        page="confirmDescriptionPc"
        description={pageData[22].description}
        descriptionPcImg={PcDescriptionBackImg}
      />
    </>
  );
};

export default Confirm;
