import { FC } from 'react';
import './willPolicyContent.css';

const WillPolicyContent: FC = () => (
  <>
    <div className="will-policy-content">
      <div>
        <p>遺言アプリ利用規約</p>
      </div>
      <div>
        <p>
          一般社団法人日本遺言執行士協会（以下、「当協会」という）は、当協会が提供するサービスである「遺言アプリ」（以下、「遺言アプリ」という）の利用について、以下のとおり利用規約を定める（以下「本規約」という）。
          遺言アプリを利用して遺言書の下書き（原稿）を作成しようとする利用者（以下、「お客様」という）は、予め本規約に同意した上で、遺言アプリを利用するものとする。
          なお、本規約の内容は、7、（2）に従い変更することがあるので、最新の利用規約をご参照ください。
        </p>
        <div className="will-policy-sentence">
          <div>
            <p>1、遺言アプリの目的</p>
            <p>
              遺言アプリは、これを利用して、気軽に自身の状況に応じた遺言書の下書きを作成しようとする遺言者の手助けとなるとともに、遺言者が遺言書の下書きから、遺言書を法的に有効にするため個別のアドバイスを求めたいとき、遺言アプリ内の名簿から登録資格者を選んで、アドバイスを受け、遺言執行者の選定をすることができるアプリである。
              同時に、遺言者が、登録資格者に関するコメント（以下、遺言者から遺言アプリに投稿された情報を総称して「口コミ」という）を公開することにより、他の遺言者が登録資格者を選ぶ際の参考としていただくことを目的とする。
            </p>
          </div>
          <div>
            <p>２、遺言者登録手続き</p>
            <p>
              遺言アプリを利用したい者は、遺言アプリの新規登録ページで、所定の項目とパスワードを入力して新規登録をする。
              当協会は、提供するサービスの内容等を任意に決定し、又は随時変更できるものとする。
            </p>
          </div>
          <div>
            <p>３、セキュリティーについて</p>
            <p>
              遺言者登録については、インターネット上の暗号通信技術SSL（Secure
              Socket
              Layer）を使用し、送信される情報は第三者に見られることのないよう暗号化される。また、コンピューターウィルスについても、ウィルス対策ソフトの導入などを行っている。
            </p>
          </div>
          <div>
            <p>４、ログインアカウント等の登録・管理</p>
            <p>
              遺言者は、ログインアカウント及びパスワード（以下「ログインアカウント等」という。）の登録・管理等をご自身の責任において行うものとし、遺言者の管理不十分、使用上の過誤、第三者の使用等により生じた損害について、当協会は一切の責任を負わない。また、遺言者は、ログインアカウント等にかかるサービス（当協会以外の運営するサービスを含み、以降同様とする）の利用をご自身の責任において利用するものとし、当該サービスの利用については当該サービスの運営者が規定する各規約の定めに従うものとする。ログインアカウント等にかかるサービスを利用したことにより生じた損害、当該サービス運営者と遺言者の間に生じたトラブル等について当協会は一切の責任を負わない。
            </p>
          </div>
          <div>
            <p>５、個人情報について</p>
            <p>（１）個人情報の取り扱い</p>
            <p className="indent1 li-indent2">
              ①
              事業者の名称、個人情報保護管理責任者の職名、連絡先は下記のとおり。
            </p>
            <ul className="indent2 li-indent0">
              <li>・事業者：一般社団法人日本遺言執行士協会</li>
              <li>・個人情報保護管理責任者：石田 泉</li>
              <li>・連絡先：ishida@igon.co.jp</li>
            </ul>

            <p className="indent1">② 利用目的</p>
            <p className="indent2">
              当協会は、遺言者に対して遺言アプリを提供するにあたり、原則として個人情報を取得することはないが、遺言者登録に際しメールアドレスを取得する。
              <br />
              また、遺言者が任意に入力した個人情報を結果的に取得する。
              <br />
              この個人情報の利用目的は以下のとおりです。
            </p>
            <ul className="indent2 li-indent0">
              <li>・遺言者登録に際し、連絡先確認のため</li>
              <li>・ログイン時における本人確認のため</li>
              <li>
                ・個人を識別できない形式に加工した上、統計データを作成するなど当事務所のサービス向上のため
              </li>
              <li>
                ・遺言アプリ利用に関し、必要に応じて遺言者に対し連絡をするため
              </li>
            </ul>
            <p>（２）第三者提供</p>
            <p className="indent1">
              当協会は、以下のいずれかに該当する場合を除き、遺言者の個人情報を第三者へ開示又は提供しない。
            </p>
            <ul className="indent1 li-indent2">
              <li>① 遺言者の同意があるとき</li>
              <li>
                ②
                遺言者を識別することができない状態で、統計的なデータとして開示・提供するとき
              </li>
              <li>③ 法令に基づくとき</li>
              <li>
                ④
                人の生命、身体又は財産の保護のために必要な場合で、登録資格者の同意を得る
                ことが困難なとき
              </li>
              <li>
                ⑤
                公共の利益のため特に必要があるときで、遺言者の同意を得ることが困難なとき
              </li>
              <li>
                ⑥
                国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要があり、遺言者の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
              </li>
            </ul>
            <p>（３）業務委託</p>
            <p className="indent1">
              当協会は取得した個人情報の取扱いの全部又は一部を、必要な保護措置を講じた上、上記利用目的に必要な範囲において、第三者へ委託する場合があります。
            </p>
            <p>（４）訂正・削除、利用停止等</p>
            <p className="indent1">
              遺言者が、自身の個人情報の変更・追加・消去を希望する場合は、遺言アプリ内の「遺言者情報」に入力して行うこと。
            </p>
            <p>（５）その他</p>
            <p className="indent1">
              遺言者がすべての項目に入力しなかった場合、遺言アプリの全部又は一部が利用できない場合がある。
            </p>
          </div>
          <div>
            <p>6、遺言者情報について</p>
            <p>
              遺言アプリ利用者に、より良いサービスを提供するためにクッキーCookie）、Webビーコン、端末識別子などの遺言者に関する情報（以下、総称して「遺言者情報」という）を取得及び利用することがある。
              <br />
              クッキーは、遺言者のブラウザ設定によりクッキーの受け取りを拒否したり、制限したりできるが、その際は、遺言アプリの利用において制約を受ける場合がある。Webビーコンの使用に関しては、遺言者側で制限することが出来ない場合がある。
              <br />
              当協会はこれらの技術を遺言者の個人情報を取得する目的では使用しない。
            </p>
          </div>
          <div>
            <p>7、遺言アプリの利用について</p>
            <p>（１）アプリの転用・転売の禁止</p>
            <ul className="indent1 li-indent2">
              <li>
                ①
                遺言者は、当事務所が提供する遺言アプリについて、その全部あるいは一部を問わず、営業活動その他の営利を目的とした行為又はそれに準ずる行為やそのための準備行為を目的として、利用又はアクセスしてはならないものとする。
                <br />
                また、その他、宗教活動、政治活動などの目的での利用又はアクセスも行ってはならない。
              </li>
              <li>
                ②
                遺言アプリへ投稿された口コミを無断転載・無断利用することは禁止する。ただし、当該投稿をした本人は除く。
              </li>
              <li>
                ③
                口コミを投稿した本人による当該口コミの利用等本規約が特に認めた場合を除き、遺言アプリに掲載されている口コミを利用して利益を得た場合には、当事務所はその利益相当額の金員を請求できる権利を有する。
              </li>
            </ul>

            <p>（２）遺言アプリの変更・停止</p>
            <p className="indent1">
              当協会は遺言者へ提供している遺言アプリを、当協会の都合により変更することがある。
              <br />
              また、災害・事故、その他緊急事態が発生した際には、遺言アプリを停止する場合がある。
              <br />
              なお、事前に遺言者の承諾を得ることなく遺言アプリを変更・停止等したことにより、遺言者又は第三者が損害を受けたとしても、当協会は一切の補償はしない。
            </p>

            <p>（３）遺言アプリの利用に必要となる設備</p>
            <p className="indent1">
              webへのアクセスについては、遺言者は自らの責任と費用で必要な機器、ソフトウェアを適切に準備、操作する必要があり、当協会は遺言者がwebにアクセスするための準備、方法などについては一切関与しない。
            </p>

            <p>（４）口コミの投稿及び著作権等</p>
            <ol className="indent1 li-indent1">
              <li>
                1
                遺言者が遺言アプリに口コミ投稿を行う際には、遺言者登録が必要となる。
              </li>
              <li>
                2
                遺言者が遺言アプリに口コミ投稿を行う際には、当協会が別途定めるガイドラインを遵守していただき、このガイドラインを含めたものが、遺言アプリ利用規約（遺言者用）となっている。
              </li>
              <li>
                3
                遺言者が遺言アプリに口コミの投稿を行った時点で、当該口コミの国内外における複製、公衆送信、頒布、翻訳・翻案等、著作権法上の権利（当協会から第三者に対する再使用許諾権を含む）を、当該著作権の存続期間の満了日まで、遺言者が当事務所に対して無償で利用することを許諾したものとする。
              </li>
              <li>
                4
                遺言者ご自身が投稿された口コミに関する複製、公衆送信、頒布、翻訳・翻案等、著作権法上の権利を全て有していることを、当該遺言者に保証していただきますので、著作権法上の権利の有無については十分に注意して投稿を行ってください。また、第三者の著作物等を利用して投稿を行う場合には、遺言者の責任と負担において前記③の許諾に必要な権利処理がなされていることを前提とさせていただきますので、第三者の著作物等を利用される場合にも十分に注意をして投稿を行うこと。
              </li>
              <li>
                5
                当協会若しくは当協会から再使用許諾を受けた第三者が、遺言者の口コミを利用する場合、地域制限、著作権表示義務その他付随条件はないものとし、遺言者による利用許諾の期間は遺言者の著作権が存続する限りとする。ロイヤリティ等の対価は一切発生しないものとします。
              </li>
              <li>
                6
                当協会又は第三者が遺言者の口コミを利用したことによって、遺言者又は第三者が受けた損害については、当協会は一切の補償をしない。
              </li>
            </ol>
            <p>（５） 口コミ投稿における外部サービス連携</p>
            <ol className="indent1 li-indent1">
              <li>
                1
                遺言アプリにおいて口コミを投稿する際に、遺言者がtwitter・Facebook等の外部サービス（以下「外部サービス」という）との連携機能を利用する場合、遺言者は、かかる連携により遺言者が作成した口コミの外部サービスへの投稿を当協会が遺言者に代行して行うこと、及び、当協会が遺言者に代行して外部サービスへの投稿にあたり、当該口コミに関連するウェブページへのURLを付加して投稿することを予め承諾する。
              </li>
              <li>
                2
                外部サービスを利用する場合、遺言者は、自己の責任において当該サービスを利用するものとし、当協会は、当該サービスを利用したことにより生じた損害、当該サービスの運営者・利用者等との間に生じたトラブルその他の当該サービスに関連する一切の事項について何らの責任も負わないものとします。
              </li>
            </ol>
          </div>
          <div>
            <p>8、免責事項</p>
            <p>
              遺言アプリは、遺言者自身の責任においてご利用いただき、以下の各号につき遺言者はあらかじめ了承する。
            </p>
            <p>（１）登録資格者情報</p>
            <p className="indent1">
              当協会は、名簿登録された登録事項に関する情報については、いかなる保証もしない。遺言者から登録資格者へアドバイスのオファーを出すときは、遺言者の責任に
              おいて行うこと。
              <br />
              また、掲載された登録資格者に関する情報によって遺言者に生じた損害や、遺言者と登録資格者のトラブル等について、当協会は一切の補償及び関与をしない。
              <br />
              なお、資格者登録の情報が客観的に正しくない場合は、当協会へご連絡ください。
            </p>
            <p>（２）口コミの内容</p>
            <p className="indent1">
              当協会は、掲載された口コミの内容に関していかなる保証もしないので、遺言者の判断で利用すること。
            </p>

            <p>（３）リンク先サイト</p>
            <p className="indent1">
              当協会は、遺言アプリからリンクされた第三者が運営するサイトに関して、いかなる保証もしない。遺言者のご判断でご利用ください。また、リンク先で生じた損害や、遺言者同士のトラブル等に対しても、当協会は一切の補償及び関与をしない。
            </p>
            <p>（４）口コミの削除</p>
            <p className="indent1">
              口コミは、遺言者からの投稿で成り立っている情報源ですが、遺言者の皆様の利用・情報交換がスムースに行われるよう、下記に該当・類似する口コミが発見された場
              合、予告無く当該口コミを遺言アプリ上から削除する場合がある。
              <br />
              なお、削除対象に該当するか否かは、全て当協会が判断する。
              <br />
              また、7、（5）に従い連携された外部サービス上に掲載された口コミに関しては、第三者が運営するサービス・ウェブサイトでの表示であるため、当協会が削除等の措置を講じることはしない。
            </p>
            <p className="center">記</p>
            <ol className="indent3 li-indent2">
              <li>1. ガイドラインに反するもの</li>
              <li>2. 公序良俗に反するもの</li>
              <li>
                3.
                遺言アプリの趣旨、又は口コミの対象となる登録資格者や遺言者と関係のないもの
              </li>
              <li>4. 有害なプログラム・スクリプト等を含むもの</li>
              <li>
                5.
                営利を目的としたものや、個人的な取引や勧誘を持ちかける内容、宣伝行為に関するもの
              </li>
              <li>
                6.
                その他、遺言アプリの管理運営を妨げる等、当事務所が不適切と判断したもの
              </li>
            </ol>
          </div>
          <div>
            <p>9、著作権、財産権その他の権利</p>
            <ol className="li-indent3">
              <li>
                （１）
                遺言アプリに含まれているコンテンツ及び個々の情報､商標､画像､広告､デザイン等に関する著作権､商標権その他の知的財産権、及びその他の財産権は全て当協会又は正当な権利者に帰属しています｡
              </li>
              <li>
                （２）
                遺言アプリ及び関連して使用されている全てのソフトウェアは､知的財産権に関する法令等により保護されている財産権を含んでいます｡
              </li>
              <li>
                （３）
                遺言者は､当協会若しくは著作権その他の知的財産権及びその他の財産権を有する第三者から利用･使用を許諾されている場合､又は､法令により権利者からの許諾なく利用若しくは使用することを許容されている場合を除き、遺言アプリ及び遺言アプリの内容について複製､編集､改変､掲載､転載､公衆送信､配布､販売､提供､翻訳・翻案その他あらゆる利用又は使用を行ってはならない。
              </li>
              <li>
                （４）
                登録資格者が前各号に反する行為によって被った損害については､当協会は一切の責任を負わないものとし、また､登録資格者がこれらの行為によって利益を得た場合､当協会はその利益相当額を請求できる権利を有するものとする。
              </li>
            </ol>
          </div>
          <div>
            <p>10、禁止行為</p>
            <p>
              遺言者が遺言アプリを利用するに際して、次の行為を行うことを禁止します。
            </p>
            <ol className="indent1 li-indent1">
              <li>
                1
                法令上又は本規約若しくはガイドライン上特に認められている場合を除き、遺言アプリの提供する情報を当協会の事前の同意なく、複写、若しくはその他の方法により再生、複製、送付、譲渡、頒布、配布、転売、又はこれらの目的で使用するために保管すること
              </li>
              <li>2 本規約又はガイドラインに違反すること</li>
              <li>3 公序良俗に反すること</li>
              <li>
                4
                違法行為・犯罪的行為・重大な危険行為に結びつくこと又はこれらを助長すること
              </li>
              <li>
                5
                当協会、他の遺言者又は第三者の知的財産権（著作権、意匠権、実用新案権、商標権、特許権、ノウハウが含まれますがこれに限定されない）を侵害すること
              </li>
              <li>
                6
                他の登録資格者又は第三者の権利又は利益を違法に侵害し、又はそのおそれがあること
              </li>
              <li>
                7 遺言アプリの運営を妨げること、又は当協会の信用を毀損すること
              </li>
              <li>
                8
                面識のない異性との出会い等を目的として遺言アプリの機能を利用すること
              </li>
              <li>9 その他、当協会が不適切と判断すること</li>
              <li>
                10
                遺言者により上記各号に該当する行為又は遺言アプリの趣旨・目的に照らして不適切であると当協会が判断する行為がなされた場合、当協会は当該遺言者に対して、遺言アプリの利用の停止その他当協会が適切と判断する措置（以下「利用停止措置」という。）をとることができる。なお、利用停止措置は遺言者の帰責性の有無にかかわらず当協会の裁量・判断に基づき行うことができ、利用停止措置を行った理由については､その如何を問わず遺言者に対して一切回答しないし、利用停止措置に起因して遺言者に生じた損害については､当協会は一切の責任を負わないものとする。
              </li>
            </ol>
          </div>
          <div>
            <p>11、退出</p>
            <ol className="indent1 li-indent1">
              <li>
                1
                遺言者が遺言アプリから退出したいときは、遺言アプリ内の「メニュー
                &gt;
                退出する」の、「退出する」をクリックすることにより退出する。
              </li>
              <li>
                2
                遺言者が退出手続を行った場合、利用していた遺言者のアカウントに関する一切の権利を失う。
              </li>
              <li>
                3
                遺言者は、退出手続によっても、遺言者として掲載した口コミ記事の全体、または部分に、重ねて「退出済み」との文字が表示されるのみで、退出後も1年間は削除されないことを予め承諾する。
              </li>
              <li>
                4
                遺言者が退出手続を完了した場合であっても、本規約4、ログインアカウント等の登録・管理、5、個人情報について、7、遺言アプリの利用について（1）（2）（4）（5）、8、免責事項、9、著作権、財産権その他の権利、10、禁止行為、13、準拠法及び裁判管轄はなお有効とする。
              </li>
            </ol>
          </div>
          <div>
            <p>12、本規約の変更</p>
            <ol className="indent1 li-indent1">
              <li>
                1
                当協会は、本規約を変更できるものとし、当協会が任意に定めた効力発生日から変更後の本規約の効力が発生するものとします。
              </li>
              <li>
                2
                前項の場合、当協会は、効力発生日の1ヶ月以上前の相当な時期までに、遺言者に対して、本規約の変更の内容及び効力発生日を通知する。
                但し、当該変更による遺言者の不利益の程度が軽微であると当協会が判断した場合、その期間を短縮することができる。
              </li>
              <li>
                3
                前項の規定は、本規約の変更が遺言者の一般の利益に適合する場合には適用しないものとします。
              </li>
            </ol>
          </div>
          <div>
            <p>13、準拠法及び裁判管轄</p>
            <ol className="indent1 li-indent1">
              <li>
                1
                本規約は、日本法に基づき解釈されるものとし、本規約に関して訴訟の必要が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とする。
              </li>
            </ol>
          </div>
          <div>
            <p>14、お問い合わせ</p>
            <p>ご不明な点は、下記にお問い合わせください。</p>
            <p className="indent1">＜受付窓口＞</p>
            <p className="indent2">一般社団法人日本遺言執行士協会</p>
            <p className="indent2">担当：石田 泉 ishida@igon.co.jp</p>
          </div>
        </div>
      </div>
    </div>
    <div className="will-policy-content">
      <div>
        <p>
          協会が定める <br className="sp-only" />
          口コミガイドライン
        </p>
      </div>
      <div>
        <p>
          口コミの正確性・信ぴょう性確保のため、全ての遺言者を対象に口コミ投稿は1日1件に制限する。
          投稿された口コミは全て審査後に表示する。 審査には5～10営業日を要する。
          <br />
          審査によって掲載されない口コミもある。
          <br />
          下記に掲げる口コミガイドラインは、サイトの信頼性と利用者の利益を担保するため、環境の変化に対応して常に見直しを行なう。
        </p>
        <div className="will-policy-sentence">
          <div>
            <p>1. あなたの言葉で口コミを書いてください</p>
            <p>
              実際にあなたが登録遺言執行者へ質問したり依頼したりした内容について、感じた事を書いてください。
              <br />
              また、内容が不明瞭で参考にならない口コミや既掲載記事のコピーは掲載対象外です。
            </p>
          </div>
          <div>
            <p>2. やらせ口コミの投稿は禁止</p>
            <p>
              実際には登録遺言執行者へ質問したり依頼したりしていないのに、登録遺言執行者や第三者から金品を受け取り、または、経済的利益を受けることの見返りに口コミ投稿をする行為を禁止する。
              <br />
              また、1人が複数のアカウントを使って口コミを投稿することを禁止する。必ず遺言者本人の持つ端末からご投稿ください。
            </p>
          </div>
          <div>
            <p>3. 登録遺言執行者の関係者による口コミ投稿は禁止</p>
            <p>審査において関係者によるものと判断した場合は削除する。</p>
          </div>
          <div>
            <p>4. 単なる誹謗中傷は掲載できない。</p>
            <p>
              遺言執行者、他の遺言者などを誹謗・中傷する内容は掲載できない。
            </p>
          </div>
          <div>
            <p>5. 権利やプライバシーを侵害する投稿はできない。</p>
            <p>
              書籍、雑誌、ホームページ、ブログ、他のウェブサイトなどに既に掲載された内容をそのまま転記・転載することはできない。
              <br />
              また、登録資格者以外の個人が特定できるような表現の文章、写真も掲載できない。
            </p>
          </div>
          <div>
            <p>6. 法令に反するもの、それに準ずるものは禁止</p>
            <p>
              法令に反するもの、犯罪行為や危険行為に結びつくもの、それらを助長するような内容の口コミ投稿は禁止する。
            </p>
          </div>
          <div>
            <p>7. 登録遺言執行者とは関係のない口コミは禁止</p>
            <p>
              その登録遺言執行者が提供しているサービスや業務の内容について、実際に依頼して感じたことをご自身の感想としてご投稿し、関係のない口コミは禁止する。
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default WillPolicyContent;
