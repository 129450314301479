/* eslint-disable react/require-default-props */
import { FC } from 'react';
import './inputItem.css';

type Props = {
  title: string;
  title1?: string;
  name: string;
};

const InputItem: FC<Props> = (props) => {
  const { title, name, title1 } = props;
  // eslint-disable-next-line consistent-return
  const category = () => {
    if (name === 'require') {
      return <span className={name}>必須</span>;
    }
    if (name === 'optional') {
      return <span className={name}>任意</span>;
    }
    if (name === 'blank') {
      return <span className={name}>入力不要</span>;
    }
  };

  return (
    <>
      <p className="inputItem sp-only">
        {title1 || title}
        {/* 必須、任意、入力不要を返す */}
        {category()}
      </p>
      <p className="inputItem pc-only" style={{ whiteSpace: 'pre-line' }}>
        {title}
        {/* 必須、任意、入力不要を返す */}
        {category()}
      </p>
    </>
  );
};

export default InputItem;
