/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { FC, useState, ChangeEvent, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { ListS3, GetS3File } from 'domains/aws/services/get-s3';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import NormalBtn from '../atoms/normalBtn';
import './executorProfileEditContent.css';
import { Prefecture, PrefectureArray } from '../data/prefecture';
import plofileEditCamera from '../images/plofile-edit-camera.svg';

type State = {
  name: string;
  birthdate: string;
  defaultPrefecture: string;
  defaultCity: string;
  defaultNumbers: string;
  defaultBuilding: string;
  phoneNumber: string;
  passport: string;
  zoneInfo: string;
  defaultReward: string;
  defaultNotion: string;
  defaultIntroduce: string;
  sub: string;
  initialImageUrl: string;
};

type cityProps = {
  prefCode: string;
  cityCode: string;
  cityName: string;
  bigCityFlag: string;
};

const ExecutorProfileEditContent: FC = () => {
  const location = useLocation();
  const {
    name,
    birthdate,
    // address,
    defaultPrefecture,
    defaultCity,
    defaultNumbers,
    defaultBuilding,
    phoneNumber,
    sub,
    passport,
    zoneInfo,
    defaultReward,
    defaultNotion,
    defaultIntroduce,
    initialImageUrl,
  } = location.state as State;

  const [imageUrl, setImageUrl] = useState(initialImageUrl);
  const [image, setImage] = useState<File | null>(null);

  useEffect(() => {
    const changeInitialImage = async () => {
      const fileExist = !!(await ListS3(`profile_${sub}`));
      if (fileExist) {
        const initialImage = await GetS3File(`profile_${sub}`, 'public');
        setImage(initialImage);
      }
    };

    void changeInitialImage();
  }, [sub]);

  const changeImage = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const profile = event.target.files[0];
      setImage(profile);

      const reader = new FileReader();
      reader.onload = (e) => {
        if (typeof e.target?.result === 'string') {
          setImageUrl(e.target?.result);
        }
      };
      reader.readAsDataURL(event.target?.files[0]);
    }
  };

  const prefectureRef = useRef<HTMLSelectElement>(null);

  const [prefecture, setPrefecture] = useState(defaultPrefecture);
  const [city, setCity] = useState(defaultCity);
  const [numbers, setNumbers] = useState(defaultNumbers);
  const [building, setBuilding] = useState(defaultBuilding);
  const [notion, setNotion] = useState(defaultNotion);
  const [reward, setReward] = useState(defaultReward);
  const [introduce, setIntroduce] = useState(defaultIntroduce);
  const [cityValue, setCityValue] = useState<cityProps[]>([]);

  const changePrefecture = (e: ChangeEvent<HTMLSelectElement>) => {
    setPrefecture(e.target.value);
  };
  const changeCity = (e: ChangeEvent<HTMLSelectElement>) => {
    setCity(e.target.value);
  };
  const changeNumbers = (e: ChangeEvent<HTMLInputElement>) => {
    setNumbers(e.target.value);
  };
  const changeBuilding = (e: ChangeEvent<HTMLInputElement>) => {
    setBuilding(e.target.value);
  };
  const changeNotion = (e: ChangeEvent<HTMLInputElement>) => {
    setNotion(e.target.value);
  };
  const changeReward = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setReward(e.target.value);
  };
  const changeIntroduce = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setIntroduce(e.target.value);
  };

  const getCity = async () => {
    const selectLabel = prefectureRef.current
      ? prefectureRef.current.value
      : '';

    const selectArr = PrefectureArray.find((ele) => ele.label === selectLabel);
    const selectId = selectArr ? selectArr.id : 48;

    await fetch(
      `https://opendata.resas-portal.go.jp/api/v1/cities?prefCode=${selectId}`,
      {
        headers: { 'X-API-KEY': 'yvet69MOZSiyzRoinuvNJaWjcXaqhoP118HTqciA' },
      },
    )
      .then((response) => response.json())
      .then((citiesData) => {
        setCityValue(citiesData.result);
      });
  };

  useEffect(() => {
    const selectLabel = defaultPrefecture;

    const selectArr = PrefectureArray.find((ele) => ele.label === selectLabel);
    const selectId = selectArr ? selectArr.id : 1000;

    void fetch(
      `https://opendata.resas-portal.go.jp/api/v1/cities?prefCode=${selectId}`,
      {
        headers: { 'X-API-KEY': 'yvet69MOZSiyzRoinuvNJaWjcXaqhoP118HTqciA' },
      },
    )
      .then((response) => response.json())
      .then((citiesData) => {
        setCityValue(citiesData.result);
      });
  }, [defaultPrefecture]);

  const formatedPhoneNumber = phoneNumber ? formatPhoneNumber(phoneNumber) : '';

  return (
    <div className="executorProfileEditContent">
      <div>
        <div>
          <div className="plofileImgRelative">
            <label htmlFor="imgEdit" className="plofileImg">
              <img src={imageUrl} alt="" />
              <img src={plofileEditCamera} alt="" />
            </label>
          </div>
          <div className="plofile-edit-icon-name">
            <input type="file" id="imgEdit" onChange={changeImage} />
            <label htmlFor="imgEdit">写真を変更</label>
            <div>
              <p>氏名</p>
              <p>{name}</p>
            </div>
          </div>
        </div>
        <form action="">
          <div>
            <p>生年月日</p>
            <p>{birthdate}</p>
          </div>
          <div>
            <label htmlFor="address">
              <p>住所または事務所</p>
              <div>
                <select
                  id="prefecture"
                  name="prefecture"
                  // onChange={changePrefecture}
                  onChange={async () => {
                    await getCity();
                  }}
                  onBlur={changePrefecture}
                  defaultValue={defaultPrefecture}
                  ref={prefectureRef}
                >
                  <option value="" selected hidden>
                    都道府県を選択
                  </option>
                  {PrefectureArray.map((ele: Prefecture) => (
                    <option value={ele.label} key={ele.label}>
                      {ele.label}
                    </option>
                  ))}
                </select>
                <select
                  id="city"
                  name="city"
                  onChange={changeCity}
                  defaultValue={defaultCity}
                >
                  <option value="" selected hidden>
                    市区町村を選択
                  </option>
                  {cityValue.map((ele) => (
                    <option
                      value={ele.cityName}
                      key={ele.cityName}
                      selected={defaultCity === ele.cityName}
                    >
                      {ele.cityName}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  id="numbers"
                  onChange={changeNumbers}
                  placeholder="町名以下を入力"
                  defaultValue={defaultNumbers}
                />
                <input
                  type="text"
                  id="building"
                  onChange={changeBuilding}
                  placeholder="建物・マンション名を入力"
                  defaultValue={defaultBuilding}
                />
              </div>
            </label>
          </div>
          <div>
            <p>電話番号</p>
            <p>{formatedPhoneNumber}</p>
          </div>
          <div>
            <p>所持資格</p>
            <p>{passport}</p>
          </div>
          <div>
            <label htmlFor="reward">
              <p>遺言執行報酬</p>
              <textarea
                name="reward"
                id="reward"
                onChange={changeReward}
                placeholder="テキストを入力"
                defaultValue={defaultReward}
              />
            </label>
          </div>
          <div>
            <p>活動地域</p>
            <p>{zoneInfo}</p>
          </div>
          <div className="notification">
            <p>登録名簿閲覧数の通知</p>
            <div>
              <input
                type="radio"
                id="receive"
                name="receive"
                value="受け取る"
                defaultChecked={notion === '受け取る'}
                onChange={changeNotion}
              />
              <label htmlFor="receive">受け取る{}</label>
              <input
                type="radio"
                id="notReceive"
                name="receive"
                value="受け取らない"
                defaultChecked={notion === '受け取らない'}
                onChange={changeNotion}
              />
              <label htmlFor="notReceive">受け取らない{}</label>
            </div>
          </div>
          <div>
            <label htmlFor="introduce">
              <p>紹介文</p>
              <p className="executor-introduce">
                &#8251;紹介文は120字以内で入力して下さい。
              </p>
              <textarea
                maxLength={120}
                name="introduce"
                id="introduce"
                // ref={introduceRef}
                onChange={changeIntroduce}
                placeholder="紹介文を入力して下さい。"
                defaultValue={defaultIntroduce}
              />
            </label>
          </div>
          <NormalBtn
            title="確認"
            className="normalBtn"
            href="/executor/profile/confirm"
            state={{
              sub,
              prefecture,
              city,
              numbers,
              building,
              notion,
              reward,
              introduce,
              imageUrl,
              image,
            }}
          />
        </form>
      </div>
    </div>
  );
};

export default ExecutorProfileEditContent;
