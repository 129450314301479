import { useEffect } from 'react';
import { DataStore } from 'aws-amplify';

const useSync = (): void => {
  const sync = async () => {
    // await DataStore.stop();
    await DataStore.start();
  };

  useEffect(() => {
    // const timeOutId = setInterval(() => {
    //   void sync();
    // }, 10000);

    // return () => clearInterval(timeOutId);
    void sync();
  }, []);
};

export default useSync;
