import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Auth, DataStore } from 'aws-amplify';
import { useAuth } from 'hooks';
import { logger } from 'utils/logger';
import './executorFooterMenuAfterLoginContent.css';

type Props = {
  setShow: () => void;
};

const ExecutorFooterMenuAfterLoginContent: FC<Props> = (props) => {
  const { setShow } = props;

  const { doQuery } = useAuth();
  // const navigate = useNavigate();
  const signOut = async () => {
    try {
      await Auth.signOut()
        .then(async () => {
          logger.info('ログアウト成功');
          await DataStore.clear();
        })
        .then(() => doQuery())
        .then(() => {
          // navigate('/');
          window.location.reload();
        });
    } catch (error) {
      logger.error('ログアウト失敗');
      logger.error(error);
    }
  };

  return (
    <div className="executorFooterMenuAfterLoginContent">
      <div>
        <div>
          <div>
            <Link to="/executor" onClick={() => setShow()}>
              <p>ホーム</p>
            </Link>
          </div>
          <div>
            <Link to="/executor/profile" onClick={() => setShow()}>
              <p>プロフィール</p>
            </Link>
            <Link to="/executor/email" onClick={() => setShow()}>
              <p>メールアドレスの変更</p>
            </Link>
            <Link to="/executor/password" onClick={() => setShow()}>
              <p>パスワードの変更</p>
            </Link>
          </div>
          <div>
            <Link to="/topics/list" onClick={() => setShow()}>
              <p>トピックス</p>
            </Link>
            {/* <Link to="/executor/question" onClick={() => setShow()}>
              <p>よくあるご質問</p>
            </Link> */}
            <p className="faqcontent">
              よくあるご質問（
              <Link to="/executor/willquestion" onClick={() => setShow()}>
                <p>お客様</p>
              </Link>
              /
              <Link to="/executor/executorquestion" onClick={() => setShow()}>
                <p>登録資格者</p>
              </Link>
              ）
            </p>
            <Link to="/executor/companyinfo" onClick={() => setShow()}>
              <p>法人概要</p>
            </Link>
            <Link to="/executor/contact" onClick={() => setShow()}>
              <p>お問い合わせ</p>
            </Link>
          </div>
          <div>
            <Link to="/" onClick={signOut}>
              <p>ログアウト</p>
            </Link>
          </div>
        </div>
        <div>
          <p>
            <Link to="/executor/executorpolicy" onClick={() => setShow()}>
              <p>利用規約</p>
            </Link>
          </p>
          <p>
            <Link
              to="/executor/executorprivacypolicy"
              onClick={() => setShow()}
            >
              <p>プライバシーポリシー</p>
            </Link>
          </p>
          <Link to="/executor/contact" onClick={() => setShow()}>
            <p>退出する</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ExecutorFooterMenuAfterLoginContent;
