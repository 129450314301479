import { FC } from 'react';
import './privacyPolicyContent.css';

const ExecutorPrivacyPolicyContent: FC = () => (
  <div className="privacy-policy-content">
    <div>
      <p>プライバシーポリシー</p>
      <div className="privacy-policy-sentence">
        <div>
          <p>基本方針</p>
          <p>
            一般社団法人日本遺言執行士協会（以下、「当協会」という）は、当協会がアプリ、ウェブサービスその他のサービス（以下、「サービス」という）を提供するにあたって、当協会が取得するお客様（以下、「登録資格者」という）の個人情報の取り扱いについて、以下の通りプライバシーポリシーを定めます。
            <br />
            なお、本ポリシーにおける定義は、個人情報の保護に関する法律（平成15年法律第57号）によるものとします。
          </p>
        </div>
        <div>
          <p>適用範囲</p>
          <p>
            本ポリシーは、当協会が行う各種サービスにおいて、登録資格者の個人情報もしくはそれに準ずる情報を取り扱う際に、当協会が遵守する方針を示したものです。
          </p>
        </div>
        <div>
          <p>個人情報の取得と利用目的</p>
          <p>・収集する情報</p>
          <p className="indent1">
            当協会は登録資格者から以下の情報をご提供いただいております。
          </p>
          <ul className="indent1 li-indent1">
            <li>1 住所</li>
            <li>2 氏名</li>
            <li>3 性別</li>
            <li>4 年齢</li>
            <li>5 所持資格</li>
            <li>6 活動地域</li>
            <li>7 メールアドレス</li>
            <li>8 パスワード</li>
          </ul>
          <p>・収集方法</p>
          <p className="indent1">
            当協会の提供する遺言アプリを利用される際に、資格者登録のために入力される情報を登録することにより収集します。
          </p>
          <p>・利用目的</p>
          <p className="indent1">
            当協会は、登録資格者からご提供いただく情報を以下の目的の範囲内において利用します。登録資格者の同意なく目的外の利用を行うことはありません。
          </p>
          <ul className="indent1 li-indent1">
            <li>1 本サービスの提供や機能拡張に向けた研究開発のため</li>
            <li>
              2 本サービスの利用に伴う連絡・各種お知らせ等の配信・送付のため
            </li>
            <li>
              3
              登録資格者の承諾・申込みに基づく、本サービス利用者（遺言者）への個人情報の提供のため
            </li>
            <li>
              4
              登録資格者が所持資格に基づき懲戒処分等を受け、または当協会の利用規約に違反して本サービス利用者または、当協会へ損害を与え、もしくは与える恐れがある場合に対応するため
            </li>
            <li>
              5 本サービスの改善・新規サービスの開発およびマーケティングのため
            </li>
            <li>6 キャンペーン・アンケート・モニター・取材等の実施のため</li>
            <li>
              7 本サービスに関するご意見、お問い合わせ内容の確認・回答のため
            </li>
          </ul>
        </div>
        <div>
          <p>個人情報の管理</p>
          <p>
            当協会は、登録資格者からご提供いただいた情報の管理について、以下を徹底します。
          </p>
          <ol className="indent1 li-indent2">
            <li>
              1）情報の正確性の確保
              <br />
              登録資格者からご提供いただいた情報については、常に正確かつ最新の情報となるよう努めます。
            </li>
            <li>
              2）安全管理措置
              <br />
              当協会は、組織的な個人情報の管理については、社内規定により厳重に取扱い方法を規定し、それに基づいた取扱いを徹底しています。
            </li>
            <li>
              3）従業者の監督
              <br />
              当協会は、当協会の規程に基づき、個人情報取扱い規程の厳格な運用を徹底しています。
            </li>
            <li>
              4）委託先の監督
              <br />
              個人情報の取扱いを外部に委託する場合には、当協会の規程に基づき、用件を満たした委託先にのみ委託を行い、適切な管理を行います。
            </li>
            <li>
              5）保存期間と廃棄
              <br />
              登録資格者からご提供いただいた情報については、保存期間を設定し、保存期間終了後は廃棄します。また、保存期間内であっても、不要となった場合にはすみやかに廃棄します。
            </li>
            <li>
              6）第三者提供の有無
              <br />
              当協会は、登録資格者からご提供いただいた個人情報を、第三者に提供することはありません。
              <br />
              また、今後第三者提供を行う事になった場合には、提供する情報と提供目的などを提示し、登録資格者から同意を得た場合のみ第三者提供を行います。
            </li>
            <li>
              7）個人情報の開示・訂正・利用停止等
              <br />
              個人情報の開示・訂正・利用停止等のお申し出があった場合には、当協会所定の方法に基づき対応致します。具体的な方法については、個別にご案内しますので、下記受付窓口までお問い合わせください。
            </li>
          </ol>
          <p className="indent4">
            一般社団法人日本遺言執行士協会
            <br />
            石田 泉 e-Mail ishida@igon.co.jp
            <br />
            電話 03-6264-5005
          </p>
        </div>
        <div className="privacy-policy-create-date">
          <p>
            <span>策定日</span>
            <span>2023年11月27日 策定</span>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default ExecutorPrivacyPolicyContent;
