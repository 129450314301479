import { FC } from 'react';
import NormalBtn from '../../../executor/components/atoms/normalBtn';
import './executorProcedureSendContent.css';

// type Props = {
//   href: string;
// };

const ExecutorProcedureSendContent: FC = () => (
  <div className="executorProcedureSend">
    <div>
      <p>遺言執行者登録申請フォーム</p>
      <div>
        <p>
          登録申請フォームを
          <br className="sp-only" />
          送信しました。
        </p>
        <p>
          審査まで1週間かかります。
          <br />
          審査が通りましたら、登録いただいたメールアドレス宛に連絡します。
        </p>
        <div>
          <p>igon.co.jpのドメインからメールが届きます。</p>
          <p>迷惑メールフォルダに入る設定になっていないか確認してください。</p>
        </div>
      </div>
      <NormalBtn
        title="ホームに戻る"
        className="normalBtn executorProcedureSendBtn"
        href="/"
      />
    </div>
  </div>
);

export default ExecutorProcedureSendContent;
