/* eslint-disable no-alert */
import { Dispatch, FC, SetStateAction, useState } from 'react';
import splitPrefectureAndCity from 'utils/splitPrefectureAndCity';
import AdminListingAreaFormCard from '../organisms/adminListingAreaFormCard';
import minusBtn from '../images/minusBtn.svg';
import PlusBtn from '../atoms/plusBtn';
import './adminActivityAreaForm.css';

type Props = {
  show: boolean;
  setShow: () => void;
  setNewZoneinfo: Dispatch<SetStateAction<string[]>>;
  NewZoneinfo: string[];
};

const AdminActivityAreaForm: FC<Props> = (props) => {
  const { show, setShow, NewZoneinfo, setNewZoneinfo } = props;

  const listingAreaPrefectureValueArr = NewZoneinfo.map(
    (ele) => splitPrefectureAndCity(ele).prefecture,
  );

  const listingAreaCitiesValueArr = NewZoneinfo.map(
    (ele) => splitPrefectureAndCity(ele).city,
  );

  // 最初に開くカード数の定義
  const numberOfCardsDisplayed = NewZoneinfo.length ? NewZoneinfo.length : 1;

  const initialForm = Array.from({ length: numberOfCardsDisplayed }, () =>
    Math.random(),
  );
  const [forms, setForms] = useState<number[]>(initialForm);
  const addition = () => {
    const random = Math.random();
    setForms([...forms, random]);
  };
  const remove = (index: number) => {
    const newForms = [...forms];
    newForms.splice(index, 1);
    setForms(newForms);
  };

  const cardDelete = (index: number) => {
    const cardDeleteText = window.confirm(
      `活動地域${index + 1}の選択内容を削除しますがよろしいですか？`,
    );
    if (cardDeleteText === true) {
      remove(index);
      const NewZoneinfos = NewZoneinfo.filter(
        (NewZone) => NewZone !== NewZoneinfo[index],
      );
      setNewZoneinfo(NewZoneinfos);
    }
  };

  if (show) {
    return (
      <div id="adminActivityAreaOverlay" className="adminActivityAreaForm">
        <p>活動地域の選択</p>
        <div className="adminActivityAreaFormSelect">
          <p>活動地域を選択してください。</p>
          {forms.map((form, index, theForms) => (
            <>
              <AdminListingAreaFormCard
                key={`listingArea${form}`}
                theIndex={index}
                cardDelete={() => {
                  cardDelete(index);
                }}
                minusBtnView={theForms.length !== 1}
                NewZoneinfo={NewZoneinfo}
                setNewZoneinfo={setNewZoneinfo}
                prefectureSelectedArr={listingAreaPrefectureValueArr}
                citySelectedArr={listingAreaCitiesValueArr}
                prefectureSelected={listingAreaPrefectureValueArr[index]}
                citySelected={listingAreaCitiesValueArr[index]}
              />
            </>
          ))}
          <button
            type="button"
            className="adminExecutorPlusBtn"
            onClick={addition}
          >
            <PlusBtn />
          </button>
        </div>
        <div className="adminActivityAreaFormkeep">
          <button
            type="button"
            onClick={() => {
              setTimeout(() => setShow(), 500);
            }}
          >
            保存
          </button>
          <button type="button" onClick={() => setShow()}>
            <img src={minusBtn} alt="minus-Button" />
            <p>閉じる</p>
          </button>
        </div>
      </div>
    );
  }

  return null;
};

export default AdminActivityAreaForm;
