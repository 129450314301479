/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useStayingTimeAtList } from 'hooks';
import { Executor } from 'domains/apigateway/services/get-Executor';
import ExecutorInfoCard from '../molecules/executorInfoCard';
// import ExecutorCard from '../molecules/executorCard';
import './executorSearchResultContent.css';

import SearchIcon from '../images/searchIcon.svg';

type Props = {
  changePage: () => void;
  executors?: Executor[];
  scrollTopforSearch: () => void;
};

const ExecutorSearch: FC<Props> = (props) => {
  const { changePage, executors = [], scrollTopforSearch } = props;
  useStayingTimeAtList();

  return (
    <div className="executorSearchResultContent">
      <p>条件に該当する遺言執行者</p>
      <div>
        {executors.length === 0 ? (
          <>
            <div className="executorSearchResultContentText">
              <p>条件に合う遺言執行者はいません。</p>
              <p>検索条件を変えてください。</p>
            </div>
          </>
        ) : (
          executors.map((ele) => (
            <>
              <ExecutorInfoCard
                key={`ExecutorInfoCard${ele.id}`}
                executor={ele}
              />
            </>
          ))
        )}
      </div>
      <Link
        to="/user/executorsearch"
        className="conditionsChange"
        onClick={() => {
          changePage();
          scrollTopforSearch();
        }}
      >
        <img src={SearchIcon} alt="" />
        <p>検索条件変更</p>
      </Link>
      <Link to="/user/executorchange" className="back-to-executor-change">
        選定方法を変更
      </Link>
    </div>
  );
};

ExecutorSearch.defaultProps = {
  executors: [],
};

export default ExecutorSearch;
