import { FC } from 'react';
import plusBtn from '../images/plusBtn.svg';
import './plusBtn.css';

const PlusBtn: FC = () => (
  <div className="adminexecutorPlus">
    <img src={plusBtn} alt="plus-Button" />
    <p>追加</p>
  </div>
);

export default PlusBtn;
