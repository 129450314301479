import { FC } from 'react';
import { Auth, DataStore } from 'aws-amplify';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from 'hooks';
import { logger } from 'utils/logger';
import adminNavList from '../datas/adminNavList';
import adminNavLogo from '../images/admin-nav-logo.svg';
import './adminNav.css';

const AdminNav: FC = () => {
  const location = useLocation();

  const { doQuery } = useAuth();
  const signOut = async () => {
    try {
      await Auth.signOut()
        .then(async () => {
          logger.info('ログアウト成功');
          await DataStore.clear();
        })
        .then(() => doQuery())
        .then(() => {
          window.location.reload();
        });
    } catch (error) {
      logger.error('ログアウト失敗');
      logger.error(error);
    }
  };

  return (
    <nav className="admin-nav">
      <img src={adminNavLogo} alt="遺言アプリ" />
      <div>
        <div>
          {adminNavList.map((list) => (
            <Link
              className={
                list.subLink?.includes(location.pathname) ||
                list.link === location.pathname
                  ? 'nav-selected'
                  : ''
              }
              to={list.link}
            >
              {list.text}
            </Link>
          ))}
        </div>
        <Link to="/" onClick={signOut}>
          ログアウト
        </Link>
      </div>
    </nav>
  );
};

export default AdminNav;
