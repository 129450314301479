import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { GetS3 } from 'domains/aws/services/get-s3';
import { formatDateToCleanStr } from 'utils/dateFormat';
import sampleImage from '../images/post_sample_image.png';
import './topicsListLink.css';

type Props = {
  postId: string;
  title: string;
  thumbnail: string;
  updatedAt: string | null | undefined;
};

const TopicsListLink: FC<Props> = (props) => {
  const { postId, title, thumbnail, updatedAt } = props;

  const { data: getImage } = useQuery(['thumbnail', thumbnail], () =>
    GetS3(thumbnail, 'public'),
  );

  const imageUrl = getImage || sampleImage;

  const updatedAtDate = new Date(updatedAt || '');
  const updatedAtFormated = updatedAt
    ? formatDateToCleanStr(updatedAtDate)
    : '';

  return (
    <Link to={`/topics/${postId}`} className="topics-link-user">
      <div className="topics-link-user_container">
        <div className="topics-link-user_img-wrapper">
          <img src={imageUrl} alt="" />
        </div>
        <div>
          <p>{updatedAtFormated}</p>
          <p>{title}</p>
        </div>
      </div>
    </Link>
  );
};

export default TopicsListLink;
