/* eslint-disable no-alert */
import { FC, useState } from 'react';
import './chatList.css';
import { Link, useLocation } from 'react-router-dom';
import {
  ListChatRoomsByAdmin,
  listMessage,
} from 'domains/cognite/services/manageChat';
import { logger } from 'utils/logger';
import { getUser } from 'domains/apigateway/services/get-user';
import { useQuery } from 'react-query';
import PaginationFunc from 'utils/paginationFunc';
import AdminNav from '../organisms/adminNav';
import PaginationLayout from '../organisms/paginationLayout';

type State = {
  sub: string;
};

const ExecutorChatList: FC = () => {
  const location = useLocation();
  const { sub } = location.state as State;

  const listChatRoomsAndClientsInfoAdmin = async () => {
    const chatRooms = await ListChatRoomsByAdmin(sub);
    if (!chatRooms) {
      logger.info(chatRooms);
      window.alert('chatRoomが存在しません。');
      throw Error('chatroomが未定義です');
    }
    const chatRoomsAndClientsInfo = await Promise.all(
      chatRooms.map(async (ele) => {
        const client = await getUser(ele.clientId);
        const messages = await listMessage(ele);

        return { chatRoom: ele, client, lastMessagge: messages.slice(-1)[0] };
      }),
    );

    return chatRoomsAndClientsInfo;
  };

  const { data } = useQuery(
    ['executor_chatlist', sub],
    listChatRoomsAndClientsInfoAdmin,
  );

  if (!data) {
    logger.info(data);
    throw Error('chatroomが未定義です');
  }

  const [selectedNum, setSelectedNum] = useState(0);

  const paginationData = PaginationFunc(data, selectedNum);

  return (
    <>
      <AdminNav />
      <div className="admin-executor-chat">
        <div className="admin-executor-chat-wrapper">
          <h1>遺言執行者管理</h1>
          <p>遺言執行者チャット一覧</p>
          <div className="admin-executor-chat-cards">
            {paginationData.map((ele) => (
              <Link
                className="admin-executor-chat-card"
                to="/admin/data/chatView"
                key={ele.client.id}
                state={{
                  client: ele.client,
                  assigneeId: ele.chatRoom.assigneeId,
                }}
              >
                <div>
                  <p>{ele.client.email}</p>
                  <p>
                    {ele.lastMessagge && ele.lastMessagge != null
                      ? ele.lastMessagge.updatedAt?.split('').slice(11, 16)
                      : ''}
                  </p>
                </div>
                <p>
                  {ele.lastMessagge && ele.lastMessagge != null
                    ? ele.lastMessagge.content
                    : 'まだやり取りがありません'}
                </p>
              </Link>
            ))}
          </div>
        </div>
        <PaginationLayout
          arrayLength={data.length}
          setSelectedNum={setSelectedNum}
          selectedNum={selectedNum}
        />
      </div>
    </>
  );
};

export default ExecutorChatList;
