/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-return-await */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { API, Auth } from 'aws-amplify';

type UserAttribute = {
  Name: string;
  Value: string;
};

export const createUser = async (
  username: string,
  userAttributes?: UserAttribute[],
) => {
  const apiName = 'AdminQueries';
  const path = '/createUser';

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
    body: {
      username,
      userAttributes,
    },
  };

  return await API.post(apiName, path, params);
};

export const checkPassword = async (username: string, password: string) => {
  const apiName = 'AdminQueries';
  const path = '/setUserPassword';

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
    body: {
      username,
      password,
    },
  };

  return await API.post(apiName, path, params);
};

export const confirmUserSignUp = async (username: string) => {
  const apiName = 'AdminQueries';
  const path = '/confirmUserSignUp';

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
    body: {
      username,
    },
  };

  return await API.post(apiName, path, params);
};

export const addUserToGroupExecutor = async (username: string) => {
  const apiName = 'AdminQueries';
  const path = '/addUserToGroup';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
    body: {
      username,
      groupname: 'Executor',
    },
  };

  return await API.post(apiName, path, params);
};
