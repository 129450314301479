import { FC } from 'react';
// import { useSync } from 'hooks';
import Header from '../organisms/header';
// import Footer from '../organisms/footer';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import ExecutorEditContent from '../organisms/executorEditContent';

// useSync();
const ExecutorChange: FC = () => (
  <>
    <Header />
    <ExecutorEditContent href="./top.tsx" />
    {/* <Footer /> */}
    <CommonFooter />
  </>
);

export default ExecutorChange;
