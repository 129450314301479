/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import { FC, useState, useEffect, ChangeEvent, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useMutation, useQueryClient } from 'react-query';
import { logger } from 'utils/logger';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import { updateExecutorFromAdmin } from 'domains/apigateway/services/updateExecutorFromAdmin';
import { useProfileIcon, useExecutorsListFromAdmin } from 'hooks';
import PutS3 from 'domains/aws/services/put-s3';
import { GetS3 } from 'domains/aws/services/get-s3';
import { checkPassword } from 'domains/apigateway/services/createExecutorFromAdmin';
import {
  PrefectureArray,
  Prefecture as PrefectureArrayType,
} from 'admin/components/data/prefecture';
import AdminActivityAreaForm from '../templates/adminActivityAreaForm';
import AdminNav from '../organisms/adminNav';
import defaultProfileIcon from '../images/defaultProfileIcon.svg';
import plofileEditCamera from '../images/plofile-edit-camera.svg';
import './executorEdit.css';

type State = {
  Email: string;
  Name: string;
  Prefecture: string;
  City: string;
  AddressNum: string;
  Building: string;
  Birthdate: string;
  Passport: string;
  PhoneNumber: string;
  Reward: string;
  Zoneinfo: string;
  Notion: string;
  Introduce: string;
  sub: string;
};

type cityProps = {
  prefCode: string;
  cityCode: string;
  cityName: string;
  bigCityFlag: string;
};

const ExecutorEdit: FC = () => {
  const location = useLocation();
  const {
    Email,
    Name,
    Prefecture,
    City,
    AddressNum,
    Building,
    Birthdate,
    Passport,
    PhoneNumber,
    Reward,
    Zoneinfo,
    Notion,
    Introduce,
    sub,
  } = location.state as State;

  // ここは使うAPIが違う気がする
  const executorDatas = useExecutorsListFromAdmin(['executorEdit_admin']);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { imageUrl, changeImageUrl } = useProfileIcon(defaultProfileIcon, sub);

  const uploadIcon = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const profile = event.target.files[0];
      await PutS3(`profile_${sub}`, profile, 'public').then(async () => {
        const profileUrl = await GetS3(`profile_${sub}`, 'public');
        changeImageUrl(profileUrl);
      });
    }
  };

  const passportRef1 = useRef<HTMLInputElement>(null);
  const passportRef2 = useRef<HTMLInputElement>(null);
  const passportRef3 = useRef<HTMLInputElement>(null);
  const passportRef4 = useRef<HTMLInputElement>(null);
  const passportRef5 = useRef<HTMLInputElement>(null);
  const passportRef6 = useRef<HTMLInputElement>(null);
  const NotionRef1 = useRef<HTMLInputElement>(null);
  const NotionRef2 = useRef<HTMLInputElement>(null);

  const DateBirthdate = Birthdate.split('/').join('-');

  const phoneNumerFormatted = formatPhoneNumber(PhoneNumber);

  const [newEmail, setNewEmail] = useState(Email);
  const [newPassword, setNewPassword] = useState('');
  const [newName, setNewName] = useState(Name);
  const [newPrefecture, setNewPrefecture] = useState(Prefecture);
  const [newCity, setNewCity] = useState(City);
  const [newAddressNum, setNewAddressNum] = useState(AddressNum);
  const [newBuilding, setNewBuilding] = useState(Building);
  const [newBirthdate, setNewBirthdate] = useState(DateBirthdate);
  const [newPassport, setNewPassport] = useState(Passport);
  const [newPhoneNumber, setNewPhoneNumber] = useState(phoneNumerFormatted);
  const [newReward, setNewReward] = useState(Reward);
  const [newNotion, setNewNotion] = useState(Notion);
  const [newIntroduce, setNewIntroduce] = useState(Introduce);

  const [cityValue, setCityValue] = useState<cityProps[]>([]);

  const phoneNumber81 = `+81${newPhoneNumber.slice(1)}`;

  const queryParamPassport = () => {
    const shikkoushi = passportRef1.current?.checked ? '遺言執行士,' : '';
    const bengoshi = passportRef2.current?.checked ? '弁護士,' : '';
    const shihoushoshi = passportRef3.current?.checked ? '司法書士,' : '';
    const gyouseishoshi = passportRef4.current?.checked ? '行政書士,' : '';
    const zeirishi = passportRef5.current?.checked ? '税理士,' : '';
    const other = passportRef6.current?.checked ? '宅地建物取引士' : '';
    setNewPassport(
      shikkoushi + bengoshi + shihoushoshi + gyouseishoshi + zeirishi + other,
    );
  };

  const queryParamNotion = () => {
    const receive = NotionRef1.current
      ? NotionRef1.current?.checked
        ? '受け取る'
        : '受け取らない'
      : '';
    setNewNotion(receive);
  };

  const executorDataAnswering = executorDatas.filter(
    (executorData) => executorData.sub === sub,
  );

  const passportArr = executorDataAnswering.map((ele) => {
    const passportData =
      ele['custom:passport'] === undefined || ele['custom:passport'] === null
        ? ''
        : ele['custom:passport'].split(',');

    return passportData;
  });

  const passportCheck = (passport: string) => {
    const passportBoolean = !!(
      passportArr[0] && passportArr[0].includes(passport)
    );

    return passportBoolean;
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>): void => {
    setNewName(e.currentTarget.value);
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>): void => {
    setNewEmail(e.currentTarget.value);
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>): void => {
    setNewPassword(e.currentTarget.value);
  };

  const handleChangePrefecture = (e: ChangeEvent<HTMLSelectElement>): void => {
    setNewPrefecture(e.currentTarget.value);
  };
  const handleChangeCity = (e: ChangeEvent<HTMLSelectElement>): void => {
    setNewCity(e.currentTarget.value);
  };
  const handleChangeAddressNum = (e: ChangeEvent<HTMLInputElement>): void => {
    setNewAddressNum(e.currentTarget.value);
  };
  const handleChangeBuilding = (e: ChangeEvent<HTMLInputElement>): void => {
    setNewBuilding(e.currentTarget.value);
  };

  const handleChangeBirthdate = (e: ChangeEvent<HTMLInputElement>): void => {
    setNewBirthdate(e.currentTarget.value);
  };

  const handleChangePhoneNumber = (e: ChangeEvent<HTMLInputElement>): void => {
    setNewPhoneNumber(e.currentTarget.value);
  };

  const handleChangeReward = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    setNewReward(e.currentTarget.value);
  };

  const handleChangeIntroduce = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    setNewIntroduce(e.currentTarget.value);
  };

  const [show, setShow] = useState(false);
  const [NewZoneinfo, setNewZoneinfo] = useState<string[]>(Zoneinfo.split(','));
  const setShowFunc = () => {
    setShow(false);
  };

  const NewZoneinfoStr = NewZoneinfo.join(',');

  const [passwordChangeBtn, setPasswordChangeBtn] = useState(true);

  const passwordChangeSwitch = () => {
    setPasswordChangeBtn(!passwordChangeBtn);
  };

  const getCity = async (prefecture: string) => {
    const selectArr = PrefectureArray.find((ele) => ele.label === prefecture);
    const selectId = selectArr ? selectArr.id : 1000;

    await fetch(
      `https://opendata.resas-portal.go.jp/api/v1/cities?prefCode=${selectId}`,
      {
        headers: { 'X-API-KEY': 'yvet69MOZSiyzRoinuvNJaWjcXaqhoP118HTqciA' },
      },
    )
      .then((response) => response.json())
      .then((citiesData) => {
        setCityValue(citiesData.result);
      });
  };

  useEffect(() => {
    const selectLabel = Prefecture;

    const selectArr = PrefectureArray.find((ele) => ele.label === selectLabel);
    const selectId = selectArr ? selectArr.id : 1000;

    void fetch(
      `https://opendata.resas-portal.go.jp/api/v1/cities?prefCode=${selectId}`,
      {
        headers: { 'X-API-KEY': 'yvet69MOZSiyzRoinuvNJaWjcXaqhoP118HTqciA' },
      },
    )
      .then((response) => response.json())
      .then((citiesData) => {
        setCityValue(citiesData.result);
      });
  }, [Prefecture]);

  const changePrefectureAndGetCity = async (
    e: ChangeEvent<HTMLSelectElement>,
  ) => {
    handleChangePrefecture(e);
    await getCity(e.currentTarget.value);
  };

  const saveData = async () => {
    const emailFormat =
      /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

    if (
      newName &&
      newEmail &&
      newPrefecture &&
      newCity &&
      newAddressNum &&
      newBuilding &&
      newBirthdate &&
      newPassport &&
      NewZoneinfoStr &&
      phoneNumber81
    ) {
      if (!emailFormat.test(newEmail)) {
        window.alert('正しい形式のメールアドレスを入力して下さい');
      } else if (!passwordChangeBtn && newPassword.length < 8) {
        window.alert('パスワードは8文字以上で入力して下さい');
      } else if (newPhoneNumber.length < 10) {
        window.alert('電話番号は10文字以上で入力して下さい');
      } else if (!newName.includes(' ')) {
        window.alert('姓名の間に半角スペースを一つ開けてください');
      } else if (newName.startsWith(' ')) {
        window.alert('姓名の先頭の文字列に空白を入れないでください');
      } else {
        const editDateConfilm =
          window.confirm('内容を更新します。よろしいですか？');
        if (editDateConfilm === true) {
          try {
            const editAttributes = [
              { Name: 'name', Value: newName },
              { Name: 'email', Value: newEmail },
              { Name: 'birthdate', Value: newBirthdate },
              { Name: 'custom:passport', Value: newPassport },
              { Name: 'zoneinfo', Value: NewZoneinfoStr },
              {
                Name: 'phone_number',
                Value: phoneNumber81,
              },
              { Name: 'custom:address_prefecture', Value: newPrefecture },
              { Name: 'custom:address_city', Value: newCity },
              { Name: 'custom:address_numbers', Value: newAddressNum },
              { Name: 'custom:address_building', Value: newBuilding },
              { Name: 'custom:reward', Value: newReward || '' },
              { Name: 'custom:notion', Value: newNotion || '' },
              {
                Name: 'custom:introduce',
                Value: newIntroduce || '',
              },
            ];
            logger.info('更新完了');
            await updateExecutorFromAdmin(newEmail, editAttributes);
            newPassword
              ? await checkPassword(executorDataAnswering[0].sub, newPassword)
              : '';
            navigate('/admin/executor/list');
          } catch (error) {
            logger.error('更新失敗');
          }
        }
      }
    } else {
      window.alert('未記入のものがあります');
    }
  };

  const { mutate } = useMutation(saveData, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['get_executorListFromGetData']);
    },
  });

  return (
    <>
      <AdminNav />
      <div className="adminExecutorEdit">
        <div className="ExecutorEdit">
          <h1>遺言執行者管理</h1>
          <p>遺言執行者編集</p>
          <form>
            <div>
              <div className="adminPlofileImgRelative">
                <label htmlFor="adminImgEdit" className="adminPlofileImg">
                  <img src={imageUrl} alt="" />
                  <img src={plofileEditCamera} alt="" />
                </label>
              </div>
              <div className="adminUploadIcon">
                <input type="file" id="adminImgEdit" onChange={uploadIcon} />
                <label htmlFor="adminImgEdit">写真を変更</label>
              </div>
            </div>
            <div className="ExecutorEditForm">
              <div>
                <p>メールアドレス</p>
                <label htmlFor="Mail">
                  <input
                    type="email"
                    id="Mail"
                    value={newEmail}
                    placeholder="メールアドレス"
                    onChange={handleChangeEmail}
                  />
                </label>
              </div>
              <div>
                <p>パスワード</p>
                <label htmlFor="Password">
                  <input
                    className={`${
                      passwordChangeBtn ? 'password-not-editable' : ''
                    }`}
                    type="text"
                    id="Password"
                    placeholder={`${
                      passwordChangeBtn ? '' : '新しいパスワード'
                    }`}
                    onChange={handleChangePassword}
                    disabled={passwordChangeBtn}
                  />
                </label>
                <button
                  type="button"
                  onClick={passwordChangeSwitch}
                  className="password-change-btn"
                >
                  変更する
                </button>
              </div>
              <div>
                <p>名前</p>
                <label htmlFor="Name">
                  <input
                    type="text"
                    id="Name"
                    placeholder="名前"
                    value={newName}
                    onChange={handleChangeName}
                  />
                </label>
                <p className="name-notice">
                  姓と名の間に半角スペースを入れてください
                </p>
              </div>
              <div>
                <p>住所</p>
                <label htmlFor="Address">
                  <select
                    name="prefecture"
                    id="Address"
                    onChange={changePrefectureAndGetCity}
                    defaultValue={Prefecture}
                  >
                    <option value="" selected disabled hidden>
                      都道府県を選択
                    </option>
                    {PrefectureArray.map((ele: PrefectureArrayType) => (
                      <option value={ele.label} key={ele.label}>
                        {ele.label}
                      </option>
                    ))}
                  </select>
                  <select
                    id="city"
                    name="city"
                    onChange={handleChangeCity}
                    defaultValue={City}
                  >
                    <option>市町村を選択</option>
                    {cityValue.map((ele) => (
                      <option
                        value={ele.cityName}
                        key={ele.cityName}
                        selected={City === ele.cityName}
                      >
                        {ele.cityName}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    id="Address"
                    placeholder="町名以下を入力"
                    value={newAddressNum}
                    onChange={handleChangeAddressNum}
                  />
                  <input
                    type="text"
                    id="Address"
                    placeholder="建物・マンション名を入力"
                    value={newBuilding}
                    onChange={handleChangeBuilding}
                  />
                </label>
              </div>
              <div>
                <p>生年月日</p>
                <label htmlFor="calender">
                  <input
                    type="date"
                    id="calender"
                    value={newBirthdate}
                    onChange={handleChangeBirthdate}
                  />
                </label>
              </div>
              <div className="ExecutorEditLicense">
                <p>所持資格</p>
                <div>
                  <div>
                    <input
                      type="checkbox"
                      id="check1"
                      name="license"
                      value="遺言執行士"
                      ref={passportRef1}
                      onChange={queryParamPassport}
                      defaultChecked={passportCheck('遺言執行士')}
                    />
                    <label htmlFor="check1">遺言執行士{}</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="check2"
                      name="license"
                      value="弁護士"
                      ref={passportRef2}
                      onChange={queryParamPassport}
                      defaultChecked={passportCheck('弁護士')}
                    />
                    <label htmlFor="check2">弁護士{}</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="check3"
                      name="license"
                      value="司法書士"
                      ref={passportRef3}
                      onChange={queryParamPassport}
                      defaultChecked={passportCheck('司法書士')}
                    />
                    <label htmlFor="check3">司法書士{}</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="check4"
                      name="license"
                      value="行政書士"
                      ref={passportRef4}
                      onChange={queryParamPassport}
                      defaultChecked={passportCheck('行政書士')}
                    />
                    <label htmlFor="check4">行政書士{}</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="check5"
                      name="license"
                      value="税理士"
                      ref={passportRef5}
                      onChange={queryParamPassport}
                      defaultChecked={passportCheck('税理士')}
                    />
                    <label htmlFor="check5">税理士{}</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="check6"
                      name="license"
                      value="宅地建物取引士"
                      ref={passportRef6}
                      onChange={queryParamPassport}
                      defaultChecked={passportCheck('宅地建物取引士')}
                    />
                    <label htmlFor="check6">宅地建物取引士{}</label>
                  </div>
                </div>
              </div>
              <div>
                <p>電話番号</p>
                <label htmlFor="phone">
                  <input
                    type="text"
                    id="phone"
                    placeholder="電話番号"
                    value={newPhoneNumber}
                    onChange={handleChangePhoneNumber}
                  />
                </label>
              </div>
              <div className="ExecutorEditReward">
                <p>遺言執行報酬</p>
                <label htmlFor="Reward">
                  <textarea
                    id="Reward"
                    value={newReward}
                    onChange={handleChangeReward}
                  />
                </label>
              </div>
              <div className="ExecutorEditPublish">
                <p>名簿掲載地の編集</p>
                <div>
                  {NewZoneinfo && NewZoneinfo.length !== 0 && NewZoneinfo[0] ? (
                    NewZoneinfo.map((ele) => <p>{ele}</p>)
                  ) : (
                    <p>選択なし</p>
                  )}
                  <button type="button" onClick={() => setShow(true)}>
                    名簿掲載地の編集
                  </button>
                  <AdminActivityAreaForm
                    show={show}
                    setShow={setShowFunc}
                    NewZoneinfo={NewZoneinfo}
                    setNewZoneinfo={setNewZoneinfo}
                  />
                </div>
              </div>
              <div className="ExecutorEditNotice">
                <p>登録名簿閲覧数の通知</p>
                <div>
                  <div>
                    <input
                      type="radio"
                      id="notice1"
                      name="notice"
                      value="受け取る"
                      ref={NotionRef1}
                      onChange={queryParamNotion}
                      defaultChecked={Notion?.includes('受け取る')}
                    />
                    <label htmlFor="notice1">受け取る{}</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="notice2"
                      name="notice"
                      value="受け取らない"
                      ref={NotionRef2}
                      onChange={queryParamNotion}
                      defaultChecked={Notion?.includes('受け取らない')}
                    />
                    <label htmlFor="notice2">受け取らない{}</label>
                  </div>
                </div>
              </div>
              <div className="ExecutorEditIntroduce">
                <p>紹介文</p>
                <label htmlFor="Introduce">
                  <textarea
                    id="Introduce"
                    value={newIntroduce}
                    onChange={handleChangeIntroduce}
                  />
                </label>
              </div>
            </div>
            <button type="button" onClick={() => mutate()}>
              完了
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ExecutorEdit;
