import { FC, ReactElement } from 'react';
import { Navigate } from 'react-router';
import { useAuth } from 'hooks';
import { Group } from 'domains/cognite/services/manageUser';

type Props = {
  children: ReactElement;
  theGroup: Group;
};

const SignInGuard: FC<Props> = (props) => {
  const { children, theGroup } = props;
  const { group } = useAuth();

  if (group === theGroup) {
    return children;
  }

  return <Navigate to="/" replace />;
};

export default SignInGuard;
