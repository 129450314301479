import { FC } from 'react';
import { Line } from 'rc-progress';
import DescriptionBackImg from '../images/descriptionBackImg.svg';
import PcDescriptionBackImg from '../images/PcdescriptionBackImg.svg';
import './homeDescription.css';

type Props = {
  percent: string;
  statusColor: string[];
  message: JSX.Element;
};

const HomeDescription: FC<Props> = (props) => {
  const { percent, statusColor, message } = props;

  return (
    <div className="HomeDescription">
      <div>
        <div>
          <p>
            進捗率<span>{percent}</span>%
          </p>
          <div className="sp-only">
            <Line
              percent={[9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 10]}
              strokeWidth={10}
              strokeColor={statusColor}
              strokeLinecap="butt"
            />
          </div>
          <div className="pc-only">
            <Line
              strokeWidth={5}
              percent={[9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 10]}
              strokeColor={statusColor}
              strokeLinecap="butt"
            />
          </div>
        </div>
        <div className="homeDescriptionText">{message}</div>
      </div>
      <img src={DescriptionBackImg} alt="sp背景画像" className="sp-only" />
      <img src={PcDescriptionBackImg} alt="Pc背景画像" className="pc-only" />
    </div>
  );
};

export default HomeDescription;
