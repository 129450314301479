import { FC } from 'react';
import CommonFooter from '../../apply/templates/commonFooter';
import UserLogoHeader from '../organisms/userLogoHeader';
import WomanBaw from '../images/deleteThanks_woman-baw.svg';
import './deleteThanks.css';
import NavigateBtn from '../../../user/components/atoms/navigateBtn';

const DeleteThanks: FC = () => (
  <>
    <UserLogoHeader />
    <div className="delete-thanks">
      <p>退出 完了</p>
      <p>
        退出手続きを受け付けました
        <br />
        ご利用ありがとうございました
      </p>
      <img src={WomanBaw} alt="女性のイラスト" />
      <NavigateBtn />
    </div>
    <CommonFooter />
  </>
);

export default DeleteThanks;
