const AdminNavList = [
  {
    text: '遺言執行者管理',
    link: '/admin/executor/list',
    subLink: [
      '/admin/executor/detail',
      '/admin/executor/edit',
      '/admin/data/chatList',
      '/admin/data/chatView',
    ],
  },
  {
    text: '遺言執行者のデータ分析',
    link: '/admin/data/executor/timeOnPage',
    subLink: ['/admin/data/executor/pageView', '/admin/data/executor/search'],
  },
  {
    text: '遺言執行者新規登録',
    link: '/admin/executor/create',
  },
  {
    text: '遺言者管理',
    link: '/admin/user/list',
    subLink: [
      '/admin/user/detail',
      '/admin/data/userChatView',
      '/admin/user/newsMessage',
    ],
  },
  {
    text: '遺言者のデータ分析',
    link: '/admin/user/newUserData',
    subLink: [
      '/admin/user/userData',
      '/admin/user/addressNewUserData',
      '/admin/user/addressUserData',
      '/admin/user/timeOnPage',
    ],
  },
  {
    text: 'トピックス',
    link: '/admin/topics/list',
    subLink: [
      '/admin/topics/detail',
      '/admin/topics/create',
      '/admin/topics/create/confirm',
      '/admin/topics/edit',
      '/admin/topics/edit/confirm',
    ],
  },
];

export default AdminNavList;
