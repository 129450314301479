/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useState } from 'react';
import './topTutorial.css';
import minusBtn from '../images/minusBtn.svg';
import TopTutorialDatas from '../data/topTutorialData';

const TopTutorial: FC = () => {
  const [tutorialNum, setTutorialNum] = useState(0);
  const [tutorialModal, setTutorialModal] = useState(true);
  const [labelBoolean, setLabelBoolean] = useState(false);
  const tutorialModalClose = () => {
    setTutorialModal(false);
  };

  const labelChecked = () => {
    setLabelBoolean(!labelBoolean);
  };

  const localStrageSet = () => {
    localStorage.setItem('isLabelChecked?', 'true');
  };

  {
    labelBoolean === true && tutorialModal === false ? localStrageSet() : '';
  }

  const localStorageGet = localStorage.getItem('isLabelChecked?');

  return (
    <>
      {localStorageGet ? (
        ''
      ) : (
        <>
          {tutorialModal === true ? (
            <div className="top-tutorial">
              <>
                <div className="tutorial-overlay" />
                <div className="tutorial-card">
                  <div
                    onClick={() => {
                      if (tutorialNum <= 6) {
                        setTutorialNum(tutorialNum + 1);
                      }
                    }}
                  />
                  <div className="tutorial-card-img">
                    <div />
                    <img
                      className="sp-only"
                      src={TopTutorialDatas[tutorialNum].imgLink}
                      alt="チュートリアル"
                    />
                    <img
                      className="pc-only"
                      src={TopTutorialDatas[tutorialNum].imgLinkPc}
                      alt="チュートリアル"
                    />
                    <p>
                      <span>{tutorialNum + 1}</span> / {TopTutorialDatas.length}
                    </p>
                  </div>
                  <div className="tutorial-card-description">
                    <div className="tutorial-card-description-container">
                      {TopTutorialDatas[tutorialNum].descriptionSp ? (
                        <>
                          <p className="sp-only">
                            {TopTutorialDatas[tutorialNum].descriptionSp}
                          </p>
                          <p className="pc-only">
                            {TopTutorialDatas[tutorialNum].description}
                          </p>
                        </>
                      ) : (
                        TopTutorialDatas[tutorialNum].description.map((ele) => (
                          <p>{ele}</p>
                        ))
                      )}
                    </div>
                    <div className="top-tutorial-btns">
                      <button
                        className={
                          tutorialNum === 0 ? 'tutorial-btn-hidden' : ''
                        }
                        onClick={() => setTutorialNum(tutorialNum - 1)}
                        type="button"
                      >
                        前へ
                      </button>
                      <button
                        className={
                          tutorialNum + 1 === TopTutorialDatas.length
                            ? 'tutorial-btn-hidden'
                            : ''
                        }
                        onClick={() => setTutorialNum(tutorialNum + 1)}
                        type="button"
                      >
                        次へ
                      </button>
                    </div>
                    <label htmlFor="nextHidden">
                      <input
                        onClick={labelChecked}
                        id="nextHidden"
                        type="checkbox"
                      />
                      <p>次回から表示しない</p>
                    </label>
                    <button
                      className="tutorial-modal-close-btn"
                      onClick={tutorialModalClose}
                      type="button"
                    >
                      <img src={minusBtn} alt="閉じるボタン" />
                      <p>閉じる</p>
                    </button>
                  </div>
                </div>
              </>
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </>
  );
};

export default TopTutorial;
