/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
import { FC, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { MutateTopic } from 'domains/cognite/services/manageTopics';
import PutS3 from 'domains/aws/services/put-s3';
import TopicsActionSubmits from '../molcules/topicsActionSubmits';
import './topicCreateOrEditContent.css';

type Props = {
  confirmPath: string;
};

type TopicFormData = {
  title: string;
  content: string;
  isDraft: boolean;
  file: File | null;
};

type LocationState =
  | {
      id: string;
      thumbnail: string;
      file: File;
      title: string;
      content: string;
      isDraft: boolean;
    }
  | undefined;

const TopicCreateOrEditContent: FC<Props> = (props) => {
  const { confirmPath } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location.state as LocationState;
  const postId = locationState ? locationState.id : '';
  const initialFile = locationState ? locationState.file : null;

  const { register, handleSubmit, setValue, watch } = useForm<TopicFormData>({
    defaultValues: {
      title: locationState ? locationState.title : '',
      content: locationState ? locationState.content : '',
      isDraft: true,
      file: initialFile,
    },
  });

  const watchFile = watch('file');

  const toConfirm: SubmitHandler<TopicFormData> = async (data) => {
    const uuid = uuidv4();
    const thumbnail = locationState
      ? locationState.thumbnail
      : `thumbnail_${uuid}`;
    if (!data.title || !data.content || !data.file) {
      window.alert('未入力の項目があります');
    } else if (!data.isDraft) {
      const state = {
        id: postId,
        ...data,
        thumbnail,
      };
      navigate(confirmPath, { state });
    } else {
      const { title, content, isDraft, file } = data;
      const instance = {
        title,
        content,
        isDraft,
        thumbnail,
      };
      await MutateTopic(postId, instance);
      if (file) {
        await PutS3(thumbnail, file, 'public');
      }
      window.alert('下書き保存しました');
      navigate('/admin/topics/list');
    }
  };

  const changeDraft = (isDraft: boolean) => {
    setValue('isDraft', isDraft);
  };

  const onDrop = useCallback((files: File[]) => {
    if (files.length > 0) {
      setValue('file', files[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filePreview = useMemo(() => {
    if (!watchFile) {
      return <></>;
    }

    const url = URL.createObjectURL(watchFile);

    return <img src={url} alt="" className="file-preview" />;
  }, [watchFile]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png', '.jpg', '.jpeg'],
    },
  });

  const dropAreaActiveColor = isDragActive ? 'drop-area_active-color' : '';

  return (
    <form onSubmit={handleSubmit(toConfirm)} className="topics-form">
      <label htmlFor="title">
        タイトル
        <input
          type="text"
          id="title"
          {...register('title')}
          placeholder="タイトルを入力してください。"
        />
      </label>
      <label htmlFor="content">
        本文
        <textarea
          id="content"
          {...register('content')}
          placeholder="本文を入力してください。"
        />
      </label>
      <label htmlFor="thumbnail">
        サムネイル画像
        <div {...getRootProps()} className={`drop-area ${dropAreaActiveColor}`}>
          <input {...getInputProps} />
          <p>ファイルを選択またはドラッグアンドドロップ</p>
        </div>
      </label>
      {filePreview}
      <TopicsActionSubmits
        firstText="確認する"
        firstFunc={() => changeDraft(false)}
        secondText="下書き保存"
        secondFunc={() => changeDraft(true)}
      />
    </form>
  );
};

export default TopicCreateOrEditContent;
