import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { GetS3, ListS3 } from 'domains/aws/services/get-s3';
import { listTestamentFiles } from 'domains/cognite/services/manageTestamentFile';

type UseQueryPdf = {
  data:
    | {
        exist: boolean;
        url: string;
        // alreadyUploaded: boolean;
      }
    | undefined;
  error: unknown;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  fileCheck:
    | {
        alreadyUploaded: boolean;
        exist: boolean;
      }
    | undefined;
  isLoadingCheck: boolean;
};

const useQueryPdf = (userId: string): UseQueryPdf => {
  const [fetch, setFetch] = useState(false);
  const checkExistPdf = async () => {
    const fileName = `遺言書_${userId}`;
    const filesInfo = await listTestamentFiles(fileName);
    const alreadyUploaded = filesInfo.length > 0;
    const existWord = await ListS3(`遺言書_${userId}.docx`);
    const existPdf = await ListS3(`遺言書_${userId}.pdf`);

    const exist = existWord !== 0 && existPdf !== 0;

    return {
      alreadyUploaded,
      exist,
    };
  };

  const getPdf = async () => {
    // const fileName = `遺言書_${userId}`;
    // const filesInfo = await listTestamentFiles(fileName);
    // const alreadyUploaded = filesInfo.length > 0;
    const existWord = await ListS3(`遺言書_${userId}.docx`);
    const existPdf = await ListS3(`遺言書_${userId}.pdf`);

    const exist = existWord !== 0 && existPdf !== 0;

    const url = await GetS3(`遺言書_${userId}.pdf`, 'public');

    return {
      // existWord,
      // existPdf,
      exist,
      url,
      // alreadyUploaded,
    };
  };

  const { data: fileCheck, isLoading: isLoadingCheck } = useQuery(
    ['user_check_exist_pdf', userId],
    checkExistPdf,
    {
      cacheTime: 500,
      refetchInterval: 4000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    if (
      fileCheck &&
      !isLoadingCheck &&
      fileCheck.exist &&
      fileCheck.alreadyUploaded
    ) {
      setFetch(true);
    }
  }, [fileCheck, isLoadingCheck]);

  // console.log('fetch', fetch);

  const { data, error, isLoading, isFetching, isError } = useQuery(
    ['user_download_pdf', userId],
    getPdf,
    {
      // refetchInterval: 5000,
      // refetchOnMount: 'always',
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 6,
      enabled: fetch,
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    isError,
    fileCheck,
    isLoadingCheck,
  };
};

export default useQueryPdf;
