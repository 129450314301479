import { FC } from 'react';
import { useQuery } from 'react-query';
import { useQueryPropertiesQuick, useQueryAllocation } from 'hooks';
import { QueryAllocationProperties } from 'domains/cognite/services/manageAllocationProperties';
import { GetUser } from 'domains/cognite/services/manageUser';
// import DeleteS3 from 'domains/aws/services/delete-s3';
import { logger } from 'utils/logger';
import Others from '../organisms/others';
import UserLogoHeader from '../organisms/userLogoHeader';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import HomeDescription from '../organisms/homeDescription';
import YourInfo from '../organisms/yourInfo';
import PropertyInfo from '../organisms/propertyInfo';
import HeirInfo from '../organisms/heirInfo';
import AllocationInfo from '../organisms/allocationInfo';
import AllDeleteInfo from '../organisms/allDeleteInfo';
import StatusColorData from '../data/statusColorData';
import './home.css';
import TopTutorial from '../organisms/topTutorial';

const Home: FC = () => {
  // useSync();
  const { data: user } = useQuery(['authenticated_user'], GetUser);
  if (!user) {
    logger.error(user);
    throw Error;
  }
  // チェックフラグの数数える
  // 遺言者情報

  // 不動産、動産、預貯金、株式・債券、生命保険、債権、負債
  const { data: propertyData } = useQueryPropertiesQuick(
    'propertiesData',
    user,
  );

  const willInfoCount = propertyData && !!propertyData.willInfo ? 1 : 0;

  let propertyCount;
  if (propertyData) {
    const realEstateData = !!propertyData.realEstate;
    const movingPropertyData = !!propertyData.movingProperty;
    const depositsData = !!propertyData.deposits;
    const stockData = !!propertyData.stock;
    const lifeInsuranceData = !!propertyData.lifeInsurance;
    const bondsData = !!propertyData.bonds;
    const liabilitiesData = !!propertyData.liabilities;
    const param = [
      realEstateData,
      movingPropertyData,
      depositsData,
      stockData,
      lifeInsuranceData,
      bondsData,
      liabilitiesData,
    ];
    const arr = param.filter((ele) => ele === true).length;

    propertyCount = arr;
  } else {
    propertyCount = 0;
  }

  // 相続人情報
  const { data: allocationData } = useQueryAllocation('allocationData', user);
  let allocationCount;
  if (allocationData) {
    const legalHeirData = !!allocationData.inputLegalHeirFlag;
    const recipientData = !!allocationData.inputRecipientFlag;
    const param = [legalHeirData, recipientData];
    const arr = param.filter((ele) => ele === true).length;

    allocationCount = arr;
  } else {
    allocationCount = 0;
  }

  // 財産割り当て情報
  const { data: allocationPropertiesData } = useQuery(
    ['allocationPropertiesData'],
    QueryAllocationProperties,
  );
  const allocationPropertiesCount =
    allocationPropertiesData && allocationPropertiesData.length !== 0 ? 1 : 0;

  const allCount =
    willInfoCount + propertyCount + allocationCount + allocationPropertiesCount;

  // const deleteFile = async () => {
  //   await DeleteS3(`遺言書_${user.attributes.sub}.docx`);
  //   await DeleteS3(`遺言書_${user.attributes.sub}.pdf`);
  // };

  return (
    <>
      <UserLogoHeader />
      <TopTutorial />
      <HomeDescription
        percent={StatusColorData[allCount].percent}
        statusColor={StatusColorData[allCount].color}
        message={StatusColorData[allCount].message}
      />
      <div className="homeYourInfo">
        <YourInfo />
      </div>
      <PropertyInfo />
      <HeirInfo />
      <AllocationInfo />
      <Others linkName="" />
      <AllDeleteInfo />
      {/* <Footer /> */}
      <CommonFooter />
    </>
  );
};

export default Home;
