/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { ChangeEvent, FC, useState } from 'react';
import { useUsersListFromAdmin } from 'hooks';
import { getFormatDate1, getFormatDate2 } from 'utils/dateFormat';
import { Pie } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { PrefectureArray } from 'user/components/data/prefecture';
import AdminNav from '../organisms/adminNav';
import DataBtns from '../organisms/dataBtns';
import './addressNewUserData.css';

Chart.register(...registerables);

const AddressNewUserData: FC = () => {
  const UserDatas = useUsersListFromAdmin(['addressNewUserData_admin']);

  // today取得（2022-01-01の型）
  const date = new Date();
  const dateYear = date.getFullYear();
  const dateMonth = `0${date.getMonth() + 1}`.slice(-2);
  const dateDay = `0${date.getDate()}`.slice(-2);
  const dateToday = `${dateYear}-${dateMonth}-${dateDay}`;

  const [inputDateString1, setInputDateString1] = useState(dateToday);
  const [inputDateString2, setInputDateString2] = useState(dateToday);

  const [selectAge, setSelectAge] = useState('全年代');

  const getValue1 = (data: ChangeEvent<HTMLInputElement>) => {
    setInputDateString1(data.currentTarget.value);
  };

  const getValue2 = (data: ChangeEvent<HTMLInputElement>) => {
    setInputDateString2(data.currentTarget.value);
  };

  const getSelectValue = (data: ChangeEvent<HTMLSelectElement>) => {
    setSelectAge(data.currentTarget.value);
  };

  const inputDate1 = getFormatDate1(inputDateString1);
  const inputDate2 = getFormatDate2(inputDateString2);

  // リファクタリング
  const rangeData = UserDatas?.filter((ele) => {
    const date2 = new Date(ele.createdAt!);
    const usersAge = ele['custom:ages'];

    if (inputDate1 <= date2 && date2 < inputDate2 && selectAge === '全年代') {
      return ele.createdAt;
    }

    if (inputDate1 <= date2 && date2 < inputDate2 && usersAge === selectAge) {
      return ele.createdAt;
    }
  });

  const age = [
    '10代',
    '20代',
    '30代',
    '40代',
    '50代',
    '60代',
    '70代',
    '80代',
    '90代',
  ];

  const prefecture = PrefectureArray.map((ele) => ele.label);

  const prefectureBaseData: string[] = [];

  const getPrefectureDatas = () =>
    rangeData?.map((x) => {
      PrefectureArray.findIndex((y) => {
        if (x.address === y.label) {
          prefectureBaseData.push(y.label);
        }
      });
    });

  getPrefectureDatas();

  const prefectureFilterData = PrefectureArray.map((y) => {
    const prefectureFilter = prefectureBaseData.filter(
      (x) => x === y.label,
    ).length;

    return prefectureFilter;
  });

  const prefectureWithoutZeroNumber: number[] = [];

  const zeroFilter = () =>
    prefectureFilterData.filter((ele, index) => {
      if (ele !== 0) {
        prefectureWithoutZeroNumber.push(index);
      }
    });

  zeroFilter();

  const prefectureWithoutZero = prefectureWithoutZeroNumber.map(
    (ele) => prefecture[ele],
  );

  const prefectureDataWithoutZero = prefectureWithoutZeroNumber.map(
    (ele) => prefectureFilterData[ele],
  );

  // chart
  // =============================================
  const chartData = {
    // x 軸のラベル
    labels: prefectureWithoutZero,
    datasets: [
      {
        label: '地域別新規登録者',
        // データの値
        data: prefectureDataWithoutZero,
        // グラフの背景色
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(179, 34, 255, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(201, 203, 207, 0.2)',
          'rgba(34, 255, 153, 0.2)',
        ],
        // グラフの枠線の色
        borderColor: [
          'rgba(255, 99, 132)',
          'rgba(255, 159, 64)',
          'rgba(179, 34, 255)',
          'rgba(255, 205, 86)',
          'rgba(75, 192, 192)',
          'rgba(54, 162, 235)',
          'rgba(153, 102, 255)',
          'rgba(201, 203, 207)',
          'rgba(34, 255, 153)',
        ],
        // グラフの枠線の太さ
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <AdminNav />
      <div className="address-new-user-data">
        <div className="address-new-user-data-wrapper">
          <h1>遺言者のデータ分析</h1>
          <p>地域別新規登録者数</p>
          <div className="address-new-user-data-chart-btns">
            <div className="address-new-user-data-chart">
              <div>
                <input
                  value={inputDateString1}
                  onChange={(e) => {
                    getValue1(e);
                  }}
                  type="date"
                />
                <input
                  value={inputDateString2}
                  onChange={(e) => {
                    getValue2(e);
                  }}
                  type="date"
                />
              </div>
              <select
                onChange={(e) => {
                  getSelectValue(e);
                }}
              >
                <option value="全年代">全年代</option>
                {age.map((ele) => (
                  <option value={ele}>{ele}</option>
                ))}
              </select>
              <Pie data={chartData} />
            </div>
            <DataBtns />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressNewUserData;
