/* eslint-disable import/prefer-default-export */
export const ExecutorQuestionData = [
  {
    questionNumber: 1,
    question:
      '遺言執行には、資格等は必要ないとのことですが、他の法律（弁護士法、税理士法など）には抵触しないのですか？',
    answer:
      '遺言書作成について形式的な要件を満たすようサポートを行い、遺言執行者に選定され、遺言書の内容に沿って遺言執行を行うことについては、他の法律に触れることはありません。但し、全ての段階において、「法律判断や税務判断」を行うことは他の法律に抵触する可能性がありますから、十分に注意するとともに、必要に応じてそれぞれの専門家と提携し、相談しながら進めるよう心がけていただく必要があります。身近に専門家がいない場合は、協会の登録遺言執行者の中から専門家をご紹介いたします。',
  },
  {
    questionNumber: 2,
    question:
      '遺言アプリの遺言執行者名簿に登録するには、一定の資格所持者であることの他に「業務を行うのに必要な要件を整えていること」が条件とされているのはなぜですか？',
    answer:
      '「一定の資格所持」を要件とするのは、登録申請者が遺言執行に必要な最低限の知識を備えているかの判定に代えて所持資格で判断し、「業務を行うのに必要な要件を整えているか」とは、遺言執行者がその活動を通して依頼者の信頼を損なうことのないよう、事務処理や個人情報保管のスペースを確保できているかなどを総合的に判断するものです。',
  },
  {
    questionNumber: 3,
    question:
      '遺言アプリの遺言執行者名簿に登録された、遺言執行者数が少ないように感じますが、これだけの人数で遺言アプリを利用する遺言者の要請にこたえられますか？',
    answer:
      '現状の登録者数では足りないと考えています。当面は、登録遺言執行者で受けきれない依頼者に対しては、当協会が対応する予定です。',
  },
  {
    questionNumber: 4,
    question:
      '遺言アプリは無料で利用できますが、登録遺言執行者が、遺言アプリを経由しない一般の顧客のために、遺言アプリを使用して遺言書作成のサポートをした場合、協会に対して利用料等の支払いをする必要がありますか？',
    answer:
      '支払の必要はありません。遺言アプリの使用方法は自由です。但し、当協会は遺言アプリの著作権を放棄していませんので、遺言アプリ自体の販売や賃貸をすることは禁止致します。',
  },
];
