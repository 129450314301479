/* eslint-disable no-param-reassign */
import { DataStore } from 'aws-amplify';
import { Stock, StockMetaData } from 'models';
import { logger } from 'utils/logger';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ModelInit } from '@aws-amplify/datastore';

const CreateStock = async (
  instance: ModelInit<Stock, StockMetaData>,
): Promise<Stock> => {
  try {
    logger.info('株式・債券の作成を開始します');
    const data = await DataStore.save(new Stock(instance));
    logger.info('株式・債券の作成が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export default CreateStock;

export const queryStock = async (): Promise<Stock[]> => {
  try {
    const queryStockData = await DataStore.query(Stock);

    const queryStockResult = !queryStockData ? [] : queryStockData;

    return queryStockResult;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const MutateStock = async (
  id: string,
  instance: ModelInit<Stock, StockMetaData>,
): Promise<Stock> => {
  try {
    logger.info('株式・債券の作成または更新を開始します');
    const original = await DataStore.query(Stock, id);

    if (original) {
      logger.info('株式・債券の更新を開始します');
      const data = await DataStore.save(
        Stock.copyOf(original, (updated) => {
          // ワンチャンここがバグ
          updated.content = instance.content;
          updated.majorNum = instance.majorNum;
          updated.noCheck = instance.noCheck;
        }),
      );

      return data;
    }
    logger.info('株式・債券の作成を開始します');
    const data = await DataStore.save(new Stock(instance));

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const MutateStockOnlyMajorNum = async (
  id: string,
  instance: ModelInit<Stock, StockMetaData>,
): Promise<Stock> => {
  try {
    logger.info('株式・債券の作成または更新を開始します');
    const original = await DataStore.query(Stock, id);

    if (original) {
      logger.info('株式・債券の更新を開始します');
      const data = await DataStore.save(
        Stock.copyOf(original, (updated) => {
          updated.majorNum = instance.majorNum;
        }),
      );

      return data;
    }
    logger.info('株式・債券の作成を開始します');
    const data = await DataStore.save(new Stock(instance));

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetStock = async (): Promise<Stock[]> => {
  try {
    logger.info('株式・債券の全項目のフェッチを開始します');
    const data = await DataStore.query(Stock);
    logger.debug(data);
    logger.info('株式・債券の全項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteStock = async (): Promise<Stock[]> => {
  try {
    logger.info('株式・債券の削除を開始します');
    const data = await DataStore.query(Stock);
    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);

          return data;
        }),
      );
    }
    logger.debug(data);
    logger.info('株式・債券の削除を開始します');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
