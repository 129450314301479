/* eslint-disable import/prefer-default-export */
import { API, Auth } from 'aws-amplify';

type Attribute = {
  Name: string;
  Value: string;
};

export const deleteExecutorFromAdmin = async (
  username: string,
  userAttributes?: Attribute[],
): Promise<void> => {
  const apiName = 'AdminQueries';
  const path = '/deleteUser';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
    body: {
      username,
      userAttributes,
    },
  };

  await API.post(apiName, path, params);
};

export const disableExecutorFromAdmin = async (
  username: string,
): Promise<void> => {
  const apiName = 'AdminQueries';
  const path = '/disableUser';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
    body: {
      username,
    },
  };

  await API.post(apiName, path, params);
};
