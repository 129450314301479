/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Auth, API } from 'aws-amplify';
import { UserAttributes } from 'domains/cognite/user';

type UserDatas = {
  Users: UserAttributes[];
  token: string;
};

// eslint-disable-next-line import/prefer-default-export
export const getUserFromAdmin = async (
  nextToken?: string,
): Promise<UserDatas> => {
  const apiName = 'AdminQueries';
  const path = '/listUsersInGroup';
  const params = {
    queryStringParameters: {
      groupname: 'User',
      limit: 60,
      token: nextToken,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };

  const admin = await API.get(apiName, path, params);

  const Users = admin.Users.map(
    (UserData: {
      Attributes: { Name: string; Value: string }[];
      UserCreateDate: string;
    }) => {
      const UserDataContent = UserData.Attributes.reduce(
        (
          prev: { [x: string]: string },
          current: { Name: string; Value: string },
        ) => {
          prev[current.Name] = current.Value;

          return prev;
        },
        {},
      );

      return {
        ...UserDataContent,
        createdAt: UserData.UserCreateDate,
      };
    },
  ) as UserAttributes[];

  const { NextToken } = admin;

  return {
    Users,
    token: NextToken,
  };
};
