/* eslint-disable no-alert */
import { FC, Suspense, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AssignStatus } from 'models';
import { useRelationshipWithAssignee, useWatchAssignStatus } from 'hooks';
import { GetUser } from 'domains/cognite/services/manageUser';
import { GetEvaluation } from 'domains/cognite/services/manageEvaluation';
import { logger } from 'utils/logger';
import ExecutorFormCardApproved from '../molecules/executorFormCardApproved';
import NormalBtn from '../atoms/normalBtn';

import './executorAwaitingApprovalContent.css';

const ExecutorAwaitingApprovalContent: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const path = useWatchAssignStatus('executor_awaiting_approval_content');
  const { data: user } = useQuery(['authenticated_user'], GetUser);
  if (!user) {
    logger.error(user);
    throw Error;
  }

  const { data } = useRelationshipWithAssignee(
    'assignee_REGISTERD',
    AssignStatus.REGISTERD,
  );

  const { data: evaluation } = useQuery(
    ['executor_awaiting_approval_content_evaluation'],
    GetEvaluation,
  );

  useEffect(() => {
    if (location.pathname !== path) {
      window.alert('データの更新があります');
      navigate(path);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const evaluationByAssigneeId = evaluation
    ? evaluation.filter(
        (ele) => data && ele.assigneeId === data.relationship.assigneeId,
      )
    : [];

  const evaluationValue = evaluationByAssigneeId.map((ele1) => {
    const evaluationValueSum =
      (ele1.response !== undefined && ele1.response !== null
        ? ele1.response
        : 0) +
      (ele1.explanation !== undefined && ele1.explanation !== null
        ? ele1.explanation
        : 0) +
      (ele1.answer !== undefined && ele1.answer !== null ? ele1.answer : 0) +
      (ele1.claritOfyPrice !== undefined && ele1.claritOfyPrice !== null
        ? ele1.claritOfyPrice
        : 0) +
      (ele1.validityOfPrice !== undefined && ele1.validityOfPrice !== null
        ? ele1.validityOfPrice
        : 0);

    return evaluationValueSum;
  });

  const evaluationValueTotal = evaluationValue.reduce(
    (sum, element) => sum + element,
    0,
  );

  const evaluationValueTotalAve =
    evaluationValueTotal / evaluationByAssigneeId.length / 5;

  // 評価の星に渡す数字（整数）
  const evaluationValueAveStar = Math.round(evaluationValueTotalAve);

  // 表示用の数字（小数第一位）
  const evaluationValueAveDisplay =
    Math.round(evaluationValueTotalAve * 10) / 10;

  const ApplicationDate =
    data && data.relationship && data.relationship.createdAt
      ? new Date(data.relationship.createdAt)
      : new Date();
  const year = ApplicationDate.getFullYear();
  const month = ApplicationDate.getMonth() + 1;
  const date = ApplicationDate.getDate();
  const formatedDate = `${year}年${month}月${date}日`;

  return (
    <div className="executorAwaitingApprovalContent">
      <div>
        {!data ? (
          <></>
        ) : (
          <>
            <Suspense fallback={<div>...読み込み中</div>}>
              <ExecutorFormCardApproved
                executorId={data.relationship.assigneeId}
                currentStatus={AssignStatus.REGISTERD}
                evaluationValueAveStar={evaluationValueAveStar || 0}
                evaluationValueAveDisplay={evaluationValueAveDisplay || 0}
                evaluationValueCount={evaluationByAssigneeId.length || 0}
              />
            </Suspense>
            <p>申請日:{formatedDate}</p>
            <div>
              <p>遺言執行者からは5営業日以内に返信があります。</p>
              <NormalBtn
                title="申請取り消し"
                className="normalBtn executorAwaitingApprovalContentBtn"
                href="/user/executorchange"
                state={{
                  assignStatus: AssignStatus.REGISTERD,
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ExecutorAwaitingApprovalContent;
