import { useQuery } from 'react-query';
import { getExecutorFromAdmin } from 'domains/apigateway/services/get-ExecutorFromAdmin';
import { UserAttributes } from 'domains/cognite/user';

const useExecutorsListFromAdmin = (keys: string[]): UserAttributes[] => {
  const getExecutors = async () => {
    let value = await getExecutorFromAdmin();
    let results = value.executorDatas;

    while (value.executorDatas.length >= 60 && value.token) {
      // eslint-disable-next-line no-await-in-loop
      const newValue = await getExecutorFromAdmin(value.token);
      value = newValue;
      results = results.concat(newValue.executorDatas);
    }

    return results;
  };

  const { data: executors } = useQuery(keys, getExecutors);
  const zeroExecutors = [] as UserAttributes[];

  return executors || zeroExecutors;
};

export default useExecutorsListFromAdmin;
