/* eslint-disable react/require-default-props */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { State } from 'history';
import { logger } from 'utils/logger';
import './normalBtn.css';
import './normalBtnResponsive.css';

type Props = {
  title: string;
  className: string;
  href: string;
  func?: () => void;
  clickName?: string;
  test?: string;
  state?: State;
};

const NormalBtn: FC<Props> = (props) => {
  const {
    title,
    className,
    href,
    func,
    clickName = '',
    test,
    state = {},
  } = props;

  return (
    <Link
      to={href}
      className={`${className} ${clickName}`}
      onClick={func}
      id={test}
      state={state}
    >
      <p className="btnTitle">{title}</p>
    </Link>
  );
};

NormalBtn.defaultProps = {
  func: () => {
    logger.info('ボタンを押しました');
  },
};

export default NormalBtn;
