import { FC, Suspense } from 'react';
import { Link } from 'react-router-dom';
// import Footer from '../user/components/organisms/footer';
import LogoutFooterToWill from './components/organisms/logoutFooterToWill';
import './top.css';
import topTriangles from '../user/components/images/top-triangles.svg';
import pcTopTriangles from '../user/components/images/pc-top-triangles.svg';
// import scrollMouse from '../user/components/images/top-first-view-mouse.svg';
import scrollArrow from '../user/components/images/top-first-view-arrow.svg';
import logo from '../user/components/images/headerLogo.svg';
import smartPhone from '../user/components/images/top-app-explain-smart-phone.svg';
import PointCard from '../user/components/atoms/pointCard';
import topData from '../user/components/data/topData';
// import StartLink from '../user/components/atoms/startLink';
import people from '../user/components/images/app-explain-people.svg';
import appView from '../user/components/images/app-explain-app-view.png';
// import topLogo from '../user/components/images/topLogo.svg';
import topLoginMan from '../user/components/images/topLoginMan.svg';
import TopicsContainer from './components/organisms/topicsContainer';

const Top: FC = () => (
  <>
    <main className="loginTop">
      <section className="topAppExplain">
        <img src={topTriangles} className="sp-only topTriangles" alt="" />
        <img src={pcTopTriangles} className="pc-only topTriangles" alt="" />
        <h2>
          <img src={logo} alt="遺言アプリ" />
        </h2>
        <div className="loginBtnArea">
          <Link to="/willSignIn" className="willLoginTopBtn">
            ユーザーログイン
          </Link>
          <Link to="/executorSignIn" className="execuorLoginTopBtn">
            遺言執行者ログイン
          </Link>
        </div>
        <div className="loginAppContainer">
          <img
            src={smartPhone}
            className="loginSmartPhone"
            alt="スマートフォン"
          />
          <p>
            このアプリの質問に答えて
            <br className="pc-only" />
            いくだけで遺言書の下書きが
            <br className="pc-only" />
            簡単にできます。
          </p>
        </div>
        <div className="loginTopRegister">
          <div>
            <Link to="/signUp">新規登録</Link>
          </div>
        </div>
        <div className="LoginPointCard">
          {topData.map((data) => (
            <PointCard point={data.point} text={data.text} key={data.point} />
          ))}
        </div>
        <p>
          仮名、架空住所で
          <br className="sp-only" />
          お試しいただけます。
        </p>
        <p>
          もちろん途中でやめて保存しておくことも、全てのデータを消すこともできますから、安心してお試しいただけます。
        </p>
        {/* <StartLink /> */}
        <div className="loginTopLink loginLinkMiddle">
          <Link to="/signUp">新規登録</Link>
        </div>
        <div className="loginTopPeople">
          <img src={people} alt="人々" />
          <p>
            現在（令和5年5月）の法律では、ワードファイルなどのデジタルデータでの遺言は認められていません。
            <br />
            一方で、少子化の影響もあって子供のいない夫婦や単身者の相続では、
            <span>相続関係が複雑で手続きが難航</span>する例が増えています。
            <br />
            遺言アプリは、この問題を解決するために開発され無料で一般に提供されています。
          </p>
          <div />
        </div>
        <img src={appView} className="loginAppView" alt="アプリ" />
      </section>
      <div className="loginTopLink loginLinkBottom">
        <Link to="/signUp">新規登録</Link>
      </div>
      <section className="topLoginWillExecutor">
        <div>
          <p>遺言執行者とは？</p>
          <img src={topLoginMan} alt="" />
          <p>
            アプリを利用して作成した遺言書は、そのままでは法律上無効です。遺言書には最低限守らなければならないルールがありますから、遺言が形式的なルールに適合しているか専門家のチェックを受けることを、お勧めします。
            <br />
            <br />
            更に、その遺言書に記載されたとおりに執行してもらうために、遺言書の中で遺言執行者を指定しておく必要があります。
            <br />
            遺言執行は未成年者・成年被後見人以外、誰でもできますが、誰がやっても同じ結果になるとは限りません。遺言執行者が、迅速・正確に遺言執行を行わないために争いに発展するようでは、遺言書を残した意味がありませんね。
          </p>
        </div>
        <div>
          <p>遺言執行者の選定</p>
          <p>
            このアプリには、遺言執行の経験がある各種資格者の名簿を搭載しています。遺言執行者の選定は、遺言を残すことと同じくらい重要な事項の一つです。
            <br />
            名簿の中から、遺言執行者を選んで「相談希望」の申し込みをすることができます。遺言執行者との相談は無料で5往復までチャットで行います。
            <br />
            遺言執行者の回答に納得できなかったり、方針が合わなければ自由に退出できます。
          </p>
        </div>
      </section>
      <Suspense fallback={<div>...読み込み中</div>}>
        <TopicsContainer />
      </Suspense>
      <div className="loginTopLink loginQuestionBtn">
        <Link to="/question">よくある質問</Link>
        <Link to="/apply">
          遺言執行者名簿登録申込み
          <img src={scrollArrow} alt="" className="sp-only" />
        </Link>
      </div>
    </main>
    <LogoutFooterToWill />
  </>
);

export default Top;
