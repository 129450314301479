/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import Linkify from 'linkify-react';
import './topicsViewArea.css';

type Props = {
  updatedAt: string;
  title: string;
  imageUrl: string;
  content: string;
};

const TopicsViewArea: FC<Props> = (props) => {
  const { updatedAt, title, imageUrl, content } = props;

  return (
    <div className="topics-user-view_container">
      <p className="topics-user-view_createdAt">{updatedAt}</p>
      <h2 className="topics-user-view_title">{title}</h2>
      <img src={imageUrl} alt="" className="topics-user-view_image" />
      <p className="topics-user-view_content">
        <Linkify
          options={{
            target: 'target_blank',
            formatHref: (href) =>
              href.includes('）') ? href.replace(/）/g, '') : href,
            render: ({ attributes, content: _content }) => {
              if (_content.includes('）')) {
                return (
                  <>
                    <a {...attributes}>{_content.replace(/）/g, '')}</a>）
                  </>
                );
              }

              return <a {...attributes}>{_content}</a>;
            },
          }}
        >
          {content}
        </Linkify>
      </p>
    </div>
  );
};

export default TopicsViewArea;
