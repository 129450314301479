/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
// import { useSync } from 'hooks';
import WillHeader from '../organisms/willHeader';
import ExectorAllocationContent from '../organisms/executorAllocationContent';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import ExecutorAllocationDescription from '../templates/executorAllocationDescription';
import ExecutorPcDescription from '../organisms/executorPcDescription';
import pageData from '../data/pageData';

const ExectorAllocation: FC = () => {
  // useSync();
  const descriptionPc = <>{pageData[24].description}</>;

  return (
    <>
      <WillHeader title="遺言執行者の選定" />
      <div className="sp-only">
        <ExecutorAllocationDescription />
      </div>
      <ExectorAllocationContent />
      <CommonFooter />
      <ExecutorPcDescription description={descriptionPc} />
    </>
  );
};

export default ExectorAllocation;
