/* eslint-disable no-alert */
import { FC, useEffect } from 'react';
// import { Navigate } from 'react-router';
import { useNavigate, useLocation } from 'react-router-dom';
import { useWatchAssignStatus } from 'hooks';
import NormalBtn from '../atoms/normalBtn';

import './executorAllocationContent.css';

const ExecutorAwaitingApprovalContent: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const path = useWatchAssignStatus('executor_allocation_content');

  useEffect(() => {
    if (location.pathname !== path) {
      window.alert('データの更新があります');
      navigate(path);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  return (
    <div className="executorAllocation">
      <div>
        <div>
          <NormalBtn
            title="遺言執行者名簿から選定する"
            className="selectionBtn"
            href="/user/executorsearch"
          />
          <p>
            遺言執行者名簿から選定した場合、チャットで5往復まで無料で相談ができます。
          </p>
        </div>
        <div>
          <NormalBtn
            title="遺言執行者名簿以外から選定する"
            className="selectionBtn"
            href="/user/executorregister"
          />
          <p>
            遺言執行者名後以外から選定した場合、チャット機能は使用できません。
          </p>
        </div>
      </div>
    </div>
  );
};

export default ExecutorAwaitingApprovalContent;
