const circledNumbersArr = [
  '0',
  '①',
  '②',
  '③',
  '④',
  '⑤',
  '⑥',
  '⑦',
  '⑧',
  '⑨',
  '⑩',
  '⑪',
  '⑫',
  '⑬',
  '⑭',
  '⑮',
  '⑯',
  '⑰',
  '⑱',
  '⑲',
  '⑳',
];

type CircledNumbers = (num: number | null | undefined) => string | null;

const circledNumbers: CircledNumbers = (num: number | null | undefined) =>
  num ? circledNumbersArr[num] : null;

export default circledNumbers;
