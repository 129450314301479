import { Paragraph, TextRun } from 'docx';
import { Bonds, BondsContent } from 'models';
import nonNullable from 'utils/nonNullable';
import { formatDateToJapaneseStr } from 'utils/dateFormat';
import circledNumbers from 'user/components/data/circledNumbers';

const bondsView = (
  bonds: Bonds | null,
  bondsContent: BondsContent[],
): Paragraph[] => {
  if (bonds && bondsContent && !!bondsContent.length) {
    const bondsContentParagraph = bondsContent
      .filter(nonNullable)
      .map((ele) => {
        if (ele.type === '貸付金') {
          return [
            new Paragraph({
              text: `${circledNumbers(ele.subNum) || 0} 貸付金`,
              style: 'subItem',
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `債務者名    ${ele.loanerName || ''}`,
                }),
                new TextRun({
                  text: `当初貸付金    ${ele.initialLoanAmount || ''}円`,
                  break: 1,
                }),
                new TextRun({
                  text: `貸付年月日    ${
                    ele.loanDate ? formatDateToJapaneseStr(ele.loanDate) : ''
                  }`,
                  break: 1,
                }),
                new TextRun({
                  text: `貸付金残高    ${ele.loanAmount || ''}円`,
                  break: 1,
                }),
                ...(ele.loanCertificate
                  ? [
                      new TextRun({
                        text: `保管場所    ${ele.loanStorageLocation || ''}`,
                        break: 1,
                      }),
                    ]
                  : []),
              ],
              style: 'GrandChild',
              // TODO 改行
            }),
          ];
        }
        if (ele.type === '出資金') {
          return [
            new Paragraph({
              text: `${circledNumbers(ele.subNum) || 0} 出資金`,
              style: 'subItem',
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `出資金先名    ${ele.investmenterName || ''}`,
                }),
                new TextRun({
                  text: `出資口数・金額    ${
                    ele.investmentUnitNumber || ''
                  }口 ${ele.investmentAmount || ''}円`,
                  break: 1,
                }),
                new TextRun({
                  text: `出資年月日    ${
                    ele.investmentDate
                      ? formatDateToJapaneseStr(ele.investmentDate)
                      : ''
                  }`,
                  break: 1,
                }),
                new TextRun({
                  text: `償還期限    ${
                    ele.redemptionDeadline
                      ? formatDateToJapaneseStr(ele.redemptionDeadline)
                      : ''
                  }`,
                  break: 1,
                }),
                ...(ele.investmentCertificate
                  ? [
                      new TextRun({
                        text: `保管場所    ${
                          ele.investmentStorageLocation || ''
                        }`,
                        break: 1,
                      }),
                    ]
                  : []),
              ],
              style: 'GrandChild',
              // TODO 改行
            }),
          ];
        }

        return [];
      })
      .flat();

    return [
      new Paragraph({
        text: `${bonds.majorNum || 0}. 債権`,
        style: 'Default',
      }),
      ...bondsContentParagraph,
      new Paragraph({ style: 'Default' }),
    ];
  }

  return [];
};

export default bondsView;
