/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/require-default-props */
import { FC, useState } from 'react';
import './pageDescriptionPc.css';
import ExecutorPcDescriptionBack from '../images/ExecutorPcDescriptionBack.svg';
import DescriptionArrow from '../images/DescriptionArrow.svg';
import './executorPcDescription.css';

type Props = {
  pageTitle?: string;
  description: JSX.Element;
};

const ExecutorPcDescription: FC<Props> = (props) => {
  const { description, pageTitle } = props;

  const [DescriptionBtn, setDescriptionBtn] = useState(true);

  const DesSwitching = () => {
    setDescriptionBtn(!DescriptionBtn);
  };

  const classSwitchIn = DescriptionBtn
    ? 'exeClassSwitchIn'
    : 'exeClassSwitchOut';
  const classSwitchOut = DescriptionBtn
    ? 'exeClassSwitchOut'
    : 'exeClassSwitchIn';

  return (
    <>
      <div
        className={`${classSwitchIn} ${pageTitle} executorPcDescription pc-only`}
      >
        <div>
          <p>{description}</p>
          <img src={ExecutorPcDescriptionBack} alt="pcDescription背景画像" />
        </div>
        <button type="button" onClick={DesSwitching}>
          <img src={DescriptionArrow} alt="pcDescription矢印" />
        </button>
      </div>
      <div className="pc-only">
        <button
          type="button"
          className={`${classSwitchOut} exeDesAdviceBtn `}
          onClick={DesSwitching}
        >
          <p>
            アドバイス
            <br />
            を表示する
          </p>
          <img src={DescriptionArrow} alt="pcDescription矢印" />
        </button>
      </div>
    </>
  );
};

export default ExecutorPcDescription;
