import { Paragraph } from 'docx';
import { MovingProperty } from 'models';
import circledNumbers from 'user/components/data/circledNumbers';

type MovingPropertyView = (
  movingProperty: MovingProperty | null,
) => Paragraph[];

const movingPropertyView: MovingPropertyView = (movingProperty) => {
  const movingPropertyContent = () => {
    if (!movingProperty) {
      return [];
    }

    const checks = [
      movingProperty.check1,
      movingProperty.check2,
      movingProperty.check3,
      movingProperty.check4,
      movingProperty.check5,
    ];

    const texts = [
      '非上場会社の株式',
      '貴金属',
      'ペット',
      '暗号資産',
      'その他動産',
    ];

    return texts
      .filter((_, index) => checks[index])
      .map(
        (ele, index) =>
          new Paragraph({
            text: `${circledNumbers(index + 1) || 0} ${ele}`,
            style: 'subItem',
          }),
      );
  };

  return movingProperty &&
    (movingProperty.check1 ||
      movingProperty.check2 ||
      movingProperty.check3 ||
      movingProperty.check4 ||
      movingProperty.check5) &&
    movingProperty.majorNum
    ? [
        new Paragraph({
          text: `${movingProperty.majorNum}. 動産`,
          style: 'Default',
        }),
        ...movingPropertyContent(),
        new Paragraph({ style: 'Default' }),
      ]
    : [];
};

export default movingPropertyView;
