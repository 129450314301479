/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import { FC } from 'react';
import { useAuth } from 'hooks';
import WillFooter from '../../../user/components/organisms/willfooter';
import ExecutorFooter from '../../../executor/components/organisms/executorFooter';
import FooterBeforeLogin from '../organisms/footerBeforeLogin';

type Props = {
  onClickFunc?: (link: string) => Promise<void> | void;
};

const CommonFooter: FC<Props> = (props) => {
  const { group } = useAuth();
  const { onClickFunc } = props;

  return (
    <>
      {group === 'User' ? (
        <WillFooter onClickFunc={onClickFunc} />
      ) : group === 'Executor' ? (
        <ExecutorFooter />
      ) : (
        <FooterBeforeLogin homeLink="/" />
      )}
    </>
  );
};

export default CommonFooter;
