import { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AssignStatus } from 'models';
import { useAssigneeInfo } from 'hooks';
import { GetUser } from 'domains/cognite/services/manageUser';
import { UpdateAssignStatus } from 'domains/cognite/services/manageAssignRelationship';
import {
  DeleteAssignExecutorNotOnTheListById,
  QueryAssignExecutorNotOnTheList,
} from 'domains/cognite/services/manageAssignExecutorNotOnTheList';
import {
  sendEmailForAssign,
  SendEmailForAssignReqBody,
} from 'domains/apigateway/services/sendEmail';
import { logger } from 'utils/logger';
import NormalBtn from '../atoms/normalBtn';
import './executorChangeContent.css';

type State = {
  assignStatus: AssignStatus;
} | null;

const ExecutorChangeContent: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { data: user } = useQuery(['authenticated_user'], GetUser);
  if (!user) {
    logger.error(user);
    throw Error;
  }

  const state = location.state as State;

  const assignStatus = state ? state.assignStatus : AssignStatus.APPROVAL;

  const { data } = useAssigneeInfo('assigneeChange_APPROVAL', assignStatus);

  const getQuery = async () => {
    const getQueryContent = await QueryAssignExecutorNotOnTheList();
    const assignExecutorNotOnTheListData = getQueryContent[0];

    return assignExecutorNotOnTheListData;
  };

  const { data: getQueryAssignExecutorData, isFetching: fetchQuery } = useQuery(
    'assignExecutorNotOnTheListData',
    () => getQuery(),
  );

  const decline = async () => {
    if (data) {
      const action =
        assignStatus === AssignStatus.REGISTERD ? 'cancel' : 'change';
      await UpdateAssignStatus(data.relationship.id, AssignStatus.DECLINE);
      const body: SendEmailForAssignReqBody = {
        fromEmail: user.attributes.email,
        toEmail: data.assignee.email,
        action,
      };
      await sendEmailForAssign(body);
    } else {
      logger.error('執行者が見つかりません');
    }
  };

  const deleteContentNotOnTheList = async () => {
    await DeleteAssignExecutorNotOnTheListById(
      getQueryAssignExecutorData ? getQueryAssignExecutorData?.id : '',
    );
  };

  const declineAll = async (path: string) => {
    await decline();
    await deleteContentNotOnTheList();
    // await DataStore.start();
    navigate(path);
  };

  return fetchQuery ? (
    <div>
      <p>データ取得中</p>
    </div>
  ) : (
    <div className="executorChangeContent">
      <div>
        <div>
          <NormalBtn
            title="戻る"
            className="selectionBtn"
            href="/user/executorcharge"
          />
        </div>
        <div>
          <p>
            変更する場合は
            <br className="sp-only" />
            下記ボタンより変更してください。
            <br />
            (下記ボタンを押した時点で現在の担当執行者は担当ではなくなります。)
          </p>
          <button
            type="button"
            className="selectionBtn"
            onClick={async () => {
              await declineAll('/user/executorsearch');
            }}
          >
            遺言執行者名簿から選定
          </button>
          <button
            type="button"
            className="selectionBtn"
            onClick={async () => {
              await declineAll('/user/executorregister');
            }}
          >
            遺言執行者名簿以外から選定
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExecutorChangeContent;
