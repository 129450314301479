import { FC } from 'react';
// import { Link } from 'react-router-dom';
import chatLogo from '../images/chatLogo.svg';
import './chatLink.css';

// const ChatLink: FC = () => (
//   <Link to="/">
//     <div className="chat">
//       <img src={chatLogo} alt="chat-logo" />
//       <p>チャット</p>
//     </div>
//   </Link>
// );

const ChatLink: FC = () => (
  <div className="chat">
    <img src={chatLogo} alt="chat-logo" />
    <p>チャット</p>
  </div>
);

export default ChatLink;
