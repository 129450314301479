/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { FC, useState, Dispatch, SetStateAction } from 'react';
import splitPrefectureAndCity from 'utils/splitPrefectureAndCity';
import minusBtn from '../images/minusBtn.svg';
import PlusBtn from '../atoms/plusBtn';
import './activityAreaForm.css';
import ListingAreaFormCard from '../organisms/listingAreaFormCard';

type Props = {
  show: boolean;
  setShow: () => void;
  newListingArea: string[];
  setNewListingArea: Dispatch<SetStateAction<string[]>>;
};

const ActivityAreaForm: FC<Props> = (props) => {
  const { show, setShow, newListingArea, setNewListingArea } = props;

  const listingAreaPrefectureValueArr = newListingArea.map(
    (ele) => splitPrefectureAndCity(ele).prefecture,
  );

  const listingAreaCitiesValueArr = newListingArea.map(
    (ele) => splitPrefectureAndCity(ele).city,
  );

  // 最初に開くカード数の定義
  const numberOfCardsDisplayed = newListingArea.length
    ? newListingArea.length
    : 1;

  const initialForm = Array.from({ length: numberOfCardsDisplayed }, () =>
    Math.random(),
  );
  const [forms, setForms] = useState<number[]>(initialForm);
  const addition = () => {
    const random = Math.random();
    setForms([...forms, random]);
  };
  const remove = (index: number) => {
    const newForms = [...forms];
    newForms.splice(index, 1);
    setForms(newForms);
  };

  const cardDelete = (index: number) => {
    remove(index);
    const newListingAreaAfterDelete = newListingArea.filter(
      (NewZone) => NewZone !== newListingArea[index],
    );
    setNewListingArea(newListingAreaAfterDelete);
  };

  if (show) {
    return (
      <div id="activityAreaOverlay" className="activityAreaForm">
        <p>活動地域の選択</p>
        <div className="activityAreaFormSelect">
          <p>活動地域を選択してください。</p>
          {forms.map((form, index, theForms) => (
            <>
              <ListingAreaFormCard
                key={`listingArea${form}`}
                theIndex={index}
                cardDelete={() => {
                  cardDelete(index);
                }}
                minusBtnView={theForms.length !== 1}
                newListingArea={newListingArea}
                setNewListingArea={setNewListingArea}
                prefectureSelectedArr={listingAreaPrefectureValueArr}
                citySelectedArr={listingAreaCitiesValueArr}
                prefectureSelected={listingAreaPrefectureValueArr[index]}
                citySelected={listingAreaCitiesValueArr[index]}
              />
            </>
          ))}
          <button type="button" className="executorPlusBtn" onClick={addition}>
            <PlusBtn />
          </button>
        </div>
        <div className="activityAreaFormkeep">
          <button
            type="button"
            onClick={() => {
              setTimeout(() => setShow(), 500);
            }}
          >
            保存
          </button>
          <button type="button" onClick={() => setShow()}>
            <img src={minusBtn} alt="minus-Button" />
            <p>閉じる</p>
          </button>
        </div>
      </div>
    );
  }

  return null;
};

export default ActivityAreaForm;
