import { Paragraph, TextRun } from 'docx';
import { WillInfo } from 'models';

type WillInfoView = (willInfo: WillInfo | null) => Paragraph[];

const willinfoView: WillInfoView = (willinfo) => {
  if (!willinfo) {
    return [];
  }

  return [
    new Paragraph({
      children: [
        new TextRun({
          text: `住所 ${willinfo.address || ''}`,
        }),
        new TextRun({
          text: `遺言者 ${willinfo.name || ''} 印`,
          break: 2,
        }),
      ],
      style: 'GrandGrandChild',
    }),
  ];
};

export default willinfoView;
