// eslint-disable-next-line import/no-extraneous-dependencies
import { StorageAccessLevel, S3ProviderListOutput } from '@aws-amplify/storage';
import { Storage } from 'aws-amplify';
import { logger } from 'utils/logger';

export const GetS3 = async (
  name: string,
  accessLevel: StorageAccessLevel,
): Promise<string> => {
  try {
    logger.info('S3からのフェッチ処理を開始します');
    const result = await Storage.get(name, { level: accessLevel });
    logger.debug(result);
    logger.info('S3からのフェッチ処理が完了しました');

    return result;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetS3File = async (
  name: string,
  accessLevel: StorageAccessLevel,
): Promise<File> => {
  try {
    logger.info('S3からのフェッチ処理を開始します');
    const result = await Storage.get(name, {
      level: accessLevel,
      download: true,
    });
    logger.debug(result);
    logger.info('S3からのフェッチ処理が完了しました');

    return result.Body as File;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetS3Others = async (
  name: string,
  identityId: string,
): Promise<string> => {
  try {
    logger.info('S3からのフェッチ処理を開始します');
    const result = await Storage.get(name, {
      level: 'protected',
      identityId, // cognitoで管理されるユーザーIDに紐づく一意なID
    });
    logger.debug(result);
    logger.info('S3からのフェッチ処理が完了しました');

    return result;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const ListS3 = async (name: string): Promise<number> => {
  try {
    logger.info('S3からのフェッチ処理を開始します');

    const list = await Storage.list(name, { level: 'public' });
    logger.info('S3からのフェッチ処理が完了しました');

    return list.length;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const ListS3Detail = async (
  name: string,
): Promise<S3ProviderListOutput> => {
  try {
    logger.info('S3からのフェッチ処理を開始します');

    const list = await Storage.list(name, { level: 'public' });
    logger.info('S3からのフェッチ処理が完了しました');

    return list;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
