import { Paragraph } from 'docx';
import { AllocationReceiver } from 'models';
import nonNullable from 'utils/nonNullable';
import { legalHeirOrBeneficiary } from '../utils/legalHeirOrBeneficiary';

const justInCase = (allocationReceiver: AllocationReceiver[]): Paragraph => {
  const allocationReceiverNonNullable = allocationReceiver.filter(nonNullable);

  // noPropertiesCheck:
  // 割当している人ひと全てが、割当財産を持っていないことを判定
  const noPropertiesCheck = allocationReceiverNonNullable.every(
    (ele) => !ele.allocationProperties || ele.allocationProperties.length === 0,
  );

  // 割当している人の中に、法定相続人で、割当財産がある人がいないこととを判定
  const legalHeirNotExistWithPropertiesCheck =
    !allocationReceiverNonNullable.some(
      (ele) =>
        legalHeirOrBeneficiary(ele.type) === '法定相続人' &&
        ele.allocationProperties &&
        ele.allocationProperties.length > 0,
    );

  // 割当てている人のうち、受遺者で、割当財産がある人が一人だけかどうか判定する
  const onlyOnebeneficiaryWithProperties = allocationReceiverNonNullable.filter(
    (ele) =>
      legalHeirOrBeneficiary(ele.type) === '受遺者' &&
      ele.allocationProperties &&
      ele.allocationProperties.length > 0,
  );

  if (noPropertiesCheck) {
    return new Paragraph({});
  }

  if (
    legalHeirNotExistWithPropertiesCheck &&
    onlyOnebeneficiaryWithProperties.length === 1
  ) {
    return new Paragraph({
      text: `万一、別紙財産目録に記載されていない財産が発見された場合は、すべて${
        onlyOnebeneficiaryWithProperties[0].name || '○○○○'
      }に遺贈する。`,
      style: 'numberedPara',
    });
  }

  return new Paragraph({
    text: '万一、遺言者より先に死亡した者がいた場合は、その者が相続もしくは受贈すべき財産は、全て○○○○へ相続させ、または受贈させる。',
    style: 'numberedPara',
  });
};

export default justInCase;
