import { FC } from 'react';
// import { useSync } from 'hooks';
import WillHeader from '../organisms/willHeader';
// import Footer from '../organisms/footer';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import ExecutorRegisterDescription from '../templates/executorRegisterDescription';
import ExecutorRegisterContent from '../organisms/executorRegisterContent';
import ExecutorPcDescription from '../organisms/executorPcDescription';
import pageData from '../data/pageData';
import './executorRegister.css';

// useSync();
const ExecutorRegister: FC = () => (
  <>
    <WillHeader title="名簿以外の遺言執行者を選定" />
    <div className="executorRegister">
      <ExecutorRegisterDescription />
      <ExecutorRegisterContent />
    </div>
    {/* <Footer /> */}
    <CommonFooter />
    <ExecutorPcDescription description={pageData[35].description} />
  </>
);

export default ExecutorRegister;
