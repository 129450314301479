import { FC } from 'react';
import { useLocation } from 'react-router';
import { useQuery } from 'react-query';
import { GetUser } from 'domains/cognite/services/manageUser';
import { UserAttributes } from 'domains/cognite/user';
import ExecutorTextHeader from '../organisms/executorTextHeader';
import ExecutorProfileConfirmContent from '../organisms/executorProfileConfirmContent';
import ExecutorLogoHeader from '../../../common/apply/organisms/executorLogoHeader';
import CommonFooter from '../../../common/apply/templates/commonFooter';

type State = {
  prefecture: string;
  city: string;
  numbers: string;
  building: string;
  notion: string;
  reward: string;
  introduce: string;
  imageUrl: string;
  image: File | null;
};

const ExecutorProfileConfirm: FC = () => {
  const location = useLocation();
  const {
    prefecture,
    city,
    numbers,
    building,
    notion,
    reward,
    introduce,
    imageUrl,
    image,
  } = location.state as State;

  const { data: executor } = useQuery(
    ['current_auth_executor_profile'],
    GetUser,
  );

  const defaultAttributes: UserAttributes = {
    sub: '',
    name: '',
    address: '',
    'custom:address_prefecture': '',
    'custom:address_city': '',
    'custom:address_numbers': '',
    'custom:address_building': '',
    email: '',
    email_verified: true,
    gender: '',
    phone_number: '',
    phone_number_verified: true,
    'custom:ages': '',
    zoneinfo: '',
    'custom:passport': '',
    'custom:reward': '',
    'custom:notion': '',
    'custom:introduce': '',
  };

  const attributes = executor ? executor.attributes : defaultAttributes;

  const updateAttributes: UserAttributes = {
    ...attributes,
    // address: `${prefecture}${city}`,
    'custom:address_prefecture': prefecture,
    'custom:address_city': city,
    'custom:address_numbers': numbers,
    'custom:address_building': building,
    'custom:notion': notion,
    'custom:reward': reward,
    'custom:introduce': introduce,
  };

  return (
    <>
      <ExecutorLogoHeader />
      <ExecutorTextHeader title="編集内容の確認" />
      <ExecutorProfileConfirmContent
        attributes={updateAttributes}
        imageUrl={imageUrl}
        image={image}
      />
      <CommonFooter />
    </>
  );
};

export default ExecutorProfileConfirm;
