/* eslint-disable no-alert */
import { FC, useEffect } from 'react';
import { useQuery } from 'react-query';
// import { Navigate } from 'react-router';
import { useNavigate, useLocation } from 'react-router-dom';
import { useWatchAssignStatus } from 'hooks';
import { QueryAssignExecutorNotOnTheList } from 'domains/cognite/services/manageAssignExecutorNotOnTheList';
import NormalBtn from '../atoms/normalBtn';
import './executorChargeContent.css';

const ExecutorRegisterContent: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const path = useWatchAssignStatus('executor_charge_content');

  useEffect(() => {
    if (location.pathname !== path) {
      window.alert('データの更新があります');
      navigate(path);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const getQuery = async () => {
    const getQueryContent = await QueryAssignExecutorNotOnTheList();
    const data = getQueryContent[0];

    return data;
  };

  const { data: getQueryData, isFetching: fetchQuery } = useQuery(
    'executor_not_list',
    () => getQuery(),
  );

  // if (location.pathname !== path) {
  //   return <Navigate to={path} replace />;
  // }

  return fetchQuery ? (
    <div>
      <p>データ取得中</p>
    </div>
  ) : (
    <div className="executorChargeContent">
      <div>
        <table>
          <tr>
            <td>氏名</td>
            <td>{getQueryData ? getQueryData.name : '未入力'}</td>
          </tr>
          <tr>
            <td>生年月日</td>
            <td>{getQueryData ? getQueryData.birth : '未入力'}</td>
          </tr>
          <tr>
            <td>住所</td>
            <td>{getQueryData ? getQueryData.address : '未入力'}</td>
          </tr>
          <tr>
            <td>続柄又は資格</td>
            <td>
              {getQueryData ? getQueryData.relationshipOrpassport : '未入力'}
            </td>
          </tr>
        </table>
      </div>
      <NormalBtn
        title="変更する"
        className="normalBtn"
        href="/user/executorchange"
      />
    </div>
  );
};

export default ExecutorRegisterContent;
