/* eslint-disable react/no-array-index-key */
/* eslint-disable no-alert */
import { FC, useRef, useState, ChangeEvent } from 'react';
import // MutateAllocationReceiverAndGetId,
// DeleteAllocationReceiverById,
'domains/cognite/services/manageAllocationReceiver';
import { AllocationReceiver } from 'models';
import { Year, YearArray } from '../data/year';
import { Month, MonthArray } from '../data/month';
import { Day, DayArray } from '../data/day';
import minusBtn from '../images/minusBtn.svg';
import InputItem from '../atoms/InputItem';
import './legalHeirFormCard.css';

type Props = {
  // remove: () => void;
  minusBtnView: boolean;
  // legalHeirQuestionId: number;
  // legalHeirData: AllocationReceiver[];
  // changeLegalHeirContentData: (newData: AllocationReceiver[]) => void;
  legalHeirDataItem?: AllocationReceiver;
  updateLegalHeirNameContent: (
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement>,
    target: string,
  ) => void;
  subjectId: string;
  updateLegalHeirBirthContent: (
    subjectKey: string,
    updateData: string,
    target: string,
  ) => void;
  deleteCard: (deleteSubject: string) => void;
  // name: string;
  // form: number;
};

const LegalHeirFormCard: FC<Props> = (props) => {
  const {
    // remove,
    minusBtnView,
    // legalHeirQuestionId,
    // legalHeirData,
    // changeLegalHeirContentData,
    legalHeirDataItem,
    updateLegalHeirNameContent,
    subjectId,
    updateLegalHeirBirthContent,
    deleteCard,
  } = props;

  // const nameRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLSelectElement>(null);
  const monthRef = useRef<HTMLSelectElement>(null);
  const dayRef = useRef<HTMLSelectElement>(null);
  // const type = '法定相続人';
  // const defaultDataId =
  //   legalHeirDataItem && legalHeirDataItem.id ? legalHeirDataItem.id : '';

  // const [legalHeirId, setLegalHeirId] = useState(defaultDataId);

  const updateNameData = (e: ChangeEvent<HTMLInputElement>) => {
    updateLegalHeirNameContent(subjectId, e, 'name');
  };

  const updateBirthData = () => {
    const yearRefValue = yearRef.current ? yearRef.current.value : '';
    const monthRefValue = monthRef.current ? monthRef.current.value : '';
    const dayRefValue = dayRef.current ? dayRef.current.value : '';
    const birthData = `${yearRefValue}/${monthRefValue}/${dayRefValue}`;
    updateLegalHeirBirthContent(subjectId, birthData, 'birth');
  };

  const deleteCardFunc = () => {
    deleteCard(subjectId);
  };

  const scroll = () => {
    window.scrollBy({
      top: 100,
      behavior: 'smooth',
    });
  };

  // const mutate = async () => {
  //   const inputNumberAll = document.querySelectorAll('input[type="number"]');
  //   inputNumberAll.forEach((ele) => {
  //     ele.addEventListener('wheel', (e) => {
  //       e.preventDefault();
  //     });
  //   });
  //   const newData = await MutateAllocationReceiverAndGetId(
  //     defaultDataId || legalHeirId,
  //     legalHeirQuestionId,
  //     1,
  //     nameRef.current ? nameRef.current.value : '',
  //     `${yearRef.current ? yearRef.current.value : ''}/${
  //       monthRef.current ? monthRef.current.value : ''
  //     }/${dayRef.current ? dayRef.current.value : ''}`,
  //     type,
  //   );
  //   setLegalHeirId(newData.id);

  //   window.scrollBy({
  //     top: 100,
  //     behavior: 'smooth',
  //   });
  // };

  // const cardDeleteReceiver = async () => {
  //   remove();
  //   await DeleteAllocationReceiverById(legalHeirId);
  //   const newData = legalHeirData.filter((ele) => ele.id !== legalHeirId);
  //   changeLegalHeirContentData(newData);
  // };

  const [year, setYear] = useState<number>(0);
  const getYear = () => {
    const yearDataInfo = document.getElementById('year') as HTMLSelectElement;
    const yearData = Number(yearDataInfo.value);

    return setYear(yearData);
  };

  const [month, setMonth] = useState<number>(0);
  const getMonth = () => {
    const monthDataInfo = document.getElementById('month') as HTMLSelectElement;
    const monthData = Number(monthDataInfo.value);

    return setMonth(monthData);
  };

  const slash = '/';
  const legalHeirDefaultBirth =
    legalHeirDataItem && legalHeirDataItem.birth ? legalHeirDataItem.birth : '';
  // 年のチェック
  const legalHeirDefaultYear = Number(legalHeirDefaultBirth.split(slash)[0]);
  // 月のチェック
  const legalHeirDefaultMonth = Number(legalHeirDefaultBirth.split(slash)[1]);
  // 日のチェック
  const legalHeirDefaultDay = Number(legalHeirDefaultBirth.split(slash)[2]);

  return (
    <div className="legalHeirForm">
      {/* <p>{form + 1}</p> */}
      <button
        type="button"
        onClick={deleteCardFunc}
        className={minusBtnView ? 'stockopen' : 'stockclose'}
      >
        <div>
          <img src={minusBtn} alt="minus-Button" />
          <p>削除</p>
        </div>
      </button>
      <div>
        <label htmlFor="name">
          <InputItem title="お名前" name="require" />
          <input
            type="text"
            id="name"
            name="name"
            placeholder="名前を入力"
            // ref={nameRef}
            onBlur={scroll}
            defaultValue={
              legalHeirDataItem && legalHeirDataItem.name
                ? legalHeirDataItem.name
                : ''
            }
            onChange={updateNameData}
          />
        </label>
        <p>架空の名前で構いません</p>
      </div>
      <div className="legalHeirInformation">
        <label htmlFor="birth">
          <InputItem title="生年月日" name="optional" />
          <div className="birthSelect birthYear">
            <select
              name="year"
              id="year"
              // value={birthYear}
              onChange={() => {
                getYear();
                // await mutate();
                updateBirthData();
              }}
              ref={yearRef}
            >
              {YearArray.map((yearEle: Year, index) => (
                <option
                  value={yearEle.year}
                  key={`year${yearEle.id}${index}`}
                  selected={
                    legalHeirDefaultYear
                      ? legalHeirDefaultYear === yearEle.year
                      : yearEle.year === 1977
                  }
                >
                  {yearEle.year}
                </option>
              ))}
            </select>
            <p>年</p>
          </div>
          <div className="MonthAndDay">
            <div className="birthSelect birthMonth">
              <select
                name="month"
                id="month"
                // value={birthMonth}
                onChange={() => {
                  getMonth();
                  updateBirthData();
                }}
                ref={monthRef}
              >
                {MonthArray.map((monthEle: Month, index) => (
                  <option
                    value={monthEle.month}
                    key={`month${monthEle.id}${index}`}
                    selected={
                      legalHeirDefaultMonth
                        ? legalHeirDefaultMonth === monthEle.month
                        : monthEle.month === 1
                    }
                  >
                    {monthEle.month}
                  </option>
                ))}
              </select>
              <p>月</p>
            </div>
            <div className="birthSelect birthDay">
              <select
                name="day"
                id="day"
                // value={birthDay}
                onChange={updateBirthData}
                ref={dayRef}
                // defaultValue={birthDayValue}
              >
                {DayArray.map((dayEle: Day, index) => {
                  if (year % 4 === 0 && month === 2) {
                    return (
                      <option
                        value={dayEle.day28}
                        key={`day28${dayEle.id}${index}`}
                        selected={
                          legalHeirDefaultDay
                            ? legalHeirDefaultDay === dayEle.day28
                            : dayEle.day28 === 1
                        }
                      >
                        {dayEle.day28}
                      </option>
                    );
                  }
                  if (year % 4 !== 0 && month === 2) {
                    return (
                      <option
                        value={dayEle.day29}
                        key={`day29${dayEle.id}${index}`}
                        selected={
                          legalHeirDefaultDay
                            ? legalHeirDefaultDay === dayEle.day29
                            : dayEle.day29 === 1
                        }
                      >
                        {dayEle.day31}
                      </option>
                    );
                  }
                  if (
                    month === 4 ||
                    month === 6 ||
                    month === 9 ||
                    month === 11
                  ) {
                    return (
                      <option
                        value={dayEle.day30}
                        key={`day30${dayEle.id}${index}`}
                        selected={
                          legalHeirDefaultDay
                            ? legalHeirDefaultDay === dayEle.day30
                            : dayEle.day30 === 1
                        }
                      >
                        {dayEle.day30}
                      </option>
                    );
                  }

                  return (
                    <option
                      value={dayEle.day31}
                      key={`day31${dayEle.id}${index}`}
                      selected={
                        legalHeirDefaultDay
                          ? legalHeirDefaultDay === dayEle.day31
                          : dayEle.day31 === 1
                      }
                    >
                      {dayEle.day31}
                    </option>
                  );
                })}
                :
              </select>
              <p>日</p>
            </div>
          </div>
        </label>
        <p>架空の生年月日で構いません</p>
      </div>
    </div>
  );
};

LegalHeirFormCard.defaultProps = {
  legalHeirDataItem: undefined,
};

export default LegalHeirFormCard;
