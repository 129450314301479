import { FC } from 'react';
import WillHeader from '../organisms/willHeader';
import ExecutorSearchResultDescription from '../templates/executorSearchResultDescription';
// import Footer from '../organisms/footer';
import CommonFooter from '../../../common/apply/templates/commonFooter';
// import ExecutorSearchResultContent from '../organisms/executorSearchResultContent';

const ExecutorSearchResult: FC = () => (
  <>
    <WillHeader title="検索結果" />
    <div className="executorSearchResult">
      <ExecutorSearchResultDescription />
      {/* <ExecutorSearchResultContent changePage={changePage} /> */}
    </div>
    {/* <Footer /> */}
    <CommonFooter />
  </>
);

export default ExecutorSearchResult;
