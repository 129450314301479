import willInfo from '../images/willInfo.svg';
import realEstate from '../images/realEstate.svg';
import movingProperty from '../images/movingProperty.svg';
import deposits from '../images/deposits.svg';
import stock from '../images/stock.svg';
import lifeInsurance from '../images/lifeInsurance.svg';
import bonds from '../images/bonds.svg';
import liabilities from '../images/liabilities.svg';
import legalHeir from '../images/legalHeir.svg';
import recipient from '../images/recipient.svg';
import propertyAllocation from '../images/propertyAllocation.svg';
import confirm from '../images/confirm.svg';
import selection from '../images/selection.svg';

export const yourInfoData = {
  name: '遺言者',
  href: '/user/willinfo',
  icon: willInfo,
};

export const PropertyInfoDatas = [
  [
    {
      name: '不動産',
      href: '/user/realEstate',
      icon: realEstate,
    },
    {
      name: '動産',
      href: '/user/movingProperty',
      icon: movingProperty,
    },
    {
      name: '預貯金',
      href: '/user/deposits',
      icon: deposits,
    },
    {
      name: '株式・債券',
      href: '/user/stock',
      icon: stock,
    },
    {
      name: '生命保険',
      href: '/user/lifeInsurance',
      icon: lifeInsurance,
    },
    {
      name: '債権',
      href: '/user/bonds',
      icon: bonds,
    },
  ],
  [
    {
      name: '負債',
      href: '/user/liabilities',
      icon: liabilities,
    },
  ],
];

export const heirInfoDatas = [
  {
    name: '法定相続人',
    href: '/user/legalHeir',
    icon: legalHeir,
    description: '法律で決まっている相続人',
  },
  {
    name: '受遺者',
    href: '/user/recipient',
    icon: recipient,
    description: '法定相続人以外の人で相続財産を渡す相手',
  },
];

export const propertyAllocationData = {
  name: '財産割当',
  href: '/user/propertyAllocation',
  icon: propertyAllocation,
};

export const OthersDatas = [
  {
    name: '遺言書確認',
    href: '/user/confirm',
    icon: confirm,
  },
  {
    name: '遺言執行者の選定',
    href: '/user/executorallocation',
    icon: selection,
  },
];

export const gg = 'fff';

// export default homeData;
