/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-nested-ternary */
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import {
  useQueryProperties,
  useQueryPropertiesQuick,
  usePropertiesItem,
  useQueryContents,
  // useSync,
} from 'hooks';
import { useNavigate } from 'react-router-dom';
import { MutateProperties } from 'domains/cognite/services/manageProperties';
import {
  DeleteMovingProperty,
  GetMovingProperty,
  MutateMovingProperty,
} from 'domains/cognite/services/manageMovingProperty';
import Dialog from 'common/apply/organisms/dialog';
import { GetUser } from 'domains/cognite/services/manageUser';
import { logger } from 'utils/logger';
import WillHeader from '../organisms/willHeader';
import RadioCustomScroll from '../molecules/radioCustomScroll';
import MovingPropertyDescription from '../templates/movingPropertyDescription';
import CommonFooter from '../../../common/apply/templates/commonFooter';
// import NextReturn from '../molecules/nextReturn';
import RequireNextReturn from '../molecules/requireNextReturn';
import Param6Checkmark from '../atoms/paramCheckmarkPc';
import PageDescriptionPc from '../organisms/pageDescriptionPc';
import pageData from '../data/pageData';
import PcDescriptionBackImg from '../images/PcMovingPropertyBack.svg';
import './movingProperty.css';

const MovingProperty: FC = () => {
  // useSync();
  const { data: user } = useQuery(['authenticated_user'], GetUser);
  if (!user) {
    logger.error(user);
    throw Error;
  }
  const { data } = useQueryProperties('movingProperty_properties', user);
  const { data: dataQuick } = useQueryPropertiesQuick(
    'movingProperty_properties_quick',
    user,
  );

  const [isDialogShow, setIsDialogShow] = useState(false);

  const toggleDialog = () => {
    setIsDialogShow(!isDialogShow);
  };

  const { movingProperty } = usePropertiesItem(dataQuick);

  const [defaultData, setDefaultData] = useState(movingProperty);

  // 質問1のチェック
  const yesCheck1Boolean =
    defaultData && defaultData.check1 !== null
      ? !!defaultData?.check1
      : undefined;

  const noCheck1Boolean = defaultData?.check1 === false;

  // 質問2のチェック
  const yesCheck2Boolean =
    defaultData && defaultData.check2 !== null
      ? !!defaultData?.check2
      : undefined;

  const noCheck2Boolean = defaultData?.check2 === false;

  // 質問3のチェック
  const yesCheck3Boolean =
    defaultData && defaultData.check3 !== null
      ? !!defaultData?.check3
      : undefined;

  const noCheck3Boolean = defaultData?.check3 === false;

  // 質問4のチェック
  const yesCheck4Boolean =
    defaultData && defaultData.check4 !== null
      ? !!defaultData?.check4
      : undefined;

  const noCheck4Boolean = defaultData?.check4 === false;
  // 質問5のチェック
  const yesCheck5Boolean =
    defaultData && defaultData.check5 !== null
      ? !!defaultData?.check5
      : undefined;

  const noCheck5Boolean = defaultData?.check5 === false;

  const [check1, setCheck1] = useState(yesCheck1Boolean);
  const [check2, setCheck2] = useState(yesCheck2Boolean);
  const [check3, setCheck3] = useState(yesCheck3Boolean);
  const [check4, setCheck4] = useState(yesCheck4Boolean);
  const [check5, setCheck5] = useState(yesCheck5Boolean);

  const check1Click = (check: boolean) => {
    setCheck1(check);
  };
  const check2Click = (check: boolean) => {
    setCheck2(check);
  };
  const check3Click = (check: boolean) => {
    setCheck3(check);
  };
  const check4Click = (check: boolean) => {
    setCheck4(check);
  };
  const check5Click = (check: boolean) => {
    setCheck5(check);
  };

  const propertiesId = data ? data.id : '';

  const { movingPropertyMajorNum } = useQueryContents(
    'movingProperty_check',
    data,
  );

  const cardDeleteAll = async () => {
    await DeleteMovingProperty();
    const updateData = await GetMovingProperty();
    await MutateProperties(propertiesId, {
      userId: user.attributes.sub,
      movingProperty: undefined,
    });

    setDefaultData(updateData[0]);
    window.location.reload();
  };

  const navigate = useNavigate();

  const wait = () =>
    new Promise((resolve) => {
      setTimeout(resolve, 200);
    });

  const save = async (link: string) => {
    await wait();
    const movingPropertyModel = {
      majorNum: movingPropertyMajorNum,
      check1,
      check2,
      check3,
      check4,
      check5,
      inputFlag: true,
    };
    const id = data
      ? data.propertiesMovingPropertyId
        ? data.propertiesMovingPropertyId
        : ''
      : '';
    const createdMovingProperty = await MutateMovingProperty(
      id,
      movingPropertyModel,
    );
    await MutateProperties(propertiesId, {
      userId: user.attributes.sub,
      movingProperty: createdMovingProperty,
    });
    navigate(link);
  };

  return (
    <>
      {isDialogShow ? (
        <Dialog
          dialogText="入力内容を削除しますがよろしいですか？"
          dialogYesFunc={cardDeleteAll}
          dialogNoFunc={toggleDialog}
        />
      ) : (
        ''
      )}
      <WillHeader title="相続財産【動産】" />
      <div className="sp-only">
        <MovingPropertyDescription data={data} />
      </div>
      <div className="movingPropertyTop">
        <div className="movingProperty">
          <div className="pc-only realEstateParam1">
            <Param6Checkmark
              StyleClass="styleParam2"
              key="movingProperty_pc"
              checkMarkSize6
              propertyData={data}
            />
          </div>
          <RadioCustomScroll
            title="非上場会社の株式は保有していますか？"
            name="yes-or-no1"
            key={1}
            yesFunc={() => {
              check1Click(true);
            }}
            noFunc={() => {
              check1Click(false);
            }}
            yesCheck={yesCheck1Boolean}
            noCheck={noCheck1Boolean}
          />
          <RadioCustomScroll
            title="貴金属はありますか？"
            name="yes-or-no2"
            key={2}
            yesFunc={() => {
              check2Click(true);
            }}
            noFunc={() => {
              check2Click(false);
            }}
            yesCheck={yesCheck2Boolean}
            noCheck={noCheck2Boolean}
          />
          <RadioCustomScroll
            title="ペットを飼っていますか？"
            name="yes-or-no3"
            key={3}
            yesFunc={() => {
              check3Click(true);
            }}
            noFunc={() => {
              check3Click(false);
            }}
            yesCheck={yesCheck3Boolean}
            noCheck={noCheck3Boolean}
          />
          <RadioCustomScroll
            title="暗号資産(ビットコイン等)はありますか？"
            name="yes-or-no4"
            key={4}
            yesFunc={() => {
              check4Click(true);
            }}
            noFunc={() => {
              check4Click(false);
            }}
            yesCheck={yesCheck4Boolean}
            noCheck={noCheck4Boolean}
          />
          <RadioCustomScroll
            title="その他、動産はありますか？"
            name="yes-or-no5"
            key={5}
            yesFunc={() => {
              check5Click(true);
            }}
            noFunc={() => {
              check5Click(false);
            }}
            yesCheck={yesCheck5Boolean}
            noCheck={noCheck5Boolean}
          />
        </div>
      </div>
      <RequireNextReturn
        returnFunc={async () => {
          await save('../realEstate');
        }}
        nextFunc={async () => {
          await save('../deposits');
        }}
        deleteFunc={toggleDialog}
      />
      <CommonFooter />
      <PageDescriptionPc
        page="movingPropertyDescriptionPc"
        description={pageData[4].description}
        descriptionPcImg={PcDescriptionBackImg}
      />
    </>
  );
};

export default MovingProperty;
