import { FC } from 'react';
import { useQuery } from 'react-query';
import { ListS3Detail } from 'domains/aws/services/get-s3';

type Props = {
  userId: string;
};

const LastUpdated: FC<Props> = (props) => {
  const { userId } = props;

  const getLastUpdated = async () => {
    const fileList = await ListS3Detail(`遺言書_${userId}.pdf`);

    if (!fileList.length) {
      return undefined;
    }

    const lastUpdatedDate = fileList[0].lastModified;

    if (!lastUpdatedDate) {
      return undefined;
    }

    const year = lastUpdatedDate.getFullYear();
    const month = lastUpdatedDate.getMonth() + 1;
    const date = lastUpdatedDate.getDate();
    const hours = lastUpdatedDate.getHours();
    const minutes = lastUpdatedDate.getMinutes();
    const seconds = lastUpdatedDate.getSeconds();

    const lastUpdatedDateFormatted = `${year}/${month}/${date} ${hours}時${minutes}分${seconds}秒`;

    return lastUpdatedDateFormatted;
  };

  const { data, error } = useQuery(
    ['file_last_updated', userId],
    getLastUpdated,
    {
      refetchInterval: 5000,
    },
  );

  if (!data || error) {
    return <></>;
  }

  return <p>最終更新日時：{data}</p>;
};

export default LastUpdated;
