/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { DataStore } from 'aws-amplify';
import { AssignStatus, Message } from 'models';
import {
  useRelationshpFromExecutor,
  useExecutorChatroomsAvailable,
} from 'hooks';
import { logger } from 'utils/logger';
import { listMessageFromlistener } from 'domains/cognite/services/manageChat';
import ExecutorFooterMenuAfterLogin from '../templates/executorFooterMenuAfterLogin';
import './executorFooter.css';
import homeIcon from '../images/homeIcon.svg';
import chatIcon from '../images/chatIcon.svg';
import printIcon from '../images/printIcon.svg';
import menuIcon from '../images/menuIcon.svg';
import redDot from '../images/redDot.svg';

const ExecutorFooter: FC = () => {
  const { chatrooms } = useExecutorChatroomsAvailable('chatlist_footer');

  const enableClick = chatrooms && chatrooms.length ? '' : 'notChatLink';

  const { data: messagesFromQuery } = useQuery(
    'executor_message_footer',
    listMessageFromlistener,
    { cacheTime: 200 },
  );

  const [chatMessages, setChatMessages] = useState<Message[]>(
    messagesFromQuery || ([] as unknown[] as Message[]),
  );

  // const [unread, setUnread] = useState('no-red-dot');

  useEffect(() => {
    const subscription = DataStore.observe(Message).subscribe((msg) => {
      logger.info(msg.model, msg.opType, msg.element);
      const list = async () => {
        const messagesFromSubscription = await listMessageFromlistener();
        setChatMessages(messagesFromSubscription);
      };
      void list();

      // ここ直したい
      // if (
      //   chatMessages.length !== 0 &&
      //   chatMessages.some((ele) => ele.alreadyRead === true)
      // ) {
      //   setUnread('');
      // }
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: relationship, error } = useRelationshpFromExecutor(
    'clients_REGISTERD_footer',
  );

  const newApplicantNotice = () => {
    if (!relationship || relationship.length === 0 || error) {
      return 'no-red-dot';
    }

    const includeRegister = relationship.some(
      (ele) => ele.status === AssignStatus.REGISTERD,
    );

    if (!includeRegister) {
      return 'no-red-dot';
    }

    return '';
  };

  const [position, setPosition] = useState<number | null>(0);
  const [show, setShow] = useState(false);
  const [ClassSwitch, setClassSwitch] = useState(false);
  const FooterScroll = document.getElementById('footerScroll');

  window.addEventListener('scroll', () => {
    const ScrollTop = document.documentElement.scrollTop;

    if (!FooterScroll) {
      <p>取得中</p>;
    } else if (position! < ScrollTop && !show) {
      setClassSwitch(true);
    } else {
      setClassSwitch(false);
    }

    setPosition(ScrollTop);
  });

  return (
    <>
      <footer
        className={`exeWillfooter ${
          ClassSwitch ? 'exeFooterScrollUp' : 'exeFooterScrollDown'
        }`}
        id="footerScroll"
      >
        <div className="exeWillfooternav">
          <Link to="/executor">
            <img src={homeIcon} alt="ホームアイコン" />
            <p>ホーム</p>
          </Link>
          <Link to="/executor/chatList" className={`${enableClick}`}>
            <img src={chatIcon} alt="チャットアイコン" />
            <p>チャット</p>
            <img
              src={redDot}
              className={
                chatMessages.length !== 0 &&
                chatMessages.some((ele) => ele.alreadyRead === false)
                  ? ''
                  : 'no-red-dot'
              }
              alt="赤点"
            />
          </Link>
          <Link to="/executor/applicantList">
            <img src={printIcon} alt="申請アイコン" />
            <p>申請者一覧</p>
            <img src={redDot} className={newApplicantNotice()} alt="赤点" />
          </Link>
          <button type="button" onClick={() => setShow(!show)}>
            <img src={menuIcon} alt="メニューアイコン" />
            <p>メニュー</p>
          </button>
        </div>
      </footer>
      <ExecutorFooterMenuAfterLogin
        show={show}
        setShow={() => setShow(false)}
      />
    </>
  );
};

export default ExecutorFooter;
