/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import { FC, Suspense } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useQueryProperties, useQueryPropertiesQuick } from 'hooks';
import { GetUser } from 'domains/cognite/services/manageUser';
import { logger } from 'utils/logger';
import ErrorBoundary from 'ErrorBoundary';
import Spinner from 'components/molecules/Spinner';
import LifeInsuranceDescription from '../templates/lifeInsuranceDescription';
import WillHeader from '../organisms/willHeader';
import LifeInsuranceTemp from '../templates/lifeInsuranceTemp';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import PageDescriptionPc from '../organisms/pageDescriptionPc';
import pageData from '../data/pageData';
import PcDescriptionBackImg from '../images/PcLifeInsuranceBack.svg';
import './lifeInsuranceResponsive.css';

const LifeInsurance: FC = () => {
  // useSync();
  const { data: user } = useQuery(['authenticated_user'], GetUser);
  if (!user) {
    logger.error(user);
    throw Error;
  }
  const { data } = useQueryProperties('lifeInsurance_properties', user);
  const { data: dataQuick } = useQueryPropertiesQuick(
    'lifeInsurance_properties_quick',
    user,
  );

  const navigate = useNavigate();

  const onClickFunc = (link: string) => {
    navigate(link);
  };

  const descriptionPc = <>{pageData[9].description}</>;

  return (
    <>
      <WillHeader title="相続財産【生命保険】" />
      <div className="sp-only">
        <LifeInsuranceDescription data={data} />
      </div>
      <ErrorBoundary>
        <Suspense fallback={<Spinner size="large" />}>
          <LifeInsuranceTemp data={dataQuick} user={user} />
        </Suspense>
      </ErrorBoundary>
      <CommonFooter onClickFunc={onClickFunc} />
      <PageDescriptionPc
        page="LifeInsuranceDescriptionPc"
        description={descriptionPc}
        descriptionPcImg={PcDescriptionBackImg}
      />
    </>
  );
};

export default LifeInsurance;
