import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { sendEmailInquiry } from 'domains/apigateway/services/sendEmail';
import './contactConfirm.css';
import CommonFooter from './apply/templates/commonFooter';
import UserLogoHeader from './components/organisms/userLogoHeader';

type State = {
  name: string;
  company: string;
  position: string;
  phoneNumber: string;
  email: string;
  inquiry: string;
};

const ContactConfirm: FC = () => {
  const location = useLocation();

  const { name, company, position, phoneNumber, email, inquiry } =
    location.state as State;

  const sendEmailForContact = async () => {
    await sendEmailInquiry(location.state as State);
  };

  return (
    <>
      <UserLogoHeader />
      <div className="contact-confirm">
        <h1>お問い合わせ 確認</h1>
        <div className="contact-confirm-content">
          <div>
            <p>お名前</p>
            <p>{name}</p>
          </div>
          <div>
            <p>会社名</p>
            <p>{company}</p>
          </div>
          <div>
            <p>役職</p>
            <p>{position}</p>
          </div>
          <div>
            <p>電話番号</p>
            <p>{phoneNumber}</p>
          </div>
          <div>
            <p>メールアドレス</p>
            <p>{email}</p>
          </div>
          <div>
            <p>お問い合わせ</p>
            <p>{inquiry}</p>
          </div>
          <div className="contact-confirm-btns">
            <Link to="/contact">前に戻る</Link>
            <Link to="/contact/thanks" onClick={sendEmailForContact}>
              送信する
            </Link>
          </div>
        </div>
      </div>
      <CommonFooter />
    </>
  );
};

export default ContactConfirm;
