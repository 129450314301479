import { FC, useState } from 'react';
// import DescriptionImg from '../images/descriptionImg.svg';
import './pageDescriptionPc.css';
import DescriptionArrow from '../images/DescriptionArrow.svg';

type Props = {
  // title: JSX.Element;
  page: string;
  description: JSX.Element;
  descriptionPcImg: string;
};

const PageDescriptionPc: FC<Props> = (props) => {
  const { page, description, descriptionPcImg } = props;

  const [DescriptionBtn, setDescriptionBtn] = useState(true);

  const DesSwitching = () => {
    setDescriptionBtn(!DescriptionBtn);
  };

  const classSwitchIn = DescriptionBtn ? 'classSwitchIn' : 'classSwitchOut';
  const classSwitchOut = DescriptionBtn ? 'classSwitchOut' : 'classSwitchIn';

  return (
    <>
      <div className={`${page} ${classSwitchIn} pageDescriptionPc pc-only`}>
        <div>
          <p>{description}</p>
          <img src={descriptionPcImg} alt="pcDescription背景画像" />
        </div>
        <button type="button" onClick={DesSwitching}>
          <img src={DescriptionArrow} alt="pcDescription矢印" />
        </button>
      </div>
      <div className="pc-only">
        <button
          type="button"
          className={`${classSwitchOut} DesAdviceBtn `}
          onClick={DesSwitching}
        >
          <p>
            アドバイス
            <br />
            を表示する
          </p>
          <img src={DescriptionArrow} alt="pcDescription矢印" />
        </button>
      </div>
    </>
  );
};

export default PageDescriptionPc;
