export interface Day {
  id: number;
  day28: number | string;
  day29: number | string;
  day30: number | string;
  day31: number;
}

export const DayArray: Day[] = [
  { id: 1, day28: 1, day29: 1, day30: 1, day31: 1 },
  { id: 2, day28: 2, day29: 2, day30: 2, day31: 2 },
  { id: 3, day28: 3, day29: 3, day30: 3, day31: 3 },
  { id: 4, day28: 4, day29: 4, day30: 4, day31: 4 },
  { id: 5, day28: 5, day29: 5, day30: 5, day31: 5 },
  { id: 6, day28: 6, day29: 6, day30: 6, day31: 6 },
  { id: 7, day28: 7, day29: 7, day30: 7, day31: 7 },
  { id: 8, day28: 8, day29: 8, day30: 8, day31: 8 },
  { id: 9, day28: 9, day29: 9, day30: 9, day31: 9 },
  { id: 10, day28: 10, day29: 10, day30: 10, day31: 10 },
  { id: 11, day28: 11, day29: 11, day30: 11, day31: 11 },
  { id: 12, day28: 12, day29: 12, day30: 12, day31: 12 },
  { id: 13, day28: 13, day29: 13, day30: 13, day31: 13 },
  { id: 14, day28: 14, day29: 14, day30: 14, day31: 14 },
  { id: 15, day28: 15, day29: 15, day30: 15, day31: 15 },
  { id: 16, day28: 16, day29: 16, day30: 16, day31: 16 },
  { id: 17, day28: 17, day29: 17, day30: 17, day31: 17 },
  { id: 18, day28: 18, day29: 18, day30: 18, day31: 18 },
  { id: 19, day28: 19, day29: 19, day30: 19, day31: 19 },
  { id: 20, day28: 20, day29: 20, day30: 20, day31: 20 },
  { id: 21, day28: 21, day29: 21, day30: 21, day31: 21 },
  { id: 22, day28: 22, day29: 22, day30: 22, day31: 22 },
  { id: 23, day28: 23, day29: 23, day30: 23, day31: 23 },
  { id: 24, day28: 24, day29: 24, day30: 24, day31: 24 },
  { id: 25, day28: 25, day29: 25, day30: 25, day31: 25 },
  { id: 26, day28: 26, day29: 26, day30: 26, day31: 26 },
  { id: 27, day28: 27, day29: 27, day30: 27, day31: 27 },
  { id: 28, day28: 28, day29: 28, day30: 28, day31: 28 },
  { id: 29, day28: '', day29: 29, day30: 29, day31: 29 },
  { id: 30, day28: '', day29: '', day30: 30, day31: 30 },
  { id: 31, day28: '', day29: '', day30: '', day31: 31 },
];
