/* eslint-disable no-param-reassign */
import { DataStore } from 'aws-amplify';
import { Properties, PropertiesMetaData } from 'models';
import { logger } from 'utils/logger';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ModelInit } from '@aws-amplify/datastore';

export const MutateProperties = async (
  id: string,
  instance: ModelInit<Properties, PropertiesMetaData>,
): Promise<Properties> => {
  try {
    logger.info('財産目録の作成または更新を開始します');
    const original = await DataStore.query(Properties, id);

    if (original) {
      const data = await DataStore.save(
        Properties.copyOf(original, (updated) => {
          // ワンチャンここがバグ
          updated.willInfo = instance.willInfo;
          updated.realEstate = instance.realEstate;
          updated.movingProperty = instance.movingProperty;
          updated.deposits = instance.deposits;
          updated.stock = instance.stock;
          updated.lifeInsurance = instance.lifeInsurance;
          updated.bonds = instance.bonds;
          updated.liabilities = instance.liabilities;
        }),
      );

      return data;
    }
    const data = await DataStore.save(new Properties(instance));

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

// 財産目録取得
export const QueryProperties = async (userId: string): Promise<Properties> => {
  try {
    const queryPropertiesData = await DataStore.query(Properties, (c) =>
      c.userId('eq', userId),
    );

    return queryPropertiesData[0];
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteProperties = async (): Promise<Properties[]> => {
  try {
    logger.info('財産目録の削除を開始します');
    const data = await DataStore.query(Properties);
    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);

          return data;
        }),
      );
    }
    logger.debug(data);
    logger.info('財産目録の削除が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
