/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC } from 'react';
import { AllocationReceiver } from 'models';
import { Link } from 'react-router-dom';
import './legalHeirModal.css';

type Props = {
  showModal: boolean;
  openModal: () => void;
  allocationReceivers: AllocationReceiver[];
};

const LegalHeirModal: FC<Props> = (props) => {
  const { showModal, openModal, allocationReceivers } = props;

  const legalHeir = allocationReceivers.filter(
    (ele) => !!ele && ele.type === '法定相続人',
  );

  const content = showModal ? (
    <div id="legalHeirOverLay" onClick={openModal}>
      <div
        className="legalHierModal"
        id="modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="legalHierModalWindow">
          {legalHeir.length ? (
            <p>法定相続人は以下の通りです｡</p>
          ) : (
            <p>法定相続人はいません。</p>
          )}
          {legalHeir.map((ele) =>
            // const message =
            //   legalHeirEle.type === 1
            //     ? '第1順位の法定相続人です。'
            //     : legalHeirEle.type === 2
            //     ? '第2順位の法定相続人です。'
            //     : legalHeirEle.type === 3
            //     ? '第3順位の法定相続人です。'
            //     : '常に法定相続人です。';

            ele ? (
              <div key={ele.id} className="legal-heir-modalcontent-container">
                {/* <p>{message}</p> */}
                <div>
                  <p>氏名</p>
                  <p>{ele.name}</p>
                </div>
                <div>
                  <p>生年月日</p>
                  <p>{ele.birth}</p>
                </div>
              </div>
            ) : (
              <></>
            ),
          )}
        </div>
        <div className="legalHierModalBtns">
          <button type="button" onClick={openModal}>
            修正する
          </button>
          <Link to="/user/recipient">次へ</Link>
        </div>
      </div>
    </div>
  ) : null;

  return content;
};

export default LegalHeirModal;
