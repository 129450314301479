type PrefectureAndCity = {
  prefecture: string;
  city: string;
};

const splitPrefectureAndCity = (data: string): PrefectureAndCity => {
  if (data.includes('全国')) {
    const splitedData = data.split('全国');

    return {
      prefecture: '全国',
      city: splitedData[1],
    };
  }
  const splitedData = data
    .replace(/^(.{2}[都道府県]|.{3}県)(.+)/, '$1 $2')
    .split(' ');

  return {
    prefecture: splitedData[0],
    city: splitedData[1],
  };
};

export default splitPrefectureAndCity;
