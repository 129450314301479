import { FC, useRef, FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChangePassword } from 'domains/cognite/services/manageUser';
import { logger } from 'utils/logger';
import UserLogoHeader from '../organisms/userLogoHeader';
import './userPassword.css';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import NavigateBtn from '../atoms/navigateBtn';

const UserPassword: FC = () => {
  const navigate = useNavigate();

  const currentPasswordRef = useRef<HTMLInputElement>(null);
  const newPasswordRef = useRef<HTMLInputElement>(null);
  const newPasswordComfirmRef = useRef<HTMLInputElement>(null);
  const [refValueArray, setRefValueArray] = useState<boolean[]>([]);
  const [errorMessageSwitch, setErrorMessageSwitch] = useState(false);

  const changePassword = async (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    const refValueData: (string | undefined)[] = [
      currentPasswordRef.current?.value,
      newPasswordRef.current?.value,
      newPasswordComfirmRef.current?.value,
    ];

    const refValueDataArray: boolean[] = refValueData.map((ele) => ele !== '');
    setRefValueArray(refValueDataArray);

    try {
      if (
        currentPasswordRef.current &&
        currentPasswordRef.current.value &&
        newPasswordRef.current &&
        newPasswordRef.current.value &&
        newPasswordComfirmRef.current &&
        newPasswordComfirmRef.current.value
      ) {
        if (
          newPasswordRef.current.value === newPasswordComfirmRef.current.value
        ) {
          await ChangePassword(
            currentPasswordRef.current.value,
            newPasswordRef.current.value,
          )
            .then(() => {
              logger.info('パスワード変更成功');
              navigate('/user');
            })
            .catch((err) => {
              logger.error('パスワード変更失敗1');
              logger.error(err);
            });
        } else {
          logger.error('パスワードが異なります');
          logger.error('パスワード変更失敗2');
          setErrorMessageSwitch(true);
        }
      } else {
        logger.error('空白あり');
        logger.error('パスワード変更失敗2');
        setErrorMessageSwitch(false);
      }
    } catch (error) {
      logger.error('パスワード変更失敗3');
      setErrorMessageSwitch(false);
    }
  };

  return (
    <>
      <UserLogoHeader />
      <div className="userPassword">
        <h1>パスワードの変更</h1>
        <form>
          {/* <div> */}
          <div>
            <label htmlFor="currentPassword">
              現在のパスワード
              <input
                type="password"
                id="currentPassword"
                ref={currentPasswordRef}
                placeholder="パスワードを入力"
              />
            </label>
            {refValueArray[0] === false ? (
              <p className="alertMessage">パスワードが入力されていません</p>
            ) : (
              ''
            )}
          </div>
          <div>
            <label htmlFor="newPassword">
              新しいパスワード
              <input
                type="password"
                id="newPassword"
                ref={newPasswordRef}
                placeholder="新しいパスワードを入力"
              />
            </label>
            {refValueArray[1] === false ? (
              <p className="alertMessage">パスワードが入力されていません</p>
            ) : (
              ''
            )}
          </div>
          <div>
            <label htmlFor="newPasswordComfirm">
              新しいパスワード（確認）
              <input
                type="password"
                id="newPasswordComfirm"
                ref={newPasswordComfirmRef}
                placeholder="新しいパスワードを再入力"
              />
            </label>
            {refValueArray[2] === false ? (
              <p className="alertMessage">パスワードが入力されていません</p>
            ) : (
              ''
            )}
          </div>
          {errorMessageSwitch ? (
            <p className="alertMessage">
              新しいパスワードと新しいパスワード (確認) の値が違います。
            </p>
          ) : (
            ''
          )}
          <input type="submit" value="変更する" onClick={changePassword} />
        </form>
        <NavigateBtn />
        <CommonFooter />
      </div>
    </>
  );
};

export default UserPassword;
