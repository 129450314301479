import { Paragraph, TextRun } from 'docx';
import { Liabilities, LiabilitiesContent } from 'models';
import nonNullable from 'utils/nonNullable';
import { formatDateToJapaneseStr } from 'utils/dateFormat';
import circledNumbers from 'user/components/data/circledNumbers';

const liabilitiesView = (
  liabilities: Liabilities | null,
  liabilitiesContent: LiabilitiesContent[],
): Paragraph[] => {
  if (liabilities && !!liabilitiesContent.length) {
    const liabilitiesContentParagraph = liabilitiesContent
      .filter(nonNullable)
      .map((ele) => [
        new Paragraph({
          text: `${circledNumbers(ele.subNum) || 0} ${ele.name || ''}`,
          style: 'subItem',
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: `当初借入金額    ${ele.initialAmount || ''}円`,
              break: 1,
            }),
            new TextRun({
              text: `当初借入年月日    ${
                ele.initialDate ? formatDateToJapaneseStr(ele.initialDate) : ''
              }`,
              break: 1,
            }),
            new TextRun({
              text: `保証人    ${ele.guarantor || ''}`,
              break: 1,
            }),
          ],
          style: 'GrandChild',
        }),
      ])
      .flat();

    return [
      new Paragraph({
        text: `${liabilities.majorNum || 0}. 負債`,
        style: 'Default',
      }),
      ...liabilitiesContentParagraph,
      new Paragraph({ style: 'Default' }),
    ];
  }

  return [];
};

export default liabilitiesView;
