import { FC, Suspense } from 'react';
import { useQuery } from 'react-query';
import { Navigate } from 'react-router-dom';
import { AssignStatus } from 'models';
import { GetUser } from 'domains/cognite/services/manageUser';
import { QueryAssignRelationshipFromClientFilteredStatus } from 'domains/cognite/services/manageAssignRelationship';
import { getOneExecutorCatch } from 'domains/apigateway/services/get-Executor';
import { logger } from 'utils/logger';
import WillHeader from '../organisms/willHeader';
import ChatContent from '../organisms/chatContent';
import './chat.css';

const Chat: FC = () => {
  const { data: user } = useQuery(['authenticated_user'], GetUser);
  if (!user) {
    logger.error(user);
    throw Error;
  }

  const { data: relationshipData } = useQuery(
    [user.attributes.sub, 'assignee', 'relationship', 'chat'],
    () =>
      QueryAssignRelationshipFromClientFilteredStatus(
        user.attributes.sub,
        AssignStatus.APPROVAL,
      ),
    {
      cacheTime: 500,
    },
  );

  const assigneeId = relationshipData ? relationshipData[0].assigneeId : '';

  const { data: assignee } = useQuery(
    ['assinee', AssignStatus.APPROVAL],
    () => getOneExecutorCatch(assigneeId),
    {
      cacheTime: 500,
    },
  );

  if (!assignee) {
    return <Navigate to="/user" />;
  }

  return (
    <>
      <WillHeader title="チャット" />
      <Suspense fallback={<div>...読み込み中</div>}>
        <ChatContent assignee={assignee} clientId={user.attributes.sub} />
      </Suspense>
    </>
  );
};

export default Chat;
