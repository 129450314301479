/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import { FC, useState, ChangeEvent } from 'react';
import { useQuery } from 'react-query';
import { useQueryPropertiesQuick } from 'hooks';
import { AllocationProperties } from 'models';
import { GetAllocationReceiver } from 'domains/cognite/services/manageAllocationReceiver';
import {
  MutateAllocation,
  QueryAllocation,
} from 'domains/cognite/services/manageAllocation';
import { useNavigate } from 'react-router-dom';
import { GetUser } from 'domains/cognite/services/manageUser';
import { logger } from 'utils/logger';
import { GetDepositsContentByParent } from 'domains/cognite/services/manageDepositsContent';
import { GetRealEstateContentByParent } from 'domains/cognite/services/manageRealEstateContent';
import { GetStockContentByParent } from 'domains/cognite/services/manageStockContent';
import { GetLifeInsuranceContentByParent } from 'domains/cognite/services/manageLifeInsuranceContent';
import { GetBondsContentByParent } from 'domains/cognite/services/manageBondsContent';
import { GetLiabilitiesContentByParent } from 'domains/cognite/services/manageLiabilitiesContent';
import Dialog from 'common/apply/organisms/dialog';
import {
  DeleteAllocationProperties,
  GetAllocationProperties,
  DeleteAllocationPropertiesById,
  MutateAllocationPropertiesAndGet,
} from 'domains/cognite/services/manageAllocationProperties';
import WillHeader from '../organisms/willHeader';
import RequireNextReturn from '../molecules/requireNextReturn';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import PropertyAllocationDescription from '../templates/propertyAllocationDescription';
import AccordionTemp from '../templates/accordionTemp';
import PageDescriptionPc from '../organisms/pageDescriptionPc';
import pageData from '../data/pageData';
import PcDescriptionBackImg from '../images/PcPropertyAllocationBack.svg';
import './propertyAllocation.css';

const PropertyRecipient = '受取人を複数名入力できます。';
const PropertyRecipientLiabilities = '負担する人を複数名入力できます。';
const PropertyRecipientLifeInsurance =
  '生命保険の受取人と受取割合は保険証券に記載されています。保険証券の記載と異なる受取人と受取割合を記載すると、遺言で変更したものとみなされますのでご注意ください。';

const PropertyAllocation: FC = () => {
  const navigate = useNavigate();
  const {
    data: user,
    isLoading,
    isFetching,
  } = useQuery(['authenticated_user'], GetUser);
  const descriptionPc = <>{pageData[20].description}</>;

  const { data: queryProperties } = useQueryPropertiesQuick('properties', user);

  const { data: allocationPropertiesDataByQuery } = useQuery(
    ['allocationReceiverData', 'propertyAllocation'],
    GetAllocationProperties,
    {
      cacheTime: 200,
    },
  );

  const allocationPropertiesData =
    allocationPropertiesDataByQuery === undefined
      ? []
      : allocationPropertiesDataByQuery;

  const [isDialogShow, setIsDialogShow] = useState(false);

  const toggleDialog = () => {
    setIsDialogShow(!isDialogShow);
  };

  const realEstate =
    queryProperties && queryProperties.realEstate
      ? queryProperties.realEstate
      : null;
  const deposits =
    queryProperties && queryProperties.deposits
      ? queryProperties.deposits
      : null;
  const stock =
    queryProperties && queryProperties.stock ? queryProperties.stock : null;
  const lifeInsurance =
    queryProperties && queryProperties.lifeInsurance
      ? queryProperties.lifeInsurance
      : null;
  const bonds =
    queryProperties && queryProperties.bonds ? queryProperties.bonds : null;
  const liabilities =
    queryProperties && queryProperties.liabilities
      ? queryProperties.liabilities
      : null;

  const { data: realEstateContent } = useQuery(
    ['realEstate_contentData', { realEstate: queryProperties?.realEstate }],
    () => GetRealEstateContentByParent(realEstate),
  );

  const createRealEstate = () => {
    const realEstateArr: AllocationProperties[] = realEstateContent
      ? realEstateContent.map((ele) => {
          const randomId = Math.random().toString();
          const realEstateArrContent = {
            id: randomId,
            contentId: ele.id,
            category: '不動産',
            majorNum: realEstate ? realEstate?.majorNum : 0,
            subNum: ele.subNum,
            ratio: null,
            receiverName: '',
            allocationReceiver: undefined,
          };

          return realEstateArrContent;
        })
      : [];

    return realEstateArr;
  };

  const initialAllocationPropertiesTypeRealEstate = createRealEstate();

  const { data: depositsContent } = useQuery(
    ['deposits_contentData', { deposits: queryProperties?.deposits }],
    () => GetDepositsContentByParent(deposits),
  );

  const createDeposits = () => {
    const depositsArr: AllocationProperties[] = depositsContent
      ? depositsContent.map((ele) => {
          const randomId = Math.random().toString();
          const depositsArrContent = {
            id: randomId,
            contentId: ele.id,
            category: '預貯金',
            majorNum: deposits ? deposits?.majorNum : 0,
            subNum: ele.subNum,
            ratio: null,
            receiverName: '',
            allocationReceiver: undefined,
          };

          return depositsArrContent;
        })
      : [];

    return depositsArr;
  };

  const initialAllocationPropertiesTypeDeposits = createDeposits();

  const { data: stockContent } = useQuery(
    ['stock_contentData', { stock: queryProperties?.stock }],
    () => GetStockContentByParent(stock),
    {
      cacheTime: 200,
    },
  );

  const createStock = () => {
    const stockArr: AllocationProperties[] = stockContent
      ? stockContent.map((ele) => {
          const randomId = Math.random().toString();
          const stockArrContent = {
            id: randomId,
            contentId: ele.id,
            category: '株式・債券',
            majorNum: stock ? stock.majorNum : 0,
            subNum: ele.subNum,
            ratio: null,
            receiverName: '',
            allocationReceiver: undefined,
          };

          return stockArrContent;
        })
      : [];

    return stockArr;
  };

  const initialAllocationPropertiesTypeStock = createStock();

  const { data: lifeInsuranceContent } = useQuery(
    [
      'lifeInsurance_contentData',
      { lifeInsurance: queryProperties?.lifeInsurance },
    ],
    () => GetLifeInsuranceContentByParent(lifeInsurance),
  );

  const createLifeInsurance = () => {
    const lifeInsuranceArr: AllocationProperties[] = lifeInsuranceContent
      ? lifeInsuranceContent.map((ele) => {
          const randomId = Math.random().toString();
          const lifeInsuranceArrContent = {
            id: randomId,
            contentId: ele.id,
            category: '生命保険',
            majorNum: lifeInsurance ? lifeInsurance.majorNum : 0,
            subNum: ele.subNum,
            ratio: null,
            receiverName: '',
            allocationReceiver: undefined,
          };

          return lifeInsuranceArrContent;
        })
      : [];

    return lifeInsuranceArr;
  };

  const initialAllocationPropertiesTypeLifeInsurance = createLifeInsurance();

  const { data: bondsContent } = useQuery(
    ['bonds_contentData', { bonds: queryProperties?.bonds }],
    () => GetBondsContentByParent(bonds),
  );

  const bondsLoanContent = bondsContent
    ? bondsContent.filter((ele) => ele.type === '貸付金')
    : [];
  const bondsInvestmentContent = bondsContent
    ? bondsContent.filter((ele) => ele.type === '出資金')
    : [];

  const createBondsLoan = () => {
    const bondsLoanArr: AllocationProperties[] = bondsLoanContent
      ? bondsLoanContent.map((ele) => {
          const randomId = Math.random().toString();
          const bondsLoanArrContent = {
            id: randomId,
            contentId: ele.id,
            category: '貸付金',
            majorNum: bonds ? bonds.majorNum : 0,
            subNum: ele.subNum,
            ratio: null,
            receiverName: '',
            allocationReceiver: undefined,
          };

          return bondsLoanArrContent;
        })
      : [];

    return bondsLoanArr;
  };

  const initialAllocationPropertiesTypebBondsLoan = createBondsLoan();

  const createBondsInvestment = () => {
    const bondsInvestmentArr: AllocationProperties[] = bondsInvestmentContent
      ? bondsInvestmentContent.map((ele) => {
          const randomId = Math.random().toString();
          const bondsInvestmentArrContent = {
            id: randomId,
            contentId: ele.id,
            category: '出資金',
            majorNum: bonds ? bonds.majorNum : 0,
            subNum: ele.subNum,
            ratio: null,
            receiverName: '',
            allocationReceiver: undefined,
          };

          return bondsInvestmentArrContent;
        })
      : [];

    return bondsInvestmentArr;
  };

  const initialAllocationPropertiesTypebBondsInvestment =
    createBondsInvestment();

  const { data: liabilitiesContent } = useQuery(
    ['liabilities_contentData', { liabilities: queryProperties?.liabilities }],
    () => GetLiabilitiesContentByParent(liabilities),
  );

  const createLiabilities = () => {
    const liabilitiesArr: AllocationProperties[] = liabilitiesContent
      ? liabilitiesContent.map((ele) => {
          const randomId = Math.random().toString();
          const liabilitiesArrContent = {
            id: randomId,
            contentId: ele.id,
            category: '負債',
            majorNum: liabilities ? liabilities.majorNum : 0,
            subNum: ele.subNum,
            ratio: null,
            receiverName: '',
            allocationReceiver: undefined,
          };

          return liabilitiesArrContent;
        })
      : [];

    return liabilitiesArr;
  };

  const initialAllocationPropertiesTypeLiabilities = createLiabilities();

  const initialAllocationPropertiesTypeAllArr = [
    initialAllocationPropertiesTypeRealEstate,
    initialAllocationPropertiesTypeDeposits,
    initialAllocationPropertiesTypeStock,
    initialAllocationPropertiesTypeLifeInsurance,
    initialAllocationPropertiesTypebBondsLoan,
    initialAllocationPropertiesTypebBondsInvestment,
    initialAllocationPropertiesTypeLiabilities,
  ];

  // 保存済みのデータがない場合の初期データ
  const initialAllocationPropertiesTypeAllTemplate =
    initialAllocationPropertiesTypeAllArr.flat();

  // 保存済みのallocationPropertiesDataのcontentId取得し重複削除
  const reservedAllocationPropertiesDataAllContentId =
    allocationPropertiesData?.map((ele) => ele.contentId);
  const set = new Set(reservedAllocationPropertiesDataAllContentId);
  const reservedAllocationPropertiesDataContentId = [...set];

  // 保存済みのcontentIdがある初期データを削除
  const initialAllocationPropertiesAfterDelete =
    initialAllocationPropertiesTypeAllTemplate
      .map((ele) => {
        if (reservedAllocationPropertiesDataContentId.includes(ele.contentId)) {
          return [];
        }

        return ele;
      })
      .flat();

  const initialAllocationPropertiesAfterAdd = [
    ...initialAllocationPropertiesAfterDelete,
    allocationPropertiesData,
  ];

  const initialAllocationProperties =
    initialAllocationPropertiesAfterAdd.flat();

  const [allocationProperties, setAllocationProperties] = useState<
    AllocationProperties[]
  >(initialAllocationProperties);

  const updateAllocationProperties = (
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    target: string | number,
  ) => {
    const newAllocationProperties = allocationProperties.map((ele) => {
      if (ele.id === subjectKey) {
        return {
          id: ele.id,
          contentId: ele.contentId,
          category: ele.category,
          majorNum: ele.majorNum,
          subNum: ele.subNum,
          ratio:
            target === 'ratio'
              ? Number(event.target.value)
              : ele.ratio === null || ele.ratio === undefined
              ? null
              : ele.ratio,
          receiverName:
            target === 'receiverName'
              ? event.target.value
              : ele.receiverName === null || ele.receiverName === undefined
              ? ''
              : ele.receiverName,
          allocationReceiver: ele.allocationReceiver,
        };
      }

      return {
        id: ele.id,
        contentId: ele.contentId,
        category: ele.category,
        majorNum: ele.majorNum,
        subNum: ele.subNum,
        ratio: ele.ratio,
        receiverName: ele.receiverName,
        allocationReceiver: ele.allocationReceiver,
      };
    });

    return setAllocationProperties(newAllocationProperties);
  };

  const addCard = (
    contentId: string,
    category: string,
    majorNum: number,
    subNum: number,
  ) => {
    const randomId = Math.random().toString();
    const addAllocationProperties = {
      id: randomId,
      contentId,
      category,
      majorNum,
      subNum,
      ratio: null,
      receiverName: '',
      allocationReceiver: undefined,
    };

    setAllocationProperties([...allocationProperties, addAllocationProperties]);
  };

  const deleteCard = (deleteSubjectId: string) => {
    const newAllocationPropertiesAfterDelete = allocationProperties.filter(
      (ele) => ele.id !== deleteSubjectId,
    );

    return setAllocationProperties(newAllocationPropertiesAfterDelete);
  };

  const cardDeleteAll = async () => {
    await DeleteAllocationProperties();
    const updateData = await GetAllocationProperties();

    setAllocationProperties(updateData);

    window.location.reload();
  };

  const allocationPropertiesTypeRealEstate = allocationProperties.filter(
    (ele1) => ele1.category === '不動産',
  );

  const allocationPropertiesTypeDeposits = allocationProperties.filter(
    (ele) => ele.category === '預貯金',
  );

  const allocationPropertiesTypeStock = allocationProperties.filter(
    (ele) => ele.category === '株式・債券',
  );

  const allocationPropertiesTypeLifeInsurance = allocationProperties.filter(
    (ele) => ele.category === '生命保険',
  );

  const allocationPropertiesTypeBondsLoan = allocationProperties.filter(
    (ele) => ele.category === '貸付金',
  );

  const allocationPropertiesTypeBondsInvestment = allocationProperties.filter(
    (ele) => ele.category === '出資金',
  );

  const allocationPropertiesTypeLiabilities = allocationProperties.filter(
    (ele) => ele.category === '負債',
  );

  const realEstateCheckType = (type: string) =>
    realEstate &&
    realEstateContent &&
    realEstateContent.some((ele) => ele && ele.type === type);

  const realEstateTypeArray = (type: string) => {
    const realEstateTypeArrayContent = realEstateContent
      ? realEstateContent?.filter((e) => e.type === type)
      : [];

    const realEstateTypeArrayContentId = realEstateTypeArrayContent.map(
      (ele) => ele.id,
    );

    const realEstateCheck = allocationPropertiesTypeRealEstate.filter(
      (e) =>
        realEstateTypeArrayContentId.includes(
          e.contentId ? e.contentId : '',
        ) === true,
    );

    const receiverNameCheck = realEstateCheck.map((ele) => ele.receiverName);

    const receiverNameCheckBoolean = !receiverNameCheck.includes('');

    return receiverNameCheckBoolean;
  };

  const bondsCheckType = (type: string) =>
    bonds &&
    bondsContent &&
    bondsContent.some((ele) => ele && ele.type === type);

  if (!user) {
    if (isLoading || isFetching) {
      return <p>読み込み中...</p>;
    }

    navigate('/');

    return <p>読み込み中...</p>;
  }

  const save = async (link: string) => {
    const reservedAllocationProperties = await GetAllocationProperties();
    const allocationPropertiesId = allocationProperties.map((ele) => ele.id);
    reservedAllocationProperties.map((ele) => {
      if (allocationPropertiesId.includes(ele.id) === false) {
        return DeleteAllocationPropertiesById(ele.id);
      }

      return ele;
    });

    const requireCheckBooleanArr = allocationProperties.map((ele) => {
      const requireCheckBoolean = !!(
        (ele.ratio !== null && ele.receiverName !== '') ||
        (ele.ratio === null && ele.receiverName === '') === true
      );

      return requireCheckBoolean;
    });

    if (requireCheckBooleanArr.includes(false)) {
      window.alert('必須項目を入力してください');
    } else {
      const allocation = await QueryAllocation(user.attributes.sub);
      const allocationData = allocation || '';
      const id = allocationData ? allocationData.id : '';
      const allocationReceiver = await GetAllocationReceiver();
      const reserveAllocationProperties = allocationProperties.filter(
        (ele) => ele.ratio !== null && ele.receiverName !== '',
      );

      // todo直し
      const newContent = await Promise.all(
        reserveAllocationProperties.map(async (ele) => {
          const selectedReceiverData = allocationReceiver.find(
            (e) => e.name === ele.receiverName,
          );
          const selectedReceiver = selectedReceiverData || undefined;
          const contentItem = await MutateAllocationPropertiesAndGet(
            ele.id,
            ele.contentId || '',
            ele.category || '',
            ele.majorNum || 0,
            ele.subNum || 0,
            ele.ratio || null,
            ele.receiverName || '',
            selectedReceiver,
          );

          return contentItem;
        }),
      );

      logger.info(newContent);

      const instance = {
        userId: user.attributes.sub,
        allocationReceiver,
        inputLegalHeirFlag: allocationData.inputLegalHeirFlag,
        inputRecipientFlag: allocationData.inputRecipientFlag,
        noCheck: allocationData.noCheck,
      };

      await MutateAllocation(id, instance);
      navigate(link);
    }
  };

  const onClickFunc = (link: string) => {
    navigate(link);
  };

  return (
    <>
      {isDialogShow ? (
        <Dialog
          dialogText="入力内容を削除しますがよろしいですか？"
          dialogYesFunc={cardDeleteAll}
          dialogNoFunc={toggleDialog}
        />
      ) : (
        ''
      )}
      <WillHeader title="相続財産の割り振り" />
      <div className="sp-only">
        <PropertyAllocationDescription />
      </div>
      <div className="propertyAllocationContent">
        {realEstate && !realEstate.noCheck ? (
          <div>
            <p>不動産</p>
            <div>
              {realEstateCheckType('土地') ? (
                <div>
                  <AccordionTemp
                    id={1}
                    title="土地"
                    text={PropertyRecipient}
                    realEstate={realEstate}
                    realEstateContent={realEstateContent}
                    filter="土地"
                    realEstateReceiverData={allocationPropertiesTypeRealEstate}
                    defaultisAccordionOpen={realEstateTypeArray('土地')}
                    updateAllocationProperties={updateAllocationProperties}
                    deleteCard={deleteCard}
                    addCard={addCard}
                  />
                </div>
              ) : (
                <></>
              )}
              {realEstateCheckType('建物戸建て') ? (
                <div>
                  <AccordionTemp
                    id={2}
                    title="建物戸建て"
                    text={PropertyRecipient}
                    realEstate={realEstate}
                    realEstateContent={realEstateContent}
                    filter="建物戸建て"
                    realEstateReceiverData={allocationPropertiesTypeRealEstate}
                    defaultisAccordionOpen={realEstateTypeArray('建物戸建て')}
                    updateAllocationProperties={updateAllocationProperties}
                    deleteCard={deleteCard}
                    addCard={addCard}
                  />
                </div>
              ) : (
                <></>
              )}
              {realEstateCheckType('建物マンション（１棟）') ? (
                <div>
                  <AccordionTemp
                    id={3}
                    title="建物マンション（１棟）"
                    text={PropertyRecipient}
                    realEstate={realEstate}
                    realEstateContent={realEstateContent}
                    filter="建物マンション（１棟）"
                    realEstateReceiverData={allocationPropertiesTypeRealEstate}
                    defaultisAccordionOpen={realEstateTypeArray(
                      '建物マンション（１棟）',
                    )}
                    updateAllocationProperties={updateAllocationProperties}
                    deleteCard={deleteCard}
                    addCard={addCard}
                  />
                </div>
              ) : (
                <></>
              )}
              {realEstateCheckType('建物マンション（部屋）') ? (
                <div>
                  <AccordionTemp
                    id={4}
                    title="建物マンション（部屋）"
                    text={PropertyRecipient}
                    realEstate={realEstate}
                    realEstateContent={realEstateContent}
                    filter="建物マンション（部屋）"
                    realEstateReceiverData={allocationPropertiesTypeRealEstate}
                    defaultisAccordionOpen={realEstateTypeArray(
                      '建物マンション（部屋）',
                    )}
                    updateAllocationProperties={updateAllocationProperties}
                    deleteCard={deleteCard}
                    addCard={addCard}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        {deposits && !deposits.noCheck ? (
          <div>
            <p>預貯金</p>
            <div>
              <AccordionTemp
                id={5}
                title="預貯金"
                text={PropertyRecipient}
                deposits={deposits}
                depositsContent={depositsContent}
                depositsReceiverData={allocationPropertiesTypeDeposits}
                defaultisAccordionOpen={
                  allocationPropertiesTypeDeposits.length > 0 &&
                  allocationPropertiesTypeDeposits[0].receiverName !== ''
                }
                updateAllocationProperties={updateAllocationProperties}
                deleteCard={deleteCard}
                addCard={addCard}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        {stock && !stock.noCheck ? (
          <div>
            <p>株式・債券</p>
            <div>
              <AccordionTemp
                id={6}
                title="株式・債券"
                text={PropertyRecipient}
                stock={stock}
                stockContent={stockContent}
                stockReceiverData={allocationPropertiesTypeStock}
                defaultisAccordionOpen={
                  allocationPropertiesTypeStock.length > 0 &&
                  allocationPropertiesTypeStock[0].receiverName !== ''
                }
                updateAllocationProperties={updateAllocationProperties}
                deleteCard={deleteCard}
                addCard={addCard}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        {lifeInsurance && !lifeInsurance.noCheck ? (
          <div>
            <p>生命保険</p>
            <div>
              <AccordionTemp
                id={7}
                title="生命保険"
                text={PropertyRecipientLifeInsurance}
                lifeInsurance={lifeInsurance}
                lifeInsuranceContent={lifeInsuranceContent}
                lifeInsuranceReceiverData={
                  allocationPropertiesTypeLifeInsurance
                }
                defaultisAccordionOpen={
                  allocationPropertiesTypeLifeInsurance.length > 0 &&
                  allocationPropertiesTypeLifeInsurance[0].receiverName !== ''
                }
                updateAllocationProperties={updateAllocationProperties}
                deleteCard={deleteCard}
                addCard={addCard}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        {bonds && !bonds.noCheck ? (
          <div>
            <p>債権</p>
            <div>
              {bondsCheckType('貸付金') ? (
                <AccordionTemp
                  id={8}
                  title="貸付金"
                  text={PropertyRecipient}
                  bonds={bonds}
                  bondsContent={bondsContent}
                  filter="貸付金"
                  bondsReceiverLoanData={allocationPropertiesTypeBondsLoan}
                  defaultisAccordionOpen={
                    allocationPropertiesTypeBondsLoan.length > 0 &&
                    allocationPropertiesTypeBondsLoan[0].receiverName !== ''
                  }
                  updateAllocationProperties={updateAllocationProperties}
                  deleteCard={deleteCard}
                  addCard={addCard}
                />
              ) : (
                <></>
              )}
              {bondsCheckType('出資金') ? (
                <AccordionTemp
                  id={9}
                  title="出資金"
                  text={PropertyRecipient}
                  bonds={bonds}
                  bondsContent={bondsContent}
                  filter="出資金"
                  bondsReceiverInvestmentData={
                    allocationPropertiesTypeBondsInvestment
                  }
                  defaultisAccordionOpen={
                    allocationPropertiesTypeBondsInvestment.length > 0 &&
                    allocationPropertiesTypeBondsInvestment[0].receiverName !==
                      ''
                  }
                  updateAllocationProperties={updateAllocationProperties}
                  deleteCard={deleteCard}
                  addCard={addCard}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        {liabilities && !liabilities.noCheck ? (
          <div>
            <p>負債</p>
            <div>
              <AccordionTemp
                id={10}
                title="負債"
                text={PropertyRecipientLiabilities}
                liabilities={liabilities}
                liabilitiesContent={liabilitiesContent}
                liabilitiesReceiverData={allocationPropertiesTypeLiabilities}
                defaultisAccordionOpen={
                  allocationPropertiesTypeLiabilities.length > 0 &&
                  allocationPropertiesTypeLiabilities[0].receiverName !== ''
                }
                updateAllocationProperties={updateAllocationProperties}
                deleteCard={deleteCard}
                addCard={addCard}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <RequireNextReturn
        returnFunc={async () => {
          await save('../recipient');
        }}
        nextFunc={async () => {
          await save('../confirm');
        }}
        deleteFunc={toggleDialog}
      />
      <CommonFooter onClickFunc={onClickFunc} />
      <PageDescriptionPc
        page="PropertyAllDescriptionPc"
        description={descriptionPc}
        descriptionPcImg={PcDescriptionBackImg}
      />
    </>
  );
};

export default PropertyAllocation;
