/* eslint-disable no-alert */
import { FC } from 'react';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import { MutateTopic } from 'domains/cognite/services/manageTopics';
import PutS3 from 'domains/aws/services/put-s3';
import { formatDateToCleanStr } from 'utils/dateFormat';
import TopicsViewArea from '../molcules/topicsViewArea';
import TopicsActionBtns from '../molcules/topicsActionBtns';

type Props = {
  prevPath: string;
};

type LocationState =
  | {
      id: string;
      thumbnail: string;
      file: File;
      title: string;
      content: string;
      isDraft: boolean;
    }
  | undefined;

const TopicCreateOrEditConfirmContent: FC<Props> = (props) => {
  const { prevPath } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location.state as LocationState;

  if (!locationState) {
    return <Navigate to="/admin/topics/list" replace />;
  }

  const { id, title, content, file, thumbnail } = locationState;
  const fileUrl = URL.createObjectURL(file);

  const today = new Date();
  const createdAtFormated = formatDateToCleanStr(today);

  const fix = () => {
    navigate(prevPath, { state: locationState });
  };

  const post = async (isDraft: boolean) => {
    const instance = {
      title,
      content,
      isDraft,
      thumbnail,
    };
    await MutateTopic(id, instance);
    await PutS3(thumbnail, file, 'public');
    const alertMessage = isDraft ? '下書き保存しました' : '投稿しました';
    window.alert(alertMessage);
    navigate('/admin/topics/list', {
      state: {
        mutated: true,
      },
    });
  };

  return (
    <>
      <TopicsViewArea
        updatedAt={createdAtFormated}
        title={title}
        imageUrl={fileUrl}
        content={content}
        isDraft={false}
      />
      <TopicsActionBtns
        firstText="修正する"
        firstFunc={fix}
        secondText="下書き保存"
        secondFunc={() => post(true)}
        thirdText="投稿する"
        thirdFunc={() => post(false)}
      />
    </>
  );
};

export default TopicCreateOrEditConfirmContent;
