/* eslint-disable @typescript-eslint/no-non-null-assertion */

/* eslint-disable no-unused-expressions */
import { FC, useState, useEffect, useRef, FormEvent, ChangeEvent } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, Link } from 'react-router-dom';
import { DataStore } from 'aws-amplify';
import { Message } from 'models';
import { useProfileIcon } from 'hooks';
import { GetUser } from 'domains/cognite/services/manageUser';
import {
  GetChatRoomFromClient,
  listMessage,
  CreateMessage,
  alreadyReadMesssage,
} from 'domains/cognite/services/manageChat';
import { Executor } from 'domains/apigateway/services/get-Executor';
import {
  sendEmailForChat,
  SendEmailForChatReqBody,
} from 'domains/apigateway/services/sendEmail';
import { logger } from 'utils/logger';
import defaultProfileIcon from '../images/defaultProfileIcon.svg';
import adminIcon from '../images/admin_icon.svg';
import chatBackAllow from '../images/chatBackAllow.svg';
import UserChatAreaPhone from '../images/userChatAreaPhone.svg';

type Props = {
  clientId: string;
  assignee: Executor;
};

const ChatContent: FC<Props> = (props) => {
  const { clientId, assignee } = props;

  const navigate = useNavigate();

  const chatAreaRef = useRef<HTMLDivElement>(null);
  const chatDummyRef = useRef<HTMLDivElement>(null);
  const chatTextareaInner = useRef<HTMLTextAreaElement | null>(null);
  const chatTextareaBtn = useRef<HTMLButtonElement | null>(null);

  const { data: user } = useQuery(['authenticated_user'], GetUser);
  if (!user) {
    logger.error(user);
    throw Error;
  }

  const { imageUrl } = useProfileIcon(defaultProfileIcon, assignee.id);

  const { data: chatRoom } = useQuery(['user_chatroom'], () =>
    GetChatRoomFromClient(),
  );

  if (!chatRoom) {
    logger.info(chatRoom);
    throw Error('chatroomが未定義です');
  }

  const { data: messages } = useQuery(
    'user_message',
    () => listMessage(chatRoom),
    { enabled: !!chatRoom },
  );

  if (messages === undefined) {
    logger.info(messages);
    throw Error('messageが未定義です');
  }

  const [input, setInput] = useState<string>('');
  const [chatMessages, setChatMessages] = useState<Message[]>(messages);

  const messagesOfMine = chatMessages.filter((ele) => ele.ownerId === clientId);

  const messagesLimitJudge = messagesOfMine.length < 5;
  const enableOrNotTextarea =
    messagesLimitJudge && chatMessages.length !== 0
      ? 'enable-textarea'
      : 'disabled-textarea';

  const scrollChatAreaToBottom = () => {
    if (chatAreaRef.current) {
      chatAreaRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    void alreadyReadMesssage(assignee.id, clientId);
  });

  useEffect(() => {
    const subscription = DataStore.observe(Message).subscribe((msg) => {
      logger.info(msg.model, msg.opType, msg.element);
      const list = async () => {
        const listmessages = await listMessage(chatRoom);
        setChatMessages(listmessages);
      };
      void list();
    });

    scrollChatAreaToBottom();

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    scrollChatAreaToBottom();
  }, [chatMessages]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await CreateMessage(clientId, assignee.id, chatRoom, input);
    scrollChatAreaToBottom();
    setInput('');

    const body: SendEmailForChatReqBody = {
      fromEmail: user.attributes.email,
      toEmail: assignee.email,
      senderType: 'user',
    };

    await sendEmailForChat(body);
  };

  const ChatTextContent = document.getElementById('chatDummyTextContent');

  const TextareaReset = () => {
    ChatTextContent ? (ChatTextContent.textContent = '') : '';
  };

  const chatOrderChange = chatMessages.slice().sort((a, b) => {
    const createdDateA = Date.parse(a.createdAt!);
    const createdDateB = Date.parse(b.createdAt!);

    if (createdDateA > createdDateB) {
      return 1;
    }

    if (createdDateA < createdDateB) {
      return -1;
    }

    return 0;
  });

  const chatTextarelimit = () => {
    if (chatTextareaInner.current?.innerHTML) {
      chatTextareaInner.current.innerHTML.length > 141
        ? chatTextareaBtn.current?.classList.add('disabled-textarea')
        : chatTextareaBtn.current?.classList.remove('disabled-textarea');
    }
  };

  const chatForceReload = () => {
    navigate('/user');
    window.location.reload();
  };

  return (
    <>
      <div className="userChat">
        <div className="userChatContent">
          <div className="userNameFixed">
            <p>{assignee.name}</p>
            {assignee.phoneNumber ? (
              <div className="userChatAreaPhone userChatAreaPhonePc">
                <a href={`tel: ${assignee.phoneNumber.replace('+81', '0')}`}>
                  <p>電話番号</p>
                  <p>{assignee.phoneNumber.replace('+81', '0')}</p>
                </a>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="user-chat-area">
            {assignee.phoneNumber ? (
              <div className="userChatAreaPhone userChatAreaPhoneSp">
                <a href={`tel: ${assignee.phoneNumber.replace('+81', '0')}`}>
                  <p>電話番号</p>
                  <p>{assignee.phoneNumber.replace('+81', '0')}</p>
                </a>
                <a href={`tel: ${assignee.phoneNumber.replace('+81', '0')}`}>
                  <img src={UserChatAreaPhone} alt="" />
                </a>
              </div>
            ) : (
              <div />
            )}
            <div className="user-chat-default-message">
              <div>
                <p className="message-content">
                  担当遺言執行者から初回メッセージをお待ちください。
                  <br />
                  初回メッセージが送信された後、返信が可能となります。
                  <br />
                  担当遺言執行者の案内に従って返信しましょう。
                  <br />
                  <br />
                  尚、メッセージが可能な回数は
                  <span className="chat-orange">お一人様5回まで</span>、
                  <br />
                  一度に送信可能な文字数は140文字となっております。
                </p>
              </div>
            </div>
            {chatOrderChange.map((ele, index) => {
              const myMesageClassName =
                ele.ownerId === clientId
                  ? 'user-my-message'
                  : 'user-other-message';

              return (
                <div
                  key={`message_${ele.id}`}
                  className={`userMessage ${myMesageClassName}`}
                  ref={index === chatMessages.length - 1 ? chatAreaRef : null}
                >
                  <div>
                    <div className="userChatPlofileIcon">
                      <img src={imageUrl} alt="" />
                    </div>
                    <div className="userChatAndName">
                      {ele.ownerId !== clientId ? (
                        <p key={`username_${ele.id}`} className="user-name">
                          {assignee.name}
                        </p>
                      ) : (
                        <></>
                      )}
                      <p
                        key={`message_content_${ele.id}`}
                        className="message-content"
                      >
                        {ele.content}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
            {!messagesLimitJudge ? (
              <div className="userMessage">
                <div>
                  <div className="userChatPlofileIcon">
                    <img src={adminIcon} alt="" />
                  </div>
                  <div className="userChatAndName ExitChatroomsMessage">
                    <p>運営</p>
                    <p className="message-content">
                      メッセージ可能上限数に達しました。
                      <br />
                      今後のやり取りについては、直接担当の遺言執行者に電話相談してください。
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="chatFormWrap">
          <form onSubmit={handleSubmit} className="chat-form">
            <div ref={chatDummyRef} id="chatDummyTextContent">
              {}
            </div>
            <textarea
              maxLength={140}
              className={enableOrNotTextarea}
              onChange={(e) => {
                handleChange(e);
                chatDummyRef.current
                  ? (chatDummyRef.current.textContent = `${e.target.value}\u200b`)
                  : null;
              }}
              value={input}
              onKeyUp={chatTextarelimit}
              ref={chatTextareaInner}
            />
            <button
              type="submit"
              ref={chatTextareaBtn}
              onClick={TextareaReset}
              disabled={!input}
            >
              送信
            </button>
          </form>
        </div>
        <div className="userChatBack chatUser-pc-only">
          <Link to="/" onClick={chatForceReload}>
            ホームへ
          </Link>
        </div>
        <Link
          className="userChatBackAllow chatUser-sp-only"
          to="/"
          onClick={chatForceReload}
        >
          <img src={chatBackAllow} alt="" />
        </Link>
      </div>
    </>
  );
};

export default ChatContent;
