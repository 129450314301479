/* eslint-disable no-unused-expressions */
import { FC, useState, useRef, FormEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { Auth, DataStore } from 'aws-amplify';
import { logger } from 'utils/logger';
import { GetGroup } from 'domains/cognite/services/manageUser';
import UserLogoHeader from 'user/components/organisms/userLogoHeader';
import descriptionBackImg from '../user/components/images/descriptionBackImg.svg';
import PcdescriptionBackImg from '../user/components/images/PcdescriptionBackImg.svg';
import CommonFooter from './apply/templates/commonFooter';
import NavigateBtn from '../user/components/atoms/navigateBtn';
import '../reset.css';
import './willSignIn.css';

const SignIn: FC = () => {
  const navigate = useNavigate();
  const [valid, setValid] = useState(true);
  const [alertEmail, setAlertEmail] = useState(false);
  const [alertPassword, setAlertPassword] = useState(false);

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const validClassName = valid ? 'validSuccess' : 'validFailed';
  const emailAlertMessage = alertEmail ? 'alertMessage' : '';
  const passwordAlertMessage = alertPassword ? 'alertMessage' : '';

  const signIn = async (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    try {
      if (emailRef.current && passwordRef.current) {
        await Auth.signIn(emailRef.current.value, passwordRef.current.value)
          .then(async () => {
            const group = await GetGroup();

            if (group !== 'User') {
              await Auth.signOut();
              throw new Error('custom error');
            }
          })
          .then(() => {
            setValid(true);
            logger.info('ログイン成功');
          })
          .then(async () => {
            await DataStore.stop();
            await DataStore.start();
          })
          .then(() => {
            navigate('/user');
            // window.location.reload();
          });
      }
    } catch (error) {
      if (!emailRef.current?.value || !passwordRef.current?.value) {
        setValid(true);
        if (!emailRef.current?.value) {
          setAlertEmail(true);
        } else {
          setAlertEmail(false);
        }
        if (!passwordRef.current?.value) {
          setAlertPassword(true);
        } else {
          setAlertPassword(false);
        }
      } else {
        setAlertEmail(false);
        setAlertPassword(false);
        setValid(false);
        logger.error('ログイン失敗');
        logger.error('error signing in', error);
      }
    }
  };

  const queryClient = useQueryClient();

  const { mutate } = useMutation(signIn, {
    onSuccess: () => {
      void queryClient.invalidateQueries(['current_auth_user']);
    },
  });

  return (
    <>
      <UserLogoHeader />
      <div className="willSignInTitle">
        <h1>【ユーザー】ログイン</h1>
        <p>遺言アプリへようこそ</p>
        <img src={descriptionBackImg} alt="" className="sp-only" />
        <img src={PcdescriptionBackImg} alt="" className="pc-only" />
      </div>
      <form className="willSignInForm">
        <div>
          <div className="willSignInWrapper">
            <p className={`validation ${validClassName}`}>
              パスワード又はメールアドレスが違います
            </p>
            <div>
              <label htmlFor="email">
                メールアドレス
                <input
                  type="email"
                  id="email"
                  ref={emailRef}
                  placeholder="メールアドレスを入力"
                />
              </label>
              <p className={`deleteMessage ${emailAlertMessage}`}>
                メールアドレスが入力されていません
              </p>
            </div>
            <div>
              <label htmlFor="password">
                パスワード
                <input
                  type="password"
                  id="password"
                  ref={passwordRef}
                  placeholder="パスワードを入力"
                />
              </label>
              <p className={`deleteMessage ${passwordAlertMessage}`}>
                パスワードが入力されていません
              </p>
            </div>
            <p>
              アカウントをお持ちでない方は
              <Link to="/signUp">新規登録</Link>
            </p>
          </div>
          <input type="submit" value="ログイン" onClick={mutate} />
          <Link
            to="/password/forget"
            className="forgetPasswordCase"
            state={{ userType: 'user' }}
          >
            パスワードを忘れた場合
          </Link>
        </div>
        <NavigateBtn />
      </form>
      <br />
      <CommonFooter />
    </>
  );
};

export default SignIn;
