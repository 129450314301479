import { FC } from 'react';
import { useParams } from 'react-router-dom';
import TopicCreateOrEditContent from '../organisms/topicCreateOrEditContent';
import TopicsTemplate from '../templates/topicsTemplate';

const TopicEdit: FC = () => {
  const { postId } = useParams();
  const inputPostId = postId || '';

  return (
    <TopicsTemplate
      subTitle="投稿編集"
      sideBtnPath="/admin/topics/list"
      sideBtnText="トピック一覧"
    >
      <TopicCreateOrEditContent
        confirmPath={`/admin/topics/edit/${inputPostId}/confirm`}
      />
    </TopicsTemplate>
  );
};

export default TopicEdit;
