import { VFC, Suspense } from 'react';
import { Route, Routes } from 'react-router';
import SignInGuard from 'auth/signInGuard';
import SignOutGuard from 'auth/signOutGuard';
import Admin from 'admin/admin';
import Spinner from 'components/molecules/Spinner';
import SignIn from 'admin/components/pages/signIn';
import ExecutorDetail from 'admin/components/pages/executorDetail';
import ExecutorList from 'admin/components/pages/executorList';
import ExecutorEdit from 'admin/components/pages/executorEdit';
import ExecutorCreate from 'admin/components/pages/executorCreate';
import UserList from 'admin/components/pages/userList';
import UserDetail from 'admin/components/pages/userSpecifics';
import UserData from 'admin/components/pages/userData';
import ChatView from 'admin/components/pages/chatView';
import UserChatView from 'admin/components/pages/userChatView';
import ChatList from 'admin/components/pages/chatList';
import ExecutorSearchData from 'admin/components/pages/executorSearchData';
import NewUserData from 'admin/components/pages/newUserData';
import AddressNewUserData from 'admin/components/pages/addressNewUserData';
import AddressUserData from 'admin/components/pages/addressUserData';
import UserTimeOnPage from 'admin/components/pages/userTimeOnPage';
import ExecutorTimeOnPage from 'admin/components/pages/executorTimeOnPage';
import ExecutorPageView from 'admin/components/pages/executorPageView';
import NewsMessage from 'admin/components/pages/newsMessage';
import TopicsList from 'admin/components/pages/topicsList';
import TopicDetail from 'admin/components/pages/topicDetail';
import TopicCreate from 'admin/components/pages/topicCreate';
import TopicCreateConfirm from 'admin/components/pages/topicCreateConfirm';
import TopicEdit from 'admin/components/pages/topicEdit';
import TopicEditConfirm from 'admin/components/pages/topicEditConfirm';

const AdminRouter: VFC = () => (
  <Routes>
    <Route
      path="signIn"
      element={
        <SignOutGuard>
          <SignIn />
        </SignOutGuard>
      }
    />
    <Route
      path="/"
      element={
        <SignInGuard theGroup="Admin">
          <Admin />
        </SignInGuard>
      }
    >
      <Route
        path="executor/list"
        element={
          <Suspense fallback={<div>{Spinner}</div>}>
            <ExecutorList />
          </Suspense>
        }
      />
      <Route path="executor/detail" element={<ExecutorDetail />} />
      <Route path="executor/edit" element={<ExecutorEdit />} />
      <Route path="executor/create" element={<ExecutorCreate />} />
      <Route
        path="/data/executor/timeOnPage"
        element={<ExecutorTimeOnPage />}
      />
      <Route
        path="user/list/"
        element={
          <Suspense fallback={<div>...読み込み中</div>}>
            <UserList />
          </Suspense>
        }
      />
      <Route path="user/detail" element={<UserDetail />} />
      <Route path="user/userData" element={<UserData />} />
      <Route path="data/executor/search" element={<ExecutorSearchData />} />
      <Route path="data/chatList" element={<ChatList />} />
      <Route path="data/chatView" element={<ChatView />} />
      <Route path="data/userChatView" element={<UserChatView />} />
      <Route path="user/newUserData" element={<NewUserData />} />
      <Route path="user/addressNewUserData" element={<AddressNewUserData />} />
      <Route path="user/addressUserData" element={<AddressUserData />} />
      <Route path="user/timeOnPage" element={<UserTimeOnPage />} />
      <Route path="data/executor/pageView" element={<ExecutorPageView />} />
      <Route path="user/newsMessage" element={<NewsMessage />} />
      <Route path="topics/list" element={<TopicsList />} />
      <Route path="topics/detail/:postId" element={<TopicDetail />} />
      <Route path="topics/create" element={<TopicCreate />} />
      <Route path="topics/create/confirm" element={<TopicCreateConfirm />} />
      <Route path="topics/edit/:postId" element={<TopicEdit />} />
      <Route
        path="topics/edit/:postId/confirm"
        element={<TopicEditConfirm />}
      />
    </Route>
  </Routes>
);

export default AdminRouter;
