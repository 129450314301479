/* eslint-disable no-lonely-if */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import { FC, useRef, FormEvent, useState, RefObject, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { logger } from 'utils/logger';
// import UserLogoHeader from 'user/components/organisms/userLogoHeader';
// import NavigateBtn from 'user/components/atoms/navigateBtn';
// import CommonFooter from './apply/templates/commonFooter';
import './signUpConifirm.css';
import Dialog from './apply/organisms/dialog';

type Props = {
  emailForSignUp: string | null;
};

type States = {
  Ref: RefObject<HTMLInputElement>;
  Pass: RefObject<HTMLInputElement>;
};

const SignUpConfirm: FC<Props> = (props) => {
  const [isDialogShow, setIsDialogShow] = useState(false);

  const [isBtnClick, setisBtnIsClick] = useState(false);
  const changeBtnBoolean = (boolean: boolean) => {
    setisBtnIsClick(boolean);
  };

  const toggleDialog = (boolean: boolean) => {
    setIsDialogShow(boolean);
  };
  const { emailForSignUp } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { Ref } = location.state as States;
  const { Pass } = location.state as States;

  const codeRef = useRef<HTMLInputElement>(null);
  const [alertEmail, setAlertEmail] = useState(false);
  const emailAlertMessage = alertEmail ? 'alertMessage' : '';

  const confirmSignUp = async (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!codeRef.current?.value) {
      setAlertEmail(true);
    } else {
      if (emailForSignUp && codeRef.current && codeRef.current.value) {
        await Auth.confirmSignUp(emailForSignUp, codeRef.current.value)
          .then(async () => {
            await Auth.signIn(Ref.toString(), Pass.toString())
              .then(() => {
                logger.info('サインアップ成功');
                navigate('/user');
              })
              .catch(() => {
                window.alert('signinに失敗しました');
              });
          })
          .catch(() => {
            window.alert('新規登録に失敗しました');
          });
      } else {
        logger.error('認証失敗');
        window.alert('認証コードが入力されていません');
      }
    }
    changeBtnBoolean(false);
  };

  const resendSignUp = async (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    try {
      if (emailForSignUp) {
        await Auth.resendSignUp(emailForSignUp);
        window.alert('認証コードを再送しました');
      }
    } catch (error) {
      logger.error('認証失敗');
      logger.error('error signing in', error);
      window.alert('再送に失敗しました。');
    }
  };

  useEffect(() => {
    history.pushState(null, '', null);
    window.addEventListener('popstate', () => {
      toggleDialog(true);
    });

    return () =>
      window.removeEventListener('popstate', () => toggleDialog(true));
  }, []);

  return (
    <>
      {isDialogShow ? (
        <Dialog
          dialogText="戻る前に、認証コードを送信してください。認証コードの送信が完了しなければ、入力されたメールアドレスでの登録ができなくなります。それでも、戻りますか？"
          dialogYesFunc={() => {
            window.history.back();
          }}
          dialogNoFunc={() => {
            history.pushState(null, '', null);
            toggleDialog(false);
          }}
        />
      ) : (
        ''
      )}
      <div className="signUpConfirm">
        <h1>認証コードの送信</h1>
        <p>
          {/* 認証コードを
          <br className="sp-only" />
          入力してください */}
          入力したメールアドレス宛に
          <br className="sp-only" />
          認証コード記載のメールを送信しました。
          <br />
          メールをご確認の上、以下の入力欄に
          <br className="sp-only" />
          認証コードを入力してください。
        </p>
        <p>
          &#8251;認証コードを入力するまでこの画面から戻らないでください
          <br />
          認証コードが届かない場合は、「コード再送」を押してください
        </p>
        <form action="" className="signUpConfirmWrapper">
          <label htmlFor="code">
            認証コード
            <input
              type="text"
              id="code"
              ref={codeRef}
              placeholder="認証コードを入力"
            />
          </label>
          <p className={`deleteMessage ${emailAlertMessage}`}>
            認証コードが入力されていません
          </p>

          {isBtnClick ? (
            <input
              disabled
              type="submit"
              value="認証する"
              onClick={confirmSignUp}
            />
          ) : (
            <input
              type="submit"
              value="認証する"
              onClick={async (e) => {
                changeBtnBoolean(true);
                await confirmSignUp(e);
              }}
            />
          )}

          <input type="submit" value="コード再送" onClick={resendSignUp} />
        </form>
        {/* <NavigateBtn /> */}
        {/* <div className="pc-only">
          <CommonFooter />
        </div> */}
      </div>
    </>
  );
};

export default SignUpConfirm;
