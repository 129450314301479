export interface Prefecture {
  id: number;
  label: string;
  value: string;
}

export const PrefectureArray: Prefecture[] = [
  { id: 1, label: '北海道', value: 'hokkaido' },
  { id: 2, label: '青森県', value: 'aomori' },
  { id: 3, label: '岩手県', value: 'iwate' },
  { id: 4, label: '宮城県', value: 'miyagi' },
  { id: 5, label: '秋田県', value: 'akita' },
  { id: 6, label: '山形県', value: 'yamagata' },
  { id: 7, label: '福島県', value: 'fukushima' },
  { id: 8, label: '茨城県', value: 'ibaraki' },
  { id: 9, label: '栃木県', value: 'tochigi' },
  { id: 10, label: '群馬県', value: 'gunma' },
  { id: 11, label: '埼玉県', value: 'saitama' },
  { id: 12, label: '千葉県', value: 'chiba' },
  { id: 13, label: '東京都', value: 'tokyo' },
  { id: 14, label: '神奈川県', value: 'kanagawa' },
  { id: 15, label: '新潟県', value: 'niigata' },
  { id: 16, label: '富山県', value: 'toyama' },
  { id: 17, label: '石川県', value: 'ishikawa' },
  { id: 18, label: '福井県', value: 'fukui' },
  { id: 19, label: '山梨県', value: 'yamanashi' },
  { id: 20, label: '長野県', value: 'nagano' },
  { id: 21, label: '岐阜県', value: 'gifu' },
  { id: 22, label: '静岡県', value: 'shizuoka' },
  { id: 23, label: '愛知県', value: 'aichi' },
  { id: 24, label: '三重県', value: 'mie' },
  { id: 25, label: '滋賀県', value: 'shiga' },
  { id: 26, label: '京都府', value: 'kyoto' },
  { id: 27, label: '大阪府', value: 'osaka' },
  { id: 28, label: '兵庫県', value: 'hyogo' },
  { id: 29, label: '奈良県', value: 'nara' },
  { id: 30, label: '和歌山県', value: 'wakayama' },
  { id: 31, label: '鳥取県', value: 'tottori' },
  { id: 32, label: '島根県', value: 'shimane' },
  { id: 33, label: '岡山県', value: 'okayama' },
  { id: 34, label: '広島県', value: 'hiroshima' },
  { id: 35, label: '山口県', value: 'yamaguchi' },
  { id: 36, label: '徳島県', value: 'tokushima' },
  { id: 37, label: '香川県', value: 'kagawa' },
  { id: 38, label: '愛媛県', value: 'ehime' },
  { id: 39, label: '高知県', value: 'kochi' },
  { id: 40, label: '福岡県', value: 'fukuoka' },
  { id: 41, label: '佐賀県', value: 'saga' },
  { id: 42, label: '長崎県', value: 'nagasaki' },
  { id: 43, label: '熊本県', value: 'kumamoto' },
  { id: 44, label: '大分県', value: 'oita' },
  { id: 45, label: '宮崎県', value: 'miyazaki' },
  { id: 46, label: '鹿児島県', value: 'kagoshima' },
  { id: 47, label: '沖縄県', value: 'okinawa' },
];
