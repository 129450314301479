/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { VFC, Suspense } from 'react';
import { Route, Routes } from 'react-router';
import SignInGuard from 'auth/signInGuard';
// これはファイル置く場所考える
import Spinner from 'components/molecules/Spinner';

import WillQuestion from 'common/components/pages/willQuestion';
import CompanyInfo from 'common/components/pages/companyInfo';
// import ExecutorPolicy from 'common/components/pages/executorPolicy';
import ContactThanks from 'common/components/pages/contactThanks';

import User from 'user/user';
import UserProfile from 'user/components/pages/userProfile';
import UserEmail from 'user/components/pages/userEmail';
import UserEmailConfirm from 'user/components/pages/userEmailConfirm';
import UserPassword from 'user/components/pages/userPassword';
import Delete from 'user/components/pages/delete';
import Home from 'user/components/pages/home';
import WillInfo from 'user/components/pages/willInfo';
import RealEstate from 'user/components/pages/realEstate';
import MovingProperty from 'user/components/pages/movingProperty';
import Deposits from 'user/components/pages/deposits';
import Stock from 'user/components/pages/stock';
import LifeInsurance from 'user/components/pages/lifeInsurance';
import Bonds from 'user/components/pages/bonds';
import Liabilities from 'user/components/pages/liabilities';
import LegalHeir from 'user/components/pages/legalHeir';
import Recipient from 'user/components/pages/recipient';
import PropertyAllocation from 'user/components/pages/propertyAllocation';
import Confirm from 'user/components/pages/confirm';
// import PdfPreview from 'user/components/pages/pdfPreview';
import ExecutorAllocation from 'user/components/pages/executorAllocation';
import ExecutorSearch from 'user/components/pages/executorSearch';
import ExecutorSearchResult from 'user/components/pages/executorSearchResult';
import ExecutorRegister from 'user/components/pages/executorRegister';
import ExecutorCharge from 'user/components/pages/executorCharge';
import ExecutorEdit from 'user/components/pages/executorEdit';
import ExecutorChange from 'user/components/pages/executorChange';
import ExecutorAwaitingApproval from 'user/components/pages/executorAwaitingApproval';
import ExecutorApproval from 'user/components/pages/executorApproval';
import Chat from 'user/components/pages/chat';
import Contact from 'common/contact';
import ContactConfirm from 'common/contactConfirm';
import WillPolicy from 'common/components/pages/willPolicy';
// import DeleteThanks from 'common/components/pages/deleteThanks';
import FrontTemporaryEnter from 'common/frontTemporaryEnter';
import ChangeMail from 'executor/components/pages/changeMail';
import ChangeEmailConfirm from 'executor/components/pages/changeEmailConfirm';
import ChangePass from 'executor/components/pages/changePass';
// import ExecutorPrivacyPolicy from 'common/components/pages/executorPrivacyPolicy';
import WillPrivacyPolicy from 'common/components/pages/willPrivacyPolicy';

const UserRouter: VFC = () => (
  <Routes>
    <Route
      path="/"
      element={
        <SignInGuard theGroup="User">
          <User />
        </SignInGuard>
      }
    >
      <Route
        path=""
        element={
          <Suspense fallback={<div>{Spinner}</div>}>
            <Home />
          </Suspense>
        }
      />
      <Route path="profile" element={<UserProfile />} />
      <Route
        path="email"
        element={
          <Suspense fallback={<div>{Spinner}</div>}>
            <UserEmail />
          </Suspense>
        }
      />
      <Route
        path="email/confirm"
        element={
          <Suspense fallback={<div>{Spinner}</div>}>
            <UserEmailConfirm />
          </Suspense>
        }
      />
      <Route path="password" element={<UserPassword />} />
      <Route path="willInfo" element={<WillInfo />} />
      <Route
        path="realEstate"
        element={
          <Suspense fallback={<div>{Spinner}</div>}>
            <RealEstate />
          </Suspense>
        }
      />
      <Route
        path="movingProperty"
        element={
          <Suspense fallback={<div>{Spinner}</div>}>
            <MovingProperty />
          </Suspense>
        }
      />
      <Route
        path="deposits"
        element={
          <Suspense fallback={<div>{Spinner}</div>}>
            <Deposits />
          </Suspense>
        }
      />
      <Route
        path="stock"
        element={
          <Suspense fallback={<div>{Spinner}</div>}>
            <Stock />
          </Suspense>
        }
      />
      <Route
        path="lifeInsurance"
        element={
          <Suspense fallback={<div>{Spinner}</div>}>
            <LifeInsurance />
          </Suspense>
        }
      />
      <Route
        path="bonds"
        element={
          <Suspense fallback={<div>{Spinner}</div>}>
            <Bonds />
          </Suspense>
        }
      />
      <Route
        path="liabilities"
        element={
          <Suspense fallback={<div>{Spinner}</div>}>
            <Liabilities />
          </Suspense>
        }
      />
      <Route
        path="legalHeir"
        element={
          <Suspense fallback={<div>{Spinner}</div>}>
            <LegalHeir />
          </Suspense>
        }
      />
      <Route
        path="recipient"
        element={
          <Suspense fallback={<div>{Spinner}</div>}>
            <Recipient />
          </Suspense>
        }
      />
      <Route
        path="propertyallocation"
        element={
          <Suspense fallback={<div>{Spinner}</div>}>
            <PropertyAllocation />
          </Suspense>
        }
      />
      <Route
        path="confirm"
        element={
          <Suspense fallback={<div>{Spinner}</div>}>
            <Confirm />
          </Suspense>
        }
      />
      <Route path="executorallocation" element={<ExecutorAllocation />} />
      <Route path="executorsearch" element={<ExecutorSearch />} />
      <Route path="executorsearchresult" element={<ExecutorSearchResult />} />
      <Route path="executorregister" element={<ExecutorRegister />} />
      <Route path="executorcharge" element={<ExecutorCharge />} />
      <Route path="executoredit" element={<ExecutorEdit />} />
      <Route path="executorchange" element={<ExecutorChange />} />
      <Route
        path="executorawaitingapproval"
        element={<ExecutorAwaitingApproval />}
      />
      <Route path="executorapproval" element={<ExecutorApproval />} />
      <Route
        path="chat"
        element={
          <Suspense fallback={<Spinner size="large" />}>
            <Chat />
          </Suspense>
        }
      />

      <Route path="question" element={<WillQuestion />} />
      <Route path="companyinfo" element={<CompanyInfo />} />
      <Route path="contact" element={<Contact />} />
      <Route path="contact/confirm" element={<ContactConfirm />} />
      <Route path="email" element={<ChangeMail />} />
      <Route path="email/confirm" element={<ChangeEmailConfirm />} />
      {/* <Route path="executorpolicy" element={<ExecutorPolicy />} /> */}
      <Route path="contactThanks" element={<ContactThanks />} />
      <Route path="password" element={<ChangePass />} />
      <Route path="willpolicy" element={<WillPolicy />} />
      <Route path="willprivacypolicy" element={<WillPrivacyPolicy />} />
      {/* <Route path="executorprivacypolicy" element={<ExecutorPrivacyPolicy />} /> */}
      {/* <Route path="delete/thanks" element={<DeleteThanks />} /> */}
      <Route path="delete" element={<Delete />} />
      <Route path="front_temporary_enter" element={<FrontTemporaryEnter />} />
    </Route>
  </Routes>
);
export default UserRouter;
