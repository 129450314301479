import { FC } from 'react';
import { Link } from 'react-router-dom';
import footerLogo from '../images/footerLogo.svg';
import './logoutFooter.css';

const LogoutFooter: FC = () => (
  <footer className="logout-footer">
    <div>
      <a href="/">
        <img src={footerLogo} alt="ロゴ" />
      </a>
      <div>
        <div>
          <Link to="/topics/list">
            <p>トピックス</p>
          </Link>
          <Link to="/question">
            <p>よくあるご質問</p>
          </Link>
          <Link to="/companyinfo">
            <p>法人概要</p>
          </Link>
          <Link to="/contact">
            <p>お問い合わせ</p>
          </Link>
        </div>
      </div>
    </div>
    <p>&copy; 2022 一社） 日本遺言執行士協会</p>
  </footer>
);

export default LogoutFooter;
