/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import Slider from 'react-slick';
import pageData from '../data/pageData';
import PageDescription from '../organisms/pageDescription';
import './executorAwaitingApprovalDescription.css';
import suitWoman1 from '../images/suit-woman1.svg';

const ExecutorAwaitingApprovalDescription: FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplayspeed: 200,
    arrows: false,
  };

  return (
    <div className="executorAwaitingApprovalDescription">
      <Slider {...settings}>
        <PageDescription
          page="realEstatePage"
          description={pageData[31].description}
          NotParamDescription="NotParamDescription"
        />
      </Slider>
      <img src={suitWoman1} alt="" />
    </div>
  );
};

export default ExecutorAwaitingApprovalDescription;
