import { FC, useState } from 'react';
import DeleteS3 from 'domains/aws/services/delete-s3';
import { DeleteAllocation } from 'domains/cognite/services/manageAllocation';
import { DeleteAllocationProperties } from 'domains/cognite/services/manageAllocationProperties';
import {
  DeleteLegalHeir,
  DeleteRecipient,
} from 'domains/cognite/services/manageAllocationReceiver';
import { DeleteBonds } from 'domains/cognite/services/manageBonds';
import { DeleteBondsContent } from 'domains/cognite/services/manageBondsContent';
import { DeleteDeposits } from 'domains/cognite/services/manageDeposits';
import { DeleteDepositsContent } from 'domains/cognite/services/manageDepositsContent';
import { DeleteLegalHeirQuestion } from 'domains/cognite/services/manageLegalHeirQuestion';
import { DeleteLiabilities } from 'domains/cognite/services/manageLiabilities';
import { DeleteLiabilitiesContent } from 'domains/cognite/services/manageLiabilitiesContent';
import { DeleteLifeInsurance } from 'domains/cognite/services/manageLifeInsurance';
import { DeleteLifeInsuranceContent } from 'domains/cognite/services/manageLifeInsuranceContent';
import { DeleteMovingProperty } from 'domains/cognite/services/manageMovingProperty';
import { DeleteProperties } from 'domains/cognite/services/manageProperties';
import { DeleteRealEstate } from 'domains/cognite/services/manageRealEstate';
import { DeleteRealEstateContent } from 'domains/cognite/services/manageRealEstateContent';
import { DeleteReceiver } from 'domains/cognite/services/manageReceiver';
import { DeleteStock } from 'domains/cognite/services/manageStock';
import { DeleteStockContent } from 'domains/cognite/services/manageStockContent';
import { DeleteWillInfo } from 'domains/cognite/services/manageWillInfo';
import { DeleteTestamentFile } from 'domains/cognite/services/manageTestamentFile';
import { useQuery } from 'react-query';
import { GetUser } from 'domains/cognite/services/manageUser';
import { logger } from 'utils/logger';
import Dialog from 'common/apply/organisms/dialog';
import './allDeleteInfo.css';

const AllDeleteInfo: FC = () => {
  const { data: user } = useQuery(['authenticated_user'], GetUser);
  if (!user) {
    logger.error(user);
    throw Error;
  }
  const [isDialogShow, setIsDialogShow] = useState(false);

  const toggleDialog = () => {
    setIsDialogShow(!isDialogShow);
  };
  const confirmDelete = async () => {
    // pdf, wordの削除
    await DeleteS3(`遺言書_${user.attributes.sub}.docx`);
    await DeleteS3(`遺言書_${user.attributes.sub}.pdf`);
    // 相続財産のデータ削除
    await DeleteProperties();
    await DeleteWillInfo();
    await DeleteRealEstateContent();
    await DeleteRealEstate();
    await DeleteMovingProperty();
    await DeleteDepositsContent();
    await DeleteDeposits();
    await DeleteStockContent();
    await DeleteStock();
    await DeleteLifeInsuranceContent();
    await DeleteReceiver();
    await DeleteLifeInsurance();
    await DeleteBondsContent();
    await DeleteBonds();
    await DeleteLiabilitiesContent();
    await DeleteLiabilities();
    // await DeleteProperties();
    // 法定相続人・受遺者のデータ削除
    await DeleteLegalHeir();
    await DeleteLegalHeirQuestion();
    await DeleteRecipient();
    // 財産割り当てのデータ削除
    await DeleteAllocationProperties();
    await DeleteAllocation();
    await DeleteTestamentFile();

    window.location.reload();
  };

  return (
    <>
      {isDialogShow ? (
        <Dialog
          dialogText="これまでに入力した全ての内容が削除されます。よろしいですか？"
          dialogYesFunc={async () => {
            await confirmDelete();

            toggleDialog();
          }}
          dialogNoFunc={toggleDialog}
        />
      ) : (
        ''
      )}
      <div className="allDeleteInfo">
        <div>
          <h2>データ削除</h2>
          <p>入力済みの内容を全て削除できます。</p>
        </div>
        <div>
          <button type="button" onClick={toggleDialog} className="allDeleteBtn">
            全てのデータを削除
          </button>
        </div>
      </div>
    </>
  );
};

export default AllDeleteInfo;
