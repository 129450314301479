import { FC } from 'react';
import { Link } from 'react-router-dom';
import './normalBtn.css';

type Props = {
  title: string;
  className: string;
  href: string;
  // func?: () => void;
};

const NormalBtn: FC<Props> = (props) => {
  const { title, className, href } = props;

  return (
    // eslint-disable-next-line no-console
    <Link to={href} className={className}>
      <p className="btnTitle">{title}</p>
    </Link>
  );
};

export default NormalBtn;
