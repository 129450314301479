/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC, useRef } from 'react';
import { useNavigate } from 'react-router';
import { UserAttributes } from 'domains/cognite/user';
import { UpdateUser } from 'domains/cognite/services/manageUser';
import PutS3 from 'domains/aws/services/put-s3';
import { logger } from 'utils/logger';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import NormalBtn from '../atoms/normalBtn';
import './executorProfileConfirmContent.css';

type Props = {
  attributes: UserAttributes;
  imageUrl: string;
  image: File | null;
};

const ExecutorProfileContent: FC<Props> = (props) => {
  const { attributes, imageUrl, image } = props;
  const iconRef = useRef<HTMLImageElement>(null);

  const navigate = useNavigate();

  const changeProfile = async () => {
    try {
      await UpdateUser(attributes);
      if (image) {
        await PutS3(`profile_${attributes.sub}`, image, 'public');
      }
      // window.location.reload();
    } catch (error) {
      logger.error('プロフィール変更失敗');
    }
    navigate('/executor/profile');
  };

  const prefecture = attributes['custom:address_prefecture']
    ? attributes['custom:address_prefecture']
    : '';

  const city = attributes['custom:address_city']
    ? attributes['custom:address_city']
    : '';

  const numbers = attributes['custom:address_numbers']
    ? attributes['custom:address_numbers']
    : '';

  const building = attributes['custom:address_building']
    ? attributes['custom:address_building']
    : '';

  const fullAddress = `${prefecture}${city}${numbers}${building}`;

  const phoneNumber = attributes.phone_number
    ? formatPhoneNumber(attributes.phone_number)
    : '';

  return (
    <div className="executorProfileConfirm">
      <div>
        <div className="plofile-confirm-icon-name">
          <img src={imageUrl} alt="" ref={iconRef} />
          <div>
            <p>氏名</p>
            <p>{attributes.name}</p>
          </div>
        </div>
        <div className="executorProfileConfirmTable">
          <div>
            <div>
              <p>生年月日</p>
              <p>{attributes.birthdate}</p>
            </div>
            <div>
              <p>住所または事務所</p>
              <p>{fullAddress}</p>
            </div>
          </div>
          <div>
            <div>
              <p>電話番号</p>
              <p>{phoneNumber}</p>
            </div>
            <div>
              <p>所持資格</p>
              <p>{attributes['custom:passport']}</p>
            </div>
          </div>
          <div>
            <div>
              <p>活動地域</p>
              <p>{attributes.zoneinfo}</p>
            </div>
            <div>
              <p>遺言執行報酬</p>
              <p>{attributes['custom:reward']}</p>
            </div>
          </div>
          <div>
            <div>
              <p>登録名簿閲覧数の通知</p>
              <p>{attributes['custom:notion']}</p>
            </div>
            <div>
              <p>紹介文</p>
              <p>{attributes['custom:introduce']}</p>
            </div>
          </div>
        </div>
        <div>
          <NormalBtn
            title="修正"
            className="normalBtn"
            href="/executor/profile/edit"
            state={{
              name: attributes.name,
              birthdate: attributes.birthdate,
              // address: attributes.address,
              defaultPrefecture: prefecture,
              defaultCity: city,
              defaultNumbers: numbers,
              defaultBuilding: building,
              phoneNumber: attributes.phone_number,
              passport: attributes['custom:passport'],
              zoneInfo: attributes.zoneinfo,
              defaultReward: attributes['custom:reward'],
              defaultNotion: attributes['custom:notion'],
              defaultIntroduce: attributes['custom:introduce'],
              sub: attributes.sub,
              initialImageUrl: imageUrl,
            }}
          />
          <button
            type="button"
            onClick={changeProfile}
            className="profileConfirmBtn"
          >
            確定
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExecutorProfileContent;
