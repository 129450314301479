import { FC } from 'react';
import UserLogoHeader from '../organisms/userLogoHeader';
import WillPrivacyPolicyContent from '../templates/willPrivacyPolicyContent';
import CommonFooter from '../../apply/templates/commonFooter';
import NavigateBtn from '../../../user/components/atoms/navigateBtn';
import './privacyPolicy.css';

const WillPrivacyPolicy: FC = () => (
  <>
    <UserLogoHeader />
    <div className="privacyPolicy">
      <WillPrivacyPolicyContent />
      <NavigateBtn />
    </div>
    <CommonFooter />
  </>
);

export default WillPrivacyPolicy;
