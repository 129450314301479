import { FC } from 'react';
import { AllocationReceiver } from 'models';
import { logger } from 'utils/logger';
import LegalHeirModal from '../organisms/legalHeirModal';
import './normalBtn.css';
import './normalBtnResponsive.css';

type Props = {
  title: string;
  func?: () => void;
  showModal: boolean;
  openModal: () => void;
  allocationReceivers: AllocationReceiver[];
};

const NormalBtnWithModal: FC<Props> = (props) => {
  const { title, func, showModal, openModal, allocationReceivers } = props;

  return (
    <>
      <button type="button" className="requireCheckBtn" onClick={func}>
        {title}
      </button>
      <LegalHeirModal
        showModal={showModal}
        openModal={openModal}
        allocationReceivers={allocationReceivers}
      />
    </>
  );
};

NormalBtnWithModal.defaultProps = {
  func: () => {
    logger.info('ボタンを押しました');
  },
};

export default NormalBtnWithModal;
