/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { FC, useState, useRef } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { CreateAssignExecutorNotOnTheList } from 'domains/cognite/services/manageAssignExecutorNotOnTheList';
import { GetUser } from 'domains/cognite/services/manageUser';
import NormalBtn from '../atoms/normalBtn';
import './executorRegisterContent.css';
import { Prefecture, PrefectureArray } from '../data/prefecture';

type cityProps = {
  prefCode: string;
  cityCode: string;
  cityName: string;
  bigCityFlag: string;
};

const ExecutorRegisterContent: FC = () => {
  const { data: user } = useQuery(['authenticated_user'], GetUser);

  if (!user) {
    throw Error('認証エラー');
  }
  const [city, setCity] = useState<cityProps[]>([]);
  const nameRef = useRef<HTMLInputElement>(null);
  const prefectureRef = useRef<HTMLSelectElement>(null);
  const citiesRef = useRef<HTMLSelectElement>(null);
  const addressRef = useRef<HTMLInputElement>(null);
  const relationshipOrpassportRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLSelectElement>(null);
  const monthRef = useRef<HTMLSelectElement>(null);
  const dayRef = useRef<HTMLSelectElement>(null);

  const [birthYear, setBirthYear] = useState(new Date().getFullYear());
  const [birthMonth, setBirthMonth] = useState(new Date().getMonth() + 1);
  const [birthDay, setBirthDay] = useState(new Date().getDay());

  const selectBirthYear = () => {
    const year = document.getElementById('year') as HTMLInputElement;
    const selectYear = Number(year?.value);
    setBirthYear(selectYear);
  };

  const selectBirthMonth = () => {
    const month = document.getElementById('month') as HTMLInputElement;
    const selectMonth = Number(month?.value);
    setBirthMonth(selectMonth);
  };

  const selectBirthDay = () => {
    const day = document.getElementById('day') as HTMLInputElement;
    const selectDay = Number(day?.value);
    setBirthDay(selectDay);
  };

  const setYear = () => {
    const list = [];
    for (let i = 1900; i <= new Date().getFullYear(); i += 1) {
      list.push(
        <option key={`year_${i}`} value={i}>
          {i}
        </option>,
      );
    }

    return list;
  };

  const setMonth = () => {
    const list = [];
    for (let i = 1; i <= 12; i += 1) {
      list.push(
        <option key={`month_${i}`} value={i}>
          {i}
        </option>,
      );
    }

    return list;
  };

  const setDay = () => {
    const list = [];
    const lastday = new Date(
      Number(birthYear),
      Number(birthMonth),
      0,
    ).getDate();
    for (let i = 1; i <= lastday; i += 1) {
      list.push(
        <option key={`day_${i}`} value={i}>
          {i}
        </option>,
      );
    }

    return list;
  };

  const getCity = async () => {
    const selectLabel = prefectureRef.current
      ? prefectureRef.current.value
      : '';
    const selectArr = PrefectureArray.find((ele) => ele.label === selectLabel);
    const selectId = selectArr ? selectArr.id : 1;
    await fetch(
      `https://opendata.resas-portal.go.jp/api/v1/cities?prefCode=${selectId}`,
      {
        headers: { 'X-API-KEY': 'yvet69MOZSiyzRoinuvNJaWjcXaqhoP118HTqciA' },
      },
    )
      .then((response) => response.json())
      .then((data) => {
        setCity(data.result);
      });
  };

  const save = async () => {
    const yearRefValue = yearRef.current ? yearRef.current.value : '';
    const monthRefValue = monthRef.current ? monthRef.current.value : '';
    const dayRefValue = dayRef.current ? dayRef.current.value : '';
    const prefectureRefValue = prefectureRef.current
      ? prefectureRef.current.value
      : '';
    const citiesRefValue = citiesRef.current ? citiesRef.current.value : '';
    const addressRefValue = addressRef.current ? addressRef.current.value : '';
    const relationshipOrpassportRefValue = relationshipOrpassportRef.current
      ? relationshipOrpassportRef.current.value
      : '';

    await CreateAssignExecutorNotOnTheList({
      userId: user.attributes.sub,
      name: nameRef.current ? nameRef.current.value : '',
      birth: `${yearRefValue}-${monthRefValue}-${dayRefValue}`,
      address: `${prefectureRefValue}${citiesRefValue}${addressRefValue}`,
      relationshipOrpassport: relationshipOrpassportRefValue,
    });
  };

  return (
    <div className="executorRegisterContent">
      <div>
        <div>
          <label htmlFor="name">
            <p>お名前</p>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="名前を入力"
              ref={nameRef}
            />
          </label>
          <label htmlFor="bank">
            <p>生年月日</p>
            <div className="executor-register-content-year">
              <select
                name="year"
                id="year"
                value={birthYear}
                ref={yearRef}
                onChange={() => {
                  selectBirthYear();
                }}
              >
                {setYear()}
              </select>
              年
            </div>
            <div className="month-day">
              <div className="executor-register-content-month">
                <select
                  name="month"
                  id="month"
                  value={birthMonth}
                  ref={monthRef}
                  onChange={selectBirthMonth}
                >
                  {setMonth()}
                </select>
                月
              </div>
              <div className="executor-register-content-day">
                <select
                  name="day"
                  id="day"
                  value={birthDay}
                  ref={dayRef}
                  onChange={() => {
                    selectBirthDay();
                  }}
                >
                  {setDay()}
                </select>
                日
              </div>
            </div>
          </label>
          <label htmlFor="bank">
            <p>住所</p>
            <div className="executor-register-content-address">
              <select
                id="prefecture"
                name="prefecture"
                onChange={async () => {
                  await getCity();
                }}
                ref={prefectureRef}
              >
                <option defaultValue="" disabled selected hidden>
                  都道府県を選択
                </option>
                {PrefectureArray.map((prefecture: Prefecture) => (
                  <option key={prefecture.id} value={prefecture.label}>
                    {prefecture.label}
                  </option>
                ))}
              </select>
              <select name="cities" id="cities" ref={citiesRef}>
                <option value="" disabled selected hidden>
                  市区町村を選択
                </option>
                {city.map((ele) => (
                  <option key={ele.cityName} value={ele.cityName}>
                    {ele.cityName}
                  </option>
                ))}
              </select>
              <div>
                <input
                  type="text"
                  id="address"
                  name="address"
                  placeholder="番地を入力"
                  ref={addressRef}
                />
              </div>
            </div>
          </label>
          <label htmlFor="relationshipOrpassport">
            <p>続柄又は資格</p>
            <input
              type="text"
              id="relationshipOrpassport"
              name="relationshipOrpassport"
              placeholder="続柄又は資格を入力"
              ref={relationshipOrpassportRef}
            />
          </label>
        </div>
      </div>
      <NormalBtn
        title="登録する"
        className="normalBtn"
        func={save}
        href="/user/executorcharge"
      />
      <Link to="/user/executorallocation" className="back-to-executor-change">
        選定方法を変更
      </Link>
    </div>
  );
};

export default ExecutorRegisterContent;
