/* eslint-disable no-param-reassign */
import { DataStore } from 'aws-amplify';
import {
  AllocationReceiver,
  AllocationProperties,
  AllocationPropertiesMetaData,
} from 'models';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ModelInit } from '@aws-amplify/datastore';
import { logger } from 'utils/logger';

export const MutateAllocationProperties = async (
  id: string,
  instance: ModelInit<AllocationProperties, AllocationPropertiesMetaData>,
): Promise<AllocationProperties> => {
  try {
    logger.info('割り振る相続財産の項目の作成または更新を開始します');
    const original = await DataStore.query(AllocationProperties, id);
    if (original) {
      const data = await DataStore.save(
        AllocationProperties.copyOf(original, (updated) => {
          updated.contentId = instance.contentId;
          updated.category = instance.category;
          updated.majorNum = instance.majorNum;
          updated.subNum = instance.subNum;
          updated.ratio = instance.ratio;
          updated.receiverName = instance.receiverName;
          updated.allocationReceiver = instance.allocationReceiver;
        }),
      );
      // logger.info(data);
      logger.info('割り振る相続財産の項目の更新が完了しました');

      return data;
    }
    const data = await DataStore.save(new AllocationProperties(instance));
    logger.info('割り振る相続財産の項目の作成が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteAllocationPropertiesById = async (
  id: string,
): Promise<void> => {
  try {
    logger.info('割り振る相続財産の項目の削除を開始します');
    const original = await DataStore.query(AllocationProperties, id);
    if (original) {
      await DataStore.delete(original);
      logger.info('割り振る相続財産の項目の削除が完了しました');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetAllocationProperties = async (): Promise<
  AllocationProperties[]
> => {
  try {
    logger.info('割り振る相続財産の項目の取得を開始します');
    const data = await DataStore.query(AllocationProperties);
    logger.info(data);
    logger.info('割り振る相続財産の項目の取得が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const QueryAllocationProperties = async (): Promise<
  AllocationProperties[]
> => {
  try {
    const queryAllocationPropertiesData = await DataStore.query(
      AllocationProperties,
    );

    const queryAllocationPropertiesResult = !queryAllocationPropertiesData
      ? []
      : queryAllocationPropertiesData;

    return queryAllocationPropertiesResult;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteAllocationProperties = async (): Promise<
  AllocationProperties[]
> => {
  try {
    logger.info('財産割り振りの全削除を開始します');
    const data = await DataStore.query(AllocationProperties);
    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);

          return data;
        }),
      );
    }
    logger.debug(data);
    logger.info('財産割り振りの全削除が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetAllocationPropertiesByParent = async (
  allocationReceiver: AllocationReceiver | null | undefined,
): Promise<AllocationProperties[]> => {
  try {
    logger.info('受遺者の項目のフェッチを開始します');
    const data = (await DataStore.query(AllocationProperties)).filter((c) => {
      if (
        c.allocationReceiver === null ||
        c.allocationReceiver === undefined ||
        !allocationReceiver
      ) {
        return false;
      }
      const allocationReceiverId = c.allocationReceiver.id;

      return allocationReceiver.id === allocationReceiverId;
    });
    logger.debug(data);
    logger.info('株式・債券の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const MutateAllocationPropertiesAndGet = async (
  id: string,
  contentId: string,
  category: string,
  majorNum: number,
  subNum: number,
  ratio: number | null,
  receiverName: string,
  allocationReceiver: AllocationReceiver | undefined,
  // instance: ModelInit<AllocationProperties, AllocationPropertiesMetaData>,
): Promise<AllocationProperties> => {
  try {
    logger.info('割り振る相続財産の項目の作成または更新を開始します');
    const original = await DataStore.query(AllocationProperties, id);
    if (original) {
      const data = await DataStore.save(
        AllocationProperties.copyOf(original, (updated) => {
          updated.contentId = contentId;
          updated.category = category;
          updated.majorNum = majorNum;
          updated.subNum = subNum;
          updated.ratio = ratio;
          updated.receiverName = receiverName;
          updated.allocationReceiver = allocationReceiver;
        }),
      );
      // logger.info(data);
      logger.info('割り振る相続財産の項目の更新が完了しました');

      return data;
    }

    const instance = {
      contentId,
      category,
      majorNum,
      subNum,
      ratio,
      receiverName,
      allocationReceiver,
    };
    const data = await DataStore.save(new AllocationProperties(instance));
    logger.info('割り振る相続財産の項目の作成が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
