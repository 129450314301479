/* eslint-disable react/require-default-props */
import { FC, useState, useEffect } from 'react';
import { GetS3 } from 'domains/aws/services/get-s3';
import { logger } from 'utils/logger';
import wordIcon from '../images/word-icon.svg';
import './executorWordDownload.css';

type Props = {
  userId: string;
  wordName?: string;
};

const ExecutorWordDownload: FC<Props> = (props) => {
  const { userId, wordName } = props;

  const [fileUrl, setFileUrl] = useState<string>('');

  useEffect(() => {
    const uploadFileAndSetUrl = async () => {
      try {
        const theFile = await GetS3(`遺言書_${userId}.docx`, 'public');
        setFileUrl(theFile);
      } catch (error) {
        logger.error('アップロード失敗');
      }
    };

    void uploadFileAndSetUrl();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <a href={fileUrl} className={wordName}>
      <img src={wordIcon} alt="" />
      Word出力
    </a>
  );
};

export default ExecutorWordDownload;
