/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import { DataStore } from 'aws-amplify';
import { Allocation, AllocationMetaData } from 'models';
import { logger } from 'utils/logger';
import { ModelInit } from '@aws-amplify/datastore';

export const CreateAllocation = async (
  instance: ModelInit<Allocation, AllocationMetaData>,
): Promise<Allocation> => {
  try {
    const data = await DataStore.save(new Allocation(instance));

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const MutateAllocation = async (
  id: string,
  instance: ModelInit<Allocation, AllocationMetaData>,
): Promise<Allocation> => {
  try {
    logger.info('相続人の作成または更新を開始します');
    const original = await DataStore.query(Allocation, id);

    if (original) {
      logger.info('相続人の更新を開始します');
      const data = await DataStore.save(
        Allocation.copyOf(original, (updated) => {
          updated.allocationReceiver = instance.allocationReceiver;
          updated.inputLegalHeirFlag = instance.inputLegalHeirFlag;
          updated.inputRecipientFlag = instance.inputRecipientFlag;
          updated.noCheck = instance.noCheck;
        }),
      );

      return data;
    }
    logger.info('相続人の作成を開始します');
    const data = await DataStore.save(new Allocation(instance));

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const QueryAllocation = async (userId: string): Promise<Allocation> => {
  try {
    const queryAllocationData = await DataStore.query(Allocation, (c) =>
      c.userId('eq', userId),
    );

    return queryAllocationData[0];
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const QueryLegalHeirAllocation = async (): Promise<Allocation[]> => {
  try {
    const queryLegalHeirAllocationData = await DataStore.query(Allocation);

    const queryLegalHeirAllocationResult =
      queryLegalHeirAllocationData && queryLegalHeirAllocationData.length !== 0
        ? queryLegalHeirAllocationData[0].inputLegalHeirFlag
          ? queryLegalHeirAllocationData
          : []
        : [];

    return queryLegalHeirAllocationResult;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const QueryRecipientAllocation = async (): Promise<Allocation[]> => {
  try {
    const queryRecipientAllocationData = await DataStore.query(Allocation);

    const queryRecipientAllocationResult =
      queryRecipientAllocationData && queryRecipientAllocationData.length !== 0
        ? queryRecipientAllocationData[0].inputRecipientFlag
          ? queryRecipientAllocationData
          : []
        : [];

    return queryRecipientAllocationResult;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetAllocation = async (): Promise<Allocation[]> => {
  try {
    logger.info('相続人のフェッチを開始します');
    const data = await DataStore.query(Allocation);
    logger.debug(data);
    logger.info('相続人のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteAllocation = async (): Promise<Allocation[]> => {
  try {
    logger.info('相続人の削除を開始します');
    const data = await DataStore.query(Allocation);
    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);

          return data;
        }),
      );
    }
    logger.debug(data);
    logger.info('相続人の削除が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
