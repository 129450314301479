import { FC, Suspense } from 'react';
import TopicsTemplate from '../templates/topicsTemplate';
import TopicDetailContent from '../organisms/topicDetailContent';

const TopicDetail: FC = () => (
  <TopicsTemplate
    subTitle="トピック詳細"
    sideBtnPath="/admin/topics/list"
    sideBtnText="トピック一覧"
  >
    <Suspense fallback={<div>...読み込み中</div>}>
      <TopicDetailContent />
    </Suspense>
  </TopicsTemplate>
);

export default TopicDetail;
