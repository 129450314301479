// import { MutateBondsLoanContentAndGet } from 'domains/cognite/services/manageBondsContent';
import { BondsContent } from 'models';
import {
  useState,
  forwardRef,
  SetStateAction,
  Dispatch,
  ChangeEvent,
} from 'react';
// import FormCardCertificate from 'molecules/formCardCertificate';
// import RadioCustomMin from 'molecules/radioCustomMin';
import InputItem from '../atoms/InputItem';
import './formContentBonds.css';

type Props = {
  name: string;
  title: string;
  yesFunc?: () => void;
  noFunc?: () => void;
  func?: () => void;
  loanOrInvestmentStorageLocation: string;
  // bondsType: string;
  defaultBondsContent: BondsContent;
  bondsId: string;
  setBondsId: Dispatch<SetStateAction<string>>;
  updateCertificateData: (e: ChangeEvent<HTMLInputElement>) => void;
  loanOrInvestmentStorageLocationYes: boolean;
  loanOrInvestmentStorageLocationNo: boolean;
};

const FormContentBonds = forwardRef<HTMLInputElement, Props>((props) => {
  const {
    name,
    title,
    yesFunc,
    noFunc,
    // func,
    loanOrInvestmentStorageLocation,
    // bondsType,
    // defaultBondsContent,
    // bondsId,
    // setBondsId,
    updateCertificateData,
    loanOrInvestmentStorageLocationYes,
    loanOrInvestmentStorageLocationNo,
  } = props;
  const [open, setOpen] = useState(loanOrInvestmentStorageLocationYes);

  // const loanStorageLocationRef = useRef<HTMLInputElement>(null!);

  // const mutateLoanData = async () => {
  //   const inputRadioAll = document.querySelectorAll('input[type="radio"]');
  //   inputRadioAll.forEach((ele) => {
  //     ele.addEventListener('wheel', (e) => {
  //       e.preventDefault();
  //     });
  //   });
  //   const newContent = await MutateBondsLoanContentAndGet(
  //     (defaultBondsContent && defaultBondsContent.id) || bondsId,
  //     defaultBondsContent && defaultBondsContent.subNum
  //       ? defaultBondsContent.subNum
  //       : 0,
  //     defaultBondsContent && defaultBondsContent.type
  //       ? defaultBondsContent.type
  //       : '',
  //     defaultBondsContent && defaultBondsContent.loanerName
  //       ? defaultBondsContent.loanerName
  //       : '',
  //     defaultBondsContent && defaultBondsContent.initialLoanAmount
  //       ? defaultBondsContent.initialLoanAmount
  //       : '',
  //     defaultBondsContent && defaultBondsContent.loanAmount
  //       ? defaultBondsContent.loanAmount
  //       : '',
  //     open,
  //     loanStorageLocationRef.current
  //       ? loanStorageLocationRef.current.value
  //       : '',
  //     defaultBondsContent && defaultBondsContent.loanDate
  //       ? defaultBondsContent.loanDate
  //       : '',
  //     defaultBondsContent && defaultBondsContent.bonds
  //       ? defaultBondsContent.bonds
  //       : undefined,
  //   );
  //   setBondsId(newContent.id);
  // };

  return (
    <>
      <div className="formContentBonds">
        <div>
          <p className="yesnomin-title">{title}</p>
          <div>
            <div>
              <input
                type="radio"
                id={`${name}1`}
                name={name}
                value="はい"
                onChange={() => setOpen(true)}
                onClick={yesFunc}
                // onBlur={mutateLoanData}
                defaultChecked={loanOrInvestmentStorageLocationYes === true}
              />
              <label htmlFor={`${name}1`} id="yes">
                はい{}
              </label>
            </div>
            <div>
              <input
                type="radio"
                id={`${name}2`}
                name={name}
                value="いいえ"
                // onBlur={mutateLoanData}
                onChange={() => setOpen(false)}
                onClick={noFunc}
                defaultChecked={loanOrInvestmentStorageLocationNo === true}
              />
              <label htmlFor={`${name}2`} id="no">
                いいえ{}
              </label>
            </div>
          </div>
        </div>
        <div
          className={open ? 'formContentBondsopen' : 'formContentBondsclose'}
        >
          <label htmlFor="certificateStorage">
            <InputItem
              title={`証書はどこに\n保管してあり\nますか`}
              title1="証書はどこに保管してありますか"
              name="optional"
            />
            <input
              type="text"
              id="certificateStorage"
              name="certificateStorage"
              placeholder="入力してください"
              // ref={loanStorageLocationRef}
              // onBlur={mutateLoanData}
              defaultValue={loanOrInvestmentStorageLocation}
              onChange={updateCertificateData}
            />
          </label>
        </div>
      </div>
    </>
  );
});

export default FormContentBonds;
