import { Paragraph, HeadingLevel, TextRun, AlignmentType } from 'docx';
import {
  AllocationReceiver,
  WillInfo,
  RealEstate,
  MovingProperty,
  Deposits,
  Stock,
  LifeInsurance,
  Bonds,
  Liabilities,
  RealEstateContent,
  DepositsContent,
  StockContent,
  LifeInsuranceContent,
  BondsContent,
  LiabilitiesContent,
  AssignExecutorNotOnTheList,
} from 'models';
import { Executor } from 'domains/apigateway/services/get-Executor';
import allocationReceiverView from './allocationReceiverView';
import allocationReceiverLiabilitiesView from './allocationReceiverLiabilitiesView';
import movingPropertyView from './movingPropertyView';
import depositsView from './depositsView';
import stockView from './stockView';
import * as variable from './variable';
import realEstateView from './realEstateView';
import lifeInsuranceView from './lifeInsuranceView';
import bondsView from './bondsView';
import liabilitiesView from './liabilitiesView';
import willinfoView from './willinfoView';
import executorView from './executorView';
import justInCase from './justInCase';

type PropertyItems = {
  willInfo: WillInfo | null;
  realEstate: RealEstate | null;
  movingProperty: MovingProperty | null;
  deposits: Deposits | null;
  stock: Stock | null;
  lifeInsurance: LifeInsurance | null;
  bonds: Bonds | null;
  liabilities: Liabilities | null;
};

export type Contents = {
  realEstateContent: RealEstateContent[];
  depositsContent: DepositsContent[];
  stockContent: StockContent[];
  lifeInsuranceContent: LifeInsuranceContent[];
  bondsContent: BondsContent[];
  liabilitiesContent: LiabilitiesContent[];
};

const sections = (
  contents: Contents,
  allocationReceiver: AllocationReceiver[],
  propertyItems: PropertyItems,
  executor: Executor | undefined,
  executorNotList: AssignExecutorNotOnTheList | undefined,
): { children: Paragraph[] }[] => {
  const {
    realEstateContent,
    depositsContent,
    stockContent,
    lifeInsuranceContent,
    bondsContent,
    liabilitiesContent,
  } = contents;

  const {
    willInfo,
    realEstate,
    movingProperty,
    deposits,
    stock,
    lifeInsurance,
    bonds,
    liabilities,
  } = propertyItems;

  // const executorAddress = executor
  //   ? `${executor.prefecture} ${executor.addressNumbers}`
  //   : '○○○○○○○○';

  // console.log('justInCase', justInCase(allocationReceiver));
  // justInCase(allocationReceiver)

  const sectionsContent = [
    {
      children: [
        // タイトル
        new Paragraph({
          text: variable.PAGE_TITLE_1,
          heading: HeadingLevel.HEADING_1,
        }),
        new Paragraph({ style: 'Default' }),

        new Paragraph({
          text: `遺言者 ${
            willInfo && willInfo.name ? willInfo.name : ''
          }は、以下の通り遺言する。`,
          style: 'Default',
          alignment: AlignmentType.LEFT,
        }),

        // 割り当て
        ...allocationReceiverView(allocationReceiver),

        // 負債割り当て
        ...allocationReceiverLiabilitiesView(allocationReceiver),

        justInCase(allocationReceiver),

        new Paragraph({ style: 'Default' }),

        // 執行者に指定
        new Paragraph({
          text: variable.rulesFrom1To8[7],
          style: 'numberedPara',
        }),

        ...executorView(executor, executorNotList),

        new Paragraph({
          children: [
            // new TextRun({
            //   text: variable.contentOfRule8[3],
            // }),
            new TextRun({
              text: variable.contentOfRule8[4],
              break: 1,
            }),
          ],
          style: 'GrandChild',
        }),

        new Paragraph({
          children: [
            new TextRun({
              text: '',
            }),
          ],
          style: 'Child',
        }),

        new Paragraph({
          children: [
            new TextRun({
              text: variable.contentOfRule8[5],
            }),
          ],
          style: 'Child',
        }),

        new Paragraph({ style: 'Default' }),

        new Paragraph({
          text: '以上',
          style: 'Default',
          alignment: AlignmentType.RIGHT,
        }),

        // 作成日
        new Paragraph({
          text: variable.signedDate,
          style: 'Default',
        }),
        new Paragraph({ style: 'Default' }),

        // 遺言者情報
        ...willinfoView(willInfo),

        // 相続財産目録
        new Paragraph({
          text: variable.PAGE_TITLE_2,
          heading: HeadingLevel.HEADING_1,
          alignment: AlignmentType.LEFT,
          pageBreakBefore: true,
        }),

        new Paragraph({ style: 'Default' }),

        // 不動産
        ...realEstateView(realEstate, realEstateContent),

        // 動産
        ...movingPropertyView(movingProperty),

        // 預貯金
        ...depositsView(deposits, depositsContent),

        // 株式・債券
        ...stockView(stock, stockContent),

        // 生命保険
        ...lifeInsuranceView(lifeInsurance, lifeInsuranceContent),

        // 債権
        ...bondsView(bonds, bondsContent),

        // 負債
        ...liabilitiesView(liabilities, liabilitiesContent),

        new Paragraph({
          text: variable.FOOTER,
          style: 'Default',
          alignment: AlignmentType.RIGHT,
        }),
      ],
    },
  ];

  return sectionsContent;
};

export default sections;
