import { FC } from 'react';
import { Link } from 'react-router-dom';
import './otherItem.css';

type Props = {
  name: string;
  href: string;
  icon: string;
};

const OtherItem: FC<Props> = (props) => {
  const { name, href, icon } = props;

  return (
    <Link to={href}>
      <div className="otherItem">
        <img src={icon} alt="" />
        <p>{name}</p>
      </div>
    </Link>
  );
};

export default OtherItem;
