import { useQuery } from 'react-query';
import { GetUser } from 'domains/cognite/services/manageUser';
import {
  getOneExecutor,
  Executor,
} from 'domains/apigateway/services/get-Executor';
import { AssignRelationship, AssignStatus } from 'models';
import { QueryAssignRelationshipFromClient } from 'domains/cognite/services/manageAssignRelationship';
// import { logger } from 'utils/logger';

type UseAssigneeInfo = (
  key: string,
  status: AssignStatus,
) => {
  data:
    | {
        assignee: Executor;
        relationship: AssignRelationship;
        clientId: string;
      }
    | undefined;
  isFetching: boolean;
  error: unknown;
};

const useAssigneeInfo: UseAssigneeInfo = (
  key: string,
  status: AssignStatus,
) => {
  const { data: user } = useQuery(['get_user'], GetUser);

  if (!user) {
    throw Error('認証エラー');
  }

  const getAssignee = async () => {
    const assigneeRelationshipArr = await QueryAssignRelationshipFromClient(
      user.attributes.sub,
    );

    if (
      assigneeRelationshipArr.length === 0 ||
      !assigneeRelationshipArr[0].assigneeId
    ) {
      return undefined;
    }

    const assigneeRelationship = assigneeRelationshipArr.find(
      (ele) => ele.status === status,
    );

    if (!assigneeRelationship) {
      return undefined;
    }

    const assignee = await getOneExecutor(assigneeRelationship.assigneeId);

    return {
      assignee,
      relationship: assigneeRelationship,
      clientId: user.attributes.sub,
    };
  };

  const { data, isFetching, error } = useQuery(['assinee', key], getAssignee, {
    cacheTime: 500,
  });

  return { data, isFetching, error };
};

export default useAssigneeInfo;
