import { FC, Suspense, ChangeEvent } from 'react';
import { AllocationProperties } from 'models';
import PlusBtn from '../atoms/plusBtn';
import AccordionCard from '../molecules/accordionCard';
import './accordionFormCard.css';

type Props = {
  title: string;
  liabilitiesCheck?: boolean;
  defaultData?: AllocationProperties[];
  updateAllocationProperties: (
    subjectKey: string,
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    target: string | number,
  ) => void;
  deleteCard: (deleteSubject: string) => void;
  contentId: string;
  category: string;
  majorNum: number;
  subNum: number;
  addCard: (
    contentId: string,
    category: string,
    majorNum: number,
    subNum: number,
  ) => void;
};

const AccordionFormCard: FC<Props> = (props) => {
  const {
    title,
    liabilitiesCheck = false,
    defaultData = [],
    updateAllocationProperties,
    deleteCard,
    contentId,
    category,
    majorNum,
    subNum,
    addCard,
  } = props;

  const addCardFunc = () => {
    addCard(contentId, category, majorNum, subNum);
  };

  return (
    <div className="AccordionForm AccordionFormCarText">
      <p>{title}</p>
      <div className="AccordionTemp">
        {defaultData.map((ele, index, theForms) => (
          <Suspense key={`sus_${ele.id}`} fallback={<div>読み込み中</div>}>
            <AccordionCard
              key={ele.id}
              title="受取人"
              form={index}
              minusBtnView={theForms.length !== 1}
              queryParam={Number(ele.id)}
              defaultDataItem={
                defaultData && !!defaultData.length
                  ? defaultData[index]
                  : undefined
              }
              liabilitiesCheck={liabilitiesCheck}
              updateAllocationProperties={updateAllocationProperties}
              subjectId={ele.id}
              deleteCard={deleteCard}
            />
          </Suspense>
        ))}
      </div>
      <button
        type="button"
        className="plusBtn-wrapper plusBtnWrapperBtn"
        onClick={addCardFunc}
      >
        <PlusBtn />
      </button>
    </div>
  );
};

AccordionFormCard.defaultProps = {
  liabilitiesCheck: false,
  defaultData: [],
};

export default AccordionFormCard;
