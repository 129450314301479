import { FC } from 'react';
import NormalBtn from '../atoms/normalBtn';
import './executorEditContent.css';

type Props = {
  href: string;
};

const ExecutorEditContent: FC<Props> = (props) => {
  const { href } = props;

  return (
    <div className="executorEdit">
      <div>
        <p>担当遺言執行者の編集</p>
        <div>
          <div>
            <label htmlFor="bank">
              名前
              <input
                type="text"
                id="bank"
                name="bank"
                placeholder="姓"
                // ref={bankRef}
                // onChange={save}
              />
              <input
                type="text"
                id="bank"
                name="bank"
                placeholder="名"
                // ref={bankRef}
                // onChange={save}
              />
            </label>
          </div>
          <div>
            <label htmlFor="bank">
              生年月日
              <input
                type="date"
                id="bank"
                name="bank"
                placeholder="姓"
                // ref={bankRef}
                // onChange={save}
              />
            </label>
          </div>
          <div>
            <label htmlFor="bank">
              住所
              <input
                type="text"
                id="bank"
                name="bank"
                placeholder=""
                // ref={bankRef}
                // onChange={save}
              />
            </label>
          </div>
          <div>
            <label htmlFor="bank">
              続柄又は資格
              <input
                type="text"
                id="bank"
                name="bank"
                placeholder=""
                // ref={bankRef}
                // onChange={save}
              />
            </label>
          </div>
          <input type="submit" name="submit" value="保存" />
          <NormalBtn title="ホームへ" className="normalBtn" href={href} />
        </div>
      </div>
    </div>
  );
};

export default ExecutorEditContent;
