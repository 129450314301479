/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/require-default-props */
/* eslint-disable no-alert */
import { FC, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { AllocationReceiver } from 'models';
import { logger } from 'utils/logger';
import RequireCheckBtn from '../atoms/requireCheckBtn';
import NormalBtnWithModal from '../atoms/normalBtnWithModal';
import './nextReturnLegalHeir.css';

type Props = {
  buttonName?: string;
  func: () => Promise<boolean>;
  deleteFunc?: () => void;
  allocationReceivers: AllocationReceiver[];
  returnFunc: () => Promise<void>;
};

const NextReturnLegalHeir: FC<Props> = (props) => {
  const { func, deleteFunc, buttonName, allocationReceivers, returnFunc } =
    props;

  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(!showModal);

  // const saveAndOpenModal = async () => {
  //   await func().then(() => {
  //     openModal();
  //   });
  // };

  const saveAndOpenModal = async () => {
    const validationResult = await func();
    if (!validationResult) {
      logger.info('save失敗');
    } else {
      openModal();
    }
  };

  const saveAndBack = async () => {
    await returnFunc();
  };

  return (
    <>
      <div className="NextReturnLegalHeir">
        <p>
          下記ボタンのいずれを押しても
          <br className="sp-only" />
          自動で保存されます
        </p>
        <div className="btnContainer_legalHeir">
          <RequireCheckBtn title="前へ" func={saveAndBack} />
          <NormalBtnWithModal
            title="次へ"
            func={saveAndOpenModal}
            showModal={showModal}
            openModal={openModal}
            allocationReceivers={allocationReceivers}
          />
        </div>
        <button
          className={`all-delete-next-returnLegalHeir ${buttonName}`}
          type="button"
          onClick={deleteFunc}
        >
          このページの内容を削除
        </button>
      </div>
    </>
  );
};

// NextReturn.defaultProps = {
//   // eslint-disable-next-line no-console
//   nextFunc: () => undefined,
// };

export default NextReturnLegalHeir;
