/* eslint-disable camelcase */
const PAGE_TITLE_1 = '遺　言　書';
const rulesFrom1To8 = [
  '遺言者は、妻○○○○（昭和00年00月00日生　東京都新宿区○○1丁目○○番○○号）に、次の財産を相続させる。',
  '遺言者は、長男○○○○（昭和00年00月00日生　東京都新宿区○○1丁目○○番○○号）に、次の財産を相続させる。',
  '遺言者は、二男○○○○（昭和00年00月00日生　東京都新宿区○○1丁目○○番○○号）に、次の財産を相続させる。',
  '遺言者は、甥○○○○（昭和00年00月00日生　東京都新宿区○○1丁目○○番○○号）に、次の財産を遺贈する。',
  '遺言者は、孫○○○○（平成00年00月00日生　東京都豊島区○○２丁目○○番○○号）に、次の財産を遺贈する。',
  '遺言者は、妻○○○○に次の債務を負担させる。また、相続財産目録に記載されていない財産または負債が発見されたときは、妻○○○○が全て相続し、または負担すること。',
  '万一、遺言者より先に死亡した者がいた場合は、その者が相続もしくは受贈すべき財産は、全て○○○○へ相続させ、または受贈させる。',
  '遺言者は、この遺言の遺言執行者として、次の者を指定する。',
];

const contentOfRule1 = [
  '相続財産目録一、①②記載の不動産',
  '相続財産目録二、①記載の動産',
  '相続財産目録三、①記載の預金のうち３分の２',
];

const contentOfRule2 = [
  '相続財産目録四、①記載の株式',
  '相続財産目録五、①記載の死亡保険金',
  'なお、受取人　妻○○○○　１００％とあるは、本遺言により、受取人　長男○○○○１００％と変更する。',
];

const contentOfRule3 = [
  '相続財産目録三、①記載の預金のうち３分の１',
  '相続財産目録五、②記載の死亡保険金',
  'なお、受取人　妻　○○○○　５０％、長男○○○○　５０％とあるは、本遺言により受取人　二男○○○○　１００％と変更する。',
];

const contentOfRule4 = [
  '相続財産目録一、③④記載の不動産',
  '相続財産目録二、②記載の動産',
];

const contentOfRule5 = ['相続財産目録六、①記載の債権'];

const contentOfRule6 = [
  '相続財産目録七、①記載の負債残高全部',
  '未払医療費',
  '葬儀代',
];

const contentOfRule8 = [
  '住所　○○○○○○○○',
  '氏名　○○○○　○○○○年○○月○○日生',
  '万一、遺言執行者○○○○が遺言者より先に亡くなるか、もしくは生存していても遺言執行が困難な場合は、下記の者を遺言執行者に指定する',
  '住所　○○○○○○○○○○',
  '遺言執行士 ○○ ○○（住所；○○県○○市○○ □丁目□番□号 生年月日；○○○○/○○/○○）',
  '上記、遺言執行者に対する報酬は、相続財産総額（積極財産）の〇％相当額とする。',
];

const date = new Date();

const signedDate = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {
  era: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}).format(date);
const testatorName = '氏名　○○○○○○　㊞';
const testatorAddress = '住所　東京都新宿区○○1丁目○○番○○号';

const PAGE_TITLE_2 = '相続財産目録';

const PROPERTY_LIST = [
  '一、　不動産',
  '二、　動産',
  '三、　預貯金',
  '四、　株式・債券',
  '五、　生命保険',
  '六、　債権',
  '七、　負債',
];

const contentOfProperty1 = [
  '土地',
  '建物',
  '土地（敷地権）',
  '建物（区分建物）',
];

const DetailOfProperty1_1 = [
  '所    在　東京都新宿区○○1丁目',
  '地    番　〇〇〇〇番〇〇',
  '地    目　宅地',
  '地    積　150.00平方メートル',
];

const DetailOfProperty1_2 = [
  '所    在  　東京都新宿区○○1丁目○○○○番地○○',
  '家屋番号　　〇〇〇〇番〇〇',
  '種　  類    居宅',
  '構    造　　軽量鉄骨造スレート葺２階建',
  '床 面 積　  １階　８０．５５平方メートル',
  '　　　　　  ２階　６０．６６平方メートル',
];

const DetailOfProperty1_3 = [
  '所　　在　　群馬県長野原草津町大字白根',
  '地　　番　　〇〇〇〇番',
  '地　　目　　宅地',
  '地　　積　　２００７．２３平方メートル',
  '            敷地権割合　1万分の２５０',
];

const DetailOfProperty1_4 = [
  '一棟の建物の表示',
  '　　　所　　在　　群馬県長野原草津町大字白根〇〇〇〇番地',
  '建物の番号　白根〇×スイート',
  '　　　家屋番号　　白根○○○○番１２３４',
  '　　　種　　類　　居宅',
  '　　　構　　造　　鉄骨鉄筋コンクリート造',
  '　　　床 面 積　　１２階部分　５６．７８平方メートル',
];

const contentOfProperty2 = [
  '上記一、②の建物内にある家財道具等全部',
  '上記一、④の建物内にある家財道具等全部',
];

const contentOfProperty3 = ['みずほ銀行丸の内支店'];

const DetailOfProperty3_1 = [
  '総合口座（普通預金・定期預金）',
  '口座番号１２３４５６７',
];

const contentOfProperty4 = ['野村証券大手町支店'];

const DetailOfProperty4_1 = [
  '口座番号２３４－５６７８',
  '株式会社丸紅　５，０００株',
];

const contentOfProperty5 = ['〇〇生命保険株式会社', '××生命保険株式会社'];

const DetailOfProperty5_1 = [
  '変額個人年金保険M-VA',
  '証券番号（０００）１２３４-５６７８',
  '死亡保険金受取人　妻　〇〇〇〇　１００％',
];

const DetailOfProperty5_2 = [
  '普通終身生命保険',
  '証券番号　３４５６７-８９１２３',
  '死亡保険金受取人　妻　〇〇〇〇　５０％',
  '　　　　　　　　　長男〇〇□△　５０％',
];

const contentOfProperty6 = ['貸付金元金５００万円　利息なし'];

const DetailOfProperty6_1 = [
  '長男〇〇□△へ、事業資金として平成２５年４月５日貸付　',
  '返済期日平成３５年４月５日元金全額一括返済',
];

const contentOfProperty7 = ['三菱UFJ銀行丸の内支店'];

const DetailOfProperty7_1 = ['住宅ローン借入元金３，０００万円'];

const FOOTER = '以上';

export {
  PAGE_TITLE_1,
  rulesFrom1To8,
  contentOfRule1,
  contentOfRule2,
  contentOfRule3,
  contentOfRule4,
  contentOfRule5,
  contentOfRule6,
  contentOfRule8,
  signedDate,
  testatorName,
  testatorAddress,
  PAGE_TITLE_2,
  PROPERTY_LIST,
  contentOfProperty1,
  contentOfProperty2,
  contentOfProperty3,
  contentOfProperty4,
  contentOfProperty5,
  contentOfProperty6,
  contentOfProperty7,
  DetailOfProperty1_1,
  DetailOfProperty1_2,
  DetailOfProperty1_3,
  DetailOfProperty1_4,
  DetailOfProperty3_1,
  DetailOfProperty4_1,
  DetailOfProperty5_1,
  DetailOfProperty5_2,
  DetailOfProperty6_1,
  DetailOfProperty7_1,
  FOOTER,
};
