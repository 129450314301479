/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC } from 'react';
import { useTopicDetail } from 'hooks';
import TopicsViewArea from '../molcules/topicsViewArea';
import TopicsActionLinks from '../molcules/topicsActionLinks';
import './topicsDetailContent.css';

const TopicsDetailContent: FC = () => {
  const topicsDetailData = useTopicDetail({ onlyReleased: true });

  if (!topicsDetailData) {
    return <></>;
  }

  const { updatedAt, title, content, imageUrl, prevId, nextId } =
    topicsDetailData;

  return (
    <div className="topics-detail">
      <TopicsViewArea
        updatedAt={updatedAt}
        title={title}
        imageUrl={imageUrl}
        content={content}
      />
      <TopicsActionLinks prevId={prevId} nextId={nextId} />
    </div>
  );
};

export default TopicsDetailContent;
