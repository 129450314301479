import { FC } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import {
  RealEstate,
  MovingProperty,
  Deposits,
  Stock,
  LifeInsurance,
  Bonds,
  Liabilities,
  // Allocation,
  AllocationProperties,
} from 'models';
// import { queryDeposits } from 'domains/cognite/services/manageDeposits';
// import movingProperty from 'images/movingProperty.svg';
import inputCompleted from '../images/inputCompleted.svg';
import './itemLink.css';

type Props = {
  name: string;
  href: string;
  icon: string;
  // eslint-disable-next-line react/require-default-props
  description?: string;
  keyId: string;
  getQuery: () =>
    | Promise<RealEstate>
    | Promise<MovingProperty>
    | Promise<Deposits>
    | Promise<Stock>
    | Promise<LifeInsurance>
    | Promise<Bonds>
    | Promise<Liabilities>
    | Promise<MovingProperty>
    // | Promise<Allocation>
    | Promise<AllocationProperties>;
  // getQuery: (Promise<string> | string);
  // mark?: string;
};

const ItemLink: FC<Props> = (props) => {
  const { name, href, icon, description, keyId, getQuery } = props;
  // const getDepositsQuery = async () => {
  //   const getDepositsQueryContent = await queryDeposits();
  //   const data = getDepositsQueryContent[0];

  //   return data;
  // };

  // const { data: getQueryData, isFetching: fetchQuery } = useQuery(
  //   'deposits',
  //   () => getDepositsQuery(),
  // );
  const { data: getQueryData, isFetching: fetchQuery } = useQuery(
    keyId,
    getQuery,
  );

  return fetchQuery ? (
    <div>
      <p>データ取得中</p>
    </div>
  ) : (
    <div className="itemLink">
      <div className="itemIcon">
        {/* {mark ?? ""} */}
        {getQueryData ? <img src={inputCompleted} alt="" /> : ''}
      </div>
      <Link to={href}>
        <div className="itemContent">
          <img src={icon} alt="" />
          <p>{name}</p>
          {description ? <p>{description}</p> : ''}
        </div>
      </Link>
    </div>
  );
};

export default ItemLink;
