import { FC } from 'react';
import ExecutoTextHeader from '../organisms/executorTextHeader';
import ChangePassContent from '../organisms/changePassContent';
import CommonFooter from '../../../common/apply/templates/commonFooter';

const ChangePass: FC = () => (
  <>
    <ExecutoTextHeader title="パスワードの変更" />
    <ChangePassContent />
    <CommonFooter />
  </>
);

export default ChangePass;
