import { FC } from 'react';
import RequireCheckBtn from '../atoms/requireCheckBtn';
import './requireNextReturn.css';

type Props = {
  returnFunc: () => void;
  nextFunc: () => void;
  deleteFunc: () => void;
};

const RequireNextReturn: FC<Props> = (props) => {
  const { returnFunc, nextFunc, deleteFunc } = props;

  return (
    <>
      <div className="requireNextReturn">
        <p>
          下記ボタンのいずれを押しても
          <br className="sp-only" />
          自動で保存されます
        </p>
        <div className="requireNextReturnBtnContainer">
          <RequireCheckBtn title="前へ" func={returnFunc} />
          <RequireCheckBtn title="次へ" func={nextFunc} />
        </div>
        <button
          className="all-delete-requireNextReturn"
          type="button"
          onClick={deleteFunc}
        >
          このページの内容を削除
        </button>
      </div>
    </>
  );
};

export default RequireNextReturn;
