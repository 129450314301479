/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { FC, useRef, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useQueryProperties, usePropertiesItem } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { MutateProperties } from 'domains/cognite/services/manageProperties';
import {
  MutateWillInfo,
  DeleteWillInfo,
} from 'domains/cognite/services/manageWillInfo';
import { GetUser, UpdateUser } from 'domains/cognite/services/manageUser';
import Dialog from 'common/apply/organisms/dialog';
import { logger } from 'utils/logger';
import { Year, YearArray } from '../data/year';
import { Month, MonthArray } from '../data/month';
import { Day, DayArray } from '../data/day';
import WillHeader from '../organisms/willHeader';
import WillInfoDescription from '../templates/willInfoDescription';
import InputItem from '../atoms/InputItem';
import { Prefecture, PrefectureArray } from '../data/prefecture';
import RequireNextReturn from '../molecules/requireNextReturn';
import PageDescriptionPc from '../organisms/pageDescriptionPc';
import pageData from '../data/pageData';
import PcDescriptionBackImg from '../images/PcdescriptionBackImg.svg';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import './willInfo.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

type cityProps = {
  prefCode: string;
  cityCode: string;
  cityName: string;
  bigCityFlag: string;
};

const WillInfo: FC = () => {
  // useSync();
  const { data: user } = useQuery(['authenticated_user'], GetUser);

  if (!user) {
    logger.error(user);
    throw Error;
  }

  const [isDialogShow, setIsDialogShow] = useState(false);

  const toggleDialog = () => {
    setIsDialogShow(!isDialogShow);
  };

  logger.info(user);
  const { data } = useQueryProperties('willInfo_properties', user);

  const { willInfo } = usePropertiesItem(data);

  // 既に値が入っているかチェック
  const nameValue =
    willInfo?.name === undefined || willInfo?.name === null
      ? ''
      : willInfo?.name;

  const birthValue =
    willInfo?.birth === undefined || willInfo?.birth === null
      ? ''
      : willInfo?.birth;
  const slash = '/';
  const birthYearValue = Number(birthValue.split(slash)[0]);
  const birthMonthValue = Number(birthValue.split(slash)[1]);
  const birthDayValue = Number(birthValue.split(slash)[2]);

  const addressValue =
    willInfo?.address === undefined || willInfo?.address === null
      ? ''
      : willInfo?.address;
  const whiteSpace = /\s+/;
  const prefectureValue = addressValue.split(whiteSpace)[0];
  const citiesValue = addressValue.split(whiteSpace)[1];
  // ここまで

  const propertiesId = data ? data.id : '';
  const [city, setCity] = useState<cityProps[]>([]);

  const nameRef = useRef<HTMLInputElement>(null!);
  // 生年月日
  const yearRef = useRef<HTMLSelectElement>(null!);
  const monthRef = useRef<HTMLSelectElement>(null!);
  const dayRef = useRef<HTMLSelectElement>(null!);
  // 住所
  const prefectureRef = useRef<HTMLSelectElement>(null!);
  const citiesRef = useRef<HTMLSelectElement>(null!);

  const flag = true;

  const descriptionPc = <>{pageData[0].description}</>;

  const cardDelete = async () => {
    await DeleteWillInfo();
    window.location.reload();
  };

  const navigate = useNavigate();

  const wait = () =>
    new Promise((resolve) => {
      setTimeout(resolve, 200);
    });

  const mutateToReturn = async () => {
    await wait();
    if (
      (willInfo === null &&
        !nameRef.current &&
        !prefectureRef.current &&
        !citiesRef.current &&
        !yearRef.current &&
        !monthRef.current &&
        !dayRef.current) ||
      (nameRef.current &&
        nameRef.current.value !== '' &&
        yearRef.current &&
        yearRef.current.value !== '0' &&
        prefectureRef.current &&
        prefectureRef.current.value !== '' &&
        citiesRef.current &&
        citiesRef.current.value !== '')
    ) {
      const address = `${
        prefectureRef.current ? prefectureRef.current.value : ''
      } ${citiesRef.current ? citiesRef.current.value : ''}`;
      const instance = {
        name: nameRef.current ? nameRef.current.value : '',
        address,
        birth: `${yearRef.current ? yearRef.current.value : ''}/${
          monthRef.current ? monthRef.current.value : ''
        }/${dayRef.current ? dayRef.current.value : ''}`,
        inputFlag: flag,
      };
      const id = data
        ? data.propertiesWillInfoId
          ? data.propertiesWillInfoId
          : ''
        : '';

      const newWillInfo = await MutateWillInfo(id, instance);

      void MutateProperties(propertiesId, {
        userId: user.attributes.sub,
        willInfo: newWillInfo,
      });
      navigate('../');
    } else {
      window.alert('必須項目を入力してください');
    }
  };

  const mutateToNext = async () => {
    await wait();
    if (
      (willInfo === null &&
        !nameRef.current &&
        !prefectureRef.current &&
        !citiesRef.current &&
        !yearRef.current &&
        !monthRef.current &&
        !dayRef.current) ||
      (nameRef.current &&
        nameRef.current.value !== '' &&
        yearRef.current &&
        yearRef.current.value !== '0' &&
        prefectureRef.current &&
        prefectureRef.current.value !== '' &&
        citiesRef.current &&
        citiesRef.current.value !== '')
    ) {
      const address = `${
        prefectureRef.current ? prefectureRef.current.value : ''
      } ${citiesRef.current ? citiesRef.current.value : ''}`;
      const instance = {
        name: nameRef.current ? nameRef.current.value : '',
        address,
        birth: `${yearRef.current ? yearRef.current.value : ''}/${
          monthRef.current ? monthRef.current.value : ''
        }/${dayRef.current ? dayRef.current.value : ''}`,
        inputFlag: flag,
      };
      const id = data
        ? data.propertiesWillInfoId
          ? data.propertiesWillInfoId
          : ''
        : '';

      const newWillInfo = await MutateWillInfo(id, instance);

      void MutateProperties(propertiesId, {
        userId: user.attributes.sub,
        willInfo: newWillInfo,
      });
      const editProfile = {
        ...user.attributes,
        name: nameRef.current ? nameRef.current.value : '',
      };

      await UpdateUser(editProfile).then(() => {
        logger.info('プロフィール変更成功');
      });
      navigate('../realestate');
    } else {
      window.alert('必須項目の入力が漏れています。');
    }
  };

  const getCity = async () => {
    // const getSelect = document.getElementById('prefecture') as HTMLInputElement;
    const selectLabel = prefectureRef.current
      ? prefectureRef.current.value
      : '';

    const selectArr = PrefectureArray.find((ele) => ele.label === selectLabel);
    const selectId = selectArr ? selectArr.id : 48;

    await fetch(
      `https://opendata.resas-portal.go.jp/api/v1/cities?prefCode=${selectId}`,
      {
        headers: { 'X-API-KEY': 'yvet69MOZSiyzRoinuvNJaWjcXaqhoP118HTqciA' },
      },
    )
      .then((response) => response.json())
      .then((citiesData) => {
        setCity(citiesData.result);
      });
  };

  useEffect(() => {
    const selectLabel = prefectureRef.current
      ? prefectureRef.current.value
      : prefectureValue;

    const selectArr = PrefectureArray.find((ele) => ele.label === selectLabel);
    const selectId = selectArr ? selectArr.id : 1000;

    void fetch(
      `https://opendata.resas-portal.go.jp/api/v1/cities?prefCode=${selectId}`,
      {
        headers: { 'X-API-KEY': 'yvet69MOZSiyzRoinuvNJaWjcXaqhoP118HTqciA' },
      },
    )
      .then((response) => response.json())
      .then((citiesData) => {
        setCity(citiesData.result);
      });
  }, [prefectureValue]);

  const [year, setYear] = useState<number>(birthYearValue || 5);
  const getYear = () => {
    const yearDataInfo = document.getElementById('year') as HTMLSelectElement;
    const yearData = Number(yearDataInfo.value);

    return setYear(yearData);
  };

  const [month, setMonth] = useState<number>(birthMonthValue);
  const getMonth = () => {
    const monthDataInfo = document.getElementById('month') as HTMLSelectElement;
    const monthData = Number(monthDataInfo.value);

    return setMonth(monthData);
  };

  return (
    <>
      {isDialogShow ? (
        <Dialog
          dialogText="これまでに入力した全ての内容が削除されます。よろしいですか？"
          dialogYesFunc={cardDelete}
          dialogNoFunc={toggleDialog}
        />
      ) : (
        ''
      )}
      <WillHeader title="遺言者情報" />
      <div className="sp-only">
        <WillInfoDescription />
      </div>
      <div className="willInfoForm">
        <div>
          <div>
            <label htmlFor="name">
              <InputItem title="お名前" name="require" />
              <input
                type="text"
                id="name"
                name="name"
                placeholder="名前を入力"
                ref={nameRef}
                defaultValue={nameValue}
              />
            </label>
            <p>架空の名前で構いません。</p>
          </div>
          <div>
            <label htmlFor="birth">
              <InputItem title="生年月日" name="require" />
              <div className="birthSelect birthYear">
                <select
                  name="year"
                  id="year"
                  // value={birthYear}
                  onChange={() => {
                    getYear();
                  }}
                  ref={yearRef}
                >
                  {YearArray.map((yearEle: Year) => {
                    if (yearEle.year === 0) {
                      return (
                        <option
                          value={yearEle.year}
                          key={`year${yearEle.id}`}
                          disabled
                          selected={
                            birthYearValue
                              ? birthYearValue === yearEle.year
                              : yearEle.year === 0
                          }
                        >
                          -
                        </option>
                      );
                    }

                    return (
                      <option
                        value={yearEle.year}
                        key={`year${yearEle.id}`}
                        selected={
                          birthYearValue
                            ? birthYearValue === yearEle.year
                            : yearEle.year === 0
                        }
                      >
                        {yearEle.year}
                      </option>
                    );
                  })}
                </select>
                <p>年</p>
              </div>
              <div className="MonthAndDay">
                <div className="birthSelect birthMonth">
                  <select
                    name="month"
                    id="month"
                    // value={birthMonth}
                    onChange={() => {
                      getMonth();
                    }}
                    ref={monthRef}
                  >
                    {MonthArray.map((monthEle: Month) => (
                      <option
                        value={monthEle.month}
                        key={`month${monthEle.id}`}
                        selected={
                          birthMonthValue
                            ? birthMonthValue === monthEle.month
                            : monthEle.month === 1
                        }
                      >
                        {monthEle.month}
                      </option>
                    ))}
                  </select>
                  <p>月</p>
                </div>
                <div className="birthSelect birthDay">
                  <select
                    name="day"
                    id="day"
                    // value={birthDay}
                    ref={dayRef}
                    // defaultValue={birthDayValue}
                  >
                    {/* <option value="" selected hidden>
                      -
                    </option> */}
                    {DayArray.map((dayEle: Day) => {
                      if (year % 4 === 0 && month === 2) {
                        return (
                          <option
                            value={dayEle.day28}
                            key={`day${dayEle.id}`}
                            selected={
                              birthDayValue
                                ? birthDayValue === dayEle.day28
                                : dayEle.day28 === 1
                            }
                          >
                            {dayEle.day28}
                          </option>
                        );
                      }
                      if (year % 4 !== 0 && month === 2) {
                        return (
                          <option
                            value={dayEle.day29}
                            key={dayEle.id}
                            selected={
                              birthDayValue
                                ? birthDayValue === dayEle.day29
                                : dayEle.day29 === 1
                            }
                          >
                            {dayEle.day31}
                          </option>
                        );
                      }
                      if (
                        month === 4 ||
                        month === 6 ||
                        month === 9 ||
                        month === 11
                      ) {
                        return (
                          <option
                            value={dayEle.day30}
                            key={dayEle.id}
                            selected={
                              birthDayValue
                                ? birthDayValue === dayEle.day30
                                : dayEle.day30 === 1
                            }
                          >
                            {dayEle.day30}
                          </option>
                        );
                      }

                      return (
                        <option
                          value={dayEle.day31}
                          key={dayEle.id}
                          selected={
                            birthDayValue
                              ? birthDayValue === dayEle.day31
                              : dayEle.day31 === 1
                          }
                        >
                          {dayEle.day31}
                        </option>
                      );
                    })}
                    :
                  </select>
                  <p>日</p>
                </div>
              </div>
            </label>
            <p>架空の生年月日で構いません。</p>
          </div>
          <div className="addressAndCities">
            <label htmlFor="address">
              <InputItem title="住所" name="require" />
              <div>
                <select
                  id="prefecture"
                  name="prefecture"
                  className="prefecture"
                  ref={prefectureRef}
                  onChange={async () => {
                    await getCity();
                  }}
                  defaultValue={prefectureValue}
                >
                  <option value="" selected hidden>
                    都道府県を選択
                  </option>
                  {PrefectureArray.map((prefecture: Prefecture) => (
                    <option
                      key={prefecture.label}
                      value={prefecture.label}
                      selected={prefectureValue === prefecture.label}
                    >
                      {prefecture.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="citySelect">
                <select
                  name="cities"
                  id="cities"
                  ref={citiesRef}
                  defaultValue={citiesValue}
                >
                  <option value="" selected hidden>
                    市区町村を選択
                  </option>
                  {city.map((ele) => (
                    <option
                      value={ele.cityName}
                      key={ele.cityName}
                      selected={citiesValue === ele.cityName}
                    >
                      {ele.cityName}
                    </option>
                  ))}
                </select>
              </div>
            </label>
            <p>
              架空の住所で構いません。市区町村（〇〇県〇〇市）まで記載してください。
            </p>
          </div>
        </div>
        <RequireNextReturn
          returnFunc={mutateToReturn}
          nextFunc={mutateToNext}
          deleteFunc={toggleDialog}
        />
      </div>
      <CommonFooter />
      <PageDescriptionPc
        page="homeDescriptionPc"
        description={descriptionPc}
        descriptionPcImg={PcDescriptionBackImg}
      />
    </>
  );
};

export default WillInfo;
