import { FC, Suspense } from 'react';
// import { useSync } from 'hooks';
import WillHeader from '../organisms/willHeader';
import ExecutorChargeDescription from '../templates/executorChargeDescription';
// import Footer from '../organisms/footer';
import CommonFooter from '../../../common/apply/templates/commonFooter';
import ExecutorChargeContent from '../organisms/executorChargeContent';
import ExecutorPcDescription from '../organisms/executorPcDescription';
import pageData from '../data/pageData';
import './executorCharge.css';

const ExecutorCharge: FC = () => (
  <>
    <WillHeader title="担当遺言執行者" />
    <div className="executorCharge">
      <ExecutorChargeDescription />
      <Suspense fallback={<div>Loading...</div>}>
        <ExecutorChargeContent />
      </Suspense>
    </div>

    {/* <Footer /> */}
    <CommonFooter />
    <ExecutorPcDescription description={pageData[36].description} />
  </>
);

export default ExecutorCharge;
