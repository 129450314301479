import { FC } from 'react';
import CommonFooter from '../../apply/templates/commonFooter';
import UserLogoHeader from '../organisms/userLogoHeader';
import WomanStanding from '../images/contactThanks_woman-standing.svg';
import NavigateBtn from '../atoms/navigateBtn';
import './contactThanks.css';

const ContactThanks: FC = () => (
  <>
    <UserLogoHeader />
    <div className="contact-thanks">
      <p>お問い合わせ 完了</p>
      <p>
        お問い合わせいただき
        <br className="sp-only" />
        ありがとうございます
      </p>
      <div>
        <div>
          <p>
            お問合せいただきありがとうございます。ご入力内容を送信いたしました。
          </p>
          <p>
            近日中に担当よりご返答させていただきますので、しばらくお待ちください。
          </p>
        </div>
        <img src={WomanStanding} alt="女性のイラスト" />
      </div>
      <NavigateBtn />
    </div>
    <CommonFooter />
  </>
);

export default ContactThanks;
