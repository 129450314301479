import { FC, Suspense, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ExecutorLogoHeader from 'common/apply/organisms/executorLogoHeader';
import ExecutorTextHeader from '../organisms/executorTextHeader';
import ChatListContent from '../organisms/chatListContent';
import './chatList.css';
import CommonFooter from '../../../common/apply/templates/commonFooter';

const ChatList: FC = () => {
  const navigate = useNavigate();

  const [position, setPosition] = useState<number>(0);
  const [classSwitch, setClassSwitch] = useState(false);
  const FooterScroll = document.getElementById('chatListBackWrap');

  window.addEventListener('scroll', () => {
    const ScrollTop = document.documentElement.scrollTop;

    if (!FooterScroll) {
      <p>取得中</p>;
    } else if (position < ScrollTop) {
      setClassSwitch(true);
    } else {
      setClassSwitch(false);
    }

    setPosition(ScrollTop);
  });

  const upOrDown = classSwitch
    ? 'exeChatListScrollUp'
    : 'exeChatListScrollDown';

  return (
    <>
      <ExecutorLogoHeader />
      <ExecutorTextHeader title="メッセージ" />
      <div className="chatList">
        <Suspense fallback={<p>...読み込み中</p>}>
          <ChatListContent />
        </Suspense>
        <div className={`chatListBack ${upOrDown}`} id="chatListBackWrap">
          <button type="button" onClick={() => navigate('/executor')}>
            戻る
          </button>
        </div>
      </div>
      <CommonFooter />
    </>
  );
};

export default ChatList;
