/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-extraneous-dependencies */
import { DataStore } from 'aws-amplify';
import { MovingProperty, MovingPropertyMetaData } from 'models';
import { logger } from 'utils/logger';
import { ModelInit } from '@aws-amplify/datastore';

export const MutateMovingPropertyAndGetId = async (
  id: string,
  check1: boolean,
  check2: boolean,
  check3: boolean,
  check4: boolean,
  check5: boolean,
): Promise<string> => {
  try {
    logger.info('動産の項目の更新を開始します');

    const original = await DataStore.query(MovingProperty, id);

    if (original) {
      logger.info(original);
      const data = await DataStore.save(
        MovingProperty.copyOf(original, (updated) => {
          updated.check1 = check1;
          updated.check2 = check2;
          updated.check3 = check3;
          updated.check4 = check4;
          updated.check5 = check5;
        }),
      );
      logger.info(data);
      logger.info('動産の項目の更新が完了しました');

      return data.id;
    }
    logger.info('動産の項目の作成を開始します');
    const instance = {
      id,
      check1,
      check2,
      check3,
      check4,
      check5,
    };
    const data = await DataStore.save(new MovingProperty(instance));
    logger.debug(data);
    logger.info('債権の項目の作成が完了しました');

    return data.id;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const CreateMovingPropertyAndGetId = async (
  instance: ModelInit<MovingProperty, MovingPropertyMetaData>,
): Promise<string> => {
  try {
    logger.info('動産の項目の作成を開始します');
    const data = await DataStore.save(new MovingProperty(instance));
    logger.debug(data);
    logger.info('動産の項目の作成が完了しました');

    return data.id;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const UpdateMovingPropertyById = async (
  id: string,
  check1: boolean,
  check2: boolean,
  check3: boolean,
  check4: boolean,
  check5: boolean,
): Promise<void> => {
  try {
    logger.info('動産の項目の更新を開始します');

    const original = await DataStore.query(MovingProperty, id);

    if (original) {
      logger.info(original);
      const data = await DataStore.save(
        MovingProperty.copyOf(original, (updated) => {
          updated.check1 = check1;
          updated.check2 = check2;
          updated.check3 = check3;
          updated.check4 = check4;
          updated.check5 = check5;
        }),
      );
      logger.info(data);
      logger.info('動産の項目の更新が完了しました');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteMovingPropertyById = async (id: string): Promise<void> => {
  try {
    logger.info('動産の項目の削除を開始します');
    const original = await DataStore.query(MovingProperty, id);
    if (original) {
      logger.info(original);
      await DataStore.delete(original);
      logger.info('動産の項目の削除が完了しました');
    }
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const GetMovingProperty = async (): Promise<MovingProperty[]> => {
  try {
    logger.info('動産の項目のフェッチを開始します');
    const data = await DataStore.query(MovingProperty);
    logger.debug(data);
    logger.info('動産の項目のフェッチが完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const CreateMovingProperty = async (
  instance: ModelInit<MovingProperty, MovingPropertyMetaData>,
): Promise<MovingProperty> => {
  try {
    logger.info('債権の作成を開始します');
    const data = await DataStore.save(new MovingProperty(instance));
    logger.debug(data);

    logger.info('債権の作成が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const queryMovingProperty = async (): Promise<MovingProperty[]> => {
  try {
    const queryMovingPropertyData = await DataStore.query(MovingProperty);

    const queryMovingPropertyResult = !queryMovingPropertyData
      ? []
      : queryMovingPropertyData;

    return queryMovingPropertyResult;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
// export default CreateMovingProperty;

export const MutateMovingProperty = async (
  id: string,
  instance: ModelInit<MovingProperty, MovingPropertyMetaData>,
): Promise<MovingProperty> => {
  try {
    logger.info('動産の作成または更新を開始します');
    const original = await DataStore.query(MovingProperty, id);

    if (original) {
      logger.info('動産の更新を開始します');
      const data = await DataStore.save(
        MovingProperty.copyOf(original, (updated) => {
          // ワンチャンここがバグ
          updated.majorNum = instance.majorNum;
          updated.check1 = instance.check1;
          updated.check2 = instance.check2;
          updated.check3 = instance.check3;
          updated.check4 = instance.check4;
          updated.check5 = instance.check5;
          updated.inputFlag = instance.inputFlag;
        }),
      );

      return data;
    }
    logger.info('動産の作成を開始します');
    const data = await DataStore.save(new MovingProperty(instance));

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const DeleteMovingProperty = async (): Promise<MovingProperty[]> => {
  try {
    logger.info('動産の削除を開始します');
    const data = await DataStore.query(MovingProperty);
    if (data) {
      await Promise.all(
        data.map(async (ele) => {
          await DataStore.delete(ele);

          return data;
        }),
      );
    }
    logger.debug(data);
    logger.info('動産の削除が完了しました');

    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
