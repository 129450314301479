/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react/jsx-props-no-spreading */
import { FC, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSync } from 'hooks';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/swiper.min.css';
import { Topics } from 'models';
import { ListTopicsReleased } from 'domains/cognite/services/manageTopics';
import TopicsContainerLink from '../molcules/topicsContainerLink';
import './topicsContainer.css';

const TopicsContainer: FC = () => {
  useSync();
  const { data: topicsFromQuery } = useQuery(['topics'], ListTopicsReleased, {
    refetchInterval: 5000,
  });

  const topics = topicsFromQuery || ([] as Topics[]);

  const loop = topics.length > 3;

  return (
    <div className="topics-container">
      <p>トピックス</p>
      <Swiper
        slidesPerView={1.2}
        loop={loop}
        centeredSlides
        breakpoints={{
          500: {
            slidesPerView: 2.5,
          },
        }}
        autoplay={false}
        modules={[Autoplay]}
      >
        {topics.map((ele) => (
          <SwiperSlide>
            <Suspense fallback={<div>...読み込み中</div>}>
              <TopicsContainerLink key={ele.id} {...ele} />
            </Suspense>
          </SwiperSlide>
        ))}
      </Swiper>
      <Link to="/topics/list" className="topics-container_to-list">
        トピックス一覧へ
      </Link>
    </div>
  );
};

export default TopicsContainer;
