/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import Slider from 'react-slick';
import pageData from '../data/pageData';
import PageDescription from '../organisms/pageDescription';
import './executorAllocationDescription.css';
import suitWoman1 from '../images/suit-woman1.svg';

const ExecutorAllocationDescription: FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplayspeed: 200,
    arrows: false,
  };

  return (
    <div className="executorAllocationDescription">
      <div>
        <Slider {...settings}>
          <PageDescription
            page=""
            description={pageData[24].description}
            NotParamDescription="NotParamDescription"
          />
        </Slider>
      </div>
      <div>
        <img src={suitWoman1} alt="" />
      </div>
    </div>
  );
};

export default ExecutorAllocationDescription;
