const calcBirthDate = (birthday: string): string => {
  const formatBirthday = Number(birthday.replace(/-/g, '').replace(/\//g, ''));

  const today = new Date();

  if (formatBirthday === 0) {
    return '年齢不詳';
  }
  const todayYear = today.getFullYear();
  const todayMonth = (today.getMonth() + 1).toString();
  const todayDate = today.getDate().toString();

  const formatMonth =
    todayMonth.length === 1 ? todayMonth.padStart(2, '0') : todayMonth;

  const formatDate =
    todayMonth.length === 1 ? todayDate.padStart(2, '0') : todayDate;

  const formatToday = Number(`${todayYear}${formatMonth}${formatDate}`);

  if (formatToday <= formatBirthday) {
    return '年齢不詳';
  }

  const age = String(Math.floor((formatToday - formatBirthday) / 10000));

  if (age.length >= 2) {
    return `${age.slice(0, 1) + age.slice(1).replace(/./g, '0')}代`;
  }

  return '10歳未満';
};

export default calcBirthDate;
