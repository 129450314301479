import { VFC, useState } from 'react';
import { Route, Routes } from 'react-router';
import SignOutGuard from 'auth/signOutGuard';
import Top from 'common/top';
import WillSignIn from 'common/willSignIn';
import ExecutorSignIn from 'common/executorSignIn';
import SignUp from 'common/containers/signUp';
import SignUpConfirm from 'common/signUpConfirm';
import ForgetPassword from 'common/forgetPassword';
import ChangePassword from 'common/changePassword';
import WillPrivacyPolicy from 'common/components/pages/willPrivacyPolicy';
import ExecutorPrivacyPolicy from 'common/components/pages/executorPrivacyPolicy';
import WillPolicy from 'common/components/pages/willPolicy';
import DeleteThanks from 'common/components/pages/deleteThanks';
import Contact from 'common/contact';
import ContactConfirm from 'common/contactConfirm';
import ContactThanks from 'common/components/pages/contactThanks';

import ExecutorProcedure from 'common/apply/pages/executorProcedure';
import ExecutorProcedureForm from 'common/apply/pages/executorProcedureForm';
import ExecutorProcedureConfirm from 'common/apply/pages/executorProcedureConfirm';
import ExecutorProcedureSend from 'common/apply/pages/executorProcedureSend';
import WillQuestion from 'common/components/pages/willQuestion';
import CompanyInfo from 'common/components/pages/companyInfo';
import ExecutorPolicy from 'common/components/pages/executorPolicy';
import TopicsList from 'common/components/pages/topicsList';
import TopicDetail from 'common/components/pages/topicDetail';

const CommonRouter: VFC = () => {
  // 一時的にApp.tsxでstate持っておいたが、本当はここは良くない
  // useContext or useLocationを使う

  const [emialForSignUp, setEmialForSignUp] = useState<string | null>(null);
  const saveEmailForSignUp = (email: string) => {
    setEmialForSignUp(email);
  };
  const [emailForForget, setEmailForForget] = useState<string | null>(null);
  const saveEmailForForget = (email: string) => {
    setEmailForForget(email);
  };

  return (
    <Routes>
      <Route
        path=""
        element={
          <SignOutGuard>
            <Top />
          </SignOutGuard>
        }
      />
      <Route
        path="apply"
        element={
          <SignOutGuard>
            <ExecutorProcedure />
          </SignOutGuard>
        }
      />
      <Route path="apply/procedureform" element={<ExecutorProcedureForm />} />
      <Route path="apply/confirm" element={<ExecutorProcedureConfirm />} />
      <Route path="apply/complete" element={<ExecutorProcedureSend />} />
      {/* <Route
        path="signIn"
        element={
          <SignOutGuard>
            <ExecutorSignIn />
          </SignOutGuard>
        }
      /> */}
      <Route
        path="willSignIn"
        element={
          <SignOutGuard>
            <WillSignIn />
          </SignOutGuard>
        }
      />
      <Route
        path="executorSignIn"
        element={
          <SignOutGuard>
            <ExecutorSignIn />
          </SignOutGuard>
        }
      />
      <Route
        path="signUp"
        element={
          <SignOutGuard>
            <SignUp saveEmail={saveEmailForSignUp} />
          </SignOutGuard>
        }
      />
      <Route
        path="signUp/confirm"
        element={
          <SignOutGuard>
            <SignUpConfirm emailForSignUp={emialForSignUp} />
          </SignOutGuard>
        }
      />
      <Route
        path="password/forget"
        element={
          <SignOutGuard>
            <ForgetPassword saveEmail={saveEmailForForget} />
          </SignOutGuard>
        }
      />
      <Route
        path="password/change"
        element={
          <SignOutGuard>
            <ChangePassword emailForForget={emailForForget} />
          </SignOutGuard>
        }
      />
      <Route path="question" element={<WillQuestion />} />
      <Route path="companyinfo" element={<CompanyInfo />} />
      <Route path="contact" element={<Contact />} />
      <Route path="contact/confirm" element={<ContactConfirm />} />
      <Route path="contact/thanks" element={<ContactThanks />} />
      <Route path="willprivacypolicy" element={<WillPrivacyPolicy />} />
      <Route path="executorprivacypolicy" element={<ExecutorPrivacyPolicy />} />
      <Route path="willpolicy" element={<WillPolicy />} />
      <Route path="delete/thanks" element={<DeleteThanks />} />
      <Route path="executorpolicy" element={<ExecutorPolicy />} />
      <Route path="delete/thanks" element={<DeleteThanks />} />
      <Route path="topics/list" element={<TopicsList />} />
      <Route path="topics/:postId" element={<TopicDetail />} />
    </Routes>
  );
};

export default CommonRouter;
